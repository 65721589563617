import React from 'react';
import { InvestorTypeEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import RadioList from '../../elements/RadioList';

type ProfileAssetsQuestionProps = {
  user: IUserGraphQL;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const ProfileAssetsQuestion: React.FC<ProfileAssetsQuestionProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  const config = Object.values(InvestorTypeEnum).map((item) => ({
    label: item,
    active: user?.investorProfile?.investorType === item,
    onClick: () => setUserInvestorProfileField({ investorType: item }),
  }));

  /* Render */
  return (
    <RadioList
      dataCy="radio-investors"
      title="Which of the following types mainly describes your investor profile?"
      config={config}
      rowGap="8px 0px"
    />
  );
};

export default ProfileAssetsQuestion;
