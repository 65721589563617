import gql from 'graphql-tag';

/** Get all Cherre data to display on PDP Data Insights section! */

/** LATLONG REFACTOR: STAYS SAME */
const CHERRE_DATA_FROM_PARCEL_ID = gql`
query CherreTaxIdsFromParcelId ($params: CherreTaxIdsFromParcelIdParams!){
  cherreTaxIdsFromParcelId (params: $params)
}`;

export default CHERRE_DATA_FROM_PARCEL_ID;
