import React from 'react';
import styled from '@emotion/styled';
import { TDropdownItemProps } from '../DropdownList';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import * as UrlUtil from '../../utils/UrlUtil';
import Colors from '../../styles/Colors';
import Icon from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import ButtonDropdown from '../../elements/ButtonDropdown';
import { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Icons from '../../elements/Icons';

const DropdownItem = styled.div<{isHovered: boolean}>`
  width: 100%;
  box-sizing: border-box;
  height: 56px;
  display: flex;
  align-items: center;
  transition: all 0.1s;
  color: ${({ isHovered }) => (isHovered ? `${Colors.Black}` : `${Colors.Grey100}`)};
  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Grey100}` : null)};

  &:hover {
    background-color: ${Colors.Grey100};
    color: ${Colors.Black};
    cursor: pointer
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  width: 40px;
`;

type SettingsMobileNavDropdownProps ={
  items: IButtonOptions[];
};

const SettingsMobileNavDropdown: React.FC<SettingsMobileNavDropdownProps> = ({
  items,
}) => {
  const { pathname } = UrlUtil.parse(window.location.href);
  const activeItem = items.find((i) => i.active.includes(pathname));

  const ItemComponent = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
    item,
    onChange,
    isHovered,
    setOpen,
  }, ref) => {
    const curItem = items.find((option) => option.text === item.name);
    const active = curItem === activeItem;
    return (
      <DropdownItem
        ref={ref}
        isHovered={isHovered}
        onClick={() => {
          onChange(item);
          setOpen(false);
        }}
      >
        {curItem?.icon && (
          <IconContainer>
            <Icon icon={curItem.icon} color={active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey900} size={18} />
          </IconContainer>
        )}
        <Text type={TextTypesEnum.Regular16} color={active ? Colors.Brand700 || Colors.Blurple700 : Colors.Black}>
          {item.name}
        </Text>
      </DropdownItem>
    );
  });

  return (
    <ButtonDropdown
      items={items}
      activeItem={activeItem}
      ItemComponent={ItemComponent}
      buttonType={ButtonTypesEnum.Pale}
      buttonSize={ButtonSizesEnum.Large}
      rightIcon={Icons.AngleDownRegular}
      iconColor={Colors.Brand700 || Colors.Blurple700}
    />
  );
};

export default SettingsMobileNavDropdown;
