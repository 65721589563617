import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { PreferredInvestmentTypeDescriptionObject, PreferredInvestmentTypeEnum, RegionalFocusEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import IListingDealProfile from '@biproxi/models/interfaces/IListingDealProfile';
import DealProfileBuyerLocationEnum from '@biproxi/models/enums/DealProfileEnums';
import { AmericanStateCodesToNamesEnum } from '@biproxi/models/enums/AmericanStatesEnums';
import { KB_CREATE_LISTING_DEALPROFILE_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Text, { TextTypesEnum } from '../../elements/Text';
import Dropdown, { DropdownTypesEnum } from '../../elements/Dropdown';
import { TDropdownItemProps } from '../DropdownList';
import Checkbox from '../../elements/Checkbox';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import RadioList from '../../elements/RadioList';
import Input, { InputTypesEnum, InputThemeEnum } from '../../elements/Input';
import Colors from '../../styles/Colors';
import { useMobileMedia } from '../../utils/MediaQuery';
import Flex from '../../elements/Flex';

const InvestmentTypeDropdownItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.1s;
  margin-bottom: 16px;
  white-space: break-spaces !important;

  &:hover {
    background-color: ${Colors.Brand50 || Colors.Blurple50};
    color: ${Colors.Black};
    cursor: pointer
  }
`;

const InvestmentTypeDropdownItem = styled.div`
  /* margin: 0px 16px; */
  padding-left: 16px;
  height: 100%;
  width: inherit;
`;

const Spacer = styled.div`
  height: 16px;
`;

type CreateListingDealProfileBox = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingDealProfile: React.FC<CreateListingDealProfileBox> = ({
  id,
  listing,
}) => {
  /** State */
  const dealProfile = listing?.dealProfile;
  const investorLocation = dealProfile?.buyerLocation;
  const investorRegionalPreference = dealProfile?.regionalPreference;
  const investmentType = listing?.dealProfile?.investmentType;

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Error handling */
  const errors = useAppSelector(ListingSelectors.errors);
  const dealProfileErrors = Object.keys(errors).reduce((cur, next) => {
    if (next.includes('/dealProfile')) cur[next.slice(next.lastIndexOf('/'))] = errors[next];
    return cur;
  }, {});
  const investorLocationError = dealProfileErrors['/buyerLocation'];
  const regionalPreferenceError = dealProfileErrors['/regionalPreference'];
  const investmentTypeError = dealProfileErrors['/investmentType'];

  /** Config */
  const investorLocationConfig = Object.values(DealProfileBuyerLocationEnum).map((item) => ({
    label: item,
    active: investorLocation === item,
    onClick: () => setListingDealProfileField(
      investorLocation === item
        ? null
        : { buyerLocation: item },
    ),
    labelTextType: TextTypesEnum.Regular14,
  }));

  const InvestmentTypeDropdownItemComponent = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
    item,
  }, ref) => (
    <InvestmentTypeDropdownItemContainer
      ref={ref}
      onClick={() => {
        setListingDealProfileField({
          investmentType: item.active
            ? dealProfile?.investmentType?.filter((i) => i !== item.value)
            : dealProfile?.investmentType
              ? [...dealProfile?.investmentType, item.value]
              : [item.value],
        });
      }}
    >
      <InvestmentTypeDropdownItem>
        <Flex
          align="start"
        >
          <Checkbox
            active={item.active}
            label={item.name}
            margin="4px 0 0 0"
            labelTextType={TextTypesEnum.Regular14}
            dataCy={`deal-profile-investment-item-${item.name.toLowerCase()}`}
          />
          <Flex
            direction="column"
            width="calc(100% - 24px)" // - 24px because that is the width of the checkbox
          >
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>{item.value}</Text>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>{PreferredInvestmentTypeDescriptionObject[item.value]}</Text>
          </Flex>
        </Flex>
      </InvestmentTypeDropdownItem>
    </InvestmentTypeDropdownItemContainer>
  ));

  /* Actions */
  const dispatch = useAppDispatch();

  const setListingDealProfileField = (field: Partial<IListingDealProfile>) => dispatch(
    ListingActions.setListingDealProfileField(field),
  );

  /* Render */
  return (
    <ContentCard
      id={id}
      title="Investor matching"
      dataCy="deal-profile-greeting"
      subtitle="Investor matching puts the right investor in front of the right deal at the right time. Direct your listing to investors based on specific criteria."
      learnMoreLink={KB_CREATE_LISTING_DEALPROFILE_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      {investorLocationError && (
        <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{investorLocationError}</Text>
      )}
      <RadioList
        title="Investor location"
        titleTextType={TextTypesEnum.Medium12}
        config={investorLocationConfig}
        flexDirection={isMobile ? 'column' : 'row'}
        columnGap="0px 8px"
        rowGap={isMobile ? '4px 0px' : null}
        dataCy="investor-location-list"
      />
      <Spacer />
      {investorLocation === DealProfileBuyerLocationEnum.LocalInvestor && (
        <>
          <Input
            label="State"
            value={AmericanStateCodesToNamesEnum[listing?.address?.state] ?? ''}
            inputType={InputTypesEnum.Text}
            inputTheme={InputThemeEnum.Dark}
            disabled
          />
          <Spacer />
        </>
      )}
      {investorLocation === DealProfileBuyerLocationEnum.RegionalInvestor && (
        <>
          <Dropdown
            data-cy="deal-profile-investor-location-dropdown"
            label="Select region"
            type={DropdownTypesEnum.Select}
            value={investorRegionalPreference ?? ''}
            onChange={(value) => {
              setListingDealProfileField({
                regionalPreference: value,
              });
            }}
            placeholder="Select a preferred investor location"
            items={Object.values(RegionalFocusEnum).map((value) => ({
              name: value,
              value,
            }))}
            error={regionalPreferenceError}
            width="100%"
          />
          <Spacer />
        </>
      )}
      <Input
        label="Asset class"
        value={listing.assetClass ?? ''}
        inputType={InputTypesEnum.Text}
        inputTheme={InputThemeEnum.Dark}
        disabled
      />
      <Spacer />
      <Dropdown
        label="Investment type"
        type={DropdownTypesEnum.Select}
        value={investmentType?.length > 1
          ? `${investmentType?.length} selected`
          : investmentType?.[0] ?? ''}
        onChange={() => {}}
        placeholder="Select one or more items"
        items={Object.values(PreferredInvestmentTypeEnum).map((value) => ({
          name: '',
          value,
          active: Boolean(investmentType?.includes(value)),
        }))}
        error={investmentTypeError}
        width="100%"
        ItemComponent={InvestmentTypeDropdownItemComponent}
        keepOpen
        data-cy="deal-profile-investment-type-dropdown"
      />
    </ContentCard>
  );
};

export default CreateListingDealProfile;
