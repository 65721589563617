import { atom } from 'recoil';

export type DataExplorerGlobalStateType= {
    mapVisible: boolean; // also coordinates with whether or not the data frame is full screen
}

export const dataExplorerGlobalAtom = atom({
  key: 'dataExplorerGlobalState',
  default: {
    mapVisible: true,
  } as DataExplorerGlobalStateType,
});
