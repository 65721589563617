import React from 'react';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import DataExplorerSettingsSectionsEnum from './@types/data-settings-types/enums/DataExplorerSettingsSectionsEnum';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import Checkbox from '../../elements/Checkbox';
import { dataFrameGeneralAtom } from './state-management/recoil/atoms/DataExplorerDataFrameAtoms';

const DataSettingsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DataSettingsSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DataSettingsSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const SectionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 16px;
`;

const DataSettingsSectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

interface IDataExplorerSettingsSectionsContent {
    item: string;
    checked: boolean;
  }

type SettingsSectionProps = {
    section: DataExplorerSettingsSectionsEnum;
}

const DataSettingsSection: React.FC<SettingsSectionProps> = ({
  section,
}) => {
  /* State/Context */
  const [expandedSections, setExpandedSections] = React.useState<DataExplorerSettingsSectionsEnum[]>([]);
  // const [sectionItemsState, setSectionItemsState] = React.useState(DataExplorerSettingsSectionsContent[section])
  const [dataFrameGeneralState, setDataFrameGeneralState] = useRecoilState(dataFrameGeneralAtom);
  const {
    dataSettingsSelectedData,
  } = dataFrameGeneralState;

  const sectionItemsState = dataSettingsSelectedData[section];

  /* Functions */
  const handleExpandedSections = (clickedSection: DataExplorerSettingsSectionsEnum) => {
    if (expandedSections.includes(clickedSection)) {
      setExpandedSections(expandedSections.filter((section) => section !== clickedSection));
    } else {
      setExpandedSections([...expandedSections, clickedSection]);
    }
  };

  const dataCySectionTitle = section.replace(' ', '-');

  return (
    <DataSettingsSectionWrapper data-cy="data-settings-section">
      <DataSettingsSectionHeader>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Grey900}
          data-cy="data-settings-section-header"
        >
          {section}
        </Text>
        <DataSettingsSectionButtons>
          <Text
            data-cy={`data-settings-select-all-${dataCySectionTitle}`}
            type={TextTypesEnum.Medium12}
            color={Colors.Brand700 || Colors.Blurple700}
            onClick={() => {
              const newState = [...sectionItemsState.map((item) => ({ ...item, checked: true }))];
              const newDataSettings = { ...dataSettingsSelectedData, [section]: newState };
              setDataFrameGeneralState((prevState) => ({
                ...prevState,
                dataSettingsSelectedData: newDataSettings,
              }));
            }}
          >
            Select all
          </Text>
          <Text
            data-cy={`data-settings-clear-all-${dataCySectionTitle}`}
            type={TextTypesEnum.Medium12}
            color={Colors.Brand700 || Colors.Blurple700}
            onClick={() => {
              const newState = [...sectionItemsState.map((item) => ({ ...item, checked: false }))];
              const newDataSettings = { ...dataSettingsSelectedData, [section]: newState };
              setDataFrameGeneralState((prevState) => ({
                ...prevState,
                dataSettingsSelectedData: newDataSettings,
              }));
            }}
          >
            Clear all
          </Text>
          <Icon
            data-cy={`data-settings-${dataCySectionTitle.toLowerCase()}-expand`}
            icon={expandedSections.includes(section) ? Icons.AngleUpRegular : Icons.AngleDownRegular}
            color={Colors.Brand700 || Colors.Blurple700}
            onClick={() => handleExpandedSections(section)}
            size={12}
          />
        </DataSettingsSectionButtons>
      </DataSettingsSectionHeader>
      <DataSettingsSectionContent>
        {expandedSections.includes(section) && (
        <SectionItemsWrapper>
          {
            sectionItemsState.map((item: IDataExplorerSettingsSectionsContent, index: number) => (
              <Flex key={index}>
                <Checkbox
                  dataCy={`${item.item.replace(/\s+/g, '-').replace(/,/g, '').toLowerCase()}-checkbox`}
                  active={item.checked}
                  onClick={() => {
                    const itemChecked = item.checked;
                    const newState = JSON.parse(JSON.stringify(sectionItemsState));
                    newState[index].checked = !itemChecked;
                    setDataFrameGeneralState((prevState) => ({
                      ...prevState,
                      dataSettingsSelectedData: {
                        ...dataSettingsSelectedData,
                        [section]: newState,
                      },
                    }));
                  }}
                />
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.Grey900}
                >
                  {item.item}
                </Text>
              </Flex>
            ))
        }
        </SectionItemsWrapper>
        )}
      </DataSettingsSectionContent>
    </DataSettingsSectionWrapper>
  );
};

export default DataSettingsSection;
