import React from 'react';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@apollo/client';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import * as IUserService from '@biproxi/models/services/IUserService';
import { useInView } from 'react-intersection-observer';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import IUserQuery from '@biproxi/models/interfaces/IUserQuery';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Loader, { LoaderSizes } from '../elements/Loader';
import LIST_USERS from '../graphql/queries/users.query';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableSpace,
} from '../components/ScrollTable';
import UserInfo from '../components/UserInfo';
import LOGIN_TO_ORGANIZATION from '../graphql/mutations/loginToOrganization.mutation';
import Auth from '../utils/Auth';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import InfiniteScroll, { LoadMoreWhenInView, useInViewOptions } from '../components/InfiniteScroll';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import TOGGLE_USER_ACCOUNT_ACTIVATION from '../graphql/mutations/toggleUserAccountActivation.mutation';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';

const Container = styled.div``;

const UsersContainer = styled.div`
  margin: 24px 0 0;
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type SuperAdminUsersPageProps = {};

const SuperAdminUsersPage: React.FC<SuperAdminUsersPageProps> = () => {
  /** State */
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');

  /** Hooks */
  const [ref, inView] = useInView(useInViewOptions);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  const pushDeactivateAccount = (userId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'De-activate account',
        text: 'Are you sure you want to de-activate this account? This user will cease to receive all communication from Biproxi except for support emails. They will also lose access to their Biproxi account until they are re-activated. Note: This will not cancel their payment plan. That must be done separately for now.',
        buttonText: 'De-activate account',
        confirmLoading: deactivateLoading,
        confirm: () => {
          toggleUserAccountActivation({
            variables: {
              params: {
                userId,
                deactivate: true,
              },
            },
          });
        },
      },
    }),
  );

  const pushActivateAccount = (userId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Re-Activate account',
        text: 'Are you sure you want to re-activate this account? This user will be able to fully access biproxi again.',
        buttonText: 'Re-activate account',
        confirmLoading: deactivateLoading,
        confirm: () => {
          toggleUserAccountActivation({
            variables: {
              params: {
                userId,
                deactivate: false,
              },
            },
          });
        },
      },
    }),
  );

  /** GraphQL */
  interface UserData {
    users: {
      data: IUserGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  interface UserVars {
    params: IUserQuery;
  }

  const { data, loading, fetchMore } = useQuery<UserData, UserVars>(LIST_USERS, {
    variables: {
      params: {
        query: {
          search,
        },
        pagination: {
          offset: 0,
          limit: PaginationLimitsEnum.SuperAdminUsers,
        },
      },
    },
    context: {
      debounceKey: 'QUERY_USERS',
    },
  });

  const [toggleUserAccountActivation, { loading: deactivateLoading }] = useMutation(TOGGLE_USER_ACCOUNT_ACTIVATION, {
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Success. The user has been notified.',
      });
      popModal();
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  type Payload = IUserService.TILoginToOrganizationPayload;

  type Data = {
    loginToOrganization: IUserService.IAuthenticateUserResponse;
  };

  const [loginToOrganization] = useMutation<Data, Payload>(LOGIN_TO_ORGANIZATION, {
    onCompleted: ({ loginToOrganization }) => {
      Auth.setToken(loginToOrganization.token, loginToOrganization.hubspotToken);
      window.location.href = '/app/dashboard/home';
    },
  });

  const users = data?.users?.data ?? [];

  /** Effects */
  React.useEffect(() => {
    const getData = async () => {
      const hasMore = data?.users?.data?.length < data?.users?.info?.totalCount;
      if (hasMore && inView && fetchMore) {
        setInfiniteLoading(true);
        await fetchMore({
          variables: {
            params: {
              query: {
                search,
              },
              pagination: {
                offset: data?.users?.data?.length,
                limit: PaginationLimitsEnum.SuperAdminUsers,
              },
            },
          },
        });
        setInfiniteLoading(false);
      }
    };
    getData();
  }, [inView]);

  const createTime = (epochTime) => StringUtil.capitalize(
    TimeUtil.format(
      epochTime,
      'f',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ).toLowerCase(),
  );

  /* Render */
  return (
    <>
      <Container>
        <Flex direction="row" align="center" justify="space-between">
          <Text color={Colors.Black} type={TextTypesEnum.Bold24}>
            Biproxi Users (
            {data?.users?.info?.totalCount}
            )
          </Text>
        </Flex>
        <Input
          margin="24px 0 16px"
          placeholder="Search by email, first name, or last name"
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          clear={() => setSearch('')}
          inputType={InputTypesEnum.Search}
          inputSize={InputSizeEnum.Small}
          width="100%"
        />
        {loading ? (
          <LoaderContainer>
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          </LoaderContainer>
        ) : (
          <UsersContainer>
            <ScrollTable>
              <ScrollTableHeader>
                <ScrollTableHeaderCell flex="1.25">
                  Name
                </ScrollTableHeaderCell>
                <ScrollTableSpace />
                <ScrollTableHeaderCell flex="0.75">
                  Phone Number
                </ScrollTableHeaderCell>
                <ScrollTableSpace />
                <ScrollTableHeaderCell flex="1">
                  Created At
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="0.75">
                  Role
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="0.5">
                  User ID
                </ScrollTableHeaderCell>
                {/* <ScrollTableHeaderCell flex="1">
                Organzation ID
              </ScrollTableHeaderCell> */}
                <ScrollTableSpace />
                <ScrollTableHeaderCell flex="0.7">
                  Location
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.5">
                  Last Activity
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="0.5">
                  Actions
                </ScrollTableHeaderCell>
              </ScrollTableHeader>
              <ScrollTableBody>
                {users.map((user) => (
                  <ScrollTableBodyRow key={user._id}>
                    <ScrollTableBodyCell flex="1.25">
                      <UserInfo user={user} />
                    </ScrollTableBodyCell>
                    <ScrollTableSpace />
                    <ScrollTableBodyCell flex="0.75">
                      <Text
                        type={TextTypesEnum.Regular14}
                        color={Colors.Brand700 || Colors.Blurple700}
                      >
                        {StringUtil.formatPhoneNumber(user.phoneNumber)}
                      </Text>
                    </ScrollTableBodyCell>
                    <ScrollTableSpace />
                    <ScrollTableBodyCell flex="1">{createTime(user.meta.createdAt)}</ScrollTableBodyCell>
                    <ScrollTableBodyCell flex="0.75">{user.role}</ScrollTableBodyCell>
                    <ScrollTableBodyCell flex="0.5">{user._id}</ScrollTableBodyCell>
                    {/* <ScrollTableBodyCell flex="1">{user.organizationId}</ScrollTableBodyCell> */}
                    <ScrollTableSpace />
                    <ScrollTableBodyCell flex="0.7">
                      {user?.geolocAddress?.state
                        ? `${user?.geolocAddress?.city}, ${user?.geolocAddress?.state}` : user?.geolocAddress?.country
                          ? `${user?.geolocAddress.country}` : 'N/A'}
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell flex="1.5">
                      {user?.latestActivityEvent && (
                        <>
                          {user?.latestActivityEvent?.eventType}
                          {' '}
                          at
                          {' '}
                          {createTime(user?.latestActivityEvent?.createdAt)}
                        </>
                      )}
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell flex="0.5">
                      <Button
                        text="Login"
                        onClick={() => {
                          loginToOrganization({
                            variables: {
                              params: {
                                userId: user._id,
                              },
                            },
                          });
                        }}
                        type={ButtonTypesEnum.Primary}
                        size={ButtonSizesEnum.Small}
                      />
                      <Icon
                        tip={user?.isDeactivated ? 'Re-activate account' : 'De-activate account'}
                        margin="0 0 0 8px"
                        icon={user?.isDeactivated ? Icons.UnlockAltSolid : Icons.LockAltSolid}
                        color={user?.isDeactivated ? Colors.Green400 : Colors.Red400}
                        size={16}
                        onClick={user?.isDeactivated ? () => pushActivateAccount(user._id) : () => pushDeactivateAccount(user._id)}
                      />
                    </ScrollTableBodyCell>
                  </ScrollTableBodyRow>
                ))}
              </ScrollTableBody>
            </ScrollTable>
          </UsersContainer>
        )}
      </Container>
      <LoadMoreWhenInView ref={ref} />
      {infiniteLoading && <InfiniteScroll />}
    </>
  );
};

export default SuperAdminUsersPage;
