import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IChannelQuery from '@biproxi/models/interfaces/IChannelQuery';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Colors from '../../styles/Colors';
import MessageTable, { MessageTableTypesEnum } from '../MessageTable';
import NoContent from '../NoContent';
import Icons from '../../elements/Icons';
import GenericCard from '../../elements/GenericCard';
import Text, { TextTypesEnum } from '../../elements/Text';
import LIST_CHANNELS from '../../graphql/queries/channels.query';
import useUpdateChannels from '../../hooks/useUpdateChannels.hook';
import { media } from '../../utils/MediaQuery';

const Container = styled.div`
  width: 0px; // not sure why i need this.. css is weird
  flex: 1;

  ${media.mobile} {
    width: 100%;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

const Content = styled.div`
  height: 354px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type PrivateEventMessageTableProps = {};

const PrivateEventMessageTable: React.FC<PrivateEventMessageTableProps> = () => {
  /** GraphQL */
  interface Data {
    channels: IChannelGraphQL[];
  }

  interface Vars {
    params: IChannelQuery;
  }
  const { data, loading } = useQuery<Data, Vars>(LIST_CHANNELS, {
    variables: {
      params: {
        pagination: {
          limit: 6,
          cursor: '',
        },
      },
    },
    onError: (error) => {
      logger.error('LIST_CHANNELS error', error);
    },
  });

  const [channels, setChannels] = React.useState(data?.channels || []);

  /** Hooks */
  // const isMobile = useMobileMedia();
  useUpdateChannels({ data, channels, setChannels });

  /** Render */
  return (
    <Container>
      <Text type={TextTypesEnum.Bold18} color={Colors.Black} margin="0px 0px 16px">
        Recent Messages
      </Text>
      <GenericCard margin="0" padding="0" overflow="hidden" borderRadius="8px">
        {loading ? (
          <Content>
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          </Content>
        ) : (
          <>
            {channels?.length > 0 ? (
              <MessageTable
                channels={channels?.slice(0, 6)} // if more channels come in through websocket, only show up to 5 most recent.
                type={MessageTableTypesEnum.Dropdown}
              />
            ) : (
              <Content>
                <NoContent
                  height="100%"
                  icon={Icons.CommentsAltLight}
                  text="No recent messages."
                />
              </Content>
            )}
          </>
        )}
      </GenericCard>
    </Container>
  );
};

export default PrivateEventMessageTable;
