import React from 'react';
import { useRecoilState } from 'recoil';
import DataExplorerDataFrameSectionsEnum from '../@types/data-frame-types/enums/DataExplorerDataFrameSectionsEnum';
import PropertyOwners from './PropertyOwners';
import TransactionHistory from './TransactionHistory';
import { dataFrameGeneralAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';

type DataFrameAddressAndTaxInfoConditionalRendersProps = {
    selectedTaxId: number;
}

/**
 * Within the data frame, there is some data that is only displayed when a user chooses to view all data.
 * Felt kinda silly to have the code for these components in the `DataFrameAddressAndTaxInfo.tsx` file when they can be grouped into a component that is only rendered if a user chooses to expand the data for a property
 */
const DataFrameAddressAndTaxInfoConditionalRenders: React.FC<DataFrameAddressAndTaxInfoConditionalRendersProps> = ({
  selectedTaxId,
}) => {
  /** State / Recoil */
  const [dataFrameGeneralState, _setDataFrameGeneralState] = useRecoilState(dataFrameGeneralAtom);
  const {
    insightDataExpanded,
    dataSettingsSelectedData,
  } = dataFrameGeneralState;

  return (
    <>
      {
        insightDataExpanded && (
          <PropertyOwners
            taxAssessorId={selectedTaxId}
          />
        )
      }
      {
        insightDataExpanded && dataSettingsSelectedData[DataExplorerDataFrameSectionsEnum.TransactionTimeline][0].checked && (
          <TransactionHistory
            taxAssessorId={selectedTaxId}
          />
        )
      }
    </>
  );
};

/**
 * Only want to re-render this component if the selectedTaxId changes
 */
const arePropsEqual = (prevProps, nextProps) => prevProps.selectedTaxId === nextProps.selectedTaxId;

export default React.memo(DataFrameAddressAndTaxInfoConditionalRenders, arePropsEqual);
