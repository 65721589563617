import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import { queryBillingPlans } from '@biproxi/next-api-requests';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import SettingsBillingPlan from '../components/settings/SettingsBillingPlan';
import SettingsBillingPayment from '../components/settings/SettingsBillingPayment';
import { media } from '../utils/MediaQuery';
import Divider from '../elements/Divider';
import SettingsHeader from '../components/settings/SettingsHeader';
import useUser from '../hooks/useUser.hook';
import useUserPermissions from '../hooks/useUserPermissions.hook';

const Container = styled.div`
  flex: 1;
  width: 100%;

  ${media.tablet} {
    margin: 16px;
    max-width: calc(100vw - 32px);
  }

  ${media.mobile} {
    max-width: calc(100vw - 32px);
  }
`;

const Spacer = styled.div`
  height: 16px;
`;

type SettingsBillingPageProps = {};

const SettingsBillingPage: React.FC<SettingsBillingPageProps> = () => {
  /** Hooks */
  const { user } = useUser();
  const { userBillingPlan, loading } = useUserPermissions({ userId: user?._id });

  const { data, isLoading } = useQuery(['billing:plans'], queryBillingPlans, {
    staleTime: 60000,
  });

  /** Render */
  const userOnFreemium: boolean = userBillingPlan === BillingPlanIdEnum.FreemiumMonthly;
  return (
    <Container>
      <Text color={Colors.Black} type={TextTypesEnum.Bold24}>Settings</Text>
      <Spacer />
      <SettingsHeader />
      <SettingsBillingPlan
        user={user}
        userSubscriptionType={userBillingPlan}
        userOnFreemium={userOnFreemium}
        billingPlans={data?.list}
        isDataLoading={isLoading || loading}
      />
      <Divider />
      <SettingsBillingPayment user={user} userOnFreemium={userOnFreemium} />
    </Container>
  );
};

export default SettingsBillingPage;
