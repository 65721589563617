import { logger } from '@biproxi/logger';
import React from 'react';
import { useQuery } from '@apollo/client';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import { useAppSelector } from '../redux/store';
import PRIVATE_EVENT_SUBSCRIPTION from '../graphql/subscriptions/privateEvent.subscription';
import GET_PRIVATE_EVENT from '../graphql/queries/privateEvent.query';
import { PrivateEventSelectors } from '../redux/privateEvent.redux';

type UsePrivateEventSubscription = {
  privateEvent: IPrivateEventGraphQL | null;
  privateEventId: string | null;
  loading: boolean;
  refetch: () => void;
  error: IError | null;
};

type UsePrivateEventSubscriptionHook = (privateEventId?: string) => UsePrivateEventSubscription;

/**
 * Hook to load in a private event
 * and subscribe to updates as they come in.
 */
const usePrivateEventSubscription: UsePrivateEventSubscriptionHook = (privateEventId) => {
  const selectedPrivateEventId = useAppSelector(PrivateEventSelectors.selectedPrivateEventId);
  privateEventId = selectedPrivateEventId ?? privateEventId;

  /** GraphQL */
  const {
    data, loading, error, subscribeToMore, refetch,
  } = useQuery(GET_PRIVATE_EVENT, {
    skip: !privateEventId,
    variables: {
      params: {
        privateEventId,
      },
    },
    context: {
      debounceKey: 'QUERY_PRIVATE_EVENT',
    },
    onError: (error) => {
      logger.error('QUERY_PRIVATE_EVENT error', error);
    },
  });

  /** Effects */
  type SubscriptionData = {
    privateEvent: IPrivateEventGraphQL;
  }

  React.useEffect(() => {
    if (subscribeToMore && privateEventId) {
      subscribeToMore<SubscriptionData>({
        document: PRIVATE_EVENT_SUBSCRIPTION,
        variables: {
          params: {
            privateEventId,
          },
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) { return prev; }
          const { privateEvent } = subscriptionData.data;
          return {
            privateEvent,
          };
        },
      });
    }
  }, [subscribeToMore, privateEventId]);

  if (!privateEventId) {
    return {
      privateEvent: null,
      privateEventId: null,
      loading: true,
      refetch: () => {},
      error: null,
    };
  }

  return {
    privateEvent: data?.privateEvent,
    privateEventId: data?.privateEvent?._id,
    loading,
    refetch,
    error: error ? ApolloUtil.parseApolloClientError(error) : undefined,
  };
};

export default usePrivateEventSubscription;
