import gql from 'graphql-tag';

const ORG_LISTINGS_ANALYTICS = gql`
    query OrganizationListingsAnalytics($params: OrganizationListingsAnalyticsParams) {
        getOrganizationListingsAnalytics(params: $params) {
            graphData
        }
    }
`;

export default ORG_LISTINGS_ANALYTICS;
