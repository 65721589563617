import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import styled from '@emotion/styled';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from '../CenteredPageLayout';
import Colors from '../../styles/Colors';
import PDPPricing from '../pdp/PDPPricing';
import Divider from '../../elements/Divider';
import PDPHeader from '../pdp/PDPHeader';
import PDPImages from '../pdp/PDPImages';
import PDPNavigation from '../pdp/PDPNavigation';
import getFilteredPDPSections from '../../utils/getFilteredPDPSections';
import { media, useMobileMedia } from '../../utils/MediaQuery';

type ListingPageProps = {
  listing: IListingGraphQL;
};

const PageContainer = styled.div`
  display: flex;
  width: fill-available;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const InnerContent = styled.div`
  display: flex;
`;

const ScrollableContent = styled.div`
  max-width: 840px;
  width: fill-available;

  ${media.mobile} {
    width: 100%;
    padding: 16px;
  }
`;

const NavContainer = styled.div``;

const Spacer = styled.div`
  height: 32px;
`;

const ListingPage: React.FC<ListingPageProps> = ({
  listing,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();

  /* Render */
  const sections = getFilteredPDPSections(listing);

  return (
    <CenteredPageLayout
      size={CenteredPageLayoutSizeEnum.PDP}
      background={Colors.White}
    >
      <PageContainer>
        <ContentContainer>
          {isMobile ? (
            <PDPNavigation listing={listing} />
          ) : (
            <PDPHeader listing={listing} isPreviewPDP />
          )}
          <PDPImages listing={listing} />
          <InnerContent>
            <ScrollableContent>
              {isMobile ? (
                <>
                  <PDPHeader listing={listing} />
                  <Divider margin="24px 0px 0px 0px" />
                </>
              ) : (
                <PDPNavigation listing={listing} isPreviewPDP />
              )}
              <Spacer />
              {sections.map((section, index) => (
                <>
                  {section.component}
                  {index !== sections.length - 1 && (
                  <Divider margin="24px 0px 32px 0px" />
                  )}
                </>
              ))}
            </ScrollableContent>
            {!isMobile && (
            <NavContainer>
              <PDPPricing listing={listing} />
            </NavContainer>
            )}
          </InnerContent>
        </ContentContainer>
      </PageContainer>
    </CenteredPageLayout>
  );
};

export default ListingPage;
