import React from 'react';
import styled from '@emotion/styled';
import { NextSeo } from 'next-seo';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
// import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
// import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
// import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
// import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
// import PermissionUtil from '@biproxi/models/utils/PermissionUtil';
// import PermissionTypesEnum from '@biproxi/models/enums/PermissionTypesEnum';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from '../components/CenteredPageLayout';
import CreateListingBasicInformation from '../components/create-listing/CreateListingBasicInformation';
import CreateListingPropertyDetails from '../components/create-listing/CreateListingPropertyDetails';
import CreateListingPricingGuidance from '../components/create-listing/CreateListingPricingGuidance';
import CreateListingSectionsEnum from '../models/enums/CreateListingSectionsEnum';
import CreateListingDescription from '../components/create-listing/CreateListingDescription';
import CreateListingHighlights from '../components/create-listing/CreateListingHighlights';
import CreateListingLicense from '../components/create-listing/CreateListingLicense';
import CreateListingPDPPreview from '../components/create-listing/CreateListingPDPPreview';
import CreateListingDataInsights from '../components/create-listing/CreateListingDataInsights';
import CreateListingContacts from '../components/create-listing/CreateListingContacts';
import CreateListingDueDiligence from '../components/create-listing/CreateListingDueDiligence';
import CreateListingDealProfile from '../components/create-listing/CreateListingDealProfile';
import PageLoader from '../components/PageLoader';
import useListing from '../hooks/useListing.hook';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import { media, useMobileMedia } from '../utils/MediaQuery';
import Button, { ButtonTypesEnum, ButtonSizesEnum } from '../elements/Button';
import Flex from '../elements/Flex';
import { ModalTypesEnum } from '../components/modal/Modal';
import { AppActions } from '../redux/app.redux';
import ListingLoaderTypesEnum from '../models/enums/ListingLoaderTypesEnum';
import ListingScoreCard from '../components/create-listing/ListingScoreCard';
// import UPDATE_LISTING_UPLOAD from '../graphql/mutations/updateListingUpload.mutation';
// import { IToastConfig, ToastTypesEnum } from '../components/Toast';
// import useUser from '../hooks/useUser.hook';
import * as UrlUtil from '../utils/UrlUtil';

const PageContainer = styled.div`
  display: flex;
  width: fill-available;

  ${media.tablet} {
    padding: 16px;
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 70px);
  flex: 1;
`;

const Spacer = styled.div`
  height: 32px;

  ${media.mobile} {
    height: 16px;
  }
`;

type CreateListingPageProps = {};

const CreateListingPage: React.FC<CreateListingPageProps> = () => {
  /* Hooks */
  const isMobile = useMobileMedia();
  const { listingId, listing, loading } = useListing();
  // const { userId, userEmail } = useUser();
  const location = useLocation();
  // const navigate = useNavigate();

  /* State */
  const { query } = UrlUtil.parse(window.location.toString());
  const isListingUploadPreview = query?.preview === 'true';
  const showPreview = useAppSelector(ListingSelectors.showPreview) || isListingUploadPreview;
  const loaders = useAppSelector(ListingSelectors.loading);
  const isScheduled = ListingUtil.isScheduled(listing);
  const isNotPublished = ListingUtil.isNotPublished(listing);
  const isPublishListingLoading = loaders[ListingLoaderTypesEnum.UpdateListing];
  const isUpdateListingLoading = loaders[ListingLoaderTypesEnum.UpdateListing];
  // const isAdmin = listing?.state === ListingStateEnum.Admin || (listing?.user?._id !== userId && PermissionUtil.hasPermission(userEmail, PermissionTypesEnum.Admin));

  /** GraphQL */
  // interface UpdateListingUploadVars {
  //   params: Partial<IListingUploadParams>
  // }

  // interface UpdateListingUploadData {
  //   updateListingUpload: IListingUploadGraphQL
  // }

  // const [updateListingUpload] = useMutation<UpdateListingUploadData, UpdateListingUploadVars>(UPDATE_LISTING_UPLOAD, {
  //   onCompleted: () => {
  //     navigate('/app/admin');
  //   },
  //   onError: () => {
  //     pushToast({
  //       type: ToastTypesEnum.Error,
  //       message: 'There was an error updating the listing upload. Please try again.',
  //     });
  //   },
  // });

  /* Actions */
  const dispatch = useAppDispatch();
  const disablePreview = () => dispatch(ListingActions.setShowPreview({ showPreview: false }));
  const createListing = () => dispatch(ListingActions.createListing({}));
  const updateListing = () => dispatch(ListingActions.updateListing({ listing, toast: true }));
  const clearSelectedListing = () => dispatch(ListingActions.selectListing({ listingId: null }));
  const checkRequiredFields = () => ListingUtil.checkRequiredFields(listing);

  const pushCreateListingActionsModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateListingActions,
      props: {
        listing,
      },
    }),
  );

  // const pushToast = (config: IToastConfig) => dispatch(
  //   AppActions.pushToast(config),
  // );

  const handlePublishListing = () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.publishListing({ listing, publishAt: null, expiresAt: null }),
      );
    } else {
      pushCreateListingActionsModal();
    }
  };

  const handleSaveListingChanges = () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.updateListing({ listing }),
      );
    } else {
      updateListing();
    }
  };

  // const handleAdminSaveListingChanges = async () => {
  //   const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
  //   if (incompleteRequiredFields) {
  //     const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
  //     window.scrollTo({
  //       top: firstIncompleteFieldId?.offsetTop,
  //       behavior: 'smooth',
  //     });
  //     dispatch(
  //       ListingActions.updateListing({ listing }),
  //     );
  //   } else {
  //     await dispatch(
  //       ListingActions.updateListing({ listing: { ...listing, state: ListingStateEnum.ReadyForReview }, toast: true }),
  //     );
  //     updateListingUpload({
  //       variables: {
  //         params: {
  //           listingId: listing._id,
  //           state: ListingUploadStateEnum.WaitingForReview,
  //         },
  //       },
  //     });
  //   }
  // };

  // Disable preview on unmount of Create Listing page
  React.useEffect(() => () => { disablePreview(); }, []);

  /** Effects */
  React.useEffect(() => () => {
    const isCreateListing = location.pathname === '/app/create-listing';
    const isDashboardPage = location.pathname.includes('/app/dashboard/listings/details');

    if (!isCreateListing && !isDashboardPage) {
      clearSelectedListing();
    }
  }, [location]);
  /**
   * Create a new listing if there is
   * not currently a selected listing
   *
   * Clear the selected on unmount
   */
  React.useEffect(() => {
    if (!listingId) {
      createListing();
    }
  }, []);

  /* Render */
  const MenuButton = React.memo(() => (
    <Button
      data-cy="publish-button"
      text={isScheduled ? 'Change schedule' : 'Publish'}
      type={ButtonTypesEnum.Primary}
      size={ButtonSizesEnum.Large}
      isLoading={isPublishListingLoading}
      onClick={handlePublishListing}
      // margin="0 0 12px 0"
    />
  ));

  const SaveButton = React.memo(() => (
    <Button
      text="Save changes"
      onClick={handleSaveListingChanges}
      isLoading={isUpdateListingLoading}
      type={ButtonTypesEnum.Primary}
      size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
    />
  ));

  // const AdminSave = React.memo(() => (
  //   <Button
  //     text="Request review"
  //     onClick={() => handleAdminSaveListingChanges()}
  //     isLoading={isUpdateListingLoading}
  //     type={ButtonTypesEnum.Primary}
  //     size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
  //     isFullWidth
  //   />
  // ));

  return (
    <>
      <NextSeo
        title="Create Listing"
      />
      <PageLoader fade={!loading} />
      {listing && (() => {
        if (showPreview) {
          return <CreateListingPDPPreview listing={listing} />;
        }

        return (
          <CenteredPageLayout size={CenteredPageLayoutSizeEnum.Small}>
            <PageContainer>
              <ContentContainer>
                <CreateListingBasicInformation id={CreateListingSectionsEnum.BasicInformation} listing={listing} hideStatus={listing?.state === ListingStateEnum.ReadyForReview} />
                <Spacer />
                <CreateListingPropertyDetails id={CreateListingSectionsEnum.PropertyDetails} listing={listing} />
                <Spacer />
                <CreateListingPricingGuidance id={CreateListingSectionsEnum.PriceGuidance} listing={listing} />
                <Spacer />
                <CreateListingDataInsights id={CreateListingSectionsEnum.DataInsights} listing={listing} />
                <Spacer />
                <CreateListingHighlights id={CreateListingSectionsEnum.Highlights} listing={listing} />
                <Spacer />
                <CreateListingDescription id={CreateListingSectionsEnum.Description} listing={listing} />
                <Spacer />
                <CreateListingDueDiligence id={CreateListingSectionsEnum.DueDiligence} listing={listing} />
                <Spacer />
                <CreateListingLicense id={CreateListingSectionsEnum.License} listing={listing} />
                <Spacer />
                <CreateListingDealProfile id={CreateListingSectionsEnum.DealProfile} listing={listing} />
                <Spacer />
                <CreateListingContacts id={CreateListingSectionsEnum.Contacts} listing={listing} />
                {!isMobile && (
                  <>
                    <Spacer />
                    <Flex align="flex-start">
                      {
                        isNotPublished ? (
                          <MenuButton />
                        ) : (
                          <SaveButton />
                        )
                      }
                    </Flex>
                  </>
                )}
              </ContentContainer>
              <ListingScoreCard listing={listing} />
            </PageContainer>
          </CenteredPageLayout>
        );
      })()}
    </>
  );
};

export default CreateListingPage;
