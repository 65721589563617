import React from 'react';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { NationalOrGlobalFocusEnum, RegionalFocusEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import AmericanStatesDictionaryUtil from '@biproxi/models/utils/AmericanStatesDictionaryUtil';
import styled from '@emotion/styled';
import CheckboxList from '../../elements/CheckBoxList';
import Checkbox, { CheckboxTypesEnum } from '../../elements/Checkbox';
import { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import Colors from '../../styles/Colors';

const CaretClickContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: auto;
`;

type ProfileRegionalChecklistProps = {
  user: IUserGraphQL;
  region: RegionalFocusEnum;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
  'data-cy'?: string;
};

const ProfileRegionalChecklist: React.FC<ProfileRegionalChecklistProps> = ({
  'data-cy': dataCy,
  user,
  region,
  setUserInvestorProfileField,
}) => {
  /** State */
  const [stateListExpanded, setStateListExpanded] = React.useState<boolean>(false);
  const geoFocuses = user?.investorProfile?.geographicalFocus;
  const nationalOrGlobal = user?.investorProfile?.nationalOrGlobal;
  const nationalFocus = nationalOrGlobal === NationalOrGlobalFocusEnum.National;
  const globalFocus = nationalOrGlobal === NationalOrGlobalFocusEnum.Global;
  const regionActive = Boolean(geoFocuses?.some((state) => AmericanStatesDictionaryUtil.dictionary[region]?.includes(state)));
  const isNotHawaiiOrAlaska = region !== RegionalFocusEnum.Hawaii && region !== RegionalFocusEnum.Alaska;

  /** Actions */
  const addOrRemoveRegion = () => {
    setUserInvestorProfileField({
      geographicalFocus: regionActive
        ? geoFocuses.filter((state) => !AmericanStatesDictionaryUtil.dictionary[region].includes(state))
        : geoFocuses
          ? [...geoFocuses, ...AmericanStatesDictionaryUtil.dictionary[region]]
          : AmericanStatesDictionaryUtil.dictionary[region],
      nationalOrGlobal: nationalFocus ? null
        : globalFocus
          ? null
          : nationalOrGlobal,
    });
  };

  const stateChecklistConfig = isNotHawaiiOrAlaska ? AmericanStatesDictionaryUtil.dictionary[region]?.map((state) => {
    const stateActive = geoFocuses?.includes(state);
    return {
      label: state,
      active: stateActive,
      type: CheckboxTypesEnum.Small,
      onClick: () => {
        setUserInvestorProfileField({
          geographicalFocus: stateActive
            ? geoFocuses.filter((focusState) => focusState !== state)
            : geoFocuses
              ? [...geoFocuses, state]
              : [state],
        });
      },
    };
  }) : null;

  /* Render */
  return (
    <>
      <Flex align="center" height="fit-content" width="90%">
        <Checkbox
          dataCy={dataCy}
          label={region}
          active={Boolean(geoFocuses?.some((state) => AmericanStatesDictionaryUtil.dictionary[region]?.includes(state)))}
          onClick={() => addOrRemoveRegion()}
          type={CheckboxTypesEnum.Large}
          labelTextType={TextTypesEnum.Medium16}
          margin="12px 8px 4px 0px"
        />
        {isNotHawaiiOrAlaska && (
          <CaretClickContainer id="CaretContainer" onClick={() => setStateListExpanded(!stateListExpanded)}>
            <Icon
              icon={Icons.AngleUpRegular}
              rotation={stateListExpanded ? 180 : null}
              size={18}
              color={Colors.Grey400}
            />
          </CaretClickContainer>
        )}
      </Flex>
      {(stateChecklistConfig && stateListExpanded) && (
        <CheckboxList
          config={stateChecklistConfig}
          margin="8px 0px 8px 32px"
        />
      )}
    </>
  );
};

export default ProfileRegionalChecklist;
