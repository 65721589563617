import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import React from 'react';
import { KB_PROFILE_PREFERENCES_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import InvestorPreferences from './InvestorPreferences';

type ProfileInvestorPreferencesProps = {
  user: IUserGraphQL;
};

const ProfileInvestorPreferences: React.FC<ProfileInvestorPreferencesProps> = ({
  user,
}) => (
  <ContentCard
    headerType={ContentCardHeaderTypesEnum.RichHeader}
    title="Investor preferences"
    learnMoreLink={KB_PROFILE_PREFERENCES_URL}
    subtitle="Tell us a bit about your CRE history and preferences."
    preserveBorderRadius
  >
    <InvestorPreferences user={user} />
  </ContentCard>
);
export default ProfileInvestorPreferences;
