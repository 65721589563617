import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { config } from '@biproxi/env';
import { IDisableNylasIntegrationParams } from '@biproxi/models/services/INylasService';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import IUserNylasSettings from '@biproxi/models/interfaces/IUserNylasSettings';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon, { Icons } from '../../elements/Icon';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Divider from '../../elements/Divider';
import Tag from '../../elements/Tag';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { UserActions } from '../../redux/user.redux';
import DISABLE_NYLAS_INTEGRATION from '../../graphql/mutations/disableNylasIntegration.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0px;
  box-sizing: border-box;

  ${media.mobile} {
    padding: 0px;
    flex-direction: column;
    justify-items: flex-start;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const SectionTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 40px 0px 0px;

  ${media.mobile} {
    width: 100%;
    padding: 40px 0px;
  }
`;

const IntegrationFlexItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  width: 100%;
`;

const MobileSpacer = styled.div`
  height: 40px;
`;

const ButtonFlexWrapper = styled.div`
  margin-left: auto;

  ${media.mobile} {
    width: 100%;
    margin: 12px 0px 0px 0px;
  }
`;

const MobileButtonWrapper = styled.div`
  margin-right: auto;
  margin: 12px 0px 0px 0px;
`;

export type SettingsIntegrationsProps = {
    user: IUserGraphQL;
}

const SettingsIntegrations: React.FC<SettingsIntegrationsProps> = ({ user }) => {
  /** State */
  const toastMessage = 'Successfully disabled Nylas integration';
  const toastErrorMessage = 'There as an error disabling Nylas integration';

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useDispatch();

  const startNylasFlow = () => {
    window.open(nylasRedirectURL, '_self');
  };

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const setUserNylasSettings = (nylasSettings: Partial<IUserNylasSettings>) => dispatch(
    UserActions.setUserNylasSettings(nylasSettings),
  );

  /** GraphQL */
  type DisableIntegrationParams = {
    params: IDisableNylasIntegrationParams,
  };
  type DisableIntegrationData = {};

  const [disableNylasIntegration] = useMutation<DisableIntegrationData, DisableIntegrationParams>(DISABLE_NYLAS_INTEGRATION, {
    variables: {
      params: {},
    },
    onCompleted: () => {
      setUserNylasSettings({
        nylasAuthenticated: false,
      });
      pushToast({
        type: ToastTypesEnum.Notification,
        message: toastMessage,
      });
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: toastErrorMessage,
      });
    },
  });

  /** Render */
  const nylasRedirectURL = `https://api.nylas.com/oauth/authorize?client_id=${config.NEXT_PUBLIC_NYLAS_CLIENT_ID}&response_type=code&login_hint=${encodeURIComponent(user?.email)}&redirect_uri=${window.location.origin}/app/dashboard/settings&scopes=email.send`;
  const googleConnected = Boolean(user?.oAuthProviders?.google);
  const linkedinConnected = Boolean(user?.oAuthProviders?.linkedin);
  const nylasConnected = Boolean(user?.nylasSettings?.nylasAuthenticated);

  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Integrations
          </Text>
          {/* <Icon
            icon={Icons.InfoSquareSolid}
            size={14}
            color={Colors.Grey400}
            margin="0px 0px 0px 8px"
          /> */}
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Enrich your Biproxi experience with third-party integrations.
        </Text>
      </SectionTitle>
      <Flex direction="column" width={isMobile ? '100%' : '60%'}>
        <IntegrationFlexItem>
          <Icon
            icon={Icons.Google}
            size={24}
            margin="0px 8px 0px 0px"
            color={Colors.Grey900}
          />
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
            margin="0px 8px 0px 0px"
          >
            Sign in with Google
          </Text>
          <Tag
            text={googleConnected ? 'Verified' : 'Not connected'}
            color={googleConnected ? 'Green' : 'Grey'}
          />
        </IntegrationFlexItem>
        <Divider margin="32px 0px" />
        <IntegrationFlexItem>
          <Icon
            icon={Icons.Linkedin}
            size={24}
            margin="0px 8px 0px 0px"
            color={Colors.Grey900}
          />
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
            margin="0px 8px 0px 0px"
          >
            Sign in with Linkedin
          </Text>
          <Tag
            text={linkedinConnected ? 'Verified' : 'Not connected'}
            color={linkedinConnected ? 'Green' : 'Grey'}
          />
        </IntegrationFlexItem>
        <Divider margin="32px 0px" />
        <Flex direction={isMobile ? 'column' : 'row'}>
          <IntegrationFlexItem>
            <Icon
              icon={Icons.EnvelopeSolid}
              size={24}
              margin="0px 8px 0px 0px"
              color={Colors.Grey900}
            />
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey900}
              margin="0px 8px 0px 0px"
            >
              Share listings to your audience via email
            </Text>
            <Tag
              text={nylasConnected ? 'Verified' : 'Not connected'}
              color={nylasConnected ? 'Green' : 'Grey'}
            />
            {!isMobile && (
              <>
                {nylasConnected ? (
                  <ButtonFlexWrapper>
                    <Button
                      type={ButtonTypesEnum.Outline}
                      size={ButtonSizesEnum.Medium}
                      text="Disable"
                      onClick={() => disableNylasIntegration()}
                    />
                  </ButtonFlexWrapper>
                ) : (
                  <ButtonFlexWrapper>
                    <Button
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Medium}
                      text="Connect Now"
                      onClick={() => startNylasFlow()}
                    />
                  </ButtonFlexWrapper>
                )}
              </>
            )}
          </IntegrationFlexItem>
          {isMobile && (
          <>
            {nylasConnected ? (
              <MobileButtonWrapper>
                <Button
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  text="Disable"
                  onClick={() => disableNylasIntegration()}
                />
              </MobileButtonWrapper>
            ) : (
              <MobileButtonWrapper>
                <Button
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Medium}
                  text="Connect Now"
                  onClick={() => startNylasFlow()}
                />
              </MobileButtonWrapper>
            )}
          </>
          )}
        </Flex>
      </Flex>
      {isMobile && (
        <MobileSpacer />
      )}
    </Container>
  );
};

export default SettingsIntegrations;
