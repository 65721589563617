import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import Text, { TextTypesEnum } from '../elements/Text';
import Loader, { LoaderSizes } from '../elements/Loader';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Colors from '../styles/Colors';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import NotificationsTable from '../components/NotificationTable';
import READ_NOTIFICATIONS from '../graphql/mutations/readNotifications.mutation';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { NotificationActions, NotificationSelectors } from '../redux/notification.redux';
import useNotification from '../hooks/useNotification.hook';
import { media } from '../utils/MediaQuery';

const Container = styled.div`
  ${media.tablet} {
    padding: 16px 16px;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

type DashboardNotificationsProps = {};

const DashboardNotifications: React.FC<DashboardNotificationsProps> = () => {
  /** Hooks */
  const { data, loading, fetchMore } = useNotification();

  /** GraphQL */
  const [readNotifications] = useMutation(READ_NOTIFICATIONS);

  /** Actions */
  const unreadNotificationsRedux = useAppSelector(NotificationSelectors.unreadNotificationsCount);
  const search = useAppSelector(NotificationSelectors.notificationSearch);
  const dispatch = useAppDispatch();
  const clearUnreadNotificationsCount = () => dispatch(
    NotificationActions.setUnreadNotificationsCount({
      unreadNotificationsCount: 0,
    }),
  );
  const setSearch = (notificationSearch: string) => dispatch(
    NotificationActions.setNotificationSearch({
      notificationSearch,
    }),
  );

  /** Effects */
  React.useEffect(() => () => {
    setSearch('');
  }, []);

  /** Render */
  const handleReadNotifications = () => {
    clearUnreadNotificationsCount();
    readNotifications({
      variables: {
        params: {
          readAll: true,
        },
      },
    });
  };

  return (
    <Container>
      <PageHeadingContainer>
        <Text
          data-cy="notification-greeting"
          color={Colors.Black}
          type={TextTypesEnum.Bold24}
        >
          Notifications
        </Text>
        {Boolean(unreadNotificationsRedux) && (
          <Text color={Colors.Brand700 || Colors.Blurple700} onClick={() => handleReadNotifications()}>Mark all as read</Text>
        )}
      </PageHeadingContainer>
      <Input
        margin="24px 0 16px"
        placeholder="Search Notifications"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        clear={() => setSearch('')}
        inputType={InputTypesEnum.Search}
        inputSize={InputSizeEnum.Small}
        width="100%"
      />
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {data?.notifications?.info?.totalCount > 0 ? (
            <>
              {data?.notifications?.data?.length > 0 ? (
                <NotificationsTable
                  fetchMore={fetchMore}
                  notifications={data?.notifications?.data}
                  totalCount={data?.notifications?.info?.totalCount}
                  search={search}
                />
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.SearchRegular}
                  text="No search results. Please try different search terms."
                />
              )}
            </>
          ) : (
            <NoContent
              height="176px"
              icon={Icons.BellLight}
              text="No notifications yet. Notifications you have received will appear here."
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardNotifications;
