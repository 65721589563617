enum CreateListingSectionsEnum {
  BasicInformation = 'BasicInformation',
  PropertyDetails = 'PropertyDetails',
  PriceGuidance = 'PriceGuidance',
  DataInsights = 'DataInsights',
  Highlights = 'Highlights',
  Description = 'Description',
  DueDiligence = 'DueDiligence',
  License = 'License',
  Contacts = 'Contacts',
  DealProfile = 'DealProfile',
}

export default CreateListingSectionsEnum;
