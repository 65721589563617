import React from 'react';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';

type LoginPageProps = {};

const LoginPage: React.FC<LoginPageProps> = () => {
  /** Actions */
  const dispatch = useAppDispatch();
  const pushLoginModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Login,
      props: {
        isStandalone: true,
      },
    }),
  );

  /** Effects */
  React.useEffect(() => {
    pushLoginModal();
  }, []);

  /** Render */
  return (
    <div />
  );
};

export default LoginPage;
