import React from 'react';
import styled from '@emotion/styled';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
// import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icons from '../../elements/Icons';
import BoxShadows from '../../styles/BoxShadows';
import Flex from '../../elements/Flex';
import Icon from '../../elements/Icon';
import useNavigateToPrivateEventManagement from '../../hooks/useNavigateToPrivateEventManagement.hook';
import ColoredTag, { ColoredTagTypesEnum } from '../../elements/ColoredTag';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import useNavigateToPrivateEventParticipation from '../../hooks/useNavigateToPrivateEventParticipation.hook';
import PrivateEventStatusDropdown from './PrivateEventStatusDropdown';
import { media, useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import useUser from '../../hooks/useUser.hook';

const Container = styled.div`
  width: 100%;
  height: 128px;
  background: ${Colors.White};
  margin: 0 0 16px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: ${BoxShadows.Light};
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${Colors.Grey400};
    box-shadow: ${BoxShadows.Standard};
  }

  ${media.mobile} {
    height: auto;
    max-width: calc(100vw - 32px);
  }

  ${media.tablet} {
    height: auto;
    width: 100%;
    /* max-width: calc(100vw - 32px); */
  }
`;

const Image = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  height: 96px;
  width: 146px;
  min-width: 146px;
  border-radius: 4px;
  margin-right: 8px;

  ${media.mobile} {
    width: 100%;
    height: 208px;
    margin-right: 0px;
    min-width: 0px;
  }
`;

const SmallImage = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 25px;
  margin-right: 8px;

  ${media.mobile} {
    width: 100%;
    height: 208px;
    margin-right: 0px;
    min-width: 0px;
  }
`;

const ListingDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const WidthHelper = styled.div`
  width: 100%;
`;

const Dot = styled.span`
  color: ${Colors.Grey400};
`;

const ListingDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 560px;

  /** Weird, but it works */
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${media.mobile} {
    margin-top: 8px;
    width: 100%;
  }
`;

// const StatBox = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 4px;
//   border: 0.5px solid ${Colors.Grey300};
//   padding: 4px 8px;
//   margin-right: 8px;
// `;

const ViewPDPButton = styled.div`
  height: 32px;
  padding: 8px 12px;
  background: ${Colors.White};
  border-radius: 4px;
  border: 0.5px solid ${Colors.Grey300};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;

  margin-left: 8px;

   &:hover {
    border: 0.5px solid ${Colors.Grey400};
   }
`;

const ActionItemContainer = styled.div`
  height: 100%;
`;

const SmallContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: ${Colors.Grey50};
    box-shadow: 0px 0px 0px 8px #F9FAFB;
  }
`;

export enum PrivateEventListingCardTypes {
  Managing = 'Managing',
  Participating = 'Participating',
}

export enum PrivateEventListingCardFormat {
  Small = 'Small',
  Large = 'Large',
}

type PrivateEventListingCardProps = {
  privateEvent: IPrivateEventGraphQL;
  type?: PrivateEventListingCardTypes;
  format?: PrivateEventListingCardFormat;
  invitePending?: boolean;
};

const PrivateEventListingCard: React.FC<PrivateEventListingCardProps> = ({
  privateEvent,
  type = PrivateEventListingCardTypes.Managing,
  invitePending,
  format = PrivateEventListingCardFormat.Large,
}) => {
  /** Hooks */
  const navigateToPrivateEventManagement = useNavigateToPrivateEventManagement();
  const navigateToPrivateEventParticipation = useNavigateToPrivateEventParticipation();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const { userId, user } = useUser();

  /** Actions */
  const dispatch = useAppDispatch();
  const pushAcceptPrivateEventInvitationModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AcceptPrivateEventInvitation,
      props: {
        privateEvent,
      },
    }),
  );

  /** Render */
  const winning = PrivateEventUtil.isUserTopBidder(userId, privateEvent);
  const placedBid = PrivateEventUtil.hasUserPlacedBid(userId, privateEvent);
  const blocked = PrivateEventUtil.getParticipantFromEmail(user?.email, privateEvent)?.disabled;
  const sqFt = privateEvent?.listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value ?? null;
  const showInvitePending = invitePending && (privateEvent?.state === PrivateEventStatesEnum.Upcoming || privateEvent?.state === PrivateEventStatesEnum.InProgress);

  function ActionItems({ margin }: { margin?: string }): React.ReactElement {
    return (
      <Flex margin={margin}>
        <PrivateEventStatusDropdown
          value={privateEvent.state}
        />
        {type === PrivateEventListingCardTypes.Managing && (
        <ViewPDPButton
          onClick={(e) => {
            e?.stopPropagation();
            navigateToPrivateEventParticipation(privateEvent?._id);
          }}
        >
          View PDP
        </ViewPDPButton>
        )}
      </Flex>
    );
  }

  if (format === PrivateEventListingCardFormat.Small) {
    return (
      <SmallContainer onClick={() => {
        if (type === PrivateEventListingCardTypes.Managing) {
          navigateToPrivateEventManagement(privateEvent?._id);
        } else if (invitePending) {
          pushAcceptPrivateEventInvitationModal();
        } else {
          navigateToPrivateEventParticipation(privateEvent?._id);
        }
      }}
      >
        <Flex>
          <SmallImage src={privateEvent?.listing?.media?.files?.[0]?.url} />
          <ListingDetails>
            <Flex width="246px">
              <ListingDetailsText>
                <Text type={TextTypesEnum.Medium14Small} color={Colors.Grey900}>
                  {ListingUtil.name(privateEvent?.listing, { allButAddress1: true })}
                </Text>
                <Flex align="center" margin="4px 0 0">
                  {privateEvent?.listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
                  <Text type={TextTypesEnum.Regular12} color={Colors.Grey700} skWidth={280}>
                    {AddressUtil.formatAddress(privateEvent?.listing?.address, privateEvent?.listing?.name ? {} : { address1: true }) || null}
                  </Text>
                </Flex>
                <Flex margin="4px 0 0" align="center" justify="space-between" width="246px">
                  <PrivateEventStatusDropdown
                    value={privateEvent.state}
                    small
                  />
                  <Text type={TextTypesEnum.Regular12} color={Colors.Grey500}>
                    {TimeUtil.format(privateEvent?.startsAt, 'f', TimeUtil.currentBrowserTimezone())}
                  </Text>
                </Flex>
              </ListingDetailsText>
            </Flex>
          </ListingDetails>
        </Flex>
      </SmallContainer>
    );
  }

  return (
    <Container onClick={() => {
      if (type === PrivateEventListingCardTypes.Managing) {
        navigateToPrivateEventManagement(privateEvent?._id);
      } else if (invitePending) {
        pushAcceptPrivateEventInvitationModal();
      } else {
        navigateToPrivateEventParticipation(privateEvent?._id);
      }
    }}
    >
      <ListingDetails>
        <Image src={privateEvent?.listing?.media?.files?.[0]?.url} />
        <WidthHelper>
          <ListingDetailsText>
            <Flex align="center">
              <Text type={TextTypesEnum.Bold16} color={Colors.Black}>
                {ListingUtil.name(privateEvent?.listing, { allButAddress1: true })}
              </Text>
              {showInvitePending && (
                <ColoredTag
                  type={blocked ? ColoredTagTypesEnum.Red : ColoredTagTypesEnum.Yellow}
                  text={blocked ? 'Disabled' : 'Pending Invitation'}
                  margin="0 0 0 8px"
                />
              )}
              {privateEvent?.state === PrivateEventStatesEnum.Finished && placedBid && (
                <ColoredTag
                  type={winning ? ColoredTagTypesEnum.Green : ColoredTagTypesEnum.Red}
                  text={winning ? 'You won this private event!' : 'You lost this private event'}
                  margin="0 0 0 8px"
                />
              )}
            </Flex>
            <Flex align="center" margin="4px 0 0">
              {privateEvent?.listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey700} skWidth={280}>
                {ListingUtil.isValidPortfolioListing(privateEvent?.listing)
                  ? 'Multiple addresses'
                  : AddressUtil.formatAddress(privateEvent?.listing?.address, privateEvent?.listing?.name ? {} : { address1: true }) || null}
              </Text>
            </Flex>
            {privateEvent?.listing?.assetClass ? (
              <Flex align="center" margin="4px 0 0">
                {privateEvent?.listing?.assetClass}
                <Dot>&nbsp;&bull;&nbsp;</Dot>
                {privateEvent?.listing?.propertyType}
                {Boolean(sqFt) && (
                <>
                  <Dot>&nbsp;&bull;&nbsp;</Dot>
                  {`${StringUtil.formatNumber(sqFt as number)} sq. ft.`}
                </>
                )}
              </Flex>
            ) : null}
            {/* Need websocket on list query for this to work how they want */}
            {/* <Flex margin="8px 0 0">
            <StatBox>
              <Text type={TextTypesEnum.Regular12} color={Colors.Grey500} margin="0 4px 0 0">
                Total bids
              </Text>
              <Text type={TextTypesEnum.Bold12} color={Colors.Grey900}>
                {PrivateEventUtil.getTotalBids(privateEvent)}
              </Text>
            </StatBox>
            <StatBox>
              <Text type={TextTypesEnum.Regular12} color={Colors.Grey500} margin="0 4px 0 0">
                Bidders
              </Text>
              <Text type={TextTypesEnum.Bold12} color={Colors.Grey900}>
                {PrivateEventUtil.getTotalBidders(privateEvent)}
              </Text>
            </StatBox>
          </Flex> */}
            {(isMobile || isTablet) && (
              <ActionItems margin="16px 0 0" />
            )}
          </ListingDetailsText>
        </WidthHelper>
        {!isMobile && !isTablet && (
          <ActionItemContainer>
            <ActionItems margin="0 0 0 16px" />
          </ActionItemContainer>
        )}
      </ListingDetails>
    </Container>
  );
};

export default PrivateEventListingCard;
