import gql from 'graphql-tag';

/** Gets N number of 'similar properties' given certain property params */
const SimilarPropertiesQuery = gql`
    query SimilarProperties ($params: DataExplorerSimilarPropertiesParams!){
        dataExplorerSimilarProperties (params: $params) {
            properties
    }
}`;

export default SimilarPropertiesQuery;
