import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import SecondaryPhoneNumberActionsEnum from '@biproxi/models/enums/SecondaryPhoneNumberActionsEnum';
import Text, { TextTypesEnum } from '../../elements/Text';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { media, useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import Icons from '../../elements/Icons';
import Icon from '../../elements/Icon';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Divider from '../../elements/Divider';
import Tag from '../../elements/Tag';
import Input, { InputTypesEnum } from '../../elements/Input';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import UPDATE_USER_SECONDARY_PHONE_NUMBERS from '../../graphql/mutations/updateUserSecondaryPhoneNumbers.mutation';
import GET_USER from '../../graphql/queries/user.query';
import { UserActions } from '../../redux/user.redux';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0px;
  box-sizing: border-box;

  ${media.mobile} {
    padding: 0px;
    flex-direction: column;
    justify-items: flex-start;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const SectionTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 40px 0px 0px;

  ${media.mobile} {
    padding: 40px 0px;
    width: 100%;
  }
`;

const ContactItemFlex = styled.div`
  display: flex;
  justify-items: center;

  ${media.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonFlexWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;

  ${media.mobile} {
    width: 100%;
    margin: 12px 0px 0px 0px;
  }

  ${media.tablet} {
    width: 100%;
    margin: 12px 0px 0px 0px;
  }
`;

const SecondaryPhoneNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
`;

const SecondaryPhoneNumbersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const AddSecondaryNumberInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;

  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SecondaryPhoneNumberButtonWrapper = styled.div`
  display: flex;
  gap: 8px;

  ${media.mobile} {
    margin: 12px 0;
  }
  ${media.tablet} {
    margin: 12px 0;
  }

`;

const MobileSpacer = styled.div`
  height: 40px;
`;

const SecondaryPhoneNumberActionToastMessageObject = {
  [SecondaryPhoneNumberActionsEnum.Add]: 'The number has been successfully added',
  [SecondaryPhoneNumberActionsEnum.Remove]: 'The number has been successfully removed',
  [SecondaryPhoneNumberActionsEnum.Edit]: 'The number has been successfully updated',
};

type SettingsContactInfoProps = {
  user: IUserGraphQL;
};

const SettingsContactInfo: React.FC<SettingsContactInfoProps> = ({ user }) => {
  /** State */
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [inputtedPhoneNumber, setInputtedPhoneNumber] = React.useState('');
  const [secondaryPhoneNumbers, setSecondaryPhoneNumbers] = React.useState<string[]>([]);
  const [displayedPhoneNumber, setDisplayedPhoneNumber] = React.useState('');
  const [updateSecondaryNumberAction, setUpdateSecondaryNumberAction] = React.useState<SecondaryPhoneNumberActionsEnum>(null);

  const secondaryPhoneNumberTooltip = 'Add a secondary phone number and make it your default number to override your verified phone number on your contact card.';

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushChangePhoneNumberModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangePhoneNumber,
      props: {},
    }),
  );

  const pushChangeEmailAddressModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeEmailAddress,
      props: {},
    }),
  );

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const updateUserSecondaryNumbersRedux = (updatedValue: string[]) => dispatch(
    UserActions.setUserField({ secondaryPhoneNumbers: updatedValue }),
  );

  type UpdateUserSecondaryPhoneNumberPayload = {
    params: {
      phoneNumbers: string[]
    }
  }

  type UpdateUserSecondaryPhoneNumberData = {
    updateUserSecondaryPhoneNumbers: boolean
  }

  /** GraphQL */
  const [updateUserSecondaryPhoneNumber] = useMutation<UpdateUserSecondaryPhoneNumberData, UpdateUserSecondaryPhoneNumberPayload>(UPDATE_USER_SECONDARY_PHONE_NUMBERS, {
    onCompleted: (data) => {
      if (data?.updateUserSecondaryPhoneNumbers) {
        pushToast({
          type: ToastTypesEnum.Notification,
          message: SecondaryPhoneNumberActionToastMessageObject[updateSecondaryNumberAction],
        });
        popModal();
      }
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message: 'An error occurred while updating your secondary phone numbers. Plesae try again.',
      });

      logger.error('Error updating secondary phone numbers', message);
    },
    refetchQueries: [{
      query: GET_USER,
    }],
  });

  /**
   * Functions
   */
  const handleNewSecondaryNumber = async () => {
    setUpdateSecondaryNumberAction(SecondaryPhoneNumberActionsEnum.Add);
    const unmaskedNumber: string = StringUtil.unmaskPhoneNumber(inputtedPhoneNumber);
    if (secondaryPhoneNumbers.includes(unmaskedNumber)) {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'This number is already in your secondary numbers.',
      });
    } else {
      updateUserSecondaryNumbersRedux([...secondaryPhoneNumbers, unmaskedNumber]);
      setSecondaryPhoneNumbers([...secondaryPhoneNumbers, unmaskedNumber]);
      setInputtedPhoneNumber('');
      await updateUserSecondaryPhoneNumber({
        variables: {
          params: {
            phoneNumbers: [...secondaryPhoneNumbers, inputtedPhoneNumber],
          },
        },
      });
    }
  };

  const handleSecondaryPhoneNumbersAction = (phoneNumber: string, actionType: SecondaryPhoneNumberActionsEnum) => {
    setUpdateSecondaryNumberAction(actionType);
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.SecondaryPhoneNumberActions,
        props: {
          actionType,
          verifiedPhoneNumber: user?.phoneNumber,
          phoneNumber,
          displayedPhoneNumber,
          secondaryPhoneNumbers,
          updateUserSecondaryPhoneNumber,
        },
      }),
    );
  };

  /**
   * Effects
   */
  React.useEffect(() => {
    if (user) {
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setSecondaryPhoneNumbers(user?.secondaryPhoneNumbers ?? []);
      setDisplayedPhoneNumber(user?.displayedPhoneNumber ?? '');
    }
  }, [user]);

  /* Render */
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Contact Information
          </Text>
          {/* <Icon
            icon={Icons.InfoSquareSolid}
            size={14}
            color={Colors.Grey400}
            margin="0px 0px 0px 8px"
          /> */}
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Let us know how to get a hold of you.
        </Text>
      </SectionTitle>
      <Flex direction="column" width={isMobile ? '100%' : '60%'}>
        <ContactItemFlex>
          <Flex
            align="center"
            width={isMobile ? '100%' : null}
          >
            <Text
              type={TextTypesEnum.Bold18}
              color={Colors.Grey900}
              margin="0px 8px 0px 0px"
            >
              @
            </Text>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey900}
              margin="0px 8px 0px 0px"
            >
              {email}
            </Text>
            {user?.emailVerifiedAt && (
            <Tag
              text="Verified"
              color="Green"
            />
            )}
          </Flex>
          <ButtonFlexWrapper>
            <Button
              type={ButtonTypesEnum.Outline}
              size={ButtonSizesEnum.Medium}
              text="Change email"
              onClick={() => pushChangeEmailAddressModal()}
            />
          </ButtonFlexWrapper>
        </ContactItemFlex>
        <Divider margin="32px 0px" />
        <ContactItemFlex>
          <Flex
            direction="column"
            width="100%"
          >
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Grey400}
              margin="0px 0px 8px 0px"
            >
              Verified phone number
            </Text>
            <Flex
              align="center"
              width="100%"
              direction={isMobile || isTablet ? 'column' : 'row'}
            >
              <Flex
                align="flex-start"
                width="100%"
              >
                <Icon
                  icon={Icons.MobileLight}
                  color={Colors.Grey900}
                  size={24}
                  margin="0px 8px 0px 0px"
                />
                <Text
                  type={TextTypesEnum.Regular16}
                  color={Colors.Grey900}
                  margin="0px 8px 0px 0px"
                >
                  {StringUtil.formatPhoneNumber(phoneNumber)}
                </Text>
                {user?.phoneNumberVerifiedAt && (
                <Tag
                  text="Verified"
                  color="Green"
                />
                )}
              </Flex>
              <ButtonFlexWrapper>
                <Button
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  text={phoneNumber === displayedPhoneNumber ? 'Default' : 'Make default'}
                  disabled={phoneNumber === displayedPhoneNumber}
                  onClick={() => handleSecondaryPhoneNumbersAction(phoneNumber, SecondaryPhoneNumberActionsEnum.MakeDefault)}
                />
                <Button
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  text="Change phone"
                  onClick={() => pushChangePhoneNumberModal()}
                />
              </ButtonFlexWrapper>
            </Flex>
            <SecondaryPhoneNumbersWrapper>
              <Flex
                align="center"
              >
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey400}
                  margin="0px 0px 8px 0px"
                >
                  Secondary phone numbers
                </Text>
                <Icon
                  icon={Icons.InfoCircleRegular}
                  size={12}
                  margin="0 0 8px 8px"
                  tip={secondaryPhoneNumberTooltip}
                  height="100%"
                  data-multiline
                />
              </Flex>
              {secondaryPhoneNumbers?.length > 0 && (
              <SecondaryPhoneNumbersListWrapper>
                {secondaryPhoneNumbers.map((phoneNumber, index) => (
                  <Flex
                    key={index}
                    margin="0 0 8px 0"
                    justify="space-between"
                    width="100%"
                    direction={isMobile || isTablet ? 'column' : 'row'}
                  >
                    <Text>{StringUtil.formatPhoneNumber(phoneNumber)}</Text>
                    <SecondaryPhoneNumberButtonWrapper>
                      <Button
                        type={ButtonTypesEnum.Outline}
                        size={ButtonSizesEnum.Small}
                        text={phoneNumber === displayedPhoneNumber ? 'Default' : 'Make default'}
                        disabled={phoneNumber === displayedPhoneNumber}
                        onClick={() => handleSecondaryPhoneNumbersAction(phoneNumber, SecondaryPhoneNumberActionsEnum.MakeDefault)}
                      />
                      <Button
                        type={ButtonTypesEnum.Outline}
                        size={ButtonSizesEnum.Small}
                        text="Change number"
                        onClick={() => handleSecondaryPhoneNumbersAction(phoneNumber, SecondaryPhoneNumberActionsEnum.Edit)}
                      />
                      <Button
                        type={ButtonTypesEnum.Outline}
                        size={ButtonSizesEnum.Small}
                        text="Remove"
                        onClick={() => handleSecondaryPhoneNumbersAction(phoneNumber, SecondaryPhoneNumberActionsEnum.Remove)}
                      />
                    </SecondaryPhoneNumberButtonWrapper>
                  </Flex>
                ))}
              </SecondaryPhoneNumbersListWrapper>
              )}

              <AddSecondaryNumberInputWrapper>
                <Input
                  label="Add phone number"
                  placeholder="(XXX) XXX-XXXX"
                  value={inputtedPhoneNumber}
                  onChange={(event) => setInputtedPhoneNumber(event.target.value)}
                  inputType={InputTypesEnum.PhoneNumber}
                  margin="0px 8px 0 0"
                  height="32px"
                  width={isMobile || isTablet ? '100%' : 'calc(100% - 8px)'}
                  autoFocus
                />
                <Button
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  text="Add"
                  margin={isMobile || isTablet ? '12px 0' : '0px'}
                  disabled={!inputtedPhoneNumber}
                  onClick={() => handleNewSecondaryNumber()}
                />
              </AddSecondaryNumberInputWrapper>
            </SecondaryPhoneNumbersWrapper>
          </Flex>
        </ContactItemFlex>
      </Flex>
      {isMobile && (
        <MobileSpacer />
      )}
    </Container>
  );
};

export default SettingsContactInfo;
