import React from 'react';
import styled from '@emotion/styled';
import { Bar } from 'react-chartjs-2';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import { logger } from '@biproxi/logger';
import Text, { TextTypesEnum } from '../../elements/Text';
import { media } from '../../utils/MediaQuery';
// import LISTING_VIEWS_GRAPH_ANALYTICS from '../../graphql/queries/listingViewsGraphAnalytics.query';

type BarChartContainerProps = {
  backgroundColor: string;
}
const BarChartContainer = styled.div<BarChartContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 16px;
  gap: 15px;
  height: 335px;
  /* max-width: 320px; */ // aaron wants them to stretch
  flex: 1 1 0;
  background: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.02);
  border-radius: 8px;

  ${media.mobile} {
    flex: 0;
    max-width: unset;
  }
`;

const BarChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
`;

export enum DashboardBarChartsEnum {
  ListingViews = 'Listing views',
  NewLeads = 'New leads',
  CAsSigned = 'CAs signed',
}

export const DashboardBarChartDataMap = {
  ListingViews: AnalyticsEventTypesEnum.ViewedListing,
  NewLeads: AnalyticsEventTypesEnum.ReceivedLead,
  CAsSigned: AnalyticsEventTypesEnum.ReceivedCA,
};

type DashboardBardChartProps = {
  barChartType: DashboardBarChartsEnum
  granularity?: string;
  graphData: any
}

const DashboardBarChart: React.FC<DashboardBardChartProps> = ({
  barChartType,
  granularity = 'day',
  graphData,
}) => {
  /** State */
  const [totalAnalyticCount, setTotalAnalyticCount] = React.useState<number>(0);

  const barChartColorMap = {
    [DashboardBarChartsEnum.ListingViews]: {
      backgroundColor: '#F0F6FF',
      bodyColor: '#0055DA',
      dataColors: ['#0055DA', '#79AEFF'],
    },
    [DashboardBarChartsEnum.NewLeads]: {
      backgroundColor: '#EAF8ED',
      bodyColor: '#047857',
      dataColors: ['#047857', '#74CC97'],
    },
    [DashboardBarChartsEnum.CAsSigned]: {
      backgroundColor: '#FFF1F9',
      bodyColor: '#A72B74',
      dataColors: ['#A72B74', ' #DF82B9'],
    },
  };

  logger.debug(granularity);

  // const granularityNumberMap = {
  //   day: 1,
  //   week: 7,
  //   month: 30,
  // };

  const bodyColor = barChartColorMap[barChartType].bodyColor;

  const xData = graphData?.map((data) => Object?.keys(data))?.flat();
  const yData = graphData?.map((data) => Object?.values(data))?.flat();

  // console.log({ graphData });
  // console.log({ xData });
  // const parsedXData = xData?.filter((data, index) => index % granularityNumberMap[granularity] === 0);
  // const parsedYData = yData?.filter((data, index) => index % granularityNumberMap[granularity] === 0);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      hover: { mode: null },
    },
    scales: {
      y: {
        // suggestedMin: 0,
        // suggestedMax: 100,
        ticks: {
          beginAtZero: true,
          callback: (value) => {
            if (value % 1 === 0) { return value; }
            return null;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        // ticks: {
        //   callback: (value, index, ticks) => {
        //     console.log({ value });
        //     console.log({ index });
        //     if (ticks?.length > 0) {
        //       console.log({ ticks });
        //     }
        //   },
        // },
      },
    },
  };

  const labels = xData;

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: yData ?? [],
        backgroundColor: barChartColorMap[barChartType].dataColors,
      },
    ],
  };

  React.useEffect(() => {
    if (!totalAnalyticCount) {
      const totalAnalytics = graphData?.reduce((acc: number, curr: object) => {
        acc += Object?.values(curr)?.[0];
        return acc;
      }, 0);
      setTotalAnalyticCount(totalAnalytics);
    }
  }, [graphData]);

  return (
    <BarChartContainer
      backgroundColor={barChartColorMap[barChartType].backgroundColor}
    >
      <Text
        type={TextTypesEnum.Bold16}
        color={bodyColor}
      >
        {barChartType}
      </Text>
      <MetricsContainer>
        <Text
          type={TextTypesEnum.Bold30}
          color={bodyColor}
        >
          {totalAnalyticCount}
        </Text>
        {/* <Text
          type={TextTypesEnum.Regular16}
          color={bodyColor}
        >
          +35%
        </Text> */}
      </MetricsContainer>
      <BarChartWrapper>
        <Bar options={options} data={data} height="100%" />
      </BarChartWrapper>
    </BarChartContainer>
  );
};

export default DashboardBarChart;
