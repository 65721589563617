import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IUnmaskedOwnerData from '@biproxi/models/interfaces/IUnmaskedOwnerData';
import IRecordedOwnerData from '@biproxi/models/interfaces/IRecordedOwnerData';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import IUnmaskedOwnerContactInfo from '@biproxi/models/interfaces/IUnmaskedOwnerContactInfo';
import Colors, { ColorsType } from '../../../styles/Colors';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Icon, { Icons, IconsType } from '../../../elements/Icon';
import Loader from '../../../elements/Loader';
import CHERRE_PROPERTY_OWNERS from '../../../graphql/queries/cherrePropertyOwners.query';
import { useAppDispatch } from '../../../redux/store';
import { AppActions } from '../../../redux/app.redux';
import { ModalTypesEnum } from '../../modal/Modal';
import NoContent from '../../NoContent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const OwnerSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const OwnerDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OwnerInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 12px 16px; */
    /* border: 1px solid ${Colors.Grey300};
    border-radius: 4px; */
    gap: 8px;
`;

const OwnerInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type PropertyOwnerCardProps = {
    ownerName: string;
    address1: string;
    address2: string;
    cardIcon: IconsType;
    cardIconBorderColor?: ColorsType;
    cardIconColor?: ColorsType;
    contactInfo?: IUnmaskedOwnerContactInfo;
};

const PropertyOwnerCard: React.FC<PropertyOwnerCardProps> = ({
  ownerName,
  address1,
  address2,
  cardIcon,
  cardIconBorderColor = Colors.Brand100 || Colors.Blurple100,
  cardIconColor = Colors.Brand700 || Colors.Blurple700,
  contactInfo,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();

  const openContactInfoModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.PropertyOwnerContactInfo,
      props: {
        ownerName,
        contactInfo,
      },
    }),
  );

  return (
    <OwnerInfoWrapper>
      <Icon
        icon={cardIcon}
        color={cardIconColor}
        size={16}
        borderRadius="100px"
        background={cardIconBorderColor}
        width="32px"
        height="32px"
      />
      <OwnerInformationContainer>
        <Text>{StringUtil.capitalizeMultiWordString(ownerName)}</Text>
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>{StringUtil.capitalizeMultiWordString(address1)}</Text>
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>{StringUtil.capitalizeMultiWordString(address2)}</Text>
        {(contactInfo?.phoneNumbers?.length || contactInfo?.emails?.length) && ( // we only care about rendering this button if we have phone number(s) and/or email(s) to display
          <Text type={TextTypesEnum.Medium14} color={Colors.Brand700 || Colors.Blurple700} onClick={() => openContactInfoModal()}>Contact details</Text>
        )}

      </OwnerInformationContainer>
    </OwnerInfoWrapper>
  );
};

enum PropertyOwnerTypeEnum {
  RecordedOwners = 'Recorded owners',
  UnmaskedOwners = 'Unmasked owners',
}

type DataExplorerPropertyOwnersProps = {
  taxAssessorId: number;
};

const DataExplorerPropertyOwners: React.FC<DataExplorerPropertyOwnersProps> = ({
  taxAssessorId,
}) => {
  /** State */
  const [recordedOwners, setRecordedOwners] = React.useState<IRecordedOwnerData[]>([]);
  const [unmaskedOwners, setUnmaskedOwners] = React.useState<IUnmaskedOwnerData[]>([]);
  /** GraphQL */
  interface Data {
    cherrePropertyOwnersData: {
          unmaskedOwners: IUnmaskedOwnerData[],
          recordedOwners: IRecordedOwnerData[]
    }
  }

  interface Vars {
    params: {
        cherreTaxAssessorId: number
    }
  }

  const { data: propertyOwnersData, loading: propertyOwnersLoading } = useQuery<Data, Vars>(CHERRE_PROPERTY_OWNERS, {
    skip: !taxAssessorId,
    variables: {
      params: {
        cherreTaxAssessorId: taxAssessorId,
      },
    },
    onError: (error) => {
      logger.error('CHERRE_PROPERTY_OWNERS error', error);
    },
    onCompleted: () => {
      const {
        cherrePropertyOwnersData: {
          recordedOwners,
          unmaskedOwners,
        },
      } = propertyOwnersData;
      setRecordedOwners(recordedOwners.filter((recordedOwner: IRecordedOwnerData) => recordedOwner?.owner_name !== null));
      setUnmaskedOwners(unmaskedOwners.filter((unmaskedOwner: IUnmaskedOwnerData) => unmaskedOwner?.owner_name !== null));
    },
  });

  if (propertyOwnersLoading) {
    return (
      <Container>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Brand700 || Colors.Blurple700}
        >
          Property Owners
        </Text>
        <LoaderContainer>
          <Loader color={Colors.Brand400 || Colors.Blurple400} />
        </LoaderContainer>
      </Container>
    );
  }

  if (!propertyOwnersData) {
    return (
      <Container>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Brand700 || Colors.Blurple700}
        >
          Property Owners
        </Text>
        <NoContent
          height="fit-content"
          icon={Icons.ExclamationTriangleSolid}
          text="There is no unmasked owner information available for this property"
          iconSize="16"
          iconBorderBackground={Colors.Grey200}
          iconBorderRadius="100px"
          iconBorderWidth="32px"
          iconBorderHeight="32px"
          iconMargin="0 10px 0 0"
          flexDirection="row"
        />
      </Container>
    );
  }

  if (propertyOwnersData && Object?.keys(propertyOwnersData)?.length) {
    return (
      <Container>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Brand700 || Colors.Blurple700}
        >
          Property Owners
        </Text>
        {
          Object.keys(PropertyOwnerTypeEnum).map((key: PropertyOwnerTypeEnum, index: number) => {
            const propertyOwnerType = PropertyOwnerTypeEnum[key];
            const propertyOwnerData = propertyOwnerType === PropertyOwnerTypeEnum.RecordedOwners ? recordedOwners : unmaskedOwners;
            return (
              <OwnerSectionContainer key={index}>
                <Text
                  type={TextTypesEnum.Regular12}
                  color={Colors.Grey500}
                >
                  {propertyOwnerType}
                </Text>
                <OwnerDataWrapper>
                  {
                    propertyOwnerData?.length ? (
                      propertyOwnerData?.map((ownerData, index) => {
                        const oneLineAddress = propertyOwnerType === PropertyOwnerTypeEnum.RecordedOwners ? ownerData?.tax_assessor__tax_assessor_id?.one_line_address_mailing : ownerData?.mailing_one_line_address;
                        const {
                          address1, city, state, zip,
                        } = AddressUtil.parseOneLineAddress(oneLineAddress);
                        return (
                          <PropertyOwnerCard
                            key={index}
                            ownerName={ownerData?.owner_name}
                            address1={address1}
                            address2={`${city}, ${state} ${zip}`}
                            cardIcon={propertyOwnerType === PropertyOwnerTypeEnum.RecordedOwners ? Icons.FileCertificateRegular : Icons.BuildingRegular}
                            cardIconColor={propertyOwnerType === PropertyOwnerTypeEnum.RecordedOwners ? Colors.Brand700 || Colors.Blurple700 : Colors.Black}
                            cardIconBorderColor={propertyOwnerType === PropertyOwnerTypeEnum.RecordedOwners ? Colors.Brand100 || Colors.Blurple100 : Colors.Grey200}
                          />
                        );
                      })
                    ) : (
                      <NoContent
                        height="fit-content"
                        icon={Icons.ExclamationTriangleSolid}
                        text={`There is no ${propertyOwnerType.toLowerCase().substring(0, propertyOwnerType.length - 1)} information available for this property`}
                        iconSize="16"
                        iconBorderBackground={Colors.Grey200}
                        iconBorderRadius="100px"
                        iconBorderWidth="32px"
                        iconBorderHeight="32px"
                        iconMargin="0 10px 0 0"
                        flexDirection="row"
                      />
                    )

                  }
                </OwnerDataWrapper>
              </OwnerSectionContainer>
            );
          })
        }
      </Container>
    );
  }

  return null;
};

export default DataExplorerPropertyOwners;
