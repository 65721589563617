import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IPropertyRecorderData from '@biproxi/models/interfaces/IPropertyRecorderData';
import IPropertyMortgageData from '@biproxi/models/interfaces/IPropertyMortgageData';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../../../styles/Colors';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Loader from '../../../elements/Loader';
import PROPERTY_TRANSACTIONS from '../../../graphql/queries/cherrePropertyTransactions.query';
import Tag from '../../../elements/Tag';
import NoContent from '../../NoContent';
import Icons from '../../../elements/Icons';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PropertyTransactionTypeContainer = styled.div`
    display: flex;
    gap: 8px;
`;

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
`;

const Transaction = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

type DataLabelProps = {
    transactionType?: PropertyTransactionTypeEnum // probably extend this into its own component and then make this a bg color props and do the logic to determine bg color in the main component below
}

const DataLabel = styled.div<DataLabelProps>`
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) => (props.transactionType === PropertyTransactionTypeEnum.Transaction ? Colors.Green100 : Colors.Yellow100)};
`;

const TransactionInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

enum PropertyTransactionTypeEnum {
    All = 'All',
    Transaction = 'Transaction',
    Mortgage = 'Mortgage',
}

interface IGeneralTransactionDate {
  year: string;
  month: string;
  day: string;
}

interface IGeneralTransactionData {
  type: PropertyTransactionTypeEnum;
  date: IGeneralTransactionDate;
  lendorName: string;
  lendeeName: string;
  price: string;
}

type DataExplorerTransactionHistoryProps = {
  taxAssessorId: number;
}

const DataExplorerTransactionHistory: React.FC<DataExplorerTransactionHistoryProps> = ({
  taxAssessorId,
}) => {
  /** State */
  const [activeTransactionType, setActiveTransactionType] = React.useState<PropertyTransactionTypeEnum>(PropertyTransactionTypeEnum.All);
  const [allTransactionData, setAllTransactionData] = React.useState<IGeneralTransactionData[]>([]);

  const transactionYears: string[] = [];

  const transactionTypeColors = {
    All: {
      tagColor: Colors.Grey200,
      borderColor: Colors.Grey500,
    },
    Transaction: {
      tagColor: Colors.Green100,
      borderColor: Colors.Green500,
    },
    Mortgage: {
      tagColor: Colors.Yellow100,
      borderColor: Colors.Yellow500,
    },
  };

    /** GraphQL */
    interface Data {
        cherrePropertyTransactionData: {
            propertyTransactionData: IPropertyRecorderData[],
            propertyMortgageData: IPropertyMortgageData[]
        }
    }

    interface Vars {
        params: {
            cherreTaxAssessorId: number
        }
    }

    const { data, loading: transactionDataLoading } = useQuery<Data, Vars>(PROPERTY_TRANSACTIONS, {
      skip: !taxAssessorId,
      variables: {
        params: {
          cherreTaxAssessorId: taxAssessorId,
        },
      },
      onError: (error) => {
        logger.error('PROPERTY_TRANSACTIONS error', error);
      },
      onCompleted: () => {
        const {
          cherrePropertyTransactionData,
        } = data;

        // Transform all the transaction data into a form that can intermingle with the mortgage data
        const transformedTransactionData = cherrePropertyTransactionData?.propertyTransactionData?.map((transaction: IPropertyRecorderData) => {
          const transactionDate = parseStringDate(transaction?.document_recorded_date);
          return {
            type: PropertyTransactionTypeEnum.Transaction,
            date: transactionDate,
            lendorName: transaction?.recorder_grantor__recorder_id[0]?.grantor_name,
            lendeeName: transaction?.recorder_grantee__recorder_id[0]?.grantee_name,
            price: StringUtil.formatNumber(transaction?.document_amount),
          };
        });

        // Transform all the mortgage data into a form that can intermingle with the transaction data
        const transformedMortgageData = cherrePropertyTransactionData?.propertyMortgageData?.map((mortgage: IPropertyMortgageData) => {
          const transactionDate = parseStringDate(mortgage?.document_recorded_date);
          return {
            type: PropertyTransactionTypeEnum.Mortgage,
            date: transactionDate,
            lendorName: mortgage?.lender_name,
            lendeeName: mortgage?.recorder__recorder_id?.recorder_grantee__recorder_id[0]?.grantee_name,
            price: StringUtil.formatNumber(mortgage?.amount),
          };
        });

        // combine the transaction data with the mortgage data into a single array, sort that array by year, and remove entries that have null date and price
        setAllTransactionData([...transformedTransactionData, ...transformedMortgageData]
          .sort((a, b) => b.date?.year?.localeCompare(a.date?.year))
          .filter((item) => item?.date !== null && item?.price !== '0'));
      },
    });

    // Takes a single string date in the form '2022-01-18' and breaks it up into its year, month, and day components
    const parseStringDate = (date: string) => {
      if (!date) return null;
      const splitDate = date.split('-');
      const year = splitDate[0];
      const month = TimeUtil.NumericDateToShortString[splitDate[1]];
      const day = splitDate[2];

      return { year, month, day };
    };

    // Function that determines if one the year of a transaction has already been rendered for another transaction
    const determineRepeatedYear = (year: string) => {
      if (!transactionYears.includes(year)) {
        transactionYears.push(year);
        return year;
      }
      return null;
    };

    const finalTransactionData = activeTransactionType === PropertyTransactionTypeEnum.All ? allTransactionData : allTransactionData?.filter((item) => item.type === activeTransactionType);

    /** Render */
    if (transactionDataLoading) {
      return (
        <Container>
          <Text
            type={TextTypesEnum.Bold16}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            Timeline
          </Text>
          <LoaderContainer>
            <Loader color={Colors.Brand400 || Colors.Blurple400} />
          </LoaderContainer>
        </Container>
      );
    }

    if (!allTransactionData?.length && !transactionDataLoading) {
      return (
        <Container>
          <Text
            type={TextTypesEnum.Bold16}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            Timeline
          </Text>
          <NoContent
            height="fit-content"
            icon={Icons.ExclamationTriangleSolid}
            text="There is no transaction information available for this property"
            iconSize="16"
            iconBorderBackground={Colors.Grey200}
            iconBorderRadius="100px"
            iconBorderWidth="32px"
            iconBorderHeight="32px"
            iconMargin="0 10px 0 0"
            flexDirection="row"
          />
        </Container>
      );
    }

    if (allTransactionData && allTransactionData?.length) {
      return (
        <Container>
          <Text
            type={TextTypesEnum.Bold16}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            Timeline
          </Text>
          <PropertyTransactionTypeContainer>
            {Object.keys(PropertyTransactionTypeEnum).map((item: PropertyTransactionTypeEnum, index: number) => (
              <Tag
                key={index}
                text={item}
                customTagColor={transactionTypeColors[item].tagColor}
                borderColor={transactionTypeColors[item].borderColor}
                    // padding="8px 12px"
                width="fit-content"
                onClick={() => setActiveTransactionType(item)}
                active={item === activeTransactionType}
                hover
              />
            ))}
          </PropertyTransactionTypeContainer>

          <TimelineContainer>
            {finalTransactionData?.map((transactionData: IGeneralTransactionData, index: number) => {
              const transactionYear = determineRepeatedYear(transactionData?.date?.year);
              return (
                <React.Fragment key={index}>
                  {transactionYear !== null && (
                  <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>{transactionYear}</Text>
                  )}
                  <Transaction key={index}>
                    <DataLabel transactionType={transactionData.type}>
                      <Text type={TextTypesEnum.Medium10}>{transactionData.date?.month}</Text>
                      <Text type={TextTypesEnum.Medium10}>{transactionData.date?.day}</Text>
                    </DataLabel>
                    <TransactionInfo>
                      <Text type={TextTypesEnum.Medium14}>
                        {transactionData?.price ? (
                          `$${StringUtil.formatNumber(transactionData?.price)}`
                        ) : (
                          'Price not specified'
                        )}
                      </Text>
                      <Text type={TextTypesEnum.Regular12} color={Colors.Grey700}>
                        {StringUtil.capitalizeMultiWordString(transactionData?.lendorName)}
                        {' '}
                        →
                        {' '}
                        {StringUtil.capitalizeMultiWordString(transactionData?.lendeeName)}
                      </Text>
                    </TransactionInfo>
                  </Transaction>
                </React.Fragment>
              );
            })}
          </TimelineContainer>
        </Container>
      );
    }

    return null;
};

export default DataExplorerTransactionHistory;
