import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import { Icons } from '../elements/Icon';
import SquareIconButton from '../elements/SquareIconButton';
import BoxShadows from '../styles/BoxShadows';
import AnchoredMenu from '../elements/AnchoredMenu';

const rectangleId = 'rectangleId';

const Container = styled.div`
  width: 100%;
  background: ${Colors.White};
  border-radius: 4px;
  border: 1px solid ${Colors.Grey300};
  display: flex;
  position: relative;
  padding: 8px;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s;
  height: 100%;

  &:hover {
    border: 1px solid ${Colors.Grey400};
    box-shadow: ${BoxShadows.Light};

    #rectangleId {
      background: ${Colors.Brand700 || Colors.Blurple700};
    }
  }

  &:active {
    border: 1px solid ${Colors.Grey400};
    background: ${Colors.Grey100};
    box-shadow: ${BoxShadows.Darker};

    #rectangleId {
      background-color: ${Colors.Brand700 || Colors.Blurple700};
    }
  }
`;

const Rectangle = styled.div`
  background: ${Colors.Grey300};
  border-radius: 2px;
  width: 4px;
  height: 100%;
  margin: 0px 12px 0px 0px;
  transition: all 0.2s;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuContainer = styled.div`
  z-index: 3;
  position: fixed;
`;

const MenuRef = styled.div``;

type DraggableListItemProps = {
  children: React.ReactNode;
  menuItems: any[];
};

const DraggableListItem: React.FC<DraggableListItemProps> = ({
  children,
  menuItems,
}) => {
  const ref = React.useRef(null);
  return (
    <>
      <Container>
        <Rectangle id={rectangleId} />
        {children}
        <ButtonContainer>
          <MenuRef ref={ref}>
            <SquareIconButton
              icon={Icons.EllipsisHRegular}
              onClick={() => null}
            />
          </MenuRef>
        </ButtonContainer>
      </Container>
      <MenuContainer>
        <AnchoredMenu
          anchorRef={ref}
          menuItems={menuItems}
        />
      </MenuContainer>
    </>
  );
};

export default DraggableListItem;
