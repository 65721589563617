import React from 'react';
import styled from '@emotion/styled';
import { NextSeo } from 'next-seo';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import CreateListingPropertyDetails from '../create-listing/CreateListingPropertyDetails';
import CreateListingSectionsEnum from '../../models/enums/CreateListingSectionsEnum';
import CreateListingDescription from '../create-listing/CreateListingDescription';
import CreateListingHighlights from '../create-listing/CreateListingHighlights';
import CreateListingLicense from '../create-listing/CreateListingLicense';
import CreateListingPDPPreview from '../create-listing/CreateListingPDPPreview';
import CreateListingDataInsights from '../create-listing/CreateListingDataInsights';
import PageLoader from '../PageLoader';
import useListing from '../../hooks/useListing.hook';
import CreateListingDueDiligence from '../create-listing/CreateListingDueDiligence';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { media } from '../../utils/MediaQuery';
import CreateListingBasicInformation from '../create-listing/CreateListingBasicInformation';
import CreateListingContacts from '../create-listing/CreateListingContacts';
import { PrivateEventActions } from '../../redux/privateEvent.redux';

const PageContainer = styled.div`
  display: flex;
  width: fill-available;

  ${media.tablet} {
    padding: 16px;
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 70px);
  flex: 1;
`;

const Spacer = styled.div`
  height: 32px;

  ${media.mobile} {
    height: 16px;
  }
`;

type CreatePrivateEventListingProps = {};

const CreatePrivateEventListing: React.FC<CreatePrivateEventListingProps> = () => {
  /* Hooks */
  // const isMobile = useMobileMedia();
  const { listingId, listing, loading } = useListing();

  /* State */
  const showPreview = useAppSelector(ListingSelectors.showPreview);

  /* Actions */
  const dispatch = useAppDispatch();
  const disablePreview = () => dispatch(ListingActions.setShowPreview({ showPreview: false }));
  const createListing = () => dispatch(ListingActions.createListing({}));
  const setPrivateEventField = (fields: Partial<IPrivateEventGraphQL>) => dispatch(
    PrivateEventActions.setPrivateEventField(fields),
  );

  // Disable preview on unmount of Create Listing page - what is this for?
  React.useEffect(() => () => { disablePreview(); }, []);

  /**
   * Create a new listing if there is
   * not currently a selected listing
   */
  React.useEffect(() => {
    if (!listingId) {
      createListing();
    }
    return () => {
      setPrivateEventField({ listingId: null });
    };
  }, []);

  /**
   * Set id on private event
   */
  React.useEffect(() => {
    setPrivateEventField({ listingId });
  }, [listingId]);

  return (
    <>
      <NextSeo
        title="Create Listing"
      />
      <PageLoader fade={!loading} />
      {listing && (() => {
        if (showPreview) {
          return <CreateListingPDPPreview listing={listing} />;
        }

        return (
          <PageContainer>
            <ContentContainer>
              <CreateListingBasicInformation id={CreateListingSectionsEnum.BasicInformation} listing={listing} hideStatus hidePrivateListing />
              <Spacer />
              <CreateListingPropertyDetails id={CreateListingSectionsEnum.PropertyDetails} listing={listing} />
              <Spacer />
              <CreateListingDataInsights id={CreateListingSectionsEnum.DataInsights} listing={listing} />
              <Spacer />
              <CreateListingHighlights id={CreateListingSectionsEnum.Highlights} listing={listing} />
              <Spacer />
              <CreateListingDescription id={CreateListingSectionsEnum.Description} listing={listing} />
              <Spacer />
              <CreateListingDueDiligence id={CreateListingSectionsEnum.DueDiligence} listing={listing} />
              <Spacer />
              <CreateListingLicense id={CreateListingSectionsEnum.License} listing={listing} />
              <Spacer />
              <CreateListingContacts id={CreateListingSectionsEnum.Contacts} listing={listing} />
            </ContentContainer>
          </PageContainer>
        );
      })()}
    </>
  );
};

export default CreatePrivateEventListing;
