import gql from 'graphql-tag';
import UserFragments from '../fragments/user.fragments';

const UPDATE_USER_NOTIFICATIONS = gql`
  mutation UpdateUserNotifications($params: UpdateUserNotificationsParams!) {
      updateUserNotifications (params: $params) {
      ...UserFields
      ...UserAddress
      ...UserInvestorProfile
      ...UserLicenses
      ...UserProfileImageFile
      ...UserOrganization
      ...UserOAuthProviders
      ...UserNotificationSettings
    }
  }

  ${UserFragments.fields}
  ${UserFragments.address}
  ${UserFragments.investorProfile}
  ${UserFragments.licenses}
  ${UserFragments.profileImageFile}
  ${UserFragments.organization}
  ${UserFragments.oAuthProviders}
  ${UserFragments.notificationSettings}
`;

export default UPDATE_USER_NOTIFICATIONS;
