import { gql } from '@apollo/client';

const UPDATE_PRIVATE_EVENT_PARTICIPANT = gql`
  mutation UpdatePrivateEventParticipant($params: UpdatePrivateEventParticipantParams!) {
    updatePrivateEventParticipant(params: $params) {
      _id
    }
  }
`;

export default UPDATE_PRIVATE_EVENT_PARTICIPANT;
