import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { KB_CREATE_LISTING_DD_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import CreateListingVaultSection from './CreateListingVaultSection';
import CreateListingCAUpload from './CreateListingCAUpload';
import Divider from '../../elements/Divider';

type CreateListingDueDiligenceProps = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingDueDiligence: React.FC<CreateListingDueDiligenceProps> = ({
  id,
  listing,
}) => (
  <ContentCard
    id={id}
    dataCy="due-diligence-greeting"
    title="Due Diligence"
    subtitle="Organize property Due Diligence in a secured data room."
    learnMoreLink={KB_CREATE_LISTING_DD_URL}
    headerType={ContentCardHeaderTypesEnum.RichHeader}
  >
    <CreateListingCAUpload listing={listing} />
    <Divider margin="32px 0px" />
    {listing.vault.folders.map((rootFolder, index) => (
      <CreateListingVaultSection
        key={index}
        listing={listing}
        rootFolder={rootFolder}
      />
    ))}
  </ContentCard>
);
export default CreateListingDueDiligence;
