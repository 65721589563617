import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Icon, { Icons } from '../elements/Icon';
import { media, useMobileMedia } from '../utils/MediaQuery';
import useCreateListing from '../hooks/useCreateListing.hook';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import SquareIconButton from '../elements/SquareIconButton';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import NextUtil from '../utils/NextUtil';
import DashboardMyListings from '../components/DashboardMyListings';
import PageViewToggleButton from '../elements/PageViewToggleButton';
import DashboardTeamListings from '../components/DashboardTeamListings';
import { UserSelectors } from '../redux/user.redux';

const Container = styled.div`
  ${media.tablet} {
    margin: 16px 16px;
  }
`;

type DashboardListingsPageProps = {};

const DashboardListingsPage: React.FC<DashboardListingsPageProps> = () => {
  /** State */
  const [isOnMyListingsView, setIsOnMyListingsView] = React.useState(true);

  /** Hooks */
  const isMobile = useMobileMedia();
  const createListing = useCreateListing();

  /** Actions */
  const dispatch = useAppDispatch();
  const pushUploadListingModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.UploadListing,
    props: {},
  }));

  const openBillingPaywallModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.OMUpload,
      },
    }),
  );

  const currentOrganizationContext = useAppSelector(UserSelectors.currentOrganizationContext);
  const teamId = currentOrganizationContext?.orgId;

  const {
    user: {
      userPermissions,
    },
  } = useAppSelector((state: AppState) => state);

  const canUserUploadOM = PermissionsUtil.canUserUploadOM(userPermissions?.userBiproxiRolesAndPermissions);

  /* Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /** Render */
  const uploadOMTooltipText = 'Get started by uploading an offering memorandum and our team will create your listing for you, free of charge. This process can take up to 48 hours to complete.';
  return (
    <Container>
      <Flex direction="row" align="center" justify="space-between" margin="0 0 24px">
        <Text data-cy="my-listings-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>Listings</Text>
        <Flex>
          {isMobile ? (
            <>
              <SquareIconButton
                icon={Icons.PlusRegular}
                backgroundColor={Colors.Brand700 || Colors.Blurple700}
                iconColor={Colors.White}
                onClick={() => createListing()}
              />
              <SquareIconButton
                icon={Icons.FileUploadRegular}
                backgroundColor={Colors.White}
                iconColor={Colors.Grey900}
                margin="0 0 0 4px"
                onClick={() => pushUploadListingModal()}
              />
            </>
          ) : (
            <>
              <Button
                text="Create listing"
                data-cy="create-listings-button"
                type={ButtonTypesEnum.Primary}
                size={ButtonSizesEnum.Small}
                onClick={() => createListing()}
              />
              <Button
                text="Submit OM"
                type={ButtonTypesEnum.Outline}
                size={ButtonSizesEnum.Small}
                margin="0 0 0 4px"
                onClick={() => (canUserUploadOM ? pushUploadListingModal() : openBillingPaywallModal())}
              />
              <Icon
                icon={Icons.InfoCircleRegular}
                size={12}
                margin="0 0 0 4px"
                tip={uploadOMTooltipText}
                data-multiline
              />
            </>
          )}
        </Flex>
      </Flex>
      {teamId && (
        <Flex margin="0 0 24px">
          <PageViewToggleButton
            onClick={() => setIsOnMyListingsView(true)}
            text="My listings"
            active={isOnMyListingsView}
            margin="0 8px 0 0"
          />
          <PageViewToggleButton
            onClick={() => setIsOnMyListingsView(false)}
            text="All team listings"
            active={!isOnMyListingsView}
          />
        </Flex>
      )}
      {isOnMyListingsView ? (
        <DashboardMyListings />
      ) : (
        <DashboardTeamListings />
      )}
    </Container>
  );
};

export default DashboardListingsPage;
