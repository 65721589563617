import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';

export enum GenericToggleTextAlignEnum {
  Left = 'Left',
  Right = 'Right',
}

type ContainerProps = {
  margin: string;
  textAlign?: string;
}

const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: ${(props) => (props.textAlign === 'Left' ? 'row' : 'row-reverse')};
`;

type ToggleWrapperProps = {
  margin?: string;
  disabled: boolean;
  onChange?: () => void;
}

const ToggleWrapper = styled.div<ToggleWrapperProps>`
  margin: ${(props) => props.margin || '0px'};

  &:hover {
    cursor: ${(props) => (props.disabled ? null : 'pointer')};
  }
`;

type ToggleOuterCircleProps = {
  active?: boolean;
  disabled?: boolean;
}

const ToggleOuterCircle = styled.div<ToggleOuterCircleProps>`
  position: relative;
  height: 24px;
  width: 40px;
  border-radius: 16px;
  background-color: ${(props) => (props.active ? `${Colors.Green500}` : `${Colors.Grey400}`)};
`;

type ToggleInnerCircleProps = {
  active?: boolean;
}

const ToggleInnerCircle = styled.div<ToggleInnerCircleProps>`
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  left: ${(props) => (props.active ? '20px' : '4px')};
  top: 4px;
  background-color: ${Colors.White};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
`;

type GenericToggleProps = {
  customText?: string;
  noText?: boolean;
  value?: boolean;
  margin?: string;
  textAlign?: string;
  dataCy?: string;
  disabled?: boolean;
  onChange?: () => void;
}

const GenericToggle: React.FC<GenericToggleProps> = ({
  customText = '',
  noText = false,
  value,
  margin,
  textAlign,
  disabled = false,
  onChange,
  dataCy = 'data-cy',
}) => (
  <Container textAlign={textAlign} margin={margin}>
    {value ? (
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Green500}
      >
        {noText ? '' : customText || 'Enabled'}
      </Text>
    ) : (
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey400}
      >
        {noText ? '' : customText || 'Disabled'}
      </Text>
    )}
    <ToggleWrapper
      data-cy={dataCy}
      margin={textAlign === 'Left' ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}
      onClick={() => onChange()}
      disabled={disabled}
    >
      <ToggleOuterCircle active={value && !disabled} disabled={disabled}>
        <ToggleInnerCircle active={value} />
      </ToggleOuterCircle>
    </ToggleWrapper>
  </Container>
);

export default GenericToggle;
