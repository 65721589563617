import React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import Colors from '../styles/Colors';
import FilterButton from '../elements/FilterButton';
import DropdownList from './DropdownList';
import Icon, { Icons } from '../elements/Icon';

/**
 * TODO
 * This component copies a lot from Dropdown
 * It would be ncie to refactor them both to do
 * more code sharing but this will work in the meantime
 */

enum Options {
  MostRecent = 'Most recent',
  LeastRecent = 'Least recent',
}

type ListingCreatedAtSortDowndownProps = {
  value: number | null;
  onChange: (sortBy: number) => void;
}

const ListingCreatedAtSortDowndown: React.FC<ListingCreatedAtSortDowndownProps> = ({
  value,
  onChange,
}) => {
  /** State */
  const [isOpen, setIsOpen] = React.useState(false);
  const sortButtonRef = React.useRef<HTMLDivElement>();

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const items = [
    {
      name: Options.MostRecent,
      value: -1,
    },
    {
      name: Options.LeastRecent,
      value: 1,
    },
  ];

  React.useEffect(() => {
    if (items?.length === 0) {
      setIsOpen(false);
    }
  }, [items]);

  /** Actions */
  const saveAll = (sortBy: number) => {
    setIsOpen(false);
    onChange(sortBy);
  };

  /** Render */
  return (
    <div ref={ref}>
      <FilterButton
        ref={sortButtonRef}
        value={items.find((item) => item.value === value).name}
        onClick={() => setIsOpen(true)}
        icon={<Icon icon={Icons.AngleDownRegular} color={Colors.Grey900} size={10} margin="0 0 0 8px" />}
        margin="0 0 0 4px"
        height="40px"
      />
      <DropdownList
        ref={sortButtonRef}
        value={value.toString()}
        open={isOpen}
        setOpen={setIsOpen}
        onChange={(value) => saveAll(value)}
        items={items}
        width="fit-content"
        right
      />
    </div>
  );
};

export default ListingCreatedAtSortDowndown;
