import React from 'react';
import { PreferredInvestmentTypeEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { TextTypesEnum } from '../../elements/Text';
import CheckboxList from '../../elements/CheckBoxList';
import { CheckboxTypesEnum } from '../../elements/Checkbox';

type ProfilePreferredInvestmentQuestionProps = {
  user: IUserGraphQL;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const ProfilePreferredInvestmentQuestion: React.FC<ProfilePreferredInvestmentQuestionProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  const config = Object.values(PreferredInvestmentTypeEnum).map((item) => {
    const preferredInvestments = user?.investorProfile?.preferredInvestmentTypes;
    const active = preferredInvestments?.includes(item);
    return {
      label: item,
      labelTextType: TextTypesEnum.Medium16,
      active,
      type: CheckboxTypesEnum.Large,
      onClick: () => {
        setUserInvestorProfileField({
          preferredInvestmentTypes: active
            ? preferredInvestments?.filter((investmentType) => investmentType !== item)
            : preferredInvestments
              ? [...preferredInvestments, item]
              : [item],
        });
      },
    };
  });

  /* Render */
  return (
    <CheckboxList
      data-cy="investment-types"
      title="What type of investment opportunities do you seek out?"
      config={config}
    />
  );
};

export default ProfilePreferredInvestmentQuestion;
