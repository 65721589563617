import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import RECENTLY_VIEWED_LISTINGS_PER_USER from '../../graphql/queries/recentlyViewedListingsPerUser.query';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icons from '../../elements/Icons';
import ListingCard from '../ListingCard.dashboard';
import NoContent from '../NoContent';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Divider from '../../elements/Divider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
`;

const RecentViewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
`;

const Content = styled.div`
  height: 292px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.Grey300};
  margin: 4px 0;
  border-radius: 8px;
`;

type RecentlyViewedListingsProps = {};

const RecentlyViewedListings: React.FC<RecentlyViewedListingsProps> = () => {
  /** GraphQL */
  const {
    data,
    loading,
  } = useQuery(RECENTLY_VIEWED_LISTINGS_PER_USER, {
    variables: {
      params: {
        listingLimit: 3,
        timeDimensionParams: {
          dateRange: 'This week',
        },
      },
    },
  });

  /** Render */
  return (
    <Container>
      <Text type={TextTypesEnum.Bold18} margin="0 0 5px 0">Recently viewed listings</Text>
      {loading ? (
        <Content>
          <Loader size={LoaderSizes.Medium} color={Colors.Brand700 || Colors.Blurple700} />
        </Content>
      ) : (
        <>
          {
            data?.recentlyViewedListingsPerUser?.length > 0
              ? (
                <RecentViewsContainer>
                  {
                  data?.recentlyViewedListingsPerUser?.map((listing: IListingGraphQL, index: number) => (
                    <React.Fragment key={listing?._id}>
                      <ListingCard
                        listing={listing}
                        onClick={() => window.open(ListingUtil.slug(listing))}
                      />
                      {index !== data?.recentlyViewedListingsPerUser?.length - 1 && <Divider margin="16px 0px" color={Colors.Grey200} />}
                    </React.Fragment>
                  ))
                }
                </RecentViewsContainer>
              )

              : (
                <Content>
                  <NoContent
                    height="100%"
                    icon={Icons.EyeLight}
                    text="You haven't viewed any listings recently."
                  />
                </Content>
              )
          }
        </>
      )}
    </Container>
  );
};

export default RecentlyViewedListings;
