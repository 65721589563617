import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import LeadQuerySortByEnum from '@biproxi/models/enums/LeadQuerySortByEnum';
import LeadQuerySortDirectionEnum from '@biproxi/models/enums/LeadQuerySortDirectionEnum';
import LeadUtil from '@biproxi/models/utils/LeadUtil';
import { useDebounceCallback } from '@react-hook/debounce';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableSpace,
} from './ScrollTable';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  LeadActions,
  LeadSelectors,
} from '../redux/lead.redux';
import Icons from '../elements/Icons';
import Icon from '../elements/Icon';
import Flex from '../elements/Flex';
import Colors from '../styles/Colors';
import NoContent from './NoContent';
import { media } from '../utils/MediaQuery';
import InvestorMatchesTableRow from './InvestorMatchesTableRow';
import Paywall from './Paywall';
import Button, { ButtonTypesEnum } from '../elements/Button';
import Loader, { LoaderSizes } from '../elements/Loader';

const Container = styled.div`
  ${media.mobile} {
    width: 94vw;
    margin: 0 auto;
    overflow: auto;
  }
`;

const LoaderContainer = styled.div`
  /* height: 30vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0 0 0;
  width: 100%;
`;

type SortArrowsProps = {
  text: string;
  sortBy: LeadQuerySortByEnum;
  flex: string;
}

const SortArrows: React.FC<SortArrowsProps> = ({
  text,
  sortBy,
  flex,
}) => {
  /* State */
  const {
    sortBy: sortByState,
    sortDirection,
  } = useAppSelector(LeadSelectors.searchQuery);

  const isActive = sortByState === sortBy;

  const isAscending = sortDirection === LeadQuerySortDirectionEnum.Ascending;

  /* Actions */
  const dispatch = useAppDispatch();

  const setSearchQuery = () => dispatch(
    LeadActions.setLeadSearchQuery({
      sortBy,
      sortDirection: isActive
        ? isAscending
          ? LeadQuerySortDirectionEnum.Descending
          : LeadQuerySortDirectionEnum.Ascending
        : sortDirection,
    }),
  );

  return (
    <ScrollTableHeaderCell onClick={() => setSearchQuery()} flex={flex}>
      {text}
      <Flex direction="column" margin="0 0 0 8px">
        <Icon
          icon={Icons.CaretUpSolid}
          color={isActive ? isAscending ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey400 : Colors.Grey700}
          size={12}
          top="3px"
        />
        <Icon
          icon={Icons.CaretDownSolid}
          color={isActive ? !isAscending ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey400 : Colors.Grey700}
          size={12}
          top="-3px"
        />
      </Flex>
    </ScrollTableHeaderCell>
  );
};

const TableHeader: React.FC = () => (
  <ScrollTableHeader>
    <SortArrows
      text="Name"
      sortBy={LeadQuerySortByEnum.Name}
      flex="1"
    />
    <ScrollTableSpace />
    <ScrollTableHeaderCell flex="0.5">
      Contact
    </ScrollTableHeaderCell>
    <ScrollTableSpace />
    <SortArrows
      text="Views"
      sortBy={LeadQuerySortByEnum.Views}
      flex="0.5"
    />
    <ScrollTableSpace />
    <SortArrows
      text="Created"
      sortBy={LeadQuerySortByEnum.Created}
      flex="1"
    />
    <ScrollTableSpace />
  </ScrollTableHeader>
);

type InvestorMatchesTableProps = {
  matches: ILeadGraphQL[];
  update?: () => void;
  hasInvestorMatchPermission: boolean;
  updateLoad: boolean;
  moreMatches: boolean;
};

const InvestorMatchesTable: React.FC<InvestorMatchesTableProps> = ({
  matches,
  update,
  hasInvestorMatchPermission,
  updateLoad,
  moreMatches,
}) => {
  /** State */
  const cachedLeads = useAppSelector(LeadSelectors.leadCache);
  const cacheLeadsArray: ILeadGraphQL[] = Object.keys(cachedLeads)?.map((key) => cachedLeads[key]);

  const [filteredLeads, setFilteredLeads] = React.useState<ILeadGraphQL[]>(cacheLeadsArray?.length ? cacheLeadsArray : matches);
  const scrollContainer = React.useRef<any>(null);
  const query = useAppSelector(LeadSelectors.searchQuery);

  /** Actions */
  const filterLeads = () => {
    setFilteredLeads(LeadUtil.sort(LeadUtil.filter(matches, query, true), query));
  };

  /** Hooks */
  const filterLeadsDebounced = useDebounceCallback(filterLeads, 250);

  /** Effects */
  React.useEffect(() => {
    filterLeadsDebounced();
  }, [query]);

  /** Here we look for changes with the list, then update, when refreshed/updated */
  React.useEffect(() => {
    filterLeads();
  }, [cachedLeads]);

  /* Render */
  if (!matches) return null;

  const LeadsTableRowMemo = React.useMemo(() => filteredLeads.map((lead: ILeadGraphQL, index) => (
    <React.Fragment key={index}>
      <InvestorMatchesTableRow
        key={lead._id}
        lead={lead}
      />
    </React.Fragment>
  )), [filteredLeads]);

  return (
    <Container>
      <Paywall
        disabled={hasInvestorMatchPermission}
      >
        <ScrollTable>
          <TableHeader />
          <ScrollTableBody ref={scrollContainer}>
            {filteredLeads?.length > 0 ? LeadsTableRowMemo : (
              <NoContent
                height="176px"
                icon={Icons.StarLight}
                text="No Investor matches match your search criteria. Try different search parameters to see matches"
              />
            )}
          </ScrollTableBody>
          {updateLoad && (
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </LoaderContainer>
          )}
        </ScrollTable>
        <Flex justify="center" margin="20px">
          {moreMatches && !updateLoad && (
            <Button
              data-cy="get-more-investors"
              type={ButtonTypesEnum.Primary}
              text="Get more investor matches"
              onClick={update}
            />
          )}
        </Flex>
      </Paywall>
    </Container>
  );
};

export default InvestorMatchesTable;
