import React from 'react';
import styled from '@emotion/styled';
import BoxShadows from '../../../styles/BoxShadows';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import { media } from '../../../utils/MediaQuery';

const QuickAnalyticCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 210px;
  min-width: 120px;
  flex: 1 1 0;

  background: ${Colors.Grey100};
  box-shadow: ${BoxShadows.Light};
  border-radius: 8px;
  padding: 4px 0px;

  ${media.mobile} {
    flex: 0;
    max-width: unset;
    padding: 16px;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
`;

export enum DashboardAnalyticsEnum {
  TotalListings = 'Total listings',
  OffersReceived = 'Offers received',
}

type DashboardQuickAnalyticCardProps = {
  analyticType: DashboardAnalyticsEnum
  analyticData: number
}
const DashboardQuickAnalyticCard: React.FC<DashboardQuickAnalyticCardProps> = ({
  analyticType,
  analyticData,
}) => (
  <QuickAnalyticCardContainer>
    <Text
      color={Colors.Grey900}
      type={TextTypesEnum.Regular16}
    >
      {analyticType}
    </Text>
    <MetricsContainer>
      <Text
        type={TextTypesEnum.Bold30}
        color={Colors.Grey900}
      >
        {analyticData || 0}
      </Text>
      {/* <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey900}
      >
        +35%
      </Text> */}
    </MetricsContainer>
  </QuickAnalyticCardContainer>
);

export default DashboardQuickAnalyticCard;
