import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import { ListingActions } from '../../redux/listing.redux';
import { useAppDispatch } from '../../redux/store';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Icon, { Icons } from '../../elements/Icon';
import Loader, { LoaderSizes } from '../../elements/Loader';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';

const DropzoneContainer = styled.div`
  height: 144px;
  width: 214px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border: 2px dashed ${Colors.Grey300};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

type CreateListingPhotoUploadProps = {
  listing: IListingGraphQL;
  dataCy: string;
};

const CreateListingPhotoUpload: React.FC<CreateListingPhotoUploadProps> = ({
  dataCy,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const addListingMediaFiles = (files: IFile[]) => dispatch(
    ListingActions.addListingMediaFiles({
      files,
    }),
  );

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  /** GraphQL */
  const [uploadFiles, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      addListingMediaFiles(data.createFiles);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error uploading your photos. Please try again or contact support.',
      });
    },
  });

  /* Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (loading) return;
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type,
      encoding: 'utf8',
      upload: browserFile,
    }));
    uploadFiles({
      variables: {
        params: fileParams,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  /* Render */
  return (
    <DropzoneContainer {...getRootProps({
      'data-cy': dataCy,
    })}
    >
      <input {...getInputProps()} />
      {loading ? (
        <>
          <Loader
            size={LoaderSizes.Medium}
            color={Colors.Brand700 || Colors.Blurple700}
          />
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Black}
            margin="8px 0 0"
          >
            Uploading...
          </Text>
        </>
      ) : (
        <>
          <Icon
            icon={Icons.ImageLight}
            color={Colors.Grey700}
            size={36}
            margin="0 0 8px 0"
          />
          <Text
            type={TextTypesEnum.Medium12}
            color={Colors.Grey700}
          >
            Drag photo(s) here or
          </Text>
          <Text
            type={TextTypesEnum.Medium12}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            click to upload
          </Text>
        </>
      )}
    </DropzoneContainer>
  );
};

export default CreateListingPhotoUpload;
