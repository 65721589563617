import axios from 'axios';
import { config } from '@biproxi/env';

const renewSubscriptionMutation = async (subscriptionId: string) => {
  const res = await axios.post('/api/billing/renew-subscription', { subscriptionId }, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const result = await res.data;
  return result;
};

export default renewSubscriptionMutation;
