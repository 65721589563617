import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import * as UrlUtil from '../../utils/UrlUtil';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import ListingDetailsPreview from './ListingDetailsPreview';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';
import { AppActions } from '../../redux/app.redux';
import Link from '../../elements/Link';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import ListingDetailsMobileNavDropdown from './ListingDetailsMobileNavDropdown';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import SquareIconButton from '../../elements/SquareIconButton';
import AnchoredMenu from '../../elements/AnchoredMenu';
import useUser from '../../hooks/useUser.hook';
import useUserPermissions from '../../hooks/useUserPermissions.hook';

const Container = styled.div`
  border-bottom: 1px solid ${Colors.Grey300};
  margin-bottom: 16px;

  ${media.mobile} {
    width: calc(100vw - 32px);
  }
`;

const ListingDetailsContainer = styled.div`
  &, & > *, > div > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MyListingsContainer = styled.div`
  white-space: nowrap;
`;

const NavItem = styled.div<{ active: boolean }>`
  transition: all 0.2s;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 12px;
`;

const MenuRef = styled.div``;

const ButtonDropdownContainer = styled.div`
  width: 100%;
  margin: 0px 0px 16px 0px;
`;

const NavUnderline = styled.div<{ underlineColor?: string}>`
  width: 100%;
  position: absolute;
  height: 4px;
  background: ${(props) => props.underlineColor};
  bottom: 0;
  left: 0;
`;
const SkeletonContainer = styled.div`
  position: absolute;
`;

type ListingDetailsHeaderProps = {
  listing: IListingGraphQL;
};

const ListingDetailsHeader: React.FC<ListingDetailsHeaderProps> = ({
  listing,
}) => {
/** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */
  /** Contentful - Redux */
  const {
    contentful: {
      brandCollection: {
        colors,
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const [underlineColorLoad, setUnderlineColorLoad] = React.useState(true);

  const underlineColor = colors.Brand700 || colors.Blurple700;

  React.useEffect(() => {
    if (fetched) {
      setUnderlineColorLoad(false);
    }
  }, []);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  const navigate = useNavigate();
  const { pathname } = UrlUtil.parse(window.location.href);
  const isMobile = useMobileMedia();
  const ref = React.useRef(null);
  const { userId } = useUser();
  const { userBiproxiRolesAndPermissions } = useUserPermissions({ userId });
  // const [userCanViewInvestorMatchesPermission, setUserCanViewInvestorMatchesPermission] = React.useState(false);

  /* Actions */
  const dispatch = useAppDispatch();

  const pushShareModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ShareListingModal,
      props: {
        listing,
      },
    }),
  );

  const pushListingReportModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateListingReport,
      props: {},
    }),
  );

  const pushBillingPaywallModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.InvestorMatching,
      },
    }),
  );

  const investorMatchPermission = PermissionsUtil.canUserViewInvestorMatches(userBiproxiRolesAndPermissions);
  const navItemConfig: IButtonOptions[] = [
    {
      text: 'Overview',
      onClick: () => navigate(`/app/dashboard/listings/details/overview?listingId=${listing?._id}`),
      icon: Icons.TachometerFastRegular,
      active: ['/app/dashboard/listings/details/overview'],
    },
    {
      text: 'Offers',
      onClick: () => navigate(`/app/dashboard/listings/details/offers?listingId=${listing?._id}`),
      icon: Icons.EnvelopeOpenDollarRegular,
      active: ['/app/dashboard/listings/details/offers'],
      dataCy: 'listing-details-header-offers',
    },
    {
      text: 'Leads',
      onClick: () => navigate(`/app/dashboard/listings/details/leads?listingId=${listing?._id}`),
      icon: Icons.FireRegular,
      active: ['/app/dashboard/listings/details/leads'],
    },
    {
      text: 'Investor Matches',
      onClick: () => (investorMatchPermission
        ? navigate(`/app/dashboard/listings/details/investor-matches?listingId=${listing?._id}`)
        : pushBillingPaywallModal()),
      icon: !investorMatchPermission ? Icons.LockAltRegular : Icons.LinkRegular,
      active: ['/app/dashboard/listings/details/investor-matches'],
    },
    {
      text: 'Messages',
      onClick: () => navigate(`/app/dashboard/listings/details/messages?listingId=${listing?._id}`),
      icon: Icons.CommentsAltRegular,
      active: ['/app/dashboard/listings/details/messages'],
    },
  ];

  const menuItems: IButtonOptions[] = [
    {
      text: 'Edit',
      onClick: () => navigate(`/app/create-listing?listingId=${listing?._id}`),
    },
    {
      text: 'Share',
      onClick: () => pushShareModal(),
    },
    {
      text: 'View',
      onClick: () => navigate(ListingUtil.slug(listing)),
    },
  ];

  if ((listing?.state !== ListingStateEnum.Draft)) {
    menuItems.unshift({
      text: 'Create report',
      onClick: pushListingReportModal,
    });
  }

  return (
    <Container>
      <Flex>
        <MyListingsContainer>
          <Text
            data-cy="listing-details-return-button"
            type={TextTypesEnum.Regular12}
            color={Colors.Black}
            onClick={() => navigate('/app/dashboard/listings')}
          >
            My listings
          </Text>
        </MyListingsContainer>
        <Text
          type={TextTypesEnum.Regular12}
          color={Colors.Grey500}
          margin="0px 8px"
        >
          /
        </Text>
        <ListingDetailsContainer>
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Grey500}
            skWidth={100}
          >
            {listing?.name || listing?.address?.address1 || null}
          </Text>
        </ListingDetailsContainer>
      </Flex>
      <Flex direction="row" align="center" justify="space-between" margin={isMobile ? '16px 0px' : '24px 0px'}>
        <ListingDetailsContainer>
          <ListingDetailsPreview listing={listing} />
        </ListingDetailsContainer>
        {listing && (
          <>
            {isMobile ? (
              <>
                <MenuRef ref={ref}>
                  <SquareIconButton
                    icon={Icons.EllipsisHRegular}
                    size="40px"
                    margin="0px 0px 0px 16px"
                    iconSize={16}
                    onClick={() => null}
                  />
                </MenuRef>
                <AnchoredMenu
                  anchorRef={ref}
                  menuItems={menuItems}
                />
              </>
            ) : (
              <Flex margin="0px 0px 0px 8px">
                {(listing?.state !== ListingStateEnum.Draft) && (
                  <Button
                    text="Create report"
                    type={ButtonTypesEnum.Pale}
                    size={ButtonSizesEnum.Small}
                    icon={Icons.FilePdfRegular}
                    iconColor={Colors.Grey900}
                    iconSize={12}
                    onClick={pushListingReportModal}
                    margin="0px 8px 0px 0px"
                  />
                )}
                <Button
                  text="Edit"
                  type={ButtonTypesEnum.Pale}
                  size={ButtonSizesEnum.Small}
                  icon={Icons.PenRegular}
                  iconColor={Colors.Grey900}
                  iconSize={12}
                  onClick={() => navigate(`/app/create-listing?listingId=${listing?._id}`)}
                  margin="0px 8px 0px 0px"
                />
                <Button
                  text="Share"
                  type={ButtonTypesEnum.Pale}
                  size={ButtonSizesEnum.Small}
                  icon={Icons.ShareAltRegular}
                  iconColor={Colors.Grey900}
                  iconSize={12}
                  onClick={() => pushShareModal()}
                  margin="0px 8px 0px 0px"
                />
                <Link href={ListingUtil.slug(listing)} target="_blank">
                  <Button
                    text="View"
                    type={ButtonTypesEnum.Pale}
                    size={ButtonSizesEnum.Small}
                    icon={Icons.ExternalLinkRegular}
                    iconColor={Colors.Grey900}
                    iconSize={12}
                    onClick={() => null}
                  />
                </Link>
              </Flex>
            )}
          </>
        )}
      </Flex>
      {isMobile ? (
        <ButtonDropdownContainer>
          <ListingDetailsMobileNavDropdown
            items={navItemConfig}
          />
        </ButtonDropdownContainer>
      ) : (
        <Flex>
          {navItemConfig.map((item, index) => {
            const active = item.active.includes(pathname);
            return (
              <NavItem onClick={item.onClick} active={active} key={index}>
                <Icon icon={item.icon} size={14} color={active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey500} margin="0px 8px 0px 0px" />
                <Text data-cy={`${item.text.replace(/\s+/g, '-').toLowerCase()}-nav-button`} type={TextTypesEnum.Medium14} color={active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey500}>
                  {item.text}
                </Text>
                {active && underlineColorLoad ? (
                  <SkeletonContainer style={{
                    width: '100%', height: '4px', left: '0', bottom: '0',
                  }}
                  >
                    <Skeleton
                      width="100%"
                      height="100%"
                      style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                    />
                  </SkeletonContainer>
                ) : active && <NavUnderline underlineColor={underlineColor} />}
              </NavItem>
            );
          })}
        </Flex>
      )}
    </Container>
  );
};

export default ListingDetailsHeader;
