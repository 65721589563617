/* eslint-disable no-undef */
import React from 'react';
import styled from '@emotion/styled';
import { ISavedSearchGraphQL } from '@biproxi/models/interfaces/ISavedSearch';
import {
  useMutation, OperationVariables, ApolloQueryResult, useQuery,
} from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IAddress from '@biproxi/models/interfaces/IAddress';
import { config } from '@biproxi/env';
import { useNavigate } from 'react-router-dom';
import LIST_LISTINGS from '../graphql/queries/listings.query';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch } from '../redux/store';
import { IToastConfig, ToastTypesEnum } from './Toast';
import { ModalTypesEnum } from './modal/Modal';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import DELETE_SAVED_SEARCH from '../graphql/mutations/deleteSavedSearch.mutation';
import { SaveSearchModalTypeEnum } from './modal/SaveSearchModal';
import { ListingActions } from '../redux/listing.redux';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import SquareIconButton from '../elements/SquareIconButton';
import AnchoredMenu from '../elements/AnchoredMenu';
import Tag from '../elements/Tag';
import { media, useMobileMedia } from '../utils/MediaQuery';

const SavedSearchCardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${media.mobile}{
      flex-direction: column;
    };
`;

const SavedSearchCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SavedSearchInfoTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StaticMapWrapper = styled.div`
  margin-right: 16px;
  border-radius: 8px;
  /* width: 100%; */
`;

const StaticMap = styled.img`
  border-radius: 8px;

  ${media.mobile}{
      width: calc(100vw - 32px);
    };
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuRef = styled.div``;

const MenuContainer = styled.div`
  z-index: 3;
  position: fixed;
`;

type SavedSearchCardProps = {
    savedSearch: ISavedSearchGraphQL;
    refetchSavedSearches: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>
  }

const SavedSearchCard: React.FC<SavedSearchCardProps> = ({
  savedSearch,
  refetchSavedSearches,
}) => {
  /** State */
  const [numberOfListings, setNumberOfListings] = React.useState<number>(0);

  const {
    _id,
    name: savedSearchName,
    query: savedSearchQuery,
    notifications: savedSearchNotifications,
  } : {
    name: string,
    query: any, // Technically, the type is IListingQuery but I can't define it that way because within that interface is a bounds field of type IMapBounds which uses types from an external library that doesn't play well with the lat/lng fields that are used below.
    _id?: string,
    notifications?: boolean
  } = savedSearch;

  const address: IAddress = savedSearchQuery?.address;

  /** Hooks */
  const isMobile = useMobileMedia();
  const navigate = useNavigate();

  const googleBounds = new google.maps.LatLngBounds(savedSearchQuery.bounds.sw, savedSearchQuery.bounds.ne);
  const center = googleBounds.getCenter();

  const menuItems = [
    {
      text: 'Edit search',
      onClick: (event) => {
        event.stopPropagation();
        editSavedSearch();
      },
    },
    {
      text: 'Delete search',
      color: Colors.Red500,
      onClick: (event) => {
        event.stopPropagation();
        pushDeleteSavedSearchModal();
      },
    },
  ];

  /** Refs */
  const menuRef = React.useRef(null);

    /** GraphQL */
    type Data = {
      listings: {
        data: IListingGraphQL[]
      }
    }

    type Vars = {
      params: {
        query: IListingQuery
      }
    }

    const { loading: listingsLoading } = useQuery<Data, Vars>(LIST_LISTINGS, {
      variables: {
        params: {
          query: savedSearch?.query,
        },
      },
      onCompleted: (data) => {
        setNumberOfListings(data?.listings?.data?.length);
      },
    });

    /** Actions */
    const dispatch = useAppDispatch();

    const pushToast = (config: IToastConfig) => dispatch(
      AppActions.pushToast(config),
    );

    const popModal = () => dispatch(
      AppActions.popModal(),
    );

    const pushDeleteSavedSearchModal = () => dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.ConfirmChange,
        props: {
          type: ConfirmChangeModalTypesEnum.Warning,
          title: 'Delete saved search',
          text: 'Are you sure? You cannot undo this action afterwards.',
          confirm: deleteSavedSearch,
        },
      }),
    );

    const pushConfirmSavedSearchDelete = () => dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.ConfirmChange,
        props: {
          type: ConfirmChangeModalTypesEnum.Success,
          title: 'Saved search has been deleted',
          confirm: popModal,
        },
      }),
    );

    const [deleteSavedSearch] = useMutation(DELETE_SAVED_SEARCH, {
      onCompleted: async () => {
        popModal();
        pushConfirmSavedSearchDelete();
        refetchSavedSearches();
      },
      variables: {
        params: {
          _id,
        },
      },
      onError: async (error) => {
        const { message } = ApolloUtil.parseApolloClientError(error);

        pushToast({
          message,
          type: ToastTypesEnum.Error,
          duration: 5000,
        });
      },
    });

    /** Helper Functions / Event Handlers */
    const editSavedSearch = () => {
      dispatch(ListingActions.cacheSavedSearch({ ...savedSearch }));
      dispatch(
        AppActions.pushModal({
          type: ModalTypesEnum.SaveSearch,
          props: {
            savedSearch,
            type: SaveSearchModalTypeEnum.Update,
          },
        }),
      );
    };

    const pushListingSearchAddress = () => {
      dispatch(ListingActions.setListingSearch({ address }));
      dispatch(ListingActions.cacheSavedSearch({ ...savedSearch }));
      dispatch(ListingActions.setListingSearch({ isSavedSearch: true }));
      navigate('/app/dashboard/search');
    };

    const determineNumberOfFilters = () => {
      let filters: number = 0;
      // alright i definitely need to find a better way to do this lmao
      Object.keys(savedSearchQuery?.assetClassesAndPropertyTypes ?? {}).forEach((assetClass) => {
        filters++;
        filters += savedSearchQuery?.assetClassesAndPropertyTypes[assetClass].length;
      });
      const priceFilters = savedSearchQuery.price;
      Object.keys(priceFilters).forEach((key) => {
        if (priceFilters[key] !== null) {
          filters++;
        }
      });
      return filters;
    };

    const getBoundsZoomLevel = (bounds, mapDim) => {
      const WORLD_DIM = { height: 256, width: 256 };
      const ZOOM_MAX = 21;

      function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      const lngDiff = ne.lng() - sw.lng();
      const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

      const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    };

    const zoomLevel = getBoundsZoomLevel(googleBounds, { width: 256, height: 256 });

    const imageSource = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat()},${center.lng()}&zoom=${zoomLevel}&size=144x100&map_id=8714d1fd4391b858&style={border-radius:8px}&key=${config.NEXT_PUBLIC_GOOGLE_BROWSER_KEY}&scale=${isMobile ? 2 : 1}`;

    return (
      <Flex justify="space-between" align="center" padding={isMobile ? '16px 0' : '16px 8px'} onClick={() => pushListingSearchAddress()}>
        <SavedSearchCardContentWrapper>
          <StaticMapWrapper>
            <StaticMap src={imageSource} alt="map" />
          </StaticMapWrapper>
          <SavedSearchCardInfoWrapper>
            <Flex direction="column" width={isMobile ? 'calc(100vw - 32px)' : '100%'}>
              <Flex justify="space-between" width="100%">
                <Text type={TextTypesEnum.Bold16}>{savedSearchName}</Text>
                {isMobile && (
                <>
                  <ButtonContainer>
                    <MenuRef ref={menuRef}>
                      <SquareIconButton
                        icon={Icons.EllipsisHRegular}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    </MenuRef>
                  </ButtonContainer>
                  <MenuContainer>
                    <AnchoredMenu
                      anchorRef={menuRef}
                      menuItems={menuItems}
                    />
                  </MenuContainer>
                </>
                )}
              </Flex>
              <Flex margin="0 0 8px 0">
                <Icon
                  icon={Icons.MapMarkerAltSolid}
                  size="10"
                  color={Colors.Grey600}
                  margin="0 4px 0 0"
                />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey400}>{address?.city ? address?.city : address?.state}</Text>
              </Flex>
              <SavedSearchInfoTagsWrapper>
                <Flex margin="0 0 4px 0">
                  <Tag
                    text={`${listingsLoading ? '--' : numberOfListings} current results`}
                    margin="0 4px 0 0"
                  />
                  <Tag
                    text={`${determineNumberOfFilters()} filters`}
                    customTagColor={Colors.Grey100}
                    margin="0 4px 0 0"
                  />
                </Flex>
                <Tag
                  text={savedSearchNotifications ? 'Notifications on' : 'Notifications off'}
                  customTagColor={savedSearchNotifications ? Colors.Green100 : Colors.Red100}
                  margin="0 4px 0 0"
                >
                  <Icon
                    icon={savedSearchNotifications ? Icons.CheckCircleSolid : Icons.TimesCircleSolid}
                    size="10"
                    color={savedSearchNotifications ? Colors.Green800 : Colors.Red800}
                    margin="0 4px 0 0"
                  />
                </Tag>
              </SavedSearchInfoTagsWrapper>
            </Flex>
          </SavedSearchCardInfoWrapper>
        </SavedSearchCardContentWrapper>
        {!isMobile && (
          <>
            <ButtonContainer>
              <MenuRef ref={menuRef}>
                <SquareIconButton
                  icon={Icons.EllipsisHRegular}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              </MenuRef>
            </ButtonContainer>
            <MenuContainer>
              <AnchoredMenu
                anchorRef={menuRef}
                menuItems={menuItems}
              />
            </MenuContainer>
          </>
        )}

      </Flex>
    );
};

export default SavedSearchCard;
