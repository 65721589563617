import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
// import Image from 'next/image';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import ListingStatus from '../ListingStatus';
import Colors from '../../styles/Colors';

const TextContainer = styled.div`
  width: 300px;

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ImageContainer = styled.div`
  background: ${Colors.Grey100};
  width: 64px;
  min-width: 64px;
  height: 40px;
  margin: 0px 12px 0px 0px;
`;

const Image = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

type ListingDetailsPreviewProps = {
  listing: IListingGraphQL;
  hideStatus?: boolean;
};

const ListingDetailsPreview: React.FC<ListingDetailsPreviewProps> = ({
  listing,
  hideStatus,
}) => (
  <Flex align="center" width="100%">
    <ImageContainer>
      <Image
        src={listing?.media?.files?.[0]?.url}
      />
    </ImageContainer>
    <TextContainer>
      <Flex align="center">
        <Text
          type={TextTypesEnum.Bold18}
          color={Colors.Black}
          margin="0px 8px 0px 0px"
          skWidth={100}
        >
          {listing?.name || listing?.address?.address1 || null}
        </Text>
        {listing && !hideStatus && (
          <ListingStatus
            listing={listing}
            changeable
          />
        )}
      </Flex>
      <Text
        type={TextTypesEnum.Regular14}
        color={Colors.Grey700}
      >
        {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, { address1: !listing?.name }) || null}
      </Text>
    </TextContainer>
  </Flex>
);

export default ListingDetailsPreview;
