import gql from 'graphql-tag';

const LISTING_HEAT_MAP_ANALYTICS = gql`
    query ListingHeatMapAnalytics($params: ListingHeatMapAnalyticsParams!) {
        listingHeatMapAnalytics(params: $params) {
            location
            weight
        }
    }
`;

export default LISTING_HEAT_MAP_ANALYTICS;
