import { IScoreRubricItem, IScoreRubricSection } from '../interfaces/IScore';

function getAllRubricItems<T>(rubric: IScoreRubricSection<T>[]): IScoreRubricItem<T>[] {
  return rubric.reduce((cur: IScoreRubricItem<T>[], next: IScoreRubricSection<T>) => cur.concat(...next.items), []);
}

function scoreRubric<T>(value: T, rubric: IScoreRubricSection<T>[]): number {
  const allRubricItems: IScoreRubricItem<T>[] = getAllRubricItems<T>(rubric);

  const totalRubricScore: number = allRubricItems.reduce((cur: number, next: IScoreRubricItem<T>) => {
    if (next.complete(value)) {
      return cur + next.points;
    }
    if (next.percentageComplete && next?.points) {
      return cur + (next.points * next.percentageComplete(value));
    }
    return cur;
  }, 0);

  return Math.ceil(totalRubricScore);
}

function scoreRubricSection<T>(value: T, rubricSection: IScoreRubricSection<T>): number {
  const allRubricSectionItems = rubricSection.items;

  const completeRubricSectionItems: IScoreRubricItem<T>[] = allRubricSectionItems
    .filter((rubricItem: IScoreRubricItem<T>): boolean => rubricItem.complete(value));

  return Math.ceil((completeRubricSectionItems.length / allRubricSectionItems.length) * 100);
}

const ScoreUtil = {
  getAllRubricItems,
  scoreRubric,
  scoreRubricSection,
};

export default ScoreUtil;
