import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import React from 'react';
import RadioList from '../../elements/RadioList';

type Investor1031ExchangeProps = {
    user: IUserGraphQL;
    setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const Investor1031Exchange: React.FC<Investor1031ExchangeProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  const config = [
    {
      label: 'Yes',
      active: user?.investorProfile?.exchangeInvestor,
      onClick: () => setUserInvestorProfileField({ exchangeInvestor: true }),
    },
    {
      label: 'No',
      active: !user?.investorProfile?.exchangeInvestor,
      onClick: () => setUserInvestorProfileField({ exchangeInvestor: false }),
    },
  ];

  /* Render */
  return (
    <RadioList
      title="Are you currently in a 1031 exchange?"
      config={config}
      rowGap="8px 0px"
    />
  );
};

export default Investor1031Exchange;
