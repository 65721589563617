import gql from 'graphql-tag';

/** Get cherre tax listings when user has manually entered latitude and longitude on manual entry form */

const CHERRE_TAX_LISTINGS_FROM_LATLONG = gql`
      query TaxListings ($params: CherreTaxListingsFromLatLongParams!) {
        cherreTaxListingsFromLatLong (params: $params) {
          one_line_address
          tax_assessor_id
        }
    }
`;

export default CHERRE_TAX_LISTINGS_FROM_LATLONG;
