import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import isDeepEqual from 'fast-deep-equal';
import { initializeApollo } from '../graphql/client';
import GET_LISTING from '../graphql/queries/listing.query';

async function hasListingChanged(listing: IListingGraphQL): Promise<boolean> {
  const client = initializeApollo();
  interface Data {
    listing: IListingGraphQL;
  }

  interface Vars {
    listingId: string;
  }

  const { data } = await client.query<Data, Vars>({
    query: GET_LISTING,
    variables: {
      listingId: listing._id,
    },
  });

  return !isDeepEqual(data.listing, listing);
}

const ChangeUtil = {
  hasListingChanged,
};

export default ChangeUtil;
