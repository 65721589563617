import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import NoContent from '../components/NoContent';
import { Icons } from '../elements/Icon';
import Loader, { LoaderSizes } from '../elements/Loader';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Flex from '../elements/Flex';
import OffersReceivedListingContainer from '../components/OffersReceivedListingContainer';
import LIST_LISTINGS_WITH_OFFERS from '../graphql/queries/listingsWithOffers.query';
import { media } from '../utils/MediaQuery';

const Container = styled.div`
  ${media.tablet} {
    padding: 16px 16px;
  }
`;

const OffersContainer = styled.div``;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DashboardOffersReceivedProps = {};

const DashboardOffersReceived: React.FC<DashboardOffersReceivedProps> = () => {
  /** State */
  const [searchQuery, setSearchQuery] = React.useState('');

  /** GraphQL */
  interface Data {
    listings: {
      data: IListingGraphQL[];
    }
  }

  interface Vars {
    params: {
      query: IListingQuery
    }
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_LISTINGS_WITH_OFFERS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Personal,
        },
      },
    },
  });

  /** Render */
  // Current search just stringifys the object and searches
  // based on substring.
  // We will need to improve and update this in the future to search on the backend.

  const listingsWithOffers = data?.listings?.data?.filter((listing) => listing?.offers?.length > 0);
  const o = listingsWithOffers?.length > 0 ? listingsWithOffers : [];
  let filteredListingsWithOffers = [...o];
  if (searchQuery) {
    filteredListingsWithOffers = filteredListingsWithOffers.filter((listing) => Boolean(JSON.stringify(listing).toLowerCase().includes(searchQuery.toLowerCase())));
  }

  return (
    <Container>
      <Text data-cy="offers-received-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>Offers received</Text>
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {(listingsWithOffers?.length > 0) ? (
            <>
              <Flex margin="24px 0px 16px 0px" justify="space-between">
                <Input
                  placeholder="Search offers"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.currentTarget.value)}
                  clear={() => setSearchQuery('')}
                  inputType={InputTypesEnum.Search}
                  inputSize={InputSizeEnum.Small}
                  width="100%"
                />
              </Flex>
              {filteredListingsWithOffers?.length > 0 ? (
                <OffersContainer>
                  {filteredListingsWithOffers.map((listing: IListingGraphQL) => (
                    <OffersReceivedListingContainer
                      key={listing._id}
                      listing={listing}
                    />
                  ))}
                </OffersContainer>
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.SearchRegular}
                  text="No search results. Please try different search terms."
                />
              )}
            </>
          ) : (
          // TODO: Update Icon
            <NoContent
              height="176px"
              icon={Icons.EnvelopeOpenDollarLight}
              text="You have no current offers."
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardOffersReceived;
