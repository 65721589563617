import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import useListing from '../hooks/useListing.hook';
import ListingDetailsHeader from '../components/listing-details/ListingDetailsHeader';
import { useAppDispatch } from '../redux/store';
import { ListingActions } from '../redux/listing.redux';
import useUser from '../hooks/useUser.hook';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ListingDetailsProps = {};

const ListingDetails: React.FC<ListingDetailsProps> = () => {
  /* Hooks */
  const { listing } = useListing();
  const { userId } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  /* Actions */
  const dispatch = useAppDispatch();
  const clearSelectedListing = () => dispatch(ListingActions.selectListing({ listingId: null }));

  /**
   * Clear the selected listing on unmount
   * as long as the route being transitioned
   * to is not the create listing route
   * and is not a part of the listing details dashboard
   */

  /** Effects */

  // HISTORY.LISTEN
  React.useEffect(() => {
    const isCreateListing = location.pathname === '/app/create-listing';
    const isDashboardPage = location.pathname.includes('/app/dashboard/listings/details');

    if (!isCreateListing && !isDashboardPage) {
      clearSelectedListing();
    }
  }, [location]);

  // redirect non-authorized users
  React.useEffect(() => {
    if (listing?._id && userId) {
      if (userId !== listing?.meta?.createdBy) {
        navigate('/app/dashboard/home');
      }
    }
  }, [listing, userId]);

  if (!listing?._id || !userId) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  /** Render */
  return (
    <div>
      <ListingDetailsHeader listing={listing} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ListingDetails;
