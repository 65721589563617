import React from 'react';
import styled from '@emotion/styled';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import StringUtil from '@biproxi/models/utils/StringUtil';
import CherreDictionaryUtil from '@biproxi/models/utils/CherreDictionaryUtil';
import { useRecoilValue } from 'recoil';
import Colors from '../../../styles/Colors';
import Text, { TextTypesEnum } from '../../../elements/Text';
import IDataInsight from '../@types/data-frame-types/interfaces/IDataInsight';
import { dataFrameGeneralAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';

const DataFrameSectionDataWrapper = styled.div<{'data-cy'?: string}>`
    data-cy: ${(props) => props['data-cy']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const DataFrameInsightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

type DataFrameInsightsProps = {
    insightSectionTitle: string;
    data: IDataInsight[];
    taxInsights: ICherreDataInsights;
}

/**
 * Called by a map function in the `DataFrameAddressAndTaxInfo` component
 * For all the active sections in `DataExplorerDataFrameSectionsContent.ts`, an instance of this component is rendered along with its child info (data)
 * Basically renders all the tax insight sections defined in `DataExplorerDataFrameSectionsContent.ts`
 */
const DataFrameInsights: React.FC<DataFrameInsightsProps> = ({
  insightSectionTitle,
  data,
  taxInsights,
}) => {
  /* State/Recoil */
  const dataFrameGeneralState = useRecoilValue(dataFrameGeneralAtom);
  const {
    insightDataExpanded,
    dataSettingsSelectedData,
  } = dataFrameGeneralState;

  /* Variables */
  const dataCySectionTitle = insightSectionTitle.replace(' ', '-');
  data = !insightDataExpanded ? data.slice(0, 3) : data; // if the map is visible (data frame ISN'T full screen), we only want to show the first 3 sections of lot info

  return (
    <DataFrameInsightWrapper
      data-cy={dataCySectionTitle}
    >
      <Text
        type={TextTypesEnum.Bold16}
        color={Colors.Brand700 || Colors.Blurple700}
        data-cy="insight-section-title"
      >
        {insightSectionTitle}
      </Text>
      {
        data.filter((_info, index) => { // filter out the sections that a user has chose to not view within the data settings
          if (dataSettingsSelectedData[insightSectionTitle][index]?.checked) {
            return true;
          }
          return false;
        }).map((info, index) => {
          let insightData = taxInsights?.[info?.cherreVariableName];
          if (insightData === null) { // if there is no data returned by Cherre, just render '--' in place
            insightData = '--';
          }
          const infoTitle: string = info.item;
          if (infoTitle === 'Property use') {
            insightData = CherreDictionaryUtil.lookup('property_use_code_mapped', insightData);
          }
          if (typeof insightData === 'number' && !info.item.toLowerCase().includes('year')) insightData = StringUtil.formatNumber(insightData);

          const dataCySectionTitle = infoTitle.split(' ').join('-');
          return (
            <DataFrameSectionDataWrapper
              data-cy={dataCySectionTitle}
              key={index}
            >
              <Text
                data-cy="insight-section-item"
                type={TextTypesEnum.Regular12}
                color={Colors.Grey500}
              >
                {infoTitle}
              </Text>
              <Text
                data-cy="insight-section-item-data"
                type={TextTypesEnum.Bold14}
                color={Colors.Grey900}
              >
                {insightData}
              </Text>
            </DataFrameSectionDataWrapper>
          );
        })
        }
    </DataFrameInsightWrapper>
  );
};

export default DataFrameInsights;
