import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import LeadQuerySortByEnum from '@biproxi/models/enums/LeadQuerySortByEnum';
import LeadQuerySortDirectionEnum from '@biproxi/models/enums/LeadQuerySortDirectionEnum';
import LeadUtil from '@biproxi/models/utils/LeadUtil';
import { useDebounceCallback } from '@react-hook/debounce';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableSpace,
} from './ScrollTable';
import LeadsTableRow from './LeadsTableRow';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  LeadActions,
  LeadSelectors,
} from '../redux/lead.redux';
import Icons from '../elements/Icons';
import Icon from '../elements/Icon';
import Flex from '../elements/Flex';
import Colors, { ColorsType } from '../styles/Colors';
import NoContent from './NoContent';
import { media } from '../utils/MediaQuery';

const Container = styled.div`
  max-width: calc(100vw);

  ${media.mobile} {
    width: 94vw;
    margin: 0 auto;
    overflow: auto;
  }
`;

const Columns = styled.div`
  display: flex;
`;

const FixedColumn = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
`;

const ScrollingColumn = styled.div`
  width: fit-content;
  overflow-x: auto;
`;

type SortArrowsProps = {
  text: string;
  sortBy: LeadQuerySortByEnum;
  flex?: string;
  borderRight?: string;
  borderLeft?: string;
  minWidth?: string;
  bgColor?: ColorsType;
  padding?: string;
}

const SortArrows: React.FC<SortArrowsProps> = ({
  text,
  sortBy,
  flex,
  borderRight,
  borderLeft,
  minWidth,
  bgColor,
  padding,
}) => {
  /* State */
  const {
    sortBy: sortByState,
    sortDirection,
  } = useAppSelector(LeadSelectors.searchQuery);
  const isActive = sortByState === sortBy;
  const isAscending = sortDirection === LeadQuerySortDirectionEnum.Ascending;

  /* Actions */
  const dispatch = useAppDispatch();
  const setSearchQuery = () => dispatch(
    LeadActions.setLeadSearchQuery({
      sortBy,
      sortDirection: isActive
        ? isAscending
          ? LeadQuerySortDirectionEnum.Descending
          : LeadQuerySortDirectionEnum.Ascending
        : sortDirection,
    }),
  );

  return (
    <ScrollTableHeaderCell
      onClick={() => setSearchQuery()}
      flex={flex}
      borderRight={borderRight}
      borderLeft={borderLeft}
      minWidth={minWidth}
      bgColor={bgColor}
      padding={padding}
    >
      {text}
      <Flex direction="column" margin="0 0 0 8px">
        <Icon
          icon={Icons.CaretUpSolid}
          color={isActive ? isAscending ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey400 : Colors.Grey700}
          size={12}
          top="3px"
        />
        <Icon
          icon={Icons.CaretDownSolid}
          color={isActive ? !isAscending ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey400 : Colors.Grey700}
          size={12}
          top="-3px"
        />
      </Flex>
    </ScrollTableHeaderCell>
  );
};

const NameHeader: React.FC = () => (
  <ScrollTableHeader padding="0">
    <SortArrows
      borderRight={`1px solid ${Colors.Grey200}`}
      text="Name"
      sortBy={LeadQuerySortByEnum.Name}
      flex="unset"
      minWidth="300px"
      bgColor={Colors.Brand50 || Colors.Blurple50}
      padding="0px 16px"
    />
  </ScrollTableHeader>

);

const ScrollingHeader: React.FC = () => (
  <ScrollTableHeader width="fit-content">
    <ScrollTableSpace />
    <ScrollTableHeaderCell
      flex="0.5"
      minWidth="160px"
    >
      Status
    </ScrollTableHeaderCell>
    <ScrollTableSpace />
    <ScrollTableHeaderCell
      flex="0.5"
      minWidth="100px"
    >
      Guidance
    </ScrollTableHeaderCell>
    <ScrollTableSpace />
    <ScrollTableHeaderCell
      flex="0.5"
      minWidth="100px"
    >
      Offer
    </ScrollTableHeaderCell>
    <ScrollTableSpace />
    <SortArrows
      text="Views"
      sortBy={LeadQuerySortByEnum.Views}
      flex="0.5"
      minWidth="75px"
    />
    <ScrollTableSpace />
    <SortArrows
      text="CA"
      sortBy={LeadQuerySortByEnum.SignedCA}
      flex="0.5"
      minWidth="75px"
    />
    <ScrollTableSpace />
    <SortArrows
      text="Created"
      sortBy={LeadQuerySortByEnum.Created}
      flex="1"
      minWidth="175px"
    />
    <ScrollTableSpace />
    <ScrollTableHeaderCell
      flex="0.5"
      minWidth="250px"
    >
      Seller Notes
    </ScrollTableHeaderCell>
  </ScrollTableHeader>
);

const ExpandHeader: React.FC<{ leads: ILeadGraphQL[] }> = ({ leads }) => {
  const dispatch = useAppDispatch();
  const allLeadIds = leads?.map((lead) => lead._id);
  const expandedArray = useAppSelector(LeadSelectors.leadTableRowExpandedLeadIds);
  const expandLead = (leadIds: string[]) => dispatch(LeadActions.pushLeadTableRowExpandedLeadIds({ leadIds }));
  const contractLead = (leadIds: string[]) => dispatch(LeadActions.pullLeadTableRowExpandedLeadIds({ leadIds }));
  const allLeadsExpanded = allLeadIds.every((item) => expandedArray.includes(item));

  return (
    <ScrollTableHeader padding="0">
      <ScrollTableHeaderCell
        borderLeft={`1px solid ${Colors.Grey200}`}
        data-tip="Expand all"
        onClick={() => {
          if (allLeadsExpanded) {
            contractLead(allLeadIds);
          } else {
            expandLead(allLeadIds); // remove duplicates
          }
        }}
        flex="unset"
        minWidth="fit-content"
        padding="0 16px"
        bgColor={Colors.Brand50 || Colors.Blurple50}
      >
        <Flex direction="column">
          <Icon
            icon={allLeadsExpanded ? Icons.CaretUpSolid : Icons.CaretDownSolid}
            color={Colors.Brand700 || Colors.Blurple700}
            size={16}
          />
        </Flex>
      </ScrollTableHeaderCell>
    </ScrollTableHeader>
  );
};

type LeadsTableProps = {
  leads: ILeadGraphQL[];
};

const LeadsTable: React.FC<LeadsTableProps> = ({
  leads,
}) => {
  /** State */
  const cachedLeads = useAppSelector(LeadSelectors.leadCache);
  const cacheLeadsArray: ILeadGraphQL[] = Object.keys(cachedLeads)?.map((key) => cachedLeads[key]);
  const [filteredLeads, setFilteredLeads] = React.useState<ILeadGraphQL[]>(cacheLeadsArray?.length ? cacheLeadsArray : leads);
  const scrollContainer = React.useRef<any>(null);
  const query = useAppSelector(LeadSelectors.searchQuery);

  /** Actions */
  const filterLeads = () => {
    setFilteredLeads(LeadUtil.sort(LeadUtil.filter(leads, query, false), query));
  };

  /** Hooks */
  const filterLeadsDebounced = useDebounceCallback(filterLeads, 250);

  /** Effects */
  React.useEffect(() => {
    filterLeadsDebounced();
  }, [query, leads, cachedLeads]);

  /* Render */
  if (!leads) return null;

  /**
   * This, the lead table rows, and the whole table component should be refactored.
   * The old table component did not work well with the new design but there was too much
   * overhead to refactor now and design needs to update the old tables.
   */
  return (
    <Container>
      {leads?.length > 0 ? (
        <ScrollTable>
          <Columns>
            <FixedColumn width="300px">
              <NameHeader />
              <ScrollTableBody ref={scrollContainer}>
                {filteredLeads.map((lead: ILeadGraphQL, index) => (
                  <LeadsTableRow
                    key={lead._id}
                    lead={lead}
                    index={index}
                    nameOnly
                  />
                ))}
              </ScrollTableBody>
            </FixedColumn>
            <ScrollingColumn>
              <ScrollingHeader />
              <ScrollTableBody ref={scrollContainer} width="fit-content">
                {filteredLeads.map((lead: ILeadGraphQL, index) => (
                  <LeadsTableRow
                    key={lead._id}
                    lead={lead}
                    index={index}
                  />
                ))}
              </ScrollTableBody>
            </ScrollingColumn>
            <FixedColumn width="fit-content">
              <ExpandHeader leads={leads} />
              <ScrollTableBody ref={scrollContainer}>
                {filteredLeads.map((lead: ILeadGraphQL, index) => (
                  <LeadsTableRow
                    key={lead._id}
                    lead={lead}
                    index={index}
                    expandOnly
                  />
                ))}
              </ScrollTableBody>
            </FixedColumn>
          </Columns>
        </ScrollTable>
      ) : (
        <NoContent
          height="176px"
          icon={Icons.StarLight}
          text="No Leads match your search criteria. Try different search parameters to see leads"
        />
      )}
    </Container>
  );
};

export default LeadsTable;
