import React from 'react';
import styled from '@emotion/styled';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IFile from '@biproxi/models/interfaces/IFile';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import { KB_PRIVATE_EVENT_USE_URL } from '@biproxi/models/externalLinks';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from '../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Input, { InputTypesEnum } from '../elements/Input';
import DateInput, { DateInputTypeEnum } from '../elements/DateInput';
import Divider from '../elements/Divider';
import Toggle from '../elements/Toggle';
import Flex from '../elements/Flex';
import KeyCodesEnum from '../models/enums/KeyCodesEnum';
import ListingSelectionDropdown from '../components/private-event/ListingSelectionDropdown';
import CreatePrivateEventCard from '../components/private-event/CreatePrivateEventCard';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { PrivateEventActions, PrivateEventSelectors } from '../redux/privateEvent.redux';
import { media } from '../utils/MediaQuery';
import CREATE_FILES from '../graphql/mutations/createFiles.mutation';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import LOIFile from '../components/LOIFile';
import CreateListingPrivateEvent from '../components/private-event/CreateListingPrivateEvent';

const Container = styled.div`
  flex: 1;

  ${media.mobile} {
    width: calc(100vw - 32px);
  }

  ${media.tablet} {
    margin: 16px;
  }
`;

const EmailListHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background-color: ${Colors.Grey100};
  border-radius: 4px;
  height: 32px;
  margin-bottom: 4px;
  box-sizing: border-box;
`;

const Dashed = styled.div`
  border: 1px dashed ${Colors.Grey300};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const EmailListRow = styled.div`
  display: flex;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 4px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  margin: 0 0 24px;
  width: 100%;
  box-sizing: border-box;

  ${media.mobile} {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
`;

const EmailTextContainer = styled.div`
  max-width: 100%;

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type CreatePrivateEventProps = {};

const CreatePrivateEvent: React.FC<CreatePrivateEventProps> = () => {
  /** State */
  const [email, setEmail] = React.useState('');
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [duplicateEmailError, setDuplicateEmailError] = React.useState('');

  /** Actions */
  const dispatch = useAppDispatch();
  const setPrivateEventField = (fields: Partial<IPrivateEventGraphQL>) => dispatch(
    PrivateEventActions.setPrivateEventField(fields),
  );
  const setValidationErrors = (errors: Record<string, string>) => dispatch(
    PrivateEventActions.setErrors(errors),
  );
  const setIsNewListing = (isNewListing: boolean) => dispatch(
    PrivateEventActions.setIsNewPrivateEventListing(isNewListing),
  );
  const privateEvent = useAppSelector(PrivateEventSelectors.privateEvent);
  const errors = useAppSelector(PrivateEventSelectors.errors);
  const isNewListing = useAppSelector(PrivateEventSelectors.isNewListing);

  /** Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadFiles({
      variables: {
        params: fileParams[0],
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /** GraphQL */
  const [uploadFiles, { loading: fileLoading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      setFiles(data?.createFiles);
      setPrivateEventField({ psaFileIds: data?.createFiles?.map((file: IFile) => file?._id) });
    },
  });

  /** Effects */
  React.useEffect(() => {
    if (isNewListing) {
      setIsNewListing(false);
    }
  }, []);

  /** Render */
  const inputHandler = (field: Partial<IPrivateEventGraphQL>) => {
    if (errors) setValidationErrors(null);
    setPrivateEventField(field);
  };

  return (
    <Flex>
      <Container>
        <Text data-cy="private-events-creation-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>
          Create private event
        </Text>
        <Text color={Colors.Grey700} type={TextTypesEnum.Regular16} margin="0 0 24px">
          Fill out the fields below to create a private event.
          For more information on how private events work,
          &nbsp;
          <Text
            type={TextTypesEnum.Regular16}
            as={TextAsEnum.Span}
            color={Colors.Brand700 || Colors.Blurple700}
            onClick={() => window.open(KB_PRIVATE_EVENT_USE_URL, '_blank')}
          >
            click here.
          </Text>
        </Text>
        <Row>
          <Input
            data-cy="starting-bid"
            label="Starting bid"
            placeholder="Enter a price"
            value={privateEvent?.settings?.startingBid?.toString()}
            onChange={(_e, unmasked) => inputHandler({
              settings: {
                ...privateEvent?.settings,
                startingBid: parseInt(unmasked, 10),
              },
            })}
            inputType={InputTypesEnum.Currency}
            error={errors?.['/settings'] || errors?.['/settings/startingBid']}
          />
          <Input
            data-cy="bid-increment"
            label="Bid increments"
            placeholder="Enter a bid increment"
            value={privateEvent?.settings?.bidIncrement?.toString()}
            onChange={(_e, unmasked) => inputHandler({
              settings: {
                ...privateEvent?.settings,
                bidIncrement: parseInt(unmasked, 10),
              },
            })}
            inputType={InputTypesEnum.Currency}
            error={errors?.['/settings'] || errors?.['/settings/bidIncrement']}
          />
        </Row>
        <Row>
          <DateInput
            dataCy="start-date"
            label="Start Date"
            value={privateEvent?.startsAt}
            onChange={(millis: number) => inputHandler({ startsAt: millis })}
            inputType={DateInputTypeEnum.DateTime}
            width="100%"
            error={errors?.['/startsAt']}
          />
          <DateInput
            dataCy="end-date"
            label="End Date"
            value={privateEvent?.endsAt}
            onChange={(millis: number) => inputHandler({ endsAt: millis })}
            inputType={DateInputTypeEnum.DateTime}
            width="100%"
            error={errors?.['/endsAt']}
          />
        </Row>
        <Divider margin="0 0 24px" />
        <Text color={Colors.Black} type={TextTypesEnum.Bold18}>
          Contingencies (optional)
        </Text>
        <Text color={Colors.Grey700} type={TextTypesEnum.Regular16} margin="0 0 24px">
          Set the contingencies that the participants will agree to in order to particpate in the private event.
        </Text>
        <Input
          data-cy="earnest-money-deposit"
          label="Earnest money deposit"
          placeholder="5,000"
          width="100%"
          margin="0 0 24px"
          value={privateEvent?.contingencies?.depositAmount?.toString()}
          onChange={(_e, unmasked) => inputHandler({
            contingencies: {
              ...privateEvent?.contingencies,
              depositAmount: parseInt(unmasked, 10),
            },
          })}
          inputType={InputTypesEnum.Currency}
          error={errors?.['/contingencies'] || errors?.['/contingencies/depositAmount']}
        />
        <Row>
          <Input
            data-cy="due-diligence-period"
            label="Due diligence period"
            placeholder="30"
            value={privateEvent?.contingencies?.dueDiligencePeriod?.toString()}
            onChange={(_e, unmasked) => inputHandler({
              contingencies: {
                ...privateEvent?.contingencies,
                dueDiligencePeriod: parseInt(unmasked, 10),
              },
            })}
            inputType={InputTypesEnum.Number}
            unit="days"
            error={errors?.['/contingencies'] || errors?.['/contingencies/dueDiligencePeriod']}
          />
          <Input
            data-cy="closing-period"
            label="Closing period"
            placeholder="30"
            value={privateEvent?.contingencies?.closingPeriod?.toString()}
            onChange={(_e, unmasked) => inputHandler({
              contingencies: {
                ...privateEvent?.contingencies,
                closingPeriod: parseInt(unmasked, 10),
              },
            })}
            inputType={InputTypesEnum.Number}
            unit="days"
            error={errors?.['/contingencies'] || errors?.['/contingencies/closingPeriod']}
          />
        </Row>
        <Input
          data-cy="additional-terms"
          label="Additional terms"
          width="100%"
          placeholder="Enter any additional terms"
          value={privateEvent?.contingencies?.additionalTerms}
          onChange={(e) => inputHandler({
            contingencies: {
              ...privateEvent?.contingencies,
              additionalTerms: e.target.value,
            },
          })}
          error={errors?.['/contingencies'] || errors?.['/contingencies/additionalTerms']}
          inputType={InputTypesEnum.TextArea}
          height="140px"
          margin="0 0 24px"
        />
        <Divider margin="0 0 24px" />
        <Text color={Colors.Black} type={TextTypesEnum.Bold18}>
          Add users
        </Text>
        <Text color={Colors.Grey700} type={TextTypesEnum.Regular16} margin="0 0 24px">
          Invite users to the private event.
        </Text>
        <Flex margin="0 0 24px" align="flex-end">
          <Input
            data-cy="invite-email"
            label="Email"
            placeholder="Email address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (duplicateEmailError) setDuplicateEmailError('');
            }}
            inputType={InputTypesEnum.Text}
            error={errors?.['/participants'] || duplicateEmailError}
            onKeyPress={(e) => {
              if (e.which === KeyCodesEnum.ENTER && email) {
                const isDuplicate = privateEvent?.participants?.some((p) => p.email === email);
                if (isDuplicate) {
                  setDuplicateEmailError('This email has already been added.');
                  return;
                }
                setDuplicateEmailError('');
                inputHandler({ participants: [...(privateEvent?.participants || []), { email }] });
                setEmail('');
              }
            }}
          />
          <Button
            data-cy="add-user-button"
            margin="0 0 0 24px"
            text="Add user"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            onClick={() => {
              if (email) {
                const isDuplicate = privateEvent?.participants?.some((p) => p.email === email);
                if (isDuplicate) {
                  setDuplicateEmailError('This email has already been added.');
                  return;
                }
                setDuplicateEmailError('');
                inputHandler({ participants: [...(privateEvent?.participants || []), { email }] });
                setEmail('');
              }
            }}
          />
        </Flex>
        {privateEvent?.participants?.length > 0 && (
          <>
            <EmailListHeader>
              <Text color={Colors.Grey600} type={TextTypesEnum.Medium12}>
                Email
              </Text>
            </EmailListHeader>
            {privateEvent?.participants?.map((participant, index) => (
              <EmailListRow key={index}>
                <EmailTextContainer>
                  <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
                    {participant?.email}
                  </Text>
                </EmailTextContainer>
                <Text
                  color={Colors.Brand700 || Colors.Blurple700}
                  type={TextTypesEnum.Bold12}
                  margin="0 0 0 16px"
                  onClick={() => {
                    inputHandler({ participants: privateEvent?.participants?.filter((p) => p?.email !== participant?.email) });
                  }}
                >
                  Remove
                </Text>
              </EmailListRow>
            ))}
          </>
        )}
        <Divider margin="0 0 24px" />
        <Text color={Colors.Black} type={TextTypesEnum.Bold18}>
          Add PSA (optional)
        </Text>
        <Text color={Colors.Grey700} type={TextTypesEnum.Regular16} margin="0 0 24px">
          Upload a Purchase and Sale Agreement (PSA) that bidders will have to accept in order to participate in the private event.
        </Text>
        {(!fileLoading && files?.length <= 0) ? (
          <Dashed {...getRootProps()}>
            <input {...getInputProps()} />
            <Flex align="center">
              <Icon icon={Icons.FilePdfLight} color={Colors.Grey500} size={18} margin="0 8px 0 0" />
              <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                Drag a PDF file here or
                &nbsp;
                <Text
                  type={TextTypesEnum.Medium14}
                  as={TextAsEnum.Span}
                  color={Colors.Brand700 || Colors.Blurple700}
                >
                  click to upload
                </Text>
              </Text>
            </Flex>
          </Dashed>
        ) : (
          <LOIFile
            file={files[0]}
            deleteFile={() => {
              setFiles([]);
              inputHandler({ psaFileIds: [] });
            }}
          />
        )}
        <Divider margin="24px 0" />
        <Text color={Colors.Black} type={TextTypesEnum.Bold18}>
          Listings
        </Text>
        {/* Temp Comment until I figure out listing inline stuff */}
        <Text color={Colors.Grey700} type={TextTypesEnum.Regular16} margin="0 0 24px">
          Choose an existing listing or create a new listing for the event. Note that listings created here will only be visible to users participating in the private event.
        </Text>
        <Toggle
          value={isNewListing}
          onChange={(active) => setIsNewListing(active)}
          margin="0px 0px 24px"
          customNoText="Existing listing"
          customYesText="New listing"
        />
        {isNewListing ? (
          <CreateListingPrivateEvent />
        ) : (
          <ListingSelectionDropdown
            data-cy="listing-selection-dropdown"
            error={errors?.['/listingId']}
            prohibitedListingState={[ListingStateEnum.Private]}
          />
        )}
      </Container>
      <CreatePrivateEventCard />
    </Flex>
  );
};

export default CreatePrivateEvent;
