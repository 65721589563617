import React from 'react';
import styled from '@emotion/styled';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import ListingCardThumbnail from './ListingCardThumbnail';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Tag from '../elements/Tag';
import useHandleRouteChange from '../hooks/useHandleRouteChange.hook';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  
  cursor: pointer;
`;

const ListingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 100%;
`;

export type TeamListingCardProps = {
  listing: INeo4jServiceAPI.INeo4jListing;
};

const ListingCard: React.FC<TeamListingCardProps> = ({ listing }) => {
  const {
    name,
    state,
    address1,
    city,
    addressState,
    zip,
    assetClass,
    propertyType,
    createdByUser,
  } = listing;

  const stateTagColor = (() => {
    switch (listing?.state) {
      case ListingStateEnum.Active:
        return {
          bgColor: Colors.Green100,
          textColor: Colors.Green800,
        };
      case ListingStateEnum.Draft:
        return {
          bgColor: Colors.Grey100,
          textColor: Colors.Grey800,
        };
      case ListingStateEnum.ReadyForReview:
      case ListingStateEnum.UnderContract:
        return {
          bgColor: Colors.Yellow100,
          textColor: Colors.Yellow800,
        };
      case ListingStateEnum.Closed:
      case ListingStateEnum.Private:
        return {
          bgColor: Colors.Brand100 || Colors.Blurple100,
          textColor: Colors.Brand800 || Colors.Blurple800,
        };
      case ListingStateEnum.Inactive:
        return {
          bgColor: Colors.Red100,
          textColor: Colors.Red800,
        };
      case ListingStateEnum.CallForOffers:
        return {
          bgColor: Colors.Green100,
          textColor: Colors.Green800,
        };
      case ListingStateEnum.Scheduled:
        return {
          bgColor: Colors.Grey100,
          textColor: Colors.Grey800,
        };
      case ListingStateEnum.Expired:
        return {
          bgColor: Colors.Red100,
          textColor: Colors.Red800,
        };
      default:
        return {
          bgColor: Colors.Grey100,
          textColor: Colors.Grey800,
        };
    }
  })();

  const handleRouteChange = useHandleRouteChange();

  const completeAddress: string = `${address1} ${city}, ${addressState} ${zip}`;
  return (
    <Container onClick={() => handleRouteChange(`/listing/${listing?._id}`)}>
      <ListingCardThumbnail
        type={ListingCardTypesEnum.Personal}
        listing={listing as any}
        listingImage={listing?.listingPrimaryListingUrl}
        width="129px"
      />
      <ListingInfoContainer>
        <Flex
          gap="8px"
          align="center"
        >
          <Text
            color={Colors.Grey900}
            type={TextTypesEnum.Bold14}
            data-cy="listing-table-listing-name"
          >
            {name}
          </Text>
          <Tag
            text={state}
            customTagColor={stateTagColor?.bgColor}
            customTextColor={stateTagColor?.textColor}
            height="16px"
            width="fit-content"
            textSize={TextTypesEnum.Regular12}
          />
        </Flex>
        {/* <Tag
          text={`${createdByUser?.[0]?.firstName} ${createdByUser?.[0]?.lastName}`}
          customTagColor={Colors.Blurple700}
          customTextColor={Colors.White}
          height="16px"
          width="fit-content"
          textSize={TextTypesEnum.Regular12}
        /> */}
        <Flex
          gap="4px"
          dataCy="listing-table-created-by"
        >
          <Text
            color={Colors.Grey700}
            type={TextTypesEnum.Regular12}
          >
            {'Created by: '}
          </Text>
          <Text
            color={Colors.Grey700}
            type={TextTypesEnum.Regular12}
          >
            {`${createdByUser?.[0]?.firstName} ${createdByUser?.[0]?.lastName}`}
          </Text>
        </Flex>
        <Text
          color={Colors.Grey700}
          type={TextTypesEnum.Regular14}
        >
          {completeAddress}
        </Text>
        <Text
          color={Colors.Grey400}
          type={TextTypesEnum.Regular12}
        >
          {`${assetClass} • ${propertyType}`}
        </Text>
      </ListingInfoContainer>
    </Container>
  );
};

export default ListingCard;
