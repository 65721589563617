/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from '@emotion/styled';
import StringUtil from '@biproxi/models/utils/StringUtil';
import IParcelBoundaryDetails from '@biproxi/models/interfaces/IParcelBoundaryDetails';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';

// type MarkerWrapperProps = {
//     lat: number;
//     lng: number;
// }

const MarkerWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const MarkerIconWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
`;

const MarkerAddressBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  min-width: 180px;

  background: ${Colors.White};
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);

  position: absolute;
  /* right: -130px; */
`;

type MapMarkerProps = {
    lat: number;
    lng: number; // lat and lng are not actually used within this file; however, if you are using this as a child compponent for Google Map, you NEED to pass lat/lng props for the Google Map component to properly place this component
    setClickedCoordinate: React.Dispatch<React.SetStateAction<string[]>>;
    clickedFeatureData: IParcelBoundaryDetails
};

const MapMarker: React.FC<MapMarkerProps> = ({
  setClickedCoordinate,
  clickedFeatureData,
}) => (
  <MarkerWrapper>
    <Icon
      icon={Icons.MapMarkerAltSolid}
      color={Colors.Brand700 || Colors.Blurple700}
      size={24}
    />
    <MarkerAddressBox>
      <MarkerIconWrapper>
        <Icon
          icon={Icons.TimesLight}
          color={Colors.Grey900}
          onClick={(e) => {
            e.stopPropagation();
            setClickedCoordinate([]);
          }}
        />
      </MarkerIconWrapper>
      <Text
        type={TextTypesEnum.Bold18}
        color={Colors.Grey900}
        margin="0 4px 0 0"
      >
        {StringUtil.capitalizeMultiWordString(clickedFeatureData?.address)}
      </Text>
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey700}
      >
        {StringUtil.capitalizeMultiWordString(clickedFeatureData?.city)}
        ,
        {' '}
        {clickedFeatureData?.state}
        {' '}
        {clickedFeatureData?.zip}
      </Text>
    </MarkerAddressBox>
  </MarkerWrapper>
);

const arePropsEqual = (prevProps, nextProps) => prevProps.lat === nextProps.lat && prevProps.lng === nextProps.lng;

export default React.memo(MapMarker, arePropsEqual);
// export default MapMarker;
