import React from 'react';
import styled from '@emotion/styled';
// import Colors from '../styles/Colors';
// import Text, { TextTypesEnum } from '../elements/Text';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useNavigate } from 'react-router-dom';
import Divider from '../elements/Divider';
import PDPHeader from '../components/pdp/PDPHeader';
import PDPImages from '../components/pdp/PDPImages';
import PDPNavigation from '../components/pdp/PDPNavigation';
import getFilteredPDPSections from '../utils/getFilteredPDPSections';
import {
  media, useMobileMedia, useTabletMedia,
} from '../utils/MediaQuery';
import PrivateEventBiddingCard from '../components/private-event/PrivateEventBiddingCard';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';
import PDPDataExplorer from '../components/pdp/PDPDataExplorer';
import Auth from '../utils/Auth';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import { useAppDispatch, useAppSelector } from '../redux/store';
import usePrivateEventSubscription from '../hooks/usePrivateEventSubscription.hook';
import useUser from '../hooks/useUser.hook';

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${media.mobile} {
    margin: -24px -16px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  /* Hack, should fix but need to adjust global page widths :( */
  /* width: calc(100% + 70px); */

  ${media.mobile} {
    width: 100%;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  /* flex: 1; */
`;

const InnerContent = styled.div`
  display: flex;
`;

const ScrollableContent = styled.div`
  max-width: 840px;
  width: fill-available;

  ${media.mobile} {
    width: 100%;
    margin: 0px 16px;
  }

  ${media.tablet} {
    width: 100%;
    margin: 0px 16px;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavContainer = styled.div``;

const Spacer = styled.div`
  height: 32px;
`;

type EventParticipationProps = {};

const EventParticipation: React.FC<EventParticipationProps> = () => {
  /** Hooks */
  const { privateEvent, refetch } = usePrivateEventSubscription();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const { userId } = useUser();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const selectListing = (listingId) => dispatch(
    ListingActions.selectListing({
      listingId,
    }),
  );
  const listing = useAppSelector(ListingSelectors.selectedListing);
  const cacheListing = (listing: IListingGraphQL) => dispatch(ListingActions.cacheListings({ listings: [listing] }));

  /** Effects */
  React.useEffect(() => {
    if (privateEvent?.listing?._id) {
      /**
       * Need to cache and select the listing in redux for
       * the file preview to work here.
       */
      cacheListing(privateEvent?.listing);
      selectListing(privateEvent?.listing._id);
    }
    return () => {
      selectListing(null);
    };
  }, [privateEvent?.listing]);

  /** Render */
  if (!listing || !privateEvent) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  // security check
  if (userId !== privateEvent?.userId && !privateEvent?.participants?.find((p) => p.userId === userId)) {
    navigate('/app/dashboard/events');
  }

  const sections = getFilteredPDPSections(listing);
  return (
    <Container>
      <PageContainer>
        <ContentContainer>
          {isMobile || isTablet ? (
            <PDPNavigation listing={listing} />
          ) : (
            <PDPHeader listing={listing} isPreviewPDP />
          )}
          {isMobile && <PDPDataExplorer listing={listing} isUserLoggedIn={Auth.isAuthenticated()} />}
          <PDPImages listing={listing} />
          {!isMobile && <PDPDataExplorer listing={listing} isUserLoggedIn={Auth.isAuthenticated()} />}
          <InnerContent>
            <ScrollableContent>
              {isMobile || isTablet ? (
                <>
                  <PrivateEventBiddingCard privateEvent={privateEvent} refetchPrivateEvent={refetch} />
                  <Divider margin="24px 0px 0px 0px" />
                </>
              ) : (
                <PDPNavigation listing={listing} isPreviewPDP />
              )}
              <Spacer />
              {sections.map((section, index) => (
                <>
                  {section.component}
                  {index !== sections.length - 1 && (
                  <Divider margin="24px 0px 32px 0px" />
                  )}
                </>
              ))}
            </ScrollableContent>
            {(!isMobile && !isTablet) && (
              <NavContainer>
                <PrivateEventBiddingCard privateEvent={privateEvent} refetchPrivateEvent={refetch} />
              </NavContainer>
            )}
          </InnerContent>
        </ContentContainer>
      </PageContainer>
    </Container>
  );
};

export default EventParticipation;
