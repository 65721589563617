import React from 'react';
import styled from '@emotion/styled';
import IListingGuidance from '@biproxi/models/interfaces/IListingGuidance';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import BrokerCommisionEnum from '@biproxi/models/enums/BrokerCommisionEnum';
import { KB_CREATE_LISTING_GUIDANCE_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Dropdown from '../../elements/Dropdown';
import Input, { InputTypesEnum } from '../../elements/Input';
import Toggle from '../../elements/Toggle';
import { useAppDispatch } from '../../redux/store';
import { ListingActions } from '../../redux/listing.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import GenericToggle, { GenericToggleTextAlignEnum } from '../../elements/GenericToggle';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import DateInput, { DateInputTypeEnum } from '../../elements/DateInput';
import InlineAlert, { InlineAlertTypesEnum, InlineAlertStylesEnum } from '../InlineAlert';

const Grid = styled.div<{margin?: boolean}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 24px;
  margin-bottom: ${(props) => (props.margin ? '32px' : null)};

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CreateListingPricingGuidanceProps = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingPricingGuidance: React.FC<CreateListingPricingGuidanceProps> = ({
  id,
  listing,
}) => {
  /** State */
  const [callForOffersEnabled, setCallForOffersEnabled] = React.useState(Boolean(listing?.guidance?.callForOffersAt));

  /** Hooks */
  const isMobile = useMobileMedia();

  /* Actions */
  const dispatch = useAppDispatch();

  const setGuidance = (params: Partial<IListingGuidance>) => dispatch(
    ListingActions.setListingGuidanceField(params),
  );

  /** Effects */
  React.useEffect(() => {
    if (!callForOffersEnabled) {
      setGuidance({ callForOffersAt: null });
    }
  }, [callForOffersEnabled]);

  /* Render */
  return (
    <ContentCard
      id={id}
      dataCy="price-and-guidance-greeting"
      title="Price & Guidance"
      subtitle="Provide basic deal guidance for investors to begin the negotiation process on your listing. The guidance will appear during the LOI submission."
      learnMoreLink={KB_CREATE_LISTING_GUIDANCE_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <Grid margin>
        <Toggle
          cyYes="priced-yes-toggle"
          cyNo="priced-no-toggle"
          label="Priced"
          value={listing.guidance.priced}
          onChange={(active) => setGuidance({ priced: active, askingPrice: null, depositAmount: null })}
        />
        <Input
          data-cy="asking-price-input"
          label="Asking price"
          placeholder={listing.guidance.priced ? '1,000,000' : 'No price set'}
          value={listing.guidance.askingPrice?.toString() ?? ''}
          onChange={(_event, unmasked) => setGuidance({ askingPrice: unmasked })}
          inputType={InputTypesEnum.Currency}
          disabled={!listing.guidance.priced}
        />
        <Input
          data-cy="deposit-amount-input"
          label="Deposit amount"
          placeholder={listing.guidance.priced ? '5,000' : 'No deposit set'}
          value={listing.guidance.depositAmount?.toString() ?? ''}
          onChange={(_event, unmasked) => setGuidance({ depositAmount: unmasked })}
          inputType={InputTypesEnum.Currency}
          disabled={!listing.guidance.priced}
        />
        <Dropdown
          cyInput="buyers-broker-commission-dropdown"
          cyList="buyers-broker-comission-list"
          label="Buyer's broker commission"
          value={listing.guidance.brokerCommision?.toString() ?? ''}
          onChange={(value) => setGuidance({ brokerCommision: value, brokerCommisionAmount: null })}
          placeholder="Select commission"
          items={Object.values(BrokerCommisionEnum).map((value) => ({
            name: value,
            value,
          }))}
        />
      </Grid>
      {listing.guidance.brokerCommision === BrokerCommisionEnum.Yes && (
        <Input
          data-cy="commission-amount-input"
          label="Commission Amount"
          placeholder="5.49"
          value={listing.guidance.brokerCommisionAmount?.toString() ?? ''}
          onChange={(event) => {
            setGuidance({ brokerCommisionAmount: event.currentTarget.value });
          }}
          inputType={InputTypesEnum.Percentage}
          margin="0 0 32px"
        />
      )}
      <Grid>
        <Input
          data-cy="due-diligence-input"
          label="Due diligence"
          placeholder="30"
          value={listing.guidance.dueDiligencePeriod?.toString() ?? ''}
          onChange={(_event, unmasked) => setGuidance({ dueDiligencePeriod: unmasked })}
          inputType={InputTypesEnum.Number}
          unit="days"
        />
        <Input
          data-cy="closing-period-input"
          label="Closing period"
          placeholder="30"
          value={listing.guidance.closingPeriod?.toString() ?? ''}
          onChange={(_event, unmasked) => setGuidance({ closingPeriod: unmasked })}
          inputType={InputTypesEnum.Number}
          unit="days"
        />
      </Grid>
      <Divider color={Colors.Grey200} margin="32px 0px" />
      <Flex
        direction={isMobile ? 'column' : 'row'}
        justify="space-between"
      >
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Black}
        >
          Call for offers
        </Text>
        <GenericToggle
          value={callForOffersEnabled}
          textAlign={GenericToggleTextAlignEnum.Left}
          onChange={() => {
            setCallForOffersEnabled(!callForOffersEnabled);
          }}
          margin={isMobile ? '8px 0px' : '0px'}
        />
      </Flex>
      <Text
        type={TextTypesEnum.Regular14}
        color={Colors.Grey700}
        width="80%"
        margin="0px 0px 24px 0px"
      >
        Enable a Call For Offers to display a date and a countdown to encourage buyers to submit their offer sooner.
      </Text>
      {callForOffersEnabled && (
        <DateInput
          label="Call for offers date"
          placeholder="Select a date"
          value={listing.guidance.callForOffersAt}
          onChange={(millis: number) => setGuidance({ callForOffersAt: millis })}
          inputType={DateInputTypeEnum.DateTime}
        />
      )}
      {(listing.guidance.callForOffersAt && (new Date(listing.guidance.callForOffersAt).getTime() < new Date().getTime())) && (
        <InlineAlert
          type={InlineAlertTypesEnum.RedWarning}
          styleOption={InlineAlertStylesEnum.WithTop}
          label="Your call for offers date has passed."
          text="Please set it in the future if you would like it to be displayed on the PDP."
          margin="24px 0px 0px 0px"
        />
      )}
    </ContentCard>
  );
};

export default CreateListingPricingGuidance;
