import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import Dropdown from '../../elements/Dropdown';
import { useAppDispatch } from '../../redux/store';
import { PrivateEventActions } from '../../redux/privateEvent.redux';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';

/** Hmm idk if this is the best solution to add space at the bottom when the dropdown is height maxed out but oh well */
const Container = styled.div`
  margin-bottom: 300px;
`;

type ListingSelectionDropdownProps = {
  error: string;
  prohibitedListingState?: ListingStateEnum[];
};

const ListingSelectionDropdown: React.FC<ListingSelectionDropdownProps> = ({
  error,
  prohibitedListingState = [],
}) => {
  /** State */
  const [curListing, setCurListing] = React.useState(null);

  /** Actions */
  const dispatch = useAppDispatch();
  const setPrivateEventField = (fields: Partial<IPrivateEventGraphQL>) => dispatch(
    PrivateEventActions.setPrivateEventField(fields),
  );

  const pushProhibitedListingModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.GeneralErrorModal,
      props: {
        modalText: 'Private marketplace listings are not compatible with Private Events. Either create a new listing or change the status of this listing to something other than private. Your Private Event listing will not appear in the marketplace.',
        textType: TextTypesEnum.Regular16,
      },
    }),
  );

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[]
    }
  }

  type Vars = {
    params: {
      query: IListingQuery
    }
  }
  const { data, loading } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Personal,
        },
      },
    },
  });

  if (!data?.listings?.data && !loading) {
    return (
      <Text
        type={TextTypesEnum.Medium14}
        color={Colors.Grey400}
      >
        No listings, create one and it will appear here.
      </Text>
    );
  }

  return (
    <Container>
      <Dropdown
        data-cy="listing-selection-dropdown"
        error={error}
        label="Choose a listing"
        value={ListingUtil.name(curListing, { allButAddress1: true }) || ''}
        onChange={(value: IListingGraphQL) => {
          if (prohibitedListingState.includes(value?.state)) {
            pushProhibitedListingModal();
          } else {
            setCurListing(value);
            setPrivateEventField({ listingId: value._id });
          }
        }}
        placeholder="Select a listing"
        items={data?.listings?.data?.map((value, index) => ({
          name: ListingUtil.name(value, { allButAddress1: true }) || 'Draft Listing',
          value,
          'data-cy': `listing-selection-item-${index}`,
        }))}
      />
    </Container>
  );
};

export default ListingSelectionDropdown;
