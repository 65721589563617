import React from 'react';
// import useOnclickOutside from 'react-cool-onclickoutside';
import styled from '@emotion/styled';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import Colors from '../../styles/Colors';
// import DropdownList, { TDropdownItemProps } from '../DropdownList';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';

// const DropdownItem = styled.div<{ isHovered: boolean }>`
//  display: flex;
//  align-items: center;
//  height: 28px;
//  width: 100%;
//  overflow: hidden;
//  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Grey100}` : null)};
//  &:hover {
//    background-color: ${Colors.Grey100};
//    color: ${Colors.Black};
//    cursor: pointer;
//  }
// `;

const Container = styled.div`
  > * {
    white-space: nowrap
  }
`;

const Dot = styled.span<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: 6px;
  height: 6px;
`;

const DotTag = styled.div<{ small: boolean }>`
  height: ${({ small }) => (small ? '20px' : '32px')};
  padding: 0px 12px;
  background: ${Colors.White};
  border-radius: 4px;
  border: 0.5px solid ${Colors.Grey300};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getDotColor = (state: PrivateEventStatesEnum) => {
  switch (state) {
    case PrivateEventStatesEnum.InProgress:
      return Colors.Green500;
    case PrivateEventStatesEnum.Upcoming:
      return Colors.Yellow400;
    case PrivateEventStatesEnum.Finished:
      return Colors.Brand700 || Colors.Blurple700;
    case PrivateEventStatesEnum.Canceled:
      return Colors.Grey400;
    default:
      return Colors.Grey400;
  }
};

// const ItemComponent = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
//   item,
//   onChange,
//   isHovered,
// }, ref) => {
//   const dotColor = getDotColor(item.value);
//   return (
//     <DropdownItem ref={ref} onClick={() => onChange(item)} isHovered={isHovered}>
//       <Flex align="center" margin={item?.value?.length ? '0px 16px' : '0px'}>
//         <Dot color={dotColor} />
//         <Text
//           type={TextTypesEnum.Regular16}
//           color={Colors.Grey900}
//           margin="0px 0px 0px 8px"
//         >
//           {item.name}
//         </Text>
//       </Flex>
//     </DropdownItem>
//   );
// });

type PrivateEventStatusDropdownProps = {
  value: any | null;
  small?: boolean;
}

/**
 * This componentent was originally a drop down but I don't think that makes any sense
 * so I disabled that. Should revisit this later and cleanup as it's kind of a mess.
 */
const PrivateEventStatusDropdown: React.FC<PrivateEventStatusDropdownProps> = ({
  value,
  small = false,
  // onChange,
}) => {
  /** State */
  // const [isOpen, setIsOpen] = React.useState(false);

  const buttonRef = React.useRef<HTMLDivElement>();

  // const ref = useOnclickOutside(() => {
  //   setIsOpen(false);
  // });

  // const items = [
  //   {
  //     name: PrivateEventStatesEnum.InProgress,
  //     value: PrivateEventStatesEnum.InProgress,
  //   },
  //   {
  //     name: PrivateEventStatesEnum.Upcoming,
  //     value: PrivateEventStatesEnum.Upcoming,
  //   },
  //   {
  //     name: PrivateEventStatesEnum.Finished,
  //     value: PrivateEventStatesEnum.Finished,
  //   },
  //   {
  //     name: PrivateEventStatesEnum.Canceled,
  //     value: PrivateEventStatesEnum.Canceled,
  //   },
  // ];

  /** Render */
  return (
    <Container
      // ref={ref}
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <DotTag
        small={small}
        ref={buttonRef}
        // onClick={() => {
        //   setIsOpen(true);
        // }}
      >
        <Flex align="center">
          <Dot color={getDotColor(value)} />
          <Text
            type={TextTypesEnum.Medium12}
            color={Colors.Grey900}
            margin="0px 0px 0px 8px"
          >
            {value}
          </Text>
        </Flex>
      </DotTag>
      {/* <DropdownList
        ref={buttonRef}
        value={value[0]}
        open={isOpen}
        setOpen={setIsOpen}
        onChange={(value) => console.log(value)}
        items={items}
        ItemComponent={ItemComponent}
        width="fit-content"
        right
      /> */}
    </Container>
  );
};

export default PrivateEventStatusDropdown;
