import { gql } from '@apollo/client';
import UserFragments from '../fragments/user.fragments';

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($params: UpdateUserProfileParams!) {
    updateUserProfile(params: $params) {
      ...UserFields
      ...UserLicenses
    }
  }

  ${UserFragments.fields}
  ${UserFragments.licenses}
`;

export default UPDATE_USER_PROFILE;
