import React from 'react';
import styled from '@emotion/styled';
import Checkbox from './Checkbox';
import Text, { TextTypesEnum } from './Text';
import Colors from '../styles/Colors';
import ISelectionBoxConfig from '../models/interfaces/ISelectionBoxConfig';

type ContainerProps = {
  margin?: string;
}

const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

type CheckboxListProps = {
  margin?: string;
  title?: string;
  config: ISelectionBoxConfig[];
  'data-cy'?: string;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  config,
  title,
  margin,
  'data-cy': dataCy,
}) => (
  <Container margin={margin}>
    {title && (
      <Text type={TextTypesEnum.Bold18} color={Colors.Black}>
        {title}
      </Text>
    )}
    {config.map((config, index) => (
      <Checkbox
        dataCy={dataCy}
        key={index}
        active={config.active}
        type={config.type}
        label={config.label}
        labelTextType={config.labelTextType}
        onClick={config.onClick}
      />
    ))}
  </Container>
);
export default CheckboxList;
