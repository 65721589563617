import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import PrivateEventsQueryTypeEnum from '@biproxi/models//enums/PrivateEventsQueryTypeEnum';
import PrivateEventParticipantStatesEnum from '@biproxi/models/enums/PrivateEventParticipantStatesEnum';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import * as url from 'url';
import ReactTooltip from 'react-tooltip';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { useNavigate } from 'react-router-dom';
import { KB_PRIVATE_EVENT_USE_URL } from '@biproxi/models/externalLinks';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import LIST_PRIVATE_EVENTS from '../graphql/queries/privateEvents.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import PrivateEventListingCard, { PrivateEventListingCardTypes } from '../components/private-event/PrivateEventListingCard';
import useUser from '../hooks/useUser.hook';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import { useAppDispatch } from '../redux/store';
import { media } from '../utils/MediaQuery';
import LinkText from '../elements/LinkText';
import Link from '../elements/Link';
// import Icon from '../elements/Icon';
import NextUtil from '../utils/NextUtil';
import Icon from '../elements/Icon';
import useUserPermissions, { Neo4jReducerStatusEnum } from '../hooks/useUserPermissions.hook';

const Container = styled.div`
  ${media.tablet} {
    // the css for this whole page is kinda whack for some reason
    width: calc(100vw - 64px);
    padding: 32px;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DashboardEventsProps = {};

const DashboardEvents: React.FC<DashboardEventsProps> = () => {
  /** Hooks */
  const navigate = useNavigate();
  const {
    user, userId,
  } = useUser();

  const userPermissions = useUserPermissions({ userId });

  const openBillingPaywallModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.CreatePrivateEvent,
      },
    }),
  );
  const permissionsReady = (!userPermissions?.loading && userPermissions.status !== Neo4jReducerStatusEnum.Idle);

  /** GraphQL */
  // broker events
  const { data, loading } = useQuery(LIST_PRIVATE_EVENTS, {
    variables: {
      params: {
        queryType: PrivateEventsQueryTypeEnum.Managing,
      },
    },
  });

  // Bidder events
  const { data: bidderData, loading: bidderLoading } = useQuery(LIST_PRIVATE_EVENTS, {
    variables: {
      params: {
        queryType: PrivateEventsQueryTypeEnum.Participating,
      },
    },
  });

  const dispatch = useAppDispatch();
  const pushAcceptPrivateEventInvitationModal = (privateEvent) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AcceptPrivateEventInvitation,
      props: {
        privateEvent,
      },
    }),
  );

  // If coming from the email link, push the invite modal on mount using url params
  React.useEffect(() => {
    const inviteId = url.parse(window.location.toString(), true)?.query?.inviteId || '';
    if (inviteId && bidderData?.privateEvents.length > 0) {
      const inviteEvent = bidderData.privateEvents.find((event: IPrivateEventGraphQL) => event._id === inviteId);
      if (inviteEvent?.participants?.find((participant) => participant.email === user.email)?.state === PrivateEventParticipantStatesEnum.Pending) {
        pushAcceptPrivateEventInvitationModal(inviteEvent);
      }
    }
  }, [bidderData, user]);

  /* Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /** Render */
  const createEventPaywallText = 'Sign up for a paid plan to enable creating your own private events!';
  const createPrivateEventPermission: boolean = PermissionsUtil.canUserCreatePrivateEvent(userPermissions?.userBiproxiRolesAndPermissions);
  return (
    <Container>
      <Flex align="center" justify="space-between" margin="0px 0px 24px">
        <Text data-cy="private-events-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>Events</Text>
        <Flex>
          <Button
            data-cy="create-event-button"
            text="Create Event"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            isLoading={!permissionsReady}
            onClick={() => ((!createPrivateEventPermission) ? openBillingPaywallModal() : navigate('/app/dashboard/events/create'))}
          />
          {!createPrivateEventPermission && (
            <Icon
              icon={Icons.InfoCircleRegular}
              size={12}
              margin="0 0 0 4px"
              tip={createEventPaywallText}
              data-multiline
            />
          )}
        </Flex>
      </Flex>
      {loading || bidderLoading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {(data?.privateEvents?.length > 0 || bidderData?.privateEvents.length > 0) ? (
            <>
              {data?.privateEvents.length > 0 && (<Text color={Colors.Grey700} margin="0px 0px 24px" type={TextTypesEnum.Medium18}>Events Managing</Text>)}
              {data?.privateEvents?.map((event: IPrivateEventGraphQL) => (
                <PrivateEventListingCard
                  key={event._id}
                  privateEvent={event}
                />
              ))}
              {bidderData?.privateEvents.length > 0 && (<Text color={Colors.Grey700} margin="24px 0px 24px" type={TextTypesEnum.Medium18}>Events Participating</Text>)}
              {bidderData?.privateEvents?.map((event: IPrivateEventGraphQL) => (
                <PrivateEventListingCard
                  key={event._id}
                  privateEvent={event}
                  type={PrivateEventListingCardTypes.Participating}
                  invitePending={event?.participants?.find((participant) => participant.email === user.email)?.state === PrivateEventParticipantStatesEnum.Pending}
                />
              ))}
            </>
          ) : (
            <NoContent
              data-cy="no-content-private-events"
              height="176px"
              icon={Icons.CalendarAltSolid} // TODO: change icon
              text={(
                <>
                  No private events yet. Create one or wait to get invited.&nbsp;
                  <Link href={KB_PRIVATE_EVENT_USE_URL} isExternal target="_blank">
                    <LinkText type={TextTypesEnum.Bold14}>Learn more about Biproxi private events here.</LinkText>
                  </Link>
                </>
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardEvents;
