import gql from 'graphql-tag';
import ChannelFragments from '../fragments/channel.fragments';

const query = gql`
  query Channel($params: GetChannelParams)  {
    channel(params: $params) {
      ...ChannelFields
      ...ChannelUser
      ...ChannelListing
    }
  }

  ${ChannelFragments.fields}
  ${ChannelFragments.user}
  ${ChannelFragments.listing}
`;

export default query;
