import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IListingVaultFolderGraphQL } from '@biproxi/models/interfaces/IListingVaultFolder';
import FileUtil from '@biproxi/models/utils/FileUtil';
import IFileParams from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import wait from '@biproxi/utils/wait';
import useOnclickOutside from 'react-cool-onclickoutside';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import { useMutation } from '@apollo/client';
import { useAppDispatch } from '../../redux/store';
import { ListingActions } from '../../redux/listing.redux';
import Colors from '../../styles/Colors';
import DraggableListItem from '../DraggableListItem';
import FileUploadBarStatus from '../FileUploadBarStatus';
import Text, { TextTypesEnum } from '../../elements/Text';
import DropdownList, { TDropdownItemProps } from '../DropdownList';
import AccessButton from '../../elements/AccessButton';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import SET_FILE_ACCESS_LEVEL from '../../graphql/mutations/setFileAccessLevel.mutation';
import Divider from '../../elements/Divider';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  &:active {
    background-color: none;
  }
`;

const LoaderBar = styled.div<{width: string}>`
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  background-color: ${Colors.Blurple5};
  width: ${(props) => `calc(${props.width}%)`};
  z-index: 1000;
`;

const AccessItemContainer = styled.div<{isHovered: boolean, margin: string}>`
  width: 240px;
  height: fit-content;
  display: grid;
  transition: all 0.1s;
  margin: ${({ margin }) => margin};
  color: ${({ isHovered }) => (isHovered ? `${Colors.Black}` : `${Colors.Grey200}`)};
  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Grey100}` : null)};

  > * {
    white-space: normal !important;
  }

  &:hover {
    background-color: ${Colors.Grey100};
    color: ${Colors.Black};
    cursor: pointer
  }
`;

type CreateListingVaultFileProps = {
  listing: IListingGraphQL;
  folder?: IListingVaultFolderGraphQL;
  file: IFile | IFileParams;
};

const CreateListingVaultFile: React.FC<CreateListingVaultFileProps> = ({
  listing,
  folder,
  file,
}) => {
  /* State */
  const isFile = FileUtil.isFile(file);
  const isCAFile = Boolean(!folder);
  const [percent, setPercent] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownAccessLevel, setDropdownAccessLevel] = React.useState(file?.accessLevel ?? FileAccessLevelsEnum.Level1);

  const sortButtonRef = React.useRef<HTMLDivElement>();
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  /* Hooks */
  React.useEffect(() => {
    async function inc() {
      if (percent < 99) {
        await wait(20);
        setPercent(percent + 3);
      }
    }
    inc();
  }, [percent]);

  /* Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  const previewListingVault = () => dispatch(
    ListingActions.previewListingVault({
      listingId: listing._id,
      fileId: file._id,
      isCAFile,
    }),
  );

  const previewCAFile = () => dispatch(
    AppActions.previewFiles({
      fileId: file._id,
      files: [listing.vault?.caFile],
      track: false,

    }),
  );

  const removeListingVaultFiles = () => {
    dispatch(
      ListingActions.removeListingVaultFiles({
        folderName: folder.name,
        fileIds: [file._id],
      }),
    );
    popModal();
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'File successfully deleted',
    });
  };

  const pushDeleteFileModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete file',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => removeListingVaultFiles(),
      },
    }),
  );

  const removeListingCAFile = () => dispatch(
    ListingActions.removeListingVaultCAFile({}),
  );

  /* GraphQL */
  const [setAccessLevel] = useMutation(SET_FILE_ACCESS_LEVEL, {
    onCompleted: async ({ setFileAccessLevel }) => {
      setDropdownAccessLevel(setFileAccessLevel.accessLevel);
    },
    onError: (error) => {
      logger.error('removeListingCAFile error', error);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error changing your file access level',
      });
    },
  });

  const menuItems = [
    {
      text: 'Preview',
      onClick: () => (isCAFile ? previewCAFile() : previewListingVault()),
    },
    {
      text: 'Delete',
      onClick: () => (isCAFile ? removeListingCAFile() : pushDeleteFileModal()),
    },
  ];

  const levels = [
    {
      name: FileAccessLevelsEnum.Level1,
      value: FileAccessLevelsEnum.Level1,
    },
    {
      name: FileAccessLevelsEnum.Level2,
      value: FileAccessLevelsEnum.Level2,
    },
  ];

  /* Render */
  return (
    <Container id="VaultFile">
      {!isFile && <LoaderBar width={percent.toString()} />}
      <DraggableListItem menuItems={menuItems}>
        <FileUploadBarStatus percent={percent} file={file} />
        {isFile ? (
          <div ref={ref}>
            <AccessButton
              ref={sortButtonRef}
              value={String(dropdownAccessLevel)}
              onClick={() => setIsOpen(true)}
              margin="0 4px 0 0"
            />
            <DropdownList
              ref={sortButtonRef}
              value={String(dropdownAccessLevel)}
              open={isOpen}
              ItemComponent={AccessItem}
              setOpen={setIsOpen}
              onChange={(value) => {
                setDropdownAccessLevel(value);
                setAccessLevel({
                  variables: {
                    params: {
                      fileId: file._id,
                      accessLevel: value,
                    },
                  },
                });
              }}
              width="240px"
              items={levels}
            />
          </div>
        ) : null}
      </DraggableListItem>
    </Container>
  );
};

const AccessItem = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
  item,
  onChange,
  isHovered,
  setOpen,
}, ref) => (
  <AccessItemContainer
    ref={ref}
    onClick={() => {
      onChange(item);
      setOpen(false);
    }}
    isHovered={isHovered}
    margin="0px"
  >
    <Text margin="8px 16px 0" color={Colors.Grey900} type={TextTypesEnum.Regular16}>{item.name}</Text>
    <Text margin="0 16px 8px" color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
      {item.value === FileAccessLevelsEnum.Level1
        ? 'Unlocked by signing a CA' : 'Unlocked by approving an access request'}
    </Text>
    {item.value === FileAccessLevelsEnum.Level1 && (
    <Divider />
    )}
  </AccessItemContainer>
));

export default CreateListingVaultFile;
