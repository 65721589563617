import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import BoxShadows from '../styles/BoxShadows';
import ListingThumbnail from './ListingCardThumbnail';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import { media, useMobileMedia } from '../utils/MediaQuery';
import useIsPrivateListing from '../hooks/useIsPrivateListing.hook';
import useUser from '../hooks/useUser.hook';
import { AppState, useAppSelector } from '../redux/store';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  transition: all 0.2s;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-shadow: ${BoxShadows.Light};

  &:hover {
    cursor: pointer;
    background: ${Colors.Grey50};
    border-radius: 4px;
    box-shadow: 0px 0px 0px 8px #F9FAFB;
  }
`;

const ActionOverlay = styled.div`
  position: absolute;
  /* margin: '16px 8px 0px 0px'; */
  right: 8px;
  top: 8px;
`;

const ListingCardContent = styled.div`
  &, & > div > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin: 0px 0px 0px 16px;

  ${media.mobile} {
    max-width: 200px;
  }
`;

const Dot = styled.span`
  color: ${Colors.Grey400};
`;

type ListingCardProps = {
    listing: IListingGraphQL | null;
    onClick?: () => void;
}

const ListingCard: React.FC<ListingCardProps> = ({ listing, onClick }) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** Contentful Variables */
  const {
    contentful: {
      brandCollection: {
        companyMediaAssets,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const privateListingImage = companyMediaAssets?.lockedListingImage?.url;

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /** Hooks */
  const isMobile = useMobileMedia();
  const { user } = useUser();
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      listing,
      user,
    },
  });

  /** Render */
  const click = onClick ? () => onClick() : null;
  const sqFt = listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value ?? null;

  return (
    <Container onClick={click}>
      <ListingThumbnail
        type={ListingCardTypesEnum.Dashboard}
        listing={listing}
        privateListingProps={{
          isPrivateListing,
          isPrivateListingAccess,
        }}
        listingImage={isPrivateListing && !isPrivateListingAccess ? privateListingImage : listing?.media?.files?.[0]?.url}
        width="91px"
      />
      <ListingCardContent>
        <Flex align="center">
          <Text type={TextTypesEnum.Bold16} color={Colors.Black} skWidth={160}>{ListingUtil.name(listing, { allButAddress1: true })}</Text>
        </Flex>
        <Flex align="center">
          {isPrivateListing && !isPrivateListingAccess
            ? null
            : (
              <Flex align="center">
                {listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey700} skWidth={280}>
                  {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, listing?.name ? {} : { address1: true }) || null}
                </Text>
              </Flex>
            )}
        </Flex>
        {listing ? (
          <Flex align="center" margin="4px 0 0">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey700}
              skWidth={240}
            >
              {(() => {
                if (!listing?.assetClass) return null;
                return (
                  <>
                    {listing?.assetClass}
                    <Dot>&nbsp;&bull;&nbsp;</Dot>
                    {listing?.propertyType}
                    {Boolean(sqFt) && (
                    <>
                      <Dot>&nbsp;&bull;&nbsp;</Dot>
                      {`${StringUtil.formatNumber(sqFt as number)} sq. ft.`}
                    </>
                    )}
                  </>
                );
              })()}
            </Text>
          </Flex>
        ) : (
          <Flex align="center" margin="4px 0 0">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey700}
              skWidth={192}
            />
          </Flex>
        )}
        {!isMobile && (
        <ActionOverlay>
          <Icon
            icon={Icons.ExternalLinkRegular}
            color={Colors.Grey900}
            size="12px"
          />
        </ActionOverlay>
        )}
      </ListingCardContent>
    </Container>
  );
};

export default React.memo(ListingCard);
