import React from 'react';
import styled from '@emotion/styled';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import Colors from '../styles/Colors';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';

  type ThumbnailContainerProps = {
    state: ListingUploadStateEnum;
    width?: string;
  }

const ThumbnailContainer = styled.div<ThumbnailContainerProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    width: ${(props) => (props.width ? props.width : '100%')};
    aspect-ratio: 1.5;
    border-radius: 8px;
    background-color: ${({ state }) => {
    switch (state) {
      case ListingUploadStateEnum.NotStarted:
        return Colors.Grey300;
      case ListingUploadStateEnum.InProgress:
      default:
        return Colors.Yellow400;
    }
  }};
    border-radius: 8px;
    margin: 0px 16px 0px 0px;
    padding: 16px;
  `;

const Placeholder = styled.div`
    width: 100%;
  `;

  type ListingUploadCardThumbnailProps = {
      listingUpload: IListingUploadGraphQL;
      width: string;
  }

const ListingUploadCardThumbnail: React.FC<ListingUploadCardThumbnailProps> = ({
  listingUpload,
  width = '100%',
}) => (
  <ThumbnailContainer state={listingUpload.state} width={width}>
    <Placeholder>
      <Icon
        color={Colors.Grey900}
        icon={listingUpload.state === ListingUploadStateEnum.NotStarted ? Icons.ClockSolid : Icons.TasksSolid}
        size="32px"
      />
    </Placeholder>
  </ThumbnailContainer>
);

export default ListingUploadCardThumbnail;
