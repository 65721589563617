import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import Loader, { LoaderSizes } from '../../../elements/Loader';
import Colors from '../../../styles/Colors';
import NoContent from '../../NoContent';
import Icons from '../../../elements/Icons';
import { useAppDispatch } from '../../../redux/store';
import GenericCard from '../../../elements/GenericCard';
import Text, { TextTypesEnum } from '../../../elements/Text';
import UserInfo from '../../UserInfo';
import { LeadActions } from '../../../redux/lead.redux';
import { AppActions } from '../../../redux/app.redux';
import { ModalTypesEnum } from '../../modal/Modal';
import { useMobileMedia } from '../../../utils/MediaQuery';
import useNavigateToListingDetails from '../../../hooks/useNavigateToListingDetails.hook';
import LinkText from '../../../elements/LinkText';

const Center = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeadRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${Colors.Grey300};
  height: 56px;
  padding: 0px 4px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: ${Colors.Grey50};
  }

  &:last-of-type {
    border-bottom: 0;
  }

  // if only one item, underline it
  &:first-of-type {
    border-bottom: 0.5px solid ${Colors.Grey300};
  }
`;

const UserInfoContainer = styled.div`
  margin-right: 16px;
  /**
   * I will buy you a coffee if you can
   * get this ellipsis to work correctly
   * on small screens and not just cut off
   * the text.
   */
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TextContainer = styled.div`
  min-width: fit-content;
`;

type RecentLeadsCardProps = {
  listingId: string;
  nonInvestorMatchLeads: Partial<ILeadGraphQL>[];
  loading: boolean;
};

const RecentLeadsCard: React.FC<RecentLeadsCardProps> = ({ listingId, nonInvestorMatchLeads, loading }) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const navigateToListingDetails = useNavigateToListingDetails();

  /** Actions */
  const dispatch = useAppDispatch();
  const pushLeadModal = (leadId: string) => {
    dispatch(LeadActions.selectLead({
      leadId,
    }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {},
    }));
  };

  /** Render */
  return (
    <GenericCard height="348px" width={isMobile ? '100%' : '33%'}>
      <LinkText
        onClick={() => navigateToListingDetails(listingId, '/leads')}
      >
        Recent Leads
      </LinkText>
      {loading ? (
        <Center>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </Center>
      ) : (
        <>
          {nonInvestorMatchLeads?.length > 0 ? nonInvestorMatchLeads
            .filter((_, index) => index < 4) // max 5 leads
            .reverse()
            .map((lead: ILeadGraphQL) => {
              const created = StringUtil.capitalize(
                TimeUtil.date(
                  lead.meta.createdAt,
                  TimeUtil.currentBrowserTimezone(),
                ).toRelativeCalendar(),
              );
              return (
                <LeadRow key={lead._id} onClick={() => pushLeadModal(lead._id)}>
                  <UserInfoContainer>
                    <UserInfo user={lead.user} />
                  </UserInfoContainer>
                  <TextContainer>
                    <Text type={TextTypesEnum.Regular12} color={Colors.Grey700} noWrap>{created}</Text>
                  </TextContainer>
                </LeadRow>
              );
            }) : (
              <Center>
                <NoContent
                  height="100%"
                  icon={Icons.UsersLight}
                  text="No leads yet."
                />
              </Center>
          )}
        </>
      )}
    </GenericCard>
  );
};

export default RecentLeadsCard;
