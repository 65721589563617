import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
// import Icon, { Icons } from '../../elements/Icon';
import InlineAlert, { InlineAlertTypesEnum, InlineAlertStylesEnum } from '../InlineAlert';
import { ModalTypesEnum } from '../modal/Modal';
import { AppActions } from '../../redux/app.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0px;
  box-sizing: border-box;

  ${media.mobile} {
    padding: 0px;
    flex-direction: column;
    justify-items: flex-start;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const SectionTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 40px 0px 0px;

  ${media.mobile} {
    padding: 40px 0px;
    width: 100%;
  }
`;

const MobileSpacer = styled.div`
  height: 40px;
`;

type SettingsPasswordProps = {
  user: IUserGraphQL;
};

const SettingsPassword: React.FC<SettingsPasswordProps> = ({ user }) => {
  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useDispatch();

  const pushChangePasswordModal = () => {
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.ChangePassword,
      props: {},
    }));
  };

  /* Render */
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Password
          </Text>
          {/*     <Icon
            icon={Icons.InfoSquareSolid}
            size={14}
            color={Colors.Grey400}
            margin="0px 0px 0px 8px"
          /> */}
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Keep your account secure with a strong password.
        </Text>
      </SectionTitle>
      <Flex width={isMobile ? '100%' : '60%'}>
        <Flex direction="column" width="100%">
          {user?.passwordLastSet && (
            <InlineAlert
              type={InlineAlertTypesEnum.GreyNotice}
              styleOption={InlineAlertStylesEnum.NoTop}
              text={`Password last set on ${new Date(user?.passwordLastSet).toLocaleDateString()}`}
              margin="0px 0px 32px 0px"
            />
          )}
          <Flex>
            <Button
              data-cy="first-change-password-button"
              text="Change password"
              margin="0px 8px 0px 0px"
              type={ButtonTypesEnum.Primary}
              size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
              onClick={() => pushChangePasswordModal()}
            />
          </Flex>
        </Flex>
      </Flex>
      {isMobile && (
        <MobileSpacer />
      )}
    </Container>
  );
};

export default SettingsPassword;
