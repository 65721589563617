import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import ILicense from '@biproxi/models/interfaces/ILicense';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon, { Icons } from '../../elements/Icon';
import NoContent from '../NoContent';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import DELETE_USER_LICENSE from '../../graphql/mutations/deleteUserLicense.mutation';
import { media, useMobileMedia } from '../../utils/MediaQuery';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0px;
  box-sizing: border-box;
  max-width: 992px; // prevents long licenses overflowing on firefow

  ${media.mobile} {
    padding: 0px;
    flex-direction: column;
    justify-items: flex-start;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const SectionTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 40px 0px 0px;

  ${media.mobile} {
    width: 100%;
    padding: 40px 0px;
  }
`;

const LicensesContainer = styled.div`
  width: 100%;
`;

const LicenseStyled = styled.div<{ margin?: string }>`
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid ${Colors.Grey300};
  justify-content: space-between;
  margin: ${(props) => props.margin};
`;

const LicenseLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MobileSpacer = styled.div`
  height: 40px;
`;

type SettingsLicensesProps = {
  user: IUserGraphQL;
};

const SettingsLicenses: React.FC<SettingsLicensesProps> = ({
  user,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));
  const popModal = () => dispatch(AppActions.popModal());

  const deleteLicense = (licenseId: string) => {
    deleteLicenseMutation({
      variables: {
        params: {
          licenseId,
        },
      },
    });
  };

  const pushAddLicenseModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AddLicense,
      props: {},
    }),
  );

  const pushDeleteLicenseModal = (licenseId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete license',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => deleteLicense(licenseId),
      },
    }),
  );

  /** GraphQL */
  const [deleteLicenseMutation] = useMutation(DELETE_USER_LICENSE, {
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'License successfully deleted',
      });
      popModal();
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /* Render */
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Licenses
          </Text>
          {/*     <Icon
            icon={Icons.InfoSquareSolid}
            size={14}
            color={Colors.Grey400}
            margin="0px 0px 0px 8px"
          /> */}
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Add or remove real estate licenses that can be used on listings.
        </Text>
      </SectionTitle>
      <Flex width={isMobile ? '100%' : '60%'}>
        <LicensesContainer>
          <Flex direction="column">
            {user?.licenses?.length > 0 ? (
              <>
                {user?.licenses.map((license: ILicense, index: number) => (
                  <LicenseStyled
                    key={index}
                    margin={index === user?.licenses?.length - 1 ? '0px' : '0px 0px 8px 0px'}
                  >
                    <LicenseLeft>
                      <Icon
                        icon={Icons.FileCertificateSolid}
                        color={Colors.Black}
                        margin="0px 8px 0px 16px"
                      />
                      <Text
                        type={TextTypesEnum.Regular16}
                        color={Colors.Black}
                      >
                        {StringUtil.formatLicense(license)}
                      </Text>
                    </LicenseLeft>
                    <Icon
                      icon={Icons.TrashRegular}
                      onClick={() => pushDeleteLicenseModal(license._id)}
                      size={16}
                      color={Colors.Grey400}
                      hoverColor={Colors.Red500}
                      margin="0px 16px 0px 8px"
                    />
                  </LicenseStyled>
                ))}
              </>
            ) : (
              <NoContent
                height="128px"
                icon={Icons.FileCertificateSolid}
                text="No licenses yet. Click &apos;Add license&apos; to get started."
              />
            )}
            <Flex justify="flex-end">
              <Button
                text="Add license"
                onClick={() => pushAddLicenseModal()}
                type={ButtonTypesEnum.Primary}
                size={ButtonSizesEnum.Medium}
                margin="32px 0px 0px 0px"
              />
            </Flex>
          </Flex>
        </LicensesContainer>
      </Flex>
      {isMobile && (
        <MobileSpacer />
      )}
    </Container>
  );
};

export default SettingsLicenses;
