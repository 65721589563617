import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
// import IListingCounts from '@biproxi/models/interfaces/IListingCounts';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
// import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
// import IListingUploadQuery from '@biproxi/models/interfaces/IListingUploadQuery';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import ReactTooltip from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import IListingsQuery from '@biproxi/models/interfaces/IListingsQuery';
import IPagination from '@biproxi/models/interfaces/IPagination';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
// import LIST_LISTINGS from '../graphql/queries/listings.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import NoContent from './NoContent';
import { Icons } from '../elements/Icon';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import { media, useMobileMedia } from '../utils/MediaQuery';
// import ListingStateFilterDropdown from './ListingStateFilterDropdown';
import useNavigateToListingDetails from '../hooks/useNavigateToListingDetails.hook';
import ListingCard from './ListingCard.personal';
import useNylas from '../hooks/useNylas.hook';
import { ListingActions } from '../redux/listing.redux';
import { useAppDispatch } from '../redux/store';
import LIST_LISTING_UPLOADS from '../graphql/queries/listingUploads.query';
import ListingUploadCard from './ListingCard.uploaded';
import NextUtil from '../utils/NextUtil';
import InfiniteScroll, { LoadMoreWhenInView, useInViewOptions } from './InfiniteScroll';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import LIST_MY_LISTINGS from '../graphql/queries/myListings.query';

const Container = styled.div`
  ${media.tablet} {
    margin: 16px 16px;
  }
`;

const ListingsContainer = styled.div`
  ${media.mobile} { // fixes weird mobile overflow issues.
    box-sizing: border-box;
    position: absolute;
    width: 100vw;
    left: 0;
    padding: 0px 16px;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DashboardMyListingsProps = {};

const DashboardMyListings: React.FC<DashboardMyListingsProps> = () => {
  /** State */
  const [searchQuery, setSearchQuery] = React.useState('');
  // const [listingStates, setListingStates] = React.useState<any>([]);
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);

  /** Hooks */
  const isMobile = useMobileMedia();
  const navigateToListingDetails = useNavigateToListingDetails();
  useNylas();
  const [ref, inView] = useInView(useInViewOptions);
  const [searchParams, setSearchParams] = useSearchParams();

  /** Actions */
  const dispatch = useAppDispatch();

  // const setListingCounts = (listingCounts: IListingCounts) => dispatch(ListingActions.setListingCounts(listingCounts));

  /* GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  type Vars = {
    params: IListingsQuery;
  }

  const {
    data, loading, fetchMore,
  } = useQuery<Data, Vars>(LIST_MY_LISTINGS, {
    variables: {
      params: {
        pagination: {
          offset: 0,
          limit: PaginationLimitsEnum.MyListings,
        },
        query: {
          queryType: ListingQueryTypesEnum.Personal,
          keywords: [searchQuery],
        },
      },
    },
    // onCompleted: (data) => {
    //   const { listings: { data: listingData } } = data;

    //   setListingCounts({
    //     All: listingData.length,
    //     Active: listingData.filter((listing) => listing.state === ListingStateEnum.Active).length,
    //     CallForOffers: listingData.filter((listing) => listing.state === ListingStateEnum.CallForOffers).length,
    //     Scheduled: listingData.filter((listing) => listing.state === ListingStateEnum.Scheduled).length,
    //     Draft: listingData.filter((listing) => listing.state === ListingStateEnum.Draft).length,
    //     UnderContract: listingData.filter((listing) => listing.state === ListingStateEnum.UnderContract).length,
    //     Closed: listingData.filter((listing) => listing.state === ListingStateEnum.Closed).length,
    //     Inactive: listingData.filter((listing) => listing.state === ListingStateEnum.Inactive).length,
    //     Expired: listingData.filter((listing) => listing.state === ListingStateEnum.Expired).length,
    //     Private: listingData.filter((listing) => listing.state === ListingStateEnum.Private).length,
    //   });
    // },
  });

  interface ListingUploadData {
    listingUploads: {
      data: IListingUploadGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  type ListingUploadVars = {
    params: {
      queryType: ListingUploadQueryTypesEnum;
      keywords: string[];
      pagination: IPagination;
    }
  }

  const { data: listingUploadData, fetchMore: fetchMoreUploads } = useQuery<ListingUploadData, ListingUploadVars>(LIST_LISTING_UPLOADS, {
    variables: {
      params: {
        queryType: ListingUploadQueryTypesEnum.Personal,
        keywords: [searchQuery],
        pagination: {
          offset: 0,
          limit: PaginationLimitsEnum.MyListings,
        },
      },
    },
  });

  /** Effects */
  React.useEffect(() => {
    const getData = async () => {
      const hasMore = data?.listings?.data?.length < data?.listings?.info?.totalCount;
      if (hasMore && inView && fetchMore) {
        setInfiniteLoading(true);
        await fetchMore({
          variables: {
            params: {
              pagination: {
                offset: data?.listings?.data?.length,
                limit: PaginationLimitsEnum.MyListings,
              },
              query: {
                queryType: ListingQueryTypesEnum.Personal,
                keywords: [searchQuery],
              },
            },
          },
        });
      }
      const hasMoreUploads = listingUploadData?.listingUploads?.data?.length < listingUploadData?.listingUploads?.info?.totalCount;
      setInfiniteLoading(true);
      if (hasMoreUploads && inView && fetchMoreUploads) {
        await fetchMoreUploads({
          variables: {
            params: {
              queryType: ListingUploadQueryTypesEnum.Personal,
              keywords: [searchQuery],
              pagination: {
                offset: listingUploadData?.listingUploads?.data?.length,
                limit: PaginationLimitsEnum.MyListings,
              },
            },
          },
        });
      }
      setInfiniteLoading(false);
    };
    getData();
  }, [inView]);

  /** Functions */
  const sortUploadedListings = () => {
    const notStartedListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.NotStarted).reverse() ?? [];
    const inProgressListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.InProgress).reverse() ?? [];
    const waitingForReviewListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.WaitingForReview).reverse() ?? [];
    const sortedUploadedListings: IListingUploadGraphQL[] = [...waitingForReviewListings, ...inProgressListings, ...notStartedListings];
    return sortedUploadedListings;
  };

  /* Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /* Render */
  const listings = data?.listings?.data.filter((listing: IListingGraphQL) => listing.state !== ListingStateEnum.Admin && listing.state !== ListingStateEnum.ReadyForReview) ?? [];

  const uploadedListings: IListingUploadGraphQL[] = listingUploadData?.listingUploads?.data ?? [];

  const sortedUploadedListings = sortUploadedListings();

  /**
   * currently, if you click on a listing card on this page, it caches that listing id, so if you go to do another action involving a different listing (e.g. create a new listing), it will access the cached listing
   * this ensures that the listing cache is reset (if it exists) when you are on this page
   */
  React.useEffect(() => {
    const listingIdParam = searchParams.get('listingId');
    if (listingIdParam) {
      setSearchParams();
      dispatch(ListingActions.selectListing({ listingId: '' }));
    }
  }, []);

  return (
    <>
      <Container data-cy="my-listings-body">
        <Flex justify="space-between" margin="0px 0px 16px 0px">
          <Input
            placeholder="Search listings"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            inputType={InputTypesEnum.Search}
            inputSize={InputSizeEnum.Small}
            width={isMobile ? '100%' : '280px'}
            clear={() => setSearchQuery('')}
          />
          {/* Will revist this in the future */}
          {/* <ListingStateFilterDropdown
              value={listingStates}
              onChange={(states) => setListingStates(states)}
            /> */}
        </Flex>
        {loading ? (
          <LoaderContainer>
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          </LoaderContainer>
        ) : (
          <>
            {listings.length > 0 || sortedUploadedListings?.length > 0 ? (
              <>
                {listings?.length > 0 || sortedUploadedListings?.length > 0 ? (
                  <ListingsContainer data-cy="listings-container">
                    <>
                      {
                      sortedUploadedListings.map((uploadedListing: IListingUploadGraphQL, index) => (
                        <ListingUploadCard
                          key={index}
                          listingUpload={uploadedListing}
                        />
                      ))
                    }
                      {listings.map((listing: IListingGraphQL | null, index) => (
                        <React.Fragment key={index}>
                          <ListingCard
                            listing={listing}
                            onClick={() => navigateToListingDetails(listing?._id)}
                          />
                        </React.Fragment>
                      ))}
                    </>
                    <LoadMoreWhenInView ref={ref} />
                    {infiniteLoading && <InfiniteScroll />}
                  </ListingsContainer>
                ) : (
                  <NoContent
                    height="176px"
                    icon={Icons.SearchRegular}
                    text="No search results. Please try different search terms."
                  />
                )}
              </>
            ) : (
              <NoContent
                data-cy="no-content-container"
                height="176px"
                icon={Icons.BuildingLight}
                text="No listings yet. Click create listing to get started."
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default DashboardMyListings;
