import gql from 'graphql-tag';

const LISTING_MOST_VIEWS_ANALYTICS = gql`
    query ListingMostViewsAnalytics($params: ListingViewsPerUserParams) {
        listingViewsPerUser(params: $params) {
            userId
            user {
                firstName
                lastName
                email
                profileImageFile {
                    url
                }
            }
            views
        }
    }
`;

export default LISTING_MOST_VIEWS_ANALYTICS;
