import React from 'react';
import styled from '@emotion/styled';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { CustomSpacer } from '../elements/Flex';
import ProfileBasicInfo from '../components/profile/ProfileBasicInfo';
import ProfileHeader from '../components/profile/ProfileHeader';
import { media } from '../utils/MediaQuery';
import useUser from '../hooks/useUser.hook';
import ProfileScoreCard from '../components/profile/ProfileScoreCard';
import ProfileInvestorPreferences from '../components/profile/ProfileInvestorPreferences';

const Container = styled.div`
  display: flex;

  ${media.tablet} {
    padding: 16px 16px;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;

  ${media.mobile} {
    max-width: calc(100vw - 32px);
  }
`;

const Spacer = styled.div`
  height: 32px;

  ${media.mobile} {
    height: 24px;
  }
`;

const NavContainer = styled.div``;

type DashboardProfileProps = {};

const DashboardProfile: React.FC<DashboardProfileProps> = () => {
  /** Hooks */
  const { user } = useUser();

  if (!user) {
    return null;
  }

  /** Render */
  const isInvestor = UserUtil.isInvestor(user);
  return (
    <Container>
      <ScrollableContent>
        <CustomSpacer height="8px" />
        {isInvestor && (
          <>
            <ProfileHeader />
            <Spacer />
          </>
        )}
        <ProfileBasicInfo user={user} />
        {isInvestor && (
          <>
            <Spacer />
            <ProfileInvestorPreferences user={user} />
          </>
        )}
      </ScrollableContent>
      <NavContainer>
        <CustomSpacer height="8px" />
        <ProfileScoreCard user={user} />
      </NavContainer>
    </Container>
  );
};

export default DashboardProfile;
