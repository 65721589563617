import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { KB_CREATE_LISTING_HIGHLIGHTS_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Input, { InputTypesEnum } from '../../elements/Input';
import { ListingActions } from '../../redux/listing.redux';
import { useAppDispatch } from '../../redux/store';
import CreateListingHighlightBox from './CreateListingHighlightBox';
import Icon, { Icons } from '../../elements/Icon';
import Colors from '../../styles/Colors';
import Label from '../../elements/Label';
import InlineAlert, { InlineAlertTypesEnum } from '../InlineAlert';

const Grid = styled.div`
  margin: 16px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const IconContainer = styled.button`
  cursor: pointer;
  position: absolute;
  right: 8px;
  background: none;
  outline: none;
  border: 0px;
`;

const ListItem = styled.div`
  margin-bottom: 8px;
`;

type CreateListingHighlightsBox = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingHighlights: React.FC<CreateListingHighlightsBox> = ({
  id,
  listing,
}) => {
  /** State */
  const [highlightArray, setHighlightArray] = React.useState(listing.highlights);
  const [newHighlight, setNewHighlight] = React.useState('');

  /* Actions */
  const dispatch = useAppDispatch();
  const setListingHighlights = (highlights: string[]) => dispatch(
    ListingActions.setListingHighlights({ highlights }),
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...highlightArray];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setHighlightArray(items);
    setListingHighlights(items);
  };

  React.useEffect(() => {
    setHighlightArray(listing.highlights);
  }, [listing.highlights]);

  /* Render */
  return (
    <ContentCard
      id={id}
      title="Highlights"
      subtitle="Showcase your listing with highlights to grab the attention of potential investors."
      learnMoreLink={KB_CREATE_LISTING_HIGHLIGHTS_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <Label
        text="Add a highlight"
      />
      <form>
        <InputContainer>
          <Input
            placeholder="Add a highlight"
            value={newHighlight}
            onChange={(event) => setNewHighlight(event.currentTarget.value)}
            inputType={InputTypesEnum.Text}
            innerWidth="calc(100% - 56px)"
          />
          <IconContainer
            type="submit"
            onClick={(event) => {
              event?.preventDefault?.();
              if (!newHighlight) return;
              const newArr = [...highlightArray];
              newArr.push(newHighlight);
              setListingHighlights(newArr);
              setNewHighlight('');
            }}
          >
            <Icon icon={Icons.PlusCircleSolid} color={Colors.Brand700 || Colors.Blurple700} size={24} />
          </IconContainer>
        </InputContainer>
      </form>
      {highlightArray?.length > 0 && (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided, _snapshot) => (
                <Grid
                  className="list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {highlightArray.map((highlight, index) => {
                    const id = `${highlight}-${index}`;
                    return (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={index}
                      >
                        {(provided, _snapshot) => (
                          <ListItem
                            key={id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CreateListingHighlightBox
                              text={highlight}
                              index={index}
                              highlights={highlightArray}
                            />
                          </ListItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
          {highlightArray.length > 4 && (
            <InlineAlert
              type={InlineAlertTypesEnum.YellowWarning}
              text="We display your top 4 highlights most prominently"
              margin="8px 0px 0px 0px"
            />
          )}
        </>
      )}
    </ContentCard>
  );
};

export default CreateListingHighlights;
