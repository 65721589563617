import React from 'react';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import {
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableSpace,
} from './ScrollTable';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import UserInfo from './UserInfo';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import { LeadActions } from '../redux/lead.redux';
import Flex from '../elements/Flex';
import { LeadModalPageStateEnum } from './modal/LeadModal';
import Tag from '../elements/Tag';

type InvestorMatchesTableRowProps = {
  lead: ILeadGraphQL;
};

const InvestorMatchesTableRow: React.FC<InvestorMatchesTableRowProps> = ({
  lead,
}) => {
  const {
    metrics: {
      pageViews,
    },
    meta: { createdAt },
  } = lead;

  const createTime = StringUtil.capitalize(
    TimeUtil.format(
      createdAt,
      'LLLL d \'at\' h:mma',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ).toLowerCase(),
  );

  /* Actions */
  const dispatch = useAppDispatch();

  const newChannel = useAppSelector((state: AppState) => (state?.chat ? state.chat?.newChannel : null));

  const pushLeadModal = (leadId: string, initialState: LeadModalPageStateEnum) => {
    dispatch(LeadActions.selectLead({ leadId: lead._id }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        isInvestorMatch: true,
        initialState,
      },
    }));
  };

  /** Render */
  const renderNewMessageTag = Boolean(newChannel?.hasUnreadMessages && newChannel?.channelUrl === lead?.channel?.channelUrl);

  return (
    <ScrollTableBodyRow
      onClick={() => pushLeadModal(lead._id, LeadModalPageStateEnum.Details)}
    >
      <ScrollTableBodyCell flex="1">
        <UserInfo user={lead.user} />
        {newChannel !== null && renderNewMessageTag && (
          <Tag
            text="New Message"
            textSize={TextTypesEnum.Regular10}
            color="Blurple"
            width="95px"
            hover
            onClick={(event) => {
              event.stopPropagation();
              pushLeadModal(lead._id, LeadModalPageStateEnum.Chat);
            }}
            margin="0px 8px 0px 0px"
          />
        )}
      </ScrollTableBodyCell>
      <ScrollTableSpace />
      <ScrollTableBodyCell flex="0.5">
        <Flex
          onClick={(event) => {
            event.stopPropagation();
            pushLeadModal(lead._id, LeadModalPageStateEnum.Chat);
          }}
          tip="Chat with this investor"
          tipPlace="right"
        >
          <Icon
            icon={Icons.CommentsAltRegular}
            color={Colors.Brand700 || Colors.Blurple700}
            size={14}
            margin="0px 8px 0px 0px"
          />
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            Message investor
          </Text>
        </Flex>
      </ScrollTableBodyCell>
      <ScrollTableSpace />
      <ScrollTableBodyCell flex="0.5">{pageViews}</ScrollTableBodyCell>
      <ScrollTableSpace />
      <ScrollTableBodyCell flex="1">{createTime}</ScrollTableBodyCell>
      <ScrollTableSpace />
    </ScrollTableBodyRow>
  );
};

export default InvestorMatchesTableRow;
