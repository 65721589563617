import React, { useState } from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import { media } from '../utils/MediaQuery';

export const ScrollTableContainer = styled.div<{ dataCy?: string }>`
  border: 1px solid ${Colors.White};
  position: relative;
  width: 100%;
  data-cy: ${(props) => props.dataCy};

  ${media.mobile} {
    min-width: 81em;
  }
`;

export const ScrollTableHeader = styled.div<{ padding?: string; width?: string; }>`
  /* position: absolute; */ // Double check this comment doesn't break anything
  width: ${(props) => props.width || '100%'};
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || '0 16px'};
  box-sizing: border-box;
  height: 32px;
  border-radius: 4px;
  background-color: ${Colors.Grey100};
  z-index: 99;
  overflow-x: visible;
`;

type TScrollTableHeaderCell = {
  flex?: string;
  justify?: string;
  borderRight?: string;
  borderLeft?: string;
  minWidth?: string;
  bgColor?: string;
  padding?: string;
};

export const ScrollTableHeaderCell = styled.div<TScrollTableHeaderCell>`
  position: relative;
  flex: ${(props) => props.flex || '1'};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-width: ${(props) => props.minWidth};
  padding: ${(props) => props.padding};
  white-space: nowrap;
  align-items: center;
  color: ${Colors.Grey700};
  background-color: ${(props) => props.bgColor};
  font-size: 1.2rem;
  font-weight: 400;
  justify-content: ${(props) => props.justify};
  text-transform: uppercase;
  border-right: ${(props) => (props.borderRight)};
  border-left: ${(props) => (props.borderLeft)};

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : null)};
  }
`;

export const ScrollTableBody = styled.div<{ width?: string; }>`
  /* margin-top: 48px; */
  /* max-height: calc(100% - 48px); */
  background-color: ${Colors.White};
  overflow-x: visible;
  width: ${(props) => props.width};

  /* overflow: auto; */
`;

type ScrollTableBodyRowProps = {
  height?: string;
  onClick?: Function;
  borderRadius?: string;
  borderBottom?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  padding?: string;
  minHeight?: string;
  dataCy?: string;
  index?: number;
}

export const ScrollTableBodyRow = styled.div<ScrollTableBodyRowProps>`
  /* transition: all 0.2s ease-in-out; */
  data-cy: 'scroll-table-body-row';
  min-height: ${({ minHeight }) => (minHeight)};
  display: flex;
  align-items: center;
  border-radius: ${({ borderRadius }) => (borderRadius || null)};
  border-bottom: ${({ borderBottom }) => (borderBottom || `1px inset ${Colors.Grey200}`)};
  background-color: ${({ backgroundColor }) => (backgroundColor || Colors.White)};
  height: ${(props) => props.height || '56px'};
  padding: 0 16px;
  box-sizing: border-box;
  padding: ${(props) => props.padding || '0 16px'};
  overflow-x: visible;

  /* data-cy: ${(props) => props.dataCy || 'scroll-table-body-row'}; */

  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : null)};
    background-color: ${(props) => (props.hoverBackgroundColor)};
  }
`;

type ScrollTableBodyCellProps = {
  flex?: string
  justify?: string
  borderRight?: string;
  borderLeft?: string;
  minWidth?: string;
  maxWidth?: string;
}

export const ScrollTableBodyCell = styled.div<ScrollTableBodyCellProps>`
  flex: ${(props) => props.flex || '1'};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  white-space: nowrap;
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  align-items: center;
  justify-content: ${(props) => props.justify};
  color: ${Colors.Grey900};
  font-size: 1.4rem;
  font-weight: 400;
  /* overflow: visible; */
  border-right: ${(props) => (props.borderRight)};
  border-left: ${(props) => (props.borderLeft)};
  height: 100%;
  overflow-x: visible;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ScrollTableNoContent = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
`;

export const ScrollTableSpace = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '20px'};
`;

type TScrollTableProps = {
  dataCy?: string;
  children?: React.ReactNode | any;
};

const ScrollTable: React.FC<TScrollTableProps> = ({
  dataCy = 'scroll-table-container',
  children,
}) => {
  const [fetching] = useState(false);

  return (
    <ScrollTableContainer
      data-cy={dataCy}
    >
      {typeof children === 'function' ? children(fetching) : children}
    </ScrollTableContainer>
  );
};

export default ScrollTable;
