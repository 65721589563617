import React from 'react';
import styled from '@emotion/styled';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import useUser from '../hooks/useUser.hook';
import RecentNotifications from '../components/dashboard-home/RecentNotifications';
import AccountRecentMessages from '../components/dashboard-home/AccountRecentMessages';
import AccountListingViewChart from '../components/dashboard-home/AccountListingViewsChart';
import RecentlyViewedListings from '../components/dashboard-home/RecentlyViewedListings';
import RecentlyFavoritedListings from '../components/dashboard-home/RecentlyFavoritedListings';
import Flex from '../elements/Flex';
import AccountOffersReceived from '../components/dashboard-home/AccountOffersReceived';
import AccountOffersMade from '../components/dashboard-home/AccountOffersMade';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
// import QuickStartGuide from '../components/dashboard-home/QuickStartGuide';
import AccountPrivateEventParticipating from '../components/dashboard-home/AccountPrivateEventsParticipating';
import OrganizationDashboardComponents from '../components/dashboard-home/organization-dashboard/OrganizationDashboardComponents';
import PageViewToggleButton from '../elements/PageViewToggleButton';
import { useAppSelector } from '../redux/store';
import { UserSelectors } from '../redux/user.redux';

const Container = styled.div`
  display: flex;
  /* max-width: calc(100vw - 32px); */

  ${media.tablet} {
    margin: 16px 16px;
  }
`;

const DashboardToggleContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const RowSpacer = styled.div`
  min-width: 48px;
`;

const ColSpacer = styled.div`
  min-height: 16px;
`;

type DashboardHomeHeaderProps = {
  greetingMessage: string;
  bodyMessage: string;
}
const DashboardHomeHeader: React.FC<DashboardHomeHeaderProps> = ({
  greetingMessage,
  bodyMessage,
}) => (
  <Flex direction="column" align="flex-start">
    <Text data-cy="dashboard-greeting" type={TextTypesEnum.Bold24}>
      {greetingMessage}
    </Text>
    <Text color={Colors.Grey700}>{bodyMessage}</Text>
  </Flex>
);

enum DashboardTypesEnum {
  Organization = 'Organization dashboard',
  Personal = 'My dashboard'
}

function DashboardHome() {
  /** State */
  const [dashboardLayout, setDashboardLayout] = React.useState<DashboardTypesEnum>(DashboardTypesEnum.Personal);
  /** Hooks */
  const { user } = useUser();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Actions */
  const currentOrganizationContext = useAppSelector(UserSelectors.currentOrganizationContext);
  const teamId = currentOrganizationContext?.orgId;

  /** Render */
  const renderDashboardLayout = (layoutType: DashboardTypesEnum) => {
    switch (layoutType) {
      case DashboardTypesEnum.Organization:
        return (
          <OrganizationDashboardComponents />
        );
      case DashboardTypesEnum.Personal:
        return (
          <>
            {/* Comment out quick start guide for now. May reintroduce in the future */}
            {/* {user && !user?.hideQuickStartGuide && (<QuickStartGuide />)} */}
            <AccountListingViewChart />
            <AccountOffersReceived />
            <AccountOffersMade />
            <RecentlyViewedListings />
            <RecentlyFavoritedListings />
          </>
        );
      default:
        return null;
    }
  };

  const listingUserFirstName: string = user?.firstName;
  return (
    <Container>
      <Flex direction="column" flex="2" overflow="hidden">
        <DashboardHomeHeader
          greetingMessage={`Hello, ${listingUserFirstName}`}
          bodyMessage="Welcome to Biproxi, the first exclusive investment community for CRE."
        />
        {teamId && (
          <DashboardToggleContainer>
            <PageViewToggleButton
              onClick={() => setDashboardLayout(DashboardTypesEnum.Personal)}
              text={DashboardTypesEnum.Personal}
              active={dashboardLayout === DashboardTypesEnum.Personal}
              margin="0 8px 0 0"
            />
            <PageViewToggleButton
              onClick={() => setDashboardLayout(DashboardTypesEnum.Organization)}
              text={DashboardTypesEnum.Organization}
              active={dashboardLayout === DashboardTypesEnum.Organization}
              margin="0 8px 0 0"
            />

          </DashboardToggleContainer>
        )}
        {
          renderDashboardLayout(dashboardLayout)
        }
      </Flex>
      {(!isMobile && !isTablet && dashboardLayout !== DashboardTypesEnum.Organization) && (
        <>
          <RowSpacer />
          <Flex direction="column" flex="1">
            <RecentNotifications />
            <ColSpacer />
            <AccountRecentMessages />
            <ColSpacer />
            <AccountPrivateEventParticipating />
          </Flex>
        </>
      )}
    </Container>
  );
}

export default DashboardHome;
