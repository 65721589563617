import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import ProfileInvestorTypeQuestion from './ProfileInvestorTypeQuestion';
import ProfileAssetsQuestion from './ProfileAssetsQuestion';
import ProfilePriceRangeQuestion from './ProfilePriceRangeQuestion';
import ProfilePreferredInvestmentQuestion from './ProfilePreferredInvestmentQuestion';
import ProfileTargetAssetClassesQuestion from './ProfileTargetAssetClassesQuestion';
import ProfileGeographicQuestion from './ProfileGeographicQuestion';
import { useAppDispatch } from '../../redux/store';
import { UserActions } from '../../redux/user.redux';
import Investor1031Exchange from './Investor1031Exchange';

const Spacer = styled.div`
  height: 32px;
`;

type InvestorPreferencesProps = {
  user: IUserGraphQL;
};

const InvestorPreferences: React.FC<InvestorPreferencesProps> = ({
  user,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const setUserInvestorProfileField = (field: Partial<IInvestorProfile>) => dispatch(
    UserActions.setUserInvestorProfileField(field),
  );

  /* Render */
  return (
    <>
      <Investor1031Exchange
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfileInvestorTypeQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfileAssetsQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfilePriceRangeQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfilePreferredInvestmentQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfileTargetAssetClassesQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
      <Spacer />
      <ProfileGeographicQuestion
        user={user}
        setUserInvestorProfileField={setUserInvestorProfileField}
      />
    </>
  );
};

export default InvestorPreferences;
