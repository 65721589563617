import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IListLeadsParams } from '@biproxi/models/services/ILeadService';
import { KB_LEADS_URL } from '@biproxi/models/externalLinks';
import LIST_LEADS from '../graphql/queries/leads.query';
import useListing from '../hooks/useListing.hook';
import LeadsTable from '../components/LeadsTable';
import LeadsTableSearchBar from '../components/LeadsTableSearchBar';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import { useAppDispatch } from '../redux/store';
import { LeadActions } from '../redux/lead.redux';
import LinkText from '../elements/LinkText';
import Link from '../elements/Link';
import { TextTypesEnum } from '../elements/Text';

const Container = styled.div``;

const LoaderContainer = styled.div`
  height: 30vh;
  width: 100%;
  /* max-width: calc(100vw - 32px); */
  /* max-width: calc(80vw); */

  max-width: calc(100vw - 100px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ListingDetailsLeadsProps = {};

const ListingDetailsLeads: React.FC<ListingDetailsLeadsProps> = () => {
  /* Hooks */
  const { listing } = useListing();

  /** Actions */
  const dispatch = useAppDispatch();
  const cacheLeads = (leads: ILeadGraphQL[]) => dispatch(LeadActions.cacheLeads({ leads }));

  /** GraphQL */
  interface Data {
    leads: ILeadGraphQL[];
  }

  interface Vars {
    params: IListLeadsParams;
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_LEADS, {
    variables: {
      params: {
        listingId: listing?._id,
        isInvestorMatches: false,
      },
    },
    onCompleted: ({ leads }) => {
      cacheLeads(leads);
    },
  });

  /** Render */
  return (
    <Container data-cy="leads-list-container">
      {loading || !listing ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {data?.leads?.length > 0 ? (
            <>
              <LeadsTableSearchBar />
              <LeadsTable
                leads={data?.leads}
              />
            </>
          ) : (
            <NoContent
              height="176px"
              icon={Icons.FireLight}
              text={(
                <>
                  No leads yet. Learn more about how we track leads&nbsp;
                  <Link href={KB_LEADS_URL} isExternal target="_blank">
                    <LinkText type={TextTypesEnum.Bold14}>here</LinkText>
                  </Link>
                  .
                </>
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ListingDetailsLeads;
