import React from 'react';
import styled from '@emotion/styled';
import NextPageContainer from '../containers/NextPage.container';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ResetPasswordPageProps = {
};

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  /** Actions */
  const dispatch = useAppDispatch();
  const pushResetPasswordModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ResetPassword,
      props: {
        isStandalone: true,
      },
    }),
  );

  /** Effects */
  React.useEffect(() => {
    pushResetPasswordModal();
  }, []);

  /* Render */
  return (
    <NextPageContainer>
      <Container />
    </NextPageContainer>
  );
};

export default ResetPasswordPage;
