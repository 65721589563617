import gql from 'graphql-tag';

/** Get auto-fillable infoFields for the Property Details card from listing tax assessor id  */

const CHERRE_INFO_FIELDS = gql`
  query InfoFields ($params: CherreInfoFieldsFromTaxAssessorIdParams!) {
    cherreInfoFieldsFromTaxAssessorId (params: $params) {
      fieldName
      value
    }
  }
`;

export default CHERRE_INFO_FIELDS;
