/* eslint-disable no-undef */
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IHeatMapData from '@biproxi/models/interfaces/IHeatMapData';
import IUserMapData from '@biproxi/models/interfaces/IUserMapData';
import { ListingOverviewAnalyticsEnum } from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { logger } from '@biproxi/logger';
import GoogleMap from '../../GoogleMap';
import {
  IGoogleHeatMap,
  IGoogleHeatMapMarkerPosition,
} from '../../../models/interfaces/IGoogleMaps';
import LISTING_HEAT_MAP_ANALYTICS from '../../../graphql/queries/listingHeatMapAnalytics.query';
import LISTING_USER_MAP_ANALYTICS from '../../../graphql/queries/listingUserMapAnalytics.query';
import Colors from '../../../styles/Colors';
import Icons from '../../../elements/Icons';
import Loader from '../../../elements/Loader';
import { media } from '../../../utils/MediaQuery';
import AnalyticsMapMarker from './AnalyticsMapMarker';
import AnalyticsOverviewMapTabs, { AnalyticsOverviewTabsTypesEnum } from './AnalyticsOverviewTabs';
import Divider, { DividerTypesEnum } from '../../../elements/Divider';

const MapAndAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 1px solid ${Colors.Grey300};
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  ${media.mobile} {
    overflow: hidden;
  }
`;

const AnalyticChartTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  ${media.mobile} {
    overflow: auto;
    margin: 8px 0;
    width: 100%;
    white-space: nowrap;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

enum ListingOverviewMapTabIcons {
  TotalListingViews = 'BrowserRegular',
  UniqueListingViews = 'UsersRegular',
  TotalListingLeads = 'FireRegular',
  TotalCAsSigned = 'FileSignatureRegular',
}

/** Types */
type AnalyticsMapProps = {
  listingId: string;
  listingUserId: string;
};

const tabNames = {
  [ListingOverviewAnalyticsEnum.TotalListingViews]: 'Listing Views',
  [ListingOverviewAnalyticsEnum.UniqueListingViews]: 'Unique Visitors',
  [ListingOverviewAnalyticsEnum.TotalListingLeads]: 'Leads',
  [ListingOverviewAnalyticsEnum.TotalCAsSigned]: 'CAs Signed',
};

const AnalyticsMap: React.FC<AnalyticsMapProps> = ({ listingId, listingUserId }) => {
  /* State */
  const [selectedAnalyticType, setSelectedAnalyticType] = React.useState<ListingOverviewAnalyticsEnum>(
    ListingOverviewAnalyticsEnum.TotalListingViews,
  );
  const [mapLoading, setMapLoading] = React.useState(false);

  const heatmapTypes = [
    ListingOverviewAnalyticsEnum.TotalListingViews,
    ListingOverviewAnalyticsEnum.UniqueListingViews,
  ];

  const isHeatmap = heatmapTypes.includes(selectedAnalyticType);

  /* GraphQL */
  interface UserMapData {
    listingUserMapAnalytics: IUserMapData[];
  }

  interface UserMapVars {
    params: {
      listingId: string;
      listingUserId: string;
      analyticType: ListingOverviewAnalyticsEnum;
    };
  }

  const { data: userMapData, loading: userMapLoading } = useQuery<UserMapData, UserMapVars>(LISTING_USER_MAP_ANALYTICS, {
    skip: isHeatmap,
    variables: {
      params: {
        listingId,
        listingUserId,
        analyticType: selectedAnalyticType,
      },
    },
    onError: (error) => {
      logger.warn(`There was an issue fetching the listing overview analytics from GraphQL: ${error}`);
    },
  });

  interface HeatMapData {
    listingHeatMapAnalytics: IHeatMapData[];
  }

  interface HeatMapVars {
    params: {
      listingId: string;
      listingUserId: string;
      analyticType: ListingOverviewAnalyticsEnum;
    };
  }

  const { data: heatMapData, loading: heatMapLoading } = useQuery<HeatMapData, HeatMapVars>(LISTING_HEAT_MAP_ANALYTICS, {
    skip: !isHeatmap,
    variables: {
      params: {
        listingId,
        listingUserId,
        analyticType: selectedAnalyticType,
      },
    },
    onError: (error) => {
      logger.warn(`There was an issue fetching the listing overview heatmap analytics from GraphQL: ${error}`);
    },
  });

  /* Render */
  const isLoading = heatMapLoading || userMapLoading;

  type THeatMapDataWithGoogleMapMarkerPosition = IHeatMapData & IGoogleHeatMapMarkerPosition;

  const heatMapPoints: IGoogleHeatMap = {
    positions: heatMapData?.listingHeatMapAnalytics?.map((item: IHeatMapData): THeatMapDataWithGoogleMapMarkerPosition => ({
      ...item,
      lat: item.location[0],
      lng: item.location[1],
    })) ?? [],
    options: {
      radius: 20,
      opacity: 1,
    },
  };
  interface IGoogleLeadMarkerPosition {
    user?: IUserGraphQL;
    lat: number;
    lng: number;
  }

  type TLeadPointDataWithGoogleMapMarkerPosition = IUserMapData & IGoogleLeadMarkerPosition;

  const userMapPoints = userMapData?.listingUserMapAnalytics?.map((item: IUserMapData): TLeadPointDataWithGoogleMapMarkerPosition => ({
    ...item,
    lat: item.location[0],
    lng: item.location[1],
  })) ?? [];

  /** Little hack to allow the Google Map component to catch up and do its thing when a user clicks on a different analytic tab
   * If it weren't for this, if a user were to click on a tab that renders markers then click back on a tab that renders a heatmap, the heatmap either renders strangely or doesn't render at all
   */
  React.useEffect(() => {
    setMapLoading(true);
    setTimeout(() => {
      setMapLoading(false);
    }, 500);
  }, [selectedAnalyticType]);

  return (
    <MapAndAnalyticsContainer>
      <AnalyticChartTabContainer>
        {Object.keys(ListingOverviewAnalyticsEnum)?.map(
          (tabAnalyticType: ListingOverviewAnalyticsEnum, index: number) => {
            const analyticName = ListingOverviewAnalyticsEnum[tabAnalyticType];
            const isLast = index === Object.keys(ListingOverviewAnalyticsEnum).length - 1;
            return (
              <React.Fragment key={index}>
                <AnalyticsOverviewMapTabs
                  key={tabAnalyticType}
                  analyticName={analyticName}
                  tabName={tabNames[analyticName]}
                  type={AnalyticsOverviewTabsTypesEnum.MapTabs}
                  icon={Icons[ListingOverviewMapTabIcons[tabAnalyticType]]}
                  listingId={listingId}
                  listingUserId={listingUserId}
                  filterValue="Last 360 days"
                  selectedAnalyticType={selectedAnalyticType}
                  setSelectedAnalyticType={setSelectedAnalyticType}
                />
                {!isLast && (
                <Divider
                  color={Colors.Grey200}
                  type={DividerTypesEnum.Vertical}
                  margin="0 16px"
                  top="25%"
                  verticalDividerHeight="48px"
                />
                )}
              </React.Fragment>
            );
          },
        )}
      </AnalyticChartTabContainer>
      <MapContainer>
        { isLoading || mapLoading ? (
          <LoaderContainer>
            <Loader color={Colors.Brand700 || Colors.Blurple700} />
          </LoaderContainer>
        ) : (
          <GoogleMap
            width="100%"
            height="400px"
            heatmap={heatMapPoints}
          >
            {!isHeatmap && (
              userMapPoints?.map((mapPoint, index) => (
                <AnalyticsMapMarker
                  key={index}
                  user={mapPoint.user}
                />
              ))
            )}
          </GoogleMap>
        )}
      </MapContainer>
    </MapAndAnalyticsContainer>
  );
};

export default AnalyticsMap;
