import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Icons from '../../../elements/Icons';
import Text, { TextTypesEnum } from '../../../elements/Text';
import NoContent from '../../NoContent';
import OffersReceivedListingContainer from '../../OffersReceivedListingContainer';

const Container = styled.div`
  width: 100%;
`;

const OffersContainer = styled.div`
  width: 100%;
`;

export type RecentOffersReceivedProps = {
  listingsWithOffers: IListingGraphQL[]
}

const RecentOffersReceived: React.FC<RecentOffersReceivedProps> = ({
  listingsWithOffers,
}) => (
  <Container>
    <Text
      type={TextTypesEnum.Bold18}
      margin="0 0 24px"
    >
      Offers received
    </Text>
    <OffersContainer>
      {listingsWithOffers?.length <= 0 ? (
        <NoContent
          height="176px"
          icon={Icons.EnvelopeOpenDollarLight}
          text="There are no current offers for your team's listings"
          padding="20px"
        />
      ) : (
        <OffersContainer>
          {listingsWithOffers.map((listing: IListingGraphQL) => (
            <OffersReceivedListingContainer
              key={listing._id}
              listing={listing}
              offerClickDisabled
            />
          ))}
        </OffersContainer>
      )}
    </OffersContainer>
  </Container>
);

export default RecentOffersReceived;
