import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useQuery } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import { KB_FAVORITES_URL } from '@biproxi/models/externalLinks';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import NoContent from '../components/NoContent';
import { Icons } from '../elements/Icon';
import Loader, { LoaderSizes } from '../elements/Loader';
import ListingCard from '../components/ListingCard.default';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import useUser from '../hooks/useUser.hook';
import { media, useMobileMedia } from '../utils/MediaQuery';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import ListingCreatedAtSortDowndown from '../components/ListingCreatedAtSortDropdown';
import Flex from '../elements/Flex';
import LinkText from '../elements/LinkText';
import Link from '../elements/Link';
import LIST_LISTINGS from '../graphql/queries/listings.query';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';

const Container = styled.div`
  ${media.tablet} {
    margin: 16px 16px;
  }
`;

const ListingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;

  ${media.mobile} { // fixes weird mobile overflow issues for now
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 100vw;
    left: 0;
    padding: 0px 16px;
  }

  ${media.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

// Fixes grid when only 2 - 3 items
// may want to swtich to flex-wrap
// for better responsiveness
const PlaceHolder = styled.div`
  width: 230px;
  height: 0;
  ${media.mobile} {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DashboardFavoritePageProps = {};

const DashboardFavoritesPage: React.FC<DashboardFavoritePageProps> = () => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const { user } = useUser();

  /** State */
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortBy, setSortBy] = React.useState<number>(1);

  /** Actions */
  const dispatch = useAppDispatch();
  const listingCache = useAppSelector(ListingSelectors.listingCache);
  const cacheListings = (listings: IListingGraphQL[]) => dispatch(
    ListingActions.cacheListings({ listings }),
  );

  /** GraphQL */
  interface Data {
    listings: {
      data: IListingGraphQL[];
    }

  }

  interface Vars {
    params: {
      query: IListingQuery;
    }
  }

  const { data } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Active,
          listingIds: user?.favoritedListings,
        },
      },
    },
  });

  /** Effects */
  React.useEffect(() => {
    if (data?.listings) {
      cacheListings(data?.listings.data);
    }
  }, [data]);

  /* Render */
  let listings = [];

  if (user) {
    listings = user.favoritedListings.map((listingId) => listingCache[listingId])
      .filter((listing) => Boolean(listing));
  }

  // Current search just stringifys the object and searches
  // based on substring.
  // We will need to improve and update this in the future to search on the backend.
  let filteredListings = [...listings];
  if (searchQuery) {
    filteredListings = filteredListings.filter((listing) => Boolean(JSON.stringify(listing).toLowerCase().includes(searchQuery.toLowerCase())));
  }

  filteredListings = sortBy === -1 ? [...filteredListings].reverse() : filteredListings;

  return (
    <Container>
      <Text data-cy="favorites-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>Favorites</Text>
      {user?.favoritedListings?.length > 0 && !listings?.length ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {(listings?.length > 0) ? (
            <>
              <Flex margin="24px 0px 16px 0px" justify="space-between">
                <Input
                  placeholder="Search favorites"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.currentTarget.value)}
                  clear={() => setSearchQuery('')}
                  inputType={InputTypesEnum.Search}
                  inputSize={InputSizeEnum.Small}
                  width={isMobile ? '100%' : '280px'}
                />
                <ListingCreatedAtSortDowndown
                  value={sortBy}
                  onChange={setSortBy}
                />
              </Flex>
              {filteredListings?.length > 0 ? (
                <ListingsContainer>
                  {filteredListings.map((listing: IListingGraphQL | null, index) => (
                    <ListingCard
                      key={listing?._id ?? index}
                      onClick={() => window.open(listing ? ListingUtil.slug(listing) : '/', '_blank')}
                      listing={listing}
                      type={ListingCardTypesEnum.DefaultFavorited}
                    />
                  ))}
                  <PlaceHolder />
                  <PlaceHolder />
                  <PlaceHolder />
                  <PlaceHolder />
                </ListingsContainer>
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.SearchRegular}
                  text="No search results. Please try different search terms."
                />
              )}
            </>
          ) : (
            <NoContent
              height="176px"
              icon={Icons.StarLight}
              text={(
                <>
                  No favorites yet. Click the star icon on a property to add it to your favorites.&nbsp;
                  <Link href={KB_FAVORITES_URL} isExternal target="_blank">
                    <LinkText type={TextTypesEnum.Bold14}>Learn more</LinkText>
                  </Link>
                  .
                </>
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardFavoritesPage;
