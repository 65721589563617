import gql from 'graphql-tag';

const PROPERTY_TRANSACTIONS = gql`
    query PropertyTransactions($params: CherrePropertyTransactionDataParams!) {
        cherrePropertyTransactionData(params: $params) {
            propertyTransactionData
            propertyMortgageData
        }
    }
`;

export default PROPERTY_TRANSACTIONS;
