import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import {
  NotificationBlockTitlesEnum, GeneralNotificationsEnum, BuyingNotificationsEnum, SellingNotificationsEnum,
} from '@biproxi/models/enums/SettingsNotificationsEnum';
import { useMutation } from '@apollo/client';
import * as IUserService from '@biproxi/models/services/IUserService';
import IUserNotificationSettings from '@biproxi/models/interfaces/IUserNotificationSettings';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Checkbox, { CheckboxTypesEnum } from '../../elements/Checkbox';
import UPDATE_USER_NOTIFICATIONS from '../../graphql/mutations/updateUserNotifications.mutation';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { useAppDispatch } from '../../redux/store';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import { UserActions } from '../../redux/user.redux';
import Divider from '../../elements/Divider';
import NotificationBlock from './SettingsNotificationBlock';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0px;
  box-sizing: border-box;

  ${media.mobile} {
    padding: 0px;
    flex-direction: column;
    justify-items: flex-start;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const SectionTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 40px 0px 0px;

  ${media.mobile} {
    width: 100%;
    padding: 40px 0px;
  }
`;

const NotificationsContainer = styled.div`
  width: 100%;
`;

const SectionSpacer = styled.div`
  margin: 16px 0px;
`;

type SettingsNotificationsProps = {
  user: IUserGraphQL;
}

const SettingsNotifications: React.FC<SettingsNotificationsProps> = ({ user }) => {
  /** State */

  /** The below is necessary since gql returns a __typename field on data coming back. Forcing it to not do that may mess with the Apollo cache,
   * so this localized solution is used instead. */
  let userNotificationSettingsWithoutTypeName;
  if (user?.notificationSettings) userNotificationSettingsWithoutTypeName = Object.keys(user?.notificationSettings).slice(1);
  const allNotificationsDisabled = userNotificationSettingsWithoutTypeName?.every((notificationSetting) => user?.notificationSettings[notificationSetting] === true);

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  const setUserNotificationSettings = (notificationSetting: Partial<IUserNotificationSettings>) => dispatch(
    UserActions.setUserNotificationSettings(notificationSetting),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  type Data = {
    updateUserNotifications: IUserGraphQL;
  }

  type Params = IUserService.TUpdateUserNotificationsPayload;

  const [updateUserNotificationsMutation] = useMutation<Data, Params>(UPDATE_USER_NOTIFICATIONS, {
    variables: {
      params: {
        notificationSettings: user?.notificationSettings,
      },
    },
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Notification settings successfully updated',
      });
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'Error updating notification settings',
      });
    },
  });

  /** Render */
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Communication Settings
          </Text>
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Set which emails you want to receive from us.
        </Text>
      </SectionTitle>
      <Flex width={isMobile ? '100%' : '60%'}>
        <NotificationsContainer>
          <NotificationBlock title={NotificationBlockTitlesEnum.General} notificationEnum={GeneralNotificationsEnum} user={user} />
          <SectionSpacer />
          <NotificationBlock title={NotificationBlockTitlesEnum.Buying} notificationEnum={BuyingNotificationsEnum} user={user} />
          <SectionSpacer />
          <NotificationBlock title={NotificationBlockTitlesEnum.Selling} notificationEnum={SellingNotificationsEnum} user={user} />
          <SectionSpacer />
          <Divider />
          <SectionSpacer />
          <Checkbox
            label="Do not send me any emails"
            type={CheckboxTypesEnum.Large}
            active={Boolean(allNotificationsDisabled)}
            onClick={() => setUserNotificationSettings({
              disableMessages: true,
              disableOfferUpdates: true,
              disableFavoritedListingsUpdates: true,
              disableListingUpdates: true,
              disableNewOffers: true,
              disableRevisedOffer: true,
              disableNewLeads: true,
              disableInvestmentOpportunities: true,
              disablePrivateListingsUpdates: true,
              disableAccountReminders: true,
            })}
          />
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey700}
            margin="0px 0px 0px 32px"
          >
            Note: You will always receive billing emails related to your account.
          </Text>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Save changes"
              onClick={() => updateUserNotificationsMutation()}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              margin="32px 0px 0px 0px"
            />
          </Flex>
        </NotificationsContainer>
      </Flex>
    </Container>
  );
};

export default SettingsNotifications;
