import React from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IListingVaultFolderGraphQL } from '@biproxi/models/interfaces/IListingVaultFolder';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { useMutation } from '@apollo/client';
import IFile from '@biproxi/models/interfaces/IFile';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListingVaultFolderNamesEnum from '@biproxi/models/enums/ListingVaultFolderNamesEnum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import GenericCard from '../../elements/GenericCard';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import CreateListingVaultFile from './CreateListingVaultFile';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import CreateListingVaultFolder from './CreateListingVaultFolder';
import { media } from '../../utils/MediaQuery';

const FileGrid = styled.div<{ subFolderGrid?: boolean }>`
  margin: 16px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: ${({ subFolderGrid }) => (subFolderGrid ? '594px' : '100%')};

  ${media.mobile} {
    max-width: ${({ subFolderGrid }) => (subFolderGrid ? '316px' : '100%')};
  }
`;

const Dashed = styled.div`
  border: 1px dashed ${Colors.Grey300};
  padding: 12px 16px;
  border-radius: 4px;
  margin: 16px 0px 0px 0px;

  &:hover {
    cursor: pointer;
  }
`;

const ListItem = styled.div`
  margin-bottom: 8px;
`;

const FileOpacityContainer = styled.div<{ fullOpacity: boolean }>`
  #VaultFile {
    background-color: ${Colors.Brand50 || Colors.Blurple50};
    opacity: ${(props) => (props.fullOpacity ? 1 : 0.3)};
  }
`;

export type FileListProps = {
  files: IFile[];
  renderFile: any;
  folderName: string;
  placeholder: any;
  uploadFiles?: GraphQLFileUpload[];
  loading?: boolean;
  subFolderGrid?: boolean;
}

export const FileList: React.FC<FileListProps> = ({
  files,
  uploadFiles,
  renderFile,
  folderName,
  placeholder,
  loading,
  subFolderGrid,
}) => (
  <FileGrid
    className={`${folderName}List`}
    subFolderGrid={subFolderGrid}
  >
    {[
      files.map(renderFile),
      placeholder,
      loading ? uploadFiles.map(renderFile) : null,
    ]}
  </FileGrid>
);

type CreateListingVaultSectionProps = {
  listing: IListingGraphQL;
  rootFolder: IListingVaultFolderGraphQL;
};

const CreateListingVaultSection: React.FC<CreateListingVaultSectionProps> = ({
  listing,
  rootFolder,
}) => {
  /* State */
  const [files, setFiles] = React.useState<GraphQLFileUpload[]>([]);
  const [sectionOpen, setSectionOpen] = React.useState<boolean>(true);
  const [dragId, setDragId] = React.useState<string>('');
  const folderCountRef = React.useRef<number>(1);
  const fullOpacity = Boolean(!dragId || (dragId === rootFolder.name));

  /** Error handling */
  const errors = useAppSelector(ListingSelectors.errors);
  const subFolderUnnamedError = Object.entries(errors).filter(([key]) => {
    if (key.includes(`/vault/folders/${listing.vault.folders.indexOf(rootFolder)}`)) {
      return true;
    }
    return false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  })?.map(([key, value]) => value)[0];

  /* Actions */
  const dispatch = useAppDispatch();

  const addListingVaultFiles = (files: IFile[]) => dispatch(
    ListingActions.addListingVaultFiles({
      folderName: rootFolder.name as ListingVaultFolderNamesEnum,
      files,
    }),
  );

  const moveListingVaultFile = (
    fileId: string,
    source: ListingVaultFolderNamesEnum | string,
    destination: ListingVaultFolderNamesEnum | string,
  ) => dispatch(
    ListingActions.moveListingVaultFile({
      fileId,
      source,
      destination,
    }),
  );

  const reOrderListingVaultFile = (folderName: ListingVaultFolderNamesEnum | string, currentFileIndex: number, newFileIndex: number) => dispatch(
    ListingActions.reOrderListingVaultFile({
      folderName,
      currentFileIndex,
      newFileIndex,
    }),
  );

  const handleOnDragUpdate = (result) => {
    if (!result.destination) return;
    setDragId(result.destination.droppableId);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    setDragId('');

    const sourceName = result.source.droppableId;
    const destinationName = result.destination.droppableId;
    const fileId = result.draggableId;

    /** If dragging into different folder, add file to destination folder and remove from source folder */
    if (sourceName !== destinationName) {
      moveListingVaultFile(fileId, sourceName, destinationName);
    } else {
      reOrderListingVaultFile(sourceName, result.source.index, result.destination.index);
    }
  };

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  /** GraphQL */
  const [uploadFiles, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      addListingVaultFiles(data.createFiles);
      setFiles([]);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error uploading your files. Please try again or contact support.',
      });
    },
  });

  /* Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    setFiles(browserFiles);
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => {
      const mimetype = browserFile.type || 'application/octet-stream';
      return {
        name: browserFile.name,
        description: browserFile.name,
        /**
         * For files without a mimetype, it will
         * come in as an empty string. Instead of this,
         * use 'application/octet-stream' as it is
         * the most general mimetype
         *
         * https://stackoverflow.com/questions/12539058/is-there-a-default-mime-type/12560996
         */
        mimetype,
        encoding: 'utf8',
        upload: browserFile,
      };
    });

    uploadFiles({
      variables: {
        params: fileParams,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: 'application/pdf, application/x-pdf, application/vnd.ms-excel',
  });

  /* Render */
  return (
    <GenericCard
      margin="0 0 16px"
      padding="16px"
      borderRadius="4px"
      height={sectionOpen ? null : '56px'}
      overflow={sectionOpen ? null : 'hidden'}
    >
      {(() => {
        const renderFile = (file, index) => {
          if (!file) return null;
          return (
            <Draggable
              key={file._id ?? index}
              draggableId={file._id ?? index}
              index={index}
            >
              {(provided) => (
                <ListItem
                  key={file._id ?? index}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <CreateListingVaultFile
                    key={file._id}
                    listing={listing}
                    folder={rootFolder}
                    file={file}
                  />
                </ListItem>
              )}
            </Draggable>
          );
        };

        const rootFolderDroppableId = rootFolder.name;
        return (
          <>
            <DragDropContext onDragUpdate={handleOnDragUpdate} onDragEnd={handleOnDragEnd}>
              <CreateListingVaultFolder
                folder={rootFolder}
                isRootFolder
                onClick={() => setSectionOpen(!sectionOpen)}
                sectionOpen={sectionOpen}
                folderCountRef={folderCountRef}
              />
              {subFolderUnnamedError && (
                <Text type={TextTypesEnum.Regular12} color={Colors.Red500} margin="0px 0px 8px 30px">
                  {subFolderUnnamedError}
                </Text>
              )}
              {Boolean(rootFolder.subFolders?.length) && (
              <Flex direction="column" margin="0px 0px 0px 30px">
                {rootFolder.subFolders?.map((subFolder: IListingVaultFolderGraphQL, index) => (
                  <CreateListingVaultFolder folder={subFolder} key={index} listing={listing} dragId={dragId} folderCountRef={folderCountRef} />
                ))}
              </Flex>
              )}
              <Droppable min-height="52px" droppableId={rootFolderDroppableId ?? 'rootFolder'}>
                {(provided, _snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <FileOpacityContainer fullOpacity={fullOpacity}>
                      <FileList
                        folderName={rootFolder.name}
                        files={rootFolder.files}
                        renderFile={renderFile}
                        placeholder={provided.placeholder}
                        uploadFiles={files}
                        loading={loading}
                      />
                    </FileOpacityContainer>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Dashed data-cy="create-listing-dd-vault-section-drop" {...getRootProps()}>
              <input {...getInputProps()} />
              <Flex align="center">
                <Icon icon={Icons.FileCertificateLight} color={Colors.Grey500} size={18} margin="0 8px 0 0" />
                <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                  Drag file(s) here or
                  &nbsp;
                  <Text
                    type={TextTypesEnum.Medium14}
                    as={TextAsEnum.Span}
                    color={Colors.Brand700 || Colors.Blurple700}
                  >
                    click to upload
                  </Text>
                </Text>
              </Flex>
            </Dashed>
          </>
        );
      })()}
    </GenericCard>
  );
};

export default CreateListingVaultSection;
