import gql from 'graphql-tag';

const LISTING_REFERRER_ANALYTICS = gql`
    query listingReferrerAnalytics($params: ListingReferrerAnalyticsParams) {
        listingReferrerAnalytics(params: $params) {
            referrer
            views
        }
    }
`;

export default LISTING_REFERRER_ANALYTICS;
