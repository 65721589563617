import gql from 'graphql-tag';
import FileFragments from '../fragments/file.fragments';

const GENERATE_LEADS_CSV = gql`
  mutation generateLeadsCSV($params: GenerateLeadsCSVParams!) {
    generateLeadsCSV(params: $params) {
      ...FileFields
    }
  }

  ${FileFragments.fields}
`;

export default GENERATE_LEADS_CSV;
