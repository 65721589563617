import gql from 'graphql-tag';

const CHERRE_BASIC_ADDRESS_FROM_LAT_LNG = gql`
query CherreBasicAddressFromLatLng($params: CherreParcelIdFromLatLngParams) {
    cherreBasicAddressInfoFromLatLng (params: $params) {
        cherre_parcel_id
        city
        state
        zip
        address
    }
}
`;

export default CHERRE_BASIC_ADDRESS_FROM_LAT_LNG;
