import React from 'react';
import styled from '@emotion/styled';
import DataExplorerPropertyFiltersEnum from '@biproxi/models/enums/DataExplorerPropertyFiltersEnum';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DataExplorerPropertyFiltersFooter from './DataExplorerPropertyFiltersFooter';
import { useMobileMedia } from '../../../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../../../elements/Text';
import Divider from '../../../../elements/Divider';
import Flex from '../../../../elements/Flex';
import Colors from '../../../../styles/Colors';
import PropertyTypeFilters from './PropertyTypeFilters';
import LotSizeFilters from './LotSizeFilters';
import BuildingDetailsFilters from './BuildingDetailsFilters';
import TransactionsFilters from './TransactionFilters';
import TaxFilters from './TaxFilters';
import Icon from '../../../../elements/Icon';
import Icons from '../../../../elements/Icons';
import BoxShadows from '../../../../styles/BoxShadows';
import dataExplorerUtilityBarAtom from '../../state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import { dataExplorerBasicFilterStateAtom, IBasicDataExplorerFilterState } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

type FiltersContainerProps = {
  width?: string;
  height?: string;
  overflow?: string;
  'data-cy'?: string;
}

const FiltersContainer = styled.div<FiltersContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: ${({ overflow }) => overflow};
  border-radius: 16px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${Colors.White};
  box-shadow: ${BoxShadows.Standard};
  padding: 16px;
`;

const FilterContainerHeader = styled.div`
  border-radius: 16px 16px 0px 0px;
`;

const FilterSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* flex: 1; */
`;

const FilterContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 32px;
`;

const MobileFilterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterSection = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ active }) => (active ? Colors.Brand50 || Colors.Blurple50 : null)};
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;

const MobileFilterSection = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ active }) => (active ? Colors.Brand50 || Colors.Blurple50 : null)};
  border-radius: 4px;
  padding: 8px;
`;

const MobileSectionSubsectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataExplorerPropertyFiltersMap = {
  [DataExplorerPropertyFiltersEnum.PropertyType]: () => <PropertyTypeFilters />,
  [DataExplorerPropertyFiltersEnum.LotSize]: () => <LotSizeFilters />,
  [DataExplorerPropertyFiltersEnum.BuildingDetails]: () => <BuildingDetailsFilters />,
  // [DataExplorerPropertyFiltersEnum.Owner]: () => <OwnerFilters />, // feature flagged for now lol
  [DataExplorerPropertyFiltersEnum.Transactions]: () => <TransactionsFilters />,
  [DataExplorerPropertyFiltersEnum.Taxes]: () => <TaxFilters />,
  // [DataExplorerPropertyFiltersEnum.Mortgages]: () => <MortgageFilters />, // commented out because we need Attom data for this :/
  // [DataExplorerPropertyFiltersEnum.CensusDemographics]: () => <DemographicsFilters />, // also dfeature flagged for now
};

export type DataExplorerPropertyFiltersProps = {}

const DataExplorerPropertyFilters: React.FC<DataExplorerPropertyFiltersProps> = () => {
  /** Recoil state */
  const setUtilityBarState = useSetRecoilState(dataExplorerUtilityBarAtom);
  const [dataExplorerBasicFilterState, setDataExplorerBasicFilterState] = useRecoilState(dataExplorerBasicFilterStateAtom);

  const {
    expandedSection,
  } = dataExplorerBasicFilterState;

  /** Hooks */
  const isMobile = useMobileMedia();

  return (
    <FiltersContainer
      width={isMobile ? null : '760px'}
      height={isMobile ? 'calc(100% - 16px)' : 'fit-content'}
      overflow="auto"
      data-cy="data-explorer-filter-modal"
    >
      <FilterContainerHeader>
        <Flex
          width="100%"
          justify="space-between"
        >
          <Text
            type={TextTypesEnum.Bold24}
            color={Colors.Black}
          >
            Filters
          </Text>
          <Icon
            data-cy="data-explorer-filter-modal-close"
            icon={Icons.TimesRegular}
            color={Colors.Grey300}
            hoverColor={Colors.Red500}
            onClick={() => setUtilityBarState((prevState) => ({
              ...prevState,
              propertyFiltersVisible: false,
            }))}
            size={24}
          />
        </Flex>
      </FilterContainerHeader>
      <Divider margin="16px 0" />
      {isMobile ? (
        <MobileFilterContentWrapper>
          {Object.keys(DataExplorerPropertyFiltersEnum).map((filterSection: DataExplorerPropertyFiltersEnum, index: number) => {
            const sectionValue: DataExplorerPropertyFiltersEnum = DataExplorerPropertyFiltersEnum[filterSection];
            return (
              <MobileSectionSubsectionWrapper>
                <MobileFilterSection
                  key={index}
                  active={sectionValue === expandedSection}
                  onClick={() => setDataExplorerBasicFilterState((prevState: IBasicDataExplorerFilterState) => ({
                    ...prevState,
                    expandedSection: sectionValue,
                  }))}
                  data-cy={DataExplorerPropertyFiltersEnum[filterSection].toLowerCase()
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join('')}
                >
                  <Flex
                    justify="space-between"
                    width="100%"
                  >
                    <Text
                      type={TextTypesEnum.Medium12}
                      color={Colors.Grey900}
                    >
                      {DataExplorerPropertyFiltersEnum[filterSection]}
                    </Text>
                    <Icon icon={(sectionValue === expandedSection) ? Icons.AngleUpRegular : Icons.AngleDownRegular} color={Colors.Grey400} size={12} />
                  </Flex>
                </MobileFilterSection>
                {(sectionValue === expandedSection) && DataExplorerPropertyFiltersMap[expandedSection]?.()}
              </MobileSectionSubsectionWrapper>
            );
          })}
        </MobileFilterContentWrapper>
      ) : (
        <FilterContentWrapper>
          <FilterSectionsWrapper>
            {Object.keys(DataExplorerPropertyFiltersEnum).map((filterSection: DataExplorerPropertyFiltersEnum, index: number) => {
              const sectionValue: DataExplorerPropertyFiltersEnum = DataExplorerPropertyFiltersEnum[filterSection];
              return (
                <FilterSection
                  key={index}
                  active={sectionValue === expandedSection}
                  onClick={() => setDataExplorerBasicFilterState((prevState: IBasicDataExplorerFilterState) => ({
                    ...prevState,
                    expandedSection: sectionValue,
                  }))}
                  data-cy={DataExplorerPropertyFiltersEnum[filterSection].toLowerCase()
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join('')}
                >
                  <Flex
                    justify="space-between"
                    width="100%"
                  >
                    <Text
                      type={TextTypesEnum.Medium12}
                      color={Colors.Grey900}
                    >
                      {DataExplorerPropertyFiltersEnum[filterSection]}
                    </Text>
                    <Icon icon={Icons.AngleRightRegular} color={Colors.Grey400} size={12} />
                  </Flex>
                </FilterSection>
              );
            })}
          </FilterSectionsWrapper>
          {DataExplorerPropertyFiltersMap[expandedSection]?.()}
        </FilterContentWrapper>
      )}
      <Divider margin="16px 0" />
      <DataExplorerPropertyFiltersFooter />
    </FiltersContainer>
  );
};

export default DataExplorerPropertyFilters;
