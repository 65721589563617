import React from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import styled from '@emotion/styled';
import RangeInput from '../../../RangeInput';
import { media } from '../../../../utils/MediaQuery';
import { InputTypesEnum } from '../../../../elements/Input';
import { dataExplorerBuildingDetailsFiltersAtom, IBuildingDetailsFilters } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

const BuildingDetailsFiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mobile} {
      padding: 0 8px;
    }
`;

type BuildingDetailsFiltersProps = {}

const BuildingDetailsFilters: React.FC<BuildingDetailsFiltersProps> = () => {
  /** Recoil */
  const dataExplorerBuildingDetailsFilter = useRecoilValue(dataExplorerBuildingDetailsFiltersAtom);
  const {
    buildingAreaSqftRange,
    yearBuiltRange,
  } = dataExplorerBuildingDetailsFilter;

  /** Callbacks */
  const setBuildingAreaSqft = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerBuildingDetailsFiltersAtom, (prevState: IBuildingDetailsFilters) => ({
      ...prevState,
      buildingAreaSqftRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [buildingAreaSqftRange]);

  const setYearBuilt = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerBuildingDetailsFiltersAtom, (prevState: IBuildingDetailsFilters) => ({
      ...prevState,
      yearBuiltRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [yearBuiltRange]);

  return (
    <BuildingDetailsFiltersWrapper
      data-cy="BuildingDetailsContainer"
    >
      <RangeInput
        label="Building area (sqft)"
        data-cy="BuildingAreaSqftInput"
        onMinChange={(_e, unmasked) => setBuildingAreaSqft({ minValue: unmasked, maxValue: buildingAreaSqftRange?.max })}
        onMaxChange={(_e, unmasked) => setBuildingAreaSqft({ minValue: buildingAreaSqftRange?.min, maxValue: unmasked })}
        minValue={buildingAreaSqftRange?.min}
        maxValue={buildingAreaSqftRange?.max}
      />
      <RangeInput
        label="Year built"
        data-cy="YearBuiltInput"
        inputType={InputTypesEnum.Text}
        onMinChange={(e) => setYearBuilt({ minValue: e.target.value, maxValue: yearBuiltRange?.max })}
        onMaxChange={(e) => setYearBuilt({ minValue: yearBuiltRange?.min, maxValue: e.target.value })}
        minValue={yearBuiltRange?.min}
        maxValue={yearBuiltRange?.max}
      />
    </BuildingDetailsFiltersWrapper>
  );
};

export default BuildingDetailsFilters;
