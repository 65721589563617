import React from 'react';
import styled from '@emotion/styled';
import { Outlet, useNavigate } from 'react-router-dom';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from './CenteredPageLayout';
import Colors from '../styles/Colors';
import { media } from '../utils/MediaQuery';
import useUser from '../hooks/useUser.hook';

type AdminLayoutProps = {
};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  margin: 24px 0;
`;

const PageContainer = styled.div`
  position: relative;
  width: 100%;

  ${media.mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div<{isSearch: boolean;}>`
  width: fill-available;
  height: fit-content;
  display: flex;
  justify-content: center;

  ${media.mobile} {
    padding: ${(props) => (props.isSearch ? '0' : '24px 16px')};
  }
`;

const AdminLayout: React.FC<AdminLayoutProps> = () => {
  /** Hooks */
  const { user } = useUser();
  const navigate = useNavigate();

  /**
   * Effects
   *
   * Redirect away from AdminPage
   * if user is not an admin
   *
   */

  React.useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/app/dashboard/home');
    }
  }, [user]);
  /* Render */
  return (
    <Container>
      <PageContainer>
        <CenteredPageLayout
          size={CenteredPageLayoutSizeEnum.FullScreen}
          background={Colors.White}
        >
          <ContentContainer isSearch={false}>
            <Outlet />
          </ContentContainer>
        </CenteredPageLayout>
      </PageContainer>
    </Container>
  );
};

export default AdminLayout;
