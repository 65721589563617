import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IAnalyticsReferrerData from '@biproxi/models/interfaces/IAnalyticsReferrerData';
import LISTING_REFERRER_ANALYTICS from '../../../graphql/queries/listingReferrerAnalytics.query';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Icon, { Icons } from '../../../elements/Icon';
import Colors from '../../../styles/Colors';
import Loader from '../../../elements/Loader';
import { media } from '../../../utils/MediaQuery';

const TopSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  height: 284px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0,0,0,0.05);

  ${media.mobile} {
    width: 100%;
    margin: 16px 0;
    height: 292px;
  }
`;

const ReferrerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
`;

const NoDataIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: ${Colors.Grey200};
  border-radius: 100px;
  margin: 4px 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ComponentProps = {
    listingId: string,
    listingUserId: string
}

const TopSourcesCard: React.FC<ComponentProps> = ({ listingId, listingUserId }) => {
  /** GraphQL */
  interface Data {
    listingReferrerAnalytics: IAnalyticsReferrerData[];
  }
  interface Vars {
    params: {
      listingId: string
      listingUserId: string
    };
  }

  const { data, loading: isDataLoading } = useQuery<Data, Vars>(
    LISTING_REFERRER_ANALYTICS,
    {
      variables: {
        params: {
          listingId,
          listingUserId,
        },
      },
      onError: (error) => {
        logger.error('LISTING_REFERRER_ANALYTICS error', error);
      },
    },
  );

  const referrerData: IAnalyticsReferrerData[] = data?.listingReferrerAnalytics ?? [];

  return (
    <TopSourcesContainer>
      <Text type={TextTypesEnum.Bold16} margin="0 0 16px 0">
        Top sources
      </Text>
      <StatsContainer>
        {isDataLoading ? (
          <LoaderContainer>
            <Loader color={Colors.Brand700 || Colors.Blurple700} />
          </LoaderContainer>
        ) : referrerData && referrerData.length ? (
          referrerData?.map(
            (referrerInfo: IAnalyticsReferrerData, index: number) => (
              <ReferrerContainer key={index}>
                <Text type={TextTypesEnum.Regular14} color={Colors.Black}>
                  {referrerInfo.referrer}
                </Text>
                <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
                  {referrerInfo.views}
                  {' '}
                  {referrerInfo.views > 1 ? 'views' : 'view'}
                </Text>
              </ReferrerContainer>
            ),
          )
        ) : (
          <NoDataContainer>
            <NoDataIconContainer>
              <Icon icon={Icons.EyeLight} color={Colors.Grey700} size={24} />
            </NoDataIconContainer>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>
              No data yet
            </Text>
          </NoDataContainer>
        )}
      </StatsContainer>
    </TopSourcesContainer>
  );
};

export default TopSourcesCard;
