import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import PrivateEventsQueryTypeEnum from '@biproxi/models//enums/PrivateEventsQueryTypeEnum';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import PrivateEventParticipantStatesEnum from '@biproxi/models/enums/PrivateEventParticipantStatesEnum';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import LIST_PRIVATE_EVENTS from '../../graphql/queries/privateEvents.query';
import PrivateEventListingCard, { PrivateEventListingCardFormat, PrivateEventListingCardTypes } from '../private-event/PrivateEventListingCard';
import useUser from '../../hooks/useUser.hook';
import Divider from '../../elements/Divider';
import Loader, { LoaderSizes } from '../../elements/Loader';

const Card = styled.div`
  width: 320px;
  padding: 16px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
  box-sizing: border-box;
`;

const LoadingContainer = styled.div`
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type AccountPrivateEventParticipatingProps = {};

const AccountPrivateEventParticipating: React.FC<AccountPrivateEventParticipatingProps> = () => {
  /** Hooks */
  const { user } = useUser();

  /** GraphQL */
  const { data, loading } = useQuery(LIST_PRIVATE_EVENTS, {
    variables: {
      params: {
        queryType: PrivateEventsQueryTypeEnum.Participating,
      },
    },
  });

  /** Render */
  if (data?.privateEvents?.length <= 0) return null;

  return (
    <>
      <Text margin="0 0 5px 0" type={TextTypesEnum.Bold18}>Private Events</Text>
      <Card>
        {loading ? (
          <LoadingContainer>
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          </LoadingContainer>
        ) : (
          <>
            {data?.privateEvents?.slice(0, 5)?.map((event: IPrivateEventGraphQL, index: number) => (
              <React.Fragment key={event._id}>
                <PrivateEventListingCard
                  format={PrivateEventListingCardFormat.Small}
                  privateEvent={event}
                  type={PrivateEventListingCardTypes.Participating}
                  invitePending={event?.participants?.find((participant) => participant.email === user.email)?.state === PrivateEventParticipantStatesEnum.Pending}
                />
                {(index !== 4 && index !== (data?.privateEvents?.length - 1)) && <Divider margin="16px 0px" />}
              </React.Fragment>
            ))}
          </>
        )}
      </Card>
    </>
  );
};

export default AccountPrivateEventParticipating;
