import React from 'react';
import styled from '@emotion/styled';
import IAddress from '@biproxi/models/interfaces/IAddress';
import { useDebounceCallback } from '@react-hook/debounce';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import GoogleTimezoneAPI from '@biproxi/utils/GoogleTimezoneAPI';
import { AmericanStatesEnum } from '@biproxi/models/enums/AmericanStatesEnums';
import Dropdown from '../elements/Dropdown';
import Input, { InputTypesEnum } from '../elements/Input';
import Flex from '../elements/Flex';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import InlineAlert, { InlineAlertTypesEnum } from './InlineAlert';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Divider from '../elements/Divider';
import { media, useMobileMedia } from '../utils/MediaQuery';

const LatLngContainer = styled.div`
  margin: 16px 0 0;
`;

const DividerContainer = styled.div`
  min-width: 80px;
  align-self: center;
  margin: 18px 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile} {
    margin: 24px 0;
  }
`;

const SelectCoordinatesButton = styled.div`
  align-self: flex-end;

  ${media.mobile} {
    align-self: center;
    width: 100%;
  }
`;

const Spacer = styled.div`
  min-width: 8px;
`;

type ManualAddressProps = {
  value?: IAddress;
  onChange?: (address: IAddress) => void;
  errors?: Record<string, string>;
  isPortfolioInput?: boolean;
  onManualComplete?: () => void;
}
const ManualAddress: React.FC<ManualAddressProps> = ({
  value,
  onChange,
  errors,
  isPortfolioInput,
  onManualComplete,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();

  /** State */
  const latString = AddressUtil.getLatitude(value) ?? '';
  const lngString = AddressUtil.getLongitude(value) ?? '';

  /** Actions */
  const dispatch = useAppDispatch();
  const pushManualAddressCoordinatesModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ManualAddressCoordinates,
      props: {
        onChange,
        value,
      },
    }),
  );

  /** Effects */
  const setTimezoneInfo = async () => {
    /**
     * Do not query for timezone until both the
     * lat and lng fiels have values.
     */
    if (AddressUtil.hasLatAndLng(value)) {
      const lat = AddressUtil.getLatitudeAsFloat(value);
      const lng = AddressUtil.getLongitudeAsFloat(value);

      const { timeZoneId } = await GoogleTimezoneAPI.getTimezoneInfo(lat, lng);

      onChange({
        ...value,
        timeZoneId,
      });
    }
  };

  const setTimezoneInfoDebounced = useDebounceCallback(setTimezoneInfo, 1000);

  React.useEffect(() => {
    setTimezoneInfoDebounced();
  }, [latString, lngString]);

  /** Render */
  return (
    <>
      <Input
        label="Address Line 1"
        placeholder="Address Line 1"
        value={value?.address1}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...value, address1: e.target.value });
        }}
        inputType={InputTypesEnum.Text}
        margin="0px 0px 16px 0px"
        error={errors?.['/address1']}
      />
      <Input
        label="Address Line 2"
        placeholder="Address Line 2"
        value={value?.address2}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...value, address2: e.target.value });
        }}
        inputType={InputTypesEnum.Text}
        margin="0px 0px 16px 0px"
        error={errors?.['/address2']}
      />
      <Flex margin="0 0 16px">
        <Input
          label="City"
          placeholder="City"
          value={value?.city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...value, city: e.target.value });
          }}
          inputType={InputTypesEnum.Text}
          error={errors?.['/city']}
        />
        <Dropdown
          label="State"
          placeholder="State"
          value={value?.state}
          onChange={(val: AmericanStatesEnum) => {
            onChange({ ...value, state: val });
          }}
          items={Object.values(AmericanStatesEnum).map((value) => ({
            name: value,
            value,
          }))}
          margin="0px 8px"
          error={errors?.['/state']}
        />
        <Input
          label="Zip Code"
          placeholder="Zip Code"
          value={value?.zip}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...value, zip: e.target.value });
          }}
          inputType={InputTypesEnum.Text}
          error={errors?.['/zip']}
        />
      </Flex>
      <Input
        label="Country"
        placeholder="Country"
        value={value?.country}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...value, country: e.target.value });
        }}
        inputType={InputTypesEnum.Text}
        error={errors?.['/country']}
      />

      <LatLngContainer>
        <InlineAlert
          type={InlineAlertTypesEnum.GreyNotice}
          text="Please enter or select the location of your property in the fields below. If you do not specify a location, we will be unable to render a pin for your listing on our map which may make it harder for investors to find."
        />
        <Flex margin="16px 0px 0px" direction={isMobile ? 'column' : 'row'}>
          <Flex width="100%">
            <Input
              label="Latitude"
              placeholder="Latitude"
              value={latString.toString()}
              inputType={InputTypesEnum.Coordinate}
              error={errors?.['/location/coordinates/1'] || errors?.['/location/coordinates']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...value,
                  location: {
                    ...value.location,
                    coordinates: [
                      AddressUtil.getLongitude(value),
                      e.currentTarget.value,
                    ],
                  },
                });
              }}
            />
            <Spacer />
            <Input
              label="Longitude"
              placeholder="Longitude"
              value={lngString.toString()}
              inputType={InputTypesEnum.Coordinate}
              error={errors?.['/location/coordinates/0'] || errors?.['/location/coordinates']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...value,
                  location: {
                    ...value.location,
                    coordinates: [
                      e.currentTarget.value,
                      AddressUtil.getLatitude(value),
                    ],
                  },
                });
              }}
            />
          </Flex>
          <DividerContainer>
            <Divider>or</Divider>
          </DividerContainer>
          <SelectCoordinatesButton>
            <Button
              text="Select location on map"
              onClick={() => {
                pushManualAddressCoordinatesModal();
              }}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth={isMobile}
            />
          </SelectCoordinatesButton>
        </Flex>
        {isPortfolioInput && (
          <Flex margin="24px 0 0">
            <Button
              text="Add address to portfolio"
              onClick={() => {
                onManualComplete();
              }}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth={isMobile}
            />
            {/* <Button
              text="Clear"
              onClick={() => {
                pushManualAddressCoordinatesModal();
              }}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth={isMobile}
            /> */}
          </Flex>
        )}
      </LatLngContainer>
    </>
  );
};

export default ManualAddress;
