import { gql } from '@apollo/client';
import UserFragments from '../fragments/user.fragments';

const TOGGLE_USER_ACCOUNT_ACTIVATION = gql`
  mutation toggleUserAccountActivation($params: ToggleUserAccountActivationParams!) {
    toggleUserAccountActivation(params: $params) {
      ...UserFields
    }
  }

  ${UserFragments.fields}
`;

export default TOGGLE_USER_ACCOUNT_ACTIVATION;
