/* eslint-disable no-undef */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from '@emotion/styled';
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import { MVTLayer } from '@deck.gl/geo-layers';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import { useLazyQuery } from '@apollo/client';
import IParcelBoundaryDetails from '@biproxi/models/interfaces/IParcelBoundaryDetails';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import { logger } from '@biproxi/logger';
import CHERRE_BASIC_ADDRESS_FROM_LAT_LNG from '../graphql/queries/cherreParcelIdFromLatLng.query';
import GoogleMap from '../components/GoogleMap';
import Colors from '../styles/Colors';
import Loader from '../elements/Loader';
import { media } from '../utils/MediaQuery';
import { useAppDispatch } from '../redux/store';
import ListingFilterPropertyList from '../components/data-explorer/utility-bar/DataExplorerFilteredPropertyList';
import { AppActions } from '../redux/app.redux';
import useUser from '../hooks/useUser.hook';
import UtilityBar from '../components/data-explorer/UtilityBar';
import DataSettings from '../components/data-explorer/DataSettings';
import DataFrame from '../components/data-explorer/data-frame/DataFrame';
import MapMarker from '../components/data-explorer/MapMarker';
import DataExplorerPropertyFilters from '../components/data-explorer/utility-bar/filters/DataExplorerPropertyFilters';
import Geographies from '../components/data-explorer/Geographies';
import dataExplorerUtilityBarAtom from '../components/data-explorer/state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import { dataFrameGeneralAtom } from '../components/data-explorer/state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import * as UrlUtil from '../utils/UrlUtil';

const MapContainer = styled.div`
  width: 100%;
  height: calc(100vh - 104px); // full viewport height - (height of main nav bar (56px) + height of data explorer utility bar (32px) + padding of utility bar (8px * 2 (top and bottom padding are 8px)))
  ${media.mobile}{
    width: 100%;
    height: calc(100vh - 148px); // full viewport height - (height of main nav bar (56px) + height of data explorer utility bar (92px) + padding of utility bar (8px * 2 (top and bottom padding are 8px)))
  };
  ${media.tablet}{
    width: 100%;
  };
  overflow: hidden;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export enum DataExplorerDataStatusEnum {
  Error = 'Error',
  AddressNotFound = 'Address Not Found',
  Complete = 'Complete',
  NoData = 'NoData',
  Loading = 'Loading'
}

type DashboardFavoritePageProps = {};

const DataExplorer: React.FC<DashboardFavoritePageProps> = () => {
  /* State */
  const [parcelFeatureData, setParcelFeatureData] = React.useState<IParcelBoundaryDetails>(null);
  const [clickedFeatureData, setClickedFeatureData] = React.useState<IParcelBoundaryDetails>(null); // this guy is only used for when someone clicked on a parcel since that data already exists before a query and can be rendered on the map marker onClick rather than wait for a query
  const [map, setMap] = React.useState<any>(null);
  const [isMapLoaded, setIsMapLoaded] = React.useState<boolean>(false);
  const [propertyLatLng, setPropertyLatLng] = React.useState<string[]>([]);
  const queryParams = UrlUtil.parse(window?.location?.toString())?.query;

  /** Recoil */
  const setDataFrameGeneralState = useSetRecoilState(dataFrameGeneralAtom);
  const [utilityBarState, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);

  const {
    propertyFiltersVisible,
    specificAddressSearch,
    showFilterPropertyList,
  } = utilityBarState;

  /** Actions */
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const tiles = [
    'https://api.mapbox.com/v4/sam-biproxi.us-parcels-2/{z}/{x}/{y}.vector.pbf?access_token=pk.eyJ1Ijoic2FtLWJpcHJveGkiLCJhIjoiY2t0bHZnc3c5MXppYTJxbmxvamk2cjg0ZiJ9.1J_06h2PL9Rx_3DLFlbrfw',
    'https://api.mapbox.com/v4/sam-biproxi.us-parcels-1/{z}/{x}/{y}.vector.pbf?access_token=pk.eyJ1Ijoic2FtLWJpcHJveGkiLCJhIjoiY2t0bHZnc3c5MXppYTJxbmxvamk2cjg0ZiJ9.1J_06h2PL9Rx_3DLFlbrfw',
  ];

  /* Hooks */
  const user = useUser();

  /** GraphQL */
  const [getCherreBasicAddressInfo] = useLazyQuery(CHERRE_BASIC_ADDRESS_FROM_LAT_LNG, {
    variables: {
      params: {
        latitude: propertyLatLng[0],
        longitude: propertyLatLng[1],
      },
    },
    onError: (error) => {
      logger.warn(`There was an error querying Cherre for a cherre parcel id given lat/lng: ${error}`);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error querying our data provider. Please try again later.',
      });
    },
    onCompleted: (data) => {
      const cherreData = data?.cherreBasicAddressInfoFromLatLng;
      setParcelFeatureData(cherreData);
    },
  });

  const overlay = new DeckOverlay({
    layers: [
      new MVTLayer({
        id: 'MVTLayer1',
        data: tiles[0],
        minZoom: 13,
        maxZoom: 15,
        getLineColor: [63, 63, 225],
        opacity: 0.5,
        getFillColor: [63, 63, 225, 26],
        pickable: true,
        autoHighlight: true,
        highlightColor: [63, 63, 225, 128],
        onClick: (info: any) => handleParcelClick(info),
        lineWidthMinPixels: 1,
        lineWidthMaxPixels: 2,
      } as any),
      new MVTLayer({
        id: 'MVTLayer2',
        data: tiles[1],
        minZoom: 13,
        maxZoom: 15,
        getLineColor: [63, 63, 225],
        opacity: 0.5,
        getFillColor: [63, 63, 225, 26],
        pickable: true,
        autoHighlight: true,
        highlightColor: [63, 63, 225, 128],
        onClick: (info: any) => handleParcelClick(info),
        lineWidthMinPixels: 1,
        lineWidthMaxPixels: 2,
      } as any),
    ],
  });

  /** Helper Functions */

  const handleParcelClick = (parcelInfo) => {
    const parcelBoundaryPropertyDetails = JSON?.parse(parcelInfo?.object?.properties?.data);
    if (parcelBoundaryPropertyDetails?.address !== parcelFeatureData?.address) { // if the current data being served is the same as what is clicked, there is no point in refetching the same data. y'know?
      setClickedFeatureData(parcelBoundaryPropertyDetails);
      setDataFrameGeneralState((prevState) => ({
        ...prevState,
        dataFrameLoading: true,
        dataFrameVisible: true,
        similarPropertiesLoading: true,
      }));
      setPropertyLatLng([parcelInfo.coordinate[1].toString(), parcelInfo.coordinate[0].toString()]);
    }
  };

  /**
   * Called when the map loads. Used to zoom into a property that a user searches for
   */
  const onMapLoad = (loadedMap) => {
    setMap(loadedMap.map);
    setIsMapLoaded(true);
  };

  /** Effects */
  const getAddressFromLatLng = async () => {
    const pdpLocation: any = queryParams?.location;
    const address: IAddress = await AddressUtil.reverseGeocode(pdpLocation);
    setUtilityBarState((prevState) => ({
      ...prevState,
      specificAddressSearch: address,
    }));
  };

  React.useEffect(() => {
    if (queryParams?.location) {
      getAddressFromLatLng();
    }
  }, []);

  React.useEffect(() => {
    if (window) {
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.ViewedDataExplorer,
        window,
        {
          userId: user?.userId,
        },
      );
    }
  }, []);

  React.useEffect(() => {
    if (propertyLatLng.length) {
      getCherreBasicAddressInfo();
    }
  }, [propertyLatLng]);

  React.useEffect(() => {
    if (specificAddressSearch) {
      const lat: string = AddressUtil.getLatitude(specificAddressSearch);
      const lng: string = AddressUtil.getLongitude(specificAddressSearch);
      map.setCenter({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });
      map.setZoom(18);
      setDataFrameGeneralState((prevState) => ({
        ...prevState,
        dataFrameLoading: true,
        dataFrameVisible: true,
        similarPropertiesLoading: true,
      }));
      setPropertyLatLng([lat, lng]);
    }
  }, [specificAddressSearch]);

  return (
    <>
      <UtilityBar />

      <MapContainer>
        {!isMapLoaded && (
        <LoaderContainer>
          <Loader color={Colors.Brand800 || Colors.Blurple800} />
        </LoaderContainer>
        )}
        <GoogleMap
          center={{ lat: 38.89618351189365, lng: -90.39266225 }}
          height="100%"
          onLoad={(map) => {
            overlay.setMap(map?.map);
            onMapLoad(map);
          }}
          data-cy="google-map"
        >
          {(propertyLatLng.length && clickedFeatureData) && (
          <MapMarker
            lat={parseFloat(propertyLatLng[0]) + 0.0001}
            lng={parseFloat(propertyLatLng[1])}
            setClickedCoordinate={setPropertyLatLng}
            clickedFeatureData={clickedFeatureData}
          />
          )}
        </GoogleMap>
      </MapContainer>
      {propertyFiltersVisible && (
        <DataExplorerPropertyFilters />
      )}
      {showFilterPropertyList && (
        <ListingFilterPropertyList />
      )}
      <Geographies />
      <DataSettings />
      <DataFrame parcelFeatureData={parcelFeatureData} />
    </>
  );
};

export default DataExplorer;
