import React from 'react';
import { useDebounceCallback } from '@react-hook/debounce';

type UseReduxInputProps = {
    dispatchFunction: any;
    dispatchParams?: any;
    placeholder?: string;
    debounceTime?: number;
}

type UseReduxInputHook = {
  reduxInputValue?: string;
  setReduxInputValue: (value: string) => void;
};

type UseReduxInput = (props: UseReduxInputProps) => UseReduxInputHook;

const useReduxInput: UseReduxInput = ({
  dispatchFunction,
  dispatchParams,
  placeholder = '',
  debounceTime = 500,
}): UseReduxInputHook => {
  /* State */
  const [reduxInputValue, setReduxInputValue] = React.useState<string>(placeholder);

  /* Hooks */
  const updateReduxStoreDebounced = useDebounceCallback(dispatchFunction, debounceTime);

  /** Effects */
  React.useEffect(() => {
    if (reduxInputValue !== placeholder) {
      updateReduxStoreDebounced(...dispatchParams);
    }
  }, [reduxInputValue]);

  return {
    reduxInputValue,
    setReduxInputValue,
  };
};

export default useReduxInput;
