import { logger } from '@biproxi/logger';
import React from 'react';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import ProfileScoreRubric from '@biproxi/models/objects/ProfileScoreRubric.object';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ScoreUtil from '@biproxi/models/utils/ScoreUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import { KB_PROFILE_SCORES_URL } from '@biproxi/models/externalLinks';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import GET_USER from '../../graphql/queries/user.query';
import ScoreCard from '../ScoreCard';
import UserInfo, { UserInfoTypesEnum } from '../UserInfo';
import { ModalTypesEnum } from '../modal/Modal';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import Colors from '../../styles/Colors';
import UPDATE_USER_PROFILE from '../../graphql/mutations/updateUserProfileMutation';
import { UserActions } from '../../redux/user.redux';
import { useMobileMedia } from '../../utils/MediaQuery';

type ProfileScoreCardProps = {
  user: IUserGraphQL;
};

const ProfileScoreCard: React.FC<ProfileScoreCardProps> = ({
  user,
}) => {
  /** State */
  const [totalInitialScore, setTotalInitialScore] = React.useState(ScoreUtil.scoreRubric(user, ProfileScoreRubric));
  /** Hooks */
  const isMobile = useMobileMedia();

  /** GraphQL */
  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER }],
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Your profile was successfully updated.',
      });
    },
    onError: async (error) => {
      logger.error('UPDATE_USER_PROFILE error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const setValidationErrors = (errors: Record<string, string>) => dispatch(
    UserActions.setUserErrors(errors),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const pushProfilePreviewModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ProfilePreview,
      props: {},
    }),
  );

  const updateProfileHandler = () => {
    const params = {
      firstName: user.firstName,
      lastName: user.lastName,
      organizationName: user.organizationName,
      role: user.role,
      address: user.address,
      bio: user.bio,
      investorProfile: user.investorProfile,
    };

    const newScore = ScoreUtil.scoreRubric(user, ProfileScoreRubric);
    if (totalInitialScore !== 100 && newScore === 100) {
      setTotalInitialScore(newScore);
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.CompletedInvestorProfile,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
        },
      );
    }

    const { errors } = JoiUtil.validate(
      UserJoi.updateUserProfileParamsSchema,
      params,
    );

    if (JoiUtil.hasErrors(errors)) {
      setValidationErrors(JoiUtil.parseErrors(errors));
      return;
    }

    updateUserProfile({
      variables: {
        params,
      },
    });
  };

  function ProfileActions() {
    return (
      <Flex>
        <Button
          text="Preview"
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Medium}
          margin="0px 8px 0px 0px"
          isFullWidth
          onClick={pushProfilePreviewModal}
        />
        <Button
          data-cy="save-profile-button"
          text="Save"
          isLoading={loading}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Medium}
          isFullWidth
          onClick={updateProfileHandler}
        />
      </Flex>
    );
  }

  function ScoreCardHeader({ onIconClick }) {
    return (
      <>
        <Flex align="center" onClick={() => onIconClick?.()} width={!isMobile && '364px'}>
          <UserInfo
            user={user}
            type={UserInfoTypesEnum.Large}
            margin="0 0 16px"
            city
            nameOnly
          />
          {onIconClick && (
          <Icon
            icon={Icons.AngleUpRegular}
            position="absolute"
            right="12px"
            size={16}
            color={Colors.Grey700}
            rotation={180}
          />
          )}
        </Flex>
        <ProfileActions />
      </>
    );
  }

  /** Render */
  return (
    <ScoreCard
      Header={ScoreCardHeader}
      rubric={ProfileScoreRubric}
      title="Profile Score"
      subtitle="The profile score is based on the completeness of the information submitted."
      learnMoreLink={KB_PROFILE_SCORES_URL}
      objectToScore={user}
      Actions={ProfileActions}
      hideScore={!UserUtil.isInvestor(user)}
    />
  );
};

export default ProfileScoreCard;
