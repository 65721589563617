/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useMutation } from 'react-query';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { deleteOrganizationMutation } from '@biproxi/next-api-requests';
import { media, useMobileMedia } from 'apps/web/src/utils/MediaQuery';
import ICreateTeamParams from '@biproxi/models/interfaces/teams/ICreateTeamParams';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch } from '../redux/store';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Colors from '../styles/Colors';
import useUser from '../hooks/useUser.hook';
import NoContent from '../components/NoContent';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import { ModalTypesEnum } from '../components/modal/Modal';
import Loader, { LoaderSizes } from '../elements/Loader';
import Text, { TextTypesEnum } from '../elements/Text';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableHeader,
  ScrollTableHeaderCell,
} from '../components/ScrollTable';
import Flex from '../elements/Flex';
import DropdownList from '../components/DropdownList';
import useUserPermissions from '../hooks/useUserPermissions.hook';
import { ConfirmChangeModalTypesEnum } from '../components/modal/ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import useHandleRouteChange from '../hooks/useHandleRouteChange.hook';
import NextUtil from '../utils/NextUtil';

const Container = styled.div<{ 'data-cy': string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  data-cy: ${(props) => props['data-cy']};

  ${media.mobile} {
    margin: 0 auto;
    overflow-x: auto;
  }
`;

const AddTeamsandMembersContainer = styled.div`
  ${media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    width: 100%;
    padding:  4px 0;
  }
`;

const TeamPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    width: 100%;
  }
`;

const TeamsHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const TooltipContainer = styled.div`
  width: fit-content;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  ${media.mobile} {
    padding: 24px 0;
    width: 70px;
  }
`;

type TeamsPageProps = {}

enum TeamsPageActionsEnum {
  ViewTeam = 'View team',
  EditTeam = 'Edit team',
  DeleteTeam = 'Delete team'
}

const TeamsPage: React.FC<TeamsPageProps> = () => {
  /** State */
  const [actionMenuTeamId, setActionMenuTeamId] = React.useState<string>(null); // When an action menu is clicked for a specific team, set the id of that team to this state
  const [activeMenuItem, setActiveMenuItem] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  /** Hooks */
  const { userId } = useUser();
  const isMobile = useMobileMedia();

  const handleRouteChange = useHandleRouteChange();

  const teamActionsMenuRef = React.createRef<HTMLInputElement>();
  const ref = useOnclickOutside(() => {
    setActionMenuTeamId(null);
  });

  const { userOrganizationsRolesAndPermissions: organizations, loading, refetch } = useUserPermissions({ userId });
  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushCreateTeamModal = (teamData?: ICreateTeamParams) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateTeam,
      props: {
        refetchTeams: refetch,
        establishedTeamData: teamData || {
          name: '',
          createdByUserId: userId,
        },
      },
    }),
  );

  const pushDeleteTeamModal = ({ organizationId }) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Warning,
        title: 'Delete team',
        text: 'Are you sure you would like to delete this team? This action is irreversible',
        confirm: () => handleDeleteTeam({ organizationId }),
      },
    }),
  );

  /** Functions */
  const { mutate: handleDeleteTeam } = useMutation((params: INeo4jServiceAPI.IDeleteOrganizationParams) => deleteOrganizationMutation(params), {
    onSuccess: () => handleDeleteOrganizationSuccess(),
    onError: (err) => handleDeleteOrganizationError(err),
  });

  const handleDeleteOrganizationSuccess = () => {
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Team successfully deleted',
    });
    refetch();
    popModal();
  };

  const handleDeleteOrganizationError = (error) => {
    pushToast({
      type: ToastTypesEnum.Error,
      message: error,
    });
  };

  const handleTeamAction = (action: TeamsPageActionsEnum, organization?: INeo4jServiceAPI.INeo4jOrganization) => {
    setActiveMenuItem(action);
    switch (action) {
      case TeamsPageActionsEnum.ViewTeam:
        handleRouteChange(`/app/dashboard/teams/${organization?._id}`);
        break;
      case TeamsPageActionsEnum.DeleteTeam:
        pushDeleteTeamModal({ organizationId: actionMenuTeamId ?? organization?._id });
        break;
      case TeamsPageActionsEnum.EditTeam:
        pushCreateTeamModal({ ...organization, createdByUserId: userId });
        break;
      default:
        break;
    }
  };

  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  React.useEffect(() => {
    if (organizations) {
      setIsLoading(false);
    }
  }, [organizations]);

  if (isLoading || loading) {
    return (
      <TeamPageWrapper
        data-cy="teams-page-wrapper"
      >
        <LoadingContainer>
          <Loader
            size={LoaderSizes.Large}
            color={Colors.Brand700 || Colors.Blurple700}
          />
        </LoadingContainer>
      </TeamPageWrapper>
    );
  }

  const menuActionsItems = [
    {
      name: TeamsPageActionsEnum.ViewTeam,
      value: TeamsPageActionsEnum.ViewTeam,
    },
    {
      name: TeamsPageActionsEnum.EditTeam,
      value: TeamsPageActionsEnum.EditTeam,
    },
    {
      name: TeamsPageActionsEnum.DeleteTeam,
      value: TeamsPageActionsEnum.DeleteTeam,
    },
  ];

  return (
    <TeamPageWrapper
      data-cy="teams-page-wrapper"
    >
      <TeamsHeader>
        <Flex
          direction="column"
        >
          {!isMobile && (
          <>
            <Text
              type={TextTypesEnum.Bold24}
              color={Colors.Black}
            >
              Teams
            </Text>
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Grey700}
            >
              View and manage your teams
            </Text>
          </>
          )}

        </Flex>
        {isMobile ? (
          <>
            <Flex direction="column" gap="10px" align="center" width="100%">
              <AddTeamsandMembersContainer>

                <Button
                  text="Create team"
                  size={ButtonSizesEnum.Small}
                  onClick={() => pushCreateTeamModal()}
                  customWidth="100%"
                  type={ButtonTypesEnum.Pale}
                  data-cy="create-team-button"
                />

              </AddTeamsandMembersContainer>
            </Flex>
          </>
        ) : (
          <>
            <Flex gap="12px">
              <Button
                text="Create team"
                size={ButtonSizesEnum.Small}
                onClick={() => pushCreateTeamModal()}
                data-cy="create-team-button"
              />
            </Flex>

          </>
        )}

      </TeamsHeader>
      {organizations?.length && !loading ? (
        <Container
          data-cy="teams-table-wrapper"
        >
          <ScrollTable>
            <ScrollTableHeader width="100%">
              <ScrollTableHeaderCell
                flex="2"
              >
                Name
              </ScrollTableHeaderCell>
              <ScrollTableHeaderCell
                flex="3"
              >
                Description
              </ScrollTableHeaderCell>
              <ScrollTableHeaderCell
                minWidth="fit-content"
                flex="1"
              >
                Members
              </ScrollTableHeaderCell>
              <ScrollTableHeaderCell
                minWidth="fit-content"
                flex="1"
              >
                Listings
              </ScrollTableHeaderCell>
              <ScrollTableHeaderCell
                minWidth="fit-content"
                flex="1"
              >
                Actions
              </ScrollTableHeaderCell>
            </ScrollTableHeader>
            <ScrollTableBody>
              {organizations?.map((org, index) => {
                const dataCyPrefix = org.orgName?.split(' ').join('-').toLowerCase();
                const truncatedDescription = org?.orgDescription.length > 20 ? `${org?.orgDescription.substring(0, 20)}...` : org?.orgDescription;
                const truncatedOrgName = org?.orgName.length > 20 ? `${org?.orgName.substring(0, 20)}...` : org?.orgName;
                return (
                  <ScrollTableBodyRow>
                    <ScrollTableBodyCell
                      flex="2"
                    >
                      <TooltipContainer
                        data-tip={org?.orgName}
                        data-place="top"
                        data-multiline
                      >
                        <Text
                          color={Colors.Brand700 || Colors.Blurple700}
                          type={TextTypesEnum.Medium14}
                          data-cy={`table-team-name-${index}`}
                          onClick={() => handleRouteChange(`/app/dashboard/teams/${org?.orgId}`)}
                        >
                          {truncatedOrgName}
                        </Text>
                      </TooltipContainer>
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell
                      flex="3"
                    >
                      <TooltipContainer
                        data-tip={org?.orgDescription}
                        data-place="top"
                        data-multiline
                      >
                        <Text
                          type={TextTypesEnum.Regular14}
                          data-cy={`table-team-description-${index}`}
                          width="100%"
                        >
                          {truncatedDescription}
                        </Text>
                      </TooltipContainer>
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell
                      flex="1"
                      maxWidth="120px"
                    >
                      <Text
                        type={TextTypesEnum.Regular14}
                        data-cy={`table-team-members-${index}`}
                      >
                        {org?.userConnections?.totalCount}
                      </Text>
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell
                      flex="1"
                      maxWidth="120px"
                    >
                      <Text
                        type={TextTypesEnum.Regular14}
                        data-cy={`table-team-listings-${index}`}
                      >
                        {org?.listingConnections?.totalCount}
                      </Text>
                    </ScrollTableBodyCell>
                    <ScrollTableBodyCell
                      flex="1"
                      maxWidth="120px"
                    >
                      <ActionButtonsContainer
                        ref={ref}
                      >
                        <Icon
                          icon={Icons.BarsRegular}
                          color={Colors.Brand700 || Colors.Blurple700}
                          size={14}
                          onClick={() => setActionMenuTeamId(org?.orgId)}
                          data-cy={`${dataCyPrefix}-actions-menu-button`}
                        />
                        <DropdownList
                          ref={teamActionsMenuRef}
                          value={activeMenuItem?.text ?? ''}
                          open={actionMenuTeamId === org?.orgId}
                          setOpen={() => setActionMenuTeamId(org?.orgId)}
                          items={menuActionsItems?.filter((menuItem) => {
                            if (org?.roles?.includes(TeamRolesEnum?.Member) && (menuItem?.value === TeamsPageActionsEnum.DeleteTeam || menuItem?.value === TeamsPageActionsEnum.EditTeam)) {
                              return false;
                            }
                            return true;
                          })}
                          onChange={(item) => handleTeamAction(item, {
                            _id: org?.orgId,
                            name: org?.orgName,
                            description: org?.orgDescription,
                          })}
                          width="fit-content"
                          cyList="team-actions-list"
                        />
                      </ActionButtonsContainer>
                    </ScrollTableBodyCell>
                  </ScrollTableBodyRow>
                );
              })}
            </ScrollTableBody>
          </ScrollTable>
        </Container>
      ) : (
        <>
          <NoContent
            icon={Icons.UserRegular}
            height="100%"
            text="You have not created a team"
            data-cy="no-content"
          />
          {(!isMobile && !loading) && (
          <Button
            text="Create a team"
            customWidth="110px"
            onClick={() => pushCreateTeamModal()}
            data-cy="no-content-button"
          />
          )}
        </>
      )}
    </TeamPageWrapper>
  );
};

export default TeamsPage;
