import gql from 'graphql-tag';

const mutation = gql`
  mutation DeleteUserLicense($params: DeleteUserLicenseParams!) {
    deleteUserLicense(params: $params) {
      firstName
      lastName
      phoneNumber
      phoneNumberVerifiedAt
      email
      role
      organizationName
      licenses {
        _id
        owner
        state
        number
      }
    }
  }
`;

export default mutation;
