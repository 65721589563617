import React from 'react';
import styled from '@emotion/styled';
import IListing, { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IScoreRubricSection } from '@biproxi/models/interfaces/IScore';
import ScoreUtil from '@biproxi/models/utils/ScoreUtil';
import IUser, { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import Colors from '../styles/Colors';
import BoxShadows from '../styles/BoxShadows';
import Divider from '../elements/Divider';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Link from '../elements/Link';
import Icon, { Icons } from '../elements/Icon';
import { media, useMobileMedia } from '../utils/MediaQuery';
import CircularProgressBar, { CircularProgressBarSizesEnum } from '../elements/CircularProgressBar';

const Container = styled.div`
  top: 80px;
  max-width: 364px;
  padding: 24px;
  margin: 0px 0px 0px 32px;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Grey300};
  border-radius: 16px;
  box-sizing: border-box;
  height: fit-content;
  box-shadow: ${BoxShadows.Light};
`;

const DescriptionTitle = styled.div`
  display: inline-block;
  border-bottom: 2px dashed ${Colors.Grey400} !important;
  margin-bottom: 4px;

  ${media.mobile} {
    margin: 0px 0px 0px 16px;
  }
`;

const Scorebar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${Colors.Grey300};
  border-radius: 8px;
  margin: 8px 0px 0px 0px;
`;

const ScorebarColor = styled.div <{ color?: string, percent?: number }>`
  width: ${(props) => (props.percent ? `${props.percent}%` : null)};
  height: 4px;
  background-color: ${(props) => (props.color ? props.color : Colors.Grey300)};
  z-index: 200;
  border-radius: 8px;
  transition: all 0.5s;
`;

const MobileFooter = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 72px;
  background-color: ${Colors.White};
  border-top: ${`1px solid ${Colors.Grey300}`};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 199;
`;

const ProgressCircleOnClickContainer = styled.div``;

type MobileScorecardContainerProps = {
  displayScorecard: boolean;
}

const MobileScorecardContainer = styled.div<MobileScorecardContainerProps>`
  position: fixed;
  overflow-y: scroll;
  z-index: 200;
  bottom: 0;
  left: 0;
  top: ${(props) => (props.displayScorecard ? '56px' : '100%')};
  width: 100%;
  transition: 0.5s ease;
  background-color: ${Colors.White};
`;

const ActionsContainer = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 16px 16px 0;
`;

type SectionProps = {
  objectToScore: IListingGraphQL | IUserGraphQL;
  section: IScoreRubricSection<IListingGraphQL | IUserGraphQL>;
  index?: number;
  rubricLength?: number;
}

const Section: React.FC<SectionProps> = ({
  objectToScore,
  section,
  index,
  rubricLength,
}) => {
  /* State */
  const [isOpen, setOpen] = React.useState<boolean>(false);

  /** Hooks */
  const isMobile = useMobileMedia();

  /* Render */
  const sectionScore = ScoreUtil.scoreRubricSection(objectToScore, section);
  const barColor = sectionScore === 0
    ? Colors.Grey300
    : sectionScore === 100
      ? Colors.Green500
      : Colors.Yellow500;
  const iconColor = sectionScore === 0
    ? Colors.Grey400
    : sectionScore === 100
      ? Colors.Green500
      : Colors.Yellow500;

  return (
    <>
      <Flex id={section.name} direction="column" margin={isMobile ? '0px 16px' : '0px 0px 8px 0px'} onClick={() => setOpen(!isOpen)}>
        <Flex align="center">
          <Icon
            icon={Icons.CheckCircleSolid}
            color={iconColor}
            size={16}
            margin="0px 8px 0px 0px"
          />
          <Text
            type={TextTypesEnum.Bold16}
            color={Colors.Black}
          >
            {section.name}
          </Text>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey500}
          >
            &nbsp;
            {`(${(sectionScore * (section.items.length / 100)).toFixed(0)}/${section.items.length})`}
          </Text>
          <Icon
            icon={Icons.AngleDownRegular}
            position="absolute"
            right="12px"
            width="12px"
            size={16}
            color={Colors.Grey700}
            rotation={isOpen ? 180 : null}
          />
        </Flex>
        <Scorebar>
          <ScorebarColor color={barColor} percent={sectionScore} />
        </Scorebar>
      </Flex>
      {isOpen && section.items.map((item, index) => (
        <Flex key={index} margin={isMobile ? '0px 16px' : null}>
          {item.complete(objectToScore) ? (
            <Icon
              icon={Icons.CheckCircleSolid}
              color={Colors.Green500}
              size={14}
              margin="0px 8px 0px 0px"
            />
          ) : (
            <Icon
              icon={Icons.CheckCircleSolid}
              color={Colors.Grey400}
              size={14}
              margin="0px 8px 0px 0px"
            />
          )}
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Grey900}
          >
            {item.name}
          </Text>
          {item.required && (
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Grey500}
            >
              &nbsp;
              (required)
            </Text>
          )}
        </Flex>
      ))}
      {index === rubricLength - 1 && !isMobile ? null : (
        <Divider margin={isMobile ? '16px 0px' : '24px 0px'} />
      )}
    </>
  );
};

// ObjectToScore must match rubric for this to work correctly.
type ScoreCardProps = {
  rubric: IScoreRubricSection<IListing | IUser>[];
  objectToScore?: IListingGraphQL | IUserGraphQL;
  Header: React.ElementType;
  title: string;
  subtitle: string;
  learnMoreLink: string;
  Actions: React.ElementType;
  hideScore?: boolean;
};

const ScoreCard: React.FC<ScoreCardProps> = ({
  rubric,
  objectToScore,
  Header,
  title,
  subtitle,
  learnMoreLink,
  Actions,
  hideScore = false,
}) => {
  /** State */
  const [displayScorecard, setDisplayScorecard] = React.useState<boolean>(false);
  const totalScore = ScoreUtil.scoreRubric(objectToScore, rubric);

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Render */
  const color = totalScore === 100
    ? Colors.Green500
    : totalScore > 0
      ? Colors.Yellow500
      : Colors.Grey300;

  return (
    <>
      {isMobile ? (
        <>
          <MobileFooter>
            <ActionsContainer>
              <Actions />
            </ActionsContainer>
            {!hideScore && (
              <ProgressCircleOnClickContainer onClick={() => setDisplayScorecard(true)}>
                <CircularProgressBar
                  size={CircularProgressBarSizesEnum.Small}
                  margin="0px 0px 0px 8px"
                  percent={totalScore}
                  background={Colors.White}
                  progressBackground={Colors.Grey300}
                  progressColor={color}
                  borderWidth={2}
                />
              </ProgressCircleOnClickContainer>
            )}
          </MobileFooter>
          <MobileScorecardContainer displayScorecard={displayScorecard}>
            <HeaderContainer>
              <Header onIconClick={() => setDisplayScorecard(false)} />
            </HeaderContainer>
            <Divider margin="16px 0px" />
            <Link href={learnMoreLink} target="_blank">
              <DescriptionTitle>
                <Flex align="center">
                  <Text
                    type={TextTypesEnum.Bold18}
                    color={Colors.Black}
                  >
                    {title}
                  </Text>
                  <Icon
                    icon={Icons.InfoSquareSolid}
                    size={14}
                    color={Colors.Grey400}
                    margin="0px 0px 0px 8px"
                  />
                </Flex>
              </DescriptionTitle>
            </Link>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey700} margin="0px 16px 16px 16px">
              {subtitle}
            </Text>
            <Flex direction="column" margin="16px 16px">
              <Flex align="flex-end">
                <Text
                  type={TextTypesEnum.Bold30}
                  color={Colors.Black}
                >
                  {totalScore}
                </Text>
                <Text
                  type={TextTypesEnum.Regular18}
                  color={Colors.Grey500}
                >
                  /100
                </Text>
              </Flex>
              <Scorebar>
                <ScorebarColor color={color} percent={totalScore} />
              </Scorebar>
            </Flex>
            <Divider margin="16px 0px" />
            {rubric.map((section, index) => (
              <Section
                objectToScore={objectToScore}
                section={section}
                index={index}
                key={index}
                rubricLength={rubric.length}
              />
            ))}
          </MobileScorecardContainer>
        </>
      ) : (
        <Container>
          <Header />
          {!hideScore && (
            <>
              <Divider margin="24px 0px" />
              <Link href={learnMoreLink} target="_blank">
                <DescriptionTitle>
                  <Flex align="center" tip="Click here to learn more">
                    <Text
                      type={TextTypesEnum.Bold18}
                      color={Colors.Black}
                    >
                      {title}
                    </Text>
                    <Icon
                      icon={Icons.InfoSquareSolid}
                      size={14}
                      color={Colors.Grey400}
                      margin="0px 0px 0px 8px"
                    />
                  </Flex>
                </DescriptionTitle>
              </Link>
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey700} margin="0px 0px 24px 0px">
                {subtitle}
              </Text>
              <Flex direction="column">
                <Flex align="flex-end">
                  <Text
                    type={TextTypesEnum.Bold30}
                    color={Colors.Black}
                  >
                    {totalScore ?? 0}
                  </Text>
                  <Text
                    type={TextTypesEnum.Regular18}
                    color={Colors.Grey500}
                  >
                    /100
                  </Text>
                </Flex>
                <Scorebar>
                  <ScorebarColor color={color} percent={totalScore} />
                </Scorebar>
              </Flex>
              <Divider margin="24px 0px" />
              {rubric.map((section, index) => (
                <Section
                  objectToScore={objectToScore}
                  section={section}
                  index={index}
                  key={index}
                  rubricLength={rubric.length}
                />
              ))}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default ScoreCard;
