enum DataExplorerSettingsSectionsEnum {
    LotInformation = 'Lot Information',
    LotSize = 'Lot Size',
    BuildingInfo = 'Building Info',
    Taxes = 'Taxes',
    PropertyDetails = 'Property Details',
    AdditionalPropertyDetails = 'Additional Property Details',
    Demographics = 'Demographics',
    PropertyOwners = 'Property Owners',
    TransactionTimeline = 'Transaction Timeline'
}

export default DataExplorerSettingsSectionsEnum;
