import React from 'react';
import { KB_PROFILE_BASE_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';

type ProfileHeaderProps = {};

const ProfileHeader: React.FC<ProfileHeaderProps> = () => (
  <ContentCard
    title="Investor Profile"
    subtitle="Your investor profile is information that is only shared with brokers when you become a lead on their property in Biproxi. Completely filling out your profile helps make you a more attractive option to a seller who is considering selling their property to you."
    learnMoreLink={KB_PROFILE_BASE_URL}
    headerType={ContentCardHeaderTypesEnum.PageHeader}
  />
);

export default ProfileHeader;
