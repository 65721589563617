import React from 'react';
import styled from '@emotion/styled';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import UserUtil from '@biproxi/models/utils/UserUtil';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import { BIPROXI_PLANS_BROKER, BIPROXI_PLANS_INVESTOR, BIPROXI_WEBSITE_URL } from '@biproxi/models/externalLinks';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import LinkText from '../../elements/LinkText';
import Link from '../../elements/Link';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import useChargebee from '../../hooks/useChargebee.hook';
import GenericToggle from '../../elements/GenericToggle';
import { UserSelectors } from '../../redux/user.redux';
import useUser from '../../hooks/useUser.hook';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import BoxShadows from '../../styles/BoxShadows';

const Container = styled.div<{ active: boolean, 'data-cy': string }>`
  overflow: hidden;
  position: relative;
  box-shadow: ${BoxShadows.Hard};
  width: 280px;
  height: 125px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: ${({ active }) => (active ? '1px solid transparent' : `1px solid ${Colors.Grey200}`)};
  background-color: ${({ active }) => (active ? Colors.Green50 : null)};
  border-radius: 16px;
  padding: 16px 24px;

  &:hover {
    background-color: ${Colors.Grey50};
  }
`;

const chargebeeItemPriceIdToPlanTitle = {
  [BillingPlanIdEnum.FreemiumMonthly]: 'Freemium',
  [BillingPlanIdEnum.BrokerProMonthly]: 'Broker Pro (Monthly)',
  [BillingPlanIdEnum.BrokerProYearly]: 'Broker Pro (Annual)',
};

type SettingsBillingPlanCardProps = {
  plan: any;
  active: boolean;
};

const SettingsBillingPlanCard: React.FC<SettingsBillingPlanCardProps> = ({ plan, active }) => {
  /** State */
  const subscription = useAppSelector(UserSelectors.subscription);
  const isFreemiumCard = Boolean(plan?.item_price?.id === BillingPlanIdEnum.FreemiumMonthly);
  const subscriptionWillNotRenew = Boolean(subscription?.status === 'non_renewing');

  /** Hooks */
  const { proceedToCheckout, cancelSubscription, renewSubscription } = useChargebee();
  const { user } = useUser();

  const learnMoreLink = isFreemiumCard ? BIPROXI_WEBSITE_URL
    : UserUtil.isBroker(user)
      ? BIPROXI_PLANS_BROKER
      : BIPROXI_PLANS_INVESTOR;

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushCancelSubscriptionModal = (subscriptionId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Cancel subscription',
        text: 'Are you sure? You will lose access to all paid features! If you choose to proceed, your subscription will expire on the next billing cycle. You can renew at any time.',
        buttonText: 'Unsubscribe',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => {
          cancelSubscription.mutate(subscriptionId);
          popModal();
        },
      },
    }),
  );

  const pushReactivateSubscriptionModal = (subscriptionId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Renew subscription',
        text: 'Your subscription will re-activate immmediately.',
        buttonText: 'Re-activate',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => {
          renewSubscription.mutate(subscriptionId);
          popModal();
        },
      },
    }),
  );

  return (
    <Container active={active} data-cy={`${chargebeeItemPriceIdToPlanTitle[plan?.item_price?.id].replace(/[{()}]/g, '').split(' ').join('-').toLowerCase()}-container`}>
      <Flex justify="space-between">
        <div>
          <Text color={Colors.Black} type={TextTypesEnum.Medium16}>
            {chargebeeItemPriceIdToPlanTitle[plan?.item_price?.id]}
          </Text>

          {/*
            The commented code below produced NaN in prod so we changed it. But tbh I don't know what this was trying to accomplish in the first place
            as the variables referenced don't even seem like the correct ones. Maybe someone else is more enlightened?
          */}

          {/* {!isFreemiumCard && Boolean(Object?.keys(subscription)?.length && subscription?.subscription_items?.[0]?.item_price_id === plan?.item_price?._id) && (
            <Text color={Colors.Green400}>
              :
              {' '}
              $
              {subscription?.subscription_items?.[0]?.amount / 100}
              /month
            </Text>
          )} */}

          {/*  */}
          {!isFreemiumCard && plan?.item_price && (
            <>
              <Text color={Colors.Green400}>
                $
                {plan?.item_price?.price / 100}
                /
                {plan?.item_price?.period_unit}
              </Text>
              {plan?.item_price?.period_unit === 'year' && (
                <Text color={Colors.Grey900} type={TextTypesEnum.Bold10}>
                  Get 1 month free when you pay annually
                </Text>
              )}
            </>
          )}
        </div>
        {!isFreemiumCard && (
          <GenericToggle
            dataCy={`${chargebeeItemPriceIdToPlanTitle[plan?.item_price?.id].replace(/[{()}]/g, '').split(' ').join('-').toLowerCase()}-checkbox`}
            value={active}
            noText
            onChange={active ? () => pushCancelSubscriptionModal(subscription.id) : () => proceedToCheckout(plan?.item_price?.id)}
          />
        )}
      </Flex>
      {/* Not working in dev for some reason, subscription object not present. Works for other envs though  */}
      {active && !subscriptionWillNotRenew && !isFreemiumCard && (
        <Flex>
          <Text type={TextTypesEnum.Medium16}>
            Next charge
          </Text>
          <Text>
            :
            {' '}
            {`${TimeUtil.format(subscription?.next_billing_at * 1000, 'DD')}`}
          </Text>
        </Flex>
      )}
      {active && subscriptionWillNotRenew && (
        <Flex>
          <Text type={TextTypesEnum.Medium16}>
            Expires on
          </Text>
          <Text>
            :
            {' '}
            {TimeUtil.format(subscription?.current_term_end * 1000, 'DD')}
          </Text>
        </Flex>
      )}
      <Link
        href={learnMoreLink}
        target="_blank"
        isExternal
      >
        <LinkText type={TextTypesEnum.Medium14}>Learn more</LinkText>
      </Link>
      {(!active && !isFreemiumCard) && (
        <LinkText
          type={TextTypesEnum.Medium14}
          onClick={() => proceedToCheckout(plan?.item_price?.id)}
        >
          {`Subscribe to ${chargebeeItemPriceIdToPlanTitle[plan?.item_price?.id]}`}
        </LinkText>
      )}
      {(active && !isFreemiumCard && !subscriptionWillNotRenew) && (
      <LinkText
        type={TextTypesEnum.Medium14}
        onClick={() => pushCancelSubscriptionModal(subscription?.id)}
      >
        Cancel subscription
      </LinkText>
      )}
      {(active && subscriptionWillNotRenew) && (
      <LinkText
        type={TextTypesEnum.Medium14}
        onClick={() => pushReactivateSubscriptionModal(subscription?.id)}
      >
        Re-activate subscription
      </LinkText>
      )}
    </Container>
  );
};

export default React.memo(SettingsBillingPlanCard);
