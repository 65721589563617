import CherrePropertyTypesEnum from '../enums/CherrePropertyTypesEnum';
import CherreAssetClassesEnum from '../enums/CherreAssetClassesEnum';

export default {
  // Agriculture
  [CherrePropertyTypesEnum.Agriculture]: [
    CherreAssetClassesEnum.Agriculture,
    CherreAssetClassesEnum.Timberland,
  ],
  [CherrePropertyTypesEnum.Hotel]: [
    CherreAssetClassesEnum.Hotel,
    CherreAssetClassesEnum.Motel,
  ],
  [CherrePropertyTypesEnum.Industrial]: [
    CherreAssetClassesEnum.Industrial,
    CherreAssetClassesEnum.Manufacturing,
    CherreAssetClassesEnum.Storage,
  ],
  [CherrePropertyTypesEnum.Land]: [
    CherreAssetClassesEnum.Vacant,
    CherreAssetClassesEnum.VacantAgricultural,
    CherreAssetClassesEnum.VacantCommercial,
    CherreAssetClassesEnum.VacantIndustrial,
    CherreAssetClassesEnum.VacantResidential,
  ],
  [CherrePropertyTypesEnum.MultiFamily]: [
    CherreAssetClassesEnum.Apartments,
    CherreAssetClassesEnum.MultiFamily,
  ],
  [CherrePropertyTypesEnum.Office]: [
    CherreAssetClassesEnum.MedicalServices,
    CherreAssetClassesEnum.MixedUse,
    CherreAssetClassesEnum.Office,
  ],
  [CherrePropertyTypesEnum.Other]: [
    CherreAssetClassesEnum.CommercialCondo,
    CherreAssetClassesEnum.Educational,
    CherreAssetClassesEnum.GeneralCommercial,
    CherreAssetClassesEnum.Government,
    CherreAssetClassesEnum.Historical,
    CherreAssetClassesEnum.Hospital,
    CherreAssetClassesEnum.Misc,
    CherreAssetClassesEnum.Parking,
    CherreAssetClassesEnum.Services,
    CherreAssetClassesEnum.Transportation,
    CherreAssetClassesEnum.Unknown,
    CherreAssetClassesEnum.Utilites,
  ],
  [CherrePropertyTypesEnum.Residential]: [
    CherreAssetClassesEnum.Cooperative,
    CherreAssetClassesEnum.MiscResidential,
    CherreAssetClassesEnum.MobileHomes,
    CherreAssetClassesEnum.ResidentialPlex,
    CherreAssetClassesEnum.ResidentialCondo,
    CherreAssetClassesEnum.SingleFamilyResidence,
  ],
  [CherrePropertyTypesEnum.Retail]: [
    CherreAssetClassesEnum.Attractions,
    CherreAssetClassesEnum.Recreation,
    CherreAssetClassesEnum.Retail,
  ],
};
