import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useQuery } from '@apollo/client';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ILicense from '@biproxi/models/interfaces/ILicense';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { KB_CREATE_LISTING_LICENSE_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Dropdown, { DropdownTypesEnum } from '../../elements/Dropdown';
import LinkText from '../../elements/LinkText';
import Text, { TextTypesEnum } from '../../elements/Text';
import { ModalTypesEnum } from '../modal/Modal';
import { AppActions } from '../../redux/app.redux';
import GET_USER from '../../graphql/queries/user.query';
import InlineAlert, { InlineAlertStylesEnum, InlineAlertTypesEnum } from '../InlineAlert';
import NoContent from '../NoContent';
import { Icons } from '../../elements/Icon';
import Checkbox from '../../elements/Checkbox';

type CreateListingLicenseProps = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingLicense: React.FC<CreateListingLicenseProps> = ({
  id,
  listing,
}) => {
  /** State */
  const errors = useAppSelector(ListingSelectors.errors);
  const licenseError = errors['/license/owner'];

  /* Actions */
  const dispatch = useAppDispatch();

  const setListingField = (license: ILicense) => dispatch(
    ListingActions.setListingField({ license }),
  );

  const setListingListedByOwner = (listedByOwner: boolean) => dispatch(
    ListingActions.setListingListedByOwner({ listedByOwner }),
  );

  const pushAddLicenseModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AddLicense,
      props: {
        createListing: true,
      },
    }),
  );

  /** Graphql */
  const { data } = useQuery<{ user: IUserGraphQL }>(GET_USER);

  if (!data?.user) return null;

  const userOnlyHasOneLicense = Boolean(data?.user?.licenses.length === 1);

  /** Effects */
  React.useEffect(() => {
    if (userOnlyHasOneLicense) {
      setListingField(data?.user?.licenses?.[0]);
    }
  }, []);

  /* Render */
  return (
    <ContentCard
      dataCy="license-requirement-greeting"
      id={id}
      title="License"
      subtitle="A real estate license is required in order to publish a listing unless you are the sole owner of the property."
      learnMoreLink={KB_CREATE_LISTING_LICENSE_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      {(() => {
        if (data?.user?.licenses?.length === 0) {
          const text = (
            <Text type={TextTypesEnum.Regular14}>
              No licenses yet.&nbsp;
              <LinkText
                onClick={() => pushAddLicenseModal()}
                type={TextTypesEnum.Medium14}
              >
                Click here to add a license
              </LinkText>
            </Text>
          );

          return (
            <>
              <Checkbox
                label="I am the owner of this property"
                dataCy="license-checkbox"
                onClick={() => setListingListedByOwner(!listing.listedByOwner)}
                active={listing.listedByOwner}
                margin="0px 0px 12px 0px"
              />
              <NoContent height="172px" icon={Icons.FileCertificateRegular} text={text} />
            </>
          );
        }

        return (
          <>
            <Checkbox
              label="I am the owner of this property"
              onClick={() => setListingListedByOwner(!listing.listedByOwner)}
              active={listing.listedByOwner}
              margin="0px 0px 12px 0px"
            />
            <Dropdown
              label="License"
              type={DropdownTypesEnum.Select}
              value={StringUtil.formatLicense(listing?.license)}
              onChange={(val) => setListingField(val)}
              placeholder="Select from your licenses"
              maxDropdownHeight="168px"
              items={data?.user?.licenses?.map((value: ILicense) => ({
                name: StringUtil.formatLicense(value),
                value,
              }))}
              error={licenseError}
            />
            <LinkText
              margin="8px 0 16px"
              type={TextTypesEnum.Medium12}
              onClick={() => pushAddLicenseModal()}
            >
              Add another license
            </LinkText>
            {/** Not enforcing this right now */}
            {false && (
            <InlineAlert
              type={InlineAlertTypesEnum.RedWarning}
              styleOption={InlineAlertStylesEnum.WithTop}
              label="Incorrect State"
              text="You must select a real estate license in the state of the listing (Montana) to list this property."
            />
            )}
          </>
        );
      })()}
    </ContentCard>
  );
};

export default CreateListingLicense;
