import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import RecentMessagesCard from './RecentMessagesCard';
import RecentInvestorMatchesCard from './RecentInvestorMatchesCard';
import RecentLeadsCard from './RecentLeadsCard';
import Text, { TextTypesEnum } from '../../../elements/Text';
import { media } from '../../../utils/MediaQuery';

const RecentActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 24px;
`;

const ActivityCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 16px;
    width: 100%;
    margin-top: 16px;
    ${media.mobile} {
        flex-direction: column;
  }
`;

type ListingActivityProps = {
  listingId: string;
  leads: Partial<ILeadGraphQL>[];
  loading: boolean;
}

const ListingActivity: React.FC<ListingActivityProps> = ({ listingId, leads, loading }) => {
  /** State */
  const nonInvestorMatchLeads = leads?.filter(({ isInvestorMatch }) => !isInvestorMatch);
  const investorMatchLeads = leads?.filter(({ isInvestorMatch }) => isInvestorMatch);

  /** Render */
  return (
    <RecentActivityContainer>
      <Text type={TextTypesEnum.Bold24} width="100%">Activity</Text>
      <ActivityCardsContainer>
        <RecentLeadsCard listingId={listingId} nonInvestorMatchLeads={nonInvestorMatchLeads} loading={loading} />
        <RecentInvestorMatchesCard listingId={listingId} investorMatchLeads={investorMatchLeads} loading={loading} />
        <RecentMessagesCard />
      </ActivityCardsContainer>
    </RecentActivityContainer>
  );
};

export default ListingActivity;
