import ListingVaultFolderNamesEnum from '../enums/ListingVaultFolderNamesEnum';
import TListingInfoField from '../types/TListingInfoField';
import ListingUtil from '../utils/ListingUtil';
import IListing from '../interfaces/IListing';
import { IScoreRubricSection } from '../interfaces/IScore';

const ListingScoreRubric: IScoreRubricSection<IListing>[] = [
  {
    name: 'Basic Information',
    items: [
      {
        name: 'Address',
        required: true,
        points: 5,
        complete: (listing: IListing) => Boolean(listing?.address?.address1),
      },
      {
        name: 'Name',
        required: false,
        points: 5,
        complete: (listing: IListing) => Boolean(listing?.name),
      },
      {
        name: 'At least 1 photo',
        required: true,
        points: 5,
        complete: (listing: IListing) => Boolean((listing?.media?.fileIds?.length ?? 0) >= 1),
      },
      {
        name: '3+ Photos',
        required: false,
        points: 5,
        complete: (listing: IListing) => Boolean((listing?.media?.fileIds?.length ?? 0) >= 3),
      },
    ],
  },
  {
    name: 'Property Details',
    items: [
      {
        name: 'Asset Class & Subtype',
        required: true,
        points: 10,
        complete: (listing: IListing) => Boolean(listing.assetClass) && Boolean(listing.propertyType),
      },
      {
        name: '4+ fields filled',
        required: false,
        points: 10,
        complete: (listing: IListing) => {
          const filledOutFieldList: TListingInfoField[] = listing.info.filter((field: TListingInfoField): boolean => Boolean(field.value ?? null));

          return filledOutFieldList.length >= 4;
        },
      },
    ],
  },
  {
    name: 'Highlights',
    items: [
      {
        name: 'Include at least 4 highlights',
        required: false,
        points: 20,
        complete: (listing: IListing) => Boolean((listing?.highlights?.length ?? 0) >= 4),
        percentageComplete: (listing: IListing) => {
          if (listing?.highlights?.length >= 4) {
            return 1;
          }
          return ((listing?.highlights?.length || 0) / 4) ?? 0;
        },
      },
    ],
  },
  {
    name: 'Description',
    items: [
      {
        name: 'Add a description',
        required: true,
        points: 20,
        complete: (listing: IListing) => Boolean(listing?.description?.length > 10),
      },
    ],
  },
  {
    name: 'Due Diligence',
    items: [
      {
        name: 'Add offering memorandum file',
        required: false,
        points: 10,
        complete: (listing: IListing) => Boolean(ListingUtil.vaultFolder(listing, ListingVaultFolderNamesEnum.OfferingMemorandum)?.fileIds.length),
      },
      {
        name: '5+ due diligence files',
        required: false,
        points: 10,
        complete: (listing: IListing) => ListingUtil.vaultFileIds(listing).length >= 5,
        percentageComplete: (listing: IListing) => {
          if (ListingUtil.vaultFileIds(listing).length >= 5) {
            return 1;
          }
          return ListingUtil.vaultFileIds(listing).length / 5;
        },
      },
    ],
  },
];

export default ListingScoreRubric;
