import React from 'react';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useMutation } from '@apollo/client';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import LeadAlertTypesEnum from '@biproxi/models/enums/LeadAlertTypesEnum';
import LeadUtil from '@biproxi/models/utils/LeadUtil';
import { ellipsis } from 'polished';
import styled from '@emotion/styled';
import {
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableSpace,
} from './ScrollTable';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import UserInfo, { UserInfoTypesEnum } from './UserInfo';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import UPDATE_FAVORITE_LEADS from '../graphql/mutations/updateFavoriteLeads.mutation';
import { IToastConfig, ToastTypesEnum } from './Toast';
import LIST_LEADS from '../graphql/queries/leads.query';
import { LeadActions, LeadSelectors } from '../redux/lead.redux';
import { LeadModalPageStateEnum } from './modal/LeadModal';
import Tag from '../elements/Tag';
import InlineLeadTableAlert, { InlineLeadTableAlertProps } from './InlineLeadTableAlert';
import LeadTableAlertUtil from '../utils/LeadTableAlertUtil';
import { CustomSpacer } from '../elements/Flex';

type LeadsTableRowProps = {
  lead: ILeadGraphQL;
  index: number;
  nameOnly?: boolean;
  expandOnly?: boolean;
};

const NotesContainer = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => ellipsis(undefined, expanded ? Infinity : 2)};
  margin: ${({ expanded }) => expanded && '9.5px 0'};
`;

/**
 * Refactor this shit.
 */
const LeadsTableRow: React.FC<LeadsTableRowProps> = ({
  lead,
  index,
  nameOnly,
  expandOnly,
}) => {
  /* State */
  const [isFavorite, setIsFavorite] = React.useState(lead?.isFavorite);
  const [dynamicRowHeight, setDynamicRowHeight] = React.useState(0);
  const inlineAlertTypes: LeadAlertTypesEnum[] = LeadUtil.getLeadAlertTypes(lead);
  const inlineAlertConfigs: InlineLeadTableAlertProps[] = LeadTableAlertUtil.getAlertConfigs(inlineAlertTypes, lead);

  const {
    metrics: {
      signedCA,
      pageViews,
    },
    meta: { createdAt },
  } = lead;

  const createTime = StringUtil.capitalize(
    TimeUtil.format(
      createdAt,
      'LLLL d \'at\' h:mma',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ).toLowerCase(),
  );

  /* Actions */
  const dispatch = useAppDispatch();
  const newChannel = useAppSelector((state: AppState) => (state?.chat ? state.chat?.newChannel : null));
  const expandedArray = useAppSelector(LeadSelectors.leadTableRowExpandedLeadIds);
  const expandLead = (lead: ILeadGraphQL) => dispatch(LeadActions.pushLeadTableRowExpandedLeadIds({ leadIds: [lead?._id] }));
  const contractLead = (lead: ILeadGraphQL) => dispatch(LeadActions.pullLeadTableRowExpandedLeadIds({ leadIds: [lead?._id] }));
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushLeadModal = (leadId: string, initialState: LeadModalPageStateEnum) => {
    dispatch(LeadActions.selectLead({ leadId }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        isInvestorMatch: true,
        initialState,
      },
    }));
  };

  const toggleFavorite = () => {
    const params: ILeadService.IUpdateFavoriteLeadsParams = {};

    if (isFavorite) {
      params.remove = [lead._id];
    } else {
      params.add = [lead._id];
    }

    setIsFavorite(!isFavorite);

    updateFavoriteLeads({
      variables: {
        params,
      },
    });
  };

  /* GraphQL */
  const [updateFavoriteLeads] = useMutation(UPDATE_FAVORITE_LEADS, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Lead favorites updated.',
      });
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error updating your Lead favorites. Please refresh the page and try again.',
      });
    },
    refetchQueries: [{
      query: LIST_LEADS,
      variables: {
        params: {
          listingId: lead.listingId,
          isInvestorMatches: false,
        },
      },
    }],
  });
  const isExpanded = expandedArray.includes(lead?._id);
  const renderNewMessageTag = Boolean(newChannel?.hasUnreadMessages && newChannel?.channelUrl === lead?.channel?.channelUrl);

  React.useEffect(() => {
    /** Not the biggest fan of this, may cause too many re-renders
     * Best solution is probably to refactor the whole table component with grid layout */
    setDynamicRowHeight(document.getElementById(`LeadTableRow-${lead?._id}`)?.offsetHeight);
  }, [document.getElementById(`LeadTableRow-${lead?._id}`)?.offsetHeight, isExpanded, lead?.sellerNotes]);

  /** Render */
  if (nameOnly) {
    return (
      <ScrollTableBodyRow
        // sorry for the readability, added numbers are from the height of the inline alert
        height={isExpanded ? `${(inlineAlertConfigs?.length > 0 ? dynamicRowHeight + 49 : dynamicRowHeight) || 0}px` : inlineAlertConfigs?.length > 0 ? '117px' : '68px'}
        padding="0"
        minHeight="68px"
        backgroundColor={index % 2 === 0 ? Colors.White : Colors.Grey50}
        onClick={() => pushLeadModal(lead._id, LeadModalPageStateEnum.Details)}
      >
        <ScrollTableBodyCell
          minWidth="200px"
          borderRight={`1px solid ${Colors.Grey200}`}
        >
          <Icon
            icon={isFavorite ? Icons.StarSolid : Icons.StarRegular}
            color={isFavorite ? Colors.Yellow500 : Colors.Grey400}
            size={16}
            margin="0 16px 0 8px"
            onClick={(event) => {
              event.stopPropagation();
              toggleFavorite();
            }}
          />
          <UserInfo user={lead.user} includePhone type={UserInfoTypesEnum.Large} />
          {newChannel !== null && renderNewMessageTag && (
          <Tag
            text="New Message"
            textSize={TextTypesEnum.Regular10}
            color="Blurple"
            width="95px"
            hover
            onClick={(event) => {
              event.stopPropagation();
              pushLeadModal(lead._id, LeadModalPageStateEnum.Details);
            }}
            margin="0px 8px 0px 0px"
          />
          )}
        </ScrollTableBodyCell>
      </ScrollTableBodyRow>
    );
  }

  if (expandOnly) {
    return (
      <ScrollTableBodyRow
        height={isExpanded ? `${(inlineAlertConfigs?.length > 0 ? dynamicRowHeight + 49 : dynamicRowHeight) || 0}px` : inlineAlertConfigs?.length > 0 ? '117px' : '68px'}
        padding="0"
        minHeight="68px"
        backgroundColor={index % 2 === 0 ? Colors.White : Colors.Grey50}
        onClick={() => pushLeadModal(lead._id, LeadModalPageStateEnum.Details)}
      >
        <ScrollTableBodyCell
          justify="center"
          borderLeft={`1px solid ${Colors.Grey200}`}
          onClick={(event) => {
            event.stopPropagation();
            if (expandedArray.includes(lead?._id)) {
              contractLead(lead);
            } else {
              expandLead(lead);
            }
          }}
        >
          <Icon
            icon={isExpanded ? Icons.CaretUpSolid : Icons.CaretDownSolid}
            transitionDuration="0.2s"
            color={Colors.Brand700 || Colors.Blurple700}
            size={16}
          />
        </ScrollTableBodyCell>
      </ScrollTableBodyRow>
    );
  }

  return (
    <>
      <ScrollTableBodyRow
        id={`LeadTableRow-${lead?._id}`}
        height={isExpanded ? 'auto' : '68px'}
        backgroundColor={index % 2 === 0 ? Colors.White : Colors.Grey50}
        minHeight="68px"
        onClick={() => pushLeadModal(lead._id, LeadModalPageStateEnum.Details)}
      >
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="160px"
          flex="unset"
        >
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Brand700 || Colors.Blurple700}
            hoverColor={Colors.Brand500 || Colors.Blurple500}
            onClick={(event) => {
              event.stopPropagation();
              pushLeadModal(lead._id, LeadModalPageStateEnum.Status);
            }}
          >
            {lead?.state}
          </Text>
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="100px"
          flex="unset"
        >
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Brand700 || Colors.Blurple700}
            hoverColor={Colors.Brand500 || Colors.Blurple500}
            onClick={(event) => {
              event.stopPropagation();
              pushLeadModal(lead._id, LeadModalPageStateEnum.Guidance);
            }}
          >
            {lead?.guidance ? `$${StringUtil.formatNumber(lead?.guidance)}` : 'Add guidance'}
          </Text>
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="100px"
          flex="unset"
        >
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Brand700 || Colors.Blurple700}
            hoverColor={Colors.Brand500 || Colors.Blurple500}
            onClick={(event) => {
              event.stopPropagation();
              pushLeadModal(lead._id, LeadModalPageStateEnum.Offers);
            }}
          >
            {lead?.offerAmount ? `$${StringUtil.formatNumber(lead?.offerAmount)}` : 'Add offer'}
          </Text>
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="75px"
          flex="unset"
        >
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            {pageViews}
          </Text>
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          flex="unset"
          minWidth="75px"
        >
          <Icon
            icon={signedCA ? Icons.CheckCircleSolid : Icons.TimesCircleSolid}
            color={signedCA ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey300}
            size={14}
          />
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="175px"
          flex="unset"
        >
          {createTime}
        </ScrollTableBodyCell>
        <ScrollTableSpace />
        <ScrollTableBodyCell
          minWidth="250px"
          maxWidth="250px"
          flex="unset"
        >
          {lead?.sellerNotes?.length > 0 ? (
            <NotesContainer expanded={expandedArray.includes(lead?._id)}>
              {lead.sellerNotes.map((note, index) => (
                <>
                  <Text
                    type={TextTypesEnum.Regular14}
                    color={Colors.Brand700 || Colors.Blurple700}
                    hoverColor={Colors.Brand500 || Colors.Blurple500}
                    onClick={(event) => {
                      event.stopPropagation();
                      pushLeadModal(lead._id, LeadModalPageStateEnum.SellerNotes);
                    }}
                  >
                    <Text type={TextTypesEnum.Bold14} as={TextAsEnum.Span} color={Colors.Brand700 || Colors.Blurple700}>
                      {StringUtil.capitalize(
                        TimeUtil.format(
                          note?.meta?.createdAt,
                          'LLLL d \'at\' h:mma',
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                        ).toLowerCase(),
                      )}
                    </Text>
                  &nbsp;
                    {note.text}
                  </Text>
                  {index !== lead.sellerNotes.length - 1 && <CustomSpacer height="12px" />}
                </>
              ))}
            </NotesContainer>
          ) : (
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Brand700 || Colors.Blurple700}
              hoverColor={Colors.Brand500 || Colors.Blurple500}
              onClick={(event) => {
                event.stopPropagation();
                pushLeadModal(lead._id, LeadModalPageStateEnum.SellerNotes);
              }}
            >
              Add Notes
            </Text>
          )}
        </ScrollTableBodyCell>
      </ScrollTableBodyRow>
      {inlineAlertConfigs?.length > 0 && (
        inlineAlertConfigs?.map((alertConfig: InlineLeadTableAlertProps) => (
          <InlineLeadTableAlert
            config={alertConfig}
          />
        ))
      )}
    </>
  );
};

export default LeadsTableRow;
