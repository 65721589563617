import React from 'react';
import styled from '@emotion/styled';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { config } from '@biproxi/env';
import { useRecoilValue } from 'recoil';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Dropdown from '../../../elements/Dropdown';
import Colors from '../../../styles/Colors';
import IDataFrameAddressOverview from '../@types/data-frame-types/interfaces/IDataFrameAddressOverview';
import { TDropdownItems } from '../../DropdownList';
import { dataFrameGeneralAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';

const DataFrameAddressOverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

type StickyAddressOverviewProps ={
  backgroundColor?: string;
}

const StickyAddressOverview = styled.div<StickyAddressOverviewProps>`
    display: flex;
    flex-direction: column;
    top: 0px;
`;

const TaxIdDropdownWrapper = styled.div`
    width: 100%;
`;

const StaticMap = styled.img`
  border-radius: 8px;
`;

interface IMultipleTaxIds {
  tax_assessor_id: number;
}

type DataFrameAddressOverviewProps = {
    addressInfo: IDataFrameAddressOverview;
    taxIds: IMultipleTaxIds[];
    selectedTaxId: number;
    setSelectedTaxId: any;
}

/**
 * This component renders the address info and static google image of the property
 */
const DataFrameAddressOverview: React.FC<DataFrameAddressOverviewProps> = ({
  addressInfo,
  taxIds,
  selectedTaxId,
  setSelectedTaxId,
}) => {
  /* State / Recoil */
  const dataFrameGeneralState = useRecoilValue(dataFrameGeneralAtom);
  const {
    comparePropertiesExpanded,
  } = dataFrameGeneralState;

  const {
    address,
    city,
    state,
    zip,
    latitude,
    longitude,
  } = addressInfo;

  const propertyTaxIds: TDropdownItems<number> = taxIds?.map((taxId: IMultipleTaxIds) => ({
    name: `Tax ID: ${taxId?.tax_assessor_id}`,
    value: taxId.tax_assessor_id,
  }));

  /* Render */
  return (
    <DataFrameAddressOverviewWrapper>
      <StickyAddressOverview backgroundColor={comparePropertiesExpanded ? Colors.Grey100 : Colors.White}>
        <Text
          type={TextTypesEnum.Bold18}
          color={Colors.Grey900}
        >
          {StringUtil.capitalizeMultiWordString(address)}
        </Text>
        <Text
          type={TextTypesEnum.Regular16}
          color={Colors.Grey700}
        >
          {StringUtil.capitalizeMultiWordString(city)}
          ,
          {' '}
          {state}
          {' '}
          {zip}
        </Text>
      </StickyAddressOverview>
      {taxIds?.length > 1 && (
      <TaxIdDropdownWrapper>
        <Dropdown
          width="fill-available"
          items={propertyTaxIds}
          value={`Tax ID: ${selectedTaxId}`}
          onChange={(value: number) => setSelectedTaxId(value)}
        />
      </TaxIdDropdownWrapper>
      )}
      <StaticMap src={`https://maps.googleapis.com/maps/api/streetview?key=${config.NEXT_PUBLIC_GOOGLE_BROWSER_KEY}&source=outdoor&radius=75&location=${latitude},${longitude}&pitch=5&size=350x200`} alt="map" />
    </DataFrameAddressOverviewWrapper>
  );
};

/**
 * In theory, we only care for this component to rerender if it is passed a different tax id
 */
const propsAreEqual = (prevProps, nextProps) => prevProps.selectedTaxId === nextProps.selectedTaxId;

export default React.memo(DataFrameAddressOverview, propsAreEqual);
