import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import * as IFileService from '@biproxi/models/services/IFileService';
import { IVideoGraphQL } from '@biproxi/models/interfaces/IVideo';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import ReactPlayer from 'react-player';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@biproxi/models/externalLinks';
import { useAppDispatch } from '../../redux/store';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Flex from '../../elements/Flex';
import Label from '../../elements/Label';
import CREATE_VIDEO_FILE from '../../graphql/mutations/createVideoFile.mutation';
import ExitButton, { ExitButtonSizesEnum, ExitButtonTypesEnum } from '../../elements/ExitButton';
import LinkText from '../../elements/LinkText';
import { ListingActions } from '../../redux/listing.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';

const Container = styled.div`
  /**
  * Style ReactPlayer via CSS.
  * Kinda janky but our only option.'
  */
  video {
    border-radius: 8px;
    max-height: 500px;
  }
`;

const DropzoneContainer = styled.div`
  height: 256px;
  width: 100%;
  position: relative;
  cursor: pointer;
  border: 2px dashed ${Colors.Grey300};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  max-height: 700px;
`;

const LoadingContainer = styled.div`
  position: relative;
  width: calc(100% - 80px);
`;

const ProgressBar = styled.div`
  position: relative;
  height: 4px;
  width: 100%;
  background-color: ${Colors.Grey200};
`;

const ProgressBarInner = styled.div<{percentComplete: string}>`
  position: absolute;
  height: 4px;
  width: ${(props) => `${props.percentComplete}%`};
  background-color: ${Colors.Brand700 || Colors.Blurple700};
  transition: all 0.2s;
`;

const PROCESSING_SPEED_BYTES_PER_SECOND = 3300000;

type CreateListingVideoProps = {
  listing: IListingGraphQL;
  dataCy?: string;
};

const CreateListingVideo: React.FC<CreateListingVideoProps> = ({
  dataCy,
  listing,
}) => {
  /* State */
  const video: IVideoGraphQL | null = listing?.media?.video ?? null;
  const [fileUploadParams, setFileUploadParams] = React.useState<IFileParams | null>(null);
  const [fileProcessingTimeEstimate, setFileProcessingTimeEstimate] = React.useState<number | null>(null);
  const [timer, setTimer] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [percentComplete, setPercentComplete] = React.useState<number>(0);
  const uploadComplete = percentComplete === 100;
  const [abortUpload, setAbort] = React.useState<any>(null);
  const [timeInterval, setTimeInterval] = React.useState(null);

  /* Actions */
  const resetVideoUpload = () => {
    abortUpload?.()?.();
    setFileUploadParams(null);
    setFileProcessingTimeEstimate(null);
    setTimer(0);
    setLoading(false);
    setPercentComplete(0);
    clearInterval(timeInterval);
    setTimeInterval(null);
    setVideoUploadInProgress(false);
  };

  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));
  const popModal = () => dispatch(AppActions.popModal());
  const setListingMediaVideo = (video: IVideoGraphQL) => dispatch(ListingActions.setListingMediaVideo({ video }));
  const setVideoUploadInProgress = (videoUploadInProgress: boolean) => dispatch(ListingActions.setVideoUploadInProgress({ videoUploadInProgress }));

  const deleteVideo = () => {
    setListingMediaVideo(null);
    popModal();
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Video successfully deleted',
    });
  };

  const pushDeleteVideoModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete video',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => deleteVideo(),
      },
    }),
  );

  type Payload = IFileService.TCreateVideoFilePayload;

  type Data = {
    createVideoFile: IVideoGraphQL;
  }

  /** GraphQL */
  const [createVideoFile] = useMutation<Data, Payload>(CREATE_VIDEO_FILE, {
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (event: ProgressEvent) => {
          const percentComplete = Math.ceil((event.loaded / event.total) * 100);
          setPercentComplete(percentComplete);

          if (percentComplete === 100) {
            clearInterval(timeInterval);

            const newTimeInterval = setInterval(() => {
              setTimer((timer) => timer + 1);
            }, 1000);

            setTimeInterval(newTimeInterval);
          }
        },
        onAbortPossible: (abort) => {
          /**
           * I have no idea what's going on here.
           *
           * For an unknown reason, the abort
           * function is being called as soon as the wrapping
           * function is called.
           */
          setAbort(() => abort);
        },
      },
    },
    onCompleted: async (data) => {
      resetVideoUpload();
      setListingMediaVideo(data.createVideoFile);
    },
    onError: () => {
      resetVideoUpload();
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error uploading your video. Please try again or contact support.',
      });
    },
  });

  /* Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (loading) return;

    const [browserFile] = browserFiles;

    const params: IFileParams = {
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type,
      encoding: 'utf8',
      upload: browserFile,
    };

    setFileUploadParams(params);
    setFileProcessingTimeEstimate(Math.ceil(browserFile.size / PROCESSING_SPEED_BYTES_PER_SECOND));
    setLoading(true);
    setVideoUploadInProgress(true);

    createVideoFile({
      variables: {
        params,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'video/mp4, video/quicktime',
  });

  /* Effect */
  React.useEffect(() => () => {
    resetVideoUpload();
  }, []);

  /* Render */
  return (
    <Container>
      <Flex justify="space-between">
        <Label
          text="Video"
        />
      </Flex>
      {(() => {
        if (loading) {
          return (
            <DropzoneContainer>
              <LoadingContainer>
                <Flex align="center" justify="space-between">
                  {(() => {
                    if (uploadComplete) {
                      const timeRemaining = fileProcessingTimeEstimate - timer;
                      return (
                        <Flex align="center">
                          <Text type={TextTypesEnum.Bold16} color={Colors.Grey900} margin="0 8px 0 0">
                            Processing (Est.
                            {' '}
                            {timeRemaining < 0 ? '0s' : TimeUtil.formattedCountDown(timer * 1000, fileProcessingTimeEstimate * 1000)}
                            )
                          </Text>
                        </Flex>
                      );
                    }

                    return (
                      <Text type={TextTypesEnum.Bold16} color={Colors.Grey900}>
                        Uploading&nbsp;
                        <Text type={TextTypesEnum.Regular16} as={TextAsEnum.Span}>
                          (
                          {percentComplete.toString()}
                          %)
                        </Text>
                      </Text>
                    );
                  })()}
                  <ExitButton
                    onClick={() => resetVideoUpload()}
                    type={ExitButtonTypesEnum.Dark}
                    size={ExitButtonSizesEnum.Small}
                    position="relative"
                    top="0"
                    right="0"
                  />
                </Flex>

                <Text type={TextTypesEnum.Medium12} color={Colors.Grey700} margin="0 0 8px">
                  {fileUploadParams?.name ?? 'File name unknown'}
                </Text>
                <ProgressBar>
                  <ProgressBarInner
                    key={uploadComplete ? 1 : 0}
                    percentComplete={(() => {
                      if (uploadComplete) {
                        const percentComplete = (timer / fileProcessingTimeEstimate) * 100;
                        return (percentComplete > 100 ? 100 : percentComplete).toString();
                      }

                      return percentComplete.toString();
                    })()}
                  />
                </ProgressBar>
              </LoadingContainer>
            </DropzoneContainer>
          );
        }

        if (video?.file) {
          return (
            <VideoContainer>
              <ReactPlayer
                url={video?.file?.url}
                width="100%"
                height="100%"
                controls
              />
              <Flex margin="4px 0 0">
                <LinkText
                  onClick={() => pushDeleteVideoModal()}
                  type={TextTypesEnum.Medium12}
                  margin="0 8px 0 0"
                >
                  Remove Video
                </LinkText>
              </Flex>
            </VideoContainer>
          );
        }

        return (
          <DropzoneContainer {...getRootProps({
            'data-cy': dataCy,
          })}
          >
            <input {...getInputProps()} />
            <Icon
              icon={Icons.VideoLight}
              color={Colors.Grey700}
              size={36}
              margin="0 0 8px 0"
            />
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey700}
              align="center"
            >
              Drag a video file here or&nbsp;
              <Text
                type={TextTypesEnum.Medium12}
                color={Colors.Brand700 || Colors.Blurple700}
                as={TextAsEnum.Span}
              >
                click to upload
              </Text>
            </Text>
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey500}
              align="center"
              margin="8px 0 0"
            >
              1 GB max file size
            </Text>
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey500}
              align="center"
              margin="4px 0 0"
            >
              .mp4 and .mov files only
            </Text>
          </DropzoneContainer>
        );
      })()}
      <Text type={TextTypesEnum.Regular12} color={Colors.Grey700} margin="4px 0 0">
        By uploading a video, you agree that you are the legal owner of this video and are in compliance with our
        &nbsp;
        <Text
          type={TextTypesEnum.Regular12}
          as={TextAsEnum.Span}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
        >
          Privacy Policy
        </Text>
        &nbsp;
        and
        &nbsp;
        <Text
          type={TextTypesEnum.Regular12}
          as={TextAsEnum.Span}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => window.open(TERMS_OF_SERVICE_URL, '_blank')}
        >
          Terms & Conditions
        </Text>
      </Text>
    </Container>
  );
};

export default CreateListingVideo;
