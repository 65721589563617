import UserUtil from '../utils/UserUtil';
import IUser from '../interfaces/IUser';
import { IScoreRubricSection } from '../interfaces/IScore';

const ProfileScoreRubric: IScoreRubricSection<IUser>[] = [
  {
    name: 'Basic Information',
    items: [
      {
        name: 'First & Last Name',
        required: true,
        points: 20,
        complete: (user: IUser) => Boolean(user?.firstName && user?.lastName),
      },
      {
        name: 'Role',
        required: true,
        points: 20,
        complete: (user: IUser) => Boolean(user?.role),
      },
      {
        name: 'Location',
        required: false,
        points: 15,
        complete: (user: IUser) => Boolean(user?.address?.city),
      },
      {
        name: 'Workplace',
        required: false,
        points: 15,
        complete: (user: IUser) => Boolean(user?.organizationName),
      },
      {
        name: 'Bio',
        required: false,
        points: 15,
        complete: (user: IUser) => Boolean(user?.bio),
      },
    ],
  },
  {
    name: 'Investor Preferences',
    items: [
      {
        name: 'Answered all questions',
        required: false,
        points: 15,
        complete: (user: IUser) => UserUtil.hasInvestorProfileFields(user, true),
      },
    ],
  },
];

export default ProfileScoreRubric;
