import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import ChatModal from './modal/ChatModal';
import { useAppSelector, AppState, useAppDispatch } from '../redux/store';
import { ChatActions } from '../redux/chat.redux';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import Icon, { Icons } from '../elements/Icon';
import Colors from '../styles/Colors';
import { CardPop } from '../elements/Motion';
import NextUtil from '../utils/NextUtil';
import { media, useMobileMedia } from '../utils/MediaQuery';

const Container = styled.div`
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 200;
  display: flex;
  pointer-events: none;
  transition: all 0.2s;
  box-sizing: border-box;

  ${media.mobile} {
    z-index: 5000;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
  }
`;

const HubspotIconAvoidanceSpacer = styled.div`
  background: transparent;
  height: 64px;
  width: 64px;
  margin-top: 16px;
`;

const MinimizedChatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ChatModalsContainer = styled.div`
  display: flex;
  box-sizing: border-box;

  ${media.mobile} {
    width: 100%;
    height: 100%;
  }
`;

const ModalContainer = styled.div`
  pointer-events: auto;
  margin: 0px 12px 0px 0px;
  box-sizing: border-box;

  ${media.mobile} {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const MinimizedChatContainer = styled(CardPop)`
  position: relative;
  cursor: pointer;
  pointer-events: auto;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImageHoverOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  transition: all 0.2s;

  &:hover {
    background: ${Colors.Black20};
  }
`;

const HideMinimizedChatButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${Colors.White};
  width: 16px;
  height: 16px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

type ChatOverlayLayoutProps = {}

const ChatOverlayLayout: React.FC<ChatOverlayLayoutProps> = () => {
  /** Actions */
  const dispatch = useAppDispatch();
  const { activeChatChannels, minimizedChatChannels, channelsOpenedUrls } = useAppSelector((state: AppState) => state.chat);
  const pushActiveChatChannel = (channel) => dispatch(
    ChatActions.pushActiveChatChannels({ channel }),
  );
  const popMinimizedChatChannel = (channel) => dispatch(
    ChatActions.popMinimizedChatChannels({ channelUrl: channel.channelUrl }),
  );
  const pushChannelOpenedUrl = (channelUrl) => dispatch(
    ChatActions.pushChannelsOpenedUrls({ channelUrl }),
  );

  /** Hooks */
  const isMobile = useMobileMedia();

  /* Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  // Issue with blue dot sometimes... Need to fix. Need to reArchitect chat tbh.
  // deviated a lot from the original designs
  const shouldShowNewNotification = (channel: IChannelGraphQL): boolean => (channel.hasUnreadMessages)
      && (!activeChatChannels?.find((ch) => ch?.channelUrl === channel?.channelUrl))
      && (!channelsOpenedUrls?.includes(channel?.channelUrl));
  // && unreadMessageCount > 0

  /** Render */
  return (
    <Container>
      <ChatModalsContainer>
        {activeChatChannels?.map((channel) => (
          <ModalContainer
            key={`${channel.channelUrl}-active`}
          >
            <ChatModal channel={channel} />
          </ModalContainer>
        ))}
      </ChatModalsContainer>
      {!isMobile && (
        <MinimizedChatsContainer>
          {minimizedChatChannels?.map((channel: IChannelGraphQL) => {
            const isNewNotification = shouldShowNewNotification(channel);
            return (
              <MinimizedChatContainer
                data-tip={`${UserUtil.fullName(channel.user)}<br /> ${ListingUtil.name(channel.listing, { allButAddress1: true })}`}
                data-place="left"
                data-multiline
                key={`${channel.channelUrl}-minimized`}
              >
                <HideMinimizedChatButton>
                  <Icon
                    icon={Icons.TimesCircleSolid}
                    color={Colors.Red500}
                    hoverColor={Colors.Red700}
                    size={18}
                    onClick={() => popMinimizedChatChannel(channel)}
                  />
                </HideMinimizedChatButton>
                <ProfileImage
                  type={isNewNotification ? ProfileImageTypes.ExtraLarge : ProfileImageTypes.Large}
                  showBorder={isNewNotification}
                  user={channel?.user}
                  margin="0px"
                />
                <ProfileImageHoverOverlay onClick={() => {
                  if (!channelsOpenedUrls.includes(channel.channelUrl)) {
                    pushChannelOpenedUrl(channel.channelUrl);
                  }
                  pushActiveChatChannel(channel);
                }}
                />
              </MinimizedChatContainer>
            );
          })}
          <HubspotIconAvoidanceSpacer />
        </MinimizedChatsContainer>
      )}
    </Container>
  );
};

export default ChatOverlayLayout;
