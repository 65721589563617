import React from 'react';
import styled from '@emotion/styled';
import Radio from './Radio';
import Text, { TextTypesEnum } from './Text';
import Colors from '../styles/Colors';
import ISelectionBoxConfig from '../models/interfaces/ISelectionBoxConfig';
import Flex from './Flex';

type ContainerProps = {
  margin?: string;
}

const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

type RadioListProps = {
  margin?: string;
  dataCy?: string;
  flexDirection?: string;
  rowGap?: string;
  columnGap?: string;
  title: string;
  titleTextType?: TextTypesEnum;
  config: ISelectionBoxConfig[];
}

const RadioList: React.FC<RadioListProps> = ({
  config,
  dataCy,
  title,
  titleTextType = TextTypesEnum.Bold18,
  margin,
  rowGap,
  columnGap,
  flexDirection = 'column',
}) => (
  <Container margin={margin}>
    <Text type={titleTextType} color={Colors.Grey900}>
      {title}
    </Text>
    <Flex direction={flexDirection}>
      {config.map((config, index) => (
        <Radio
          dataCy={dataCy}
          key={index}
          active={config.active}
          label={config.label}
          labelTextType={config.labelTextType}
          onClick={config.onClick}
          margin={flexDirection === 'row' ? columnGap : rowGap}
        />
      ))}
    </Flex>
  </Container>
);
export default RadioList;
