import axios from 'axios';
import { config } from '@biproxi/env';

const queryBillingInvoices = async (userId: string) => {
  const res = await axios(`/api/billing/invoices?id=${userId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const invoices = await res.data;
  return invoices;
};

export default queryBillingInvoices;
