/* eslint-disable dot-notation */
import { logger } from '@biproxi/logger';
/* eslint-disable camelcase */
import React from 'react';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { useQuery } from '@apollo/client';
import IPagination from '@biproxi/models/interfaces/IPagination';
import ISimilarPropertyParams from '@biproxi/models/interfaces/ISimilarPropertyParams';
import { IDataExplorerFilters } from '@biproxi/models/interfaces/IDataExplorerFilters';
import GeoUtil from '@biproxi/models/utils/GeoUtil';
import { useRecoilCallback, useRecoilState } from 'recoil';
import CherreDictionaryUtil from '@biproxi/models/utils/CherreDictionaryUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Flex from '../../../elements/Flex';
import Icon from '../../../elements/Icon';
import Checkbox from '../../../elements/Checkbox';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../../elements/Button';
import SIMILAR_PROPERTIES from '../../../graphql/queries/dataExplorerSimilarProperties.query';
import Loader from '../../../elements/Loader';
import { useMobileMedia } from '../../../utils/MediaQuery';
import { AppState, useAppSelector } from '../../../redux/store';
import NoContent from '../../NoContent';
import Icons from '../../../elements/Icons';
import { dataFrameComparePropertiesAtom, dataFrameGeneralAtom, dataFrameSimilarPropertiesAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import { dataExplorerGlobalAtom } from '../state-management/recoil/atoms/DataExplorerGlobalContext';
import ScrollTable, {
  ScrollTableBody, ScrollTableBodyCell, ScrollTableBodyRow, ScrollTableHeader, ScrollTableHeaderCell,
} from '../../ScrollTable';

const SimilarPropertiesWrapper = styled.div`
  /* margin-top: 8px; */
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const InfiniteLoadingContainer = styled.div`
  padding: 100px 0;
  height: 24px;
`;

const RadiusButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 16px;
  height: 100%;


  scrollbar-width: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical:hover {
    background-color: rgba(0, 0, 0, .5);
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;

const InfiniteScrollLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: 24px;
`;

const MobileButtonWrapper = styled.div`
  width: 100%;
  padding: 8px 0;
  background-color: ${Colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

enum SimilarPropertiesRadiusValuesEnum {
    TenMiles = '10 mi',
    TwentyFiveMiles = '25 mi',
    FiftyMiles = '50 mi',
    OneHundredMiles = '100 mi',
    Anywhere = 'Anywhere',
}

const SimilarPropertiesRadiusMap = {
  [SimilarPropertiesRadiusValuesEnum.TenMiles]: 10,
  [SimilarPropertiesRadiusValuesEnum.TwentyFiveMiles]: 25,
  [SimilarPropertiesRadiusValuesEnum.FiftyMiles]: 50,
  [SimilarPropertiesRadiusValuesEnum.OneHundredMiles]: 100,
  [SimilarPropertiesRadiusValuesEnum.Anywhere]: 1000,
};

type DataFrameSimilarPropertiesProps = {
    similarPropertyParams: Partial<ISimilarPropertyParams>;
};

const DataFrameSimilarProperties: React.FC<DataFrameSimilarPropertiesProps> = ({
  similarPropertyParams,
}) => {
  /* State / Recoil */
  const [activeRadiusButton, setActiveRadiusButton] = React.useState<SimilarPropertiesRadiusValuesEnum>(SimilarPropertiesRadiusValuesEnum.TenMiles);
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);
  const reduxFilters = useAppSelector((state: AppState) => state.app.dataExplorerFilters); // user inputted search filters from the address search bar filters modal

  const [dataFrameGeneralState, setDataFrameGeneralState] = useRecoilState(dataFrameGeneralAtom);
  const [dataExplorerGlobalState, setDataExplorerGlobalState] = useRecoilState(dataExplorerGlobalAtom);
  const [dataFrameSimilarPropertiesState, setDataFrameSimilarPropertiesState] = useRecoilState(dataFrameSimilarPropertiesAtom);
  const {
    selectedComparedPropertyCherreIds: selectedProperties,
  } = dataFrameSimilarPropertiesState;

  const {
    comparePropertiesExpanded,
    similarPropertyDataExpanded,
    similarPropertiesLoading,
  } = dataFrameGeneralState;

  const {
    mapVisible,
  } = dataExplorerGlobalState;

  /** Hooks */
  const loaderRef = React.useRef(null);
  const { ref: inViewRef, inView } = useInView({
    // threshold: 1.0,
    rootMargin: '20px',
  });

  const isMobile = useMobileMedia();

  const {
    zip,
  } = similarPropertyParams;

  const generateSimilarPropertyParams = ({
    defaultParams,
  } : {
    defaultParams: Partial<ISimilarPropertyParams>,
    userSearchParams: Partial<IDataExplorerFilters>,
  }): Partial<ISimilarPropertyParams> => {
    const {
      zip,
      state,
      city,
      property_use_standardized_code,
      property_group_type,
    } = defaultParams;

    const lotSizeSqft = defaultParams?.lotSizeSqft ?? 0; // Can't destruct this one since there are chances that it is a null value. Thanks Cherre...

    // const {
    //   selectedFilters,
    // } = userSearchParams;

    const radius = SimilarPropertiesRadiusMap[activeRadiusButton];
    const radiusInMeters = radius * 1609; // Radius value is in miles but we need to convert it to meters for the `displaceCardinalCoordinatePoints` algorithm
    const latitude = defaultParams?.latitude ?? null;
    const longitude = defaultParams?.longitude ?? null;
    const displacedObject = GeoUtil.displaceCardinalCoordinatePoints({ lat: latitude, lng: longitude }, radiusInMeters);
    const similarPropertyParams: Partial<ISimilarPropertyParams> = {
      zip,
      state,
      city,
      latitude,
      longitude,
      property_use_standardized_code,
      property_group_type,
      lotSizeSqft,
      displacedGeoCoords: {
        lat1: displacedObject['South'][0],
        lat2: displacedObject['North'][0],
        lng1: displacedObject['West'][1],
        lng2: displacedObject['East'][1],
      },
    };
    return similarPropertyParams;
  };

  const memoizedSimilarPropertyParams: Partial<ISimilarPropertyParams> = React.useMemo(() => generateSimilarPropertyParams({
    defaultParams: similarPropertyParams,
    userSearchParams: reduxFilters,
  }), [activeRadiusButton]);

  const handleSelectProperty = useRecoilCallback(({ set }) => (cherre_parcel_id: string) => {
    // const flattenedSelectedProperties: string[] = selectedProperties.flat();
    if (selectedProperties.includes(cherre_parcel_id)) {
      const filteredSelectedProperties: string[] = selectedProperties.filter((propertyId: string) => propertyId !== cherre_parcel_id);
      set(dataFrameSimilarPropertiesAtom, (prevState) => ({
        ...prevState,
        selectedComparedPropertyCherreIds: filteredSelectedProperties,
      }));
    } else {
      const newSelectedProperties: string[] = [...selectedProperties, cherre_parcel_id];
      set(dataFrameSimilarPropertiesAtom, (prevState) => ({
        ...prevState,
        selectedComparedPropertyCherreIds: newSelectedProperties,
      }));
      // setSelectedProperties(newSelectedProperties.reduce((rows, key, index) => (index % 4 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []));
    }
  }, [selectedProperties]);

    /* GraphQL */
    type SimilarPropertyVars = {
        params: {
            propertyParams: any;
            pagination: IPagination
        };
    };

    type SimilarPropertyData = {
        dataExplorerSimilarProperties: {
            properties: any // fix with real typing
        }
    };

    const {
      data: similarPropertyData, fetchMore, refetch,
    } = useQuery<SimilarPropertyData, SimilarPropertyVars>(SIMILAR_PROPERTIES, {
      skip: !zip,
      notifyOnNetworkStatusChange: true,
      variables: {
        params: {
          propertyParams: memoizedSimilarPropertyParams,
          pagination: {
            limit: !similarPropertyDataExpanded ? 3 : 12,
            offset: 0,
          },
        },
      },
      onError: (error) => {
        logger.error('Whoopsie, there was an issue when querying for similar properties: ', error);
      },
      onCompleted: () => {
        setDataFrameGeneralState((prevState) => ({
          ...prevState,
          similarPropertiesLoading: false,
        }));
      },
    });

    /** Functions */
    const handleCompareProperties = useRecoilCallback(
      ({ set }) => () => {
        set(dataFrameGeneralAtom, (prevState) => ({
          ...prevState,
          comparePropertiesExpanded: true,
          insightDataExpanded: true,
        }));
        set(dataFrameComparePropertiesAtom, (prevState) => ({
          ...prevState,
          comparePropertiesLoading: true,
        }));
      },
    );

    const fetchMoreProperties = async (offset: number) => {
      await fetchMore({
        variables: {
          params: {
            propertyParams: memoizedSimilarPropertyParams,
            pagination: {
              limit: !similarPropertyDataExpanded ? 3 : 12,
              offset,
            },
          },
        },
      });
    };

    /* Effects */
    React.useEffect(() => {
      const getMoreData = async () => {
        if (inView && fetchMore && similarPropertyData?.dataExplorerSimilarProperties?.properties?.length) {
          setInfiniteLoading(true);
          await fetchMoreProperties(similarPropertyData?.dataExplorerSimilarProperties?.properties?.length);
          setInfiniteLoading(false);
        }
      };
      getMoreData();
    }, [inView]);

    React.useEffect(() => {
      const refetchPropertyData = async () => {
        await refetch({
          params: {
            propertyParams: memoizedSimilarPropertyParams,
            pagination: {
              limit: mapVisible ? 3 : 12,
              offset: 0,
            },
          },
        });
      };
      if (similarPropertyData?.dataExplorerSimilarProperties?.properties?.length) {
        refetchPropertyData();
      }
    }, [similarPropertyParams]);

    React.useEffect(() => {
      const refetchPropertyData = async () => {
        setDataFrameGeneralState((prevState) => ({
          ...prevState,
          similarPropertiesLoading: true,
        }));
        await refetch({
          params: {
            propertyParams: memoizedSimilarPropertyParams,
            pagination: {
              limit: mapVisible ? 3 : 12,
              offset: 0,
            },
          },
        });
      };
      refetchPropertyData();
    }, [activeRadiusButton]);

    /**
     * Since the component is memoized, it won't be rerendered when the data frame exits full screen mode
     * Therefore, we need to set a useEffect that checks for this so that we don't get a bunch of similar properties in non-fullscreen mode
     */
    React.useEffect(() => {
      const refetchPropertyData = async () => {
        setDataFrameGeneralState((prevState) => ({
          ...prevState,
          similarPropertiesLoading: true,
        }));
        await refetch({
          params: {
            propertyParams: memoizedSimilarPropertyParams,
            pagination: {
              limit: 3,
              offset: 0,
            },
          },
        });
      };
      if (!similarPropertyDataExpanded) {
        refetchPropertyData();
      }
    }, [similarPropertyDataExpanded]);

    if (similarPropertiesLoading) {
      return (
        <SimilarPropertiesWrapper
          data-cy="similar-property-loading-wrapper"
        >

          <RadiusButtonsWrapper>
            {Object.keys(SimilarPropertiesRadiusValuesEnum).map((radiusKey, index) => (
              <Button
                key={index}
                text={SimilarPropertiesRadiusValuesEnum[radiusKey]}
                size={ButtonSizesEnum.Small}
                type={SimilarPropertiesRadiusValuesEnum[radiusKey] === activeRadiusButton ? ButtonTypesEnum.Primary : ButtonTypesEnum.BlurpleGhost}
                onClick={() => setActiveRadiusButton(SimilarPropertiesRadiusValuesEnum[radiusKey])}
              />
            ))}
          </RadiusButtonsWrapper>
          <LoaderContainer
            data-cy="similar-property-loading"
          >
            <Loader
              color={Colors.Brand700 || Colors.Blurple700}
            />
          </LoaderContainer>
        </SimilarPropertiesWrapper>
      );
    }

    if (!similarPropertiesLoading && !similarPropertyData?.dataExplorerSimilarProperties?.properties?.length) {
      return (
        <SimilarPropertiesWrapper data-cy="data-frame-similar-properties">
          <PropertiesWrapper>
            <NoContent
              height="fit-content"
              icon={Icons.ExclamationTriangleSolid}
              text="There are no similar properties available for this property"
              iconSize="16"
              iconBorderBackground={Colors.Grey200}
              iconBorderRadius="100px"
              iconBorderWidth="32px"
              iconBorderHeight="32px"
              iconMargin="0 10px 0 0"
              flexDirection="row"
            />
          </PropertiesWrapper>
        </SimilarPropertiesWrapper>
      );
    }

    if (!similarPropertiesLoading && !comparePropertiesExpanded && isMobile) {
      const propertyData: any = similarPropertyData?.dataExplorerSimilarProperties?.properties?.filter((property) => property?.cherre_parcel_id !== null || property?.city !== null);
      const MobileWrapper = styled.div`
          position: absolute;
          height: 100vh;
      `;
      // sometimes, we get repeated properties, so filter out any properties with duplicate addresses using a Set in a closure
      const
        data = propertyData;
      const slicedDataKeys = ['address'];
      const filteredData = data.filter(
        ((set) => (object) => ((key) => !set.has(key) && set.add(key))(slicedDataKeys.map((key) => object[key]).join('|'))
        )(new Set()),
      );

      return (
        <MobileWrapper>
          <SimilarPropertiesWrapper
            data-cy="data-frame-similar-properties"
          >
            <ScrollTable>
              <ScrollTableHeader>
                <ScrollTableHeaderCell flex="2.5">
                  ADDRESS
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  CITY
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  STATE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  ZIP
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  PROPERTY TYPE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  SUBTYPE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  SIZE
                </ScrollTableHeaderCell>
              </ScrollTableHeader>
              {
              filteredData?.map((similarProperty, index) => {
                const isCheckboxDisabled = (): boolean => {
                  let flag = false;
                  if (selectedProperties?.length >= 5) {
                    flag = true;
                  }
                  if (selectedProperties?.includes(similarProperty.cherre_parcel_id)) {
                    flag = false;
                  }
                  return flag;
                };
                return (
                  <ScrollTableBody>
                    <ScrollTableBodyRow
                      key={index}
                      backgroundColor={index % 2 === 0 ? Colors.White : Colors.Grey50}
                    >
                      <ScrollTableBodyCell flex="2.5">
                        <Checkbox
                          data-cy={`similar-property-checkbox-${index}`}
                          active={selectedProperties.includes(similarProperty.cherre_parcel_id)}
                          onClick={() => {
                            handleSelectProperty(similarProperty.cherre_parcel_id);
                          }}
                          disabled={isCheckboxDisabled()}
                        />
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {similarProperty.address}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {similarProperty.city}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {similarProperty.state}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {similarProperty.zip}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {similarProperty.property_group_type}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {CherreDictionaryUtil.lookup('property_use_standardized_code', similarProperty.property_use_standardized_code)}
                        </Text>
                      </ScrollTableBodyCell>
                      <ScrollTableBodyCell flex="1.33">
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={Colors.Grey900}
                        >
                          {StringUtil.formatNumber(similarProperty.lot_size_sq_ft)}
                          {' '}
                          sqft
                        </Text>
                      </ScrollTableBodyCell>
                    </ScrollTableBodyRow>
                  </ScrollTableBody>
                );
              })

              }
            </ScrollTable>
            <RadiusButtonsWrapper
              data-cy="similar-properties-radius-buttons"
            >
              <ButtonWrapper>
                <Flex direction="row" align="right" justify="space-between">
                  {
                isMobile ? (
                  <MobileButtonWrapper>
                    <Button
                      text="Compare properties"
                      data-cy="compare-properties-button"
                      size={ButtonSizesEnum.Small}
                      isFullWidth={false}
                      disabled={!selectedProperties?.length}
                      onClick={() => handleCompareProperties()}
                    />
                  </MobileButtonWrapper>
                ) : (
                  <ButtonWrapper>
                    <Button
                      text="Compare properties"
                      data-cy="compare-properties-button"
                      size={ButtonSizesEnum.Small}
                      isFullWidth={false}
                      disabled={!selectedProperties?.length}
                      onClick={() => handleCompareProperties()}
                    />
                  </ButtonWrapper>
                )
              }
                  {/* <Icon
                  data-cy="download-table"
                  icon={Icons.faInbox}
                  color={Colors.Brand700 || Colors.Blurple700}
                  size="16px"
                  onClick={() => {
                    setDataFrameGeneralState((prevState) => ({
                      ...prevState,
                      dataFrameVisible: false,
                      similarPropertyDataExpanded: false,
                    }));
                    setDataExplorerGlobalState((prevState) => ({
                      ...prevState,
                      mapVisible: true,
                    }));
                  }}
                /> */}
                  <Icon
                    data-cy="close-data-frame"
                    icon={Icons.TimesRegular}
                    color={Colors.Brand700 || Colors.Blurple700}
                    size="150%"
                    onClick={() => {
                      setDataFrameGeneralState((prevState) => ({
                        ...prevState,
                        dataFrameVisible: false,
                        similarPropertyDataExpanded: false,
                        selectedProperties: [],
                      }));
                      setDataExplorerGlobalState((prevState) => ({
                        ...prevState,
                        mapVisible: true,
                      }));
                      setDataFrameSimilarPropertiesState((prevState) => ({
                        ...prevState,
                        selectedComparedPropertyCherreIds: [],
                      }));
                    }}
                  />
                </Flex>
              </ButtonWrapper>
            </RadiusButtonsWrapper>
            <PropertiesWrapper
              data-cy="similar-properties-wrapper"
            >
              {
              similarPropertyDataExpanded && (
                <InfiniteLoadingContainer ref={inViewRef} id="LoadMoreId">
                  {infiniteLoading && (
                    <InfiniteScrollLoaderContainer ref={loaderRef}>
                      <Loader color={Colors.Brand800 || Colors.Blurple800} />
                    </InfiniteScrollLoaderContainer>
                  )}
                </InfiniteLoadingContainer>
              )
            }
            </PropertiesWrapper>
          </SimilarPropertiesWrapper>
        </MobileWrapper>
      );
    }

    if (!similarPropertiesLoading && !comparePropertiesExpanded) {
      const propertyData: any = similarPropertyData?.dataExplorerSimilarProperties?.properties?.filter((property) => property?.cherre_parcel_id !== null || property?.city !== null);

      // sometimes, we get repeated properties, so filter out any properties with duplicate addresses using a Set in a closure
      const
        data = propertyData;
      const slicedDataKeys = ['address'];
      const filteredData = data.filter(
        ((set) => (object) => ((key) => !set.has(key) && set.add(key))(slicedDataKeys.map((key) => object[key]).join('|'))
        )(new Set()),
      );

      return (
        <SimilarPropertiesWrapper
          data-cy="data-frame-similar-properties"
        >
          <RadiusButtonsWrapper
            data-cy="similar-properties-radius-buttons"
          >
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.Grey900}
            >
              {filteredData.length}
              {' '}
              {filteredData.length === 1 ? 'similar property' : 'similar properties'}
            </Text>
            {Object.keys(SimilarPropertiesRadiusValuesEnum).map((radiusKey, index) => (
              <Button
                key={index}
                data-cy={`radius-${SimilarPropertiesRadiusMap[SimilarPropertiesRadiusValuesEnum[radiusKey]]}`}
                text={SimilarPropertiesRadiusValuesEnum[radiusKey]}
                size={ButtonSizesEnum.Small}
                type={SimilarPropertiesRadiusValuesEnum[radiusKey] === activeRadiusButton ? ButtonTypesEnum.Primary : ButtonTypesEnum.BlurpleGhost}
                onClick={() => setActiveRadiusButton(SimilarPropertiesRadiusValuesEnum[radiusKey])}
              />
            ))}
            <ButtonWrapper>
              <Flex direction="row" align="right" justify="space-between">
                {
                  isMobile ? (
                    <MobileButtonWrapper>
                      <Button
                        text="Compare properties"
                        data-cy="compare-properties-button"
                        size={ButtonSizesEnum.Small}
                        isFullWidth={false}
                        disabled={!selectedProperties?.length}
                        onClick={() => handleCompareProperties()}
                      />
                    </MobileButtonWrapper>
                  ) : (
                    <ButtonWrapper>
                      <Button
                        text="Compare properties"
                        data-cy="compare-properties-button"
                        size={ButtonSizesEnum.Small}
                        isFullWidth={false}
                        disabled={!selectedProperties?.length}
                        onClick={() => handleCompareProperties()}
                      />
                    </ButtonWrapper>
                  )
              }
                {/* <Icon
                  data-cy="download-table"
                  icon={Icons.faInbox}
                  color={Colors.Brand700 || Colors.Blurple700}
                  size="16px"
                  onClick={() => {
                    setDataFrameGeneralState((prevState) => ({
                      ...prevState,
                      dataFrameVisible: false,
                      similarPropertyDataExpanded: false,
                    }));
                    setDataExplorerGlobalState((prevState) => ({
                      ...prevState,
                      mapVisible: true,
                    }));
                  }}
                /> */}
                <Icon
                  data-cy="close-data-frame"
                  icon={Icons.TimesRegular}
                  color={Colors.Brand700 || Colors.Blurple700}
                  size="150%"
                  onClick={() => {
                    setDataFrameGeneralState((prevState) => ({
                      ...prevState,
                      dataFrameVisible: false,
                      similarPropertyDataExpanded: false,
                      selectedProperties: [],
                    }));
                    setDataExplorerGlobalState((prevState) => ({
                      ...prevState,
                      mapVisible: true,
                    }));
                    setDataFrameSimilarPropertiesState((prevState) => ({
                      ...prevState,
                      selectedComparedPropertyCherreIds: [],
                    }));
                  }}
                />
              </Flex>
            </ButtonWrapper>
          </RadiusButtonsWrapper>
          <PropertiesWrapper
            data-cy="data-frame-similar-properties-container"
          >
            <ScrollTable>
              <ScrollTableHeader>
                <ScrollTableHeaderCell flex="2.5">
                  ADDRESS
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  CITY
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  STATE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  ZIP
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  PROPERTY TYPE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  SUBTYPE
                </ScrollTableHeaderCell>
                <ScrollTableHeaderCell flex="1.33">
                  SIZE
                </ScrollTableHeaderCell>
              </ScrollTableHeader>
              {
                filteredData?.map((similarProperty, index) => {
                  const isCheckboxDisabled = (): boolean => {
                    let flag = false;
                    if (selectedProperties?.length >= 5) {
                      flag = true;
                    }
                    if (selectedProperties?.includes(similarProperty.cherre_parcel_id)) {
                      flag = false;
                    }
                    return flag;
                  };
                  return (
                    <ScrollTableBody
                      data-cy="similar-properties-wrapper"
                    >
                      <ScrollTableBodyRow
                        key={index}
                        backgroundColor={index % 2 === 0 ? Colors.White : Colors.Grey50}
                      >
                        <ScrollTableBodyCell flex="2.5">
                          <Checkbox
                            data-cy={`similar-property-checkbox-${index}`}
                            active={selectedProperties.includes(similarProperty.cherre_parcel_id)}
                            onClick={() => {
                              handleSelectProperty(similarProperty.cherre_parcel_id);
                            }}
                            disabled={isCheckboxDisabled()}
                          />
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {similarProperty.address}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {similarProperty.city}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {similarProperty.state}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {similarProperty.zip}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {similarProperty.property_group_type}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {CherreDictionaryUtil.lookup('property_use_standardized_code', similarProperty.property_use_standardized_code)}
                          </Text>
                        </ScrollTableBodyCell>
                        <ScrollTableBodyCell flex="1.33">
                          <Text
                            type={TextTypesEnum.Regular14}
                            color={Colors.Grey900}
                          >
                            {StringUtil.formatNumber(similarProperty.lot_size_sq_ft)}
                            {' '}
                            sqft
                          </Text>
                        </ScrollTableBodyCell>
                      </ScrollTableBodyRow>
                    </ScrollTableBody>
                  );
                })

              }
            </ScrollTable>
            {
              similarPropertyDataExpanded && (
                <InfiniteLoadingContainer
                  ref={inViewRef}
                  id="LoadMoreId"
                  data-cy="similar-properties-infinite-loading-container"
                >
                  {infiniteLoading && (
                    <InfiniteScrollLoaderContainer ref={loaderRef}>
                      <Loader color={Colors.Brand800 || Colors.Blurple800} />
                    </InfiniteScrollLoaderContainer>
                  )}
                </InfiniteLoadingContainer>
              )
            }
          </PropertiesWrapper>
        </SimilarPropertiesWrapper>
      );
    }

    return null;
};

const areDeepPropsEqual = (prevProps, nextProps) => (prevProps?.latitude === nextProps?.latitude && prevProps?.longtitude === nextProps?.longtitude);

export default React.memo(DataFrameSimilarProperties, areDeepPropsEqual);
