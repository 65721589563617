import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { KB_CREATE_LISTING_CONTACTS_URL } from '@biproxi/models/externalLinks';
import { useAppDispatch } from '../../redux/store';
import { useMobileMedia } from '../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import { ListingActions } from '../../redux/listing.redux';
import useUserHook from '../../hooks/useUser.hook';
import ListingContactBox from '../ListingContactBox';
import LinkText from '../../elements/LinkText';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`;

const ListItem = styled.div`
  margin-bottom: 8px;
`;

type CreateListingContactsProps = {
  id: string;
  listing: IListingGraphQL;
};

const CreateListingContacts: React.FC<CreateListingContactsProps> = ({ id, listing }) => {
  /** Hooks */
  const { user } = useUserHook();
  const isMobile = useMobileMedia();

  /** State */
  const [contacts, setContacts] = React.useState<Array<IListingContact>>(listing?.contacts);
  const [showDefaultContact, setShowDefaultContact] = React.useState<boolean>(true);
  const [defaultContactIsHidden, setDefaultContactIsHidden] = React.useState<boolean>(false);

  const defaultContact: IListingContact = {
    _id: user._id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    phoneNumber: user?.displayedPhoneNumber ?? user.phoneNumber,
    profileImageUrl: user.profileImageFile?.url ?? '',
  };

  /** Actions */
  const dispatch = useAppDispatch();

  const pushAddContactModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AddOrEditListingContact,
      props: {
        contacts,
      },
    }),
  );

  const setListingContacts = (contacts: IListingContact[]) => dispatch(
    ListingActions.setListingContacts({ contacts }),
  );

  const removeDefaultContact = () => {
    const contactsWithoutDefault = contacts.filter((contact) => contact._id !== defaultContact._id);
    setListingContacts(contactsWithoutDefault);
    setShowDefaultContact(false);
    setDefaultContactIsHidden(true);
  };

  const addDefaultContact = () => {
    setListingContacts([...contacts, defaultContact]);
    setShowDefaultContact(true);
    setDefaultContactIsHidden(false);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...contacts];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setContacts(items);
    setListingContacts(items);
  };

  /** Passed to children so that when default contact deleted, we can update UI accordingly */
  const handleDefaultContactDeletion = () => {
    setShowDefaultContact(false);
    setDefaultContactIsHidden(true);
  };

  /** Effects */
  React.useEffect(() => {
    // /** generate default contact for newly created listing */
    if (!listing?.contacts?.length) {
      setListingContacts([defaultContact]);
    } else {
    /** Otherwise, populate extant contacts */
      setContacts(listing?.contacts);
    }
  }, [listing?.contacts]);

  /** Render */
  const customSubtitle: React.ReactElement = (
    <Flex width="100%" justify="space-between" direction={isMobile ? 'column' : 'row'}>
      <Text type={TextTypesEnum.Regular14}>Add contacts to your listing. At least one contact is required.</Text>
      {(contacts?.length > 1 || defaultContactIsHidden) && (
        <>
          {showDefaultContact ? (
            <LinkText
              type={TextTypesEnum.Medium14}
              onClick={() => removeDefaultContact()}
            >
              Hide default contact
            </LinkText>
          ) : (
            <LinkText
              type={TextTypesEnum.Medium14}
              onClick={() => addDefaultContact()}
            >
              Display default contact
            </LinkText>
          )}
        </>
      )}
    </Flex>
  );

  return (
    <ContentCard
      id={id}
      title="Contacts"
      subtitle={customSubtitle}
      learnMoreLink={KB_CREATE_LISTING_CONTACTS_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided, _snapshot) => (
            <Grid
              className="list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contacts?.map((contact, index) => {
                const id = `${contact}-${index}`;
                return (
                  <React.Fragment key={index}>
                    <Draggable
                      key={id}
                      draggableId={id}
                      index={index}
                    >
                      {(provided, _snapshot) => (
                        <ListItem
                          key={id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListingContactBox
                            contact={contact}
                            index={index}
                            contacts={contacts}
                            handleDefaultContactDeletion={handleDefaultContactDeletion}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  </React.Fragment>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Flex justify="flex-start" width="100%">
        <LinkText
          margin="16px 0px"
          type={TextTypesEnum.Medium12}
          onClick={() => pushAddContactModal()}
        >
          Add another contact
        </LinkText>
      </Flex>
    </ContentCard>
  );
};

export default CreateListingContacts;
