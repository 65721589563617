import React from 'react';
import styled from '@emotion/styled';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import { useQuery } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import { useNavigate } from 'react-router-dom';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import ListingCard from '../ListingCard.default';
import ListingCardTypesEnum from '../../models/enums/ListingCardTypesEnum';
import useUser from '../../hooks/useUser.hook';
import Icons from '../../elements/Icons';
import NoContent from '../NoContent';
import { media } from '../../utils/MediaQuery';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
`;

const SectionTitleWithButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const FavoriteListingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  width: 100%;

  ${media.mobile} {
    display: flex;
    flex-direction: column;
  }

  /* ${media.tablet} {
    display: flex;
    flex-direction: column;
  } */
`;

const Content = styled.div`
  height: 292px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.Grey300};
  margin: 4px 0;
  border-radius: 8px;
`;

type RecentlyFavoritedListingsProps = {};

const RecentlyFavoritedListings: React.FC<RecentlyFavoritedListingsProps> = () => {
  /** Hooks */
  const { user } = useUser();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const listingCache = useAppSelector(ListingSelectors.listingCache);
  const cacheListings = (listings: IListingGraphQL[]) => dispatch(
    ListingActions.cacheListings({ listings }),
  );

  /** GraphQL */
  interface Data {
    listings: {
      data: IListingGraphQL[];
    }

  }

  interface Vars {
    params: {
      query: IListingQuery;
    }
  }

  const { data } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Active,
          listingIds: user?.favoritedListings,
        },
      },
    },
  });

  /** Effects */
  React.useEffect(() => {
    if (data?.listings?.data) {
      cacheListings(data.listings?.data);
    }
  }, [data]);

  /* Render */
  let listings = [];

  if (user) {
    listings = user.favoritedListings.map((listingId) => listingCache[listingId])
      .filter((listing) => Boolean(listing));
  }

  /* Render */
  if (listings.length === 0) {
    return null;
  }

  /** Render */
  return (
    <Container>
      <SectionTitleWithButtonContainer>
        <Text type={TextTypesEnum.Bold18}>Your favorited listings</Text>
        <Text
          type={TextTypesEnum.Medium14}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => navigate('/app/dashboard/favorites')}
        >
          See all
        </Text>
      </SectionTitleWithButtonContainer>
      <FavoriteListingsContainer>
        {(() => {
          if (listings.length === 0) {
            return (
              <Content>
                <NoContent
                  height="100%"
                  icon={Icons.StarLight}
                  text="No favorites yet. Click the star icon on a property to add it to your favorites list."
                />
              </Content>
            );
          }

          return listings.slice(0, 3).map((listing: IListingGraphQL | null, index) => (
            <ListingCard
              key={listing?._id ?? index}
              onClick={() => window.open(ListingUtil.slug(listing), '_blank')}
              listing={listing}
              type={ListingCardTypesEnum.DefaultFavoritedDashboard}
            />
          ));
        })()}
      </FavoriteListingsContainer>
    </Container>
  );
};

export default RecentlyFavoritedListings;
