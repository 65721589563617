import React from 'react';
import styled from '@emotion/styled';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableSpace,
} from '../ScrollTable';
import Icons from '../../elements/Icons';
import NoContent from '../NoContent';
import { media } from '../../utils/MediaQuery';
import InvoiceTableRow from './InvoiceTableRow';

const Container = styled.div`
  ${media.mobile} {
    width: 94vw;
    margin: 0 auto;
    overflow: auto;
  }
`;

const Spacer = styled.div`
  height: 10px;
`;

const TableHeader: React.FC = () => (
  <ScrollTableHeader>
    <ScrollTableHeaderCell flex="1">
      Date
    </ScrollTableHeaderCell>
    <ScrollTableSpace />
    {/* <ScrollTableHeaderCell flex="1">
        Payment Method
      </ScrollTableHeaderCell> */}
    <ScrollTableSpace width="500px" />
    <ScrollTableHeaderCell flex="1">
      Total
    </ScrollTableHeaderCell>
    <ScrollTableHeaderCell flex="1" justify="center">
      Actions
    </ScrollTableHeaderCell>
  </ScrollTableHeader>
);

type InvoiceTableProps = {
  invoices: any;
};

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
}) => {
  /** State */
  const scrollContainer = React.useRef<any>(null);

  /* Render */
  if (!invoices) return null;

  return (
    <Container>
      <ScrollTable>
        <TableHeader />
        <Spacer />
        <ScrollTableBody ref={scrollContainer}>
          {invoices?.length > 0 ? invoices?.map((invoice: any, index) => (
            <React.Fragment key={index}>
              <InvoiceTableRow
                invoice={invoice}
              />
              {index !== invoices.length - 1 && <Spacer />}
            </React.Fragment>
          )) : (
            <NoContent
              height="176px"
              icon={Icons.StarLight}
              text="No invoices at this time."
            />
          )}
        </ScrollTableBody>
      </ScrollTable>
    </Container>
  );
};

export default InvoiceTable;
