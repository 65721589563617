enum ChargebeePortalSections {
  ACCOUNT_DETAILS = 'account_details',
  ADDRESS = 'portal_address',
  ADD_PAYMENT_SOURCE = 'portal_add_payment_method',
  BILLING_HISTORY = 'portal_billing_history',
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'portal_choose_payment_method',
  EDIT_ACCOUNT_DETAILS = 'portal_edit_account',
  EDIT_BILLING_ADDRESS = 'portal_edit_billing_address',
  EDIT_PAYMENT_SOURCE = 'portal_edit_payment_method',
  EDIT_SHIPPING_ADDRESS = 'portal_edit_shipping_address',
  EDIT_SUBSCRIPTION = 'edit_subscription',
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'portal_edit_subscription_cf',
  PAYMENT_SOURCES = 'portal_payment_methods',
  SUBSCRIPTION_CANCELLATION = 'sub_cancel',
  SUBSCRIPTION_DETAILS = 'sub_details',
  VIEW_PAYMENT_SOURCE = 'portal_view_payment_method',
  VIEW_SCHEDULED_CHANGES = 'scheduled_changes',
}

export default ChargebeePortalSections;
