import React from 'react';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import IChargebeeInvoice from '@biproxi/models/interfaces/IChargebeeInvoice';
import { useQuery } from 'react-query';
import {
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableSpace,
} from '../ScrollTable';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import queryInvoicePdf from '../../next-api/queries/billing/invoicePdf.query';

type InvoiceTableRowProps = {
  invoice: IChargebeeInvoice;
};

const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({
  invoice,
}) => {
  /** Data */
  const { refetch: getInvoiceDownloadLink } = useQuery('billing:invoice-pdf', () => queryInvoicePdf(invoice.id), {
    enabled: false,
    onSuccess: (data) => downloadInvoicePdf(data?.download?.download_url),
  });

  /* Actions */
  const downloadInvoicePdf = (link: string) => {
    if (window.globalThis.Cypress) return;
    window.open(link, '_self');
  };

  /** Render */
  return (
    <ScrollTableBodyRow
      // onClick={() => {}}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      height="48px"
      borderRadius="4px"
      backgroundColor={Colors.Brand50 || Colors.Blurple50}
      hoverBackgroundColor={Colors.Grey50}
    >
      <ScrollTableBodyCell flex="1">
        <Text
          type={TextTypesEnum.Medium14}
          color={Colors.Brand700 || Colors.Blurple700}
          data-cy="invoice-table-row-date"
        >
          {invoice?.date ? TimeUtil.format(TimeUtil.secondsDateToMilliseconds(invoice.date), 'D') : ''}

        </Text>
      </ScrollTableBodyCell>
      <ScrollTableSpace />
      {/* <ScrollTableBodyCell flex="1">
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey900}
          data-cy="invoice-table-row-payment"
        >
          *********5555
          {/* {invoice.paymentMethod} */}
      {/* </Text> */}
      {/* </ScrollTableBodyCell> */}
      <ScrollTableSpace width="500px" />
      <ScrollTableBodyCell flex="1">
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey900}
          data-cy="invoice-table-row-amount"
        >
          $
          {invoice.amount_paid / 100}
        </Text>
      </ScrollTableBodyCell>
      <ScrollTableBodyCell flex="1" justify="center">
        <Icon
          icon={Icons.DownloadSolid}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => getInvoiceDownloadLink()}
          data-cy="invoice-table-row-download-button"
        />
      </ScrollTableBodyCell>
    </ScrollTableBodyRow>
  );
};

export default InvoiceTableRow;
