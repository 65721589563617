import React from 'react';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import Colors from '../../styles/Colors';
import DataExplorerSettingsSectionsEnum from './@types/data-settings-types/enums/DataExplorerSettingsSectionsEnum';
import DataSettingsSection from './DataSettingsSection';
import { media } from '../../utils/MediaQuery';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import dataExplorerUtilityBarAtom from './state-management/recoil/atoms/DataExplorerUtilityBarAtom';

const DataSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 16px;
  z-index: 300;
  overflow-y: scroll;

  background-color: ${Colors.White};

  position: absolute;
  top: 50px;
  right: 24px;
  width: 370px; // probs want to change this
  height: 612px; // this too lol

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${media.mobile} {
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
  }
`;

type DataSettingsProps = {}

type DataSettingsConfig = {}

const DataSettings: React.FC<DataSettingsConfig> = (
  _config: DataSettingsConfig = { props: {} as DataSettingsProps },
) => {
  /* Recoil State */
  const [utilityBarState, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);
  const {
    dataSettingsVisible,
  } = utilityBarState;

  const settingsSections: string[] = Object.keys(DataExplorerSettingsSectionsEnum).map((key) => DataExplorerSettingsSectionsEnum[key]);

  /* Render */
  if (dataSettingsVisible) {
    return (
      <DataSettingsWrapper data-cy="data-settings-container">
        <Icon
          data-cy="close-data-settings"
          icon={Icons.TimesRegular}
          color={Colors.Brand700 || Colors.Blurple700}
          size="24px"
          margin="0 0 0 auto"
          onClick={() => setUtilityBarState((prevState) => ({
            ...prevState,
            dataSettingsVisible: false,
          }))}
        />
        {settingsSections.map((section: DataExplorerSettingsSectionsEnum, index: number) => (
          <DataSettingsSection
            key={index}
            section={section}
          />
        ))}
      </DataSettingsWrapper>
    );
  }

  return null;
};

export default DataSettings;
