import React from 'react';
import styled from '@emotion/styled';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { useRecoilValue } from 'recoil';
import DataExplorerDataFrameSectionsContent from '../@types/data-frame-types/objects/DataExplorerDataFrameSectionsContent';
import DataExplorerDataFrameDemographicsSections from '../@types/data-frame-types/objects/DataExplorerDataFrameDemographicsSections';
import DataExplorerDataFrameSectionsEnum from '../@types/data-frame-types/enums/DataExplorerDataFrameSectionsEnum';
import DataFrameInsights from './DataFrameInsights';
import DataFrameDemographics from './DataFrameDemographics';
import DataFrameAddressAndTaxInfoConditionalRenders from './DataFrameAddressAndTaxInfoConditionalRenders';
import { media } from '../../../utils/MediaQuery';
import { dataFrameGeneralAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import { dataExplorerGlobalAtom } from '../state-management/recoil/atoms/DataExplorerGlobalContext';
import { AppState, useAppSelector } from '../../../redux/store';
import Paywall from '../../Paywall';
/**
 * Within the designs, every child div wrapped in this div is grouped together in a column within the fullscreen view
 */
 type DataFrameAddressAndTaxInfoWrapperProps = {
    width: string;
    overflowY: string;
}

const DataFrameAddressAndTaxInfoWrapper = styled.div<DataFrameAddressAndTaxInfoWrapperProps>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width};
    gap: 16px;
    overflow-y: ${({ overflowY }) => overflowY};
    max-height: inherit;
    flex: 1;
    padding: 12px 0;
    /* height: calc(100% - 32px); */

    ${media.mobile} {
      overflow-y: scroll;
    }

    scrollbar-width: auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .2);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:vertical:hover {
      background-color: rgba(0, 0, 0, .5);
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
`;

const DataFrameSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

type DataFrameAddressAndTaxInfoProps = {
    taxInsights: ICherreDataInsights;
    taxDemographics: ICherreDataDemographics;
    taxIds: number[];
    yScrollRule?: string;
}

/**
 * The parent component for basically everything you see below the data frame toolbar
 * i.e. the parent component for the address/property overview info, tax inights, and tax demographics
 */
const DataFrameAddressAndTaxInfo: React.FC<DataFrameAddressAndTaxInfoProps> = ({
  taxInsights,
  taxDemographics,
  taxIds,
  yScrollRule = 'scroll',
}) => {
  /* State/Recoil */
  const selectedTaxId = taxIds?.[0];

  const {
    user: {
      userPermissions,
    },
  } = useAppSelector((state: AppState) => state);

  const canUserViewDataExplorerData = PermissionsUtil.canUserViewDataExplorerData(userPermissions?.userBiproxiRolesAndPermissions);

  const dataFrameGeneralState = useRecoilValue(dataFrameGeneralAtom);

  const {
    insightDataExpanded,
    dataSettingsSelectedData,
  } = dataFrameGeneralState;

  const dataExplorerGlobalState = useRecoilValue(dataExplorerGlobalAtom);
  const {
    mapVisible,
  } = dataExplorerGlobalState;

  const dataSections = !insightDataExpanded ? [Object.keys(DataExplorerDataFrameSectionsContent)[0]] : Object.keys(DataExplorerDataFrameSectionsContent); // if the map is visible (data frame ISN'T fullscreen), we only want to render lot info
  const dataDemographics = Object.keys(DataExplorerDataFrameDemographicsSections);

  /** Paywall data frame data */
  if (!canUserViewDataExplorerData) {
    const freeSections = dataSections?.filter((section: DataExplorerDataFrameSectionsEnum) => section === DataExplorerDataFrameSectionsEnum.LotInformation || section === DataExplorerDataFrameSectionsEnum.LotSize);
    const paywallSections = dataSections?.filter((section: DataExplorerDataFrameSectionsEnum) => section !== DataExplorerDataFrameSectionsEnum.LotInformation && section !== DataExplorerDataFrameSectionsEnum.LotSize);

    return (
      <>
        <DataFrameAddressAndTaxInfoWrapper
          width={mapVisible ? '100%' : null}
          overflowY={yScrollRule}
          id="data-frame-info"
        >
          <DataFrameSectionWrapper
            data-cy="data-frame-cherre-data-wrapper"
          >
            {freeSections.map((dataSection, index) => (
              <DataFrameInsights
                key={index}
                insightSectionTitle={dataSection}
                data={DataExplorerDataFrameSectionsContent[dataSection]}
                taxInsights={taxInsights}
              />
            ))}
            <Paywall>
              {paywallSections.map((dataSection, index) => (
                <DataFrameInsights
                  key={index}
                  insightSectionTitle={dataSection}
                  data={DataExplorerDataFrameSectionsContent[dataSection]}
                  taxInsights={taxInsights}
                />
              ))}
              {insightDataExpanded && dataDemographics?.filter((data, index) => (
                dataSettingsSelectedData[DataExplorerDataFrameSectionsEnum.Demographics][index].checked
              ))?.map((demographicSection, index) => (
                <DataFrameDemographics
                  key={index}
                  demographicSectionTitle={demographicSection}
                  data={DataExplorerDataFrameDemographicsSections[demographicSection]}
                  demographics={taxDemographics}
                />
              ))}
              <DataFrameAddressAndTaxInfoConditionalRenders selectedTaxId={selectedTaxId} />
            </Paywall>

          </DataFrameSectionWrapper>
        </DataFrameAddressAndTaxInfoWrapper>
      </>
    );
  }

  return (
    <>
      <DataFrameAddressAndTaxInfoWrapper
        width={mapVisible ? '100%' : null}
        overflowY={yScrollRule}
        id="data-frame-info"
      >
        <DataFrameSectionWrapper
          data-cy="data-frame-cherre-data-wrapper"
        >
          {dataSections.map((dataSection, index) => (
            <DataFrameInsights
              key={index}
              insightSectionTitle={dataSection}
              data={DataExplorerDataFrameSectionsContent[dataSection]}
              taxInsights={taxInsights}
            />
          ))}
          {insightDataExpanded && dataDemographics?.filter((data, index) => (
            dataSettingsSelectedData[DataExplorerDataFrameSectionsEnum.Demographics][index].checked
          ))?.map((demographicSection, index) => (
            <DataFrameDemographics
              key={index}
              demographicSectionTitle={demographicSection}
              data={DataExplorerDataFrameDemographicsSections[demographicSection]}
              demographics={taxDemographics}
            />
          ))}
          <DataFrameAddressAndTaxInfoConditionalRenders selectedTaxId={selectedTaxId} />
        </DataFrameSectionWrapper>
      </DataFrameAddressAndTaxInfoWrapper>
    </>
  );
};

export default DataFrameAddressAndTaxInfo;
