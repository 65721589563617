import React from 'react';
import styled from '@emotion/styled';
import Text, { TextTypesEnum } from '../elements/Text';
import Input, { InputTypesEnum } from '../elements/Input';

const Wrapper = styled.div<{dataCy?: string}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  data-cy: ${(props) => props['data-cy']};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

type RangeInputProps = {
  label?: string;
  inputType?: InputTypesEnum;
  onMinChange?: any;
  onMaxChange?: any;
  minValue?: string;
  maxValue?: string;
  'data-cy'?: string;
}

const RangeInput: React.FC<RangeInputProps> = ({
  label,
  inputType = InputTypesEnum.Number,
  onMinChange,
  onMaxChange,
  minValue = null,
  maxValue = null,
  'data-cy': dataCy,
}) => (
  <Wrapper
    data-cy={dataCy}
  >
    <Text
      type={TextTypesEnum.Medium12}
    >
      {label}
    </Text>
    <InputWrapper>
      <Input
        data-cy="range-input-min"
        placeholder="From"
        inputType={inputType}
        value={minValue}
        height="32px"
        onChange={onMinChange}
      />
      <Input
        data-cy="range-input-max"
        placeholder="To"
        inputType={inputType}
        value={maxValue}
        height="32px"
        onChange={onMaxChange}
      />
    </InputWrapper>
  </Wrapper>
);

export default RangeInput;
