import React from 'react';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useMutation } from '@apollo/client';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { useNavigate } from 'react-router-dom';
import GenericCard from '../../elements/GenericCard';
import BoxShadows from '../../styles/BoxShadows';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';
import { PrivateEventActions } from '../../redux/privateEvent.redux';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import UPDATE_PRIVATE_EVENT from '../../graphql/mutations/updatePrivateEvent.mutation';
import { useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import Flex from '../../elements/Flex';
import { LeadActions } from '../../redux/lead.redux';
import { LeadModalPageStateEnum } from '../modal/LeadModal';
import InlineAlert, { InlineAlertTypesEnum } from '../InlineAlert';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import PrivateEventQuickDisplayInfo from './PrivateEvenQuickDisplayInfo';
import usePrivateEventCountdown from '../../hooks/usePrivateEventCountdown.hook';

type PrivateEventManagementCardProps = {
  privateEvent: IPrivateEventGraphQL;
  refetchPrivateEvent: () => void;
};

const PrivateEventManagementCard: React.FC<PrivateEventManagementCardProps> = ({
  privateEvent,
  refetchPrivateEvent,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const navigate = useNavigate();
  const {
    time, blinkText, isLessThanTwoMinutesLeft, curEndsAt,
  } = usePrivateEventCountdown(privateEvent, refetchPrivateEvent);

  /** GraphQL */
  const [updatePrivateEvent, { loading }] = useMutation(UPDATE_PRIVATE_EVENT, {
    onCompleted: () => {
      popModal();
    },
    onError: (error) => {
      popModal();
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const pushModifyBiddingRoom = () => {
    dispatch(PrivateEventActions.selectPrivateEvent({
      privateEventId: privateEvent._id,
    }));
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.ModifyBiddingRoom,
        props: {
          privateEvent,
        },
      }),
    );
  };
  const pushMessageModal = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.MessageAllBidders,
        props: {
          privateEvent,
        },
      }),
    );
  };
  const pushCancelBiddingRoom = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Cancel Event',
        text: 'Are you sure you want to cancel this private event? All participants will be notified.',
        buttonText: 'Cancel event',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirmLoading: loading,
        confirm: () => updatePrivateEvent({
          variables: {
            params: {
              privateEventId: privateEvent._id,
              updateParams: {
                state: PrivateEventStatesEnum.Canceled,
              },
            },
          },
        }),
      },
    }),
  );
  const pushLeadModal = (leadId: string) => {
    dispatch(LeadActions.selectLead({ leadId }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        initialState: LeadModalPageStateEnum.Chat,
      },
    }));
  };

  const topBid = PrivateEventUtil.getTopBid(privateEvent);
  const topBidder = PrivateEventUtil.getParticipantFromBid(topBid, privateEvent);

  return (
    <GenericCard
      width={(isMobile || isTablet) ? '100%' : '320px'}
      margin={(isMobile || isTablet) ? '24px 0 0' : '0 0 0 24px'}
      boxShadow={BoxShadows.Standard}
      borderRadius="8px"
      padding="24px"
      height="fit-content"
    >
      {(() => {
        // Don't move this code into closures. Even if they are
        // memoized it breaks the button focus on rerender for the countdown.
        switch (privateEvent.state) {
          case PrivateEventStatesEnum.Upcoming:
          case PrivateEventStatesEnum.InProgress:
            return (
              <>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  Start date
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px" data-cy="private-event-start-date">
                  {privateEvent?.startsAt ? TimeUtil.format(privateEvent?.startsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                </Text>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  End date
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                  {privateEvent?.endsAt ? TimeUtil.format(privateEvent?.endsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                </Text>
                {privateEvent?.state === PrivateEventStatesEnum.InProgress && curEndsAt >= time && (
                  <>
                    <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                      Time Remaining
                    </Text>
                    <Text
                      color={isLessThanTwoMinutesLeft
                        ? blinkText
                          ? Colors.White
                          : Colors.Red600
                        : Colors.Black}
                      type={TextTypesEnum.Bold18}
                      margin="0 0 16px"
                    >
                      {TimeUtil.formattedCountDown(time, curEndsAt)}
                    </Text>
                  </>
                )}
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  Starting bid
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px" data-cy="private-event-starting-bid">
                  {privateEvent?.settings?.startingBid ? `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}` : '-'}
                </Text>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  Min. bid increments
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                  {privateEvent?.settings?.bidIncrement ? `$${StringUtil.formatNumber(privateEvent?.settings?.bidIncrement)}` : '-'}
                </Text>
                <Button
                  text="Message all bidders"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={pushMessageModal}
                  margin="16px 0 8px"
                />
                <Button
                  text="Modify bidding room"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={pushModifyBiddingRoom}
                  margin="0 0 8px"
                />
                <Button
                  text="Cancel bidding room"
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={pushCancelBiddingRoom}
                />
              </>
            );
          case PrivateEventStatesEnum.Canceled:
            return (
              <>
                <InlineAlert
                  type={InlineAlertTypesEnum.GreyNotice}
                  text="The private event has been canceled."
                  margin="0 0 24px"
                />
                <Button
                  text="Message all bidders"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={pushMessageModal}
                  margin="16px 0 8px"
                />
                <Button
                  text="Create new event"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={() => navigate('/app/dashboard/events/create')}
                  margin="0 0 8px"
                />
              </>
            );
          case PrivateEventStatesEnum.Finished:
            return (
              <>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  Start date
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                  {privateEvent?.startsAt ? TimeUtil.format(privateEvent?.startsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                </Text>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  End date
                </Text>
                <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                  {privateEvent?.endsAt ? TimeUtil.format(privateEvent?.endsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                </Text>
                <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                  Winning bid
                </Text>
                {topBid ? (
                  <>
                    <Text color={Colors.Black} type={TextTypesEnum.Bold18}>
                      {`$${StringUtil.formatNumber(topBid?.totalBidAmount)}`}
                    </Text>
                    <Flex>
                      <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
                        Winning bidder: &nbsp;
                      </Text>
                      <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
                        {`${topBidder?.user?.firstName} ${topBidder?.user?.lastName}`}
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                    --
                  </Text>
                )}
                {topBid && (
                <Button
                  text="Message winner"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={() => pushLeadModal(topBidder?.leadId)}
                  margin="16px 0 8px"
                />
                )}
                <Button
                  text="Message all bidders"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                  onClick={pushMessageModal}
                  margin="0 0 8px"
                />
              </>
            );
          default:
            return null;
        }
      })()}
      <PrivateEventQuickDisplayInfo privateEvent={privateEvent} />
    </GenericCard>
  );
};

export default PrivateEventManagementCard;
