enum CherreAssetClassesEnum {
    Agriculture = 'Agriculture',
    Timberland = 'Timberland',
    Hotel = 'Hotel',
    Motel = 'Motel',
    Industrial = 'Industrial',
    Manufacturing = 'Manufacturing',
    Storage = 'Storage',
    Vacant = 'Vacant',
    VacantAgricultural = 'Vacant - Agricultural',
    VacantCommercial = 'Vacant - Commercial',
    VacantIndustrial = 'Vacant - Industrial',
    VacantResidential = 'Vacant - Residential',
    Apartments = 'Apartments',
    MultiFamily = 'Multi-Family',
    MedicalServices = 'Medical Services',
    MixedUse = 'Mixed Use',
    Office = 'Office',
    CommercialCondo = 'Commercial Condo',
    Educational = 'Educational',
    GeneralCommercial = 'General Commercial',
    Government = 'Government',
    Historical = 'Historical',
    Hospital = 'Hospital',
    Misc = 'Misc',
    Parking = 'Parking',
    Services = 'Services',
    Transportation = 'Transportation',
    Unknown = 'Unknown',
    Utilites = 'Utilities',
    Cooperative = 'Cooperative',
    MiscResidential = 'Misc Residential',
    MobileHomes = 'Mobile Homes',
    ResidentialPlex = 'Residential - Duplex/Triplex/Quadplex',
    ResidentialCondo = 'Residential Condo',
    SingleFamilyResidence = 'Single Family Residence',
    Attractions = 'Attractions',
    Recreation = 'Recreation',
    Retail = 'Retail',
  }

export default CherreAssetClassesEnum;
