import gql from 'graphql-tag';

const LIST_SAVED_SEARCHES = gql`
    query SavedSearches {
        savedSearches {
            _id
            organizationId
            userId
            name
            query {
                address {
                    city
                    state
                    googlePlaceId
                    location {
                        type
                        coordinates
                    }
                }
                queryType
                assetClasses
                keywords
                propertyTypes
                price {
                    includeUndefined
                    min
                    max
                }
                sortBy
                listingIds
                bounds {
                    ne
                    nw
                    se
                    sw
                }
                state
            }
            user {
                firstName
                lastName
                email
            }
            address {
                city
                state
                googlePlaceId
                location {
                    type
                    coordinates
                }
            }
            notifications
        }
    }
`;

export default LIST_SAVED_SEARCHES;
