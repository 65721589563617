/**
 * Util file for app related things such as routing and whatnot.
 */
const isInDashboard = (path: string): boolean => path.includes('/app/')
  && !path.includes('/login')
  && !path.includes('/register')
  && !path.includes('/reset-password');

const AppUtil = {
  isInDashboard,
};

export default AppUtil;
