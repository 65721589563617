import React from 'react';
import { useMutation } from '@apollo/client';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import LeadAlertTypesEnum from '@biproxi/models/enums/LeadAlertTypesEnum';
import UpdateLeadTypeEnum from '@biproxi/models/enums/UpdateLeadTypeEnum';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import ILeadPrivateListingAccess from '@biproxi/models/interfaces/ILeadPrivateListingAccess';
import { InlineLeadTableAlertProps } from '../components/InlineLeadTableAlert';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import REJECT_LEAD_FILE_ACCESS_LEVEL from '../graphql/mutations/rejectLeadFileAccessLevel.mutation';
import SET_LEAD_FILE_ACCESS_LEVEL from '../graphql/mutations/setLeadFileAccessLevel.mutation';
import UPDATE_LEAD from '../graphql/mutations/updateLead.mutation';
import { AppActions } from '../redux/app.redux';
import { LeadActions } from '../redux/lead.redux';
import { useAppDispatch } from '../redux/store';

function getPrivateListingAlertConfig(lead: ILeadGraphQL): InlineLeadTableAlertProps {
  const [updateType, setUpdateType] = React.useState<UpdateLeadTypeEnum>(null);
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
    /** GraphQL */
    interface UpdateLeadData {
        updateLead: ILeadGraphQL;
    }

    interface UpdateLeadVars {
        params: {
            _id: string;
            userId: string;
            privateListingAccess: ILeadPrivateListingAccess;
            updateType: UpdateLeadTypeEnum;
        }
    }

    const [updateLead] = useMutation<UpdateLeadData, UpdateLeadVars>(UPDATE_LEAD, {
      onCompleted: (data) => {
        if (data?.updateLead) {
          pushToast({
            type: ToastTypesEnum.Notification,
            message: updateType === UpdateLeadTypeEnum.ApprovePrivateListingAccess ? 'You have approved access to your private listing' : 'You have denied access to your private listing',
          });
          popModal();
        }
      },
      onError: () => {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'There was an error approving access. Please try again.',
        });
      },
    });
    return {
      alertText: `${lead?.user?.firstName} has requested access to your private listing`,
      onAccept: (masterParentFunction) => {
        masterParentFunction?.();
        setUpdateType(UpdateLeadTypeEnum.ApprovePrivateListingAccess);
        updateLead({
          variables: {
            params: {
              _id: lead?._id,
              userId: lead?.userId,
              privateListingAccess: {
                accessApproved: true,
                accessDenied: false,
                accessRequested: true,
              },
              updateType: UpdateLeadTypeEnum.ApprovePrivateListingAccess,
            },
          },
        });
      },
      onDecline: (masterParentFunction) => {
        masterParentFunction?.();
        setUpdateType(UpdateLeadTypeEnum.DenyPrivateListingAccess);
        updateLead({
          variables: {
            params: {
              _id: lead?._id,
              userId: lead?.userId,
              privateListingAccess: {
                accessApproved: false,
                accessDenied: true,
                accessRequested: false, // reset state so investor can request again
              },
              updateType: UpdateLeadTypeEnum.DenyPrivateListingAccess,
            },
          },
        });
      },
      isTableAlert: true,
      lead,
    };
}

function getLevel2AccessAlertConfig(lead: ILeadGraphQL): InlineLeadTableAlertProps {
  /** Actions */
  const dispatch = useAppDispatch();
  /** Actions */
  const cacheLead = (lead: ILeadGraphQL) => dispatch(LeadActions.cacheLeads({ leads: [lead] }));

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  const [setLeadFileAccessLevel] = useMutation(SET_LEAD_FILE_ACCESS_LEVEL, {
    onCompleted: ({ setLeadFileAccessLevel }) => {
      cacheLead(setLeadFileAccessLevel);
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Level 2 access approved.',
      });
    },
  });
  const [rejectLeadFileAccessLevelChange] = useMutation(REJECT_LEAD_FILE_ACCESS_LEVEL, {
    onCompleted: ({ rejectLeadFileAccessLevelChange }) => {
      cacheLead(rejectLeadFileAccessLevelChange);
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Level 2 access rejected.',
      });
    },
  });
  return {
    alertText: `${lead?.user?.firstName} has requested access to the Level 2 Diligence Vault`,
    onAccept: (masterParentFunction) => {
      masterParentFunction?.();
      setLeadFileAccessLevel({
        variables: {
          params: {
            leadId: lead._id,
            accessLevel: FileAccessLevelsEnum.Level2,
          },
        },
      });
    },
    onDecline: (masterParentFunction) => {
      masterParentFunction?.();
      rejectLeadFileAccessLevelChange({
        variables: {
          params: {
            leadId: lead._id,
          },
        },
      });
    },
    isTableAlert: true,
    lead,
  };
}

function getAlertConfigs(alertTypes: LeadAlertTypesEnum[], lead: ILeadGraphQL): InlineLeadTableAlertProps[] {
  let alertConfigs: InlineLeadTableAlertProps[] = [];
  if (alertTypes.includes(LeadAlertTypesEnum.Level2FileAccess)) {
    alertConfigs = [...alertConfigs, getLevel2AccessAlertConfig(lead)];
  }
  if (alertTypes.includes(LeadAlertTypesEnum.PrivateListing)) {
    alertConfigs = [...alertConfigs, getPrivateListingAlertConfig(lead)];
  }
  return alertConfigs;
}

const LeadTableAlertUtil = {
  getPrivateListingAlertConfig,
  getLevel2AccessAlertConfig,
  getAlertConfigs,
};

export default LeadTableAlertUtil;
