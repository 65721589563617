import React from 'react';
import styled from '@emotion/styled';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Colors from '../../styles/Colors';
import NotificationTable, { NotificationTableTypesEnum } from '../NotificationTable';
import NoContent from '../NoContent';
import Icons from '../../elements/Icons';
import GenericCard from '../../elements/GenericCard';
import Text, { TextTypesEnum } from '../../elements/Text';
import { media } from '../../utils/MediaQuery';
import useNotification from '../../hooks/useNotification.hook';

const Container = styled.div`
width: 320px;

${media.mobile} {
  width: 100%;
  }

${media.tablet} {
  width: 100%;
  }
`;

const Content = styled.div`
  height: 354px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type RecentNotificationsCardProps = {};

const RecentNotificationsCard: React.FC<RecentNotificationsCardProps> = () => {
  /** Hooks */
  const { data, loading } = useNotification();

  /** Render */
  const notifications = data?.notifications?.data;

  return (
    <Container>
      <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0px 0px 8px">
        Recent Notifications
      </Text>

      <GenericCard margin="0" padding="0" overflow="hidden" borderRadius="8px">
        {loading ? (
          <Content>
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          </Content>
        ) : (
          <>
            {notifications?.length > 0 ? (
              <NotificationTable
                notifications={notifications.slice(0, 5)}
                type={NotificationTableTypesEnum.Dropdown}
              />
            ) : (
              <Content>
                <NoContent
                  height="100%"
                  icon={Icons.BellLight}
                  text="No recent notifications."
                />
              </Content>
            )}
          </>
        )}
      </GenericCard>
    </Container>
  );
};

export default RecentNotificationsCard;
