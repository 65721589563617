/* eslint-disable camelcase */
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { useQuery } from '@apollo/client';
import IAddress from '@biproxi/models/interfaces/IAddress';
import ICherreTaxListing from '@biproxi/models/interfaces/ICherreTaxListing';
import { KB_CREATE_LISTING_INSIGHTS_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Dropdown, { DropdownTypesEnum } from '../../elements/Dropdown';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import GenericToggle, { GenericToggleTextAlignEnum } from '../../elements/GenericToggle';
import CHERRE_TAX_LISTINGS_FROM_LATLONG from '../../graphql/queries/listingCherreTaxListingsFromLatLong.query';
import { useAppDispatch } from '../../redux/store';
import { ListingActions } from '../../redux/listing.redux';
import Colors from '../../styles/Colors';
import InlineAlert, { InlineAlertTypesEnum, InlineAlertStylesEnum } from '../InlineAlert';
import { useMobileMedia } from '../../utils/MediaQuery';
import Loader, { LoaderSizes } from '../../elements/Loader';

type CreateListingDataInsightsProps = {
    id?: string
    listing: IListingGraphQL
}

const CreateListingDataInsights: React.FC<CreateListingDataInsightsProps> = ({ id, listing }) => {
  /** State */
  const [cherreId, setCherreId] = React.useState<string | null>(listing?.address?.address1);
  const [displayCherreData, setDisplayCherreData] = React.useState<boolean>(false);

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  const setListingCherreId = (fields: Partial<IAddress>, toggle?: boolean) => {
    if (toggle) {
      dispatch(ListingActions.setListingCherreId(fields));
    } else {
      dispatch(ListingActions.setListingCherreId({ cherreId: null }));
    }
  };

  const { data: cherreTaxListingsFromLatLong, loading } = useQuery<any, any>(CHERRE_TAX_LISTINGS_FROM_LATLONG, {
    /**
     * Do not query for cherreId until both the
     * lat and lng fiels have values.
     */
    skip: !AddressUtil.hasLatAndLng(listing?.address),
    variables: { params: AddressUtil.formatCherreLatLong(listing.address) },
    onCompleted: (data) => {
      setCherreId(data?.cherreTaxListingsFromLatLong?.[0]?.tax_assessor_id.toString() ?? '');
      setDisplayCherreData(true);
      setListingCherreId(
        {
          cherreId: data?.cherreTaxListingsFromLatLong?.[0]?.tax_assessor_id.toString() ?? '',
        },
        true,
      );
    },
    context: {
      debounceKey: 'CHERRE_TAX_LISTINGS_FROM_LATLONG',
    },
    onError: () => {
      setCherreId(null);
    },
  });

  /** Effects */
  React.useEffect(() => {
    setDisplayCherreData(Boolean(listing?.address?.cherreId));
    if (!listing?.address?.location?.coordinates.length) {
      setCherreId(null);
    }
  });

  /** Render */
  const taxListings = cherreTaxListingsFromLatLong?.cherreTaxListingsFromLatLong ?? [];

  /**
   * Iterate through the taxListings array
   * and find the tax listing that is currently selected.
   * Use that taxListing's one_line_address as the
   * value for the dropdown
   *
   * If the one_line_address is not populated, which
   * happens in the case of properties with private address
   * information, we use the string "Private" plus the index+1
   * of the selected address as the dropdown value
   */

  const getPrivateAddressName = (taxListing: ICherreTaxListing, index): string => `Private Address ${index + 1} (Tax ID: ${taxListing?.tax_assessor_id ?? 'Unknown'})`;

  let dropdownValue = taxListings?.find((taxListing: ICherreTaxListing) => taxListing.tax_assessor_id.toString() === cherreId)?.one_line_address;

  if (!dropdownValue && taxListings.length > 0) {
    const privateAddressIndex = taxListings?.findIndex((taxListing: ICherreTaxListing) => taxListing.tax_assessor_id.toString() === cherreId) ?? 0;

    dropdownValue = getPrivateAddressName(taxListings?.[privateAddressIndex], privateAddressIndex);
  }

  return (
    <ContentCard
      id={id}
      title="Data Insights"
      subtitle="Import automated property data to display detailed insights on the listing."
      learnMoreLink={KB_CREATE_LISTING_INSIGHTS_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <Flex
        direction={isMobile ? 'column' : 'row'}
        justify="space-between"
      >
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Black}
          margin="0px 0px"
        >
          Display data insights on listing
        </Text>
        <GenericToggle
          value={Boolean(displayCherreData && cherreId)}
          textAlign={GenericToggleTextAlignEnum.Left}
          onChange={() => {
            const toggle = !displayCherreData;
            if (cherreId) setDisplayCherreData(toggle);
            setListingCherreId({ cherreId }, toggle);
          }}
          margin={isMobile ? '8px 0px' : '0px'}
        />
      </Flex>
      <Text
        type={TextTypesEnum.Regular14}
        color={Colors.Grey700}
        width="80%"
        margin="0px 0px 24px 0px"
      >
        When enabled, Data Insights from Cherre will be displayed publicly for anyone who views your listing to see. This can also be disabled and hidden.
      </Text>
      {taxListings?.length ? (
        <>
          {loading ? (
            <Flex
              flex="1"
              align="center"
              justify="center"
              direction="column"
              height="140px"
            >
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </Flex>
          ) : (
            <Dropdown
              label="Select Tax Listing"
              type={DropdownTypesEnum.Select}
              value={dropdownValue}
              onChange={(value) => {
                setCherreId(value.toString());
                const toggle = displayCherreData;
                setListingCherreId({ cherreId: value.toString() }, toggle);
              }}
              placeholder="Please select a tax listing"
              maxDropdownHeight="168px"
              items={taxListings?.map((taxListing: ICherreTaxListing, index: number) => ({
                name: taxListing.one_line_address ?? getPrivateAddressName(taxListing, index),
                value: taxListing.tax_assessor_id,
              }))}
            />
          )}
        </>
      ) : null}
      {Boolean(!listing?.address?.location?.coordinates.length) && (
      <InlineAlert
        type={InlineAlertTypesEnum.RedWarning}
        styleOption={InlineAlertStylesEnum.WithTop}
        label="Address not yet entered"
        text="You have not yet entered a complete address for your listing. Please enter an address above to enable data insights."
      />
      )}
      {listing?.address?.location?.coordinates.length > 0 && taxListings.length === 0 && (
        <InlineAlert
          type={InlineAlertTypesEnum.RedWarning}
          text="The address you entered does not have any data associated with it at this time."
        />
      )}
    </ContentCard>
  );
};

export default CreateListingDataInsights;
