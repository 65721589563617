import React from 'react';
import styled from '@emotion/styled';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import Flex from '../elements/Flex';
import Icon, { Icons } from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import { useMobileMedia } from '../utils/MediaQuery';

const DocumentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    padding: 0 12px;
    border: 1px solid ${Colors.Grey200};
`;

type ListingUploadAdminDocumentsProps = {
    uploadedListing: IListingUploadGraphQL;
}

const ListingUploadAdminDocuments: React.FC<ListingUploadAdminDocumentsProps> = ({
  uploadedListing,
}) => {
  const { media: { files } } = uploadedListing;
  const isMobile = useMobileMedia();

  return (
    <>
      {files?.map((file, index) => (
        <DocumentContainer key={index}>
          <Flex>
            <Icon
              size="12px"
              icon={Icons.FileSolid}
              color={Colors.Grey400}
              margin="0 8px 0 0"
            />
            <Text type={TextTypesEnum.Regular14}>{file?.name}</Text>
          </Flex>
          <Flex padding={isMobile ? '0' : '0 130px 0 0'}>
            <Icon
              size="12px"
              icon={Icons.DownloadSolid}
              onClick={() => {
                window.open(file?.url);
              }}
              color={Colors.Grey400}
              margin="0 16px 0 0"
            />
            {/* <Icon
              size="12px"
              icon={Icons.TrashSolid}
              color={Colors.Grey400}
            /> */}
          </Flex>
        </DocumentContainer>
      ))}

    </>
  );
};

export default ListingUploadAdminDocuments;
