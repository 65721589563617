import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { IListLeadsParams } from '@biproxi/models/services/ILeadService';
import { useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import useListing from '../hooks/useListing.hook';
import AnalyticsMap from '../components/listing-details/overview/AnalyticsMap';
import ListingAnalytics from '../components/listing-details/overview/ListingAnalytics';
import ListingActivity from '../components/listing-details/overview/ListingActivity';
import { media } from '../utils/MediaQuery';
import LIST_LEADS from '../graphql/queries/leads.query';

const Container = styled.div`
  ${media.mobile} {
    width: 90vw;
    margin: 0 auto;
  }
`;

const ListingDetailsOverview: React.FC = () => {
  /* Hooks */
  const { listing } = useListing();
  const listingId = listing?._id;
  const listingUserId = listing?.user?._id;

  /** GraphQL */
  interface Data {
    leads: ILeadGraphQL[];
  }
  interface Vars {
    params: IListLeadsParams;
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_LEADS, {
    variables: {
      params: {
        listingId,
      },
    },
    onError: (error) => {
      logger.error('ListingDetailsOverview error', error);
    },
  });

  return (
    <Container>
      {/* Heatmap */}
      <AnalyticsMap listingId={listingId} listingUserId={listingUserId} />
      {/* Activity section */}
      <ListingActivity listingId={listingId} leads={data?.leads} loading={loading} />
      {/* Analytics section */}
      <ListingAnalytics listingId={listingId} listingUserId={listingUserId} />
    </Container>
  );
};

export default ListingDetailsOverview;
