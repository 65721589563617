import gql from 'graphql-tag';
import FileFragments from '../fragments/file.fragments';

const CREATE_VIDEO_FILE = gql`
  mutation CreateVideoFile($params: FileParams!) {
    createVideoFile(params: $params) {
      file {
        ...FileFields
      }
    }
  }

  ${FileFragments.fields}
`;

export default CREATE_VIDEO_FILE;
