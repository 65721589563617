import { gql } from '@apollo/client';

const CHECK_INVESTORS = gql`
  mutation checkInvestorMatches($params: CheckInvestorMatchesParams!) {
    checkInvestorMatches(params: $params) {
      newMatches
    }
  }
`;

export default CHECK_INVESTORS;
