import React from 'react';
import { TargetPriceRangeEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import CheckboxList from '../../elements/CheckBoxList';
import { TextTypesEnum } from '../../elements/Text';
import { CheckboxTypesEnum } from '../../elements/Checkbox';

type ProfilePriceRangeQuestionProps = {
  user: IUserGraphQL;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const ProfilePriceRangeQuestion: React.FC<ProfilePriceRangeQuestionProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  const config = Object.values(TargetPriceRangeEnum).map((item) => {
    const priceRanges = user?.investorProfile?.targetPriceRanges;
    const active = priceRanges?.includes(item);
    return {
      label: item,
      labelTextType: TextTypesEnum.Medium16,
      active,
      type: CheckboxTypesEnum.Large,
      onClick: () => {
        setUserInvestorProfileField({
          targetPriceRanges: active
            ? priceRanges?.filter((priceRange) => priceRange !== item)
            : priceRanges
              ? [...priceRanges, item]
              : [item],
        });
      },
    };
  });

  /* Render */
  return (
    <CheckboxList
      data-cy="price-range"
      title="What are your target price ranges?"
      config={config}
    />
  );
};

export default ProfilePriceRangeQuestion;
