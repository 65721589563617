import React from 'react';
import styled from '@emotion/styled';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../../../styles/Colors';
import Text, { TextTypesEnum } from '../../../elements/Text';
import { media } from '../../../utils/MediaQuery';
import IDataInsight from '../@types/data-frame-types/interfaces/IDataInsight';

const DataFrameDemographicsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const DataFrameSectionDataWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const CherreDemographicsChartBar = styled.div<{ width?: number, backgroundColor?: string }>`
  background-color: ${Colors.Brand700 || Colors.Blurple700};
  width: ${(props) => props.width}px;
  height: 13px;
  ${media.print}{
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  };
`;

const ChartWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: flex-start;
    width: 50%;
`;

type DataFrameDemographicsProps = {
    demographicSectionTitle: string;
    data: IDataInsight[]; // fix
    demographics: ICherreDataDemographics
};

/**
 * Much like `DataFrameInsights.tsx`, this component is called within a map function within the `DataFrameAddressAndTaxInfo.tsx` component
 * For all the demographics content in `DataExplorerSettingsSectionsContent.ts`, we render the Cherre data associated with the section
 */
const DataFrameDemographics: React.FC<DataFrameDemographicsProps> = ({
  demographicSectionTitle,
  data,
  demographics,
}) => {
  // max value of the data within the section
  const maxValue = Math.max(...data.slice(1)?.filter((info) => (
    demographics?.[info?.cherreVariableName] !== null
  ))?.map((dataPoint) => demographics?.[dataPoint?.cherreVariableName]));

  return (
    <DataFrameDemographicsWrapper>
      <Text
        type={TextTypesEnum.Bold16}
        color={Colors.Brand700 || Colors.Blurple700}
      >
        {demographicSectionTitle}
      </Text>
      {
        data.slice(1).filter((info) => (
          demographics?.[info.cherreVariableName] !== null
        )).map((info, index) => {
          let demographicData = demographics?.[info.cherreVariableName];
          if (typeof demographicData === 'number') demographicData = StringUtil.formatNumber(demographicData);
          return (
            <DataFrameSectionDataWrapper key={index}>
              <Text
                type={TextTypesEnum.Regular12}
                color={Colors.Grey500}
                width="50%"
              >
                {info.item}
              </Text>
              <ChartWrapper>
                <CherreDemographicsChartBar key={info.item} width={(demographics?.[info.cherreVariableName] / maxValue) * 140} />
                <Text
                  type={TextTypesEnum.Regular12}
                  color={Colors.Grey500}
                >
                  {demographicData}
                </Text>
              </ChartWrapper>
            </DataFrameSectionDataWrapper>
          );
        })
      }
    </DataFrameDemographicsWrapper>
  );
};

export default DataFrameDemographics;
