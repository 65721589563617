import React from 'react';
import styled from '@emotion/styled';
import NextPageContainer from '../containers/NextPage.container';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import Colors from '../styles/Colors';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.SpecialBlack};
`;

type RegisterPageProps = {
};

const RegisterPage: React.FC<RegisterPageProps> = () => {
  const dispatch = useAppDispatch();

  const pushRegisterModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Register,
      props: {
        isStandalone: true,
      },
    }),
  );

  React.useEffect(() => {
    pushRegisterModal();
  }, []);

  /* Render */
  return (
    <NextPageContainer>
      <Container />
    </NextPageContainer>
  );
};

export default RegisterPage;
