import ILocation from './ILocation';

interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country?: string;
  location?: ILocation;
  googlePlaceId: string | null;
  timeZoneId: string | null;
  cherreId?: string;
}

export function addressState(): IAddress {
  return {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    location: {
      type: 'Point',
      coordinates: [],
    },
    googlePlaceId: '',
    timeZoneId: '',
    cherreId: '',
  };
}

export default IAddress;
