import React from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import styled from '@emotion/styled';
import RangeInput from '../../../RangeInput';
import { InputTypesEnum } from '../../../../elements/Input';
import { media } from '../../../../utils/MediaQuery';
import { dataExplorerTaxFiltersAtom, ITaxFilters } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

const TaxFiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mobile} {
      padding: 0 8px;
    }
`;

type TaxFiltersProps = {}

const TaxFilters: React.FC<TaxFiltersProps> = () => {
  /** Recoil */
  const dataExplorerTaxFilters = useRecoilValue(dataExplorerTaxFiltersAtom);
  const {
    taxAmountRange,
    assessedPropertyValueRange,
    marketPropertyValueRange,
  } = dataExplorerTaxFilters;

  /** Callbacks */
  const setTaxAmount = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerTaxFiltersAtom, (prevState: ITaxFilters) => ({
      ...prevState,
      taxAmountRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [taxAmountRange]);

  const setAssessedPropertyValue = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerTaxFiltersAtom, (prevState: ITaxFilters) => ({
      ...prevState,
      assessedPropertyValueRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [assessedPropertyValueRange]);

  const setMarketPropertyValue = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerTaxFiltersAtom, (prevState: ITaxFilters) => ({
      ...prevState,
      marketPropertyValueRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [marketPropertyValueRange]);

  return (
    <TaxFiltersWrapper
      data-cy="TaxesContainer"
    >
      <RangeInput
        label="Tax amount"
        data-cy="TaxAmountInput"
        inputType={InputTypesEnum.Currency}
        onMinChange={(_e, unmasked) => setTaxAmount({ minValue: unmasked, maxValue: taxAmountRange?.max })}
        onMaxChange={(_e, unmasked) => setTaxAmount({ minValue: taxAmountRange?.min, maxValue: unmasked })}
        minValue={taxAmountRange?.min}
        maxValue={taxAmountRange?.max}
      />
      <RangeInput
        label="Assessed property value"
        data-cy="AssessedPropertyValueInput"
        inputType={InputTypesEnum.Currency}
        onMinChange={(_e, unmasked) => setAssessedPropertyValue({ minValue: unmasked, maxValue: assessedPropertyValueRange?.max })}
        onMaxChange={(_e, unmasked) => setAssessedPropertyValue({ minValue: assessedPropertyValueRange?.min, maxValue: unmasked })}
        minValue={assessedPropertyValueRange?.min}
        maxValue={assessedPropertyValueRange?.max}
      />
      <RangeInput
        label="Market property value"
        data-cy="MarketPropertyValueInput"
        inputType={InputTypesEnum.Currency}
        onMinChange={(_e, unmasked) => setMarketPropertyValue({ minValue: unmasked, maxValue: marketPropertyValueRange?.max })}
        onMaxChange={(_e, unmasked) => setMarketPropertyValue({ minValue: marketPropertyValueRange?.min, maxValue: unmasked })}
        minValue={marketPropertyValueRange?.min}
        maxValue={marketPropertyValueRange?.max}
      />
    </TaxFiltersWrapper>
  );
};

export default TaxFilters;
