import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import IListingUploadQuery from '@biproxi/models/interfaces/IListingUploadQuery';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import useOnclickOutside from 'react-cool-onclickoutside';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Button, { ButtonSizesEnum } from '../elements/Button';
import Input, { InputTypesEnum } from '../elements/Input';
import Colors from '../styles/Colors';
import LIST_LISTING_UPLOADS from '../graphql/queries/listingUploads.query';
import Loader from '../elements/Loader';
import ListingUploadsTable from '../components/ListingUploadsTable';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import SquareIconButton from '../elements/SquareIconButton';
import Icons from '../elements/Icons';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import FilterButton from '../elements/FilterButton';
import Icon from '../elements/Icon';
import DropdownList from '../components/DropdownList';

/**
 * START ADMIN PAGE
 */
const Container = styled.div`
  max-width: calc(100vw - 32px);
  ${media.mobile} {
    width: 100%;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
  }
`;

const UploadedListingsPageContainer = styled.div`
  margin-bottom: 32px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

type AdminPageProps = {};

const AdminPage: React.FC<AdminPageProps> = () => {
  /** State */
  const [search, setSearch] = React.useState('');
  const [listingUploadStateFilterOpen, setListingUploadStateFilterOpen] = React.useState(false);
  const [currentListingUploadStateFilter, setCurrentListingUploadStateFilter] = React.useState(ListingUploadStateEnum.NotStarted);

  const listingUploadStateItems = Object.values(ListingUploadStateEnum).map((state) => (
    {
      name: state,
      value: state,
    }
  ));

  // listingUploadStateItems = {
  //   ...listingUploadStateItems,
  //   {
  //     name: 'All',
  //     value: [Object.values(ListingUploadStateEnum)],
  //   }
  // }

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Refs */
  const sortButtonRef = React.useRef<HTMLDivElement>();

  const ref = useOnclickOutside(() => {
    setListingUploadStateFilterOpen(false);
  });

  /** Actions */
  const dispatch = useAppDispatch();

  const pushUploadListingModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.UploadListing,
    props: {},
  }));

  /** GraphQL */
  interface ListingUploadData {
    listingUploads: {
      data: IListingUploadGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  interface ListingUploadVars {
    params: IListingUploadQuery;
  }
  const { data, loading: listingUploadsLoading } = useQuery<ListingUploadData, ListingUploadVars>(LIST_LISTING_UPLOADS, {
    variables: {
      params: {
        queryType: ListingUploadQueryTypesEnum.All,
      },
    },
  });

  /** Functions */
  // const sortUploadedListings = () => {
  //   const notStartedListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.NotStarted).reverse() ?? [];
  //   const inProgressListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.InProgress).reverse() ?? [];
  //   const waitingForReviewListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.WaitingForReview).reverse() ?? [];
  //   const publishedListings = uploadedListings.filter((listing) => listing.state === ListingUploadStateEnum.Published).reverse() ?? [];
  //   const sortedUploadedListings: IListingUploadGraphQL[] = [...notStartedListings, ...inProgressListings, ...waitingForReviewListings, ...publishedListings];
  //   return sortedUploadedListings;
  // };

  /** Render */
  const uploadedListings: IListingUploadGraphQL[] = data?.listingUploads?.data ?? [];
  // let filteredListings = [...uploadedListings];
  let filteredListings = uploadedListings.filter((listing) => listing.state === currentListingUploadStateFilter).reverse() ?? [];

  // currently filtering by listingId, broker email, assignee email, and description. MIGHT want to cut that back by a few fields but idk
  if (search) {
    filteredListings = filteredListings.filter((listingUpload) => {
      const {
        listingId, adminInfo: { email: adminEmail }, additionalInfo, user: { email }, listingName,
      } = listingUpload;

      return listingId.toUpperCase().includes(search.toUpperCase()) || adminEmail?.toUpperCase()?.includes(search.toUpperCase()) || additionalInfo.toUpperCase().includes(search.toUpperCase()) || email.toUpperCase().includes(search.toUpperCase()) || listingName.toUpperCase().includes(search.toUpperCase());
    });
  }

  return (
    <Container>
      <Flex>
        <Text>Admin dashboard</Text>
      </Flex>
      {listingUploadsLoading ? (
        <LoadingContainer>
          <Loader
            color={Colors.Brand700 || Colors.Blurple700}
          />
        </LoadingContainer>
      ) : (
        <UploadedListingsPageContainer>
          <Flex justify="space-between" margin="16px 0">
            <Text type={isMobile ? TextTypesEnum.Bold18 : TextTypesEnum.Bold24}>
              Uploaded property details
            </Text>
            {(isMobile || isTablet) ? (
              <SquareIconButton
                icon={Icons.PlusRegular}
                iconColor={Colors.White}
                backgroundColor={Colors.Blurple700}
                size="32px"
                onClick={() => pushUploadListingModal()}
              />
            ) : (
              <Button
                text="Upload property details"
                size={ButtonSizesEnum.Small}
                onClick={() => pushUploadListingModal()}
              />
            )}

          </Flex>
          <Flex justify="space-between">
            <Input
              placeholder="Search listing uploads"
              inputType={InputTypesEnum.Search}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              clear={() => setSearch('')}
              width="100%"
            />
            <div ref={ref}>
              <FilterButton
                ref={sortButtonRef}
                value={currentListingUploadStateFilter}
                onClick={() => setListingUploadStateFilterOpen(!listingUploadStateFilterOpen)}
                icon={<Icon icon={Icons.AngleDownRegular} color={Colors.Grey900} size={10} margin="0 0 0 8px" />}
                margin="0 0 0 4px"
                height="48px"
                width="156px"
              />
              <DropdownList
                ref={sortButtonRef}
                open={listingUploadStateFilterOpen}
                setOpen={setListingUploadStateFilterOpen}
                items={listingUploadStateItems}
                value={currentListingUploadStateFilter}
                onChange={(value) => setCurrentListingUploadStateFilter(value)}
                width="156px"
                right
              />
            </div>
          </Flex>

          <ListingUploadsTable uploadedListings={filteredListings} />
        </UploadedListingsPageContainer>
      )}

    </Container>
  );
};

export default AdminPage;
