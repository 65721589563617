import React from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../../../elements/Button';
import Flex from '../../../../elements/Flex';
import Text, { TextTypesEnum } from '../../../../elements/Text';
import Colors from '../../../../styles/Colors';
import DataExplorerFilterPropertyListDataControllersEnum from '../../@types/utility-bar-types/enums/DataExplorerFilterPropertyListDataControllersEnum';
import {
  dataExplorerBasicFilterStateAtom, dataExplorerBuildingDetailsFiltersAtom, dataExplorerLotSizeFiltersAtom, dataExplorerPropertyTypeFiltersAtom, dataExplorerTaxFiltersAtom, dataExplorerTransactionFiltersAtom,
} from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';
import dataExplorerUtilityBarAtom from '../../state-management/recoil/atoms/DataExplorerUtilityBarAtom';

type CherrePropertyFiltersModalProps = {}

const CherrePropertyFiltersModal: React.FC<CherrePropertyFiltersModalProps> = () => {
  /** Recoil State */
  const setUtilityBarState = useSetRecoilState(dataExplorerUtilityBarAtom);
  const [, setBasicFilterState] = useRecoilState(dataExplorerBasicFilterStateAtom);

  /** Clear Values functions */
  const resetPropertyState = useResetRecoilState(dataExplorerPropertyTypeFiltersAtom);
  const resetLotSizeState = useResetRecoilState(dataExplorerLotSizeFiltersAtom);
  const resetBuildingDetailState = useResetRecoilState(dataExplorerBuildingDetailsFiltersAtom);
  const resetTransactionState = useResetRecoilState(dataExplorerTransactionFiltersAtom);
  const resetTaxeState = useResetRecoilState(dataExplorerTaxFiltersAtom);

  /** Actions */
  const applyFilters = () => setUtilityBarState((prevState) => ({
    ...prevState,
    filteredPropertyListQueryType: DataExplorerFilterPropertyListDataControllersEnum.TaxFilters,
    fetchFilterProperties: true,
    showFilterPropertyList: true,
    propertyFiltersVisible: false,
  }));

  const clearFilters = () => {
    resetPropertyState();
    resetLotSizeState();
    resetBuildingDetailState();
    resetTransactionState();
    resetTaxeState();
    setBasicFilterState((prevState) => ({
      ...prevState,
      selectedSections: null,
      fetchFilterProperties: null,
    }));
  };

  return (
    <Flex align="center" justify="space-between">
      <Text
        type={TextTypesEnum.Medium12}
        color={Colors.Brand700 || Colors.Blurple700}
        data-cy="data-explorer-clear-all-filters"
        onClick={() => clearFilters()}
      >
        Clear all
      </Text>

      <Flex>
        <Button
          data-cy="PropertyFiltersFooterCancel"
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Small}
          text="Cancel"
          margin="0 8px 0 0"
          onClick={() => setUtilityBarState((prevState) => ({
            ...prevState,
            propertyFiltersVisible: false,
          }))}
        />
        <Button
          data-cy="PropertyFiltersFooterApply"
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Small}
          text="Apply"
          onClick={() => applyFilters()}
        />
      </Flex>
    </Flex>

  );
};

export default CherrePropertyFiltersModal;
