const msaNames = [
  'Abilene, TX',
  'Albany-Lebanon, OR',
  'Albany, GA',
  'Alexandria, LA',
  'Altoona, PA',
  'Amarillo, TX',
  'Ames, IA',
  'Anchorage, AK',
  'Ann Arbor, MI',
  'Anniston-Oxford, AL',
  'Appleton, WI',
  'Asheville, NC',
  'Athens-Clarke County, GA',
  'Atlantic City-Hammonton, NJ',
  'Auburn-Opelika, AL',
  'Bangor, ME',
  'Barnstable Town, MA',
  'Battle Creek, MI',
  'Bay City, MI',
  'Beaumont-Port Arthur, TX',
  'Beckley, WV',
  'Bellingham, WA',
  'Bend, OR',
  'Billings, MT',
  'Binghamton, NY',
  'Bismarck, ND',
  'Blacksburg-Christiansburg, VA',
  'Bloomington, IL',
  'Bloomington, IN',
  'Bloomsburg-Berwick, PA',
  'Boulder, CO',
  'Bowling Green, KY',
  'Bremerton-Silverdale-Port Orchard, WA',
  'Brownsville-Harlingen, TX',
  'Brunswick, GA',
  'Burlington-South Burlington, VT',
  'Burlington, NC',
  'California-Lexington Park, MD',
  'Canton-Massillon, OH',
  'Cape Girardeau, MO-IL',
  'Carbondale-Marion, IL',
  'Carson City, NV',
  'Casper, WY',
  'Cedar Rapids, IA',
  'Chambersburg-Waynesboro, PA',
  'Champaign-Urbana, IL',
  'Charleston, WV',
  'Charlottesville, VA',
  'Chattanooga, TN-GA',
  'Cheyenne, WY',
  'Chico, CA',
  'Clarksville, TN-KY',
  'Cleveland, TN',
  'Coeur d\'Alene, ID',
  'College Station-Bryan, TX',
  'Columbia, MO',
  'Columbus, GA-AL',
  'Columbus, IN',
  'Corpus Christi, TX',
  'Corvallis, OR',
  'Crestview-Fort Walton Beach-Destin, FL',
  'Cumberland, MD-WV',
  'Dalton, GA',
  'Danville, IL',
  'Daphne-Fairhope-Foley, AL',
  'Davenport-Moline-Rock Island, IA-IL',
  'Decatur, AL',
  'Decatur, IL',
  'Dothan, AL',
  'Dover, DE',
  'Dubuque, IA',
  'Duluth, MN-WI',
  'East Stroudsburg, PA',
  'Eau Claire, WI',
  'El Centro, CA',
  'Elizabethtown-Fort Knox, KY',
  'Elkhart-Goshen, IN',
  'Elmira, NY',
  'Enid, OK',
  'Erie, PA',
  'Eugene-Springfield, OR',
  'Evansville, IN-KY',
  'Fairbanks, AK',
  'Fargo, ND-MN',
  'Farmington, NM',
  'Fayetteville-Springdale-Rogers, AR',
  'Fayetteville, NC',
  'Flagstaff, AZ',
  'Flint, MI',
  'Florence-Muscle Shoals, AL',
  'Florence, SC',
  'Fond du Lac, WI',
  'Fort Collins, CO',
  'Fort Smith, AR-OK',
  'Fort Wayne, IN',
  'Gadsden, AL',
  'Gainesville, FL',
  'Gainesville, GA',
  'Gettysburg, PA',
  'Glens Falls, NY',
  'Goldsboro, NC',
  'Grand Forks, ND-MN',
  'Grand Island, NE',
  'Grand Junction, CO',
  'Grants Pass, OR',
  'Great Falls, MT',
  'Greeley, CO',
  'Green Bay, WI',
  'Greenville, NC',
  'Gulfport-Biloxi, MS',
  'Hagerstown-Martinsburg, MD-WV',
  'Hammond, LA',
  'Hanford-Corcoran, CA',
  'Harrisonburg, VA',
  'Hattiesburg, MS',
  'Hickory-Lenoir-Morganton, NC',
  'Hilton Head Island-Bluffton-Beaufort, SC',
  'Hinesville, GA',
  'Homosassa Springs, FL',
  'Hot Springs, AR',
  'Houma-Thibodaux, LA',
  'Huntington-Ashland, WV-KY-OH',
  'Huntsville, AL',
  'Idaho Falls, ID',
  'Iowa City, IA',
  'Ithaca, NY',
  'Jackson, MI',
  'Jackson, TN',
  'Jacksonville, NC',
  'Janesville-Beloit, WI',
  'Jefferson City, MO',
  'Johnson City, TN',
  'Johnstown, PA',
  'Jonesboro, AR',
  'Joplin, MO',
  'Kahului-Wailuku-Lahaina, HI',
  'Kalamazoo-Portage, MI',
  'Kankakee, IL',
  'Kennewick-Richland, WA',
  'Killeen-Temple, TX',
  'Kingsport-Bristol, TN-VA',
  'Kingston, NY',
  'Kokomo, IN',
  'La Crosse-Onalaska, WI-MN',
  'Lafayette-West Lafayette, IN',
  'Lafayette, LA',
  'Lake Charles, LA',
  'Lake Havasu City-Kingman, AZ',
  'Lancaster, PA',
  'Lansing-East Lansing, MI',
  'Laredo, TX',
  'Las Cruces, NM',
  'Lawrence, KS',
  'Lawton, OK',
  'Lebanon, PA',
  'Lewiston-Auburn, ME',
  'Lewiston, ID-WA',
  'Lexington-Fayette, KY',
  'Lima, OH',
  'Lincoln, NE',
  'Logan, UT-ID',
  'Longview, TX',
  'Longview, WA',
  'Lubbock, TX',
  'Lynchburg, VA',
  'Macon-Bibb County, GA',
  'Madera, CA',
  'Manchester-Nashua, NH',
  'Manhattan, KS',
  'Mankato, MN',
  'Mansfield, OH',
  'Medford, OR',
  'Merced, CA',
  'Michigan City-La Porte, IN',
  'Midland, MI',
  'Midland, TX',
  'Missoula, MT',
  'Mobile, AL',
  'Modesto, CA',
  'Monroe, LA',
  'Monroe, MI',
  'Montgomery, AL',
  'Morgantown, WV',
  'Morristown, TN',
  'Mount Vernon-Anacortes, WA',
  'Muncie, IN',
  'Muskegon, MI',
  'Myrtle Beach-Conway-North Myrtle Beach, SC-NC',
  'Napa, CA',
  'Naples-Marco Island, FL',
  'New Bern, NC',
  'New York-Newark-Jersey City, NY-NJ',
  'Niles, MI',
  'Norwich-New London, CT',
  'Ocala, FL',
  'Ocean City, NJ',
  'Odessa, TX',
  'Olympia-Lacey-Tumwater, WA',
  'Oshkosh-Neenah, WI',
  'Owensboro, KY',
  'Panama City, FL',
  'Parkersburg-Vienna, WV',
  'Pensacola-Ferry Pass-Brent, FL',
  'Peoria, IL',
  'Pine Bluff, AR',
  'Pittsfield, MA',
  'Pocatello, ID',
  'Port St. Lucie, FL',
  'Portland-South Portland, ME',
  'Prescott Valley-Prescott, AZ',
  'Pueblo, CO',
  'Punta Gorda, FL',
  'Racine, WI',
  'Rapid City, SD',
  'Reading, PA',
  'Redding, CA',
  'Reno, NV',
  'Roanoke, VA',
  'Rochester, MN',
  'Rockford, IL',
  'Rocky Mount, NC',
  'Rome, GA',
  'Saginaw, MI',
  'Salem, OR',
  'Salinas, CA',
  'Salisbury, MD-DE',
  'San Angelo, TX',
  'San Luis Obispo-Paso Robles, CA',
  'Santa Cruz-Watsonville, CA',
  'Santa Fe, NM',
  'Santa Maria-Santa Barbara, CA',
  'Santa Rosa-Petaluma, CA',
  'Savannah, GA',
  'Scranton–Wilkes-Barre, PA',
  'Sebastian-Vero Beach, FL',
  'Sebring-Avon Park, FL',
  'Sheboygan, WI',
  'Sherman-Denison, TX',
  'Shreveport-Bossier City, LA',
  'Sierra Vista-Douglas, AZ',
  'Sioux City, IA-NE-SD',
  'Sioux Falls, SD',
  'South Bend-Mishawaka, IN-MI',
  'Spartanburg, SC',
  'Springfield, IL',
  'Springfield, MO',
  'Springfield, OH',
  'St. Cloud, MN',
  'St. George, UT',
  'St. Joseph, MO-KS',
  'State College, PA',
  'Staunton, VA',
  'Sumter, SC',
  'Tallahassee, FL',
  'Terre Haute, IN',
  'Texarkana, TX-AR',
  'The Villages, FL',
  'Topeka, KS',
  'Trenton-Princeton, NJ',
  'Tuscaloosa, AL',
  'Twin Falls, ID',
  'Tyler, TX',
  'Utica-Rome, NY',
  'Valdosta, GA',
  'Vallejo, CA',
  'Victoria, TX',
  'Vineland-Bridgeton, NJ',
  'Visalia, CA',
  'Waco, TX',
  'Walla Walla, WA',
  'Warner Robins, GA',
  'Waterloo-Cedar Falls, IA',
  'Watertown-Fort Drum, NY',
  'Wausau-Weston, WI',
  'Weirton-Steubenville, WV-OH',
  'Wenatchee, WA',
  'Wheeling, WV-OH',
  'Wichita Falls, TX',
  'Williamsport, PA',
  'Wilmington, NC',
  'Winchester, VA-WV',
  'Yakima, WA',
  'York-Hanover, PA',
  'Youngstown-Warren-Boardman, OH-PA',
  'Yuba City, CA',
  'Yuma, AZ',
];

export default msaNames;
