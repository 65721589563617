import { gql } from '@apollo/client';

const UPDATE_INVESTORS = gql`
  mutation updateInvestorMatches($params: UpdateInvestorMatchesParams!) {
    updateInvestorMatches(params: $params) {
      moreMatches
    }
  }
`;

export default UPDATE_INVESTORS;
