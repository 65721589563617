import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingScoreRubric from '@biproxi/models/objects/ListingScoreRubric.object';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { KB_LISTING_SCORES_URL } from '@biproxi/models/externalLinks';
import Flex from '../../elements/Flex';
import ScoreCard from '../ScoreCard';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import Colors from '../../styles/Colors';
import CreateListingActions from './CreateListingActions';
import Text, { TextTypesEnum } from '../../elements/Text';
import ListingStatus from '../ListingStatus';
import LinkText from '../../elements/LinkText';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';

const ImageContainer = styled.div`
  height: 50px;
  width: 75px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  margin: 0px 8px 0px 0px;
`;

const OverflowContainer = styled.div`
  max-width: 200px;

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const AlignRightContainer = styled.div`
  margin-left: auto;
`;

type ListingScoreCardProps = {
  listing: IListingGraphQL;
};

const ListingScoreCard: React.FC<ListingScoreCardProps> = ({
  listing,
}) => {
  function ScoreCardHeader({ onIconClick }) {
    return (
      <>
        <Flex margin="0 0 24px">
          <ImageContainer>
            {listing?.media?.files[0]?.url ? (
              <Image
                src={listing.media.files[0].url}
                width={75}
                height={50}
                alt="listing-image"
              />
            ) : (
              <Skeleton height="100%" width="100%" />
            )}
          </ImageContainer>
          <Flex direction="column" justify="center" width="90%">
            <Flex>
              <ListingStatus listing={listing} changeable />
              <AlignRightContainer>
                <LinkText
                  type={TextTypesEnum.Medium12}
                  onClick={() => pushChangeListingStatusModal()}
                  margin="0 8px 0 0px"
                >
                  Edit status
                </LinkText>
              </AlignRightContainer>
            </Flex>
            <OverflowContainer id="Overflow container">
              {onIconClick && (
              <Icon
                icon={Icons.AngleUpRegular}
                onClick={() => onIconClick?.()}
                position="absolute"
                right="12px"
                size={16}
                color={Colors.Grey700}
                rotation={180}
              />
              )}
              <Text
                type={TextTypesEnum.Bold18}
                color={Colors.Black}
              >
                {ListingUtil.name(listing, { allButAddress1: true }) ?? '--'}
              </Text>
            </OverflowContainer>
          </Flex>
        </Flex>
        <CreateListingActions />
      </>
    );
  }

  /** Actions */
  const dispatch = useAppDispatch();

  const pushChangeListingStatusModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeListingStatus,
      props: {
        listing,
      },
    }),
  );

  /** Render */
  return (
    <ScoreCard
      Header={ScoreCardHeader}
      rubric={ListingScoreRubric}
      title="Listing Score"
      subtitle="The listing score is based on the completeness of the information submitted. Higher scores show up higher in search results."
      learnMoreLink={KB_LISTING_SCORES_URL}
      objectToScore={listing}
      Actions={CreateListingActions}
    />
  );
};

export default ListingScoreCard;
