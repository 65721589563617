import React from 'react';
import styled from '@emotion/styled';
import { Outlet, useNavigate } from 'react-router-dom';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from './CenteredPageLayout';
import Colors from '../styles/Colors';
import { media } from '../utils/MediaQuery';
import useUser from '../hooks/useUser.hook';

type SuperAdminLayoutProps = {};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
`;

const PageContainer = styled.div`
  position: relative;
  width: 100%;

  ${media.mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div<{isSearch: boolean;}>`
  width: fill-available;
  height: fit-content;

  ${media.mobile} {
    padding: ${(props) => (props.isSearch ? '0' : '24px 16px')};
  }
`;

const SuperAdminLayout: React.FC<SuperAdminLayoutProps> = () => {
  /** Hooks */
  const { user } = useUser();
  const navigate = useNavigate();

  /**
   * Effects
   *
   * Redirect away from SuperUser
   * if user is not a super user
   *
   */

  React.useEffect(() => {
    if (user && !user.isSuperUser) {
      navigate('/app/dashboard/home');
    }
  }, [user]);
  /* Render */
  return (
    <Container>
      <PageContainer>
        <CenteredPageLayout
          size={CenteredPageLayoutSizeEnum.Regular}
          background={Colors.White}
        >
          <ContentContainer isSearch={false}>
            <Outlet />
          </ContentContainer>
        </CenteredPageLayout>
      </PageContainer>
    </Container>
  );
};

export default SuperAdminLayout;
