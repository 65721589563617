import CherreAssetClassesEnum from '@biproxi/models/enums/CherreAssetClassesEnum';
import CherrePropertyOwnerTypesEnum from '@biproxi/models/enums/CherrePropertyOwnerTypeEnum';
import CherrePropertyTypesEnum from '@biproxi/models/enums/CherrePropertyTypesEnum';
import CherrePropertyUnmaskedOwnerTypesEnum from '@biproxi/models/enums/CherrePropertyUnmaskedOwnerTypesEnum';
import DataExplorerPropertyFiltersEnum from '@biproxi/models/enums/DataExplorerPropertyFiltersEnum';
import IAddress from '@biproxi/models/interfaces/IAddress';
import IRangeInput from '@biproxi/models/interfaces/IRangeInput';
import { atom, selector } from 'recoil';
import PropertyFilterSectionsEnum from '../../../@types/utility-bar-types/enums/PropertyFilterSectionsEnum';

export interface IBasicDataExplorerFilterState {
    expandedSection: DataExplorerPropertyFiltersEnum
    selectedSections: DataExplorerPropertyFiltersEnum[]
    fetchFilterProperties: boolean
}

export interface IPropertyTypeFilters {
    expandedPropertyType: CherrePropertyTypesEnum
    selectedAssetClasses: CherrePropertyTypesEnum[]
    selectedPropertyTypes: CherreAssetClassesEnum[];
}

export interface ILotSizeFilters {
    lotSizeSqftRange: IRangeInput
    lotSizeAcreRange: IRangeInput
    grossAreaSqftRange: IRangeInput
}

export interface IBuildingDetailsFilters {
    buildingAreaSqftRange: IRangeInput
    yearBuiltRange: IRangeInput
}

export interface IOwnerFilters {
    ownerName: string
    ownerType: CherrePropertyOwnerTypesEnum
    unmaskedOwnerName: string
    unmaskedOwnerType: CherrePropertyUnmaskedOwnerTypesEnum
}

export interface ITransactionFilters {
    lastSaleAmountRange: IRangeInput
    lastSalePeriodRange: IRangeInput
}

export interface IMortgageFilters {
    lastMortgageAmountRange: IRangeInput
    lastMortgagePeriodRange: IRangeInput
    lastMortgageLender: string
    lastMortgageBorrower: string
}

export interface ITaxFilters {
    taxAmountRange: IRangeInput
    assessedPropertyValueRange: IRangeInput
    marketPropertyValueRange: IRangeInput
}

export interface IDemographicsFilters {
    crimeRiskIndexRange: IRangeInput
    medianHouseholdIncomeRange: IRangeInput
    medianPopulationAgeRange: IRangeInput
}

export interface IMSAFilter {
  msaNamesList: string[];
}

export interface IZipFilter {
  zipCodesList: string[];
}
export interface ICityStateFilter {
  city: string[];
  state: string[];
}

/** Property type filters */
export const dataExplorerPropertyTypeFiltersAtom = atom({
  key: 'propertyTypeFiltersState',
  default: {
    expandedPropertyType: null,
    selectedAssetClasses: null,
    selectedPropertyTypes: null,
  } as IPropertyTypeFilters,
});

/** Lot size filters */
export const dataExplorerLotSizeFiltersAtom = atom({
  key: 'lotSizeFiltersState',
  default: {
    lotSizeSqftRange: null,
    lotSizeAcreRange: null,
    grossAreaSqftRange: null,
  } as ILotSizeFilters,
});

/** Building details filters */
export const dataExplorerBuildingDetailsFiltersAtom = atom({
  key: 'buildingDetailsFiltersState',
  default: {
    buildingAreaSqftRange: null,
    yearBuiltRange: null,
  } as IBuildingDetailsFilters,
});

/** Owner filters */
export const dataExplorerOwnerFiltersAtom = atom({
  key: 'ownerFiltersState',
  default: {
    ownerName: null,
    ownerType: null,
    unmaskedOwnerName: null,
    unmaskedOwnerType: null,
  } as IOwnerFilters,
});

/** Transaction filters */
export const dataExplorerTransactionFiltersAtom = atom({
  key: 'transactionFiltersState',
  default: {
    lastSaleAmountRange: null,
    lastSalePeriodRange: null,
  } as ITransactionFilters,
});

/** Mortgage filters */
export const dataExplorerMortgageFiltersAtom = atom({
  key: 'mortgageFiltersState',
  default: {
    lastMortgageAmountRange: null,
    lastMortgagePeriodRange: null,
    lastMortgageLender: null,
    lastMortgageBorrower: null,
  } as IMortgageFilters,
});

/** Tax filters */
export const dataExplorerTaxFiltersAtom = atom({
  key: 'taxFiltersState',
  default: {
    taxAmountRange: null,
    assessedPropertyValueRange: null,
    marketPropertyValueRange: null,
  } as ITaxFilters,
});

/** Demographics filters */
export const dataExplorerDemographicsFiltersAtom = atom({
  key: 'demographicsFiltersState',
  default: {
    crimeRiskIndexRange: null,
    medianHouseholdIncomeRange: null,
    medianPopulationAgeRange: null,
  } as IDemographicsFilters,
});

/** Address filters */
export const dataExplorerAddressFiltersAtom = atom({
  key: 'addressFiltersState',
  default: {
    city: null,
    state: null,
    zip: null,
  } as Partial<IAddress>,
});

/** MSA filter */
export const dataExplorerMSAFilterStateAtom = atom({
  key: 'dataExplorerMSAFilterState',
  default: {
    msaNamesList: null,
  } as IMSAFilter,
});

/** Zip filter */
export const dataExplorerZipFilterStateAtom = atom({
  key: 'dataExplorerZipFilterState',
  default: {
    zipCodesList: null,
  } as IZipFilter,
});

/** City State Filter */
export const dataExplorerCityStateFilterStateAtom = atom({
  key: 'dataExplorerCityStateFilterState',
  default: {
    city: null,
    state: null,
    // zip: null,
  } as ICityStateFilter,
});

/** Basic state */
export const dataExplorerBasicFilterStateAtom = atom({
  key: 'dataExplorerBasicFilterState',
  default: {
    expandedSection: DataExplorerPropertyFiltersEnum.PropertyType,
    selectedSections: null,
    fetchFilterProperties: null,
  } as IBasicDataExplorerFilterState,
});

export const cachedCherrePropertyData = atom({
  key: 'cachedCherrePropertyData',
  default: null,
});

// export const dataExplorerAtomsFamily = atomFamily\

export const getAllDataExplorerFilterState = selector({
  key: 'dataExplorerFilterState',
  get: ({ get }) => ({
    [PropertyFilterSectionsEnum.PropertyType]: {
      selectedAssetClasses: get(dataExplorerPropertyTypeFiltersAtom).selectedAssetClasses,
      selectedPropertyTypes: get(dataExplorerPropertyTypeFiltersAtom).selectedPropertyTypes,
    },
    [PropertyFilterSectionsEnum.LotSize]: {
      lotSizeSqftRange: get(dataExplorerLotSizeFiltersAtom).lotSizeSqftRange,
      lotSizeAcreRange: get(dataExplorerLotSizeFiltersAtom).lotSizeAcreRange,
      grossAreaSqftRange: get(dataExplorerLotSizeFiltersAtom).grossAreaSqftRange,
    },
    [PropertyFilterSectionsEnum.BuildingDetails]: {
      buildingAreaSqftRange: get(dataExplorerBuildingDetailsFiltersAtom).buildingAreaSqftRange,
      yearBuiltRange: {
        min: parseInt(get(dataExplorerBuildingDetailsFiltersAtom)?.yearBuiltRange?.min, 10),
        max: parseInt(get(dataExplorerBuildingDetailsFiltersAtom)?.yearBuiltRange?.max, 10),
      },
    },
    [PropertyFilterSectionsEnum.Owner]: {
      ownerName: get(dataExplorerOwnerFiltersAtom).ownerName,
      ownerType: get(dataExplorerOwnerFiltersAtom).ownerType,
      unmaskedOwnerName: get(dataExplorerOwnerFiltersAtom).unmaskedOwnerName,
      unmaskedOwnerType: get(dataExplorerOwnerFiltersAtom).unmaskedOwnerType,
    },
    [PropertyFilterSectionsEnum.Transactions]: {
      lastSaleAmountRange: get(dataExplorerTransactionFiltersAtom).lastSaleAmountRange,
      lastSalePeriodRange: get(dataExplorerTransactionFiltersAtom).lastSalePeriodRange,
    },
    [PropertyFilterSectionsEnum.Taxes]: {
      taxAmountRange: get(dataExplorerTaxFiltersAtom).taxAmountRange,
      assessedPropertyValueRange: get(dataExplorerTaxFiltersAtom).assessedPropertyValueRange,
      marketPropertyValueRange: get(dataExplorerTaxFiltersAtom).marketPropertyValueRange,
    },
    [PropertyFilterSectionsEnum.Demographics]: {
      crimeRiskIndexRange: get(dataExplorerDemographicsFiltersAtom).crimeRiskIndexRange,
      medianHouseholdIncomeRange: get(dataExplorerDemographicsFiltersAtom).medianHouseholdIncomeRange,
      medianPopulationAgeRange: get(dataExplorerDemographicsFiltersAtom).medianPopulationAgeRange,
    },
    [PropertyFilterSectionsEnum.Address]: {
      city: get(dataExplorerAddressFiltersAtom).city,
      state: get(dataExplorerAddressFiltersAtom).state,
      zip: get(dataExplorerAddressFiltersAtom).zip,
    },
    [PropertyFilterSectionsEnum.MSA]: {
      msaName: get(dataExplorerMSAFilterStateAtom).msaNamesList,
    },
    [PropertyFilterSectionsEnum.Zip]: {
      zipCode: get(dataExplorerZipFilterStateAtom).zipCodesList,
    },
    [PropertyFilterSectionsEnum.CityState]: {
      city: get(dataExplorerCityStateFilterStateAtom).city,
      state: get(dataExplorerCityStateFilterStateAtom).state,
    },
  }),
});
