import gql from 'graphql-tag';

const SET_FILE_ACCESS_LEVEL = gql`
  mutation setFileAccessLevel($params: SetFileAccessLevelParams!) {
    setFileAccessLevel(params: $params) {
        accessLevel
    }
  }
`;

export default SET_FILE_ACCESS_LEVEL;
