enum MoodysCreditRatingEnum {
  AAA = 'AAA',
  AA1 = 'AA1',
  AA2 = 'AA2',
  AA3 = 'AA3',
  A1 = 'A1',
  BAA1 = 'BAA1',
  A2 = 'A2',
  BAA2 = 'BAA2',
  BAA3 = 'BAA3',
}

export default MoodysCreditRatingEnum;
