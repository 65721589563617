import gql from 'graphql-tag';

const CHERRE_TAX_IDS_FROM_PARCEL_IDS = gql`
    query CherreTaxIdsFromParcelIds ($params: CherreTaxAssessorIdsFromParcelIdsParams!){
        cherreTaxIdsFromParcelIds(params: $params) {
            taxAssessorIds
        }
    }
`;

export default CHERRE_TAX_IDS_FROM_PARCEL_IDS;
