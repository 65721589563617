import React from 'react';
// import { GetServerSideProps } from 'next';
import {
  useNavigate,
  // useParams,
} from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery as rqQuery } from 'react-query';
import { getOrganization } from '@biproxi/next-api-requests';
import { TeamPermissionsEnum } from '@biproxi/models/enums/PermissionsEnum';
import NextPageContainer from '../containers/NextPage.container';
import CenteredPageLayout, {
  CenteredPageLayoutSizeEnum,
} from './CenteredPageLayout';
import Colors from '../styles/Colors';
import { media } from '../utils/MediaQuery';
import Loader, { LoaderSizes } from '../elements/Loader';
import useUser from '../hooks/useUser.hook';
import TeamPageListingsTable from './teams/TeamPageListingsTable';
import useUserPermissions, { IUserOrganizationData } from '../hooks/useUserPermissions.hook';
import PageError from './PageError';
import { Icons } from '../elements/Icon';
import { UserSelectors } from '../redux/user.redux';
import { useAppSelector } from '../redux/store';

// TODO: Mobile on this page needs fixing, its passable but kinda whack
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  flex: 1;
  ${media.mobile} {
    padding: 8px;
     margin: 0 auto;
     top: 56px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${media.mobile} {
    padding: 8px 0 0 0;
    width: 100%;
  }
`;

const TeamListingsContainer = styled.div<{ 'data-cy': string; }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  data-cy: ${(props) => props['data-cy']};
  ${media.mobile} {
    padding: 8px 0 0 0;
  }
`;

const LoadingContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TeamPageProps = {};

const TeamPage: React.FC<TeamPageProps> = () => {
  /** Hooks */
  const { userId } = useUser();
  const { userOrganizationsRolesAndPermissions, loading } = useUserPermissions({ userId });
  const navigate = useNavigate();

  /** State */
  const currentOrganizationContext = useAppSelector(UserSelectors.currentOrganizationContext);
  const teamId = currentOrganizationContext?.orgId;

  const [team, setTeam] = React.useState(null);
  const [teamUsers, setTeamUsers] = React.useState(null);
  const [teamListings, setTeamListings] = React.useState(null);
  const [authLoading, setAuthLoading] = React.useState(true);
  const [unAuthed, setUnAuthed] = React.useState(false);

  const { data, refetch, isLoading } = rqQuery(['neo4j:organization', teamId], () => getOrganization({
    organizationId: teamId,
  }), {
    onSuccess: (successData) => {
      const team = successData?.data;
      setTeam(team?.data);
      setTeamUsers(team?.data?.usersConnection?.edges?.reverse()); // reverse list because the list comes back in newest - oldest, but we want oldest - newest
      setTeamListings(team?.data?.listingsConnection?.edges);
    },
    enabled: Boolean(teamId?.length),
    cacheTime: 0,
  });

  /** Effects */
  React.useEffect(() => {
    if (teamUsers?.length && userId) {
      const teamUserIds: string[] = teamUsers?.map((user) => user?.node?._id);
      if (!teamUserIds?.includes(userId)) {
        setAuthLoading(false);
        setUnAuthed(true);
      }
      setAuthLoading(false);
    }
  }, [teamUsers]);

  /** Render */
  if (!teamId || isLoading || loading || !data || authLoading) {
    return (
      <NextPageContainer>
        <CenteredPageLayout
          size={CenteredPageLayoutSizeEnum.Small}
          background={Colors.White}
          dataCy="team-page-wrapper"
        >
          <LoadingContainer>
            <Loader
              size={LoaderSizes.Large}
              color={Colors.Brand700 || Colors.Blurple700}
            />
          </LoadingContainer>
        </CenteredPageLayout>
      </NextPageContainer>
    );
  }

  if (unAuthed) {
    return (
      <PageError
        icon={Icons.TimesRegular}
        header="You're not authorized to be here"
        body="We're sorry, but you are not allowed to view this page. Let's get you back to Biproxi."
        onClick={() => navigate('/app/dashboard/search')}
      />
    );
  }

  const userTeamPermissions: TeamPermissionsEnum[] = userOrganizationsRolesAndPermissions?.filter((team: IUserOrganizationData) => team?.orgId === teamId)?.[0]?.permissions;
  return (
    <>
      <NextPageContainer>
        <CenteredPageLayout
          size={CenteredPageLayoutSizeEnum.FullScreen}
          background={Colors.White}
          dataCy="team-page-wrapper"
        >
          <PageContainer>
            {/* {!isMobile && (
              <>
                <TeamPageHeader>

                  <Text
                    type={TextTypesEnum.Bold24}
                    color={Colors.Grey900}
                    data-cy="team-name-header"
                  >
                    {team?.name}
                  </Text>
                  <Text
                    type={TextTypesEnum.Regular14}
                    color={Colors.Grey900}
                    data-cy="team-description-header"
                  >
                    {team?.description}
                  </Text>
                </TeamPageHeader>
              </>
            )} */}
            <ContentContainer>
              <TeamListingsContainer
                data-cy="team-listings-section-wrapper"
              >
                <TeamPageListingsTable
                  team={team}
                  listings={teamListings}
                  userPermissions={userTeamPermissions}
                  refetch={refetch}
                />
              </TeamListingsContainer>
            </ContentContainer>
          </PageContainer>
        </CenteredPageLayout>
      </NextPageContainer>
    </>
  );
};

export default TeamPage;
