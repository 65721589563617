import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';
import IDataExplorerCherrePropertyData from '@biproxi/models/interfaces/IDataExplorerCherrePropertyData';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from '@apollo/client';
import FILTERED_PROPERTIES from '../../../graphql/queries/dataExplorerFilteredProperties.query';
import PaginationLimitsEnum from '../../../models/enums/PaginationLimitsEnum';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import Divider from '../../../elements/Divider';
import { LoadMoreWhenInView, useInViewOptions } from '../../InfiniteScroll';
import { useAppDispatch } from '../../../redux/store';
import Loader from '../../../elements/Loader';
import Flex from '../../../elements/Flex';
import Icon, { Icons } from '../../../elements/Icon';
import { media } from '../../../utils/MediaQuery';
import { IToastConfig, ToastTypesEnum } from '../../Toast';
import { AppActions } from '../../../redux/app.redux';
import dataExplorerUtilityBarAtom, { utilityBarFamilyState } from '../state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import { getAllDataExplorerFilterState } from '../state-management/recoil/atoms/DataExplorerFiltersAtoms';

type ContainerProps = {
  position?: string,
  top?: string,
  right?: string,
  bottom?: string,
  left?: string,
}
const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 400px;
    max-height: 556px;
    border-radius: 4px;
    background-color: ${Colors.White};
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    z-index: 9;
    position: ${({ position }) => position};
    top: 64px;
    left: 16px;
    top: ${({ top }) => top};
    right: ${({ right }) => right};
    bottom: ${({ bottom }) => bottom};
    left: ${({ left }) => left};

    ${media.mobile} {
      height: 556px;
      width: calc(100vw - 32px);
      top: 100px;
    }
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfiniteScrollLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 8px 16px;
  cursor: pointer;
`;

type CherreFilterPropertySectionProps = {
    property: IDataExplorerCherrePropertyData
    setDataExplorerSearchAddress?:(address: Partial<IAddress>) => void;
}

const CherreFilterPropertySection: React.FC<CherreFilterPropertySectionProps> = ({
  property,
  setDataExplorerSearchAddress,
}) => {
  const propertyAddress = property?.address;
  const propertyCity = property?.city?.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
  const propertyState = property?.state;
  const propertyZip = property?.zip;
  const propertyGroupType = property?.property_group_type;
  const propertySquareFeet = StringUtil.formatNumber(property?.lot_size_sq_ft);
  const propertyLastSaleAmount = property?.last_sale_amount;

  /** actions */
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const handlePropertySectionClick = () => {
    if (property?.one_line_address && property?.one_line_address?.length) {
      const address = AddressUtil.parseOneLineAddress(property.one_line_address);
      address.location = {
        type: 'Point',
        coordinates: [property?.longitude?.toString(), property?.latitude?.toString()],
      };
      if (!property?.longitude && !property?.latitude) {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'This property has incomplete data. Please select another.',
        });
      } else {
        setDataExplorerSearchAddress(address);
      }
    }
  };
  return (
    <SectionContainer onClick={() => handlePropertySectionClick()}>
      <Text type={TextTypesEnum.Bold14}>{propertyAddress ? propertyAddress.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ') : 'No address'}</Text>
      <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
        {propertyCity?.length && (
          <>
            {propertyCity}
            {', '}
          </>
        )}

        {propertyState}
        {' '}
        {propertyZip}
      </Text>
      <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
        {propertyGroupType}
        {(propertySquareFeet?.length && !(propertySquareFeet?.[0] === '0')) ? (
          <>
            {' '}
            -
            {' '}
            {propertySquareFeet}
            {' '}
            sqft
          </>
        ) : null}
      </Text>
      {propertyLastSaleAmount > 0 && (
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Last sell -
          {' '}
          $
          {StringUtil.formatNumber(propertyLastSaleAmount)}
        </Text>
      )}
    </SectionContainer>
  );
};

type DataExplorerFilterPropertyListStyleProps = {
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

type ListingFilterPropertyListProps = {
  styleProps?: DataExplorerFilterPropertyListStyleProps;
}

const DataExplorerFilteredPropertyList: React.FC<ListingFilterPropertyListProps> = ({
  styleProps = {
    position: 'absolute',
    top: '64px',
    right: '0',
    bottom: '0',
    left: '16px',
  },
}) => {
  /** State */
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);
  const [propertyData, setPropertyData] = React.useState(null);

  const [_, setSpecificAddressSearch] = useRecoilState(utilityBarFamilyState('specificAddressSearch')); const [utilityBarState, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);
  const dataExplorerFilterState = useRecoilValue(getAllDataExplorerFilterState);

  const { loading: filteredPropertiesLoading, fetchMore } = useQuery(FILTERED_PROPERTIES, {
    skip: !utilityBarState?.fetchFilterProperties,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      params: {
        dataExplorerFilters: dataExplorerFilterState,
        pagination: {
          limit: PaginationLimitsEnum.DataExplorerProperties,
          offset: 0,
        },
      },
    },
    onCompleted: (data) => {
      setPropertyData(data?.dataExplorerFilterProperties?.properties);
      setUtilityBarState((prevValue) => ({
        ...prevValue,
        fetchFilterProperties: false,
      }));
    },
  });

  /** Hooks */
  const [ref, inView] = useInView(useInViewOptions);

  React.useEffect(() => {
    const getData = async () => {
      setInfiniteLoading(true);
      setUtilityBarState((prevValue) => ({
        ...prevValue,
        fetchFilterProperties: true,
      }));
      await fetchMore({
        variables: {
          params: {
            pagination: {
              limit: PaginationLimitsEnum.DataExplorerProperties,
              offset: propertyData?.length,
            },
            dataExplorerFilters: dataExplorerFilterState,
          },
        },
      });
      setInfiniteLoading(false);
    };
    getData();
  }, [inView]);

  return (
    <Container
      position={styleProps.position}
      top={styleProps.top}
      right={styleProps.right}
      bottom={styleProps.bottom}
      left={styleProps.left}
      data-cy="FilteredPropertyListContainer"
    >
      {filteredPropertiesLoading || utilityBarState?.fetchFilterProperties ? (
        <LoaderContainer>
          <Loader color={Colors.Brand800 || Colors.Blurple800} />
        </LoaderContainer>
      ) : (
        <Flex direction="column">
          <Flex justify="space-between">
            <Text type={TextTypesEnum.Bold14} margin="8px 16px">
              {propertyData?.length}
              {' '}
              Properties
            </Text>
            <Icon
              data-cy="CloseFilteredPropertiesList"
              icon={Icons.TimesRegular}
              color={Colors.Grey600}
              onClick={() => setUtilityBarState((prevState) => ({
                ...prevState,
                showFilterPropertyList: false,
              }))}
              margin="0 16px"
            />
          </Flex>
          <Divider />
          <Flex direction="column" data-cy="FilteredPropertyListProperties">
            {propertyData?.map((property: IDataExplorerCherrePropertyData, index: number) => (
              <React.Fragment key={index}>
                <CherreFilterPropertySection
                  property={property}
                  setDataExplorerSearchAddress={setSpecificAddressSearch}
                />
                {index !== propertyData?.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Flex>
          <LoadMoreWhenInView ref={ref} />
          {infiniteLoading && (
            <InfiniteScrollLoaderContainer>
              <Loader data-cy="filtered-property-list-container" color={Colors.Brand800 || Colors.Blurple800} />
            </InfiniteScrollLoaderContainer>
          )}
        </Flex>
      )}

    </Container>
  );
};

export default DataExplorerFilteredPropertyList;
