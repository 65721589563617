import React from 'react';
import styled from '@emotion/styled';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import { KB_PRIVATE_EVENT_USE_URL } from '@biproxi/models/externalLinks';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';
import LinkText from '../../elements/LinkText';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

type PrivateEventQuickDisplayInfoProps = {
  privateEvent: IPrivateEventGraphQL;
};

const PrivateEventQuickDisplayInfo: React.FC<PrivateEventQuickDisplayInfoProps> = ({
  privateEvent,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const viewPSA = () => dispatch(
    AppActions.previewFiles({
      fileId: privateEvent?.psaFileIds?.[0],
      files: privateEvent?.psaFiles,
      track: false,
    }),
  );

  const viewAdditionalTerms = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.DisplayTextBlock,
        props: {
          title: 'Additional terms',
          text: privateEvent?.contingencies?.additionalTerms,
        },
      }),
    );
  };

  return (
    <>
      <Divider margin="16px 0" />
      <Text color={Colors.Black} type={TextTypesEnum.Bold16} margin="0 0 4px">
        Bid overview
      </Text>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Total bids
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {PrivateEventUtil.getTotalBids(privateEvent) || '--'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Bidders
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small} data-cy="private-event-bidders-count">
          {PrivateEventUtil.getTotalBidders(privateEvent) || '--'}
        </Text>
      </Row>
      <Divider margin="16px 0" />
      <Text color={Colors.Black} type={TextTypesEnum.Bold16} margin="0 0 4px">
        Contingencies
      </Text>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Diligence period
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.contingencies?.dueDiligencePeriod ? `${privateEvent.contingencies.dueDiligencePeriod} days` : '--'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Closing period
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.contingencies?.closingPeriod ? `${privateEvent.contingencies.closingPeriod} days` : '--'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Earnest money deposit
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small} data-cy="private-event-earnest-money-deposit">
          {privateEvent?.contingencies?.depositAmount ? `$${StringUtil.formatNumber(privateEvent.contingencies.depositAmount)}` : '--'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Additional terms
        </Text>
        {privateEvent?.contingencies?.additionalTerms ? (
          <LinkText
            type={TextTypesEnum.Regular14Small}
            onClick={viewAdditionalTerms}
          >
            View
          </LinkText>
        ) : (
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            --
          </Text>
        )}
      </Row>
      {privateEvent?.psaFiles?.length > 0 && (
        <Row>
          <LinkText
            type={TextTypesEnum.Regular14Small}
            onClick={viewPSA}
          >
            View PSA
          </LinkText>
        </Row>
      )}
      <Divider margin="16px 0" />
      <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
        For more information on how private events work,
        &nbsp;
        <Text
          type={TextTypesEnum.Regular14}
          as={TextAsEnum.Span}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => window.open(KB_PRIVATE_EVENT_USE_URL, '_blank')}
        >
          click here.
        </Text>
      </Text>
    </>
  );
};

export default PrivateEventQuickDisplayInfo;
