import { gql } from '@apollo/client';
import PrivateEventFragments from '../fragments/privateEvent.fragments';

const CREATE_PRIVATE_EVENT = gql`
  mutation CreatePrivateEvent($params: PrivateEventParams!) {
    createPrivateEvent(params: $params) { 
      ...PrivateEventFields
      ...PrivateEventPSAFiles
      ...PrivateEventParticipants
      ...PrivateEventBids
      ...PrivateEventSettings
      ...PrivateEventContingencies
      ...PrivateEventMeta
      ...PrivateEventListing 
    }
  }

  ${PrivateEventFragments.fields}
  ${PrivateEventFragments.psaFiles}
  ${PrivateEventFragments.participants}
  ${PrivateEventFragments.bids}
  ${PrivateEventFragments.settings}
  ${PrivateEventFragments.contingencies}
  ${PrivateEventFragments.meta}
  ${PrivateEventFragments.listing}
`;

export default CREATE_PRIVATE_EVENT;
