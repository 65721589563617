import { gql } from '@apollo/client';

const RESEND_PRIVATE_EVENT_INVITE_EMAIL = gql`
  mutation ResendPrivateEventInviteEmail($params: ResendPrivateEventInviteEmailParams!) {
    resendPrivateEventInviteEmail(params: $params) {
      _id
    }
  }
`;

export default RESEND_PRIVATE_EVENT_INVITE_EMAIL;
