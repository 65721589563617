import React from 'react';
import styled from '@emotion/styled';
import IAddress from '@biproxi/models/interfaces/IAddress';
import LinkText from '../elements/LinkText';
import AutoCompleteAddress from './AutoCompleteAddress';
import ManualAddress from './ManualAddress';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';

const Container = styled.div<{ width?: string }>`
  position: relative;
`;

export type AddressProps = {
  label?: string;
  id?: string;
  dataCy?: string;
  value?: IAddress;
  onChange?: (address: IAddress) => void;
  errors?: Record<string, string>;
  fullAddressesOnly?: boolean;
  isPortfolioInput?: boolean;
  onManualChange?: (address: IAddress) => void;
  onManualComplete?: () => void;
};

enum AddressInputTypesEnum {
  Auto = 'Auto',
  Manual = 'Manual',
}

const Address: React.FC<AddressProps> = ({
  label,
  id,
  dataCy,
  value,
  onChange,
  errors,
  fullAddressesOnly = false,
  isPortfolioInput,
  onManualChange,
  onManualComplete,
}) => {
  const [addressInputType, setAddressInputType] = React.useState(AddressInputTypesEnum.Auto);
  const [rendered, setRendered] = React.useState(false);
  const [googlePlaceId, setGooglePlaceId] = React.useState<string>('');

  // On initial render after value has loaded,
  // If there is an address, determine if it is a manual
  // or autocomplete address and display the correct input.
  // The Autocomplete address is the default
  React.useEffect(() => {
    if (!rendered && value) {
      setRendered(true);
      if (value?.address1 && !value?.googlePlaceId) {
        setAddressInputType(AddressInputTypesEnum.Manual);
      }
    }
  }, [value]);

  /**
   * Save the Google Place ID if it exists on the address.
   * Update the Google Place ID any time it changes as
   * long as it is not empty.
   *
   * When we switch from auto-complete address to manual
   * input, we clear the Google Place ID. If we switch back
   * to auto-complete, we add the Google Place ID back
   * to the address object.
   */

  React.useEffect(() => {
    if (value?.googlePlaceId && value.googlePlaceId !== googlePlaceId) {
      setGooglePlaceId(value.googlePlaceId);
    }
  }, [value?.googlePlaceId]);

  const isAuto = addressInputType === AddressInputTypesEnum.Auto;

  /** Render */
  return (
    <Container id={id}>
      {isAuto ? (
        <AutoCompleteAddress
          dataCy={dataCy}
          label={label}
          value={value}
          onChange={onChange}
          showUnit={!isPortfolioInput}
          /**
           * Pass the first error in the errors object
           * as a string
           */
          error={errors?.[Object.keys(errors)?.[0]] ?? null}
          fullAddressesOnly={fullAddressesOnly}
        />
      ) : (
        <ManualAddress
          value={value}
          onChange={onManualChange || onChange}
          isPortfolioInput={isPortfolioInput}
          onManualComplete={onManualComplete}
          /**
           * Pass the entire errors object
           */
          errors={errors}
        />
      )}
      <Flex>
        {isAuto && <Text color={Colors.Grey700} type={TextTypesEnum.Regular12} margin="4px 8px 0px 0px">Address not showing up?</Text>}
        <LinkText
          margin="4px 0 0"
          type={TextTypesEnum.Medium12}
          onClick={() => {
            /**
           * Set the Google Place ID to the value currently
           * held in state when switching from manual to auto-complete.
           *
           * Clear the Google Place ID when switching from
           * auto-complete to manual.
           */
            onChange({
              ...value,
              googlePlaceId: isAuto ? '' : googlePlaceId,
            });

            if (isAuto) {
              setAddressInputType(AddressInputTypesEnum.Manual);
            } else {
              setAddressInputType(AddressInputTypesEnum.Auto);
            }
          }}
        >
          {isAuto ? 'Enter address manually' : 'Enter address using autocomplete'}
        </LinkText>
      </Flex>
    </Container>
  );
};

export default Address;
