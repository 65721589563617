import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { ListingOverviewAnalyticsEnum } from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingOverviewChartDateFilterEnum from '@biproxi/models/enums/ListingOverviewChartDateFilterEnum';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icons from '../../elements/Icons';
import AnalyticsOverviewTabs from '../listing-details/overview/AnalyticsOverviewTabs';
import ListingViewsLineChart from '../listing-details/overview/ListingViewsLineChart';
import useUser from '../../hooks/useUser.hook';
import Divider, { DividerTypesEnum } from '../../elements/Divider';
import Colors from '../../styles/Colors';

const Container = styled.div`
  position: relative;
  max-width: calc(100vw - 32px);
  width: 100%;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 16px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  width: auto;
`;

const AnalyticChartTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 16px 0 24px 0;
  padding: 0px;
  position: static;
  width: 100%;
  border-radius: 8px;

  ${media.mobile} {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    justify-content: center;
    margin: 0px;

    > div {
      margin: 0 0 4px 0;


      &:last-of-type {
        margin-bottom: 0px;
      }

      > div {
        margin: 0;
      }
    }

  }

  ${media.tablet} {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    justify-content: center;

    > div {
      margin: 0 0 4px 0;


      &:last-of-type {
        margin-bottom: 0px;
      }

      > div {
        margin: 0;
      }
    }
  }
`;

const tabNames = {
  [ListingOverviewAnalyticsEnum.TotalListingViews]: 'Listing Views',
  [ListingOverviewAnalyticsEnum.TotalListingLeads]: 'New Leads',
  [ListingOverviewAnalyticsEnum.TotalCAsSigned]: 'CAs Signed',
};

enum DashboardOverviewAnalyticsEnum {
  TotalListingViews = 'totalListingViews',
  TotalListingLeads = 'totalListingLeads',
  TotalCAsSigned = 'totalCAsSigned'
}

enum DashboardOverviewAnalyticsTabIcons {
  TotalListingViews = 'BrowserRegular',
  TotalListingLeads = 'FireRegular',
  TotalCAsSigned = 'FileSignatureRegular',
}

type AccountListingViewsChartProps = {}

const AccountListingViewChart: React.FC<AccountListingViewsChartProps> = () => {
  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[],
    }
  }

  type Vars = {
    params: {
      query: IListingQuery
    }
  }
  const { data: userListingsData, loading: userListingsDataLoading } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Personal,
        },
      },
    },
  });

  /** Hooks */
  const { user } = useUser();
  const isMobile = useMobileMedia();

  /** State */
  const activeUserListingIds: string[] = userListingsData?.listings?.data
    ?.filter((listing) => listing?.state === 'Active')
    .map((listing) => listing?._id);

  const listingUserId: string = user?._id;

  /** Render */
  return (
    <Container>
      <Text type={TextTypesEnum.Bold18} margin="0 0 8px 0">In the last 7 days</Text>

      <ChartContainer>
        <AnalyticChartTabContainer>
          {Object.keys(DashboardOverviewAnalyticsEnum).map((tabAnalyticType: DashboardOverviewAnalyticsEnum, index: number) => {
            const analyticName = ListingOverviewAnalyticsEnum[tabAnalyticType];
            const tabName = tabNames[analyticName];
            const isLast = index === Object.keys(DashboardOverviewAnalyticsEnum).length - 1;
            return (
              <React.Fragment
                key={tabAnalyticType}
              >
                <AnalyticsOverviewTabs
                  analyticName={analyticName}
                  tabName={tabName}
                  icon={Icons[DashboardOverviewAnalyticsTabIcons[tabAnalyticType]]}
                  listingId={activeUserListingIds}
                  listingUserId={listingUserId}
                  filterValue={ListingOverviewChartDateFilterEnum.ThisWeek}
                  clickableTabs={false}
                />
                {!isLast && (
                <Divider
                  color={Colors.Grey200}
                  type={isMobile ? DividerTypesEnum.Horizontal : DividerTypesEnum.Vertical}
                  margin="0 16px"
                  top="50%"
                  verticalDividerHeight="48px"
                />
                )}
              </React.Fragment>
            );
          })}
        </AnalyticChartTabContainer>
        <Divider color={Colors.Grey200} />
        <ListingViewsLineChart
          listingId={activeUserListingIds}
          listingUserId={listingUserId}
          filterValue={ListingOverviewChartDateFilterEnum.ThisWeek}
          loading={userListingsDataLoading}
          boxShadow={false}
          showBorder={false}
          padding="0px"
        />
      </ChartContainer>
    </Container>
  );
};

export default AccountListingViewChart;
