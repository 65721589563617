import gql from 'graphql-tag';
import UserFragments from '../fragments/user.fragments';

const LIST_USERS = gql`
  query Users($params: ListUsersParams) {
    users(params: $params) {
      data {
        ...UserFields
        ...UserProfileImageFile
        ...UserLatestActivityEvent
        ...UserGeolocAddress
        ...UserMeta
      }
      info {
        totalCount
      }
    }
  }

  ${UserFragments.fields}
  ${UserFragments.profileImageFile}
  ${UserFragments.latestActivityEvent}
  ${UserFragments.geolocAddress}
  ${UserFragments.meta}
`;

export default LIST_USERS;
