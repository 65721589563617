import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import { Outlet, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { isEmpty } from 'lodash';
import AppUtil from '@biproxi/models/utils/AppUtil';
import DashboardSideNavigation from './DashboardSideNavigation';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from './CenteredPageLayout';
import Colors from '../styles/Colors';
import { AppActions } from '../redux/app.redux';
import { media } from '../utils/MediaQuery';
import { useAppSelector, AppState, useAppDispatch } from '../redux/store';
import useInitializeSendbird from '../hooks/useInitializeSendbird.hook';
import ChatOverlayLayout from './ChatOverlayLayout';
import useUser from '../hooks/useUser.hook';
import { ModalTypesEnum } from './modal/Modal';
import Auth from '../utils/Auth';

type DashboardLayoutProps = {};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
`;

const Spacer = styled.div`
  width: 60px;

  ${media.mobile} {
    display: none;
  }

  ${media.tablet} {
    display: none;
  }
`;

const PageContainer = styled.div`
  position: relative;
  width: calc(100% - 60px);

  ${media.mobile} {
    width: 100%;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

type ContentContainerProps = {
  pageSize?: CenteredPageLayoutSizeEnum
}

const ContentContainer = styled.div<ContentContainerProps>`
  width: fill-available;
  height: fit-content;
  max-width: ${({ pageSize }) => {
    switch (pageSize) {
      case CenteredPageLayoutSizeEnum.DataExplorer:
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '100vw';
      default:
        return 'calc(100vw - 124px)';
    }
  }};

  ${media.mobile} {
    max-width: 100vw;
  }

  ${media.tablet} {
    max-width: 100vw;
  }

  ${media.mobile} {
    padding: ${({ pageSize }) => {
    switch (pageSize) {
      case CenteredPageLayoutSizeEnum.DataExplorer:
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '0px';
      default:
        return '24px 16px';
    }
  }};
  }
`;

type DashboardSideNavContainerProps = {
  mobileSideNavOpen: boolean; // mobile
  isExpanded: boolean; // desktop
  dashboardColor?: string; // company's color theme
  loadingDashboardColor?: boolean; // loader color for fetch from Contentful
}

const DashboardSideNavContainer = styled.div<DashboardSideNavContainerProps>`
  position: fixed;
  width: ${(props) => (props.isExpanded ? '190px' : '60px')};
  z-index: 9999;
  background-color: ${(props) => (props.loadingDashboardColor ? Colors.Grey300 : props.dashboardColor)};
  height: calc(100vh - 56px);
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  ${media.mobile} {
    display: ${(props) => (props.mobileSideNavOpen ? 'block' : 'none')};
    position: fixed;
    background: ${Polished.rgba(Colors.Black, 0.4)};
    height: 100vh;
    margin-right: 0px;
    width: 100vw;
    top: 0;
  }

  ${media.tablet} {
    display: ${(props) => (props.mobileSideNavOpen ? 'block' : 'none')};
    position: fixed;
    background: ${Polished.rgba(Colors.Black, 0.4)};
    height: 100vh;
    margin-right: 0px;
    width: 100vw;
    top: 0;
  }
`;

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  const [loadingDashboardColor, setLoadingDashboardColor] = React.useState(true);

  /** Contentful State - Redux */
  const {
    contentful: {
      brandCollection: {
        colors,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const contentful = useAppSelector((state: AppState) => state.contentful);

  /**
   * ? We have to change these, a little confusing on which is which
   * ? the colors Blurple400 and Grey700 seem to piggyback off of the same Brand
   * ? colors, so like Blurple400 -> Brand400
   * ? and Grey400 -> Brand400 also?, consulted Aaron, waiting on fix
  */
  let dashboardColor = colors.BrandNavContainer || colors.Grey700;

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /* State */
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const location = useLocation();
  const { user } = useUser();

  let pageSize;
  switch (location.pathname) {
    case '/app/dashboard/search':
      pageSize = CenteredPageLayoutSizeEnum.FullScreen;
      break;
    case '/app/dashboard/data-explorer':
      pageSize = CenteredPageLayoutSizeEnum.DataExplorer;
      break;
    case '/app/dashboard/events/participate':
      pageSize = CenteredPageLayoutSizeEnum.Regular;
      break;
    case '/app/dashboard/home':
      pageSize = CenteredPageLayoutSizeEnum.Regular;
      break;
    default:
      pageSize = CenteredPageLayoutSizeEnum.ExtraSmall;
      /** start phasing in new dashboard page sizes, starting here */
      if (location.pathname.includes('/app/dashboard/listings/details')) {
        pageSize = CenteredPageLayoutSizeEnum.Regular;
      }
      break;
  }

  /* Actions */
  const dispatch = useAppDispatch();
  const { mobileSideNavOpen } = useAppSelector((state: AppState) => state.app);

  const closeMobileSideNav = () => dispatch(
    AppActions.toggleMobileSideNav(false),
  );

  /** Hooks */
  const mobileSideNavRef = React.useRef(null);
  useInitializeSendbird();

  const pushCompleteRegister = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CompleteRegister,
      props: {},
    }),
  );

  /** Effects */
  /**
   * This used to be in app.redux
   * but the client query for the user in getPageConfiguration is broken
   * so we do it here in the mean time.
   */
  React.useEffect(() => {
    if (user?.isDeactivated) {
      Auth.logout();
    }
    if (user && !UserUtil.phoneIsVerified(user) && AppUtil.isInDashboard(location.pathname)) {
      pushCompleteRegister();
    }
  }, [user]);

  /** Desktop view - DashboardNavColor */
  React.useEffect(() => {
    if (!isEmpty(contentful.brandCollection)) {
      dashboardColor = colors?.Grey700;
      setLoadingDashboardColor(false);
    } else {
      setTimeout(() => {
        setLoadingDashboardColor(false);
      }, 5000);
    }
  }, []);

  /* Render */
  return (
    <Container id="DashboardLayoutContainer">
      <ChatOverlayLayout />
      <DashboardSideNavContainer
        data-cy="dashboard-side-nav"
        isExpanded={isExpanded}
        dashboardColor={dashboardColor}
        loadingDashboardColor={loadingDashboardColor}
        mobileSideNavOpen={mobileSideNavOpen}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
        onClick={(event) => {
          if ((event.target instanceof Element)
            && mobileSideNavOpen
            && !mobileSideNavRef?.current?.contains(event?.target)) {
            event.preventDefault();
            closeMobileSideNav();
          }
        }}
      >
        <Suspense fallback={(
          <SkeletonContainer style={{ width: '100%', height: '100%' }}>
            <Skeleton
              width="100%"
              height="100%"
              style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
            />
          </SkeletonContainer>
          )}
        >
          <DashboardSideNavigation
            ref={mobileSideNavRef}
            close={closeMobileSideNav}
            isExpanded={isExpanded || mobileSideNavOpen}
            setIsExpanded={setIsExpanded}
          />
        </Suspense>
      </DashboardSideNavContainer>
      <Spacer />
      <PageContainer id="DashboardLayoutPageContainer">
        <CenteredPageLayout
          size={pageSize}
          background={Colors.White}
        >
          <ContentContainer pageSize={pageSize} id="DashboardLayoutContentContainer">
            <Outlet />
          </ContentContainer>
        </CenteredPageLayout>
      </PageContainer>
    </Container>
  );
};

export default DashboardLayout;
