import gql from 'graphql-tag';

const FILTERED_PROPERTIES = gql`
    query DataExplorerFilteredProperties ($params: CherrePropertyFiltersParams!) {
        dataExplorerFilterProperties (params: $params) {
            properties
        }
    }
`;

export default FILTERED_PROPERTIES;
