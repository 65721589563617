import DataExplorerSettingsSectionsEnum from '../enums/DataExplorerSettingsSectionsEnum';

const DataExplorerSettingsSectionsContent = {
  [DataExplorerSettingsSectionsEnum.LotInformation]: [
    {
      item: 'APN',
      checked: true,
    },
    {
      item: 'Property type',
      checked: true,
    },
    {
      item: 'Property use',
      checked: true,
    },
    {
      item: 'Last sale',
      checked: true,
    },
    {
      item: 'Zone',
      checked: true,
    },
    {
      item: 'Last county assessor update',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.LotSize]: [
    {
      item: 'Size',
      checked: true,
    },
    {
      item: 'Lot size (acres)',
      checked: true,
    },
    {
      item: 'Width',
      checked: true,
    },
    {
      item: 'Depth',
      checked: true,
    },
    {
      item: 'Gross area',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.BuildingInfo]: [
    {
      item: 'Year built',
      checked: true,
    },
    {
      item: 'Number of units',
      checked: true,
    },
    {
      item: 'Building area',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.Taxes]: [
    {
      item: 'Current tax',
      checked: true,
    },
    {
      item: 'Jurisdiction',
      checked: true,
    },
    {
      item: 'Assessed total value',
      checked: true,
    },
    {
      item: 'Assessed tax year',
      checked: true,
    },
    {
      item: 'Assessed improvements',
      checked: true,
    },
    {
      item: 'Assessed land value',
      checked: true,
    },
    {
      item: 'Market total value',
      checked: true,
    },
    {
      item: 'Market value year',
      checked: true,
    },
    {
      item: 'Market improvements value',
      checked: true,
    },
    {
      item: 'Market value land',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.PropertyDetails]: [
    {
      item: 'Upper floor square footage',
      checked: true,
    },
    {
      item: 'Sewer usage',
      checked: true,
    },
    {
      item: 'Water usage',
      checked: true,
    },
    {
      item: 'Construction',
      checked: true,
    },
    {
      item: 'Fire resistance class',
      checked: true,
    },
    {
      item: 'Exterior',
      checked: true,
    },
    {
      item: 'Topography',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.AdditionalPropertyDetails]: [
    {
      item: 'Basement size (sqft)',
      checked: true,
    },
    {
      item: 'Heating',
      checked: true,
    },
    {
      item: 'Cooling',
      checked: true,
    },
    {
      item: 'Has elevator',
      checked: true,
    },
    {
      item: 'Porch',
      checked: true,
    },
    {
      item: 'Porch size (sqft)',
      checked: true,
    },
    {
      item: 'Fence',
      checked: true,
    },
    {
      item: 'Fence size (sqft)',
      checked: true,
    },
    {
      item: 'Has canopy',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.Demographics]: [
    {
      item: 'Household income',
      checked: true,
    },
    {
      item: 'Employment',
      checked: true,
    },
    {
      item: 'Population',
      checked: true,
    },
    {
      item: 'Crime',
      checked: true,
    },
    {
      item: 'Education',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.PropertyOwners]: [
    {
      item: 'Recorded owners',
      checked: true,
    },
    {
      item: 'Unmasked owners',
      checked: true,
    },
  ],
  [DataExplorerSettingsSectionsEnum.TransactionTimeline]: [
    {
      item: 'Transaction timeline',
      checked: true,
    },
  ],
};

export default DataExplorerSettingsSectionsContent;

/**
 * Hello future readers of this file. Since this is kinda whack and perhaps a little weird to follow, I figured I would write a little dox around ir
 * So this file should (in theory), never really be touched (unless you are adding more data to data explorer).
 * Basically, this file contains the basic structure of the state for the 'Data settings' within Data Explorer and (somewhat) determines what data is 'checked' and rendered for the client vs what isn't
 * As of writing this up, there are really only two separate sections of data for the data explorer: 1) insights and 2) demographics
 *
 * Example:
 * Before refactoring this file to map the DataExplorerDataFrameSectionsContent object, the `DataExplorerInisghtSettings` looked like this:
 *  const DataExplorerInsightSettings = {
    [DataExplorerSettingsSectionsEnum.LotInformation]: [
        {
            item: 'APN',
            checked: true,
        },
        {
            item: 'Property type',
            checked: true,
        },
        {
            item: 'Property use',
            checked: true,
        },
        {
            item: 'Last sale',
            checked: true,
        },
        {
            item: 'Zone',
            checked: true,
        },
        {
            item: 'Last county assessor update',
            checked: true,
        },
    ],
    [DataExplorerSettingsSectionsEnum.LotSize]: [
        {
            item: 'Size',
            checked: true,
        },
        {
            item: 'Lot size (acres)',
            checked: true,
        },
        {
            item: 'Width',
            checked: true,
        },
        {
            item: 'Depth',
            checked: true,
        },
        {
            item: 'Gross area',
            checked: true,
        },
    ],
    ... <--- rest of the insight settings
    [DataExplorerSettingsSectionsEnum.AdditionalPropertyDetails]: [
        {
            item: 'Basement size (sqft)',
            checked: true,
        },
        {
            item: 'Heating',
            checked: true,
        },
        {
            item: 'Cooling',
            checked: true,
        },
        {
            item: 'Has elevator',
            checked: true,
        },
        {
            item: 'Porch',
            checked: true,
        },
        {
            item: 'Porch size (sqft)',
            checked: true,
        },
        {
            item: 'Fence',
            checked: true,
        },
        {
            item: 'Fence size (sqft)',
            checked: true,
        },
        {
            item: 'Has canopy',
            checked: true,
        },
    ]

    * As you can tell, this looks VERY similar to the DataExplorerDataFrameSectionsContent object, except it has a `checked` field to check if the user wants it rendered or not.
    * So I decided to refactor some stuff so that if we were to add another field to `DataExplorerDataFrameSectionsContent`, we would only have to do it within that file, then it would be mapped to the settings as well instead of the need to add it to that file as well as this one, y'know
    * As I stated, Data Explorer data is currently split up so we are taking in `insights` and `demographics` from Cherre. So we also map Demographic settings here
    * If you add another section to data explorer, all you should have to do is make a new object for it that mimicks the format of the `DataExplorerDataFrameSectionsContent` and `DataExplorerDataFrameDemographicsSections` objects, then map those values here and add a `checked` field.
 */
