enum CherrePropertyTypesEnum {
    Agriculture = 'Agriculture',
    Hotel = 'Hotel',
    Industrial = 'Industrial',
    Land = 'Land',
    MultiFamily = 'Multi-Family',
    Office = 'Office',
    Other = 'Other',
    Residential = 'Residential',
    Retail = 'Retail'
}

export default CherrePropertyTypesEnum;
