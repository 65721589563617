import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import IOfferQuery from '@biproxi/models/interfaces/IOfferQuery';
import LIST_OFFERS from '../graphql/queries/offers.query';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import NoContent from '../components/NoContent';
import { Icons } from '../elements/Icon';
import Loader, { LoaderSizes } from '../elements/Loader';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Flex from '../elements/Flex';
import OfferCard, { OfferCardTypeEnum } from '../components/OfferCard';
import IButtonOptions from '../models/interfaces/IButtonOptions';
import ButtonDropdown from '../elements/ButtonDropdown';
import { media, useMobileMedia } from '../utils/MediaQuery';

const Container = styled.div`

${media.tablet} {
  padding: 16px 16px;
}`;

const OffersContainer = styled.div`
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonDropdownContainer = styled.div`
  min-width: 138px;
  margin-left: 8px;
`;

type DashboardOffersSentProps = {};

enum OffersPageStateEnum {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive',
}

const DashboardOffersSent: React.FC<DashboardOffersSentProps> = () => {
  /** State */
  const [pageState, setPageState] = React.useState(OffersPageStateEnum.All);
  const [searchQuery, setSearchQuery] = React.useState('');

  /** Hooks */
  const isMobile = useMobileMedia();

  /** GraphQL */
  interface Data {
    offers: IOfferGraphQL[];
  }

  interface Vars {
    params: IOfferQuery;
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_OFFERS, {
    variables: {
      params: {
        queryType: OfferQueryTypesEnum.Sent,
        isActive: pageState === OffersPageStateEnum.All
          ? null : pageState === OffersPageStateEnum.Active,
      },
    },
  });

  /** Render */
  const offerFilterItems: IButtonOptions[] = [
    {
      text: 'All offers',
      onClick: () => setPageState(OffersPageStateEnum.All),
      active: pageState === OffersPageStateEnum.All,
    },
    {
      text: 'Active offers',
      onClick: () => setPageState(OffersPageStateEnum.Active),
      active: pageState === OffersPageStateEnum.Active,
    },
    {
      text: 'Inactive offers',
      onClick: () => setPageState(OffersPageStateEnum.Inactive),
      active: pageState === OffersPageStateEnum.Inactive,
    },
  ];
  const activeFilterItem = offerFilterItems.find((i) => i.active);
  const o = data?.offers?.length > 0 ? data?.offers : [];
  let filteredOffers = [...o];
  if (searchQuery) {
    filteredOffers = filteredOffers.filter((listing) => Boolean(JSON.stringify(listing).toLowerCase().includes(searchQuery.toLowerCase())));
  }

  return (
    <Container>
      <Text data-cy="offers-sent-greeting" margin="0 0 24px" color={Colors.Black} type={TextTypesEnum.Bold24}>Offers sent</Text>
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          <Flex margin="0px 0px 16px 0px" justify="space-between">
            <Input
              placeholder="Search offers"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              clear={() => setSearchQuery('')}
              inputType={InputTypesEnum.Search}
              inputSize={InputSizeEnum.Small}
              width="100%"
            />
            <ButtonDropdownContainer>
              <ButtonDropdown
                items={offerFilterItems}
                activeItem={activeFilterItem}
              />
            </ButtonDropdownContainer>
          </Flex>
          {(data?.offers?.length > 0) ? (
            <>
              {filteredOffers?.length > 0 ? (
                <OffersContainer>
                  {filteredOffers.map((offer: IOfferGraphQL) => (
                    <OfferCard
                      key={offer._id}
                      offer={offer}
                      type={OfferCardTypeEnum.Sent}
                      hideOtherTerms={isMobile}
                    />
                  ))}
                </OffersContainer>
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.SearchRegular}
                  text="No search results. Please try different search terms."
                />
              )}
            </>
          ) : (
          // TODO: Update Icon
            <NoContent
              height="176px"
              icon={Icons.EnvelopeOpenDollarLight}
              text={`You have no current ${pageState === OffersPageStateEnum.All ? '' : pageState.toLowerCase()} offers.`}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardOffersSent;
