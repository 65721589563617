import gql from 'graphql-tag';

const GET_LEAD_STATUS_OVERVIEW = gql`
    query getLeadStatusOverview ($params: LeadStatusOverviewParams!){
        getLeadStatusOverview(params: $params) {
          totalCount
          notContactedCount
          attemptingContactCount
          notInterestedCount
          propertyTourScheduledCount
          reviewingDiligenceCount
          offerExpectedCount
          offerReceivedCount
        }
    }
`;

export default GET_LEAD_STATUS_OVERVIEW;
