/* eslint-disable no-return-assign */
import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import { useMutation } from '@apollo/client';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Icon, { Icons } from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import AnchoredMenu from '../elements/AnchoredMenu';
import { AppActions } from '../redux/app.redux';
import { ListingActions } from '../redux/listing.redux';
import { ModalTypesEnum } from './modal/Modal';
import { useAppDispatch } from '../redux/store';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from './Toast';
import DELETE_LISTING_UPLOAD from '../graphql/mutations/deleteListingUpload.mutation';
import UPDATE_LISTING_UPLOAD from '../graphql/mutations/updateListingUpload.mutation';
import useUser from '../hooks/useUser.hook';
import NextUtil from '../utils/NextUtil';
import NoContent from './NoContent';
import ListingUploadAdminDocuments from './ListingUploadAdminDocuments';
import { useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import ListingUploadsTableMobile from './ListingUploadsTableMobile';
import LIST_LISTING_UPLOADS from '../graphql/queries/listingUploads.query';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';

enum ListingUploadsTableHeaders {
    ListingID = 'Listing ID',
    ListingName = 'Listing name',
    Broker = 'Broker',
    Assignee = 'Assignee',
    Description = 'Description',
    Status = 'Status',
    Actions = 'Actions',
  }

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, minmax(170px, 1fr));
    grid-row-gap: 16px;
`;

const MappedContainer = styled.div``;

type BodyGridContainerProps = {
  listingUploadState: ListingUploadStateEnum;
}

const BodyGridContainer = styled.div<BodyGridContainerProps>`
    display: grid;
    grid-template-columns: repeat(7, minmax(170px, 1fr));
    grid-row-gap: 16px;
    padding: 0 12px;
    background-color: ${({ listingUploadState }) => {
    switch (listingUploadState) {
      case ListingUploadStateEnum.NotStarted:
        return Colors.Red50;
      case ListingUploadStateEnum.WaitingForReview:
        return Colors.Green50;
      case ListingUploadStateEnum.Published:
        return Colors.Green100;
      case ListingUploadStateEnum.InProgress:
      default:
        return Colors.Yellow50;
    }
  }};
    /* background-color: ${Colors.Brand50 || Colors.Blurple50}; */
    height: 32px;
    align-content: center;
    border-radius: 4px;
`;

const ListingUploadsTableHeaderContainer = styled.div`
    background-color: ${Colors.Grey100};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    padding: 0 12px;
`;

const MenuRef = styled.div``;

const Margin = styled.div`
    margin: 12px 0 0 0;
`;

const TooltipContainer = styled.div``;

type ListingUploadsTableProps = {
    uploadedListings: IListingUploadGraphQL[];
  };

const ListingUploadsTable: React.FC<ListingUploadsTableProps> = ({
  uploadedListings,
}) => {
  /** State */
  const [expandedListings, setExpandedListings] = React.useState<string[]>([uploadedListings[0]?.listingId]);
  const [clickedListing, setClickedListing] = React.useState<IListingUploadGraphQL>(null);

  enum MenuItemEnum {
    CreateListing = 'Create listing',
    Assign = 'Assign',
    UploadDocuments = 'Upload documents',
    DownloadDocuments = 'Download all documents',
    MessageBroker = 'Message broker',
    Edit = 'Edit listing',
    Delete = 'Delete listing',
  }

  const menuItems = [
    {
      text: clickedListing?.state === ListingUploadStateEnum.NotStarted ? MenuItemEnum.CreateListing : MenuItemEnum.Edit,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.CreateListing]();
      },
    },
    {
      text: MenuItemEnum.Assign,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.Assign]();
      },
    },
    {
      text: MenuItemEnum.UploadDocuments,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.UploadDocuments]();
      },
    },
    {
      text: MenuItemEnum.DownloadDocuments,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.DownloadDocuments]();
      },
    },
    {
      text: MenuItemEnum.MessageBroker,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.MessageBroker]();
      },
    },
    {
      text: MenuItemEnum.Delete,
      onClick: (event) => {
        event.stopPropagation();
        menuItemFunctions[MenuItemEnum.Delete]();
      },
    },
  ];

  /** Hooks */
  const user = useUser();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** GraphQL */
  interface DeleteListingUploadVars {
    params: {
      listingUploadId: string;
    }
  }

  interface DeleteListingUploadData {
      deleted: boolean;
  }

  const [deleteListingUpload] = useMutation<DeleteListingUploadData, DeleteListingUploadVars>(DELETE_LISTING_UPLOAD, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'You have deleted the listing',
      });
      popModal();
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error deleting the listing. Please try again.',
      });
    },
    refetchQueries: [{
      query: LIST_LISTING_UPLOADS,
      variables: {
        params: {
          queryType: ListingUploadQueryTypesEnum.All,
        },
      },
    }],
  });

  interface UpdateListingUploadVars {
    params: Partial<IListingUploadParams>
  }

  interface UpdateListingUploadData {
      updateListingUpload: IListingUploadGraphQL
  }

  const [updateListingUpload] = useMutation<UpdateListingUploadData, UpdateListingUploadVars>(UPDATE_LISTING_UPLOAD, {
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error updating the listing upload. Please try again.',
      });
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushAssignAdminModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.AssignAdmin,
    props: {
      uploadedListing: clickedListing,
    },
  }));

  const pushConfirmChangeModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.ConfirmChange,
    props: {
      type: ConfirmChangeModalTypesEnum.Warning,
      title: 'Delete document',
      text: 'Are you sure? You cannot undo this action afterwards.',
      buttonText: 'Delete',
      confirm: () => handleDeleteUploadedListing(),
    },
  }));

  const pushUploadDocumentsModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.UploadDocumentsAdmin,
    props: {
      onSubmit: (clickedListing, newFileIds) => {
        updateListingUpload({
          variables: {
            params: {
              _id: clickedListing._id,
              media: {
                fileIds: [...clickedListing.fileIds, ...newFileIds],
              },
            },
          },
        });
      },
      uploadedListing: clickedListing,
    },
  }));

  const pushMessageBrokerModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ContactBroker,
      props: {
        listingIdProp: clickedListing?.listingId,
      },
    }),
  );

  const selectListing = () => dispatch(
    ListingActions.selectListing({
      listingId: clickedListing.listingId,
    }),
  );

  const startCreateListingFlow = () => {
    if (clickedListing?.state !== ListingUploadStateEnum.NotStarted) selectListing();
    dispatch(ListingActions.setListingState({
      listingState: {
        listingId: clickedListing.listingId,
        state: ListingStateEnum.Admin,
        publishAt: null,
        expiresAt: null,
      },
      toast: false,
    }));
    updateListingUpload({
      variables: {
        params: {
          _id: clickedListing._id,
          adminInfo: {
            userId: user?.userId,
            email: user?.userEmail,
          },
          state: ListingUploadStateEnum.InProgress,
        },
      },
    });
    window.open(ListingUtil.createListingPath(clickedListing.listingId), '_blank');
  };

  /** Functions */
  const expandUploadedListing = (listingId: string) => {
    if (expandedListings.includes(listingId)) {
      setExpandedListings(expandedListings.filter((id) => id !== listingId));
    } else {
      setExpandedListings([...expandedListings, listingId]);
    }
  };

  const downloadAllDocuments = () => {
    window.open(clickedListing?.media?.zipFile?.url);
  };

  const handleDeleteUploadedListing = () => {
    deleteListingUpload({
      variables: {
        params: {
          listingUploadId: clickedListing._id,
        },
      },
    });
  };

  const menuItemFunctions = {
    [MenuItemEnum.CreateListing]: () => {
      startCreateListingFlow();
    },
    [MenuItemEnum.Assign]: () => {
      pushAssignAdminModal();
    },
    [MenuItemEnum.UploadDocuments]: () => {
      pushUploadDocumentsModal();
    },
    [MenuItemEnum.DownloadDocuments]: () => {
      downloadAllDocuments();
    },
    [MenuItemEnum.MessageBroker]: () => {
      pushMessageBrokerModal();
    },
    [MenuItemEnum.Delete]: () => {
      pushConfirmChangeModal();
    },
  };

  /** Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /**
   * I created a separate component for mobile since, fundamentally, it is a much different component. The desktop view is rendered through grid display while the mobile/tablet view is all flex-box
   */
  if (isMobile || isTablet) {
    return (
      <ListingUploadsTableMobile
        uploadedListings={uploadedListings}
        menuItems={menuItems}
        setClickedListing={setClickedListing}
      />
    );
  }
  return (
    <>
      <ListingUploadsTableHeaderContainer>

        <GridContainer>
          {Object.values(ListingUploadsTableHeaders).map((header, index) => (
            <Text key={index}>{header}</Text>
          ))}
        </GridContainer>
      </ListingUploadsTableHeaderContainer>
      {!uploadedListings?.length && (
      <NoContent
        height="100%"
        icon={Icons.EyeRegular}
        text="There are no uploaded listings"
      />
      )}
      {uploadedListings?.map((uploadedListing: IListingUploadGraphQL, index: number) => {
        const {
          listingId, additionalInfo, state, user, media, adminInfo, listingName,
        } = uploadedListing;

        const { files } = media;
        const { email: adminEmail } = adminInfo;

        const { email } = user;
        const isListingUploadExpanded = expandedListings.includes(listingId);
        const truncatedEmail = email.length > 20 ? `${email.substring(0, 20)}...` : email;
        const truncatedAdditionalInfo = additionalInfo.length > 20 ? `${additionalInfo.substring(0, 20)}...` : additionalInfo;
        const truncatedListingName = listingName.length > 20 ? `${listingName.substring(0, 20)}...` : listingName;

        const menuRef: React.RefObject<HTMLDivElement> = React.createRef();
        return (
          <MappedContainer key={index}>
            {index !== 0 && <Margin />}
            <BodyGridContainer listingUploadState={state}>
              <Text color={Colors.Brand700 || Colors.Blurple700} type={TextTypesEnum.Medium14}>{listingId}</Text>
              <TooltipContainer
                data-tip={listingName}
                data-place="top"
                data-multiline
              >
                <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>{truncatedListingName}</Text>
              </TooltipContainer>
              <TooltipContainer
                data-tip={email}
                data-place="top"
                data-multiline
              >
                <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>{truncatedEmail}</Text>
              </TooltipContainer>
              <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>{adminEmail ?? 'Not assigned'}</Text>
              <TooltipContainer
                data-tip={additionalInfo}
                data-place="top"
                data-multiline
              >
                <Text
                  color={Colors.Grey900}
                  type={TextTypesEnum.Regular14}
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(additionalInfo);
                      pushToast({
                        type: ToastTypesEnum.Notification,
                        message: 'Description copied to clipboard',
                      });
                    } catch (e) {
                      pushToast({
                        type: ToastTypesEnum.Error,
                        message: 'Failed to copy description to clipboard',
                      });
                    }
                  }}
                >
                  {truncatedAdditionalInfo}
                </Text>
              </TooltipContainer>

              <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>{state}</Text>
              <Flex
                align="center"
              >
                <MenuRef ref={menuRef}>
                  <Icon
                    size="12px"
                    icon={Icons.EllipsisHRegular}
                    color={Colors.Grey400}
                    margin="0 16px 0 0"
                    onClick={(e) => {
                      e.stopPropagation();
                      setClickedListing(uploadedListing);
                    }}
                  />

                </MenuRef>
                <Icon
                  size="12px"
                  icon={isListingUploadExpanded ? Icons.AngleUpRegular : Icons.AngleDownRegular}
                  color={Colors.Grey400}
                  onClick={() => expandUploadedListing(listingId)}
                />
              </Flex>

            </BodyGridContainer>
            {(files?.length && isListingUploadExpanded) && (
              <Flex direction="column">
                <ListingUploadAdminDocuments uploadedListing={uploadedListing} />
              </Flex>
            )}
            <AnchoredMenu
              anchorRef={menuRef}
              menuItems={menuItems}
              placement={PopperPlacementTypes.Right}
            />
          </MappedContainer>
        );
      })}
    </>
  );
};

export default ListingUploadsTable;
