/* eslint-disable react/no-this-in-sfc */
import { logger } from '@biproxi/logger';
/* eslint-disable no-undef */
import React from 'react';
import styled from '@emotion/styled';
import { Line } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import IAnalyticsChartData from '@biproxi/models/interfaces/IAnalyticsChartData';
import {
  IAnalyticsQueryTimeDimensionGraphQL,
} from '@biproxi/models/interfaces/IAnalyticsQuery';
import ListingOverviewChartDateFilterEnum, { ListingOverviewChartDaysFromCurrentEnum } from '@biproxi/models/enums/ListingOverviewChartDateFilterEnum';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import LISTING_VIEWS_GRAPH_ANALYTICS from '../../../graphql/queries/listingViewsGraphAnalytics.query';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import { media, useMobileMedia, useTabletMedia } from '../../../utils/MediaQuery';
import Loader from '../../../elements/Loader';

type ListingViewsGraphContainerProps = {
  boxShadow: boolean;
  margin: string;
  padding: string;
  showBorder: boolean;
}

const ListingViewsGraphContainer = styled.div<ListingViewsGraphContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding};
  background: ${Colors.White};
  border: ${(props) => ((props.showBorder) ? `1px solid ${Colors.Grey300}` : null)};
  border-radius: ${(props) => ((props.showBorder) ? '16px' : null)};
  box-sizing: border-box;
  box-shadow: ${(props) => (props.boxShadow ? '0px 4px 16px rgba(0, 0, 0, 0.05)' : null)};
  margin: ${(props) => props.margin};
  width: 100%;
  position: relative;

`;

const ListingViewsChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 196px;

  ${media.mobile} {
    height: 212px;
  };

  ${media.tablet} {
    height: 212px;
  };
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 232px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/** Utilized by CubeUtil to query for listing views with time dimensions */
// there is definitely a way better way to handle this but i am extremely stupid
export const cubeTimeDimensionFilters = {
  [ListingOverviewChartDateFilterEnum.ThisWeek]: {
    dateRange: 'from 7 days ago to now',
    granularity: 'day',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.ThisWeek,
  },
  [ListingOverviewChartDateFilterEnum.LastDay]: {
    dateRange: 'from 1 days ago to now',
    granularity: 'hour',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastDay,
  },
  [ListingOverviewChartDateFilterEnum.LastWeek]: {
    dateRange: 'from 14 days ago to 7 days ago',
    granularity: 'day',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastWeek,
  },
  [ListingOverviewChartDateFilterEnum.Last30Days]: {
    dateRange: 'from 30 days ago to now',
    granularity: 'day',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.Last30Days,
  },
  [ListingOverviewChartDateFilterEnum.Last90Days]: {
    dateRange: 'from 90 days ago to now',
    granularity: 'week',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.Last90Days,
  },
  [ListingOverviewChartDateFilterEnum.LastYear]: {
    dateRange: 'from 360 days ago to now',
    granularity: 'month',
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastYear,
  },
};

/** Types */
type LineChartProps = {
  listingId: string | string[];
  listingUserId: string;
  filterValue: string;
  loading?: boolean;
  boxShadow?: boolean;
  showBorder?: boolean;
  margin?: string;
  padding?: string;
};

const ListingViewsLineChart: React.FC<LineChartProps> = ({
  listingId,
  listingUserId,
  filterValue,
  loading = false,
  boxShadow = true,
  showBorder = true,
  margin = '16px 0',
  padding = '24px',
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** State */
  if (typeof listingId === 'string') listingId = [listingId];

  /** GraphQL */
  // if you see this tongight and are curious about why I do this, ask me tomorrow lol. This involves diving into a rabbit hole
  interface FrontEndData {
    listingViewsGraphAnalytics: Data;
  }
  interface Data {
    graphData: IAnalyticsChartData;
  }
  interface Vars {
    params: {
      listingId: string[];
      listingUserId: string;
      lineChartFilterParams: IAnalyticsQueryTimeDimensionGraphQL;
      timezone: string;
    };
  }

  const { data, loading: isDataLoading } = useQuery<FrontEndData, Vars>(
    LISTING_VIEWS_GRAPH_ANALYTICS,
    {
      variables: {
        params: {
          listingId,
          listingUserId,
          lineChartFilterParams: {
            dateRange: cubeTimeDimensionFilters[filterValue].dateRange,
            granularity: cubeTimeDimensionFilters[filterValue].granularity,
            daysFromToday: cubeTimeDimensionFilters[filterValue].daysFromToday,
          },
          timezone: TimeUtil.currentBrowserTimezone(),
        },
      },
      skip: !listingId?.length,
      onError: (error) => {
        logger.error('LISTING_VIEWS_GRAPH_ANALYTICS error', error);
      },
    },
  );
  const { graphData } = data?.listingViewsGraphAnalytics ?? {};

  const lineChartLabels: string[] = graphData ? Object.keys(graphData) : [];
  const lineChartData: number[] = graphData ? Object.values(graphData) : [];

  /* Chartjs Configuration */
  const chartData = React.useMemo(
    () => ({
      labels: lineChartLabels,
      datasets: [
        {
          data: lineChartData,
          fill: true,
          backgroundColor: 'rgba(63, 63, 225, 0.2)',
          borderColor: Colors.Brand700 || Colors.Blurple700,
          lineTension: 0.4,
          pointRadius: 3.5,
          pointHoverRadius: 4,
        },
      ],
    }),
    [lineChartLabels, lineChartData],
  );

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            let label = context.parsed.y || '0';
            if (context.parsed.y !== null) {
              label += ' views';
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 10,
      },
      x: {
        ticks: {
          minRotation: isMobile || isTablet ? 45 : 0,
          maxRotation: 90,
          callback(value, index) {
            let labelValue;
            if (filterValue === ListingOverviewChartDateFilterEnum.Last30Days || filterValue === ListingOverviewChartDateFilterEnum.LastDay) {
              if (index === 0 || index === lineChartData.length - 1) labelValue = this.getLabelForValue(value);
            } else if (filterValue === ListingOverviewChartDateFilterEnum.Last90Days) {
              if (index === 0 || (index + 1) % 7 === 0) labelValue = this.getLabelForValue(value);
            } else {
              labelValue = this.getLabelForValue(value);
            }
            return labelValue;
          },
          autoSkip: false,
        },
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <ListingViewsGraphContainer
      margin={margin}
      padding={padding}
      boxShadow={boxShadow}
      showBorder={showBorder}
    >
      <Text type={TextTypesEnum.Bold16} margin="0 0 24px 0">
        Listing views
      </Text>
      {isDataLoading || loading ? (
        <LoaderContainer>
          <Loader color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <ListingViewsChartContainer>
          <Line
            data={chartData}
            options={options}
            height={isMobile || isTablet ? 212 : 63}
            width={290}
          />
        </ListingViewsChartContainer>
      )}
    </ListingViewsGraphContainer>
  );
};

export default React.memo(ListingViewsLineChart);
