import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';

const RECENTLY_VIEWED_LISTINGS_PER_USER = gql`
    query recentlyViewedListingsPerUser($params: RecentlyViewedListingsPerUserParams) {
        recentlyViewedListingsPerUser(params: $params) {
            ...ListingFields
            ...ListingAddress
            ...ListingPortfolioAddresses
            ...ListingMedia
            ...ListingUser
        }
    }

    ${ListingFragments.fields}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.media}
    ${ListingFragments.user}
`;

export default RECENTLY_VIEWED_LISTINGS_PER_USER;
