import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserRolesEnum from '@biproxi/models/enums/UserRolesEnum';
import IAddress from '@biproxi/models/interfaces/IAddress';
import { KB_PROFILE_INFO_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Input, { InputTypesEnum } from '../../elements/Input';
import Dropdown from '../../elements/Dropdown';
import Flex, { CustomSpacer } from '../../elements/Flex';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import { ModalTypesEnum } from '../modal/Modal';
import ProfileImage from '../ProfileImage';
import AutoCompleteAddress from '../AutoCompleteAddress';
import RichTextEditor from '../RichTextEditor';
import { UserActions, UserSelectors } from '../../redux/user.redux';

const Spacer = styled.div`
  height: 32px;
`;

// the div that contains the entirety of all elements within the 'profile picture' block
const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  margin: 0 0 40px 0;
`;

// the div that contains the actual picture
const ProfileImageWrapper = styled.div`
  width: 72px;
  height: 72px;
  background: ${Colors.Grey200};
  border-radius: 100px;
  margin: 5px 0px;
  position: static;
  top: 21px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// the div that contains both the profile image and pen icon
const ProfileIconsWrapper = styled.div`
  position: relative;
`;

const PenIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${Colors.White};
  border: 1px solid ${Colors.Grey300};
  box-sizing: border-box;
  border-radius: 100px;
  &:hover {
    background-color: ${Colors.Grey100};
    cursor: pointer;
  }
`;

type ProfileBasicInfoProps = {
  user: IUserGraphQL;
};

const ProfileBasicInfo: React.FC<ProfileBasicInfoProps> = ({
  user,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const setValidationErrors = (errors: Record<string, string>) => dispatch(
    UserActions.setUserErrors(errors),
  );
  const setUserField = (fields: Partial<IUserGraphQL>) => dispatch(
    UserActions.setUserField(fields),
  );

  // Handle change profile picture modal popup
  // This file contains profile picture upload logic
  const pushChangeProfilePictureModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeProfilePicture,
      props: {},
    }),
  );

  const errors = useAppSelector(UserSelectors.userErrors);

  /* Render */
  return (
    <ContentCard
      title="Basic Information"
      subtitle="Provide some biographical information about yourself."
      learnMoreLink={KB_PROFILE_INFO_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
      preserveBorderRadius
    >
      <ProfilePictureContainer>
        <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
          Profile picture
        </Text>
        <ProfileIconsWrapper>
          <ProfileImageWrapper onClick={pushChangeProfilePictureModal}>
            {user ? (
              <ProfileImage user={user} margin="0px" />
            ) : (
              <Icon icon={Icons.UserLight} color={Colors.Grey700} size={24} margin="0 0 8px 0" />
            )}
          </ProfileImageWrapper>
          <PenIconWrapper onClick={pushChangeProfilePictureModal}>
            <Icon icon={Icons.PenRegular} color={Colors.Grey700} size={12} margin="8px 0 0 0" />
          </PenIconWrapper>
        </ProfileIconsWrapper>
      </ProfilePictureContainer>
      <Flex direction="row" justify="space-between">
        <Flex flex="1">
          <Input
            label="First name"
            placeholder="First name"
            value={user?.firstName}
            onChange={(event) => {
              if (errors) setValidationErrors(null);
              setUserField({ firstName: event.target.value });
            }}
            inputType={InputTypesEnum.Text}
            error={errors?.['/firstName']}
          />
        </Flex>
        <CustomSpacer width="16px" />
        <Flex flex="1">
          <Input
            label="Last name"
            placeholder="Last name"
            value={user?.lastName}
            onChange={(event) => {
              if (errors) setValidationErrors(null);
              setUserField({ lastName: event.target.value });
            }}
            inputType={InputTypesEnum.Text}
            error={errors?.['/lastName']}
          />
        </Flex>
      </Flex>
      <Spacer />
      <Dropdown
        label="CRE Role"
        value={user?.role}
        onChange={(role) => setUserField({ role })}
        placeholder="Select your role"
        items={Object.values(UserRolesEnum).map((value) => ({
          name: value,
          value,
        }))}
      />
      <Spacer />
      <AutoCompleteAddress
        label="Location"
        dataCy="city-address-input"
        value={user?.address}
        onChange={(address: IAddress) => setUserField({ address })}
        // error={address?.error()?.[Object.keys(address?.error())?.[0]] ?? null}
        showUnit={false}
        placeholder="Enter the city you reside in"
      />
      <Spacer />
      {/* <Input
        label="Organization"
        placeholder="Enter your organization name"
        value={user?.organizationName}
        onChange={(event) => setUserField({ organizationName: event.target.value })}
        inputType={InputTypesEnum.Text}
      /> */}
      <Spacer />
      <RichTextEditor
        label="Bio"
        value={user?.bio}
        onChange={(bio: string) => {
          // value can have left over p tags when content
          // is cleared so we delete them manually.
          // Would be better to do this internally somehow.
          if (bio.match(/^<p>\s*<\/p>$/)) {
            setUserField({ bio: '' });
          } else {
            setUserField({ bio });
          }
        }}
        placeholder="Create a bio for yourself"
      />
    </ContentCard>
  );
};

export default ProfileBasicInfo;
