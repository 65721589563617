import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import RangeInput from '../../../RangeInput';
import { InputTypesEnum } from '../../../../elements/Input';
import { media } from '../../../../utils/MediaQuery';
import { dataExplorerLotSizeFiltersAtom, ILotSizeFilters } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

const LotSizeFiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mobile} {
      padding: 0 8px;
    }
`;

type LotSizeFiltersProps = {}

const LotSizeFilters: React.FC<LotSizeFiltersProps> = () => {
  /** Recoil */
  const dataExplorerLotSizeFilters = useRecoilValue(dataExplorerLotSizeFiltersAtom);
  const {
    lotSizeSqftRange,
    lotSizeAcreRange,
    grossAreaSqftRange,
  } = dataExplorerLotSizeFilters;

  /** Callbacks */
  const setLotSizeSqft = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerLotSizeFiltersAtom, (prevState: ILotSizeFilters) => ({
      ...prevState,
      lotSizeSqftRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [lotSizeSqftRange]);

  const setLotSizeAcre = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerLotSizeFiltersAtom, (prevState: ILotSizeFilters) => ({
      ...prevState,
      lotSizeAcreRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [lotSizeAcreRange]);

  const setGrossAreaSqft = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerLotSizeFiltersAtom, (prevState: ILotSizeFilters) => ({
      ...prevState,
      grossAreaSqftRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [grossAreaSqftRange]);

  return (
    <LotSizeFiltersWrapper
      data-cy="LotSizeContainer"
    >
      <RangeInput
        label="Lot size (sqft)"
        data-cy="LotSizeSqftInput"
        inputType={InputTypesEnum.Number}
        onMinChange={(_e, unmasked) => setLotSizeSqft({ minValue: unmasked, maxValue: lotSizeSqftRange?.max })}
        onMaxChange={(_e, unmasked) => setLotSizeSqft({ minValue: lotSizeSqftRange?.min, maxValue: unmasked })}
        minValue={lotSizeSqftRange?.min}
        maxValue={lotSizeSqftRange?.max}
      />
      <RangeInput
        label="Lot size (acre)"
        data-cy="LotSizeAcreInput"
        onMinChange={(_e, unmasked) => setLotSizeAcre({ minValue: unmasked, maxValue: lotSizeAcreRange?.max })}
        onMaxChange={(_e, unmasked) => setLotSizeAcre({ minValue: lotSizeAcreRange?.min, maxValue: unmasked })}
        minValue={lotSizeAcreRange?.min}
        maxValue={lotSizeAcreRange?.max}
      />
      <RangeInput
        label="Gross area (sqft)"
        data-cy="GrossAreaSqftInput"
        onMinChange={(_e, unmasked) => setGrossAreaSqft({ minValue: unmasked, maxValue: grossAreaSqftRange?.max })}
        onMaxChange={(_e, unmasked) => setGrossAreaSqft({ minValue: grossAreaSqftRange?.min, maxValue: unmasked })}
        minValue={grossAreaSqftRange?.min}
        maxValue={grossAreaSqftRange?.max}
      />
    </LotSizeFiltersWrapper>
  );
};

export default LotSizeFilters;
