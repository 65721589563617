import { NotificationBlockTitlesEnum } from '@biproxi/models/enums/SettingsNotificationsEnum';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import IUserNotificationSettings from '@biproxi/models/interfaces/IUserNotificationSettings';
import StringUtil from '@biproxi/models/utils/StringUtil';
import styled from '@emotion/styled';
import React from 'react';
import Checkbox, { CheckboxTypesEnum } from '../../elements/Checkbox';
import Text, { TextTypesEnum } from '../../elements/Text';
import { useAppDispatch } from '../../redux/store';
import { UserActions } from '../../redux/user.redux';
import Colors from '../../styles/Colors';

const NotificationSpacer = styled.div`
  margin: 0px 0px 16px 0px;
`;

type NotificationBlockProps = {
    title: NotificationBlockTitlesEnum;
    notificationEnum: any;
    user: IUserGraphQL;
}

const NotificationBlock: React.FC<NotificationBlockProps> = ({ title, notificationEnum, user }) => {
  /** Actions */
  const dispatch = useAppDispatch();

  const setUserNotificationSettings = (notificationSetting: Partial<IUserNotificationSettings>) => dispatch(
    UserActions.setUserNotificationSettings(notificationSetting),
  );

  /** Render */
  return (
    <>
      <Text
        type={TextTypesEnum.Medium18}
        color={Colors.Grey900}
        margin="0px 0px 16px 0px"
      >
        {title}
      </Text>
      {Object.entries(notificationEnum).map(([key, value], index) => {
        const formattedKey = StringUtil.formatSettingsNotification(key);
        return (
          <React.Fragment key={index}>
            <Checkbox
              label={key}
              type={CheckboxTypesEnum.Large}
              active={Boolean(!user?.notificationSettings[formattedKey])}
              onClick={() => setUserNotificationSettings({
                [formattedKey]: !user.notificationSettings[formattedKey],
              })}
            />
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
              margin="0px 0px 0px 32px"
            >
              {value}
            </Text>
            {index !== Object.keys(notificationEnum).length - 1 && <NotificationSpacer />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NotificationBlock;
