import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import IOfferQuery from '@biproxi/models/interfaces/IOfferQuery';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import { useNavigate } from 'react-router-dom';
import Text, { TextTypesEnum } from '../../elements/Text';
import LIST_OFFERS from '../../graphql/queries/offers.query';
import AccountOfferCard, { AccountOfferCardTypesEnum } from './AccountOfferCard';
import Flex from '../../elements/Flex';
import LinkText from '../../elements/LinkText';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
`;

const OffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
`;

type AccountOffersReceivedProps = {};

const AccountOffersReceived: React.FC<AccountOffersReceivedProps> = () => {
  /** Hooks */
  const navigate = useNavigate();

  /** GraphQL */
  interface Data {
    offers: IOfferGraphQL[];
  }

  interface Vars {
    params: IOfferQuery;
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_OFFERS, {
    variables: {
      params: {
        queryType: OfferQueryTypesEnum.Received,
        isActive: true,
        pagination: {
          limit: 3,
          offset: 0,
        },
      },
    },
  });

  /** Render */
  if (data?.offers?.length <= 0 || loading) return null;

  return (
    <Container>
      <Flex margin="0 0 5px 0" justify="space-between">
        <Text type={TextTypesEnum.Bold18}>Offers received</Text>
        <LinkText
          onClick={() => navigate('/app/dashboard/offers-received')}
          type={TextTypesEnum.Medium14}
        >
          See all
        </LinkText>
      </Flex>
      <OffersContainer>
        {data?.offers?.map((offer, index) => (
          <React.Fragment key={offer._id}>
            <AccountOfferCard offer={offer} type={AccountOfferCardTypesEnum.Received} />
            {index !== data?.offers?.length - 1 && <Divider color={Colors.Grey200} />}
          </React.Fragment>
        ))}
      </OffersContainer>
    </Container>
  );
};

export default AccountOffersReceived;
