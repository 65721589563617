enum ListingOverviewChartDateFilterEnum {
    ThisWeek = 'This week',
    LastDay = 'Last 24 hours',
    LastWeek = 'Last week',
    Last30Days = 'Last 30 days',
    Last90Days = 'Last 90 days',
    LastYear = 'Last 360 days'
  }

export enum ListingOverviewChartDaysFromCurrentEnum {
    LastDay = 1,
    ThisWeek = 7,
    LastWeek = 14,
    Last30Days = 30,
    Last90Days = 90,
    LastYear = 360,
  }

export default ListingOverviewChartDateFilterEnum;
