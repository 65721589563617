import GeoJSON from 'geojson';
import * as Geolib from 'geolib';
import { GeolibInputCoordinates } from 'geolib/es/types';
import IMapBounds from '../interfaces/IMapBounds';

function boundsToGeoJSONPolygon(bounds: IMapBounds): GeoJSON.Polygon {
  const requiredBounds: Required<IMapBounds> = ensureAllBoundingPointsExist(bounds);

  // A GeoJSON Polygon must end in the
  // same point that it begins.
  // This is super inconvient but
  // There's no way around it.
  const coordinates = [
    requiredBounds.sw,
    requiredBounds.se,
    requiredBounds.ne,
    requiredBounds.nw,
    requiredBounds.sw,
  ].map(coordinateToGeoJSON);

  return {
    type: 'Polygon',
    coordinates: [coordinates],
  };
}

function coordinateToGeoJSON(coordinate: GeolibInputCoordinates): GeoJSON.Position {
  return [Geolib.getLongitude(coordinate), Geolib.getLatitude(coordinate)];
}

function ensureAllBoundingPointsExist(bounds: IMapBounds): Required<IMapBounds> {
  const neLat = Geolib.getLatitude(bounds.ne);
  const neLng = Geolib.getLongitude(bounds.ne);

  const swLat = Geolib.getLatitude(bounds.sw);
  const swLng = Geolib.getLongitude(bounds.sw);

  return {
    ne: bounds.ne,
    nw: {
      latitude: neLat,
      longitude: swLng,
    },
    sw: bounds.sw,
    se: {
      latitude: swLat,
      longitude: neLng,
    },
  };
}

/**
 * Alright so this is a little wild but here is what this function does:
 * Given a geoCoordinate (lat, lng) *IN DEGREES* and a radius *IN METERS*, this function will calculate displaced coordinates in each cardinal direction.
 * E.g. If you give the coordinates (45.675552, -111.046806) and a radius of 100m, this function will output the coordinates (in degrees) that are 100m in each cardinal direction of the initial coordinates
 *
 * Disclaimer: These calculations are not perfect lol. There are quite a few asspull assumptions that I make in an effort to not overcomplicate this:
 *
 * Complications and asspulls:
 *
 * Complication #1: If you were in a plane, then the point that is r meters away at a bearing of a degrees east of north is displaced by r * cos(a) in the north direction and r * sin(a) in the east direction (These statements are very elementary definitions of sine and cosine)
 * HOWEVER, when working with coordinates and the globe, you obviously are NOT in a plane -- you are working on the surface of a curved ellipsoid that models the Earth's surface.
 *
 * Asspull #1: Any distance less than a few hundred kilometers covers such a small part of the Earth's surface that we can kinda consider that we are working with a flat surface. With these calculations, I am doing just that
 *
 * Complication #2: One degree of longitude does not cover the same distance as a degree of latitude. In a spherical Earth model, one degree of longitude is only cos(lat) as long as a degree of latitude. In an ellipsoidal model, this is a pretty good approximation -- good to about 2.5 sig-figs
 *
 * Asspull #2: I am assuming that one degree of latitude is ~ 10^7 / 90 = 111,111 meters and one degree of longitude is 111,111 * cos(latitude) meters
 *
 * Really, I am only using this function for the 'Similar Properties' feature in Data Explorer to find the geocoordinates of the cardinal bounds from a clicked property; however, it could be used to find the geocoordinates any distance away from a point
 */
function displaceCardinalCoordinatePoints(coordinate: any, displacedRadius: number) {
  // ensure that lat and lng are floating point nums
  const lat = parseFloat(coordinate.lat);
  const lng = parseFloat(coordinate.lng);
  const LATDEGREE = 111111;

  const PI = Math.PI;
  const cardinalDirections: string[] = ['North', 'East', 'South', 'West'];
  const cardinalBearings = [(2 * PI), (PI / 2), (PI), ((3 * PI) / 2)]; // Bearings from North in cardinal directions (N, E, S, W) in radians

  const displacedCoordinates = cardinalBearings.reduce((acc, curr, index) => {
    const cardinalDirection = cardinalDirections[index];
    const cosBearing = Math.cos(curr);
    const sinBearing = Math.sin(curr);

    const latRadians = (lat * (PI / 180));
    const cosLat = Math.cos(latRadians);

    const lngDisplacement = (((displacedRadius * sinBearing) / cosLat) / LATDEGREE);
    const latDisplacement = ((displacedRadius * cosBearing) / LATDEGREE);

    return {
      ...acc, [cardinalDirection]: [lat + latDisplacement, lng + lngDisplacement],
    };
  }, {});

  return displacedCoordinates;
}

const GeoUtil = {
  boundsToGeoJSONPolygon,
  coordinateToGeoJSON,
  ensureAllBoundingPointsExist,
  displaceCardinalCoordinatePoints,
};

export default GeoUtil;
