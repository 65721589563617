import { atom, selector, selectorFamily } from 'recoil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import DataExplorerFilterPropertyListDataControllersEnum from '../../../@types/utility-bar-types/enums/DataExplorerFilterPropertyListDataControllersEnum';

interface IDataExplorerFilterAddress extends Omit<IAddress, 'city'> {
    city?: string;
  }

type DataExplorerUtilityBarFiltersType = {
    dataSettingsVisible: boolean;
    propertyFiltersVisible: boolean;
    specificAddressSearch: IDataExplorerFilterAddress,
    fetchFilterProperties: boolean;
    showFilterPropertyList: boolean;
    dataSettingsSelectedData: any;
    filteredPropertyListQueryType: DataExplorerFilterPropertyListDataControllersEnum;
    geographiesVisible: boolean;
}

const dataExplorerUtilityBarAtom = atom({
  key: 'utilityBarState',
  default: {
    dataSettingsVisible: null,
    propertyFiltersVisible: null,
    specificAddressSearch: null,
    fetchFilterProperties: null,
    showFilterPropertyList: null,
    dataSettingsSelectedData: null,
    filteredPropertyListQueryType: null,
    geographiesVisible: null,
  } as DataExplorerUtilityBarFiltersType,
});

export const dataSettingsVisibleState = selector({
  key: 'dataSettingsVisibleState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).dataSettingsVisible,
});

export const propertyFiltersVisibleState = selector({
  key: 'propertyFiltersVisibleState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).propertyFiltersVisible,
});

export const specificAddressSearchState = selector({
  key: 'specificAddressSearchState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).specificAddressSearch,
});

export const fetchFilterPropertiesState = selector({
  key: 'fetchFilterPropertiesState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).fetchFilterProperties,
});

export const showFilterPropertyListState = selector({
  key: 'showFilterPropertyListState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).showFilterPropertyList,
});

export const filteredPropertyListQueryTypeState = selector({
  key: 'filteredPropertyListQueryTypeState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).filteredPropertyListQueryType,
});

export const geographiesVisibleState = selector({
  key: 'geographiesVisibleState',
  get: ({ get }) => get(dataExplorerUtilityBarAtom).geographiesVisible,
});

export const utilityBarFamilyState = selectorFamily({
  key: 'utilityBarFamilyState',
  get: (field) => ({ get }) => get(dataExplorerUtilityBarAtom)[field as any],
  set: (field) => ({ set }, newValue) => set(dataExplorerUtilityBarAtom, (prevState) => ({ ...prevState, [field as any]: newValue })),
});

export default dataExplorerUtilityBarAtom;
