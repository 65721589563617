import axios from 'axios';
import { config } from '@biproxi/env';

const queryChargebeeCustomer = async (userId: string) => {
  const res = await axios(`/api/billing/customer?id=${userId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const customer = await res.data;
  return customer;
};

export default queryChargebeeCustomer;
