import axios from 'axios';
import { config } from '@biproxi/env';

const queryInvoicePdfUrl = async (invoiceId: string) => {
  const res = await axios(`/api/billing/invoice-pdf?id=${invoiceId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const queryInvoicePdfUrl = await res.data;
  return queryInvoicePdfUrl;
};

export default queryInvoicePdfUrl;
