import React from 'react';
import { useQuery as rqQuery, useMutation as rqMutation } from 'react-query';
import styled from '@emotion/styled';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { getOrganization, removeUserFromOrganization, updateUserOrganizationRole } from '@biproxi/next-api-requests';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import { logger } from '@biproxi/logger';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useUser from '../../hooks/useUser.hook';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import { media } from '../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../elements/Text';
import CenteredPageLayout, { CenteredPageLayoutSizeEnum } from '../CenteredPageLayout';
import Loader from '../../elements/Loader';
import Button, { ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import useHandleRouteChange from '../../hooks/useHandleRouteChange.hook';
import PageError from '../PageError';
import Icons from '../../elements/Icons';
import Icon from '../../elements/Icon';

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${media.mobile} {
    padding: 24px 0;
    width: 70px;
  }
`;

type TeamInvitationProps = {
  // team: INeo4jServiceAPI.INeo4jOrganization
}

const TeamInvitationPage: React.FC<TeamInvitationProps> = () => {
  /** State */
  const [team, setTeam] = React.useState<INeo4jServiceAPI.INeo4jOrganization>(null);
  const [authLoading, setAuthLoading] = React.useState(true);
  const [unAuthed, setUnAuthed] = React.useState(false);
  const [userMember, setUserMember] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  /** Hooks */
  const { userId } = useUser();
  const { teamId } = useParams();
  const handleRouteChange = useHandleRouteChange();
  const navigate = useNavigate();

  /** React Query */
  const { mutate: handleUpdateUserOrgRole } = rqMutation((params: INeo4jServiceAPI.IUpdateUserOrganizationRoleParams) => updateUserOrganizationRole(params), {
    onSuccess: () => handleUpdateUserOrgRoleSuccess(),
    onError: (err) => handleUpdateUserOrgRoleError(err),
  });

  const { mutate: handleRemoveUserFromOrg } = rqMutation((params: INeo4jServiceAPI.IRemoveUserFromOrganizationParams) => removeUserFromOrganization(params), {
    onSuccess: () => handleRemoveUserFromOrganizationSuccess(),
    onError: (err) => handleRemoveUserFromOrganizationError(err),
  });

  const { isLoading } = rqQuery(['neo4j:organization'], () => getOrganization({
    organizationId: teamId,
  }), {
    onSuccess: (data) => {
      const team = data?.data?.data;
      setTeam(team);
    },
    enabled: Boolean(teamId?.length),
    cacheTime: 0,
  });

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** Functions */
  const handleAcceptInvitation = () => {
    const targetOrgUserRoles = team?.usersConnection?.edges?.filter((user) => user?.node?._id === userId)?.[0]?.roles;
    const acceptedInvitationRoles: TeamRolesEnum[] = targetOrgUserRoles?.filter((orgRole: TeamRolesEnum) => orgRole !== TeamRolesEnum.Pending);
    const mutationParams: INeo4jServiceAPI.IUpdateUserOrganizationRoleParams = {
      userId,
      orgId: teamId,
      orgRoles: acceptedInvitationRoles,
    };
    handleUpdateUserOrgRole(mutationParams);
  };

  const handleDeclineInvitation = () => {
    const mutationParams: INeo4jServiceAPI.IRemoveUserFromOrganizationParams = {
      userId,
      organizationId: teamId,
    };
    handleRemoveUserFromOrg(mutationParams);
    handleRouteChange('/app/dashboard/search');
  };

  const handleUpdateUserOrgRoleSuccess = () => {
    logger.info(`User ${userId} accepted team invite to team ${team?.name}`);
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Succesfully added to team',
    });
    handleRouteChange(`/app/dashboard/teams/${teamId}`);
  };

  const handleUpdateUserOrgRoleError = (error) => {
    logger.warn(`There was an issue with a user accepting a team invite: ${error}`);
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'There was an issue accepting the invitation. Please contact support or try again later',
    });
  };

  const handleRemoveUserFromOrganizationSuccess = () => {
    logger.info(`User ${userId} declined team invitation from team ${team?.name}`);
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Invitation successfully declined',
    });
  };
  const handleRemoveUserFromOrganizationError = (error) => {
    logger.warn(`There was an issue with a user declining a team invite: ${error}`);
    pushToast({
      type: ToastTypesEnum.Error,
      message: 'There was an issue declining the invitation. Please contact support or try again later',
    });
  };

  /** Effects */

  /**
   * A strange useUffect
   * Basically, if a user doesn't have an account with Biproxi when they go to accept a team invite, they will go through the usual sign up flow
   * There can be some pretty strange behaviours that occur when there are modals present on this page, so i just remove them all-together
   */
  React.useEffect(() => {
    if (searchParams.has('modalType')) {
      searchParams.delete('modalType');
      setSearchParams(searchParams);
      popModal?.();
    }
  }, []);

  /**
   * Yet another strange useEffect here
   * So this useEffect is kind of a hacky way for solving a certain race condition
   * The race condition: if a user is invited to join a team but they do not have an account with Biproxi, they go through the normal register flow....
   * ...but their user info isn't actually updated in Aura until after the sign up flow, but they may get redirected to this invitation page just moments before that update completes...
   * ...thus this component will attempt to fetch a user that doesn't quite exist yet
   *
   * This useEffect basically polls the user Aura info (refetch()) every 3 seconds if the info is null until the info is fetched
   */
  // React.useEffect(
  //   () => {
  //     if (!userOrganizationsRolesAndPermissions?.length) {
  //       const timer1 = setTimeout(() => setRerender(!rerender), 3000);
  //       refetch();
  //       return () => {
  //         clearTimeout(timer1);
  //       };
  //     }
  //     return undefined;
  //   },
  //   [rerender],
  // );

  React.useEffect(() => {
    if (team && userId) {
      const userTeamRolesAndPermissions = team?.usersConnection?.edges?.filter((user) => user?.node?._id === userId)?.[0];
      if (userTeamRolesAndPermissions) {
        const userRoles = userTeamRolesAndPermissions?.roles;
        if (!userRoles?.includes(TeamRolesEnum.Pending)) {
          setUserMember(true);
        }
      } else {
        setUnAuthed(true);
      }
      setAuthLoading(false);
    }
  }, [team]);

  if (isLoading || authLoading) {
    return (
      <CenteredPageLayout
        size={CenteredPageLayoutSizeEnum.ExtraSmall}
        background={Colors.White}
        dataCy="team-invitation-page-wrapper"
      >
        <LoadingContainer>
          <Loader
            color={Colors.Brand600 || Colors.Blurple600}
          />
        </LoadingContainer>
      </CenteredPageLayout>
    );
  }

  if (unAuthed && !authLoading) {
    return (
      <PageError
        icon={Icons.TimesRegular}
        header="You're not authorized to be here"
        body="We're sorry, but you are not allowed to view this page. Let's get you back to Biproxi."
        onClick={() => navigate('/app/dashboard/search')}
      />
    );
  }

  if (userMember) {
    return (
      <PageError
        icon={Icons.TimesRegular}
        header="You are already a member"
        body="You seem to already be a member of this organization. Let's go to the organization page."
        buttonText="Go to org page"
        onClick={() => navigate(`/app/dashboard/teams/${teamId}`)}
      />
    );
  }

  return (
    <CenteredPageLayout
      size={CenteredPageLayoutSizeEnum.ExtraSmall}
      background={Colors.White}
      dataCy="team-invitation-page-wrapper"
    >
      <Flex
        direction="column"
        gap="16px"
        justify="center"
        align="center"
        margin="0 auto"
        padding="24px 0"
      >
        <Icon
          icon={Icons.UsersRegular}
          color={Colors.Grey900}
          borderRadius="100px"
          background={Colors.Grey100}
          width="64px"
          height="64px"
          size="24px"
        />
        <Text
          type={TextTypesEnum.Regular16}
          align="center"
          data-cy="invitation-page-title"
        >
          You have been invited to join team
          {' '}
          <Text type={TextTypesEnum.Bold16} align="center">
            {team?.name}
          </Text>
        </Text>
        <Flex
          gap="16px"
          width="100%"
          justify="center"
        >
          <Button
            type={ButtonTypesEnum.Primary}
            text="Accept"
            onClick={() => handleAcceptInvitation()}
            data-cy="accept-invitiation-button"
          />
          <Button
            type={ButtonTypesEnum.Pale}
            text="Decline"
            onClick={() => handleDeclineInvitation()}
          />
        </Flex>
      </Flex>
    </CenteredPageLayout>
  );
};

export default TeamInvitationPage;
