import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import OfferCard from './OfferCard';
import Flex from '../elements/Flex';
import LinkText from '../elements/LinkText';
import Text, { TextTypesEnum } from '../elements/Text';
import { useAppDispatch } from '../redux/store';
import { ListingActions } from '../redux/listing.redux';
import { useMobileMedia } from '../utils/MediaQuery';
import SquareIconButton from '../elements/SquareIconButton';
import Icons from '../elements/Icons';
import AnchoredMenu from '../elements/AnchoredMenu';

const Container = styled.div`
  background: ${Colors.White};
  border-radius: 8px;
  border: 1px solid ${Colors.Grey300};
  padding: 16px 16px 8px 16px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  margin: 0 0 16px;
  max-width: calc(100vw - 32px);

  &:hover {
    border: 1px solid ${Colors.Grey400};
  }
`;

const ListingNameContainer = styled.div`
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ListingDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListingImage = styled.div<{ src: string }>`
  background-color: ${Colors.Grey100};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => src});
  width: 64px;
  min-width: 64px;
  height: 48px;
  border-radius: 8px;
  margin: 0px 8px 0px 0px;
`;

const LinkContainer = styled.div`
  display: flex;
  min-width: fit-content;
`;

type OfferReceivedListingContainerProps = {
  listing: IListingGraphQL;
  offerClickDisabled?: boolean;
};

const OfferReceivedListingContainer: React.FC<OfferReceivedListingContainerProps> = ({
  listing,
  offerClickDisabled,
}) => {
  /** State */
  const [collapsed, setCollapsed] = React.useState(false);

  /** Hooks */
  const navigate = useNavigate();
  const isMobile = useMobileMedia();
  const menuRef = React.useRef();

  /** Actions */
  const dispatch = useAppDispatch();
  const selectListing = () => dispatch(
    ListingActions.selectListing({
      listingId: listing._id,
    }),
  );
  const goToListing = () => {
    selectListing();
    navigate(`/app/dashboard/listings/details/offers?listingId=${listing._id}`);
  };

  /** Render */
  const menuItems = [
    {
      text: collapsed ? 'Expand' : 'Collapse',
      onClick: (e) => {
        setCollapsed(!collapsed);
        e.stopPropagation();
      },
    },
    {
      text: 'Go to listing',
      onClick: (e) => {
        goToListing();
        e.stopPropagation();
      },
    },
  ];

  const offersArrayLength = listing?.offers?.length;
  return (
    <Container>
      <Flex
        justify="space-between"
        align="center"
        margin={collapsed ? '0 0px 8px' : '0px 0px 16px'}
      >
        <ListingDetailsContainer>
          <ListingImage src={ListingUtil.primaryImageUrl(listing)} />
          <ListingNameContainer>
            <Text type={TextTypesEnum.Bold14} color={Colors.Black}>
              {ListingUtil.name(listing, { allButAddress1: true })}
            </Text>
            <Text type={TextTypesEnum.Regular14Small} color={Colors.Black}>
              {offersArrayLength.toString()}
              {' '}
              total offer
              {offersArrayLength > 1 ? 's' : null}
            </Text>
          </ListingNameContainer>
        </ListingDetailsContainer>
        {isMobile ? (
          <div ref={menuRef} style={{ position: 'relative' }}>
            <SquareIconButton
              margin="0px 0px 0px 16px"
              icon={Icons.EllipsisHRegular}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <AnchoredMenu
              anchorRef={menuRef}
              menuItems={menuItems}
            />
          </div>
        ) : (
          <LinkContainer>
            <LinkText
              onClick={() => setCollapsed(!collapsed)}
              margin="0 16px 0"
              type={TextTypesEnum.Medium14}
            >
              {collapsed ? 'Expand' : 'Collapse'}
            </LinkText>
            <LinkText
              onClick={() => {
                selectListing();
                navigate(`/app/dashboard/listings/details/offers?listingId=${listing._id}`);
              }}
              type={TextTypesEnum.Medium14}
            >
              Go to listing
            </LinkText>
          </LinkContainer>
        )}
      </Flex>
      {!collapsed && (
        <>
          {listing?.offers?.slice(0, 3).map((offer) => (
            <OfferCard
              key={offer._id}
              offer={offer}
              hideOtherTerms={isMobile}
              offerClickDisabled={offerClickDisabled}
            />
          ))}
          {offersArrayLength > 3 && (
            <Flex justify="center" margin="16px 0px 8px">
              <LinkText
                onClick={() => {
                  goToListing();
                }}
                type={TextTypesEnum.Medium14}
              >
                Go to listing to view all
                {' '}
                {offersArrayLength}
                {' '}
                offers
              </LinkText>
            </Flex>
          )}
        </>
      )}
    </Container>
  );
};

export default OfferReceivedListingContainer;
