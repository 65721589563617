import React from 'react';
import styled from '@emotion/styled';
import { useRecoilValue, useRecoilCallback, useRecoilState } from 'recoil';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import { dataExplorerCityStateFilterStateAtom, dataExplorerMSAFilterStateAtom, dataExplorerZipFilterStateAtom } from './state-management/recoil/atoms/DataExplorerFiltersAtoms';
import DataExplorerFilterPropertyListDataControllersEnum from './@types/utility-bar-types/enums/DataExplorerFilterPropertyListDataControllersEnum';
import dataExplorerUtilityBarAtom from './state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import Checkbox from '../../elements/Checkbox';

const FlexItemContainer = styled.div`
display: flex;
gap: 8px;
border-radius: 4px;
padding: 4px;

&:hover {
  cursor: pointer;
  background-color: ${Colors.Brand100 || Colors.Brand100};
}
`;

type cityState = {
  city?: string;
  state?: string;
}

type FlexItemProps = {
    dataPoint: string;
    cityState?: cityState;
    msaTabActive: boolean;
    cityStateTabActive: boolean;
}

const GeographiesFlexItem: React.FC<FlexItemProps> = ({
  dataPoint,
  cityState,
  msaTabActive,
  cityStateTabActive,
}) => {
  /** Recoil State */
  const [, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);

  const selectedMSAState = useRecoilValue(dataExplorerMSAFilterStateAtom);
  const selectedZipState = useRecoilValue(dataExplorerZipFilterStateAtom);
  const selectedCityState = useRecoilValue(dataExplorerCityStateFilterStateAtom);

  const {
    msaNamesList: selectedMSANames,
  } = selectedMSAState;

  const {
    zipCodesList: selectedZipCodes,
  } = selectedZipState;

  const {
    city: selectedCities,
    state: selectedStates,
  } = selectedCityState;

  /** Actions */
  const handleSelectedMSA = useRecoilCallback(({ set }) => (dataPoint: string) => {
    if (selectedMSANames?.includes(dataPoint.toUpperCase())) {
      const filteredSelectedMSAs: string[] = selectedMSANames.filter((name: string) => name !== dataPoint.toUpperCase());
      set(dataExplorerMSAFilterStateAtom, (prevState) => ({
        ...prevState,
        msaNamesList: filteredSelectedMSAs,
      }));
    } else {
      const newSelectedMSAs: string[] = [...(selectedMSANames ?? []), dataPoint.toUpperCase()];
      set(dataExplorerMSAFilterStateAtom, (prevState) => ({
        ...prevState,
        msaNamesList: newSelectedMSAs,
      }));
    }
    setUtilityBarState((prevState) => ({
      ...prevState,
      filteredPropertyListQueryType: DataExplorerFilterPropertyListDataControllersEnum.TaxFilters,
      showFilterPropertyList: true,
      fetchFilterProperties: true,
    }));
  }, [selectedMSANames]);

  const handleSelectedZips = useRecoilCallback(({ set }) => (dataPoint: string) => {
    if (selectedZipCodes?.includes(dataPoint)) {
      const filteredSelectedZips: string[] = selectedZipCodes.filter((zip: string) => zip !== dataPoint);
      set(dataExplorerZipFilterStateAtom, (prevState) => ({
        ...prevState,
        zipCodesList: filteredSelectedZips,
      }));
    } else {
      const newSelectedZips: string[] = [...(selectedZipCodes ?? []), dataPoint];
      set(dataExplorerZipFilterStateAtom, (prevState) => ({
        ...prevState,
        zipCodesList: newSelectedZips,
      }));
    }
    setUtilityBarState((prevState) => ({
      ...prevState,
      filteredPropertyListQueryType: DataExplorerFilterPropertyListDataControllersEnum.TaxFilters,
      showFilterPropertyList: true,
      fetchFilterProperties: true,
    }));
  }, [selectedZipCodes]);

  const handleSelectedCityStates = useRecoilCallback(({ set }) => (cityState: cityState) => {
    if (selectedCities?.includes(cityState?.city)
      && selectedStates?.includes(cityState?.state)
      && Array.isArray(selectedCities)
      && Array.isArray(selectedStates)
    ) {
      const filteredSelectedCities: string[] = selectedCities.filter((city: string) => city !== cityState?.city);
      const filteredSelectedStates: string[] = selectedStates.filter((states: string) => states !== cityState?.state);
      set(dataExplorerCityStateFilterStateAtom, (prevState) => ({
        ...prevState,
        city: filteredSelectedCities,
        state: filteredSelectedStates,
      }));
    } else {
      const newSelectedCities: string[] = [...(selectedCities ?? []), cityState.city];
      const newSelectedStates: string[] = [...(selectedStates ?? []), cityState.state];
      set(dataExplorerCityStateFilterStateAtom, (prevState) => ({
        ...prevState,
        city: newSelectedCities,
        state: newSelectedStates,
      }));
    }
    setUtilityBarState((prevState) => ({
      ...prevState,
      filteredPropertyListQueryType: DataExplorerFilterPropertyListDataControllersEnum.TaxFilters,
      showFilterPropertyList: true,
      fetchFilterProperties: true,
    }));
  }, [selectedCities, selectedStates]);

  /** Actions */
  return typeof dataPoint !== 'object' && dataPoint !== null ? (
    <FlexItemContainer
      onClick={() => (msaTabActive
        ? handleSelectedMSA(dataPoint)
        : handleSelectedZips(dataPoint))}
      data-cy={dataPoint.replace(/\s+/g, '-').replace(/,/g, '').toLowerCase()}
    >
      <Checkbox
        onClick={() => (msaTabActive
          ? handleSelectedMSA(dataPoint)
          : handleSelectedZips(dataPoint))}
        active={
                msaTabActive
                  ? selectedMSANames?.includes(dataPoint.toUpperCase())
                  : selectedZipCodes?.includes(dataPoint)
                }
      />
      <Text type={TextTypesEnum.Medium14}>
        {dataPoint}
      </Text>
    </FlexItemContainer>
  ) : (
    <FlexItemContainer onClick={() => handleSelectedCityStates(cityState)}>
      <Checkbox
        onClick={() => handleSelectedCityStates(cityState)}
        active={cityStateTabActive && (selectedCities?.includes(cityState.city))}
      />
      <Text type={TextTypesEnum.Medium14}>
        {`${cityState.city}, ${cityState.state}`}
      </Text>
    </FlexItemContainer>
  );
};

export default GeographiesFlexItem;
