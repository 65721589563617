/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */

import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import Colors from '../../../styles/Colors';
import Icon, { Icons } from '../../../elements/Icon';
import UserInfo, { UserInfoTypesEnum } from '../../UserInfo';

const Container = styled.div`
  &:hover {
    cursor: pointer;

    #AnalyticsMarker {
      background-color: ${Colors.Black};
      border: 1px solid ${Colors.Black};
      z-index: 1;
    }
  }
`;

const AnalyticsMarker = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background-color: ${Colors.Grey700};
  border: 0.5px solid ${Colors.Grey500};
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  transition: all 0.1s;
  display: inline-flex;
  z-index: 1;
`;

export type AnalyticsMapMarkerProps = {
  user?: IUserGraphQL
  latlng?: google.maps.LatLng,
  lat?: number,
  lng?: number,
};

const AnalyticsMapMarker: React.FC<AnalyticsMapMarkerProps> = ({
  user,
}) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  return (
    <Container>
      <AnalyticsMarker
        id="AnalyticsMarker"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Icon icon={Icons.UserSolid} color={Colors.White} size={14} />
      </AnalyticsMarker>
      {hovered && (
      <UserInfo user={user} type={UserInfoTypesEnum.Map} />
      )}
    </Container>
  );
};

export default React.memo(AnalyticsMapMarker);
