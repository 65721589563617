import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as Polished from 'polished';
import IFile from '@biproxi/models/interfaces/IFile';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@biproxi/models/externalLinks';
import { ListingActions } from '../../redux/listing.redux';
import { useAppDispatch } from '../../redux/store';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Label from '../../elements/Label';
import CreateListingPhotoUpload from './CreateListingPhotoUpload';
import Colors from '../../styles/Colors';
import TranslucentButton, { TranslucentButtonTypesEnum } from '../../elements/TranslucentButton';
import { Icons } from '../../elements/Icon';
import BoxShadows from '../../styles/BoxShadows';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Flex, { CustomSpacer } from '../../elements/Flex';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { ListingImageTypeEnum } from '../modal/CropListingImageModal';

// CSS Id
const dragAndDropGridStyle = 'dragAndDropGridStyle';

const Container = styled.div``;

type ImageContainerProps = {
  error?: string;
}

const ImagesContainer = styled.div<ImageContainerProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 12px;
  column-gap: 24px;

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ImageContainer = styled.div`
  height: 144px;
  width: 214px;
  position: relative;
  cursor: grab;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

const SortableItemContainer = styled.div`
  z-index: 1;
  cursor: grab;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

const DefaultImageContainer = styled.div<{ color: string }>`
  border-radius: 4px;
  border: 4px solid ${({ color }) => color};
  box-sizing: border-box;
  height: 144px;
  width: 214px;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 0;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

const DefaultImageText = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  position: absolute;
  padding: 4px;
  border-radius: 4px 0 4px 0;
  z-index: 2;
  top: 0;

`;

const TopRight = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const ImageOverLay = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  border-radius: 4px;

  &:hover {
    background: ${Polished.rgba(Colors.Black, 0.1)};
    box-shadow: ${BoxShadows.Hard};
  }
`;

const Image = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const RelativeImageContainer = styled.div`
  position: relative;
`;

type CreateListingPhotosProps = {
  id?: string;
  dataCy?: string;
  listing: IListingGraphQL;
  error?: string;
};

const CreateListingPhotos: React.FC<CreateListingPhotosProps> = ({
  id,
  dataCy,
  listing,
  error,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));
  const popModal = () => dispatch(AppActions.popModal());

  const deleteListingMediaFiles = (fileId: string) => {
    dispatch(
      ListingActions.deleteListingMediaFiles({
        fileId,
      }),
    );
    popModal();
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Photo successfully deleted',
    });
  };

  const pushDeleteImageModal = (fileId: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete photo',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => deleteListingMediaFiles(fileId),
      },
    }),
  );

  const pushCropImageModal = (file: IFile, type: ListingImageTypeEnum) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CropListingImageModal,
      props: {
        file,
        type,
      },
    }),
  );

  const reOrderListingMediaFiles = (oldIndex: number, newIndex: number) => dispatch(
    ListingActions.reOrderListingMediaFiles({
      oldIndex,
      newIndex,
    }),
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    reOrderListingMediaFiles(oldIndex, newIndex);
  };

  /** Hooks */
  const isMobile = useMobileMedia();

  /* Render */
  const SortableItem = SortableElement(({ children }) => <SortableItemContainer>{children}</SortableItemContainer>) as any;

  const SortableList = SortableContainer(({ children }) => <ImagesContainer error={error}>{children}</ImagesContainer>) as any;

  return (
    <Container id={id}>
      <Flex justify="space-between">
        <Label
          text="Photos"
        />
        {error && <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{error}</Text>}
      </Flex>
      <SortableList
        axis="xy"
        onSortEnd={onSortEnd}
        distance={isMobile ? 0 : 1}
        helperClass={dragAndDropGridStyle}
        pressDelay={isMobile ? 200 : 0}
      >
        {listing?.media?.files?.map((file, index) => (
          <RelativeImageContainer>
            <SortableItem index={index} key={file._id}>
              <ImageContainer>
                <Image
                  src={file.url}
                >
                  <ImageOverLay />
                </Image>
                <TopRight>
                  <TranslucentButton
                    type={TranslucentButtonTypesEnum.Icon}
                    icon={Icons.TrashRegular}
                    onClick={() => {
                      pushDeleteImageModal(file._id);
                    }}
                  />
                  <CustomSpacer height="4px" />
                  <TranslucentButton
                    type={TranslucentButtonTypesEnum.Icon}
                    icon={Icons.CropAltRegular}
                    onClick={() => {
                      /**
                       * if its the first image, its of primary type.
                       * if it's images 2, 3, 4, 5 then it's a highlight type.
                       */
                      const type = index === 0
                        ? ListingImageTypeEnum.Primary
                        : (index > 0 && index <= 4)
                          ? ListingImageTypeEnum.Highlight
                          : ListingImageTypeEnum.Other;

                      pushCropImageModal(file, type);
                    }}
                  />
                </TopRight>
              </ImageContainer>
            </SortableItem>
            {index === 0 && (
            <>
              <DefaultImageText
                color={Colors.Green500}
              >
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.White}
                >
                  Primary Image
                </Text>
              </DefaultImageText>
              <DefaultImageContainer
                color={Colors.Green500}
              />
            </>
            )}
            {index > 0 && index <= 4 && (
            <>
              <DefaultImageText
                color={Colors.Yellow500}
              >
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.White}
                >
                  Highlight Image
                </Text>
              </DefaultImageText>
              <DefaultImageContainer
                color={Colors.Yellow500}
              />
            </>
            )}
            {/* listing?.media?.files?.length > 0 */}
          </RelativeImageContainer>
        ))}
        <CreateListingPhotoUpload dataCy={dataCy} listing={listing} />
      </SortableList>
      <Text type={TextTypesEnum.Regular12} color={Colors.Grey700} margin="4px 0 0">
        By uploading photos, you agree that you are the legal owner of these photos, and are in compliance with our
        &nbsp;
        <Text
          type={TextTypesEnum.Regular12}
          as={TextAsEnum.Span}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
        >
          Privacy Policy
        </Text>
        &nbsp;
        and
        &nbsp;
        <Text
          type={TextTypesEnum.Regular12}
          as={TextAsEnum.Span}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => window.open(TERMS_OF_SERVICE_URL, '_blank')}
        >
          Terms & Conditions
        </Text>
      </Text>
    </Container>
  );
};

export default CreateListingPhotos;
