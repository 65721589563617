import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import IChannelQuery from '@biproxi/models/interfaces/IChannelQuery';
import Loader, { LoaderSizes } from '../../../elements/Loader';
import Colors from '../../../styles/Colors';
import MessageTable, { MessageTableTypesEnum } from '../../MessageTable';
import NoContent from '../../NoContent';
import Icons from '../../../elements/Icons';
import LIST_CHANNELS from '../../../graphql/queries/channels.query';
import { useAppSelector } from '../../../redux/store';
import { ListingSelectors } from '../../../redux/listing.redux';
import useUpdateChannels from '../../../hooks/useUpdateChannels.hook';
import GenericCard from '../../../elements/GenericCard';
import { useMobileMedia, media } from '../../../utils/MediaQuery';
import LinkText from '../../../elements/LinkText';
import useNavigateToListingDetails from '../../../hooks/useNavigateToListingDetails.hook';

const Center = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Strange I know, but Mozilla browsers are
 * behaving weird with this flexbox and with long messages
 * they extend the whole parent container of the page
 * and this was the quickest solution I found.
 */
const TableContainer = styled.div`
  max-width: 33vw;

  ${media.mobile} {
    max-width: unset;
  }
`;

type RecentMessagesCardProps = {};

const RecentMessagesCard: React.FC<RecentMessagesCardProps> = () => {
  /** State */
  const listingId = useAppSelector(ListingSelectors.selectedListingId);

  /** Hooks */
  const navigateToListingDetails = useNavigateToListingDetails();

  /** GraphQL */
  interface Data {
    channels: IChannelGraphQL[];
  }

  interface Vars {
    params: IChannelQuery;
  }
  const { data, loading } = useQuery<Data, Vars>(LIST_CHANNELS, {
    variables: {
      params: {
        listingId,
        pagination: {
          limit: 5,
          cursor: '',
        },
      },
    },
    onError: (error) => {
      logger.error('LIST_CHANNELS error', error);
    },
  });

  const [channels, setChannels] = React.useState(data?.channels || []);

  /** Hooks */
  const isMobile = useMobileMedia();
  useUpdateChannels({ data, channels, setChannels });

  return (
    <GenericCard height="348px" width={isMobile ? '100%' : '33%'} margin={isMobile ? '16px 0 0 0' : '0px'}>
      <LinkText
        onClick={() => navigateToListingDetails(listingId, '/messages')}
      >
        Recent Messages
      </LinkText>
      {loading ? (
        <Center>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </Center>
      ) : (
        <>
          {channels?.length > 0 ? (
            <TableContainer>
              <MessageTable
                channels={channels?.slice(0, 5)} // if more channels come in through websocket, only show up to 5 most recent.
                type={MessageTableTypesEnum.Card}
              />
            </TableContainer>
          ) : (
            <Center>
              <NoContent
                height="100%"
                icon={Icons.CommentsAltLight}
                text="No messages yet."
              />
            </Center>
          )}
        </>
      )}
    </GenericCard>
  );
};

export default RecentMessagesCard;
