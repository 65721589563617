/* eslint-disable import/no-named-as-default */
import React from 'react';
import styled from '@emotion/styled';
import {
  Routes, Route, useNavigate,
  useLocation,
} from 'react-router-dom';
import { GetServerSideProps } from 'next';
import { RecoilRoot } from 'recoil';
/** Dashboard */
import AppUtil from '@biproxi/models/utils/AppUtil';
import { isDevelopment, isLocal, isTest } from '@biproxi/env';
import DashboardHome from '../spa-pages/DashboardHome.page';
import DashboardEvents from '../spa-pages/DashboardEvents.page';
import DashboardListings from '../spa-pages/DashboardListings.page';
import DashboardFavorites from '../spa-pages/DashboardFavorites.page';
import CreateListing from '../spa-pages/CreateListing.page';
import DataExplorer from '../spa-pages/DataExplorer.page';
import DashboardOffersSent from '../spa-pages/DashboardOffersSent.page';
import DashboardOffersReceived from '../spa-pages/DashboardOffersReceived.page';
import Search from '../spa-pages/Search.page';
import DashboardMessages from '../spa-pages/DashboardMessages.page';
import DashboardNotifications from '../spa-pages/DashboardNotifications.page';
import DashboardLayout from '../components/DashboardLayout';
import EventManagement from '../spa-pages/EventManagement.page';
import EventParticipation from '../spa-pages/EventParticipation.page';
import CreatePrivateEvent from '../spa-pages/CreatePrivateEvent.page';
import DashboardSettingsPage from '../spa-pages/DashboardSettings.page';
import SettingsAccountPage from '../spa-pages/SettingsAccount.page';
import SettingsBillingPage from '../spa-pages/SettingsBilling.page';
import SettingsInvoicePage from '../spa-pages/SettingsInvoice.page';
/** Listing Details */
import ListingDetailsOverview from '../spa-pages/ListingDetailsOverview.page';
import ListingDetailsLeads from '../spa-pages/ListingDetailsLeads.page';
import ListingDetailsInvestorMatches from '../spa-pages/ListingDetailsInvestorMatches.page';
import ListingDetailsMessages from '../spa-pages/ListingDetailsMessages.page';
import ListingDetailsOffers from '../spa-pages/ListingDetailsOffers.page';
/** Super Admin */
import SuperAdminLayout from '../components/SuperAdminLayout';
import SuperAdminUsers from '../spa-pages/SuperAdminUsers.page';
/** Admin Page */
import AdminLayout from '../components/AdminLayout';
import AdminPage from '../spa-pages/Admin.page';
/** Components */
import ListingDetailsContainer from '../containers/ListingDetails.container';
import NextUtil from '../utils/NextUtil';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import useUser from '../hooks/useUser.hook';
import PageError from '../components/PageError';
import Icons from '../elements/Icons';
import NextPageContainer from '../containers/NextPage.container';
// import trackSPAPageViews from '../hooks/trackSPAPageViews.hook';
// import scrollToTop from '../hooks/scrollToTop.hook';
import DashboardProfile from '../spa-pages/DashboardProfile.page';
import SavedSearches from '../spa-pages/SavedSearches.page';
import ListingPage from './listing/[listingId]/[slug]';
// auth
import ResetPasswordPage from '../spa-pages/ResetPassword.page';
import LoginPage from '../spa-pages/Login.page';
import RegisterPage from '../spa-pages/Register.page';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import { AppState, useAppSelector } from '../redux/store';
import TeamsPage from '../spa-pages/Teams.page';
import TeamPage from '../components/teams/TeamPage';
import TeamInvitationPage from '../components/teams/TeamInvitationPage';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fill-available;
`;

const SkeletonLoader = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Dashboard404 = () => {
  const navigate = useNavigate();
  return (
    <PageError
      icon={Icons.TimesRegular}
      header="We took a wrong turn..."
      body="We're sorry, but the place you are trying to access does not exist. Let's get you back to Biproxi."
      onClick={() => navigate('/app/dashboard/home')}
    />
  );
};

function App404() {
  return (
    <PageError
      icon={Icons.TimesRegular}
      header="We took a wrong turn.."
      body="We're sorry, but the place you are trying to access does not exist. Let's get you back to Biproxi."
    />
  );
}

function Unauthenticated401() {
  return (
    <PageError
      icon={Icons.TimesRegular}
      header="You're not authorized to be here"
      body="We're sorry, but you are not allowed to view this page. Let's get you back to Biproxi."
    />
  );
}

function DataExplorerWrapper() {
  return (
    <RecoilRoot>
      <DataExplorer />
    </RecoilRoot>
  );
}

type AppPageProps = {
  config: IPageConfiguration;
};

const AppPage: React.FC<AppPageProps> = () => {
  /**
   * Weird I know, but this makes our router work better
   * Talk to Sam before you remove this
   *
   */
  // const router = useNextRouter();
  // const history = useNavigate();
  const location = useLocation();

  // if (NextUtil.hasWindow() && history?.length) {
  //   router.pathname = window.location.pathname;
  //   location.pathname = window.location.pathname;
  //   scrollToTop();
  //   trackSPAPageViews();
  // }

  /** Redux - Contentful Variables */
  const {
    contentful: {
      brandCollection: {
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);

  /** Hooks */
  const { user, loading } = useUser() ?? {};

  if (!user && !loading && AppUtil.isInDashboard(location?.pathname)) {
    return <Unauthenticated401 />;
  }

  if (!NextUtil.hasWindow()) return null;

  if (!fetched && isLocal()) {
    return (
      <SkeletonLoader data-cy="page-loader-container">
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        <Text type={TextTypesEnum.Medium24} margin="18px 0 0">Loading Contentful Variables</Text>
      </SkeletonLoader>
    );
  }

  if (!fetched && isTest()) {
    return (
      <SkeletonLoader data-cy="page-loader-container">
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        <Text type={TextTypesEnum.Medium24} margin="18px 0 0">Loading Contentful Variables</Text>
      </SkeletonLoader>
    );
  }

  /** // TODO
   * Make a button that is stored in local storage
   * to turn off the colors on dev/local if wanted
   */

  /** Assuming we also run it for the dev site as well */
  if (!fetched && isDevelopment()) {
    return (
      <SkeletonLoader data-cy="page-loader-container">
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        <Text type={TextTypesEnum.Medium24} margin="18px 0 0">Loading Contentful Variables</Text>
      </SkeletonLoader>
    );
  }

  return (
    <NextPageContainer>
      <Container>
        <Routes>
          {/* DASHBOARD */}
          <Route
            element={<DashboardLayout />}
          >
            <Route
              path="app/dashboard/home"
              element={<DashboardHome />}
            />
            <Route
              path="app/dashboard/events"
              element={<DashboardEvents />}
            />
            <Route
              path="app/dashboard/messages"
              element={<DashboardMessages />}
            />
            <Route
              path="app/dashboard/notifications"
              element={<DashboardNotifications />}
            />
            <Route
              path="app/dashboard/profile"
              element={<DashboardProfile />}
            />
            <Route
              path="app/dashboard/favorites"
              element={<DashboardFavorites />}
            />
            <Route
              path="app/dashboard/data-explorer"
              element={<DataExplorerWrapper />}
            />
            <Route
              path="app/dashboard/offers-sent"
              element={<DashboardOffersSent />}
            />
            <Route
              path="app/dashboard/offers-received"
              element={<DashboardOffersReceived />}
            />
            <Route
              path="app/dashboard/saved-searches"
              element={<SavedSearches />}
            />
            <Route
              path="app/dashboard/search"
              element={<Search />}
            />
            <Route
              path="app/dashboard/settings"
              element={<DashboardSettingsPage />}
            />
            <Route
              path="app/dashboard/settings/account"
              element={<SettingsAccountPage />}
            />
            <Route
              path="app/dashboard/settings/billing"
              element={<SettingsBillingPage />}
            />
            <Route
              path="app/dashboard/settings/invoice"
              element={<SettingsInvoicePage />}
            />
            <Route
              path="app/dashboard/listings"
              element={<DashboardListings />}
            />
            <Route
              path="app/dashboard/events/create"
              element={<CreatePrivateEvent />}
            />
            <Route
              path="app/dashboard/events/manage"
              element={<EventManagement />}
            />
            <Route
              path="app/dashboard/events/participate"
              element={<EventParticipation />}
            />
            <Route
              path="/app/dashboard/teams"
              element={<TeamsPage />}
            />
            <Route
              path="/app/dashboard/teams/:teamId"
              element={<TeamPage />}
            />
            <Route
              path="/app/dashboard/teams/invitation/:teamId"
              element={<TeamInvitationPage />}
            />
            <Route
              path="app/dashboard/*"
              element={<Search />}
            />

            {/* LISTING DETAILS */}
            <Route
              element={<ListingDetailsContainer />}
            >
              <Route
                path="app/dashboard/listings/details/overview"
                element={<ListingDetailsOverview />}
              />
              <Route
                path="app/dashboard/listings/details/offers"
                element={<ListingDetailsOffers />}
              />
              <Route
                path="app/dashboard/listings/details/leads"
                element={<ListingDetailsLeads />}
              />
              <Route
                path="app/dashboard/listings/details/investor-matches"
                element={<ListingDetailsInvestorMatches />}
              />
              <Route
                path="app/dashboard/listings/details/messages"
                element={<ListingDetailsMessages />}
              />
            </Route>
          </Route>

          {/* SUPER ADMIN */}
          <Route
            element={<SuperAdminLayout />}
          >
            <Route
              path="/app/super-admin/users"
              element={<SuperAdminUsers />}
            />
            <Route
              path="/app/super-admin/*"
              element={<Dashboard404 />}
            />
          </Route>

          {/* ADMIN */}
          <Route
            element={<AdminLayout />}
          >
            <Route
              path="/app/admin/"
              element={<AdminPage />}
            />
            <Route
              path="/app/admin/*"
              element={<Dashboard404 />}
            />
          </Route>

          {/* Auth */}
          <Route
            path="/app/login"
            element={<LoginPage />}
          />
          <Route
            path="/app/register"
            element={<RegisterPage />}
          />
          <Route
            path="/app/reset-password"
            element={<ResetPasswordPage />}
          />

          {/* Other */}
          <Route
            path="/app/create-listing/*"
            element={<CreateListing />}
          />
          <Route
            path="/listing/*"
            element={<ListingPage config={null} listing={null} />}
          />
          <Route
            path="*"
            element={<App404 />}
          />
        </Routes>
      </Container>
    </NextPageContainer>
  );
};

export const getServerSideProps: GetServerSideProps<AppPageProps> = async (
  context,
) => {
  try {
    const config = await NextUtil.getPageConfiguration(context);

    return {
      props: {
        config,
        props: {},
      },
    };
  } catch {
    return {
      props: {
        config: {
          user: null,
          listing: null,
          lead: null,
          privateEvent: null,
          initialApolloState: null,
          initialReduxState: null,
          pageContext: null,
        },
        props: {},
      },
    };
  }
};

export default AppPage;
