/* eslint-disable no-useless-escape */
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { KB_CREATE_LISTING_DESC_URL } from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import RichTextEditor from '../RichTextEditor';

type CreateListingDescriptionProps = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingDescription: React.FC<CreateListingDescriptionProps> = ({
  id,
  listing,
}) => {
  /* State */
  const errors = useAppSelector(ListingSelectors.errors);
  const descriptionError = errors['/description'];

  /* Actions */
  const dispatch = useAppDispatch();

  const setListingField = (description: string) => dispatch(
    ListingActions.setListingField({ description }),
  );

  /* Render */
  return (
    <ContentCard
      id={id}
      dataCy="create-description-greeting"
      title="Description"
      subtitle="Enter the description of the property"
      learnMoreLink={KB_CREATE_LISTING_DESC_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <RichTextEditor
        value={listing?.description}
        onChange={(description: string) => {
          // value can have left over p tags when content
          // is cleared so we delete them manually.
          // Would be better to do this internally somehow.
          if (description.match(/^<p>\s*<\/p>$/)) {
            setListingField('');
          } else {
            setListingField(description);
          }
        }}
        placeholder="Create a description of your property"
        label="Description"
        error={descriptionError}
      />
    </ContentCard>
  );
};

export default CreateListingDescription;
