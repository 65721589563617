import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import SettingsContactInfo from '../components/settings/SettingsContactInfo';
import SettingsPassword from '../components/settings/SettingsPassword';
import SettingsIntegrations from '../components/settings/SettingsIntegrations';
import SettingsLicenses from '../components/settings/SettingsLicenses';
import SettingsNotifications from '../components/settings/SettingsNotifications';
import { media } from '../utils/MediaQuery';
import Divider from '../elements/Divider';
import useUserHook from '../hooks/useUser.hook';
import useNylas from '../hooks/useNylas.hook';
import SettingsHeader from '../components/settings/SettingsHeader';

const Container = styled.div`
  flex: 1;
  width: 100%;

  ${media.tablet} {
    margin: 16px;
    max-width: calc(100vw - 32px);
  }

  ${media.mobile} {
    max-width: calc(100vw - 32px);
  }
`;

const Spacer = styled.div`
  height: 16px;
`;

type SettingsAccountPageProps = {};

const SettingsAccountPage: React.FC<SettingsAccountPageProps> = () => {
  /** Hooks */
  const { user } = useUserHook();
  useNylas();

  /** Render */
  return (
    <Container id="Settings Container">
      <Text color={Colors.Black} type={TextTypesEnum.Bold24}>Settings</Text>
      <Spacer />
      <SettingsHeader />
      <SettingsContactInfo user={user} />
      <Divider />
      <SettingsPassword user={user} />
      <Divider />
      <SettingsIntegrations user={user} />
      <Divider />
      <SettingsLicenses user={user} />
      <Divider />
      <SettingsNotifications user={user} />
    </Container>
  );
};

export default SettingsAccountPage;
