import gql from 'graphql-tag';

/** Gets insight data for multiple properties */
const CHERRE_INSIGHTS_DEMO_DATA = gql`
    query CherreInsightsAndDemographicDataFromManyTaxIds ($params: CherreInsightsAndDemographicDataFromManyTaxIdsParams!){
        cherreInsightsAndDemographicDataFromManyTaxIds (params: $params) {
            insights
            demographics
            priorYearDemographics
        }
    }
`;

export default CHERRE_INSIGHTS_DEMO_DATA;
