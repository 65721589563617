import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import * as UrlUtil from '../../utils/UrlUtil';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import SettingsMobileNavDropdown from './SettingsMobileNavDropdown';
import { AppState, useAppSelector } from '../../redux/store';

const Container = styled.div`
  border-bottom: 1px solid ${Colors.Grey300};

  ${media.mobile} {
    width: calc(100vw - 32px);
  }
`;

const NavItem = styled.div<{ active: boolean }>`
  transition: all 0.2s;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 4px;
`;

const ButtonDropdownContainer = styled.div`
  width: 100%;
  margin: 0px 0px 16px 0px;
`;

const NavUnderline = styled.div<{ underlineColor?: string }>`
  width: 100%;
  position: absolute;
  height: 4px;
  background: ${(props) => props.underlineColor};
  bottom: 0;
  left: 0;
`;

const SkeletonContainer = styled.div`
  position: absolute;
`;

type ListingDetailsHeaderProps = {};

const ListingDetailsHeader: React.FC<ListingDetailsHeaderProps> = () => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** Contentful - Redux */
  const {
    contentful: {
      brandCollection: {
        colors,
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const [underlineColorLoad, setUnderlineColorLoad] = React.useState(true);

  const underlineColor = colors.Brand700 || colors.Blurple700;

  React.useEffect(() => {
    if (fetched) {
      setUnderlineColorLoad(false);
    }
  }, []);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  const navigate = useNavigate();
  const { pathname } = UrlUtil.parse(window.location.href);
  const isMobile = useMobileMedia();

  const navItemConfig: IButtonOptions[] = [
    {
      text: 'Account',
      onClick: () => navigate('/app/dashboard/settings/account'),
      active: ['/app/dashboard/settings/account'],
    },
    {
      text: 'Plans & Billing',
      onClick: () => navigate('/app/dashboard/settings/billing'),
      active: ['/app/dashboard/settings/billing'],
    },
    {
      text: 'Invoices',
      onClick: () => navigate('/app/dashboard/settings/invoice'),
      active: ['/app/dashboard/settings/invoice'],
    },
  ];

  return (
    <Container>
      <Flex direction="row" align="center" justify="space-between">
        {isMobile ? (
          <ButtonDropdownContainer>
            <SettingsMobileNavDropdown
              items={navItemConfig}
            />
          </ButtonDropdownContainer>
        ) : (
          <Flex>
            {navItemConfig.map((item, index) => {
              const active = item.active.includes(pathname);
              return (
                <NavItem data-cy={`${item.text.split(' ').join('-').toLowerCase()}`} onClick={item.onClick} active={active} key={index}>
                  <Text type={TextTypesEnum.Medium14} color={active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey500}>
                    {item.text}
                  </Text>
                  {active && underlineColorLoad ? (
                    <SkeletonContainer style={{
                      width: '100%', height: '4px', left: '0', bottom: '0',
                    }}
                    >
                      <Skeleton
                        width="100%"
                        height="100%"
                        style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                      />
                    </SkeletonContainer>
                  ) : active && <NavUnderline underlineColor={underlineColor} />}
                </NavItem>
              );
            })}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default ListingDetailsHeader;
