import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Link from './Link';
import BoxShadows from '../styles/BoxShadows';
import { media } from '../utils/MediaQuery';
import Icon, { Icons } from './Icon';
import Flex from './Flex';
import NextUtil from '../utils/NextUtil';

export enum ContentCardHeaderTypesEnum {
  BasicHeader = 'BasicHeader',
  RichHeader = 'RichHeader',
  PageHeader = 'PageHeader',
}

const Container = styled.div<{ type: ContentCardHeaderTypesEnum; preserveBorderRadius: boolean }>`
  position: relative;
  border-radius: 16px;
  border: 1px solid ${Colors.Grey300};
  background-color: ${Colors.White};
  width: fill-available;
  box-shadow: ${BoxShadows.Standard};

  ${media.mobile} {
    border-radius: ${(props) => (props.type === ContentCardHeaderTypesEnum.RichHeader && !props.preserveBorderRadius ? '0px' : '16px')};
  }
`;

export const Content = styled.div`
  position: relative;
  background-color: ${Colors.White};
  padding: 32px;
  border-radius: 0 0 16px 16px;

  ${media.mobile} {
    padding: 24px 16px;
  }
`;

const RichHeaderContainer = styled.div`
  padding: 32px;
  border-radius: 16px 16px 0 0;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Grey300};

  ${media.mobile} {
    padding: 24px 16px;
  }
`;

const PageHeaderContainer = styled.div`
  padding: 32px;
  border-radius: 16px;
  background-color: ${Colors.White};

  ${media.mobile} {
    padding: 24px 16px;
  }
`;

const BasicHeaderContainer = styled.div`
  padding: 16px 32px;
  border-radius: 16px 16px 0 0;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Grey300};

  ${media.mobile} {
    padding: 16px;
  }
`;

const HeaderTitle = styled.div`
  display: inline-block;
  border-bottom: 2px dashed ${Colors.Grey400} !important;
  margin-bottom: 4px;
`;

type ContentCardProps = {
  title: string;
  dataCy?: string;
  headerType: ContentCardHeaderTypesEnum;
  subtitle?: string | React.ReactElement;
  learnMoreLink?: string;
  preserveBorderRadius?: boolean;
  id?: string;
  children?: React.ReactNode,
};

const ContentCard: React.FC<ContentCardProps> = ({
  title,
  dataCy,
  headerType,
  subtitle,
  learnMoreLink,
  preserveBorderRadius,
  id,
  children,
}) => {
  /* Effects */
  // Doesn't seem to be working
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /** Render */
  return (
    <Container id={id} type={headerType} preserveBorderRadius={preserveBorderRadius}>
      {(() => {
        switch (headerType) {
          case ContentCardHeaderTypesEnum.BasicHeader:
            return (
              <BasicHeaderContainer>
                <Flex align="center">
                  <Text type={TextTypesEnum.Medium16} color={Colors.Black}>
                    {title}
                  </Text>
                </Flex>
              </BasicHeaderContainer>
            );
          case ContentCardHeaderTypesEnum.RichHeader:
            return (
              <RichHeaderContainer>
                {learnMoreLink ? (
                  <Link href={learnMoreLink} target="_blank">
                    <HeaderTitle>
                      <Flex align="center" tip="Click here to learn more">
                        <Text
                          data-cy={dataCy}
                          type={TextTypesEnum.Bold18}
                          color={Colors.Black}
                        >
                          {title}
                        </Text>
                        <Icon
                          icon={Icons.InfoSquareSolid}
                          size={14}
                          color={Colors.Grey400}
                          margin="0px 0px 0px 8px"
                        />
                      </Flex>
                    </HeaderTitle>
                  </Link>
                ) : (
                  <HeaderTitle>
                    <Text
                      type={TextTypesEnum.Bold18}
                      color={Colors.Black}
                    >
                      {title}
                    </Text>
                  </HeaderTitle>
                )}
                {subtitle && (
                  <>
                    {typeof subtitle === 'string' ? (
                      <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>
                        {subtitle}
                      </Text>
                    ) : (
                      <>
                        {subtitle}
                      </>
                    )}
                  </>
                )}
              </RichHeaderContainer>
            );
          case ContentCardHeaderTypesEnum.PageHeader:
            return (
              <PageHeaderContainer>
                <Link href={learnMoreLink} target="_blank">
                  <HeaderTitle>
                    <Flex align="center" tip="Click here to learn more">
                      <Text
                        type={TextTypesEnum.Bold24}
                        color={Colors.Black}
                      >
                        {title}
                      </Text>
                      <Icon
                        icon={Icons.InfoSquareSolid}
                        size={18}
                        color={Colors.Grey400}
                        margin="0px 0px 0px 8px"
                      />
                    </Flex>
                  </HeaderTitle>
                </Link>
                {subtitle && (
                  <>
                    {typeof subtitle === 'string' ? (
                      <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>
                        {subtitle}
                      </Text>
                    ) : (
                      <>
                        {subtitle}
                      </>
                    )}
                  </>
                )}
              </PageHeaderContainer>
            );
          default:
            return null;
        }
      })()}
      {children && (
        <Content>
          {children}
        </Content>
      )}
    </Container>
  );
};

export default ContentCard;
