import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import CherrePropertyTypesEnum from '@biproxi/models/enums/CherrePropertyTypesEnum';
import CherreAssetClassPropertyTypesObject from '@biproxi/models/objects/CherreAssetClassPropertyTypes.object';
import CherreAssetClassesEnum from '@biproxi/models/enums/CherreAssetClassesEnum';
import Text, { TextTypesEnum } from '../../../../elements/Text';
import Flex from '../../../../elements/Flex';
import Checkbox from '../../../../elements/Checkbox';
import Colors from '../../../../styles/Colors';
import Icon from '../../../../elements/Icon';
import Icons from '../../../../elements/Icons';
import { dataExplorerPropertyTypeFiltersAtom, IPropertyTypeFilters } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

const FilterSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FilterSection = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ active }) => (active ? Colors.Brand50 || Colors.Blurple50 : null)};
  border-radius: 4px;
  padding: 8px;
  flex: 1;
`;

const FilterSubSection = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

type PropertyTypeFiltersProps = {}

const PropertyTypeFilters: React.FC<PropertyTypeFiltersProps> = () => {
  /** State / Context */

  /** Recoil */
  const dataExplorerPropertyTypeFilters = useRecoilValue(dataExplorerPropertyTypeFiltersAtom);
  const {
    expandedPropertyType,
    selectedAssetClasses,
    selectedPropertyTypes,
  } = dataExplorerPropertyTypeFilters;

  /** Callbacks */
  const setExpandedPropertyTypeCallback = useRecoilCallback(({ set }) => ({ propertyType } : { propertyType: CherrePropertyTypesEnum }) => {
    set(dataExplorerPropertyTypeFiltersAtom, (prevState) => ({
      ...prevState,
      expandedPropertyType: propertyType,
    }));
  }, [expandedPropertyType]);

  const setSelectedAssetClassCallback = useRecoilCallback(({ set }) => ({ assetClass } : { assetClass: CherrePropertyTypesEnum }) => {
    if (selectedAssetClasses?.includes(assetClass)) {
      set(dataExplorerPropertyTypeFiltersAtom, (prevState) => ({
        ...prevState,
        selectedAssetClasses: selectedAssetClasses.filter((selectedPropertyType: CherrePropertyTypesEnum) => selectedPropertyType !== assetClass),
        // Also want to unselect all property types associated with the asset class
        selectedPropertyTypes: [...selectedPropertyTypes.filter((propertyType: CherreAssetClassesEnum) => !CherreAssetClassPropertyTypesObject[assetClass]?.includes(propertyType))],
      }));
    } else {
      set(dataExplorerPropertyTypeFiltersAtom, (prevState) => ({
        ...prevState,
        selectedAssetClasses: [
          ...(selectedAssetClasses ?? []),
          assetClass,
        ],
        // Also want to select all the property types associated with the asset class by default
        selectedPropertyTypes: [...(selectedPropertyTypes ?? []), ...CherreAssetClassPropertyTypesObject[assetClass]?.map((propertyType: CherreAssetClassesEnum) => propertyType)],
      }));
    }
  }, [selectedAssetClasses, expandedPropertyType]);

  const setSelectedPropertyTypeCallback = useRecoilCallback(({ set }) => ({ propertyType } : { propertyType: CherreAssetClassesEnum }) => {
    if (selectedPropertyTypes?.includes(propertyType)) {
      set(dataExplorerPropertyTypeFiltersAtom, (prevState) => ({
        ...prevState,
        selectedPropertyTypes: selectedPropertyTypes.filter((selectedPropertyType: CherreAssetClassesEnum) => selectedPropertyType !== propertyType),
      }));
      // Also want to automatically unselect associated asset class if all associated property types are unselected
      if (CherreAssetClassPropertyTypesObject[expandedPropertyType]?.some((propertyType: CherreAssetClassesEnum) => !selectedPropertyTypes?.includes(propertyType))) {
        set(dataExplorerPropertyTypeFiltersAtom, (prevState: IPropertyTypeFilters) => ({
          ...prevState,
          selectedAssetClasses: [...selectedAssetClasses?.filter((assetClass: CherrePropertyTypesEnum) => assetClass !== expandedPropertyType)],
        }));
      }
    } else {
      set(dataExplorerPropertyTypeFiltersAtom, (prevState) => ({
        ...prevState,
        selectedPropertyTypes: [
          ...(selectedPropertyTypes ?? []),
          propertyType,
        ],
        selectedAssetClasses: [...(selectedAssetClasses ?? []), expandedPropertyType],
      }));
    }
  }, [selectedPropertyTypes, expandedPropertyType]);

  return (
    <Flex
      flex="2"
      gap="32px"
      // padding={isMobile ? '0 0px' : '0'}
    >
      <FilterSectionsWrapper
        data-cy="PropertyTypeContainer"
      >
        {Object.keys(CherrePropertyTypesEnum).map((propertyTypeKey: CherrePropertyTypesEnum, index: number) => {
          const sectionValue = CherrePropertyTypesEnum[propertyTypeKey];
          return (
            <FilterSection
              data-cy={`${CherrePropertyTypesEnum[propertyTypeKey]}FilterSection`}
              key={index}
              active={sectionValue === expandedPropertyType}
              onClick={() => setExpandedPropertyTypeCallback({ propertyType: sectionValue })}
            >
              <Checkbox
                active={selectedAssetClasses?.includes(sectionValue)}
                dataCy={`${CherrePropertyTypesEnum[propertyTypeKey]}Checkbox`}
                onClick={() => setSelectedAssetClassCallback({ assetClass: sectionValue })}
              />
              <Flex
                width="100%"
                justify="space-between"
              >
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.Grey900}
                >
                  {sectionValue}
                </Text>
                <Icon icon={Icons.AngleRightRegular} color={Colors.Grey400} size={12} />
              </Flex>
            </FilterSection>
          );
        })}
      </FilterSectionsWrapper>

      {expandedPropertyType && (
        <FilterSectionsWrapper>
          {CherreAssetClassPropertyTypesObject[expandedPropertyType]?.map((propertyType: CherreAssetClassesEnum, index: number) => (
            <FilterSubSection
              key={index}
              data-cy={`${propertyType.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join('').replace('-', '')
                .split('/')
                .join('')}`}
            >
              <Checkbox
                active={selectedPropertyTypes?.includes(propertyType)}
                onClick={() => setSelectedPropertyTypeCallback({ propertyType })}
              />
              <Text
                type={TextTypesEnum.Medium12}
                color={Colors.Grey900}
              >
                {propertyType}
              </Text>
            </FilterSubSection>
          ))}
        </FilterSectionsWrapper>
      )}
    </Flex>

  );
};

export default PropertyTypeFilters;
