import React from 'react';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IListLeadsParams } from '@biproxi/models/services/ILeadService';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { isEmpty } from 'lodash';
import LIST_LEADS from '../graphql/queries/leads.query';
import useListing from '../hooks/useListing.hook';
import LeadsTableSearchBar from '../components/LeadsTableSearchBar';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import { useAppDispatch } from '../redux/store';
import { LeadActions } from '../redux/lead.redux';
import UPDATE_INVESTORS from '../graphql/mutations/updateInvestorMatches.mutation';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import { AppActions } from '../redux/app.redux';
import Button, { ButtonTypesEnum } from '../elements/Button';
import Flex from '../elements/Flex';
import InvestorMatchesTable from '../components/InvestorMatchesTable';
import useUser from '../hooks/useUser.hook';
import useUserPermissions from '../hooks/useUserPermissions.hook';
import CHECK_INVESTORS from '../graphql/mutations/checkInvestorMatches.mutation';

const Container = styled.div``;

const LoaderContainer = styled.div`
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ListingDetailsInvestorMatchesProps = {};

const ListingDetailsInvestorMatches: React.FC<ListingDetailsInvestorMatchesProps> = () => {
  /** State */
  const [moreMatches, setMoreMatches] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(false);
  /* Hooks */
  const { listing } = useListing();
  const { userId } = useUser();
  const { userBiproxiRolesAndPermissions, loading: isUserPermissionsHookLoading } = useUserPermissions({ userId });
  /** Actions */
  const dispatch = useAppDispatch();
  const cacheLeads = (leads: ILeadGraphQL[]) => dispatch(LeadActions.cacheLeads({ leads }));
  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  /** GraphQL */
  interface Data {
    leads: ILeadGraphQL[];
  }

  interface Vars {
    params: IListLeadsParams;
  }

  /** Query */
  const { data: matches, loading: matchesLoad } = useQuery<Data, Vars>(LIST_LEADS, {
    skip: !listing,
    variables: {
      params: {
        listingId: listing?._id,
        isInvestorMatches: true,
        // refreshOption: true,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ leads }) => {
      cacheLeads(leads);
    },
  });

  const [checkMatches] = useMutation(CHECK_INVESTORS, {
    variables: {
      params: {
        listingId: listing?._id as string,
      },
    },
    onCompleted({ checkInvestorMatches }) {
      const { newMatches } = checkInvestorMatches;
      if (newMatches) {
        setMoreMatches(true);
      } else {
        setMoreMatches(false);
      }
    },
  });

  const [updateInvestorMatches, { loading: updateLoad }] = useMutation(UPDATE_INVESTORS, {
    variables: {
      params: {
        listingId: listing?._id as string,
      },
    },
    refetchQueries: [{
      query: LIST_LEADS,
      // fetchPolicy: 'no-cache',
      // notifyOnNetworkStatusChange: true,
      variables: {
        params: {
          listingId: listing?._id,
          isInvestorMatches: true,
          // refreshOption: true,
        },
      },
    }],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    onCompleted: ({ updateInvestorMatches }) => {
      const { moreMatches } = updateInvestorMatches;
      if (!moreMatches) {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'No more Investor Matches',
        });
      } else {
        pushToast({
          type: ToastTypesEnum.Notification,
          message: 'Investor Matches Updated',
        });
      }
    },
  });

  React.useEffect(() => {
    /** Check if we can show the button */
    checkMatches();
    setInitialLoad(true);
  }, []);

  React.useEffect(() => {
    /** Check investors for button again just in case 0_0 */
    checkMatches();
    if (matches?.leads) cacheLeads(matches.leads);
  }, [matches]);

  /**
   * There were major issues with a lot of stuff in this file and corresponding code paths
   * and it is in the process of being reworking and fixed. The code corrupts the data in the database.
   * Do not uncommment the get more matches stuff unless you know what you are doing.
   */
  const hasInvestorMatchPermission = PermissionsUtil.canUserViewInvestorMatches(userBiproxiRolesAndPermissions);
  if ((isUserPermissionsHookLoading)) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  if ((initialLoad && matchesLoad)) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  /** if update load is started and array is empty */
  if (updateLoad && isEmpty(matches?.leads)) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  if (!listing) {
    return (
      <NoContent
        height="176px"
        icon={Icons.LinkRegular}
        text="Listing not found"
      />
    );
  }

  /* Render */
  return (
    <Container>
      {matches?.leads?.length > 0 ? (
        <>
          <LeadsTableSearchBar
            isInvestorMatchSearchBar
          />
          <InvestorMatchesTable
            hasInvestorMatchPermission={hasInvestorMatchPermission}
            updateLoad={updateLoad}
            update={updateInvestorMatches}
            matches={matches?.leads}
            moreMatches={moreMatches}
          />
        </>
      ) : (
        <>
          <NoContent
            height="176px"
            padding="30px 0px 0px 0px"
            icon={Icons.LinkRegular}
            text={(
              <>
                No investor matches at time of publishing.
                {/* Click the button below to see if you have any new matches! */}
                <Flex justify="center" margin="20px">
                  {moreMatches && !matchesLoad && (
                  <Button
                    data-cy="get-more-investors"
                    disabled={!moreMatches && (true)}
                    type={ButtonTypesEnum.Primary}
                    text="Get more investor matches"
                    onClick={updateInvestorMatches}
                  />
                  )}
                </Flex>
              </>
              )}
          />
        </>
      )}
    </Container>
  );
};

export default ListingDetailsInvestorMatches;
