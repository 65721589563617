import React from 'react';
import styled from '@emotion/styled';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useDebounceCallback } from '@react-hook/debounce';
import ListingScoreRubric from '@biproxi/models/objects/ListingScoreRubric.object';
import ScoreUtil from '@biproxi/models/utils/ScoreUtil';
// import { useNavigate } from 'react-router-dom';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import PermissionUtil from '@biproxi/models/utils/PermissionUtil';
import PermissionTypesEnum from '@biproxi/models/enums/PermissionTypesEnum';
import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import ListingLoaderTypesEnum from '../../models/enums/ListingLoaderTypesEnum';
import { AppActions } from '../../redux/app.redux';
import ChangeUtil from '../../utils/ChangeUtil';
import { useMobileMedia } from '../../utils/MediaQuery';
import { ModalTypesEnum } from '../modal/Modal';
import useUser from '../../hooks/useUser.hook';
import UPDATE_LISTING_UPLOAD from '../../graphql/mutations/updateListingUpload.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import useUserPermissions from '../../hooks/useUserPermissions.hook';
// import UPDATE_LISTING_UPLOAD from '../../graphql/mutations/updateListingUpload.mutation';
// import useUser from '../../hooks/useUser.hook';
// import { IToastConfig, ToastTypesEnum } from '../Toast';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

type CreateListingActionsProps = {};

const CreateListingActions: React.FC<CreateListingActionsProps> = () => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const { userId, userEmail } = useUser();
  const { userOrganizationsRolesAndPermissions } = useUserPermissions({ userId });
  const navigate = useNavigate();

  /* State */
  const [eventListener, setEventListener] = React.useState<boolean>(false);
  const listing = useAppSelector(ListingSelectors.selectedListing);
  const initialLoading = listing === null;
  const showPreview = useAppSelector(ListingSelectors.showPreview);
  const loading = useAppSelector(ListingSelectors.loading);
  const isUpdateLoading = loading[ListingLoaderTypesEnum.UpdateListing];
  const isNotPublished = ListingUtil.isNotPublished(listing);
  const isScheduled = ListingUtil.isScheduled(listing);
  const userOrgListings = userOrganizationsRolesAndPermissions?.map((org) => org.listingConnections?.edges?.map((edge) => edge?.node?._id))?.flat();
  const isAdmin = listing?.state === ListingStateEnum.Admin || (listing?.user?._id !== userId && PermissionUtil.hasPermission(userEmail, PermissionTypesEnum.Admin) && !userOrgListings?.includes(listing?._id));

  /** GraphQL */
  interface UpdateListingUploadVars {
    params: Partial<IListingUploadParams>
  }

  interface UpdateListingUploadData {
      updateListingUpload: IListingUploadGraphQL
  }

  const [updateListingUpload] = useMutation<UpdateListingUploadData, UpdateListingUploadVars>(UPDATE_LISTING_UPLOAD, {
    onCompleted: () => {
      navigate('/app/admin');
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error updating the listing upload. Please try again.',
      });
    },
  });

  /* Actions */
  const dispatch = useAppDispatch();
  const updateListing = () => dispatch(ListingActions.updateListing({ listing, toast: true }));
  const togglePreview = () => dispatch(ListingActions.setShowPreview({ showPreview: !showPreview }));
  const checkRequiredFields = () => ListingUtil.checkRequiredFields(listing);

  const pushCreateListingActionsModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateListingActions,
      props: {
        listing,
      },
    }),
  );
  const setListingScore = (score: number) => {
    dispatch(ListingActions.setListingScore({ score }));
  };

  const pushSaveChangesModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.SaveChanges,
    props: {
      listing,
    },
  }));

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const setListingScoreDebounced = useDebounceCallback(setListingScore, 250);

  const handlePublishListing = () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.publishListing({ listing, publishAt: null, expiresAt: null }),
      );
    } else {
      pushCreateListingActionsModal();
    }
  };

  const handleSaveListingChanges = () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.updateListing({ listing }),
      );
    } else {
      updateListing();
    }
  };

  const handleAdminSaveListingChanges = async () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.updateListing({ listing }),
      );
    } else {
      await dispatch(
        ListingActions.updateListing({ listing: { ...listing, state: ListingStateEnum.ReadyForReview }, toast: true }),
      );
      updateListingUpload({
        variables: {
          params: {
            listingId: listing._id,
            state: ListingUploadStateEnum.WaitingForReview,
          },
        },
      });
    }
  };

  /** Effects */

  /** Update the listing score upon change */
  React.useEffect(() => {
    const effect = async () => {
      if (listing) {
        const hasChanges = await ChangeUtil.hasListingChanged(listing);
        if (hasChanges) setListingScoreDebounced(ScoreUtil.scoreRubric(listing, ListingScoreRubric));
      }
    };
    effect();
  }, [listing]);

  const handleUnloadListener = React.useCallback((e) => {
    e.preventDefault();
    if (eventListener) setEventListener(false);
    else {
      e.returnValue = 'Any unsaved changes will be lost. Are you sure you want to continue?';
    }
  }, [setEventListener]);

  // Warn user before they leave the page that unsaved changes will be lost.
  React.useEffect(() => {
    if (!eventListener) {
      window.addEventListener('beforeunload', handleUnloadListener);
    } else {
      window.removeEventListener('beforeunload', handleUnloadListener);
    }
    return () => window.removeEventListener('beforeunload', handleUnloadListener);
  }, [eventListener]);

  /* Render */
  const MenuButton = React.memo(() => (
    <Button
      data-cy="publish-listing-action-button"
      text={isScheduled ? 'Change schedule' : 'Publish'}
      type={ButtonTypesEnum.Primary}
      size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
      isLoading={initialLoading}
      onClick={handlePublishListing}
      isFullWidth
    />
  ));

  const SaveButton = React.memo(() => (
    <Button
      text="Save changes"
      onClick={handleSaveListingChanges}
      isLoading={isUpdateLoading}
      type={ButtonTypesEnum.Primary}
      size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
      isFullWidth
    />
  ));

  // yeah, i know this button's functionality is basically 1:1 with the SaveButton but I wanted to create a separately named component for admin stuff
  const AdminSave = React.memo(() => (
    <Button
      text="Request review"
      onClick={handleAdminSaveListingChanges}
      isLoading={isUpdateLoading}
      type={ButtonTypesEnum.Primary}
      size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
      isFullWidth
    />
  ));

  if (isAdmin) {
    return (
      <Container>
        <Button
          text="Exit"
          data-cy="exit-listing-button"
          onClick={() => pushSaveChangesModal()}
          type={ButtonTypesEnum.Outline}
          size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
          margin="0 8px 0 0"
          isFullWidth
        />
        <Button
          text={showPreview ? 'Edit' : 'Preview'}
          onClick={() => togglePreview()}
          type={ButtonTypesEnum.Outline}
          size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
          margin="0 8px 0 0"
          isFullWidth
        />
        <AdminSave />
      </Container>
    );
  }

  return (
    <Container>
      <Button
        text="Exit"
        data-cy="exit-listing-button"
        onClick={() => pushSaveChangesModal()}
        type={ButtonTypesEnum.Outline}
        size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
        margin="0 8px 0 0"
        isFullWidth
      />
      <Button
        text={showPreview ? 'Edit' : 'Preview'}
        onClick={() => togglePreview()}
        type={ButtonTypesEnum.Outline}
        size={isMobile ? ButtonSizesEnum.Large : ButtonSizesEnum.Medium}
        margin="0 8px 0 0"
        isFullWidth
      />
      {
        isNotPublished ? (
          <MenuButton />
        ) : (
          <SaveButton />
        )
      }
    </Container>
  );
};

export default CreateListingActions;
