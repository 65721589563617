import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import SettingsBillingPlanCard from './BillingPlanCard';
import { media } from '../../utils/MediaQuery';
import Loader, { LoaderSizes } from '../../elements/Loader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
  box-sizing: border-box;
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 16px 0px;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0px 0px 16px 0px;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type SettingsBillingPlanProps = {
  user: IUserGraphQL;
  userSubscriptionType: BillingPlanIdEnum;
  billingPlans: string[];
  userOnFreemium: boolean;
  isDataLoading?: boolean;
}

const SettingsBillingPlan: React.FC<SettingsBillingPlanProps> = ({
  user,
  userSubscriptionType,
  billingPlans,
  userOnFreemium,
  isDataLoading = false,
}) => {
  /** Render */
  const userSpecificPlans = UserUtil.filterPlansBasedOnRole(user, billingPlans);
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Plans
          </Text>
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          View plans and manage your subscription.
        </Text>
      </SectionTitle>
      {isDataLoading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <PlansContainer>
          {userSpecificPlans?.map((plan, index) => {
            const planItemPriceId = plan?.item_price?.id;
            const isActiveSubscription = Boolean(
              userSubscriptionType === planItemPriceId
              || (planItemPriceId === BillingPlanIdEnum.FreemiumMonthly && userOnFreemium),
            );
            return (
              <SettingsBillingPlanCard
                key={index}
                plan={plan}
                active={isActiveSubscription}
              />
            );
          })}
        </PlansContainer>
      )}
    </Container>
  );
};

export default SettingsBillingPlan;
