import React from 'react';
import styled from '@emotion/styled';
import ILeadQuery from '@biproxi/models/interfaces/ILeadQuery';
import { useMutation } from '@apollo/client';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import IFile from '@biproxi/models/interfaces/IFile';
import LeadStatesEnum from '@biproxi/models/enums/LeadStatesEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Colors from '../styles/Colors';
import Icons from '../elements/Icons';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  LeadActions, LeadSelectors,
} from '../redux/lead.redux';
import AnchoredMenu from '../elements/AnchoredMenu';
import GENERATE_LEADS_CSV from '../graphql/mutations/generateLeadsCSV.mutation';
import { ListingSelectors } from '../redux/listing.redux';
import { useMobileMedia } from '../utils/MediaQuery';

const Container = styled.div`
  margin-bottom: 8px;
  display: flex;
`;

type LeadsTableSearchBarProps = {
  isInvestorMatchSearchBar?: boolean;
};

const LeadsTableSearchBar: React.FC<LeadsTableSearchBarProps> = ({ isInvestorMatchSearchBar }) => {
  /* State */
  const menuAnchorRef = React.useRef();
  const listingId = useAppSelector(ListingSelectors.selectedListingId);
  const query = useAppSelector(LeadSelectors.searchQuery);
  const { term, leadType } = query;
  const investorMatchesOrLeads = isInvestorMatchSearchBar ? 'investor matches' : 'Leads';

  /** Hooks */
  const isMobile = useMobileMedia();

  const menuItems = Object.values(LeadStatesEnum).map((state: LeadStatesEnum) => ({
    text: state,
    onClick: () => {
      setSearchQuery({ leadType: state });
    },
  }));

  menuItems.unshift({
    text: 'All' as any,
    onClick: () => {
      setSearchQuery({ leadType: null });
    },
  });

  /* Actions */
  const dispatch = useAppDispatch();

  const setSearchQuery = (query: Partial<ILeadQuery>) => dispatch(
    LeadActions.setLeadSearchQuery(query),
  );

  /* GraphQL */
  type TPayload = ILeadService.TGenerateLeadsCSVPayload;

  type TData = {
    generateLeadsCSV: IFile;
  };

  const [generateLeadsCSV, { loading: exportLoading }] = useMutation<TData, TPayload>(GENERATE_LEADS_CSV, {
    variables: {
      params: {
        listingId,
        query,
        isInvestorMatches: isInvestorMatchSearchBar ?? false,
      },
    },
    onCompleted: ({ generateLeadsCSV }) => {
      window.open(generateLeadsCSV.url);
    },
  });

  /* Render */
  return (
    <Container>
      <Input
        inputType={InputTypesEnum.Search}
        value={term}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery({ term: e.target.value });
        }}
        placeholder={`Search ${investorMatchesOrLeads}`}
        inputSize={InputSizeEnum.Small}
        margin="0 8px 0 0"
        clear={() => setSearchQuery({ term: '' })}
      />
      {!isInvestorMatchSearchBar && (
        <>
          <div ref={menuAnchorRef}>
            <Button
              text={leadType || 'All'}
              rightIcon={Icons.AngleDownRegular}
              iconColor={Colors.Grey900}
              iconSize={12}
              onClick={() => {

              }}
              type={ButtonTypesEnum.Pale}
              size={ButtonSizesEnum.Medium}
            />
          </div>
          <AnchoredMenu
            anchorRef={menuAnchorRef}
            menuItems={menuItems}
          />
        </>
      )}
      {!isMobile && (
      <>
        {/* {isInvestorMatchSearchBar && (
          <Button
            text="Get more matches"
            onClick={() => {
              refetchMatchesCallback();
            }}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            isLoading={refetchMatchesLoading}
          />
          )} */}
          {!isInvestorMatchSearchBar && ( // hidden for now for investor matches
            <Button
              text={exportLoading ? 'Generating...' : 'Export list'}
              icon={Icons.FileExportRegular}
              iconColor={Colors.Grey900}
              iconSize={12}
              onClick={() => {
                generateLeadsCSV();
              }}
              type={ButtonTypesEnum.Pale}
              size={ButtonSizesEnum.Medium}
              margin="0 0 0 8px"
              isLoading={exportLoading}
              loaderColor={Colors.Brand700 || Colors.Blurple700}
            />
          )}
      </>
      )}
    </Container>
  );
};

export default LeadsTableSearchBar;
