import React from 'react';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useAppDispatch } from '../redux/store';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import { AppActions } from '../redux/app.redux';

type UsePrivateEventCountdown = {
  time: number;
  blinkText: boolean;
  isLessThanTwoMinutesLeft: boolean;
  curEndsAt: number | null;
};

type UsePrivateEventCountdownHook = (privateEvent: IPrivateEventGraphQL, refetchPrivateEvent: () => void) => UsePrivateEventCountdown;

const usePrivateEventCountdown: UsePrivateEventCountdownHook = (privateEvent, refetchPrivateEvent) => {
  /** State */
  const [time, setTime] = React.useState(TimeUtil.now());
  const [curEndsAt, setCurEndsAt] = React.useState(null);
  const [blinkText, setBlinkText] = React.useState(false);

  /** Actions */
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** Effects */

  /**
   * Re-render every second to show real time countdown
   * if the private event is live.
   */
  React.useEffect(() => {
    let interval;
    if (privateEvent.state === PrivateEventStatesEnum.InProgress && privateEvent.endsAt > TimeUtil.now()) {
      interval = setInterval(() => setTime(TimeUtil.now()), 1000);
      setCurEndsAt(privateEvent?.endsAt);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Show toast if time increases
   */
  React.useEffect(() => {
    if (curEndsAt && (privateEvent?.endsAt > curEndsAt)) {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Time has increased.',
      });
      setCurEndsAt(privateEvent?.endsAt);
      setBlinkText(false);
    }
  }, [privateEvent?.endsAt]);

  const isLessThanTenSecondsLeft = (privateEvent.endsAt - TimeUtil.now() > 0)
  && (privateEvent.endsAt - TimeUtil.now() <= TimeUtil.TimeEnumInMiliseconds.SECOND * 10);
  const isLessThanTwoMinutesLeft = (privateEvent.endsAt - TimeUtil.now() > 0)
  && (privateEvent.endsAt - TimeUtil.now() <= TimeUtil.TimeEnumInMiliseconds.MINUTE * 2);

  // uncomment here and on backend for testing overtiem feature
  // const isLessThanTwoMinutesLeft = (privateEvent.endsAt - TimeUtil.now() > 0)
  // && (privateEvent.endsAt - TimeUtil.now() <= TimeUtil.TimeEnumInMiliseconds.DAY * 400);

  /**
   * Flash countdown text every half second if less than 10 seconds left
   */
  React.useEffect(() => {
    let interval;
    if (isLessThanTenSecondsLeft) {
      interval = setInterval(() => setBlinkText((x) => !x), 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isLessThanTenSecondsLeft]);

  /**
   * Auto refetch Private Event when tab is brought into view to
   * prevent user from seeing stale data.
  */
  // not really part of the count down but used in multiple places so placed here
  // as a convenience
  React.useEffect(() => {
    window.addEventListener('focus', refetchPrivateEvent);
    return () => {
      window.removeEventListener('focus', refetchPrivateEvent);
    };
  }, []);

  return {
    time,
    blinkText,
    isLessThanTwoMinutesLeft,
    curEndsAt,
  };
};

export default usePrivateEventCountdown;
