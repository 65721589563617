import axios from 'axios';
import { config } from '@biproxi/env';

const queryUserCurrentSubscription = async (userId: string, userEmail: string) => {
  const res = await axios(`/api/billing/subscription?id=${userId}&email=${userEmail}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const currentSubscription = res.data;
  return currentSubscription;
};

export default queryUserCurrentSubscription;
