import React from 'react';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import CheckboxList from '../../elements/CheckBoxList';
import { TextTypesEnum } from '../../elements/Text';
import { CheckboxTypesEnum } from '../../elements/Checkbox';

type ProfileTargetAssetClassesQuestionProps = {
  user: IUserGraphQL;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const ProfileTargetAssetClassesQuestion: React.FC<ProfileTargetAssetClassesQuestionProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  const config = Object.values(ListingAssetClassEnum).map((item) => {
    const assets = user?.investorProfile?.targetAssetClasses;
    const active = assets?.includes(item);
    return {
      label: item,
      labelTextType: TextTypesEnum.Medium16,
      active,
      type: CheckboxTypesEnum.Large,
      onClick: () => {
        setUserInvestorProfileField({
          targetAssetClasses: active
            ? assets?.filter((assetClass) => assetClass !== item)
            : assets
              ? [...assets, item]
              : [item],
        });
      },
    };
  });

  /* Render */
  return (
    <CheckboxList
      data-cy="asset-class"
      title="What asset class or use types are you interested in?"
      config={config}
    />
  );
};

export default ProfileTargetAssetClassesQuestion;
