import gql from 'graphql-tag';

const CHERRE_PROPERTY_OWNERS = gql`
    query PropertyOwnersData($params: CherrePropertyOwnersDataParams!) {
        cherrePropertyOwnersData(params: $params) {
            unmaskedOwners
            recordedOwners
        }
    }
`;

export default CHERRE_PROPERTY_OWNERS;
