import React from 'react';
import styled from '@emotion/styled';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import { useMutation } from '@apollo/client';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import BoxShadows from '../styles/BoxShadows';
import { media, useMobileMedia } from '../utils/MediaQuery';
import ListingUploadCardThumbnail from './ListingUploadCardThumbnail';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Icon, { Icons } from '../elements/Icon';
import DELETE_LISTING_UPLOAD from '../graphql/mutations/deleteListingUpload.mutation';
import LIST_LISTING_UPLOADS from '../graphql/queries/listingUploads.query';
import { IToastConfig, ToastTypesEnum } from './Toast';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import ListingStatus from './ListingStatus';
import ListingThumbnail from './ListingCardThumbnail';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import { ListingActions } from '../redux/listing.redux';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  border: 0.5px solid ${Colors.Grey300};
  transition: all 0.2s;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin: 0px 0px 8px 0px;
  box-shadow: ${BoxShadows.Light};

  &:hover {
      box-shadow: ${BoxShadows.Hard};
      border: ${`0.5px solid ${Colors.Grey400}`};
  }

  ${media.mobile} {
    flex-direction: column;
  }

  #OverrideLeftPadding {
    padding-left: 0px;
  }
`;

const ListingUploadInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
  justify-content: space-around;
  max-width: 500px;
  white-space: pre-wrap;

  ${media.mobile} {
   margin: 0;
  }
`;

const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${Colors.Grey300};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 8px 0 0;
  padding: 4px 8px;
`;

const ListingUploadCardButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;

  ${media.mobile} {
    margin-top: 16px;
    position: initial;
  }
`;

const Dot = styled.span`
  color: ${Colors.Grey400};
`;

type ListingUploadCardProps = {
    listingUpload: IListingUploadGraphQL;
}

const ListingUploadCard: React.FC<ListingUploadCardProps> = ({
  listingUpload,
}) => {
  /** State */
  const listingUploadState = listingUpload.state;
  const {
    month, year, day,
  } = TimeUtil.date(listingUpload.meta.lastUpdatedAt);
  const listing = listingUpload?.listing;
  const listingSqft = listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value ?? null;
  const isReadyForReview = listingUploadState === ListingUploadStateEnum.WaitingForReview || listing?.state === ListingStateEnum.ReadyForReview;

  /** Hooks */
  const isMobile = useMobileMedia();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushConfirmChangeModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.ConfirmChange,
    props: {
      type: ConfirmChangeModalTypesEnum.Warning,
      title: 'Delete document',
      text: 'Are you sure? You cannot undo this action afterwards.',
      buttonText: 'Delete',
      confirm: () => handleDeleteListingUpload(),
    },
  }));

  const selectListing = () => dispatch(
    ListingActions.selectListing({
      listingId: listing._id,
    }),
  );

  const setListingPreview = () => dispatch(
    ListingActions.setShowPreview({ showPreview: true }),
  );

  /** GraphQL */
  interface DeleteListingUploadVars {
    params: {
      listingUploadId: string;
    }
  }

  interface DeleteListingUploadData {
      deleted: boolean;
  }

  const [deleteListingUpload] = useMutation<DeleteListingUploadData, DeleteListingUploadVars>(DELETE_LISTING_UPLOAD, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'You have deleted the listing',
      });
      popModal();
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error deleting the listing. Please try again.',
      });
    },
    refetchQueries: [{
      query: LIST_LISTING_UPLOADS,
      variables: {
        params: {
          queryType: ListingUploadQueryTypesEnum.Personal,
        },
      },
    }],
  });

  /** Functions */
  const handleDeleteListingUpload = () => {
    deleteListingUpload({
      variables: {
        params: {
          listingUploadId: listingUpload._id,
        },
      },
    });
  };

  const handleListingReview = () => {
    selectListing();
    setListingPreview();
    navigate(`${ListingUtil.createListingPath(listingUpload.listingId)}&preview=true`);
  };

  /** Render */
  const ListingCardHeaders = {
    [ListingUploadStateEnum.NotStarted]: () => (
      <Text
        type={TextTypesEnum.Bold16}
        margin={isMobile ? '8px 0' : '0px'}
      >
        {`${listingUpload.listingName} review has not started`}
      </Text>
    ),
    [ListingUploadStateEnum.WaitingForReview]: () => (
      <Flex align="center" justify="flex-start">
        <Text
          type={TextTypesEnum.Bold16}
          margin={isMobile ? '8px 8px 8px 0' : '0 8px 0 0'}
        >
          {listingUpload.listingName}
        </Text>
        <ListingStatus status={ListingStateEnum.ReadyForReview} />

      </Flex>
    ),
    [ListingUploadStateEnum.InProgress]: () => (
      <Text
        type={TextTypesEnum.Bold16}
        margin={isMobile ? '8px 0' : '0px'}
      >
        {`${listingUpload.listingName} is being processed`}
      </Text>
    ),
  };

  return (
    <Container>
      {isReadyForReview ? (
        <ListingThumbnail
          type={ListingCardTypesEnum.Personal}
          listing={listing as any}
          width={isMobile ? '100%' : '146px'}
        />
      ) : (
        <ListingUploadCardThumbnail
          listingUpload={listingUpload}
          width={isMobile ? '100%' : '146px'}
        />
      )}

      {/* <Flex
        align="flex-start"
        justify="space-between"
        direction={isMobile ? 'column' : 'row'}
      > */}
      <ListingUploadInfoContainer>
        {ListingCardHeaders[listingUploadState]()}
        {isReadyForReview ? (
          <>
            <Flex align="center" margin="4px 0 0">
              {listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey700} skWidth={280}>
                {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, listing?.name ? {} : { address1: true }) || null}
              </Text>
            </Flex>
            <Flex align="center" margin="4px 0 0">
              <Text
                type={TextTypesEnum.Regular12}
                color={Colors.Grey700}
                skWidth={240}
              >
                {(() => {
                  if (!listing?.assetClass) return null;
                  return (
                    <>
                      {listing?.assetClass}
                      <Dot>&nbsp;&bull;&nbsp;</Dot>
                      {listing?.propertyType}
                      {Boolean(listingSqft) && (
                        <>
                          <Dot>&nbsp;&bull;&nbsp;</Dot>
                          {`${StringUtil.formatNumber(listingSqft as number)} sq. ft.`}
                        </>
                      )}
                    </>
                  );
                })()}
              </Text>
            </Flex>
          </>

        ) : (
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Grey700}
            lineHeight="16px"
          >
            The uploaded document may take up to
            {' '}
            <b>48 hours</b>
            {' '}
            to process.
            Once its finished, your listing will be ready for you to review and publish.
          </Text>
        )}

        {!isMobile && (
          <Flex>
            <StatusInfo>
              <Text
                type={TextTypesEnum.Regular12}
                color={Colors.Grey500}
              >
                Assignee
                {' '}
                <b>{listingUploadState === ListingUploadStateEnum.NotStarted || !listingUpload?.adminInfo?.email?.length ? 'Unassigned' : listingUpload?.adminInfo.email}</b>
              </Text>
            </StatusInfo>
            <StatusInfo>
              <Text
                type={TextTypesEnum.Regular12}
                margin="0 4px 0 0"
              >
                {isReadyForReview ? 'Finished' : 'Started'}
                {' '}
                <b>{`${month}/${day}/${year}`}</b>
              </Text>
            </StatusInfo>
          </Flex>
        )}

      </ListingUploadInfoContainer>
      <ListingUploadCardButtonsContainer>
        {isReadyForReview && (
          <>
            <Flex
              margin="0 16px 0 0"
              onClick={() => handleListingReview()}
            >
              <Icon
                icon={Icons.ExternalLinkRegular}
                color={Colors.Grey900}
                margin="0 8px 0 0"
                size={isMobile ? '16px' : '12px'}
              />
              <Text
                type={TextTypesEnum.Medium12}
                color={Colors.Grey900}
              >
                Review
              </Text>
            </Flex>
            <Flex
              onClick={() => pushConfirmChangeModal()}
            >
              <Icon
                icon={Icons.TrashSolid}
                color={Colors.Grey900}
                margin="0 8px 0 0"
                size={isMobile ? '16px' : '12px'}
              />
              <Text
                type={TextTypesEnum.Medium12}
                color={Colors.Grey900}
              >
                Delete
              </Text>
            </Flex>
          </>

        )}

      </ListingUploadCardButtonsContainer>
      {/* </Flex> */}
    </Container>
  );
};

export default ListingUploadCard;
