interface IListingOverviewAnalytics {
    [ListingOverviewAnalyticsEnum.TotalListingViews]: number,
    [ListingOverviewAnalyticsEnum.UniqueListingViews]: number,
    [ListingOverviewAnalyticsEnum.TotalListingLeads]: number,
    [ListingOverviewAnalyticsEnum.TotalCAsSigned]: number,
    overviewAnalyticsByListingId?: any, // fix lol
}

export enum ListingOverviewAnalyticsEnum {
  TotalListingViews = 'totalListingViews',
  UniqueListingViews = 'uniqueListingViews',
  TotalListingLeads = 'totalListingLeads',
  TotalCAsSigned = 'totalCAsSigned',
}

export default IListingOverviewAnalytics;
