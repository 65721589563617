import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Icon, { Icons } from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import AnchoredMenu, { MenuItems } from '../elements/AnchoredMenu';
import NextUtil from '../utils/NextUtil';
import ListingUploadAdminDocuments from './ListingUploadAdminDocuments';
import NoContent from './NoContent';

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

type ListingUploadContainerProps = {
  isExpanded?: boolean;
  listingUploadState: ListingUploadStateEnum
}

const ListingUploadContainer = styled.div<ListingUploadContainerProps>`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    margin-top: 12px;
    background-color: ${({ listingUploadState }) => {
    switch (listingUploadState) {
      case ListingUploadStateEnum.NotStarted:
        return Colors.Red50;
      case ListingUploadStateEnum.WaitingForReview:
        return Colors.Green50;
      case ListingUploadStateEnum.Published:
        return Colors.Green100;
      case ListingUploadStateEnum.InProgress:
      default:
        return Colors.Yellow50;
    }
  }};
    border-radius: 4px 4px 0px 0px;
    border-radius: ${({ isExpanded }) => (isExpanded ? '4px 4px 0px 0px' : '4px')};
`;

const ListingUploadInformation = styled.div`
    display: flex;
    flex-direction: column;
`;

const ListingUploadHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

type ListingUploadsTableMobileProps = {
    uploadedListings: IListingUploadGraphQL[];
    menuItems: MenuItems[];
    setClickedListing: React.Dispatch<React.SetStateAction<IListingUploadGraphQL>>;
};

const ListingUploadsTableMobile: React.FC<ListingUploadsTableMobileProps> = ({
  uploadedListings,
  menuItems,
  setClickedListing,
}) => {
  /** State */
  const [expandedListings, setExpandedListings] = React.useState<string[]>([uploadedListings[0]?.listingId]);

  /** Functions */
  const expandUploadedListing = (listingId: string) => {
    if (expandedListings.includes(listingId)) {
      setExpandedListings(expandedListings.filter((id) => id !== listingId));
    } else {
      setExpandedListings([...expandedListings, listingId]);
    }
  };

  /** Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /** Render */
  return (
    <BodyContainer>
      {!uploadedListings?.length && (
        <NoContent
          height="100%"
          icon={Icons.EyeRegular}
          text="There are no uploaded listings"
        />
      )}
      {uploadedListings?.map((uploadedListing: IListingUploadGraphQL) => {
        const {
          listingId, additionalInfo, state, user, media, adminInfo, listingName,
        } = uploadedListing;

        const { fileIds } = media;
        const { email: adminEmail } = adminInfo;

        const menuRef: React.RefObject<HTMLDivElement> = React.createRef();
        const { email } = user;
        const isListingUploadExpanded = expandedListings.includes(listingId);
        //   const truncatedAdditionalInfo = additionalInfo.length > 50 ? `${additionalInfo.substring(0, 50)}...` : additionalInfo;
        return (
          <>
            <ListingUploadContainer
              isExpanded={isListingUploadExpanded}
              listingUploadState={state}
            >
              <ListingUploadInformation>
                <ListingUploadHeader>
                  <Text
                    color={Colors.Brand700 || Colors.Blurple700}
                    type={TextTypesEnum.Medium14}
                  >
                    {listingId}
                  </Text>
                  <Flex
                    align="center"
                  >
                    <div ref={menuRef}>
                      <Icon
                        size="12px"
                        icon={Icons.EllipsisHRegular}
                        color={Colors.Grey400}
                        margin="0 16px 0 0"
                        onClick={() => {
                          setClickedListing(uploadedListing);
                        }}
                      />
                      <AnchoredMenu
                        anchorRef={menuRef}
                        menuItems={menuItems}
                      />
                    </div>
                    <Icon
                      size="12px"
                      icon={isListingUploadExpanded ? Icons.AngleUpRegular : Icons.AngleDownRegular}
                      color={Colors.Grey400}
                      onClick={() => expandUploadedListing(listingId)}
                    />
                  </Flex>
                </ListingUploadHeader>
                {/* I Apologize for the annoying repeated code below
                  I tried to make a reusable component for this but it ended up being more work than necessary and the code ended up being quite messy, so I opted for this :/ */}
                <Flex direction="column" margin="0 0 8px 0">
                  <Text
                    color={Colors.Brand700 || Colors.Blurple700}
                    type={TextTypesEnum.Regular12}
                  >
                    Broker
                  </Text>
                  <Text
                    color={Colors.Grey900}
                    type={TextTypesEnum.Regular14}
                  >
                    {email}
                  </Text>
                </Flex>
                {isListingUploadExpanded && (
                <>
                  <Flex direction="column" margin="0 0 8px 0">
                    <Text
                      color={Colors.Brand700 || Colors.Blurple700}
                      type={TextTypesEnum.Regular12}
                    >
                      Listing name
                    </Text>
                    <Text
                      color={Colors.Grey900}
                      type={TextTypesEnum.Regular14}
                    >
                      {listingName}
                    </Text>
                  </Flex>
                  <Flex direction="column" margin="0 0 8px 0">
                    <Text
                      color={Colors.Brand700 || Colors.Blurple700}
                      type={TextTypesEnum.Regular12}
                    >
                      Assignee
                    </Text>
                    <Text
                      color={Colors.Grey900}
                      type={TextTypesEnum.Regular14}
                    >
                      {adminEmail?.length ? adminEmail : 'Unassigned'}
                    </Text>
                  </Flex>
                  <Flex direction="column" margin="0 0 8px 0">
                    <Text
                      color={Colors.Brand700 || Colors.Blurple700}
                      type={TextTypesEnum.Regular12}
                    >
                      Description
                    </Text>
                    <Text
                      color={Colors.Grey900}
                      type={TextTypesEnum.Regular14}
                    >
                      {additionalInfo}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      color={Colors.Brand700 || Colors.Blurple700}
                      type={TextTypesEnum.Regular12}
                    >
                      Status
                    </Text>
                    <Text
                      color={Colors.Grey900}
                      type={TextTypesEnum.Regular14}
                    >
                      {state}
                    </Text>
                  </Flex>
                </>
                )}
              </ListingUploadInformation>
            </ListingUploadContainer>
            {(fileIds?.length && isListingUploadExpanded) && (
            <ListingUploadAdminDocuments uploadedListing={uploadedListing} />
            )}
          </>
        );
      })}
    </BodyContainer>
  );
};

export default ListingUploadsTableMobile;
