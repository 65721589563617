import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { ISavedSearchGraphQL } from '@biproxi/models/interfaces/ISavedSearch';
import LIST_SAVED_SEARCHES from '../graphql/queries/savedSearches.query';
import Text, { TextTypesEnum } from '../elements/Text';
import Icons from '../elements/Icons';
import NoContent from '../components/NoContent';
import Colors from '../styles/Colors';
import Loader, { LoaderSizes } from '../elements/Loader';
import Flex from '../elements/Flex';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Divider from '../elements/Divider';
import SavedSearchCard from '../components/SavedSearchCard';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import { SaveSearchModalTypeEnum } from '../components/modal/SaveSearchModal';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import { useAppDispatch } from '../redux/store';
import SquareIconButton from '../elements/SquareIconButton';
import { useMobileMedia } from '../utils/MediaQuery';

const Container = styled.div``;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type SavedSearchPageProps = {};

const SavedSearchesPage: React.FC<SavedSearchPageProps> = () => {
  /** State */
  const [searchQuery, setSearchQuery] = React.useState('');

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  /** GraphQL */
  const { data, loading, refetch } = useQuery(LIST_SAVED_SEARCHES);

  const savedSearches: ISavedSearchGraphQL[] = data?.savedSearches?.filter((savedSearch) => savedSearch.name.includes(searchQuery));

  const handleSaveSearchClick = (savedSearchType: SaveSearchModalTypeEnum) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.SaveSearch,
      props: {
        type: savedSearchType,
        refetchSavedSearches: refetch,
      },
    }),
  );

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <Container>
      <Flex justify="space-between">
        <Text data-cy="saved-searches-greeting" color={Colors.Black} type={TextTypesEnum.Bold24}>Saved Searches</Text>
        {isMobile ? (
          <SquareIconButton
            icon={Icons.SaveRegular}
            iconColor={Colors.White}
            backgroundColor={Colors.Brand700 || Colors.Blurple700}
            iconSize={16}
            onClick={() => handleSaveSearchClick(SaveSearchModalTypeEnum.Create)}
            size="40px"
            margin="0px 8px 0px 0px"
          />
        ) : (
          <Button
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            text="Save search"
            onClick={() => {
              handleSaveSearchClick(SaveSearchModalTypeEnum.Create);
            }}
          />
        )}

      </Flex>
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {savedSearches?.length ? (
            <Flex direction="column">
              <Input
                placeholder="Search saved searches"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
                clear={() => setSearchQuery('')}
                inputType={InputTypesEnum.Search}
                inputSize={InputSizeEnum.Small}
                width="100%"
                margin="16px 0"
              />
              {
              savedSearches.map((savedSearch: ISavedSearchGraphQL, index) => (
                <div key={index}>
                  <SavedSearchCard
                    savedSearch={savedSearch}
                    refetchSavedSearches={refetch}
                  />
                  {index !== savedSearches.length - 1 && (
                    <Divider />
                  )}
                </div>

              ))
            }
            </Flex>
          ) : (
            <NoContent
              height="176px"
              icon={Icons.SaveRegular}
              text={(
                <>
                  No saved searches yet.&nbsp;
                  {/* <Link href="https://support.biproxi.com/how-to-favorite-properties" isExternal target="_blank">
                    <LinkText type={TextTypesEnum.Bold14}>Learn more</LinkText>
                  </Link>
                  . */}
                  {/* Add Link text in later if there are docs on saved searches */}
                </>
          )}
            />
          )}
        </>
      )}

    </Container>
  );
};

export default SavedSearchesPage;
