import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import IChannelQuery from '@biproxi/models/interfaces/IChannelQuery';
import Text, { TextTypesEnum } from '../elements/Text';
import Loader, { LoaderSizes } from '../elements/Loader';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Colors from '../styles/Colors';
import MessageTable from '../components/MessageTable';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import LIST_CHANNELS from '../graphql/queries/channels.query';
import useUpdateChannels from '../hooks/useUpdateChannels.hook';
// import SendBirdClientSDK from '../utils/SendBirdClientSDK';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import { media } from '../utils/MediaQuery';
// import { AppState, useAppSelector } from '../redux/store';

const Container = styled.div`
  ${media.tablet} {
    padding: 16px 16px;
  }
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

type DashboardMessagesProps = {};

const DashboardMessages: React.FC<DashboardMessagesProps> = () => {
  /** State */
  const [search, setSearch] = React.useState('');

  /** GraphQL */
  interface Data {
    channels: IChannelGraphQL[];
  }

  interface Vars {
    params: IChannelQuery;
  }

  const { data, loading, fetchMore } = useQuery<Data, Vars>(LIST_CHANNELS, {
    variables: {
      params: {
        search,
        pagination: {
          limit: PaginationLimitsEnum.Channels,
          cursor: '',
        },
      },
    },
    context: {
      debounceKey: 'QUERY_CHANNELS',
    },
  });

  const [channels, setChannels] = React.useState(data?.channels || []);

  /** Actions */
  // const { chat: { unreadMessageCount } } = useAppSelector((state: AppState) => state);

  /** Hooks */
  useUpdateChannels({ data, channels, setChannels });

  // Issue with blue dot sometimes... Need to fix before uncommenting.
  // const handleReadMessages = () => {
  //   SendBirdClientSDK.markAllChannelsAsRead();
  // };

  /** Render */
  return (
    <Container>
      <PageHeadingContainer>
        <Text
          data-cy="messages-greeting"
          color={Colors.Black}
          type={TextTypesEnum.Bold24}
        >
          Messages
        </Text>
        {/* {unreadMessageCount > 0 && (
          <Text color={Colors.Brand700 || Colors.Blurple700} onClick={() => handleReadMessages()}>Mark all as read</Text>
        )} */}
      </PageHeadingContainer>
      <Input
        margin="24px 0 16px"
        placeholder="Search Messages"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        clear={() => setSearch('')}
        inputType={InputTypesEnum.Search}
        inputSize={InputSizeEnum.Small}
        width="100%"
      />
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          {channels?.length > 0 ? (
            <MessageTable
              fetchMore={fetchMore}
              channels={channels}
              search={search}
            />
          ) : (
            <NoContent
              height="176px"
              icon={search ? Icons.SearchRegular : Icons.CommentAltLinesLight}
              text={search
                ? 'No search results. Please try different search terms.'
                : 'No messages yet. Messages you have sent or received will appear here.'}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardMessages;
