import React from 'react';
import {
  useRecoilState, useRecoilValue,
} from 'recoil';
import styled from '@emotion/styled';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import { InputSizeEnum, InputThemeEnum } from '../../elements/Input';
import Colors from '../../styles/Colors';
import DataExplorerAddressSearch from './DataExplorerAddressSearch';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Icons from '../../elements/Icons';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import dataExplorerUtilityBarAtom from './state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import { dataExplorerGlobalAtom } from './state-management/recoil/atoms/DataExplorerGlobalContext';
import { DataExplorerDataFrameGeneralStateType, dataFrameGeneralAtom } from './state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';

const UtilityBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 32px;
    background: ${Colors.Brand50 || Colors.Blurple50};
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);

    ${media.mobile} {
      /* width: 100vw; */
      flex-direction: column;
      height: fit-content;
      gap: 12px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;

    ${media.mobile} {
      justify-content: space-between;
      width: 100%;
    }
`;

type UtilityBarProps = {};

/**
 * The top utiltiy bar within data explorer that contains the search bar, filters, and data settings
 */
const UtilityBar: React.FC<UtilityBarProps> = () => {
  /** Recoil  */
  const [dataFrameGeneralState, setDataFrameGeneralState] = useRecoilState(dataFrameGeneralAtom);
  const [_dataExplorerGlobalState, setDataExplorerGlobalState] = useRecoilState(dataExplorerGlobalAtom);
  const {
    dataFrameVisible,
  } = dataFrameGeneralState;

  const [utilityBarState, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);
  const {
    propertyFiltersVisible,
    geographiesVisible,
    dataSettingsVisible,
  } = utilityBarState;
  const showTableButtonClick = () => {
    setDataFrameGeneralState((prevState: DataExplorerDataFrameGeneralStateType) => ({
      ...prevState,
      similarPropertyDataExpanded: true,
    }));
    setDataExplorerGlobalState(() => ({ mapVisible: false }));
  };
  const hideTableButtonClick = () => {
    setDataFrameGeneralState((prevState: DataExplorerDataFrameGeneralStateType) => ({
      ...prevState,
      similarPropertyDataExpanded: true,
    }));
    setDataExplorerGlobalState(() => ({ mapVisible: true }));
  };

  const dataExplorerGlobalState = useRecoilValue(dataExplorerGlobalAtom);
  const {
    mapVisible,
  } = dataExplorerGlobalState;
  const isMobile = useMobileMedia();

  const dataExplorerDataFrame = useRecoilValue(dataFrameGeneralAtom);
  const {
    dataFrameLoading,
  } = dataExplorerDataFrame;

  const dispatch = useAppDispatch();
  const openBillingPaywallModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.DataExplorer,
      },
    }),
  );

  const {
    user: {
      userPermissions,
    },
  } = useAppSelector((state: AppState) => state);

  const canUserViewDataExplorerData = PermissionsUtil.canUserViewDataExplorerData(userPermissions?.userBiproxiRolesAndPermissions);

  const handleUtilityBarButtonClick = (utilityBarStateToChange) => (!canUserViewDataExplorerData ? openBillingPaywallModal() : setUtilityBarState((prevState) => ({
    ...prevState,
    ...utilityBarStateToChange,
  })));

  return (
    <UtilityBarWrapper
      data-cy="utility-bar-wrapper"
    >
      <DataExplorerAddressSearch
        placeholder="Search Data Explorer"
        width="inherit"
        showUnit={false}
        inputSize={InputSizeEnum.Fitted}
        inputTheme={InputThemeEnum.Light}
      />
      <ButtonWrapper>
        <Button
          customWidth={isMobile ? '80px' : null}
          text={!isMobile ? 'Geographies' : null}
          type={ButtonTypesEnum.BlurpleGhost}
          size={ButtonSizesEnum.Small}
          iconSize={12}
          icon={Icons.GlobeAmericasSolid}
          iconColor={Colors.Brand700 || Colors.Blurple700}
          data-cy="data-explorer-geographies-button"
          onClick={() => handleUtilityBarButtonClick({ geographiesVisible: !geographiesVisible })}
        />
        <Button
          customWidth={isMobile ? '80px' : null}
          icon={Icons.VerticalSliders}
          iconSize={12}
          iconColor={Colors.Brand700 || Colors.Blurple700}
          size={ButtonSizesEnum.Small}
          type={ButtonTypesEnum.BlurpleGhost}
          data-cy="data-explorer-filters-button"
          text={!isMobile ? 'Filters' : null}
          onClick={() => handleUtilityBarButtonClick({ propertyFiltersVisible: !propertyFiltersVisible })}
        />
        <Button
          customWidth={isMobile ? '80px' : null}
          text={!isMobile ? 'Data settings' : null}
          type={ButtonTypesEnum.BlurpleGhost}
          size={ButtonSizesEnum.Small}
          icon={Icons.LayerGroupSolid}
          iconSize={12}
          iconColor={dataFrameVisible ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey200}
          onClick={() => handleUtilityBarButtonClick({ dataSettingsVisible: !dataSettingsVisible })}
          disabled={!dataFrameVisible}
          data-cy="data-explorer-data-settings"
        />
        {mapVisible ? (
          <Button
            data-cy="data-explorer-show-table"
            customWidth={isMobile ? '80px' : null}
            text={!isMobile ? 'Similar properties' : null}
            type={ButtonTypesEnum.BlurpleGhost}
            size={ButtonSizesEnum.Small}
            icon={Icons.faList}
            iconSize={12}
            iconColor={(!dataFrameVisible || dataFrameLoading) ? Colors.Grey200 : Colors.Brand700 || Colors.Blurple700}
            onClick={() => (!canUserViewDataExplorerData ? openBillingPaywallModal() : showTableButtonClick())}
            disabled={!dataFrameVisible || dataFrameLoading}
          />
        ) : (
          <Button
            customWidth={isMobile ? '80px' : null}
            text={!isMobile ? 'Similar properties' : null}
            type={ButtonTypesEnum.HideTable}
            size={ButtonSizesEnum.Small}
            icon={Icons.faList}
            iconSize={12}
            iconColor={Colors.White}
            onClick={() => hideTableButtonClick()}
            disabled={!dataFrameVisible}
            data-cy="data-explorer-show-table"
          />
        )}

      </ButtonWrapper>
    </UtilityBarWrapper>
  );
};

export default UtilityBar;
