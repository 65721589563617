import React from 'react';
import styled from '@emotion/styled';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import EconomicScaleEnum from '@biproxi/models/enums/info-fields/EconomicScaleEnum';
import OwnershipEnum from '@biproxi/models/enums/info-fields/OwnershipEnum';
import BuildingClassEnum from '@biproxi/models/enums/info-fields/BuildingClassEnum';
import CreditRatingAgencyEnum from '@biproxi/models/enums/info-fields/CreditRatingAgencyEnum';
import SPCreditRatingEnum from '@biproxi/models/enums/info-fields/SPCreditRatingEnum';
import FitchCreditRatingEnum from '@biproxi/models/enums/info-fields/FitchCreditRatingEnum';
import MoodysCreditRatingEnum from '@biproxi/models/enums/info-fields/MoodysCreditRatingEnum';
import LocationTypeEnum from '@biproxi/models/enums/info-fields/LocationTypeEnum';
import DefaultStatusEnum from '@biproxi/models/enums/info-fields/DefaultStatusEnum';
import LeinPositionEnum from '@biproxi/models/enums/info-fields/LeinPositionEnum';
import ParkingLotTypeEnum from '@biproxi/models/enums/info-fields/ParkingLotTypeEnum';
import ChildcareTenancyEnum from '@biproxi/models/enums/info-fields/ChildcareTenancyEnum';
import CommunityTypeEnum from '@biproxi/models/enums/info-fields/CommunityTypeEnum';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import Dropdown, { TDropdownItems } from '../../elements/Dropdown';
import Input, { InputTypesEnum } from '../../elements/Input';
import Toggle from '../../elements/Toggle';
import DateInput, { DateInputTypeEnum } from '../../elements/DateInput';
import { media } from '../../utils/MediaQuery';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';

function enumToDropdownItems<ValueType>(enumToConvert: ValueType): TDropdownItems<ValueType> {
  return Object.values(enumToConvert).map((value: ValueType) => ({
    name: value.toString(),
    value,
  }));
}

const UnitBreakdownContainer = styled.div`
  width: auto;
  height: auto;
  background:  ${Colors.White};
  border-radius: 8px;
  padding: 24px;
  border: 0.5px solid ${Colors.Grey300};
  box-sizing: border-box;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 24px;

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CreateListingsInfoFieldInputProps = {
  listing: IListingGraphQL;
  fieldType: ListingInfoFieldNamesEnum,
  value: any;
  onChange: (value: any) => void
  autoFocus: boolean;
};

const CreateListingsInfoFieldInput: React.FC<CreateListingsInfoFieldInputProps> = ({
  listing,
  fieldType,
  value,
  onChange,
  autoFocus,
}) => {
  /* Render */
  switch (fieldType) {
    case ListingInfoFieldNamesEnum.AccruedInterestAmount:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AccruedInterestAmount)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AccruedInterestAmount.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.AdditionalStructuralCapacity:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AdditionalStructuralCapacity)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.ADR:
      return (
        <Input
          autoFocus={autoFocus}
          label={ListingInfoFieldNamesEnum.ADR}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.ADR.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.AgeRestriction:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AgeRestriction)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.AmortizationRate:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AmortizationRate)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AmortizationRate.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.Anchored:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Anchored)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration:
      return (
        <DateInput
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Date}
        />
      );
    case ListingInfoFieldNamesEnum.AnchorTenant:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AnchorTenant)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AnchorTenant.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.AnchorTenantCredit:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AnchorTenantCredit)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AnchorTenantCredit.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.AnnualRent:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AnnualRent)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AnnualRent.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.AnnualRentIncrease:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AnnualRentIncrease)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AnnualRentIncrease.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.AssetClass:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AssetClass)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AssetClass.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.AverageRentPerPad:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AverageRentPerPad)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AverageRentPerPad.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.AverageNumberOfBedAndBath:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AverageNumberOfBedAndBath)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AverageNumberOfBedAndBath.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
        />
      );
    case ListingInfoFieldNamesEnum.AverageSqft:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AverageSqft)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.AverageSqft.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
        />
      );
    case ListingInfoFieldNamesEnum.BoatRVStorage:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.BoatRVStorage)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.BuildingClass:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.BuildingClass)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.BuildingClass.toLowerCase()}`}
          value={value}
          onChange={(value: BuildingClassEnum) => onChange(value)}
          items={enumToDropdownItems<typeof BuildingClassEnum>(BuildingClassEnum)}

        />
      );
    case ListingInfoFieldNamesEnum.BuildingSqFt:
      return (
        <Input
          data-cy="building-sq-ft-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.BuildingSqFt)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.BuildingSqFt.toLowerCase()}`}
          value={value}
          onChange={(_event, unmasked) => onChange(unmasked)}
          inputType={InputTypesEnum.Number}
          unit="sq. feet"
        />
      );
    case ListingInfoFieldNamesEnum.CeilingHeight:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.CeilingHeight)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.CeilingHeight.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="feet"
        />
      );
    case ListingInfoFieldNamesEnum.ClearHeight:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.ClearHeight)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.ClearHeight.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="feet"
        />
      );
    case ListingInfoFieldNamesEnum.CommunityType:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.CommunityType)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.CommunityType.toLowerCase()}`}
          value={value}
          onChange={(value: CommunityTypeEnum) => onChange(value)}
          items={enumToDropdownItems<typeof CommunityTypeEnum>(CommunityTypeEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.ConservationEasement:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.ConservationEasement)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.Credit:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Credit)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.CreditRatingAgency:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.CreditRatingAgency)}
          placeholder={`Select ${ListingInfoFieldNamesEnum.CreditRatingAgency.toLowerCase()}`}
          value={value}
          onChange={(value: CreditRatingAgencyEnum) => onChange(value)}
          items={enumToDropdownItems<typeof CreditRatingAgencyEnum>(CreditRatingAgencyEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.CreditRating: {
      const items = (() => {
        const ratingAgency = ListingUtil.getField(
          listing.info,
          ListingInfoFieldNamesEnum.CreditRatingAgency,
        ).value;

        switch (ratingAgency) {
          case CreditRatingAgencyEnum.Moodys:
            return enumToDropdownItems<typeof MoodysCreditRatingEnum>(MoodysCreditRatingEnum);
          case CreditRatingAgencyEnum.SP:
            return enumToDropdownItems<typeof SPCreditRatingEnum>(SPCreditRatingEnum);
          case CreditRatingAgencyEnum.Fitch:
            return enumToDropdownItems<typeof FitchCreditRatingEnum>(FitchCreditRatingEnum);
          default:
            return [];
        }
      })();

      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.CreditRating)}
          placeholder={`Select ${ListingInfoFieldNamesEnum.CreditRating.toLowerCase()}`}
          value={value}
          onChange={(value: CreditRatingAgencyEnum) => onChange(value)}
          items={items}
        />
      );
    }
    case ListingInfoFieldNamesEnum.Contiguous:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Contiguous)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );

    case ListingInfoFieldNamesEnum.DefaultInterest:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.DefaultInterest)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.DefaultInterest.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.DefaultStatus:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.DefaultStatus)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.DefaultStatus.toLowerCase()}`}
          value={value}
          onChange={(value: DefaultStatusEnum) => onChange(value)}
          items={enumToDropdownItems<typeof DefaultStatusEnum>(DefaultStatusEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.DevelopableLand:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.DevelopableLand)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.EconomicScale:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.EconomicScale)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.EconomicScale.toLowerCase()}`}
          value={value}
          onChange={(value: EconomicScaleEnum) => onChange(value)}
          items={enumToDropdownItems<typeof EconomicScaleEnum>(EconomicScaleEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.ElectricBilledDirect:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.ElectricBilledDirect)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.EnvironmentalSurvey:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.EnvironmentalSurvey)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.FeeSimple:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.FeeSimple)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.GradeLevelDoors:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.GradeLevelDoors)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.GradeLevelDoors.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
        />
      );
    case ListingInfoFieldNamesEnum.Guarantor:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Guarantor)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Guarantor.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.InterestRate:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.InterestRate)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.InterestRate.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.KickOutClause:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.KickOutClause)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.KickOutClause.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="day(s)"
        />
      );
    case ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.LeaseCommencementDate:
      return (
        <DateInput
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LeaseCommencementDate)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LeaseCommencementDate.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Date}
        />
      );
    case ListingInfoFieldNamesEnum.LeaseExpiration:
      return (
        <DateInput
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LeaseExpiration)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LeaseExpiration.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Date}
        />
      );
    case ListingInfoFieldNamesEnum.LeinPosition:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LeinPosition)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LeinPosition.toLowerCase()}`}
          value={value}
          onChange={(value: LeinPositionEnum) => onChange(value)}
          items={enumToDropdownItems<typeof LeinPositionEnum>(LeinPositionEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.LoadingDocks:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LoadingDocks)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LoadingDocks.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="dock(s)"
        />
      );
    case ListingInfoFieldNamesEnum.LocationType:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LocationType)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LocationType.toLowerCase()}`}
          value={value}
          onChange={(value: LocationTypeEnum) => onChange(value)}
          items={enumToDropdownItems<typeof LocationTypeEnum>(LocationTypeEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.LotSize:
      return (
        <Input
          data-cy="lot-size-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.LotSize)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.LotSize.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="acre(s)"
        />
      );
    case ListingInfoFieldNamesEnum.MaturityDate:
      return (
        <DateInput
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.MaturityDate)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.MaturityDate.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Date}
        />
      );
    case ListingInfoFieldNamesEnum.YearBuiltRange:
      return (
        <Flex align="center">
          <DateInput
            dataCy="testing"
            autoFocus={autoFocus}
            label={StringUtil.capitalize(ListingInfoFieldNamesEnum.YearBuiltRange)}
            placeholder="From"
            value={value}
            onChange={(millis: number) => onChange(millis)}
            inputType={DateInputTypeEnum.Date}
          />
          <DateInput
            autoFocus={autoFocus}
            label=" "
            placeholder="To"
            value={value}
            onChange={(millis: number) => onChange(millis)}
            inputType={DateInputTypeEnum.Date}
          />
        </Flex>
      );
    case ListingInfoFieldNamesEnum.MaximumBoatSize:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.MaximumBoatSize)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.MaximumBoatSize.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="feet"
        />
      );
    case ListingInfoFieldNamesEnum.NAICS:
      return (
        <Input
          autoFocus={autoFocus}
          label={ListingInfoFieldNamesEnum.NAICS}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NAICS}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.NetLease:
      return (
        <Toggle
          cyYes="net-lease-yes-toggle"
          cyNo="net-lease-no-toggle"
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NetLease)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.NumberofDryRacks:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofDryRacks)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofDryRacks.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="dry rack(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberOfExistingLeases:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberOfExistingLeases)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberOfExistingLeases.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
        />
      );
    case ListingInfoFieldNamesEnum.NumberOfFloors:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberOfFloors)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberOfFloors.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="floor(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberOfHomes:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberOfHomes)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberOfHomes.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="home(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofLeaseRenewals:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofLeaseRenewals)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofLeaseRenewals.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="renewal(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofLevels:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofLevels)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofLevels.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="level(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofPads:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofPads)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofPads.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="pad(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofRooms:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofRooms)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofRooms.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="room(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofSpaces:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofSpaces)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofSpaces.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="space(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofTenants:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofTenants)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofTenants.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="tenant(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofUnits:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofUnits)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofUnits.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="unit(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofUnitsSpaces:
      return (
        <Input
          autoFocus={autoFocus}
          label="Number of units"
          placeholder="Enter number of units"
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="unit(s)"
        />
      );
    case ListingInfoFieldNamesEnum.NumberofWetSlips:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.NumberofWetSlips)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.NumberofWetSlips.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="slip(s)"
        />
      );
    case ListingInfoFieldNamesEnum.Occupancy:
      return (
        <Input
          data-cy="occupancy-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Occupancy)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Occupancy.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );
    case ListingInfoFieldNamesEnum.OnCampus:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.OnCampus)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.Operating:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Operating)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.OperatingForChildcare:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.OperatingForChildcare)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.Ownership:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Ownership)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Ownership.toLowerCase()}`}
          value={value}
          onChange={(value: OwnershipEnum) => onChange(value)}
          items={enumToDropdownItems<typeof OwnershipEnum>(OwnershipEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.ParkingSpaces:
      return (
        <Input
          data-cy="parking-spaces-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.ParkingSpaces)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.ParkingSpaces.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="space(s)"
        />
      );
    case ListingInfoFieldNamesEnum.RedevelopmentOpportunity:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.RedevelopmentOpportunity)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.RemainingTotalTerm:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.RemainingTotalTerm)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.RemainingTotalTerm.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="year(s)"
        />
      );
    case ListingInfoFieldNamesEnum.RevPAR:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.RevPAR)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.RevPAR.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.SIC:
      return (
        <Input
          autoFocus={autoFocus}
          label={ListingInfoFieldNamesEnum.SIC}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.SIC}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.Tenancy:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Tenancy)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Tenancy.toLowerCase()}`}
          value={value}
          onChange={(value: ChildcareTenancyEnum) => onChange(value)}
          items={enumToDropdownItems<typeof ChildcareTenancyEnum>(ChildcareTenancyEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.TenantName:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.TenantName)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.TenantName.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );
    case ListingInfoFieldNamesEnum.TotalSqFt:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.TotalSqFt)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.TotalSqFt.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
          unit="sq. feet"
        />
      );
    case ListingInfoFieldNamesEnum.Type:
      return (
        <Dropdown
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Type)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Type.toLowerCase()}`}
          value={value}
          onChange={(value: ParkingLotTypeEnum) => onChange(value)}
          items={enumToDropdownItems<typeof ParkingLotTypeEnum>(ParkingLotTypeEnum)}
        />
      );
    case ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome:
      return (
        <UnitBreakdownContainer>
          <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 24px">Unit breakdown</Text>
          <Grid>
            <Input
              autoFocus={autoFocus}
              label="Singlewide"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.Singlewide]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.Singlewide]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="singlewides"
            />
            <Input
              label="Doublewide"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.Doublewide]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.Doublewide]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="doublewides"
            />
            <Input
              label="Triplewide"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.Triplewide]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.Triplewide]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="triplewides"
            />
            <Input
              label="RV"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.RV]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.RV]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="RVs"
            />
          </Grid>
        </UnitBreakdownContainer>
      );
    case ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily:
      return (
        <UnitBreakdownContainer>
          <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 24px">Unit breakdown</Text>
          <Grid>
            <Input
              autoFocus={autoFocus}
              label="Studio"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.Studio]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.Studio]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="studio units"
            />
            <Input
              label="1BR"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.OneBedroom]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.OneBedroom]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="1BR units"
            />
            <Input
              label="2BR"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.TwoBedroom]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.TwoBedroom]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="2BR units"
            />
            <Input
              label="3BR+"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.ThreePlusBedroom]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.ThreePlusBedroom]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="3BR+ units"
            />
          </Grid>
        </UnitBreakdownContainer>
      );
    case ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage:
      return (
        <UnitBreakdownContainer>
          <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 24px">Unit breakdown</Text>
          <Grid>
            <Input
              autoFocus={autoFocus}
              label="5X5"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.FiveByFive]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.FiveByFive]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="5x5s"
            />
            <Input
              label="10X10"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.TenByTen]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.TenByTen]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="10x10s"
            />
            <Input
              label="10X15"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.TenByFifteen]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.TenByFifteen]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="10x15s"
            />
            <Input
              label="10X20"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.TenByTwenty]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.TenByTwenty]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="10x20s"
            />
            <Input
              label="10X30"
              placeholder="Enter a number"
              value={value[ListingInfoFieldNamesEnum.TenByThirty]}
              onChange={(event) => {
                onChange({
                  ...value,
                  [ListingInfoFieldNamesEnum.TenByThirty]: event.target.value,
                });
              }}
              inputType={InputTypesEnum.Number}
              unit="10x30s"
            />
          </Grid>
        </UnitBreakdownContainer>
      );
    case ListingInfoFieldNamesEnum.UnitPriceFor10X10:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.UnitPriceFor10X10)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.UnitPriceFor10X10.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.UPB:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.UPB)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.UPB.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Currency}
        />
      );
    case ListingInfoFieldNamesEnum.WaterPaidbyPark:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.WaterPaidbyPark)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );
    case ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
        />
      );
    case ListingInfoFieldNamesEnum.YearBuilt:
      return (
        <DateInput
          dataCy="year-built-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.YearBuilt)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.YearBuilt.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Year}
        />
      );
    case ListingInfoFieldNamesEnum.YearRenovated:
      return (
        <DateInput
          dataCy="year-renovated-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.YearRenovated)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.YearRenovated.toLowerCase()}`}
          value={value}
          onChange={(millis: number) => onChange(millis)}
          inputType={DateInputTypeEnum.Year}
        />
      );
    case ListingInfoFieldNamesEnum.YearsPerRenewalOption:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.YearsPerRenewalOption)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.YearsPerRenewalOption.toLowerCase()}`}
          value={value}
          onChange={(event, _unmasked) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Number}
          unit="year(s)"
        />
      );
    case ListingInfoFieldNamesEnum.Zoning:
      return (
        <Input
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.Zoning)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.Zoning.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Text}
        />
      );

    case ListingInfoFieldNamesEnum.CapRate:
      return (
        <Input
          data-cy="cap-rate-input"
          autoFocus={autoFocus}
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.CapRate)}
          placeholder={`Enter ${ListingInfoFieldNamesEnum.CapRate.toLowerCase()}`}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          inputType={InputTypesEnum.Percentage}
        />
      );

    case ListingInfoFieldNamesEnum.SameSubdivision:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.SameSubdivision)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );

    case ListingInfoFieldNamesEnum.RentControl:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.RentControl)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );

    case ListingInfoFieldNamesEnum.AssumableFinancing:
      return (
        <Toggle
          label={StringUtil.capitalize(ListingInfoFieldNamesEnum.AssumableFinancing)}
          value={value}
          onChange={(active) => onChange(active)}
        />
      );

    default:
      return null;
  }
};

export default CreateListingsInfoFieldInput;
