import React from 'react';
import styled from '@emotion/styled';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import StringUtil from '@biproxi/models/utils/StringUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import OfferStatus from '../OfferStatus';
import UserInfo, { UserInfoTypesEnum } from '../UserInfo';
import { media } from '../../utils/MediaQuery';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { LeadModalPageStateEnum } from '../modal/LeadModal';
import { BuyerLeadModalPageStateEnum } from '../modal/BuyerLeadModal';
import { LeadActions } from '../../redux/lead.redux';

const Container = styled.div``;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  &:hover {
    cursor: pointer;
    background: ${Colors.Grey50};
    border-radius: 4px;
    box-shadow: 0px 0px 0px 8px #F9FAFB;
  }
`;

const Image = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  width: 73px;
  min-width: 56px;
  height: 48px;
  border-radius: 8px;
  margin-left: 8px;
`;

const ListingInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${media.mobile} {
    display: none;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export enum AccountOfferCardTypesEnum {
  Sent = 'Sent',
  Received = 'Received',
}

type AccountOfferCardProps = {
  offer: IOfferGraphQL;
  type: AccountOfferCardTypesEnum;
};

const AccountOfferCard: React.FC<AccountOfferCardProps> = ({
  offer,
  type,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const pushBuyerLeadModal = (listingId: string) => {
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.BuyerLead,
      props: {
        listingId,
        initialState: BuyerLeadModalPageStateEnum.Offers,
      },
    }));
  };
  const pushLeadModal = (leadId: string) => {
    dispatch(LeadActions.selectLead({
      leadId,
    }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        initialState: LeadModalPageStateEnum.Offers,
      },
    }));
  };

  /** Render */
  return (
    <Container>
      <RowContainer
        onClick={() => {
          if (type === AccountOfferCardTypesEnum.Sent) {
            pushBuyerLeadModal(offer?.listingId);
          } else {
            pushLeadModal(offer?.leadId);
          }
        }}
      >
        <div>
          {type === AccountOfferCardTypesEnum.Sent ? (
            <OfferStatus offer={offer} />
          ) : (
            <UserInfo user={offer.user} type={UserInfoTypesEnum.ExtraSmall} nameOnly />
          )}
          <Text type={TextTypesEnum.Bold16} color={Colors.Black}>
            {`$${StringUtil.formatNumber(offer.purchasePrice)}`}
          </Text>
        </div>
        <Flex align="center">
          <ListingInfo>
            <TextContainer>
              <Text
                type={TextTypesEnum.Bold14}
                color={Colors.Black}
              >
                {`${ListingUtil.name(offer?.listing, { allButAddress1: true })}`}
              </Text>
            </TextContainer>
            <TextContainer>
              <Text
                type={TextTypesEnum.Regular14Small}
                color={Colors.Grey700}
              >
                {ListingUtil.isValidPortfolioListing(offer?.listing) ? 'Multiple addresses' : AddressUtil.formatAddress(offer?.listing?.address, { address1: true, address2: true })}
              </Text>
            </TextContainer>
          </ListingInfo>
          <Image
            src={offer?.listing?.media?.files?.[0]?.url}
          />
        </Flex>
      </RowContainer>
    </Container>
  );
};

export default AccountOfferCard;
