// The below are used to map checkboxes and their respective messages
export enum GeneralNotificationsEnum {
   'Messages' = 'Receive an email when someone sends you a message.',
   'Account Reminders' = 'Receive automated emails including tips and reminders to get the most out of your Biproxi experience.',
}

export enum BuyingNotificationsEnum {
    'Offer Updates' = 'Receive an email when anything changes with your offer.',
    'Favorited Listings Updates' = 'Receive an email when a favorited listing changes status or is no longer available.',
    'Investment Opportunities' = 'Receive an email when a listing matching your investment criteria is available.',
    'Private Listings Updates' = 'Receive update emails when a broker accepts or declines your request for a private listing.'
}

export enum SellingNotificationsEnum {
    'Listing Updates' = 'Receive an email when your listing is published, scheduled or becomes expired.',
    'New Offers' = 'Receive an email when someone makes a new offer.',
    'Revised Offer' = 'Receive an email when someone revises their offer.',
    'New Leads' = 'Receive an email when there’s a new lead on your listing.',
}

export enum NotificationBlockTitlesEnum {
    'General' = 'General',
    'Buying' = 'Buying',
    'Selling' = 'Selling',
}
