import React from 'react';
import styled from '@emotion/styled';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';
import DraggableListItem from '../DraggableListItem';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import { ListingActions } from '../../redux/listing.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';

const TextContainer = styled.div`
  width: 100%;
`;

type CreateListingHighlightBoxProps = {
  text: string;
  index: number;
  highlights: string[];
};

const CreateListingHighlightBox: React.FC<CreateListingHighlightBoxProps> = ({
  text,
  index,
  highlights,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));
  const popModal = () => dispatch(AppActions.popModal());

  const deleteHighlight = () => {
    const h = [...highlights];
    h.splice(index, 1);
    dispatch(ListingActions.setListingHighlights({ highlights: h }));
    popModal();
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Highlight successfully deleted',
    });
  };

  const pushEditHighlightModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.EditHighlight,
      props: {
        index,
        highlights,
      },
    }),
  );

  const pushDeleteHighlightModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete highlight',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => deleteHighlight(),
      },
    }),
  );

  const menuItems = [
    {
      text: 'Edit',
      onClick: () => pushEditHighlightModal(),
    },
    {
      text: 'Delete',
      onClick: () => pushDeleteHighlightModal(),
    },
  ];

  return (
    <DraggableListItem menuItems={menuItems}>
      <TextContainer>
        <Text type={TextTypesEnum.Regular16} color={Colors.Black} skWidth={192}>
          {text}
        </Text>
      </TextContainer>
    </DraggableListItem>
  );
};

export default CreateListingHighlightBox;
