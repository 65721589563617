import React from 'react';
import styled from '@emotion/styled';
// import IListingOverviewAnalytics from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import { INeo4jListing } from '@biproxi/models/services/INeo4jService';
import Flex from '../../../elements/Flex';
import Icons from '../../../elements/Icons';
import LinkText from '../../../elements/LinkText';
import Text, { TextTypesEnum } from '../../../elements/Text';
// import Flex from '../../../elements/Flex';
import ListingCard from '../../ListingCard.team';
import NoContent from '../../NoContent';
import { media } from '../../../utils/MediaQuery';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
`;

const ListingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;

  ${media.mobile} {
    /* flex-direction: column; */
  }
`;

type OrganizationDashboardListingsProps = {
  teamListings: INeo4jListing[]
}

const OrganizationDashboardListings: React.FC<OrganizationDashboardListingsProps> = ({
  teamListings,
}) => {
  const [showAll, setShowAll] = React.useState(false);

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <Text
          type={TextTypesEnum.Bold18}
        >
          Recently added listings
        </Text>
        {teamListings?.length > 3 && (
          <LinkText
            margin="4px 0 0"
            type={TextTypesEnum.Medium14}
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'View less' : 'View more'}
          </LinkText>
        )}
      </Flex>
      <ListingsWrapper>
        {(teamListings?.length <= 0 || !teamListings?.length) ? (
          <NoContent
            height="176px"
            icon={Icons.BuildingLight}
            text="There are no listings in this team"
            padding="20px"
          />
        ) : (
          <>
            {teamListings?.slice(0, showAll ? teamListings?.length : 3).map((listing: INeo4jListing, index: number) => (
              <ListingsContainer key={index}>
                <ListingCard listing={listing} />
                {/* {!isMobile && (
                <Icon
                  icon={Icons.ExternalLinkRegular}
                  color={Colors.Brand700}
                  size={14}
                  onClick={() => {
                    window.open(`/listing/${listing?._id}`, '_blank');
                  }}
                />
                )} */}
              </ListingsContainer>
            ))}
          </>
        )}
      </ListingsWrapper>
    </Container>
  );
};

export default OrganizationDashboardListings;
