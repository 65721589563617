import gql from 'graphql-tag';

const LOGIN_TO_ORGANIZATION = gql`
  mutation loginToOrganization($params: LoginToOrganizationParams!) {
    loginToOrganization(params: $params) {
      user {
        _id
        firstName
        lastName
        email
        role
        meta {
          createdAt
        }
      }
      token
      hubspotToken
    }
  }
`;

export default LOGIN_TO_ORGANIZATION;
