import React from 'react';
import styled from '@emotion/styled';
// import IListingOverviewAnalytics from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import { logger } from '@biproxi/logger';
import { useQuery } from '@apollo/client';
import { useQuery as rqQuery } from 'react-query';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import { getOrganizationListings } from '@biproxi/next-api-requests';
import { INeo4jListing } from '@biproxi/models/services/INeo4jService';
import { ListingOverviewChartDaysFromCurrentEnum } from '@biproxi/models/enums/ListingOverviewChartDateFilterEnum';
import LIST_LISTINGS_WITH_OFFERS from '../../../graphql/queries/listingsWithOffers.query';
import Loader, { LoaderSizes } from '../../../elements/Loader';
import ORG_LISTINGS_ANALYTICS from '../../../graphql/queries/organizationListingsAnalytics.query';
import useUser from '../../../hooks/useUser.hook';
import DashboardQuickAnalyticCard, { DashboardAnalyticsEnum } from './QuickAnalyticCard';
import DashboardBarChart, { DashboardBarChartDataMap, DashboardBarChartsEnum } from '../DashboardBarChart';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import { media } from '../../../utils/MediaQuery';
import RecentOffersReceived from './RecentOffersReceived';
import OrganizationDashboardListings from './OrganizationDashboardListings';
import { useAppSelector } from '../../../redux/store';
import { UserSelectors } from '../../../redux/user.redux';
import ButtonDropdown from '../../../elements/ButtonDropdown';
import IButtonOptions from '../../../models/interfaces/IButtonOptions';
import { ButtonSizesEnum, ButtonTypesEnum } from '../../../elements/Button';
import Icons from '../../../elements/Icons';

const LoadingContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrganizationDashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
`;

const OrganizationHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

const OrganizationDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 24px;
  width: 100%;
`;

const AnalyticsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  gap: 16px;
`;

const BarChartsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex: 1;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const AnalticCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 210px;
  gap: 16px;

  ${media.mobile} {
    flex-direction: 100%;
    width: 100%;
  }
`;

const OffersReceivedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

const ListingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

enum AnalyticsFilterDateRangeEnum {
  // AllTime = 'All time',
  // Today = 'Today',
  // Yesterday = 'Yesterday',
  ThisWeek = 'This week',
  ThisMonth = 'This month',
  ThisQuarter = 'This quarter',
  // ThisYear = 'This year'
}

const cubeDateTimeFiltersMap = {
  // [AnalyticsFilterDateRangeEnum.Today]: {
  //   granularity: 'hour',
  //   dateRange: AnalyticsFilterDateRangeEnum.Today,
  //   daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastDay,
  // },
  // [AnalyticsFilterDateRangeEnum.Yesterday]: {
  //   granularity: 'hour',
  //   dateRange: AnalyticsFilterDateRangeEnum.Yesterday,
  //   daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastDay,
  // },
  [AnalyticsFilterDateRangeEnum.ThisWeek]: {
    granularity: 'day',
    dateRange: AnalyticsFilterDateRangeEnum.ThisWeek,
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.ThisWeek,
  },
  [AnalyticsFilterDateRangeEnum.ThisMonth]: {
    granularity: 'day',
    dateRange: AnalyticsFilterDateRangeEnum.ThisMonth,
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.Last30Days,
  },
  [AnalyticsFilterDateRangeEnum.ThisQuarter]: {
    granularity: 'week',
    dateRange: AnalyticsFilterDateRangeEnum.ThisQuarter,
    daysFromToday: ListingOverviewChartDaysFromCurrentEnum.Last90Days,
  },
  // [AnalyticsFilterDateRangeEnum.ThisYear]: {
  //   granularity: 'month',
  //   dateRange: AnalyticsFilterDateRangeEnum.ThisYear,
  //   daysFromToday: ListingOverviewChartDaysFromCurrentEnum.LastYear,
  // },
};

const OrganizationDashboardComponents: React.FC = () => {
  /** State */
  const [graphData, setGraphData] = React.useState<object[]>(null);
  const [analyticData, setAnalyticData] = React.useState<object>(null);
  const [listingsWithOffers, setListingsWithOffers] = React.useState<IListingGraphQL[]>([]);
  const [teamListings, setTeamListings] = React.useState<INeo4jListing[]>(null);
  const [listingIds, setListingIds] = React.useState<string[]>([]);
  const [initialLoader, setInitialLoader] = React.useState<boolean>(true);
  const [timeFilter, setTimeFilter] = React.useState<AnalyticsFilterDateRangeEnum>(AnalyticsFilterDateRangeEnum.ThisWeek);

  /** Hooks */
  const { userId } = useUser();

  const currentOrganizationContext = useAppSelector(UserSelectors.currentOrganizationContext);
  const teamId = currentOrganizationContext?.orgId;

  const { isLoading: isOrgListingsDataLoading = true } = rqQuery(['neo4j:organizationListings', teamId], () => getOrganizationListings({
    organizationId: teamId,
  }, cubeDateTimeFiltersMap[timeFilter].dateRange), {
    onSuccess: (successData) => {
      const listings = successData?.data?.map((listingData) => listingData);
      const listingIds = listings?.map((listing: INeo4jListing) => listing?._id);
      setListingIds(listingIds);
      setTeamListings(listings);
      setAnalyticData({
        ...analyticData,
        [DashboardAnalyticsEnum.TotalListings]: listings?.length || 0,
      });
    },
    enabled: Boolean(teamId?.length),
    cacheTime: 0,
  });

    interface Data {
      getOrganizationListingsAnalytics: {
        graphData: any;
      }
    }
    interface Vars {
      params: {
        listingIds: string[];
        listingUserIds: string[];
        dateRange: string,
        granularity: string,
        analyticEventTypes: AnalyticsEventTypesEnum[],
        timezone: string,
        daysFromToday: number,
      };
    }

    const { data: orgyData, loading: isOrgListingAnalyticsLoading = true } = useQuery<Data, Vars>(
      ORG_LISTINGS_ANALYTICS,
      {
        variables: {
          params: {
            listingIds,
            listingUserIds: [userId],
            dateRange: cubeDateTimeFiltersMap[timeFilter].dateRange,
            granularity: cubeDateTimeFiltersMap[timeFilter].granularity,
            analyticEventTypes: [AnalyticsEventTypesEnum.ViewedListing, AnalyticsEventTypesEnum.ReceivedLead, AnalyticsEventTypesEnum.ReceivedCA],
            timezone: TimeUtil.currentBrowserTimezone(),
            daysFromToday: cubeDateTimeFiltersMap[timeFilter].daysFromToday,
          },
        },
        skip: !listingIds?.length,
        onError: (error) => {
          logger.warn('ORG_LISTINGS_ANALYTICS error', error);
        },
      },
    );

    interface OffersData {
      listings: {
        data: IListingGraphQL[];
      }
    }

    interface OffersVars {
      params: {
        query: IListingQuery
      }
    }

    const { data: offerData, loading: isOffersDataLoading = true } = useQuery<OffersData, OffersVars>(LIST_LISTINGS_WITH_OFFERS, {
      variables: {
        params: {
          query: {
            listingIds,
            queryType: ListingQueryTypesEnum.Active,
          },
        },
      },
      skip: !listingIds?.length,
    });

    // Fun Fact, onComplete doesn't run on subsequent fetches if the variables change in useQuery so you have to do the frontend
    // stuff with the new data in an effect.
    React.useEffect(() => {
      setGraphData(orgyData?.getOrganizationListingsAnalytics?.graphData);
      setInitialLoader(false);
    }, [orgyData]);

    React.useEffect(() => {
      const listingsWithOffers = offerData?.listings?.data?.filter((listing) => listing?.offers?.length > 0);
      const filteredListingsWithOffers = listingsWithOffers?.length > 0 ? listingsWithOffers : [];
      setListingsWithOffers(filteredListingsWithOffers);
      setAnalyticData({ ...analyticData, [DashboardAnalyticsEnum.OffersReceived]: filteredListingsWithOffers?.length || 0 });
    }, [offerData]);

    React.useEffect(() => {
      if (!isOrgListingAnalyticsLoading && !isOffersDataLoading && !isOrgListingsDataLoading) {
        setInitialLoader(false);
      }
    }, [isOrgListingAnalyticsLoading, isOffersDataLoading, isOrgListingsDataLoading]);

    React.useEffect(() => {
      setInitialLoader(true);
    }, [currentOrganizationContext]);

    /** Render */
    const dropdownConfig: IButtonOptions[] = Object.entries(AnalyticsFilterDateRangeEnum).map(([_key, value]) => ({
      text: value,
      onClick: () => setTimeFilter(value),
      active: value === timeFilter,
    }));
    if (initialLoader) {
      return (
        <LoadingContainer>
          <Loader
            size={LoaderSizes.Large}
            color={Colors.Brand700 || Colors.Blurple700}
          />
        </LoadingContainer>
      );
    }

    return (
      <OrganizationDashboardContainer>
        <OrganizationDashboardHeader>
          <Text
            type={TextTypesEnum.Bold18}
          >
            {timeFilter}
          </Text>
          <OrganizationHeaderButtons>
            <ButtonDropdown
              items={dropdownConfig}
              activeItem={dropdownConfig.find((i) => i.active)}
              buttonType={ButtonTypesEnum.Pale}
              buttonSize={ButtonSizesEnum.Small}
              rightIcon={Icons.CalendarAltSolid}
              iconColor={Colors.Brand700 || Colors.Blurple700}
              styles={{
                padding: '8px 12px',
              }}
            />
          </OrganizationHeaderButtons>
        </OrganizationDashboardHeader>
        <AnalyticsWrapper>
          <BarChartsWrapper>
            {Object.keys(DashboardBarChartsEnum).map((barChartType: DashboardBarChartsEnum, index: number) => (
              <DashboardBarChart
                barChartType={DashboardBarChartsEnum[barChartType]}
                graphData={graphData?.[DashboardBarChartDataMap[barChartType]]}
                granularity={cubeDateTimeFiltersMap[timeFilter]?.granularity}
                key={index}
              />
            ))}
          </BarChartsWrapper>
          <AnalticCardsWrapper>
            {Object.keys(DashboardAnalyticsEnum).map((analytic: DashboardAnalyticsEnum, index: number) => (
              <DashboardQuickAnalyticCard
                analyticType={DashboardAnalyticsEnum?.[analytic]}
                analyticData={analyticData?.[DashboardAnalyticsEnum[analytic]]}
                key={index}
              />
            ))}
          </AnalticCardsWrapper>
        </AnalyticsWrapper>
        <ListingsWrapper>
          <OrganizationDashboardListings teamListings={teamListings} />
        </ListingsWrapper>
        <OffersReceivedWrapper>
          <RecentOffersReceived listingsWithOffers={listingsWithOffers} />
        </OffersReceivedWrapper>
      </OrganizationDashboardContainer>
    );
};

export default OrganizationDashboardComponents;
