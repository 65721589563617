import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Text, { TextTypesEnum } from './Text';
import Colors, { ColorsType } from '../styles/Colors';

export enum CircularProgressBarSizesEnum {
  ExtraSmall = 'Extra Small',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

const sharedCSS = (size: CircularProgressBarSizesEnum) => {
  const circleSize = (() => {
    switch (size) {
      case CircularProgressBarSizesEnum.ExtraSmall:
        return '32px';
      case CircularProgressBarSizesEnum.Small:
        return '40px';
      case CircularProgressBarSizesEnum.Medium:
        return '80px';
      case CircularProgressBarSizesEnum.Large:
        return '120px';
      default:
        return '40x';
    }
  })();

  return css`
    width: ${circleSize};
    height: ${circleSize};
    border-radius: 50%;
  `;
};

type ContainerProps = {
  size?: CircularProgressBarSizesEnum;
  background?: ColorsType;
  margin?: string;
};

const Container = styled.div<ContainerProps>`
  margin: ${(props) => (props.margin ? props.margin : null)};
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  ${(props) => sharedCSS(props.size)};
`;

type BaseCircleProps = {
  size?: CircularProgressBarSizesEnum;
  progressBackground: ColorsType;
  borderWidth?: number;
}

const BaseCircle = styled.div<BaseCircleProps>`
  position: absolute;
  box-sizing: border-box;
  border: ${(props) => `solid ${props.progressBackground}`};
  border-width: ${(props) => `${props.borderWidth}px`};
  ${(props) => sharedCSS(props.size)};
`;

type ProgressCircleProps = {
  rotationValue: string;
  size?: CircularProgressBarSizesEnum;
  progressColor?: ColorsType;
  borderWidth?: number;
}

const ProgressCircle = styled.div<ProgressCircleProps>`
  position: absolute;
  box-sizing: border-box;
  border: 8px solid;
  border-width: ${(props) => `${props.borderWidth}px`};
  border-right-color: ${(props) => props.progressColor};
  border-top-color: ${(props) => props.progressColor};
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(${(props) => props.rotationValue});
  transition: all 0.5s;
  ${(props) => sharedCSS(props.size)};
`;

type TextContainerProps = {
  size?: CircularProgressBarSizesEnum;
}

const TextContainer = styled.div<TextContainerProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => sharedCSS(props.size)};
`;

type CircularProgressBarProps = {
  size?: CircularProgressBarSizesEnum;
  percent: number;
  progressColor?: ColorsType;
  progressBackground?: ColorsType;
  background?: ColorsType;
  borderWidth?: number;
  margin?: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  size = CircularProgressBarSizesEnum.Small,
  margin,
  percent,
  progressColor = Colors.Brand700 || Colors.Blurple700,
  progressBackground = Colors.Grey300,
  background = Colors.White,
  borderWidth = 2,
}) => {
  const rotateByStyle = (percent, baseDegrees) => {
    const rotateBy = baseDegrees + percent * 3.6;
    return `${rotateBy}deg`;
  };

  let firstLayerRotation = '';
  let secondLayerRotation = '';

  if (percent > 50) {
    firstLayerRotation = `${45}deg`;
    secondLayerRotation = rotateByStyle(percent - 50, 45);
  } else {
    firstLayerRotation = rotateByStyle(percent, -135);
    secondLayerRotation = `${-135}deg`;
  }

  const textStyleEnum = size === CircularProgressBarSizesEnum.ExtraSmall ? TextTypesEnum.Bold16
    : TextTypesEnum.Bold18;

  return (
    <Container size={size} margin={margin} background={background}>
      <BaseCircle size={size} progressBackground={progressBackground} borderWidth={borderWidth} />
      <ProgressCircle
        size={size}
        borderWidth={borderWidth}
        progressColor={progressColor}
        rotationValue={firstLayerRotation}
      />
      <ProgressCircle
        size={size}
        borderWidth={borderWidth}
        progressColor={percent <= 50 ? progressBackground : progressColor}
        rotationValue={secondLayerRotation}
      />
      <TextContainer size={size}>
        <Text
          type={textStyleEnum}
          color={Colors.Black}
        >
          {percent}
        </Text>
      </TextContainer>
    </Container>
  );
};

export default CircularProgressBar;
