import React from 'react';
import styled from '@emotion/styled';
import { useQuery as rqQuery, useMutation as rqMutation } from 'react-query';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import ChargebeePortalSectionsEnum from '@biproxi/models/enums/ChargebeePortalSectionsEnum';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import { useMobileMedia } from '../../utils/MediaQuery';
import Input from '../../elements/Input';
import queryChargebeeCustomer from '../../next-api/queries/billing/customer.query';
import LinkText from '../../elements/LinkText';
import useChargebee from '../../hooks/useChargebee.hook';
import deletePaymentSourceMutation from '../../next-api/mutations/deletePaymentSource.mutation';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { useAppDispatch } from '../../redux/store';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
  box-sizing: border-box;
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 16px 0px;
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentMethodActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0px 0px 0px;
`;

const FlexRightWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 12px;
`;

export type SettingsBillingPaymentProps = {
  user: IUserGraphQL;
  userOnFreemium: boolean;
}

const SettingsBillingPayment: React.FC<SettingsBillingPaymentProps> = ({ user, userOnFreemium }) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const { portalInstance } = useChargebee();

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const editPaymentMethodInPortal = () => {
    portalInstance.openSection({ sectionType: ChargebeePortalSectionsEnum.PAYMENT_SOURCES }, { close: () => getLatestPaymentInfo() });
  };

  const launchDeletePaymentSourceModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.ConfirmChange,
    props: {
      title: 'Delete payment source',
      text: `Delete payment method ending in ${card?.masked_number.slice(-4)}?`,
      buttonText: 'Delete',
      confirm: () => {
        deletePaymentSource.mutate(card?.payment_source_id);
        popModal();
      },
    },
  }));

  const deletePaymentSource = rqMutation((paymentSourceId: string) => deletePaymentSourceMutation(paymentSourceId), {
    onSuccess: () => getLatestPaymentInfo(),
  });

  /** Data */
  const { data, refetch: getLatestPaymentInfo } = rqQuery(['billing:chargebeeCustomer'], () => queryChargebeeCustomer(user?._id), {
    enabled: false,
  });
  const card = data?.card;

  React.useEffect(() => {
    if (!data) getLatestPaymentInfo();
  });

  /** Render */
  return (
    <Container>
      <SectionTitle>
        <Flex>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Billing Information
          </Text>
        </Flex>
        <Text
          type={TextTypesEnum.Regular14}
          color={Colors.Grey700}
        >
          Manage payment information on your account.
        </Text>
      </SectionTitle>
      <PaymentMethodContainer>
        <Input
          label="Payment Name"
          value={card ? `${card?.first_name} ${card?.last_name}` : ''}
          margin="0px 0px 16px 0px"
          disabled
          data-cy="billing-name-on-card"
        />
        <Input
          label="Card number"
          value={card?.masked_number ?? ''}
          margin="0px 0px 16px 0px"
          disabled
          data-cy="billing-card-number"
        />
        <Flex direction={isMobile ? 'column' : 'row'} gap="8px">
          <Input
            label="Expiration date (MM/YY)"
            value={card ? `${card?.expiry_month}/${String(card?.expiry_year).slice(2)}` : ''}
            disabled
            data-cy="billing-exp-date"
          />
          <Input
            label="Zip code"
            value={card?.billing_zip ?? ''}
            disabled
            data-cy="billing-zip"
          />
        </Flex>
      </PaymentMethodContainer>
      <PaymentMethodActionsContainer>
        <Flex>
          {/* <GenericToggle
            customText="Default"
            value
          /> */}
          <FlexRightWrapper>
            {card && (
              <LinkText dataCy="billing-edit-payment" type={TextTypesEnum.Medium14} onClick={() => editPaymentMethodInPortal()}>Edit</LinkText>
            )}
            {(userOnFreemium && card) && (
              <LinkText type={TextTypesEnum.Medium14} onClick={() => launchDeletePaymentSourceModal()}>Remove</LinkText>
            )}
          </FlexRightWrapper>
        </Flex>
        {/* <LinkText type={TextTypesEnum.Medium14}>Add a backup payment method</LinkText> */}
      </PaymentMethodActionsContainer>
    </Container>
  );
};

export default SettingsBillingPayment;
