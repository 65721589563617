import React from 'react';
import styled from '@emotion/styled';
import PrivateEventParticipantStatesEnum from '@biproxi/models/enums/PrivateEventParticipantStatesEnum';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useMutation } from '@apollo/client';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Divider from '../elements/Divider';
import Flex, { CustomSpacer } from '../elements/Flex';
import ListingDetailsPreview from '../components/listing-details/ListingDetailsPreview';
import NoContent from '../components/NoContent';
import Icons from '../elements/Icons';
import GenericCard from '../elements/GenericCard';
import SquareIconButton from '../elements/SquareIconButton';
import AnchoredMenu from '../elements/AnchoredMenu';
import PrivateEventManagementCard from '../components/private-event/PrivateEventManagementCard';
import ColoredTag, { ColoredTagTypesEnum } from '../elements/ColoredTag';
import UserInfo from '../components/UserInfo';
import Loader, { LoaderSizes } from '../elements/Loader';
import PrivateEventStatusDropdown from '../components/private-event/PrivateEventStatusDropdown';
import usePrivateEventSubscription from '../hooks/usePrivateEventSubscription.hook';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import KeyCodesEnum from '../models/enums/KeyCodesEnum';
import Input, { InputTypesEnum } from '../elements/Input';
import SEND_PRIVATE_EVENT_INVITE from '../graphql/mutations/sendPrivateEventInvite.mutation';
import GET_PRIVATE_EVENT from '../graphql/queries/privateEvent.query';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import { useAppDispatch } from '../redux/store';
import { LeadActions } from '../redux/lead.redux';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import UPDATE_PRIVATE_EVENT_PARTICIPANT from '../graphql/mutations/updatePrivateEventParticipant.mutation';
import useNavigateToPrivateEventParticipation from '../hooks/useNavigateToPrivateEventParticipation.hook';
import useUser from '../hooks/useUser.hook';
import RESEND_PRIVATE_EVENT_INVITE_EMAIL from '../graphql/mutations/resendPrivateEventInviteEmail.mutation';
import PrivateEventMessageTable from '../components/private-event/PrivateEventMessageTable';
import { LeadModalPageStateEnum } from '../components/modal/LeadModal';

const OuterContainer = styled.div`
  flex: 1;
  ${media.mobile} {
    width: calc(100vw - 32px);
  }

  ${media.tablet} {
    margin: 32px;
    width: calc(100vw - 32px);
  }
`;

const Container = styled.div`
  flex: 1;
`;

const LoaderContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListingNameContainer = styled.div`
  max-width: 700px;

  &, & > *, > div > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ViewPDPButton = styled.div`
  height: 32px;
  padding: 8px 12px;
  background: ${Colors.White};
  border-radius: 4px;
  border: 0.5px solid ${Colors.Grey300};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;

   &:hover {
    border: 0.5px solid ${Colors.Grey400};
   }
`;

type EventManagementProps = {};

const EventManagement: React.FC<EventManagementProps> = () => {
  /** State */
  const [showAllBids, setShowAllBids] = React.useState(false);
  const [showAllBidders, setShowAllBidders] = React.useState(false);
  const [email, setEmail] = React.useState('');

  /** Hooks */
  const { privateEvent, refetch } = usePrivateEventSubscription();
  const navigateToPrivateEventParticipation = useNavigateToPrivateEventParticipation();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const navigate = useNavigate();
  const { userId } = useUser();

  /** GraphQL */
  const [sendPrivateEventInvite, { loading }] = useMutation(SEND_PRIVATE_EVENT_INVITE, {
    refetchQueries: [{
      query: GET_PRIVATE_EVENT,
      variables: {
        params: {
          privateEventId: privateEvent?._id,
        },
      },
    }],
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Private event invite sent.',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  const [resendPrivateEventInviteEmail] = useMutation(RESEND_PRIVATE_EVENT_INVITE_EMAIL, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Private event invite resent.',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  const [updatePrivateEventParticipant] = useMutation(UPDATE_PRIVATE_EVENT_PARTICIPANT, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'User status updated.',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const pushLeadModal = (leadId: string) => {
    dispatch(LeadActions.selectLead({ leadId }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        initialState: LeadModalPageStateEnum.Bids,
        showBidsTab: true,
      },
    }));
  };

  /** Render */
  if (!privateEvent) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  // security
  if (userId !== privateEvent?.userId) {
    navigate('/app/dashboard/events');
  }

  const sendInvite = () => {
    if (email) {
      sendPrivateEventInvite({
        variables: {
          params: {
            privateEventId: privateEvent?._id,
            email,
          },
        },
      });
      setEmail('');
    }
  };

  const bidLength = PrivateEventUtil.getTotalBids(privateEvent);
  const bidderLength = privateEvent?.participants?.length;
  const highestBidsFirst = [...privateEvent?.bids]?.reverse();
  return (
    <Flex>
      <OuterContainer>
        <Flex margin="0 0 16px">
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Black}
            onClick={() => navigate('/app/dashboard/events')}
          >
            Events
          </Text>
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Grey500}
            margin="0px 8px"
          >
            /
          </Text>
          <ListingNameContainer>
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey500}
              skWidth={100}
            >
              {privateEvent?.listing?.name || privateEvent?.listing?.address?.address1 || null}
            </Text>
          </ListingNameContainer>
        </Flex>
        <Flex direction="row" align="center" justify="space-between">
          <ListingNameContainer>
            <ListingDetailsPreview listing={privateEvent?.listing} hideStatus />
          </ListingNameContainer>
          <Flex>
            {!isMobile && !isTablet && (
              <Flex>
                <PrivateEventStatusDropdown
                  value={privateEvent.state}
                />
                <CustomSpacer width="8px" />
                <ViewPDPButton
                  onClick={() => navigateToPrivateEventParticipation(privateEvent?._id)}
                >
                  View PDP
                </ViewPDPButton>
              </Flex>
            )}
          </Flex>
        </Flex>
        {(isMobile || isTablet) && (
          <>
            <Flex margin="16px 0 0">
              <PrivateEventStatusDropdown
                value={privateEvent.state}
              />
              <CustomSpacer width="8px" />
              <ViewPDPButton
                onClick={() => navigateToPrivateEventParticipation(privateEvent?._id)}
              >
                View PDP
              </ViewPDPButton>
            </Flex>
            <PrivateEventManagementCard privateEvent={privateEvent} refetchPrivateEvent={refetch} />
          </>
        )}
        <Flex margin="32px 0 0">
          <Container>
            <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px" data-cy="private-event-bids">
              Bids
            </Text>
            {/* Should componentize this generic card and the participant one */}
            {bidLength > 0 ? (
              <>
                {highestBidsFirst?.slice(0, showAllBids ? bidLength : 3)?.map((bid, index) => (
                  <GenericCard
                    key={bid._id}
                    width="100%"
                    padding="16px"
                    borderRadius="8px"
                    margin={`0 0 ${bidLength > 1 ? '16px' : '0'} 0`}
                    hover
                    onClick={() => {
                      pushLeadModal(PrivateEventUtil?.getParticipantFromBid(bid, privateEvent)?.leadId);
                    }}
                  >
                    <Flex align="center" justify="space-between">
                      <Flex>
                        <UserInfo
                          user={PrivateEventUtil.getPrivateEventUser(bid.userId, privateEvent)}
                          includePhone
                          links
                        />
                      </Flex>
                    </Flex>
                    <Flex align="center" margin="16px 0px 0px">
                      {index === 0 && (
                        <ColoredTag
                          type={ColoredTagTypesEnum.Green}
                          text="Highest bid"
                          margin="0px 8px 0px 0px"
                        />
                      )}
                      <Text color={Colors.Black} type={TextTypesEnum.Medium14} margin="0 8px 0 0">
                        $
                        {StringUtil.formatNumber(bid.totalBidAmount)}
                      </Text>
                      <Text color={Colors.Grey500} type={TextTypesEnum.Regular14}>
                        {TimeUtil.format(bid.meta.createdAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone())}
                      </Text>
                    </Flex>
                  </GenericCard>
                ))}
                {bidLength > 3 && (
                  <Button
                    text={showAllBids ? 'View less' : 'View more'}
                    onClick={() => setShowAllBids(!showAllBids)}
                    type={ButtonTypesEnum.Outline}
                    size={ButtonSizesEnum.Medium}
                  />
                )}
              </>
            ) : (
              <NoContent
                height="172px"
                icon={Icons.EnvelopeOpenDollarLight} // TODO: update icon
                text="There are no bids for this event."
              />
            )}
            <Divider margin="24px 0" />
            <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px" data-cy="private-event-bidders">
              Bidders
            </Text>
            {(privateEvent?.state === PrivateEventStatesEnum.InProgress
              || privateEvent?.state === PrivateEventStatesEnum.Upcoming) && (
              <Flex margin="0 0 24px">
                <Input
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputType={InputTypesEnum.Text}
                  onKeyPress={(e) => {
                    if (e.which === KeyCodesEnum.ENTER) {
                      sendInvite();
                    }
                  }}
                />
                <Button
                  margin="0 0 0 24px"
                  text="Add user"
                  isLoading={loading}
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  onClick={sendInvite}
                />
              </Flex>
            )}
            {privateEvent?.participants?.slice(0, showAllBidders ? bidderLength : 3).map((participant) => {
              const participantRef: React.RefObject<HTMLDivElement> = React.createRef();
              const isDisabled = participant.disabled;

              const bidderMenuItems = [
                {
                  text: isDisabled ? 'Enable' : 'Disable',
                  onClick: () => {
                    updatePrivateEventParticipant({
                      variables: {
                        params: {
                          privateEventId: privateEvent?._id,
                          privateEventParticipantId: participant?._id,
                          updateParams: {
                            disabled: !isDisabled,
                          },
                        },
                      },
                    });
                  },
                },
              ];

              if (participant?.state === PrivateEventParticipantStatesEnum.Pending) {
                bidderMenuItems.push({
                  text: 'Resend invite',
                  onClick: () => {
                    resendPrivateEventInviteEmail({
                      variables: {
                        params: {
                          privateEventId: privateEvent?._id,
                          privateEventParticipantId: participant?._id,
                        },
                      },
                    });
                  },
                });
              }

              return (
                <GenericCard
                  key={participant._id}
                  width="100%"
                  padding="16px"
                  borderRadius="8px"
                  margin="0 0 16px 0"
                  hover={!!participant?.leadId}
                  onClick={() => {
                    if (participant?.leadId) {
                      pushLeadModal(participant?.leadId);
                    }
                  }}
                >
                  <Flex align="center" justify="space-between">
                    <div>
                      <Text color={Colors.Grey900} type={TextTypesEnum.Medium12}>
                        {participant?.email}
                      </Text>
                      {/* This is messy, could componentize or make better later */}
                      <ColoredTag
                        margin="4px 0 0"
                        text={isDisabled ? 'Disabled' : participant.state}
                        icon={isDisabled ? Icons.TimesCircleSolid
                          : participant.state === PrivateEventParticipantStatesEnum.InvitationAccepted
                            ? Icons.CheckCircleSolid : Icons.ExclamationTriangleSolid}
                        type={isDisabled ? ColoredTagTypesEnum.Red
                          : participant.state === PrivateEventParticipantStatesEnum.InvitationAccepted
                            ? ColoredTagTypesEnum.Green : ColoredTagTypesEnum.Yellow}
                      />
                    </div>
                    <div ref={participantRef}>
                      <SquareIconButton
                        icon={Icons.EllipsisHRegular}
                        onClick={(event) => {
                          event?.stopPropagation();
                        }}
                      />
                    </div>
                  </Flex>
                  <AnchoredMenu
                    anchorRef={participantRef}
                    menuItems={bidderMenuItems}
                  />
                </GenericCard>
              );
            })}
            {bidderLength > 3 && (
              <Button
                text={showAllBidders ? 'View less' : 'View more'}
                onClick={() => setShowAllBidders(!showAllBidders)}
                type={ButtonTypesEnum.Outline}
                size={ButtonSizesEnum.Medium}
              />
            )}
            <Divider margin="24px 0" />
            <Flex>
              <PrivateEventMessageTable />
            </Flex>
          </Container>
          {!isMobile && !isTablet && (
            <PrivateEventManagementCard privateEvent={privateEvent} refetchPrivateEvent={refetch} />
          )}
        </Flex>
      </OuterContainer>
    </Flex>
  );
};

export default EventManagement;
