import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import { useMutation } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import IListingCounts from '@biproxi/models/interfaces/IListingCounts';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { useNavigate } from 'react-router-dom';
import DELETE_LISTING from '../graphql/mutations/deleteListing.mutation';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import BoxShadows from '../styles/BoxShadows';
import { IToastConfig, ToastTypesEnum } from './Toast';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import Link from '../elements/Link';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import ListingThumbnail from './ListingCardThumbnail';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import ListingStatus from './ListingStatus';
// import CircularProgressBar, { CircularProgressBarSizesEnum } from '../elements/CircularProgressBar';
import { media, useMobileMedia } from '../utils/MediaQuery';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import LIST_LISTINGS from '../graphql/queries/listings.query';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  border: 0.5px solid ${Colors.Grey300};
  transition: all 0.2s;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin: 0px 0px 8px 0px;
  box-shadow: ${BoxShadows.Light};

  &:hover {
      box-shadow: ${BoxShadows.Hard};
      border: ${`0.5px solid ${Colors.Grey400}`};
  }

  ${media.mobile} {
    flex-direction: column;
  }

  #OverrideLeftPadding {
    padding-left: 0px;
  }
`;

const ActionOverlay = styled.div`
  position: absolute;
  margin: '16px 8px 0px 0px';
  right: 8px;
  top: 8px;
`;

// const ScoreCircleOverlay = styled.div`
//   position: absolute;
//   bottom: 12px;
//   right: 12px;
// `;

const ListingCardContent = styled.div`
  &, & > div > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  max-width: 500px;

  ${media.mobile} {
    max-width: 310px;
  }
`;

const AnalyticsChiclet = styled.div`
  display: flex;
  max-width: 98px;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 0.5px solid ${Colors.Grey300};
  border-radius: 4px;
`;

const Dot = styled.span`
  color: ${Colors.Grey400};
`;

type ListingCardProps = {
    listing: IListingGraphQL | null;
    onClick?: () => void;
}

const ListingCard: React.FC<ListingCardProps> = ({ listing, onClick }) => {
  /** State */
  const listingCounts = useAppSelector(ListingSelectors.listingCounts);

  /** Hooks */
  const navigate = useNavigate();
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  const selectListing = () => dispatch(
    ListingActions.selectListing({
      listingId: listing._id,
    }),
  );

  const setListingCounts = (listingCounts: IListingCounts) => dispatch(ListingActions.setListingCounts(listingCounts));

  const pushShareModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ShareListingModal,
      props: {
        listing,
      },
    }),
  );

  const pushDeleteListingModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete listing',
        dataCy: 'delete-modal-button',
        text: `Delete ${ListingUtil.name(listing, { allButAddress1: true }) ?? 'listing'}? You cannot undo this action afterwards.`,
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => deleteListing(),
      },
    }),
  );

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  const [deleteListing] = useMutation(DELETE_LISTING, {
    variables: {
      params: {
        listingId: listing._id,
      },
    },
    onCompleted: () => {
      setListingCounts({
        All: listingCounts.All - 1,
        Draft: listingCounts.Draft - 1,
      });
      popModal();
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Listing successfully deleted',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      popModal();

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
    refetchQueries: [{
      query: LIST_LISTINGS,
      variables: {
        params: {
          query: {
            queryType: ListingQueryTypesEnum.Personal,
          },
        },
      },
    }],
  });

  /** Render */
  const click = onClick ? () => onClick() : null;
  const sqFt = listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value ?? null;
  // const color = (() => {
  //   if (listing?.score === 90) return Colors.Green500;
  //   if (listing?.score === 0) return Colors.Grey300;
  //   return Colors.Yellow500;
  // })();

  return (
    <Container
      data-cy={listing.state === ListingStateEnum.Draft ? 'listing-card-draft' : 'listing-card'}
      onClick={click}
    >
      <ListingThumbnail
        type={ListingCardTypesEnum.Personal}
        listing={listing}
        width={isMobile ? '100%' : '146px'}
      />
      <ListingCardContent>
        <Flex align="center" margin={isMobile ? '8px 0 0' : '0'}>
          <Text data-cy="listing-card-name" type={TextTypesEnum.Bold16} margin="0px 4px 0px 0px" color={Colors.Black} skWidth={160}>{ListingUtil.name(listing, { allButAddress1: true })}</Text>
          <ListingStatus listing={listing} changeable />
        </Flex>
        <Flex align="center" margin="4px 0 0">
          {listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
          <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey700} skWidth={280}>
            {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, listing?.name ? {} : { address1: true }) || null}
          </Text>
        </Flex>
        {listing ? (
          <Flex align="center" margin="4px 0 0">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey700}
              skWidth={240}
            >
              {(() => {
                if (!listing?.assetClass) return null;
                return (
                  <>
                    {listing?.assetClass}
                    <Dot>&nbsp;&bull;&nbsp;</Dot>
                    {listing?.propertyType}
                    {Boolean(sqFt) && (
                    <>
                      <Dot>&nbsp;&bull;&nbsp;</Dot>
                      {`${StringUtil.formatNumber(sqFt as number)} sq. ft.`}
                    </>
                    )}
                  </>
                );
              })()}
            </Text>
          </Flex>
        ) : (
          <Flex align="center" margin="4px 0 0">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey700}
              skWidth={192}
            />
          </Flex>
        )}
        {!isMobile && (
        <Flex direction="column" margin="8px 0px 0px 0px">
          <AnalyticsChiclet>
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey500}
            >
              CAs signed &nbsp;
            </Text>
            <Text
              type={TextTypesEnum.Bold12}
              color={Colors.Black}
            >
              {/* We subtract by one since when a listing is created, we add
               the listing creator to the signedCAUserIDs array so that they do
               not have to manually unlock due diligence on their own listing */}
              {listing?.metrics?.signedCAUserIds?.length - 1}
            </Text>
          </AnalyticsChiclet>
        </Flex>
        )}
      </ListingCardContent>
      {isMobile ? (
        <Flex margin="16px 0 0 0" justify="left">
          <Button
            id="OverrideLeftPadding"
            text="Edit"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Small}
            icon={Icons.PenRegular}
            iconColor={Colors.Grey900}
            iconSize={16}
            onClick={(event) => {
              event.stopPropagation();
              selectListing();
              navigate(`/app/create-listing?listingId=${listing._id}`);
            }}
          />
          <Button
            text="Share"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Small}
            icon={Icons.ShareAltRegular}
            iconColor={Colors.Grey900}
            iconSize={16}
            onClick={(event) => {
              event.stopPropagation();
              pushShareModal();
            }}
          />
          <Link href={ListingUtil.slug(listing)} target="_blank">
            <Button
              text="View"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Small}
              icon={Icons.ExternalLinkRegular}
              iconColor={Colors.Grey900}
              iconSize={16}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </Link>
          {listing.state === ListingStateEnum.Draft && (
          <Button
            data-cy="delete-listing-button"
            text="Delete"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Small}
            icon={Icons.TrashRegular}
            iconColor={Colors.Grey900}
            iconSize={16}
            onClick={(event) => {
              event.stopPropagation();
              pushDeleteListingModal();
            }}
          />
          )}
        </Flex>
      ) : (
        <ActionOverlay>
          <Flex>
            <Button
              data-cy="edit-listing-button"
              text="Edit"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Small}
              icon={Icons.PenRegular}
              iconColor={Colors.Grey900}
              iconSize={16}
              onClick={(event) => {
                event.stopPropagation();
                selectListing();
                navigate(`/app/create-listing?listingId=${listing._id}`);
              }}
            />
            <Button
              text="Share"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Small}
              icon={Icons.ShareAltRegular}
              iconColor={Colors.Grey900}
              iconSize={16}
              onClick={(event) => {
                event.stopPropagation();
                pushShareModal();
              }}
            />
            <Link href={ListingUtil.slug(listing)} target="_blank">
              <Button
                text="View"
                type={ButtonTypesEnum.Ghost}
                size={ButtonSizesEnum.Small}
                icon={Icons.ExternalLinkRegular}
                iconColor={Colors.Grey900}
                iconSize={16}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </Link>
            {listing.state === ListingStateEnum.Draft && (
              <Button
                text="Delete"
                data-cy="delete-listing-button"
                type={ButtonTypesEnum.Ghost}
                size={ButtonSizesEnum.Small}
                icon={Icons.TrashRegular}
                iconColor={Colors.Grey900}
                iconSize={16}
                onClick={(event) => {
                  event.stopPropagation();
                  pushDeleteListingModal();
                }}
              />
            )}
          </Flex>
        </ActionOverlay>
      )}
      {/* Needs backfill before release */}
      {/* {!isMobile && (
        <ScoreCircleOverlay>
          <CircularProgressBar
            size={CircularProgressBarSizesEnum.ExtraSmall}
            percent={listing.score ? listing.score : 0}
            background={Colors.White}
            progressBackground={Colors.Grey300}
            progressColor={color}
            borderWidth={2}
          />
        </ScoreCircleOverlay>
      )} */}
    </Container>
  );
};

export default React.memo(ListingCard);
