import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAppDispatch } from '../../../redux/store';
import { AppActions } from '../../../redux/app.redux';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import ITaxAssessorIds from '../@types/data-frame-types/interfaces/ITaxAssessorIds';
import CHERRE_TAX_IDS_FROM_PARCEL_IDS from '../../../graphql/queries/cherreTaxIdsFromParcelIds.query';
import CHERRE_INSIGHTS_DEMO_DATA from '../../../graphql/queries/cherreInsightsAndDemoDataFromManyTaxIds.query';
import Loader from '../../../elements/Loader';
import DataFrameAddressAndTaxInfo from './DataFrameTaxInfo';
import Icon, { Icons } from '../../../elements/Icon';
import { media, useMobileMedia } from '../../../utils/MediaQuery';
import {
  dataFrameComparePropertiesAtom, dataFrameGeneralAtom, dataFrameSimilarPropertiesAtom, dataFrameTaxAtom,
} from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import DataFrameAddressOverview from './DataFrameAddressOverview';
import { dataExplorerGlobalAtom } from '../state-management/recoil/atoms/DataExplorerGlobalContext';
import { ModalTypesEnum } from '../../modal/Modal';

type ComparePropertiesWrapperProps = {
    dataLoading?: boolean;
}

const IndividualComparedProperty = styled.div`
    display: flex;
    flex-direction: column;
`;

const ComparePropertiesWrapper = styled.div<ComparePropertiesWrapperProps>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${({ dataLoading }) => (dataLoading ? 'center' : 'flex-start')};
    justify-content: ${({ dataLoading }) => (dataLoading ? 'center' : 'null')};
    gap: 16px;
    background-color: ${Colors.Grey100};
    padding: 12px 16px;
    overflow-x: scroll;
    overflow-y: scroll;

    ${media.mobile} {
      width: calc(100% - 32px);
      height: inherit;
      overflow-x: initial;
    }
`;

const ComparedPropertiesDataWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    height: fit-content;
    gap: 16px;
    overflow-y: scroll;
    overflow-x: scroll;
    flex: 1 1 0;

    ${media.mobile} {
      flex: 1;
    }

    scrollbar-width: auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .2);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:vertical:hover {
      background-color: rgba(0, 0, 0, .5);
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
`;

const ComparePropertiesToolbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const DataFrameToolbarIcons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`;

type ComparePropertiesProps = {
    setSelectedTaxIdCallback: (taxId: number) => void,
    selectedTaxId: number,
    selectedPropertyInfo: any;
}

const CompareProperties: React.FC<ComparePropertiesProps> = ({
  setSelectedTaxIdCallback,
  selectedTaxId,
  selectedPropertyInfo,
}) => {
  /* State/Context */
  const [taxIds, setTaxIds] = React.useState<ITaxAssessorIds[]>([]);

  const dataFrameTaxState = useRecoilValue(dataFrameTaxAtom);
  const [dataFrameComparePropertiesState, setDataFrameComparePropertiesState] = useRecoilState(dataFrameComparePropertiesAtom);
  const setDataFrameGeneralState = useSetRecoilState(dataFrameGeneralAtom);
  const dataFrameSimilarPropertiesState = useRecoilValue(dataFrameSimilarPropertiesAtom);
  const dataFrameMapState = useRecoilValue(dataExplorerGlobalAtom);

  const {
    mapVisible,
  } = dataFrameMapState;

  const {
    propertyAddressData,
  } = dataFrameTaxState;

  const {
    comparePropertiesLoading,
  } = dataFrameComparePropertiesState;

  const {
    selectedComparedPropertyCherreIds: cherreParcelIds,
  } = dataFrameSimilarPropertiesState;

  const dispatch = useAppDispatch();
  const pushComparePropertiesModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CompareDataExplorerProperties,
      props: {
        selectedProperty: selectedPropertyInfo,
        properties: cherrePropertyData,
      },
    }),
  );

  const isMobile = useMobileMedia();

    /* GraphQL */
    type SimilarPropertiesTaxIdsVar = {
      params: {
        cherreParcelIds: string[];
      };
    };

    type SimilarPropertiesTaxIdsData = {
      cherreTaxIdsFromParcelIds: {
        taxAssessorIds: ITaxAssessorIds[];
      };
    };

    const { data: _taxAssessorIds, loading: _taxAssessorIdsLoading } = useQuery<SimilarPropertiesTaxIdsData, SimilarPropertiesTaxIdsVar>(CHERRE_TAX_IDS_FROM_PARCEL_IDS, {
      skip: !cherreParcelIds?.length,
      variables: {
        params: {
          cherreParcelIds,
        },
      },
      onError: (error) => {
        logger.error('Whoopsie, there was an error when querying for similar property tax ids: ', error);
      },
      onCompleted: (data) => {
        const taxIds: ITaxAssessorIds[] = data?.cherreTaxIdsFromParcelIds?.taxAssessorIds;
        setTaxIds(taxIds);
      },
    });

    type PropertyDataVars = {
        params: {
            cherreTaxAssessorIds: number[],
            cherreGeographyId: string,
        };
    };

    type PropertyData = {
        cherreInsightsAndDemographicDataFromManyTaxIds: {
            insights: ICherreDataInsights[],
            demographics: ICherreDataDemographics,
            priorYearDemographics: ICherreDataDemographics,
        };
    };

    const { data: cherrePropertyData, loading: cherrePropertyDataLoading } = useQuery<PropertyData, PropertyDataVars>(CHERRE_INSIGHTS_DEMO_DATA, {
      skip: !taxIds.length,
      fetchPolicy: 'network-only',
      variables: {
        params: {
          cherreTaxAssessorIds: taxIds.map((assessor) => assessor?.tax_assessor_id),
          cherreGeographyId: `ZI${propertyAddressData?.zip}`,
        },
      },
      onError: (error) => {
        logger.error('CHERRE_INSIGHTS_DEMO_DATA error', error);
      },
      onCompleted: () => {
        setDataFrameComparePropertiesState((prevState) => ({
          ...prevState,
          comparePropertiesLoading: false,
        }));
      },
    });
    if (comparePropertiesLoading) {
      return (
        <ComparePropertiesWrapper dataLoading={comparePropertiesLoading}>
          <Loader
            color={Colors.Brand700 || Colors.Blurple700}
          />
        </ComparePropertiesWrapper>
      );
    }

    if (!cherrePropertyDataLoading && !comparePropertiesLoading && isMobile) {
      const insights = cherrePropertyData?.cherreInsightsAndDemographicDataFromManyTaxIds?.insights;
      const demographics = cherrePropertyData?.cherreInsightsAndDemographicDataFromManyTaxIds?.demographics;
      return (
        <ComparePropertiesWrapper
          data-cy="compare-properties-wrapper"
        >
          <ComparePropertiesToolbarWrapper>
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.Grey900}
            >
              Compared properties
            </Text>
            <DataFrameToolbarIcons>
              <Icon
                icon={Icons.DownloadSolid}
                color={Colors.Brand700 || Colors.Blurple700}
                size="16px"
                onClick={pushComparePropertiesModal}
              />
              <Icon
                data-cy="close-compare-properties"
                icon={Icons.TimesRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size="16px"
                onClick={() => {
                  setDataFrameGeneralState((prevState) => ({
                    ...prevState,
                    comparePropertiesExpanded: false,
                  }));
                  // setSelectedComparedPropertyCherreIds([[]]);
                }}
              />
            </DataFrameToolbarIcons>
          </ComparePropertiesToolbarWrapper>
          <ComparedPropertiesDataWrapper>
            {insights?.map((insight: ICherreDataInsights, index: number) => (
              <IndividualComparedProperty>
                <DataFrameAddressOverview
                  addressInfo={{
                    address: insight?.address,
                    city: insight?.city,
                    state: insight?.state,
                    zip: insight?.zip,
                    latitude: insight?.latitude,
                    longitude: insight?.longitude,
                  }}
                  taxIds={taxIds as any}
                  selectedTaxId={selectedTaxId}
                  setSelectedTaxId={setSelectedTaxIdCallback}
                />
                <DataFrameAddressAndTaxInfo
                  key={index}
                  taxInsights={insight}
                  taxDemographics={demographics}
                  taxIds={Object.keys(taxIds[index])?.map((key: string) => taxIds[index][key])}
                  yScrollRule="inherit"
                />
              </IndividualComparedProperty>
            ))}
          </ComparedPropertiesDataWrapper>
        </ComparePropertiesWrapper>
      );
    }

    if (!cherrePropertyDataLoading && !comparePropertiesLoading && !mapVisible) {
      const insights = cherrePropertyData?.cherreInsightsAndDemographicDataFromManyTaxIds?.insights;
      const demographics = cherrePropertyData?.cherreInsightsAndDemographicDataFromManyTaxIds?.demographics;

      return (
        <ComparePropertiesWrapper
          data-cy="compare-properties-wrapper"
        >
          <ComparePropertiesToolbarWrapper>
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.Grey900}
            >
              Compared properties
            </Text>
            <DataFrameToolbarIcons>
              <Icon
                icon={Icons.DownloadSolid}
                color={Colors.Brand700 || Colors.Blurple700}
                size="16px"
                onClick={pushComparePropertiesModal}
              />
              <Icon
                data-cy="close-compare-properties"
                icon={Icons.TimesRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size="16px"
                onClick={() => {
                  setDataFrameGeneralState((prevState) => ({
                    ...prevState,
                    comparePropertiesExpanded: false,
                  }));
                  // setSelectedComparedPropertyCherreIds([[]]);
                }}
              />
            </DataFrameToolbarIcons>
          </ComparePropertiesToolbarWrapper>
          <ComparedPropertiesDataWrapper>
            {insights?.map((insight: ICherreDataInsights, index: number) => (
              <IndividualComparedProperty>
                <DataFrameAddressOverview
                  addressInfo={{
                    address: insight?.address,
                    city: insight?.city,
                    state: insight?.state,
                    zip: insight?.zip,
                    latitude: insight?.latitude,
                    longitude: insight?.longitude,
                  }}
                  taxIds={taxIds as any}
                  selectedTaxId={selectedTaxId}
                  setSelectedTaxId={setSelectedTaxIdCallback}
                />
                <DataFrameAddressAndTaxInfo
                  key={index}
                  taxInsights={insight}
                  taxDemographics={demographics}
                  taxIds={Object.keys(taxIds[index])?.map((key: string) => taxIds[index][key])}
                  yScrollRule="inherit"
                />
              </IndividualComparedProperty>
            ))}
          </ComparedPropertiesDataWrapper>
        </ComparePropertiesWrapper>
      );
    }

    return null;
};

export default CompareProperties;
