import axios from 'axios';
import React from 'react';
import { useQuery as rqQuery, useMutation as rqMutation, UseMutationResult } from 'react-query';
import { config } from '@biproxi/env';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import queryUserCurrentSubscription from '../next-api/queries/billing/currentSubscription.query';
import cancelSubscriptionMutation from '../next-api/mutations/cancelSubscription.mutation';
import renewSubscriptionMutation from '../next-api/mutations/renewSubscription.mutation';
import { useAppSelector } from '../redux/store';
import { UserSelectors } from '../redux/user.redux';

type UseChargebee = {
  portalInstance: any;
  updateSubscriptionInStore: () => void;
  proceedToCheckout: (planId: string) => void;
  cancelSubscription: UseMutationResult<any, unknown, string, unknown>;
  renewSubscription: UseMutationResult<any, unknown, string, unknown>;
};

type UseChargebeeHook = () => UseChargebee;

const useChargebee: UseChargebeeHook = () => {
  /* State */
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
  const user = useAppSelector(UserSelectors.user);

  let cbInstance: { createChargebeePortal: () => any; openCheckout: (arg0: { hostedPage: () => Promise<any>; close: () => void; }) => void; };
  let portalInstance: any;

  if (isLoaded) {
    cbInstance = window.globalThis.Chargebee.getInstance();
    portalInstance = cbInstance.createChargebeePortal();
  }

  const proceedToCheckout = (planId: BillingPlanIdEnum) => {
    cbInstance.openCheckout({
      hostedPage: () => {
        EventTrackerUtil.trackWindowEvent(
          AnalyticsEventTypesEnum.ViewSubscriptionModal,
          window,
          {
            userId: user?._id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            billingData: {
              planId,
            },
          },
        );
        return axios(
          `/api/billing/hosted-page?userId=${user._id}&planId=${planId}`,
          {
            headers: {
              Authorization: `${config.CHARGEBEE_KEY}`,
            },
          },
        ).then((response) => response.data);
      },
      close: () => {
        updateSubscriptionInStore();
        // currently we refresh the page but there is probably a better solution
        // long term, but if we don't, none of the cached data is updated in regards to the fact that the user is now
        // a paid user
        // window.location.replace('/app/dashboard/search?modalType=SubscriptionConfirmed');
        window.location.reload();
      },
    });
  };

  const { refetch: updateSubscriptionInStore } = rqQuery(['billing:current-subscription'], () => queryUserCurrentSubscription(user?._id, user?.email), {
    enabled: false,
  });

  const cancelSubscription = rqMutation((subscriptionId: string) => cancelSubscriptionMutation(subscriptionId), {
    onSuccess: () => updateSubscriptionInStore(),
  });

  const renewSubscription = rqMutation((subscriptionId: string) => renewSubscriptionMutation(subscriptionId), {
    onSuccess: () => updateSubscriptionInStore(),
  });

  /** Effects */
  React.useEffect(() => {
    window.globalThis.Chargebee.init({
      site: config.NEXT_PUBLIC_CHARGEBEE_SITE,
    });
    setIsLoaded(true);
  }, []);

  return {
    portalInstance,
    updateSubscriptionInStore,
    proceedToCheckout,
    cancelSubscription,
    renewSubscription,
  };
};

export default useChargebee;
