import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { PrivateEventActions } from '../redux/privateEvent.redux';

type NavigateToPrivateEventManagement = (privateEventId?: string, path?: string) => void;

type NavigateToPrivateEventManagementHook = () => NavigateToPrivateEventManagement;

const useNavigateToPrivateEventManagement: NavigateToPrivateEventManagementHook = () => {
  /* Hooks */
  const navigate = useNavigate();

  /* Actions */
  const dispatch = useAppDispatch();
  const setPrivateEventId = (privateEventId: string) => dispatch(PrivateEventActions.selectPrivateEvent({ privateEventId }));

  const managePrivateEvent = React.useCallback((privateEventId) => {
    setPrivateEventId(privateEventId);
    navigate(`/app/dashboard/events/manage?privateEventId=${privateEventId}`);
  }, []);

  /** Return */
  return managePrivateEvent;
};

export default useNavigateToPrivateEventManagement;
