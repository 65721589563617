import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import { KB_BASE_URL } from '@biproxi/models/externalLinks';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import Icon, { Icons } from '../elements/Icon';
import * as UrlUtil from '../utils/UrlUtil';
import { media, useDesktopMedia } from '../utils/MediaQuery';
import { useAppSelector, AppState, useAppDispatch } from '../redux/store';
import { SlideIn } from '../elements/Motion';
import Text, { TextTypesEnum } from '../elements/Text';
import IButtonOptions from '../models/interfaces/IButtonOptions';
import BiproxiIconEnum from '../models/enums/BiproxiIconEnum';
import useUser from '../hooks/useUser.hook';
import useUserPermissions, { IUserOrganizationData } from '../hooks/useUserPermissions.hook';
import AnchoredMenu from '../elements/AnchoredMenu';
import { UserActions } from '../redux/user.redux';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';

type ExpandedProp = {
  isExpanded: boolean;
  backgroundColor?: string;
  loadingDashboardColor?: boolean;
}

const OuterContainer = styled.div<ExpandedProp>`
  width: ${({ isExpanded }) => (isExpanded ? '190px' : '60px')};
  z-index: 999;

  ${media.mobile} {
    height: 100vh;
    background: ${(props) => (props.loadingDashboardColor ? Colors.Grey300 : props.backgroundColor)};
    position: fixed;
    width: 190px;
  }

  ${media.print} {
    display: none;
  }

  ${media.tablet} {
    height: 100vh;
    background: ${(props) => (props.loadingDashboardColor ? Colors.Grey300 : props.backgroundColor)};
    position: fixed;
    width: 190px;
  }
`;

const Line = styled.div`
  width: 16px;
  height: 1px;
  /* background: ${Colors.Black}; */
  background: ${Colors.Grey400};
  margin: 4px 0 -12px 22px;
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${Colors.Red500};
  border-radius: 50%;
  position: absolute;
  top: 14px;
  left: 4px;
`;

const Container = styled.div<ExpandedProp>`
  position: absolute;
  width: ${({ isExpanded }) => (isExpanded ? '190px' : '60px')};
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    padding: 24px 0px;
  }

  ${media.tablet} {
    padding: 24px 0px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  margin: 16px 0px 0px 18px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 18px;
  bottom: 16px;
  position: fixed;

  // idk why this is what mobile needs to work
  // also it would be great to interpolate both media queries
  // into one with an or statement. Should be easy to do.
  ${media.mobile} {
    margin: auto 0 16px 18px;
    position: relative;
  }

  ${media.tablet} {
    margin: auto 0 16px 18px;
    position: relative;
  }
`;

const TextContainer = styled.div<{ isExpanded: boolean }>`
  transition: all 0.2s ease-in-out;
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isExpanded ? '1' : '0')};
  white-space: nowrap;
  position: absolute;
  left: 32px;

  max-width: 130px;
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SectionTextContainer = styled.div<{ isExpanded: boolean }>`
  transition: all 0.2s ease-in-out;
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isExpanded ? '1' : '0')};
  position: absolute;
  white-space: nowrap;
`;

const SectionTextOuterContainer = styled.div`
  position: relative;
  height: fit-content;
  margin: 24px 0 16px;
  display: flex;
  align-items: center;
`;

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

type SideNavigationButtonProps = {
  active: boolean;
  iconColors?: string;
};

const Button = styled.div<SideNavigationButtonProps>`
  position: relative;
  height: 40px;
  width: fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${({ active, iconColors }) => (active ? Colors.Brand400 || Colors.White : iconColors)};

  &:hover {
    color: ${Colors.Brand400 || Colors.White};
  }
`;

const MenuContainer = styled.div`
  z-index: 9000000; // sloppy
  position: fixed;
`;

enum SideNavButtonNamesEnum {
  GeneralTools = '',
  InvestorTools = 'Investor Tools',
  BrokerTools = 'Broker Tools',
  BottomSection = '',
}

interface ISideNavButtonOptions extends IButtonOptions {
  notification?: number;
  dataCy?: string;
}

interface ISideNavButtonSection {
  name: SideNavButtonNamesEnum,
  buttons: ISideNavButtonOptions[];
}

type DashboardSideNavigationProps = {
  close: () => void; // this is for mobile
  isExpanded: boolean;
  setIsExpanded: (x: boolean) => void; // this is for desktop
};

const DashboardSideNavigation = React.forwardRef<HTMLDivElement, DashboardSideNavigationProps>(({
  close,
  isExpanded,
  setIsExpanded,
}, ref) => {
  /** State */
  const { pathname } = UrlUtil.parse(window.location.href);
  const {
    app: {
      mobileSideNavOpen,
    },
    user: {
      currentOrganizationContext,
    },
    chat: {
      unreadMessageCount,
    },
    notification: {
      unreadNotificationsCount,
    },
    contentful: {
      brandCollection: {
        colors,
        companyMediaAssets,
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const [currentOrganization, setCurrentOrganization] = React.useState<IUserOrganizationData>(currentOrganizationContext);
  const [menuItems, setMenuItems] = React.useState(null);
  const [loadingLogo, setLoadingLogo] = React.useState(true);
  const [loadingNavColor, setLoadingNavColor] = React.useState(true);
  const [loadingDashboardColor, setLoadingDashboardColor] = React.useState(true);

  /** Refs */
  const menuAnchorRef = React.useRef();

  /** Actions */
  const dispatch = useAppDispatch();
  const setCurrentOrganizationContext = (organization: IUserOrganizationData) => dispatch(UserActions.setCurrentOrganizationContext({ organization }));

  /**
   * ? We have to change these, a little confusing on which is which
   * ? the colors Blurple400 and Grey700 seem to piggyback off of the same Brand
   * ? colors, so like Blurple400 -> Brand400
   * ? and Grey400 -> Brand400 also?, consulted aaron, waiting on fix
  */
  let iconColors = colors.BrandNavIcon || colors.Blurple400;
  let textColors = colors.BrandNavText || colors.Grey400;
  let dashboardColor = colors.BrandNavContainer || colors.Grey700;
  let dashboardMobileLogo = companyMediaAssets.navigationMobileLogo.url || companyMediaAssets.navigationMobileLogo;

  /** Effects */
  React.useEffect(() => {
    /** If the data is already fetched, then we set the colors/assets */
    if (fetched) {
      iconColors = colors.BrandNavIcon || colors.Blurple400;
      textColors = colors.BrandNavText || colors.Grey400;
      dashboardColor = colors.BrandNavContainer || colors.Grey700;
      dashboardMobileLogo = companyMediaAssets.navigationMobileLogo.url || companyMediaAssets.navigationMobileLogo;
      setLoadingLogo(false);
      setLoadingNavColor(false);
      setLoadingDashboardColor(false);
    }
  }, [fetched]);

  /** Hooks */
  const isDesktop = useDesktopMedia();
  const navigate = useNavigate();
  const { userId } = useUser();
  const userPermissions = useUserPermissions({ userId });
  const userOrganizations = userPermissions?.userOrganizationsRolesAndPermissions;

  /** Render */
  const mainSections: ISideNavButtonSection[] = [
    {
      name: SideNavButtonNamesEnum.GeneralTools,
      buttons: [
        {
          text: 'Search Listings',
          dataCy: 'search-button-nav',
          link: '/app/dashboard/search',
          customIcon: BiproxiIconEnum.Search,
          active: ['/app/dashboard/search'],
        },
        {
          text: 'Dashboard',
          dataCy: 'dashboard-button-nav',
          link: '/app/dashboard/home',
          customIcon: BiproxiIconEnum.Dashboard,
          active: ['/app/dashboard/home'],
        },
        {
          text: 'Messages',
          dataCy: 'messages-button-nav',
          link: '/app/dashboard/messages',
          customIcon: BiproxiIconEnum.Messages,
          active: ['/app/dashboard/messages'],
          notification: unreadMessageCount,
        },
        {
          text: 'Notifications',
          dataCy: 'notifications-button-nav',
          link: '/app/dashboard/notifications',
          customIcon: BiproxiIconEnum.Notifications,
          active: ['/app/dashboard/notifications'],
          notification: unreadNotificationsCount,
        },
        {
          text: 'Private Events',
          dataCy: 'private-events-button-nav',
          link: '/app/dashboard/events',
          customIcon: BiproxiIconEnum.PrivateEvents,
          active: ['/app/dashboard/events'],
        },
        {
          text: 'Teams',
          dataCy: 'organizations-button-nav',
          link: '/app/dashboard/teams',
          icon: Icons.UsersRegular,
          active: ['/app/dashboard/teams'],
        },
      ],
    },
    {
      name: SideNavButtonNamesEnum.InvestorTools,
      buttons: [
        {
          text: 'Data Explorer',
          dataCy: 'data-explorer-button-nav',
          link: '/app/dashboard/data-explorer',
          customIcon: BiproxiIconEnum.DateExplorer,
          active: ['/app/dashboard/data-explorer'],
        },
        {
          text: 'Saved Searches',
          dataCy: 'saved-searches-button-nav',
          link: '/app/dashboard/saved-searches',
          customIcon: BiproxiIconEnum.SavedSearches,
          active: ['/app/dashboard/saved-searches'],
        },
        {
          text: 'Favorites',
          dataCy: 'favorites-button-nav',
          link: '/app/dashboard/favorites',
          customIcon: BiproxiIconEnum.Favorites,
          active: ['/app/dashboard/favorites'],
        },
        {
          text: 'Offers Sent',
          dataCy: 'offers-sent-button-nav',
          link: '/app/dashboard/offers-sent',
          customIcon: BiproxiIconEnum.OffersSent,
          active: ['/app/dashboard/offers-sent'],
        },
      ],
    },
    {
      name: SideNavButtonNamesEnum.BrokerTools,
      buttons: [
        {
          text: 'My Listings',
          dataCy: 'my-listings-button-nav',
          link: '/app/dashboard/listings',
          customIcon: BiproxiIconEnum.Listings,
          active: ['/app/dashboard/listings'],
        },
        {
          text: 'Offers Received',
          dataCy: 'offers-received-button-nav',
          link: '/app/dashboard/offers-received',
          customIcon: BiproxiIconEnum.OffersReceived,
          active: ['/app/dashboard/offers-received'],
        },
      ],
    },
  ];

  const bottomSection: ISideNavButtonSection = {
    name: SideNavButtonNamesEnum.BottomSection,
    buttons: [
      {
        text: 'Knowledge Base',
        dataCy: 'knowledge-base-button-nav',
        link: KB_BASE_URL,
        customIcon: BiproxiIconEnum.KnowledgeBase,
      },
    ],
  };

  /** Effects */
  React.useEffect(() => {
    if (currentOrganizationContext) {
      setCurrentOrganization(currentOrganizationContext);
    }
  }, [currentOrganizationContext]);

  React.useEffect(() => {
    // only add the dropdown if they have more than one organization
    if (userOrganizations?.length > 1) {
      const orgsAsMenuItems = userOrganizations.map((org) => ({
        text: org?.orgName,
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
          setCurrentOrganizationContext(org);
          if (isDesktop) {
            setIsExpanded(false);
          } else {
            close();
          }
        },
      }));
      setMenuItems(orgsAsMenuItems);
    }
  }, [userOrganizations]);

  return (
    <SlideIn shouldAnimate={!isDesktop} isOpen={mobileSideNavOpen}>
      <OuterContainer
        ref={ref}
        isExpanded={isExpanded}
        backgroundColor={dashboardColor}
        loadingDashboardColor={loadingDashboardColor}
      >
        <Container isExpanded={isExpanded}>
          {!isDesktop && (
          <MobileHeader>
            {!loadingLogo
              ? (
                <Image
                  src={dashboardMobileLogo}
                  alt="skyscraper"
                  width={80}
                  height={24}
                  onClick={() => {
                    navigate('/app/dashboard/home');
                    close();
                  }}
                />
              ) : (
                <SkeletonContainer style={{ width: '80%', height: '100%' }}>
                  <Skeleton
                    width="100%"
                    height="100%"
                    style={{ backgroundColor: Colors.Grey500 }}
                  />
                </SkeletonContainer>
              )}

            <Icon
              onClick={() => close()}
              icon={Icons.TimesRegular}
              color={iconColors}
              size={24}
              data-cy="closeSideNav"
            />
          </MobileHeader>
          )}
          {/* Way too much similar code, need to optimize */}
          {currentOrganization && (
            <div ref={menuAnchorRef}>
              <Section>
                <Button
                  iconColors={iconColors}
                  data-cy="idk-change-this"
                  active={false}
                  onClick={() => {
                    // ask aaron if this should do anything if only 1 org
                    // since the dropdown wont appear
                  }}
                >
                  {!loadingNavColor
                    ? (
                      <>
                        <Icon
                          customIcon={BiproxiIconEnum.Org}
                          color="inherit"
                          size="1.6rem"
                          width="24px"
                          height="24px"
                          justify="center"
                          transitionDuration="0.0s"
                        />
                      </>
                    ) : (
                      <Skeleton
                        circle
                        width="25px"
                        height="25px"
                        style={{ backgroundColor: Colors.Grey500 }}
                      />
                    )}
                  <TextContainer isExpanded={isExpanded}>
                    <Text
                      type={TextTypesEnum.Regular14}
                      color={textColors}
                    >
                      {!loadingNavColor ? currentOrganization.orgName
                        : (
                          <Skeleton
                            width="100px"
                            height="15px"
                            style={{ backgroundColor: Colors.Grey500 }}
                          />
                        )}
                    </Text>
                  </TextContainer>
                  {/* {isExpanded && (
                    <Icon
                      icon={Icons.AngleDownRegular}
                      color="inherit"
                      size="1.6rem"
                      justify="center"
                      transitionDuration="0.0s"
                    />
                  )} */}
                </Button>
                {isExpanded && (
                  <MenuContainer>
                    <AnchoredMenu
                      anchorRef={menuAnchorRef}
                      menuItems={menuItems}
                      placement={PopperPlacementTypes.BottomStart}
                      offset={[8, -4]}
                      maxWidth="220px"
                    />
                  </MenuContainer>
                )}
              </Section>
              <Line />
            </div>
          )}
          <Buttons>
            {mainSections.map((section) => (
              <Section key={section.name}>
                {section.name && (
                  <SectionTextOuterContainer>
                    <SectionTextContainer isExpanded={isExpanded}>
                      <Text
                        type={TextTypesEnum.Bold10}
                        color={textColors}
                      >
                        {!loadingNavColor ? section.name.toUpperCase() : (
                          <Skeleton
                            width="80px"
                            height="10px"
                            style={{ backgroundColor: Colors.Grey200 }}
                          />
                        )}
                      </Text>
                    </SectionTextContainer>
                  </SectionTextOuterContainer>
                )}
                {section.buttons.map((button, index) => {
                  const active = button.active?.find((activePath: string): boolean => pathname.includes(activePath));
                  return (
                    <Button
                      iconColors={iconColors}
                      data-cy={button.dataCy}
                      key={index}
                      active={active}
                      onClick={() => {
                        navigate(button.link);
                        if (isDesktop) {
                          setIsExpanded(false);
                        } else {
                          close();
                        }
                      }}
                    >
                      {!loadingNavColor
                        ? (
                          <>
                            <Icon
                              customIcon={button?.customIcon}
                              icon={button?.icon}
                              color="inherit"
                              size="1.6rem"
                              width="24px"
                              height="24px"
                              justify="center"
                              transitionDuration="0.0s"
                            />
                            {Boolean(button.notification) && <NotificationDot />}
                          </>
                        ) : (
                          <Skeleton
                            circle
                            width="25px"
                            height="25px"
                            style={{ backgroundColor: Colors.Grey500 }}
                          />
                        )}
                      <TextContainer isExpanded={isExpanded}>
                        <Text
                          type={TextTypesEnum.Regular14}
                          color={textColors}
                        >
                          {!loadingNavColor ? button.text
                            : (
                              <Skeleton
                                width="100px"
                                height="15px"
                                style={{ backgroundColor: Colors.Grey500 }}
                              />
                            )}
                        </Text>
                      </TextContainer>
                    </Button>
                  );
                })}
              </Section>
            ))}
            <BottomSection>
              {bottomSection.buttons.map((button, index) => (
                <Button
                  key={index}
                  data-cy={button.dataCy}
                  iconColors={iconColors}
                  active={false}
                  onClick={() => {
                    // hardcoded isExternal param, take note if you add more here
                    window.open(button.link, '_blank');
                    if (isDesktop) {
                      setIsExpanded(false);
                    } else {
                      close();
                    }
                  }}
                >
                  <Icon
                    customIcon={button.customIcon}
                    color="inherit"
                    size="1.6rem"
                    width="24px"
                    height="24px"
                    justify="center"
                    transitionDuration="0.0s"
                  />
                  {Boolean(button.notification) && <NotificationDot />}
                  <TextContainer isExpanded={isExpanded}>
                    <Text
                      type={TextTypesEnum.Regular14}
                      color={textColors}
                    >
                      {button.text}
                    </Text>
                  </TextContainer>
                </Button>
              ))}
            </BottomSection>
          </Buttons>
        </Container>
      </OuterContainer>
    </SlideIn>
  );
});

export default DashboardSideNavigation;
