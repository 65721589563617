import React from 'react';
import styled from '@emotion/styled';
import ListingOverviewChartDateFilterEnum from '@biproxi/models/enums/ListingOverviewChartDateFilterEnum';
import ListingLineChart from './ListingViewsLineChart';
import MostViewsCard from './MostViewsCard';
import TopSourcesCard from './TopSourcesCard';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Icons from '../../../elements/Icons';
import Colors from '../../../styles/Colors';
import Button, {
  ButtonTypesEnum,
  ButtonSizesEnum,
} from '../../../elements/Button';
import AnchoredMenu from '../../../elements/AnchoredMenu';
import { media } from '../../../utils/MediaQuery';
import LeadsOverviewCard from './LeadsOverviewCard';

const AnalyticsCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 16px;
  height: 284px;
  width: 100%;
  margin-bottom: 24px;
  ${media.mobile} {
    flex-direction: column;
    height: 100%;
  }
`;

const AnalyticsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ListingAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 24px 0;
`;

const MenuContainer = styled.div`
  z-index: 3;
  position: fixed;
`;

const MenuRef = styled.div``;

type AnalyticsProps = {
  listingId: string;
  listingUserId?: string;
};

const ListingAnalytics: React.FC<AnalyticsProps> = ({ listingId, listingUserId }) => {
  /* State */
  const [dropdownValue, setDropdownValue] = React.useState<ListingOverviewChartDateFilterEnum>(ListingOverviewChartDateFilterEnum.ThisWeek);

  /* Refs */
  const ref = React.useRef(null);

  /** Event handlers */
  const saveDropdownValue = (event, value) => {
    event.stopPropagation();
    setDropdownValue(value);
  };

  /** Populates dropdown items in filter menu */
  const dropdownItems = Object.values(ListingOverviewChartDateFilterEnum).map(
    (value) => ({
      text: value,
      onClick: (event) => saveDropdownValue(event, value),
    }),
  );
  return (
    <ListingAnalyticsContainer>
      {/* Container for the Analytics title and dropdown menu */}
      <AnalyticsTitleContainer>
        <Text type={TextTypesEnum.Bold24}>Analytics</Text>
        <MenuRef ref={ref}>
          <Button
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Small}
            icon={Icons.CalendarWeekSolid}
            iconSize={12}
            text={dropdownValue}
            iconColor={Colors.Grey900}
          />
        </MenuRef>
      </AnalyticsTitleContainer>
      <MenuContainer>
        <AnchoredMenu anchorRef={ref} menuItems={dropdownItems} />
      </MenuContainer>
      {/* Line chart */}
      <ListingLineChart listingId={listingId} listingUserId={listingUserId} filterValue={dropdownValue} />
      {/* Analytics Cards */}
      <AnalyticsCardsContainer>
        <MostViewsCard listingId={listingId} listingUserId={listingUserId} />
        <LeadsOverviewCard listingId={listingId} />
        <TopSourcesCard listingId={listingId} listingUserId={listingUserId} />
      </AnalyticsCardsContainer>
    </ListingAnalyticsContainer>
  );
};

export default ListingAnalytics;
