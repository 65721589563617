import React from 'react';
// import { GetServerSideProps } from 'next';
import styled from '@emotion/styled';
import { useMutation as rqMutation } from 'react-query';
import ReactTooltip from 'react-tooltip';
import { removeUserFromOrganization } from '@biproxi/next-api-requests';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { logger } from '@biproxi/logger';
import { TeamPermissionsEnum } from '@biproxi/models/enums/PermissionsEnum';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import Flex from '../../elements/Flex';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableHeader,
  ScrollTableHeaderCell,
} from '../ScrollTable';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import GenericToggle from '../../elements/GenericToggle';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import NextUtil from '../../utils/NextUtil';

const Container = styled.div<{ 'data-cy': string }>`
  data-cy: ${(props) => props['data-cy']};
  ${media.mobile} {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
  }
`;

const TooltipContainer = styled.div`
  width: 100%;
`;

const TeamMembersHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const TextContainer = styled(Text)`
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: "20px 0 0 20px";
`;

const MobileTeamContainer = styled.div`
  ${media.mobile} {
    width: 100vw;
    padding: 8px 8px;
    margin: 0 auto;
    overflow-x: hidden;
    white-space: nowrap;
  }
`;

type TeamPageUsersTableProps = {
  users: any;
  team: INeo4jServiceAPI.INeo4jOrganization;
  usersRolesMap: any;
  userPermissions: TeamPermissionsEnum[];
  refetch: () => void;
}

const TeamPageUsersTable: React.FC<TeamPageUsersTableProps> = ({
  users,
  team,
  usersRolesMap,
  userPermissions,
  refetch,
}) => {
  /** State */
  const teamId = team?._id;
  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushInviteMembersModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.InviteUserToTeam,
      props: {
        refetchUsers: refetch,
        organizationId: teamId,
        organizationName: team?.name,
        currentUsers: users,
        userPermissions,
      },
    }),
  );

  const pushRemoveUserModal = ({ organizationId, userId, userName }) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Warning,
        title: `Remove ${userName}?`,
        text: `Are you sure you want to remove ${userName} from your team? If you remove the member, they will no longer have access to the team. You can always add members back if you change your mind later`,
        buttonText: 'Remove member',
        confirm: () => handleRemoveUserFromOrg({ organizationId, userId }),
      },
    }),
  );

  const pushMakeUserAdminModal = ({
    modalContent,
  }) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Warning,
        title: modalContent.title,
        text: modalContent.body,
        confirmButton: modalContent?.permissed,
        buttonText: 'Confirm',
        confirm: modalContent?.onConfirm,
      },
    }),
  );

  const pushUserRemovedConfirmationModal = ({ userName }) => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: `${userName} has been removed`,
        text: 'You can always add members back if you change your mind later.',
        confirm: () => popModal(),
      },
    }),
  );

  /** React Query */
  const { mutate: handleRemoveUserFromOrg } = rqMutation((params: INeo4jServiceAPI.IRemoveUserFromOrganizationParams) => removeUserFromOrganization(params), {
    onSuccess: (data) => {
      popModal();
      handleRemoveUserFromOrganizationSuccess(data?.data?.updateUsers?.users?.[0]);
    },
    onError: (err) => handleRemoveUserFromOrganizationError(err),
  });

  /** Functions */
  const handleAdminToggle = async (isAdmin: boolean, userId: string, teamId: string) => {
    const orgRole = isAdmin ? TeamRolesEnum.Member : TeamRolesEnum.Admin;
    const isUserRoleUpdated = await PermissionsUtil.updateUserOrgRole([orgRole], userId, teamId);
    if (isUserRoleUpdated) {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Admin privileges successfully set',
      });
      refetch();
      popModal();
    } else {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an issue setting user privileges',
      });
    }
  };

  const handleAdminModalLogic = (params) => {
    const {
      isSelectedUserAdmin, userName,
    } = params;
    const modalContent = {
      title: '',
      body: '',
      permissed: false,
      onConfirm: () => popModal(),
    };
    if (params?.selectedUserRoles?.includes(TeamRolesEnum.Owner)) {
      modalContent.title = "You cannot edit an owner's permissions";
      modalContent.body = "You do not have the proper permissions for this action. Contact Biproxi support to edit an owner's permissions";
    } else if (params?.selectedUserRoles?.includes(TeamRolesEnum.Pending)) {
      modalContent.title = 'You cannot assign a role to a pending user';
      modalContent.body = 'A user must accept their invitation before you can assign a role to them';
    } else if (isSelectedUserAdmin && userPermissions?.includes(TeamPermissionsEnum.RemoveAdmin)) {
      modalContent.title = `Revoke ${userName} admin permissions?`;
      modalContent.body = `Are you sure you want to revoke admin privileges to ${userName}? By doing so, the user will no longer have full permissions within the team. You can always make the user an admin again if you change your mind later`;
      modalContent.permissed = true;
      modalContent.onConfirm = () => handleAdminToggle(isSelectedUserAdmin, params?.userId, params?.organizationId);
    } else if (userPermissions?.includes(TeamPermissionsEnum.AddAdmin) && !isSelectedUserAdmin) {
      modalContent.title = `Make ${userName} an admin?`;
      modalContent.body = `Are you sure you want to give admin privileges to ${userName}? By doing so, the user will have full permissions within the team. You can always revoke admin permissions if you change your mind later`;
      modalContent.permissed = true;
      modalContent.onConfirm = () => handleAdminToggle(isSelectedUserAdmin, params?.userId, params?.organizationId);
    } else {
      modalContent.title = 'You cannot do this action';
      modalContent.body = 'You do not have the proper permissions for this action. Only team admins can give and revoke admin permissions.';
    }
    pushMakeUserAdminModal({ modalContent });
  };

  const handleRemoveUserFromOrganizationSuccess = (data: INeo4jServiceAPI.INeo4jUser) => {
    pushUserRemovedConfirmationModal({ userName: `${data?.firstName} ${data?.lastName}` });
    refetch();
  };

  const handleRemoveUserFromOrganizationError = (error) => {
    logger.warn(`There was an issue removing a user from the team ${teamId}: ${error}`);
    pushToast({
      type: ToastTypesEnum.Error,
      message: 'There was an issue removing the user. Please contact support or try again later.',
    });
  };

  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /** Render */
  return (
    <>
      <TeamMembersHeader>
        {isMobile && (
        <>
          <MobileTeamContainer>
            <Flex direction="column" margin="56px 0 0 0">
              <TextContainer type={TextTypesEnum.Bold18}>
                Team members
              </TextContainer>
              <TextContainer
                type={TextTypesEnum.Regular14}
                color={Colors.Grey700}
              >
                View and manage teams
              </TextContainer>
              <Button
                text="Add member"
                size={ButtonSizesEnum.Small}
                type={ButtonTypesEnum.Pale}
                onClick={() => pushInviteMembersModal()}
                customWidth="100vw"
                margin="16px 0 0 0"
                data-cy="add-member-button"
              />
            </Flex>
          </MobileTeamContainer>
        </>
        )}
        {!isMobile && (
        <>
          <Flex direction="column">
            <Text type={TextTypesEnum.Bold18}>Team members</Text>
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Grey700}
            >
              View and manage team members
            </Text>
          </Flex>
          <Button
            text="Add member"
            size={ButtonSizesEnum.Small}
            type={ButtonTypesEnum.Pale}
            onClick={() => pushInviteMembersModal()}
            data-cy="add-member-button"
          />
        </>
        )}
      </TeamMembersHeader>
      <Container
        data-cy="team-members-table-wrapper"
      >
        <ScrollTable>
          <ScrollTableHeader width="100%">

            <ScrollTableHeaderCell>
              Name
            </ScrollTableHeaderCell>
            <ScrollTableHeaderCell>
              Email
            </ScrollTableHeaderCell>
            <ScrollTableHeaderCell>
              Status
            </ScrollTableHeaderCell>
            <ScrollTableHeaderCell>
              Admin
            </ScrollTableHeaderCell>
            { userPermissions?.includes(TeamPermissionsEnum.RemoveMembers) && (
            <ScrollTableHeaderCell>
              Actions
            </ScrollTableHeaderCell>
            )}

          </ScrollTableHeader>
          <ScrollTableBody>
            {users?.map((user, key) => {
              const newRoles = user?.roles?.filter((role) => role !== TeamRolesEnum.Pending);
              logger.info('TeamPageUsersTable', { newRoles });
              const userNodeInfo: INeo4jServiceAPI.INeo4jUser = user?.node;
              const isSelectedUserAdmin: boolean = usersRolesMap[userNodeInfo._id].includes(TeamRolesEnum.Owner)
                        || usersRolesMap[userNodeInfo._id].includes(TeamRolesEnum.Admin);
              const selectedUserRoles: TeamRolesEnum[] = usersRolesMap[userNodeInfo?._id];
              const truncatedUserEmail = userNodeInfo?.email?.length > 20 ? `${userNodeInfo?.email?.substring(0, 20)}...` : userNodeInfo?.email;
              const userName = `${userNodeInfo?.firstName} ${userNodeInfo?.lastName}`;
              return (
                <ScrollTableBodyRow key={key} height="48px">
                  <ScrollTableBodyCell>
                    <Text>
                      {userName}
                    </Text>
                  </ScrollTableBodyCell>
                  <ScrollTableBodyCell>
                    <TooltipContainer
                      data-tip={userNodeInfo?.email}
                      data-place="top"
                      data-multiline
                    >
                      <Text
                        data-cy={`user-table-email-${key}`}
                      >
                        {truncatedUserEmail}
                      </Text>
                    </TooltipContainer>
                  </ScrollTableBodyCell>
                  <ScrollTableBodyCell>
                    <Text
                      data-cy={`user-table-status-${key}`}
                      color={user?.roles?.includes(TeamRolesEnum.Pending) ? Colors.Yellow600 : Colors.Green600}
                    >
                      {user?.roles?.[0]?.charAt(0)?.toUpperCase() + user?.roles?.[0]?.slice(1)?.toLowerCase()}
                    </Text>
                  </ScrollTableBodyCell>
                  <ScrollTableBodyCell>
                    <GenericToggle
                      value={isSelectedUserAdmin}
                      // disabled={usersRolesMap[userNodeInfo._id].includes(TeamRolesEnum.Owner)}
                      noText
                      onChange={() => handleAdminModalLogic({
                        isSelectedUserAdmin, selectedUserRoles, userId: userNodeInfo?._id, userName: `${userNodeInfo.firstName} ${userNodeInfo.lastName}`, organizationId: teamId,
                      })}
                      dataCy={`admin-toggle-${key}`}
                    />
                  </ScrollTableBodyCell>
                  {userPermissions?.includes(TeamPermissionsEnum.RemoveMembers) && (
                  <ScrollTableBodyCell>
                    <ActionButtonsContainer>
                      {!selectedUserRoles?.includes(TeamRolesEnum.Owner) && (
                      <Icon
                        icon={Icons.TimesRegular}
                        color={Colors.Brand700 || Colors.Blurple700}
                        size={14}
                        onClick={() => {
                          const mutationParams: INeo4jServiceAPI.IRemoveUserFromOrganizationParams = {
                            userId: user?.node?._id,
                            organizationId: teamId,
                          };
                          pushRemoveUserModal({ ...mutationParams, userName: `${user?.node?.firstName} ${user?.node?.lastName}` });
                        }}
                        data-cy={`remove-user-${key}`}
                      />
                      )}

                    </ActionButtonsContainer>
                  </ScrollTableBodyCell>
                  )}
                </ScrollTableBodyRow>
              );
            })}
          </ScrollTableBody>
        </ScrollTable>
      </Container>
    </>
  );
};

export default TeamPageUsersTable;
