import React, { SetStateAction } from 'react';
import styled from '@emotion/styled';
import msaNames from '@biproxi/models/objects/data-explorer.msaNames.object';
import zipCodes from '@biproxi/models/objects/data-explorer.zipCodes.object';
import { useDebounceCallback } from '@react-hook/debounce';
import { useRecoilState } from 'recoil';
import { useInView } from 'react-intersection-observer';
import { useQuery } from '@apollo/client';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import Colors from '../../styles/Colors';
import { media } from '../../utils/MediaQuery';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import Input, { InputSizeEnum, InputTypesEnum } from '../../elements/Input';
import dataExplorerUtilityBarAtom from './state-management/recoil/atoms/DataExplorerUtilityBarAtom';
import { LoadMoreWhenInView, useInViewOptions } from '../InfiniteScroll';
import Loader, { LoaderSizes } from '../../elements/Loader';
import GeographiesFlexItem from './GeographiesFlextItem';
import DATA_EXPLORER_CHERRE_CITY_STATE from '../../graphql/queries/dataExplorerCherreCityState.query';

const GeographiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  z-index: 1000;
  background-color: ${Colors.White};

  position: absolute;
  top: 60px;
  right: 8%;
  width: 370px;
  height: 80vh;
  overflow-y: auto;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${media.mobile} {
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 72px;
`;

const InfiniteScrollLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type GeographyProps = {}

type GeographiesConfig = {}

const Geographies: React.FC<GeographiesConfig> = (_config: GeographiesConfig = { props: {} as GeographyProps }) => {
  /* State */
  const [activeTab, setActiveTab] = React.useState<'MSA' | 'Zip' | 'CityState' | ''>('MSA');
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [cleared, setCleared] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = React.useState<string[] | number[]>([]);

  const msaTabActive = Boolean(activeTab === 'MSA');
  const zipTabActive = Boolean(activeTab === 'Zip');
  const cityStateTabActive = Boolean(activeTab === 'CityState');

  const [currentZips, setCurrentZips] = React.useState<number>(50);
  const [infiniteLoad, setInfiniteLoad] = React.useState<boolean>(false);
  const [ref, inView] = useInView(useInViewOptions);

  /** Recoil State */
  const [utilityBarState, setUtilityBarState] = useRecoilState(dataExplorerUtilityBarAtom);

  const { data: cityStateData, loading: cityStateLoading, fetchMore } = useQuery(DATA_EXPLORER_CHERRE_CITY_STATE, {
    skip: !cityStateTabActive,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    variables: {
      params: {
        pagination: {
          offset: 0,
          limit: 50,
        },
      },
    },
    onCompleted: (data) => {
      setFilteredData(data?.dataExplorerCherreCityState?.cityState);
      setInfiniteLoad(false);
    },
  });

  const {
    geographiesVisible,
  } = utilityBarState;

  /** Actions */
  const cleaningZipsArr = () => {
    const sortedArray = zipCodes.sort((a, b) => a - b);
    const set = new Set(sortedArray);
    const removeDuplicates = [...set];
    const filterArray = removeDuplicates.filter((zip) => zip > 1000);
    const convertArray = filterArray.map(String);
    const padArr = convertArray.map((zip) => (zip.length < 5 && zip.length > 3 ? zip.padStart(5, '0') : zip));

    return padArr;
  };

  /** Stripped the dupes and add 0s to the zips less than 5 digits */
  const zips = cleaningZipsArr().slice(0, currentZips);

  const filterDataBySearchTerm = () => {
    // const zipSearch = zipTabActive && (setFilteredData(cleaningZipsArr()?.filter((dataPoint) => dataPoint?.toString().toLowerCase().includes(searchTerm.toLowerCase()))));
    setInfiniteLoad(false);
  };

  const addZips = () => {
    setCurrentZips(currentZips + 100);
    return currentZips;
  };

  /** Hooks */
  const filterDataBySearchTermDebounced = useDebounceCallback(filterDataBySearchTerm, 350);

  /** Effects */
  React.useEffect(() => {
    if (geographiesVisible && msaTabActive) setFilteredData(msaNames);
    if (geographiesVisible && zipTabActive) setFilteredData(zips);
    // if (geographiesVisible && cityStateTabActive) {
    //   setFilteredData(cityStateData?.dataExplorerCherreCityState?.cityState);
    // }
  }, [geographiesVisible]);

  React.useEffect(() => {
    setFilteredData(filterDataBySearchTermDebounced() as SetStateAction<any>);
    if (msaTabActive && !searchTerm) {
      setFilteredData(msaNames);
    }
    if (zipTabActive && !searchTerm) {
      setCurrentZips(50);
    }
  }, [searchTerm]);

  React.useEffect(() => {
    setSearchTerm('');
    if (geographiesVisible && msaTabActive) {
      setFilteredData(msaNames);
    }
    if (geographiesVisible && zipTabActive) {
      // then load a certain amount (50 zips)
      setFilteredData(zips);
    }
    if (geographiesVisible && cityStateTabActive) {
      setFilteredData([]);
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (cleared && zipTabActive && currentZips === 50) {
      setFilteredData(zips);
    }
    if (zipTabActive) {
      setFilteredData(zips);
    }
  }, [currentZips]);

  React.useEffect(() => {
    // if in view & still more data, zipActive, no Search, and it's not cleared
    if (inView && currentZips !== cleaningZipsArr().length && zipTabActive && !searchTerm) {
      setTimeout(() => {
        setInfiniteLoad(true);
        addZips();
      }, 3000);
      setInfiniteLoad(false);
    }

    const getData = async () => {
      if (!cityStateLoading
        && cityStateData
        && !searchTerm
        && inView
        && cityStateData?.dataExplorerCherreCityState?.cityState?.length
        && fetchMore
        && filteredData) {
        setInfiniteLoad(true);
        await fetchMore({
          variables: {
            params: {
              pagination: {
                limit: 100,
                offset: filteredData?.length,
              },
            },
          },
        });
      }
    };
    getData();
  }, [inView]);

  React.useEffect(() => {
    if (cleared && zipTabActive) {
      setCurrentZips(50);
    }
  }, [cleared]);

  /* Render */
  if (geographiesVisible) {
    return (
      <GeographiesWrapper data-cy="data-explorer-geographies-wrapper">
        <InnerWrapper id="inner">
          <ButtonWrapper>
            <Button
              text="MSA"
              type={msaTabActive ? ButtonTypesEnum.Primary : ButtonTypesEnum.Pale}
              size={ButtonSizesEnum.Small}
              margin="0 8px 0 0"
              data-cy="data-explorer-msa-button"
              onClick={() => {
                setActiveTab('MSA');
              }}
            />
            <Button
              text="Zip"
              type={zipTabActive ? ButtonTypesEnum.Primary : ButtonTypesEnum.Pale}
              size={ButtonSizesEnum.Small}
              margin="0 8px 0 0"
              data-cy="data-explorer-zip-button"
              onClick={() => {
                setActiveTab('Zip');
              }}
            />
            <Button
              text="City/State"
              type={cityStateTabActive ? ButtonTypesEnum.Primary : ButtonTypesEnum.Pale}
              size={ButtonSizesEnum.Small}
              margin="0 8px 0 0"
              data-cy="data-explorer-city-state-button"
              onClick={() => {
                setActiveTab('CityState');
              }}
            />
            <Icon
              data-cy="data-explorer-close-geographies-button"
              icon={Icons.TimesRegular}
              color={Colors.Brand700 || Colors.Blurple700}
              size="24px"
              margin="0 0 0 auto"
              onClick={() => {
                setActiveTab('MSA');
                setUtilityBarState((prevState) => ({
                  ...prevState,
                  geographiesVisible: false,
                }));
              }}
            />
          </ButtonWrapper>
          <Input
            data-cy="data-explorer-geographies-search-bar"
            value={searchTerm}
            placeholder={`Search ${activeTab === 'CityState' ? 'City State' : activeTab}`}
            inputType={InputTypesEnum.Search}
            inputSize={InputSizeEnum.Small}
            clear={() => {
              setSearchTerm('');
              setCleared(true);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
          />
          <Flex dataCy="data-explorer-geographies-list-container" padding="0 0 10px 0" direction="column" gap="12px" width="100%">
            {cityStateLoading && !infiniteLoad ? (
              <LoaderContainer>
                <Loader color={Colors.Brand800 || Colors.Blurple800} />
              </LoaderContainer>
            ) : (
              filteredData?.map((dataPoint, index) => (
                <GeographiesFlexItem
                  dataPoint={dataPoint}
                  cityState={dataPoint}
                  msaTabActive={msaTabActive}
                  cityStateTabActive={cityStateTabActive}
                  key={index}
                />
              ))
            )}
            {filteredData && (<LoadMoreWhenInView ref={ref} />)}
          </Flex>
          {infiniteLoad && !searchTerm && !msaTabActive ? (
            <InfiniteScrollLoaderContainer id="loader-container" data-cy="zips-infinite-loader-container">
              <Loader size={LoaderSizes.Large} color={Colors.Brand800 || Colors.Blurple800} />
            </InfiniteScrollLoaderContainer>
          ) : null}
        </InnerWrapper>
      </GeographiesWrapper>
    );
  }

  return null;
};

export default Geographies;
