import { atom } from 'recoil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import IParcelBoundaryDetails from '@biproxi/models/interfaces/IParcelBoundaryDetails';
import DataExplorerSettingsSectionsContent from '../../../@types/data-settings-types/objects/DataExplorerSettingsSectionsContent';

interface IPropertyAddressData extends IParcelBoundaryDetails {
    latitude: number;
    longitude: number;
}

interface IDataExplorerFilterAddress extends Omit<IAddress, 'city'> {
  city?: string;
}

export interface IDataExplorerFilter {
  address: IDataExplorerFilterAddress;
}

export type DataExplorerDataFrameGeneralStateType = {
    dataFrameVisible: boolean;
    dataFrameLoading: boolean;
    insightDataExpanded: boolean;
    similarPropertyDataExpanded: boolean;
    similarPropertiesLoading: boolean;
    comparePropertiesExpanded: boolean;
    dataSettingsSelectedData: any;
}

export type DataExplorerDataFrameTaxStateType = {
    propertyAddressData: IPropertyAddressData;
}

export type DataExplorerDataFrameSimilarPropertiesStateType = {
    selectedComparedPropertyCherreIds: string[];
}

export type DataExplorerDataFrameComparePropertiesStateType = {
    compareProperties: boolean;
    comparePropertiesLoading: boolean;
}

export type DataExplorerDataFrameContextType = {
  showFilterPropertyList: boolean;
  comparedPropertyPage: number;
};

export const dataFrameGeneralAtom = atom({
  key: 'dataFrameGeneralState',
  default: {
    dataFrameVisible: false,
    dataFrameLoading: false,
    insightDataExpanded: true,
    similarPropertyDataExpanded: false,
    similarPropertiesLoading: false,
    comparePropertiesExpanded: false,
    dataSettingsSelectedData: DataExplorerSettingsSectionsContent,
  } as DataExplorerDataFrameGeneralStateType,
});

export const dataFrameTaxAtom = atom({
  key: 'dataFrameTaxState',
  default: {
    propertyAddressData: {},
  } as DataExplorerDataFrameTaxStateType,
});

export const dataFrameSimilarPropertiesAtom = atom({
  key: 'dataFrameSimilarPropertiesState',
  default: {
    selectedComparedPropertyCherreIds: [],
  } as DataExplorerDataFrameSimilarPropertiesStateType,
});

export const dataFrameComparePropertiesAtom = atom({
  key: 'dataFrameComparePropertiesState',
  default: {
    comparePropertiesLoading: false,
  } as DataExplorerDataFrameComparePropertiesStateType,
});
