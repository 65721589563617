import React from 'react';
import styled from '@emotion/styled';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useInView } from 'react-intersection-observer';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import { ChatActions } from '../redux/chat.redux';
import BlueDot from '../elements/BlueDot';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import InfiniteScroll, { LoadMoreWhenInView, useInViewOptions } from './InfiniteScroll';
import Divider from '../elements/Divider';

const Container = styled.div`
  max-width: calc(100vw - 32px);
`;

const RowContainer = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
`;

const Row = styled.div<{ padding?: string, type?: MessageTableTypesEnum, length?: number }>`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  margin: 16px 0;
  position: relative;
  transition: all 0.2s;

  &:hover {
    background: ${Colors.Grey50};
    border-radius: 8px;
    box-shadow: 0px 0px 0px 8px #F9FAFB;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

`;

const DividerContainer = styled.div`
  padding: 0 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

const TextContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RightContainer = styled.div`
  min-width: fit-content;
  display: flex;
  align-items: center;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export enum MessageTableTypesEnum {
  Page = 'Page',
  Card = 'Card',
  Dropdown = 'Dropdown',
}

type MessageTableProps = {
  channels: IChannelGraphQL[];
  type?: MessageTableTypesEnum;
  fetchMore?: any;
  search?: string;
};

const MessageTable: React.FC<MessageTableProps> = ({
  channels,
  type = MessageTableTypesEnum.Page,
  fetchMore,
  search,
}) => {
  /** State */
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);

  /** Hooks */
  const [ref, inView] = useInView(useInViewOptions);

  /** Actions */
  const dispatch = useAppDispatch();
  const pushActiveChatChannel = (channel) => dispatch(
    ChatActions.pushActiveChatChannels({ channel }),
  );
  const pushChannelOpenedUrl = (channelUrl) => dispatch(
    ChatActions.pushChannelsOpenedUrls({ channelUrl }),
  );
  // const { chat: { unreadMessageCount } } = useAppSelector((state: AppState) => state);
  const { activeChatChannels, channelsOpenedUrls } = useAppSelector((state: AppState) => state.chat);

  /** Effects */
  React.useEffect(() => {
    const getData = async () => {
      const cursor = channels[channels?.length - 1]?.paginationCursor;
      if (cursor && inView && fetchMore) {
        setInfiniteLoading(true);
        await fetchMore({
          variables: {
            params: {
              search,
              pagination: {
                limit: PaginationLimitsEnum.Channels,
                cursor,
              },
            },
          },
        });
        setInfiniteLoading(false);
      }
    };
    getData();
  }, [inView]);

  // Issue with blue dot sometimes... Need to fix.
  const shouldRenderBlueDot = (channel: IChannelGraphQL): boolean => (channel.hasUnreadMessages)
    && (!activeChatChannels?.find((ch) => ch?.channelUrl === channel?.channelUrl))
    && (!channelsOpenedUrls?.includes(channel?.channelUrl));
    // && unreadMessageCount > 0

  /* Render the card view */
  if (type === MessageTableTypesEnum.Card) {
    return (
      <Container>
        {channels?.map((channel, index) => (
          <Row
            padding="12px 4px"
            onClick={() => {
              if (!channelsOpenedUrls.includes(channel.channelUrl)) {
                pushChannelOpenedUrl(channel.channelUrl);
              }
              pushActiveChatChannel(channel);
            }}
            key={`${channel.channelUrl}-${index}`}
          >
            <LeftContainer>
              <TextContainer marginTop="0px">
                <NameContainer>
                  {shouldRenderBlueDot(channel) && (<BlueDot marginTop="1px" />)}
                  <Text type={TextTypesEnum.Regular14} color={Colors.Black} margin="0px">
                    {channel?.lastMessageText || 'File Message'}
                  </Text>
                </NameContainer>
              </TextContainer>
            </LeftContainer>
            <RightContainer>
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey900} margin="0px 8px 0px 0px">
                {`${channel?.user?.firstName} ${channel?.user?.lastName}`}
              </Text>
              <ProfileImage
                user={channel.user}
                type={ProfileImageTypes.Small}
                margin="0"
              />
            </RightContainer>
          </Row>
        ))}
      </Container>
    );
  }

  /** Render the full page view or dropdown view as they are more similar */
  return (
    <>
      <Container>
        {channels?.map((channel, index) => (
          <React.Fragment
            key={`${channel.channelUrl}-${index}`}
          >
            <RowContainer padding={type === MessageTableTypesEnum.Dropdown ? '0 16px' : '0 8px'}>
              <Row
                onClick={() => {
                  if (!channelsOpenedUrls.includes(channel.channelUrl)) {
                    pushChannelOpenedUrl(channel.channelUrl);
                  }
                  pushActiveChatChannel(channel);
                }}
                type={type}
                length={channels?.length}
              >
                <LeftContainer>
                  <ImageContainer>
                    <ProfileImage
                      user={channel.user}
                      type={type === MessageTableTypesEnum.Dropdown ? ProfileImageTypes.Small : ProfileImageTypes.Large}
                      margin={type === MessageTableTypesEnum.Dropdown ? '4px 8px 0px 0px' : '0px 8px 0px 0px'}
                    />
                  </ImageContainer>
                  <TextContainer>
                    <Flex width="100%" justify="space-between" align="center">
                      <NameContainer>
                        {shouldRenderBlueDot(channel) && (<BlueDot />)}
                        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
                          {`${channel.user.firstName} ${channel.user.lastName}`}
                        </Text>
                      </NameContainer>
                      <Text type={TextTypesEnum.Regular12} color={Colors.Grey500}>
                        {TimeUtil.format(channel.lastMessageAt, 'ff', TimeUtil.currentBrowserTimezone())}
                      </Text>
                    </Flex>
                    {type !== MessageTableTypesEnum.Dropdown && (
                      <Text type={TextTypesEnum.Regular12} color={Colors.Grey500}>
                        {ListingUtil.name(channel.listing, { allButAddress1: true })}
                      </Text>
                    )}
                    <Text type={TextTypesEnum.Regular12} color={Colors.Grey700}>
                      {channel.lastMessageText || 'File Message'}
                    </Text>
                  </TextContainer>
                </LeftContainer>
              </Row>
            </RowContainer>
            <DividerContainer>
              {index !== channels.length - 1 && (
                <Divider color={Colors.Grey200} />
              )}
            </DividerContainer>
          </React.Fragment>
        ))}
      </Container>
      <LoadMoreWhenInView ref={ref} />
      {infiniteLoading && <InfiniteScroll />}
    </>
  );
};

export default MessageTable;
