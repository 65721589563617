import gql from 'graphql-tag';

const LISTING_VIEWS_GRAPH_ANALYTICS = gql`
    query ListingViewsGraphAnalytics($params: ListingViewsGraphAnalyticsParams) {
        listingViewsGraphAnalytics(params: $params) {
            graphData
        }
    }
`;

export default LISTING_VIEWS_GRAPH_ANALYTICS;
