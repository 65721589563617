import React from 'react';
import { useQuery } from '@apollo/client';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { useAppDispatch } from '../redux/store';
import SendBirdClientSDK from '../utils/SendBirdClientSDK';
import { ChatActions } from '../redux/chat.redux';
import GET_USER from '../graphql/queries/user.query';

// type UseInitializeSendbirdHookParams = {};

type UseInitializeSendbirdHook = () => void;

const useInitializeSendbird: UseInitializeSendbirdHook = () => {
  /* GraphQL */
  type TData = {
    user: IUserGraphQL;
  };

  const { data } = useQuery<TData>(GET_USER);

  /* Actions */
  const dispatch = useAppDispatch();

  const handleNewMessage = (channel, message) => dispatch(
    ChatActions.setNewMessageReceived({ channel, message }),
  );

  const setUnreadMessageCount = (unreadMessageCount: number) => dispatch(
    ChatActions.setUnreadMessageCount({ unreadMessageCount }),
  );

  /* Effects */
  React.useEffect(() => {
    (async () => {
      if (data?.user?._id) {
        const res = await SendBirdClientSDK.connectUser(data.user._id);
        if (res) {
          SendBirdClientSDK.connectChannelEventHandler(handleNewMessage);
          const count = await SendBirdClientSDK.getUnreadMessageCount();
          if (count > 0) setUnreadMessageCount(count);
          SendBirdClientSDK.connectUserEventHandler(setUnreadMessageCount);
        }
      }
    })();

    return () => {
      SendBirdClientSDK.disconnectUser();
    };
  }, [data]);
};

export default useInitializeSendbird;
