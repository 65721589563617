import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import IParcelBoundaryDetails from '@biproxi/models/interfaces/IParcelBoundaryDetails';
import { useQuery } from '@apollo/client';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import { AppState, useAppSelector } from '../../../redux/store';
import Flex from '../../../elements/Flex';
import Text, { TextTypesEnum } from '../../../elements/Text';
import { Icons } from '../../../elements/Icon';
import Colors from '../../../styles/Colors';
import CHERRE_DATA_FROM_PARCEL_ID from '../../../graphql/queries/cherrePropertyDataFromParcelId.query';
import CHERRE_DATA from '../../../graphql/queries/listingCherreDataFromFormattedAddressAndGeographyId.query';
import Loader from '../../../elements/Loader';
import DataFrameSimilarProperties from './DataFrameSimilarProperties';
import DataFrameAddressAndTaxInfo from './DataFrameTaxInfo';
import CompareProperties from './CompareProperties';
import { media, useMobileMedia } from '../../../utils/MediaQuery';
import { dataFrameGeneralAtom, dataFrameTaxAtom } from '../state-management/recoil/atoms/DataExplorerDataFrameAtoms';
import { dataExplorerGlobalAtom } from '../state-management/recoil/atoms/DataExplorerGlobalContext';
import NoContent from '../../NoContent';
import DataFrameAddressOverview from './DataFrameAddressOverview';

type DataFrameWrapperProps = {
    dataLoading?: boolean;
    width: string;
}
const DataFrameWrapper = styled.div<DataFrameWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: ${({ dataLoading }) => (dataLoading ? 'center' : 'flex-start')};
    justify-content: ${({ dataLoading }) => (dataLoading ? 'center' : 'null')};

    background-color: ${Colors.White};

    position: absolute;
    left: 0px;
    top: 48px;

    width: ${({ width }) => width};
    height: calc(100vh - 104px); // Entire viewport height - (height of data explorer utility bar (32px) + padding on utility bar (16px) + height of main nav bar (56px))
    overflow-x: hidden;
    overflow-y: hidden;

    ${media.mobile} {
      width: fit-content;
      top: 91px;
      height: calc(100vh - 152px); // Entire viewport height - (height of data explorer utility bar (92px) + padding on utility bar (16px) + height of main nav bar (56px))
      overflow-x: scroll;
      max-width: fit-content;
    }
`;

const StickyDataFrameAddressPreview = styled.div`
    position: sticky;
    top: 0;
    margin-bottom: 16px;
    `;

type DataFrameDataWrapperProps = {
    isFullScreen: boolean;
}
const DataFrameDataWrapper = styled.div<DataFrameDataWrapperProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 32px;
    height: 100%;

    ${media.mobile} {
      flex-direction: column;
    }
`;

type SelectedDataFrameWrapperProps = {
    width: string;
    fullScreen: boolean;
}

const SelectedDataFrameWrapper = styled.div<SelectedDataFrameWrapperProps>`
    display: flex;
    flex-direction: column;
    min-width: 340px;
    width: ${({ width }) => width};
    height: 100%; // calc(100% - 24px) = calc(100% - (top padding (12px) + bottom padding (12px)))
    padding: 12px 16px;
    gap: 16px;
    border-right: ${Colors.Grey200} 1px solid;


    ${media.mobile} {
      height: inherit;

    }
`;

const NoContentContainer = styled.div`
    width: fill-available;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    gap: 16px;
`;

type DataFrameProps = {
    parcelFeatureData?: IParcelBoundaryDetails
};

/**
 * Main parent component for the 'Data Frame' that appears when you click on (or search for) a property
 */
const DataFrame: React.FC<DataFrameProps> = ({
  parcelFeatureData,
}) => {
  /* State/Context */
  const [selectedTaxId, setSelectedTaxId] = React.useState<number>(null);
  const [taxIds, setTaxIds] = React.useState<number[]>([]);
  const [noData, setNoData] = React.useState<boolean>(false);
  const isMobile = useMobileMedia();

  const {
    user: {
      userPermissions,
    },
  } = useAppSelector((state: AppState) => state);

  const canUserViewDataExplorerData = PermissionsUtil.canUserViewDataExplorerData(userPermissions?.userBiproxiRolesAndPermissions);
  logger.info(`User can view data explorer data: ${canUserViewDataExplorerData}`);
  const [dataExplorerGeneralState, setDataFrameGeneralState] = useRecoilState(dataFrameGeneralAtom);
  const setDataFrameTaxState = useSetRecoilState(dataFrameTaxAtom);
  const {
    dataFrameVisible,
    dataFrameLoading,
    comparePropertiesExpanded,
  } = dataExplorerGeneralState;

  const dataExplorerGlobalState = useRecoilValue(dataExplorerGlobalAtom);
  const {
    mapVisible,
  } = dataExplorerGlobalState;

    /* GraphQL */
    type PropertyTaxListingIdsVars = {
        params: {
            cherreParcelId: string;
        };
    };

    type PropertyTaxListingIdsData = {
        cherreTaxIdsFromParcelId: number[];
    };

    /**
     * First GQL query that is called
     * This queries for all tax id's associated with a property
     * Within Cherre-land, each property has a UUID called 'cherre_parcel_id'. This UUID is used to get ALL tax id's that are associated with that property.
     * Tax id's are used as the next step of the process -- receiving all mf tax info (insights and demographics) on the property
     */
    const { data: _propertyTaxListingIds, loading: _propertyTaxListingIdsLoading } = useQuery<PropertyTaxListingIdsData, PropertyTaxListingIdsVars>(CHERRE_DATA_FROM_PARCEL_ID, {
      variables: {
        params: {
          cherreParcelId: parcelFeatureData?.cherre_parcel_id,
        },
      },
      skip: !parcelFeatureData || !parcelFeatureData?.cherre_parcel_id,
      onError: (error) => {
        logger.error('There was an error fetching property tax listing ids from Cherre in the DataFrame component: ', error);
      },
      onCompleted: (data) => {
        if (data?.cherreTaxIdsFromParcelId?.length) {
          const taxIds: number[] = data?.cherreTaxIdsFromParcelId;
          setTaxIds(taxIds);
          setSelectedTaxId(taxIds[0]);
          setNoData(false);
        } else {
          setDataFrameGeneralState((prevState) => ({
            ...prevState,
            dataFrameLoading: false,
          }));
          setNoData(true);
        }
      },
    });

    type PropertyDataVars = {
        params: {
            cherreTaxAssessorId: number,
            cherreGeographyId: string,
        };
    };

    type PropertyData = {
        cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId: {
            insights: ICherreDataInsights,
            demographics: ICherreDataDemographics,
            priorYearDemographics: ICherreDataDemographics,
        };
    };

    /**
     * Second GQL query that is called
     * Once we have the tax id(s) associated with a property, we need to use that tax id to receive all tax info (the insights and demographics)
     * This query does exactly that
     */
    const { data: cherrePropertyData, loading: _cherrePropertyDataLoading } = useQuery<PropertyData, PropertyDataVars>(CHERRE_DATA, {
      skip: !selectedTaxId,
      variables: {
        params: {
          cherreTaxAssessorId: Number(selectedTaxId),
          cherreGeographyId: `ZI${parcelFeatureData?.zip}`,
        },
      },
      onError: (error) => {
        logger.error('DataFrame CHERRE_DATA error', error);
      },
      onCompleted: (data) => {
        setDataFrameGeneralState((prevState) => ({
          ...prevState,
          dataFrameLoading: false,
        }));
        setDataFrameTaxState((prevState) => ({
          ...prevState,
          propertyAddressData: {
            ...parcelFeatureData,
            latitude: data?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights?.latitude,
            longitude: data?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights?.longitude,
          },
        }));
      },
    });
    const setSelectedTaxIdCallback = React.useCallback((taxId: number) => setSelectedTaxId(taxId), []);

    if (noData) {
      return (
        <DataFrameWrapper
          width={mapVisible ? '460px' : '100%'}
          data-cy="data-frame-wrapper"
          id="data-frame-wrapper"
        >
          <NoContentContainer>
            <NoContent
              height="100%"
              icon={Icons.EyeRegular}
              iconMargin="0 0 12px 0"
              text="No data found for this address. Try searching for another address"
            />
          </NoContentContainer>
        </DataFrameWrapper>
      );
    }

    if (dataFrameVisible && dataFrameLoading) {
      return (
        <DataFrameWrapper
          dataLoading={dataFrameLoading}
          data-cy="data-frame-loading-wrapper"
          width={isMobile ? '100%' : '460px'}
        >
          <Loader
            color={Colors.Brand700 || Colors.Blurple700}
          />
        </DataFrameWrapper>
      );
    }

    if (dataFrameVisible && !dataFrameLoading) {
      const {
        address,
        city,
        state,
        zip,
      } = parcelFeatureData ?? {
        address: 'Address not available',
        city: 'City not available',
        state: 'State not available',
        zip: 'Zip not available',
      };

      const {
        latitude,
        longitude,
      } = cherrePropertyData?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights ?? {
        latitude: null,
        longitude: null,
      };

      const addressOverview = {
        address,
        city,
        state,
        zip,
        latitude,
        longitude,
      };

      const insights: ICherreDataInsights = cherrePropertyData?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights;
      const demographics: ICherreDataDemographics = cherrePropertyData?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.demographics;

      return (
        <DataFrameWrapper
          width={mapVisible ? '460px' : '100%'}
          data-cy="data-frame-wrapper"
          id="data-frame-wrapper"
        >
          <SelectedDataFrameWrapper
            fullScreen={!mapVisible}
            width={comparePropertiesExpanded ? null : '100%'}
          >
            <DataFrameDataWrapper isFullScreen={!mapVisible}>
              <Flex direction="column">
                <Text
                  type={TextTypesEnum.Bold16}
                  color={Colors.Grey900}
                  margin="0 0 12px 0"
                >
                  Selected property
                </Text>
                <StickyDataFrameAddressPreview>
                  <DataFrameAddressOverview
                    addressInfo={addressOverview}
                    taxIds={taxIds as any}
                    selectedTaxId={selectedTaxId}
                    setSelectedTaxId={setSelectedTaxIdCallback}
                  />
                </StickyDataFrameAddressPreview>
                <DataFrameAddressAndTaxInfo
                  taxInsights={insights}
                  taxDemographics={demographics}
                  taxIds={taxIds}
                />
              </Flex>
              {
                !mapVisible && (
                <DataFrameSimilarProperties
                  similarPropertyParams={
                    {
                      zip,
                      city,
                      state,
                      latitude,
                      longitude,
                      property_use_standardized_code: insights?.property_use_standardized_code,
                      property_group_type: insights?.property_group_type,
                      lotSizeSqft: insights?.lot_size_sq_ft,
                    }
                }
                />
                )
              }
            </DataFrameDataWrapper>
          </SelectedDataFrameWrapper>
          {comparePropertiesExpanded && (
            <CompareProperties
              setSelectedTaxIdCallback={setSelectedTaxIdCallback}
              selectedTaxId={selectedTaxId}
              selectedPropertyInfo={cherrePropertyData}
            />
          )}
        </DataFrameWrapper>
      );
    }

    return null;
};

const propsAreEqual = (prevProps, nextProps) => {
  const prevParcelFeatureData: IParcelBoundaryDetails = prevProps.parcelFeatureData;
  const nextParcelFeatureData: IParcelBoundaryDetails = nextProps.parcelFeatureData;

  const arePropsEqual = (
    prevParcelFeatureData?.cherre_parcel_id === nextParcelFeatureData?.cherre_parcel_id
    && prevParcelFeatureData?.address === nextParcelFeatureData?.address
    && prevParcelFeatureData?.city === nextParcelFeatureData?.city
    && prevParcelFeatureData?.state === nextParcelFeatureData?.state
    && prevParcelFeatureData?.zip === nextParcelFeatureData?.zip
  );
  return arePropsEqual;
};

export default React.memo(DataFrame, propsAreEqual);
// export default DataFrame;
