import React from 'react';
import styled from '@emotion/styled';
import IAddress, { addressState } from '@biproxi/models/interfaces/IAddress';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingParams from '@biproxi/models/interfaces/IListingParams';
import ListingRequiredFieldsEnum from '@biproxi/models/enums/ListingRequiredFieldsEnum';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import {
  KB_CREATE_LISTING_BASIC_URL,
  KB_CREATE_LISTING_LEASE_URL,
} from '@biproxi/models/externalLinks';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Input, { InputTypesEnum } from '../../elements/Input';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Address from '../Address';
import CreateListingVideo from './CreateListingVideo';
import CreateListingPhotos from './CreateListingPhotos';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import LinkText from '../../elements/LinkText';
import Colors from '../../styles/Colors';
import ListingStatus from '../ListingStatus';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import Link from '../../elements/Link';
import RadioList from '../../elements/RadioList';
import ISelectionBoxConfig from '../../models/interfaces/ISelectionBoxConfig';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import useUser from '../../hooks/useUser.hook';
import useUserPermissions from '../../hooks/useUserPermissions.hook';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 32px;
`;

const RedBar = styled.div`
  width: 4px;
  background: ${Colors.Red400};
  height: 100%;
`;

const ForLeaseContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${Colors.Red50};
  gap: 8px;
  padding: 0 16px;
`;

const customSubtitle: React.ReactElement = (
  <>
    <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>
      Provide basic information about your listing to start building your property display page.
    </Text>
    {'\n'}
    <Flex height="56px" width="100%">
      <RedBar />
      <ForLeaseContainer>
        <Icon
          icon={Icons.ExclamationTriangleSolid}
          size="16px"
          color={Colors.Red400}
        />
        <Text type={TextTypesEnum.Regular16} color={Colors.Red900}>
          *For lease properties are not supported at this time.
          {'\n'}
          <Link
            href={KB_CREATE_LISTING_LEASE_URL}
            target="_blank"
            isExternal
          >
            <LinkText type={TextTypesEnum.Bold16}>Learn More</LinkText>
          </Link>
        </Text>
      </ForLeaseContainer>
    </Flex>
  </>
);

type CreateListingBasicInformationProps = {
  id?: string;
  listing: IListingGraphQL;
  hideStatus?: boolean;
  hidePrivateListing?: boolean; // At some point, probably want to tie the listing sections to object keys or something similar so that we conditionally render sections more flexibly than passing boolean values to hide certain sections lol
};

const CreateListingBasicInformation: React.FC<CreateListingBasicInformationProps> = ({
  id,
  listing,
  hideStatus,
  hidePrivateListing,
}) => {
  /* State */
  const errors = useAppSelector(ListingSelectors.errors);
  const [isPrivateListing, setIsPrivateListing] = React.useState(listing?.isPrivateListing ?? false);
  const [isPortfolioListing, setIsPortfolioListing] = React.useState(listing?.isPortfolioListing ?? false);
  const [currentPortfolioAddressInput, setCurrentPortfolioAddressInput] = React.useState(addressState());

  /** Hooks */
  const { userId } = useUser();
  const { userBiproxiRolesAndPermissions } = useUserPermissions({ userId });
  /**
   * Get all the listing errors
   * that begin with /listing/address.
   *
   * These errors will be passed to children
   * components to render on a per field basis.
   */

  const addressErrors = Object.entries(errors).reduce((cur: Record<string, string>, [key, value]) => {
    if (key.includes('/address')) {
      cur[key.substring('/address'.length)] = value;
    }
    return cur;
  }, {});
  const photoError = errors['/media/fileIds'];
  const portfolioAddressError = errors['/portfolioAddresses'];

  /* Actions */
  const dispatch = useAppDispatch();

  const setListingField = (fields: Partial<IListingParams>) => dispatch(
    ListingActions.setListingField(fields),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushChangeListingStatusModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeListingStatus,
      props: {
        listing,
      },
    }),
  );

  const pushBillingPaywalModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.CreatePrivateListing,
      },
    }),
  );

  const createPrivateListingPermission: boolean = PermissionsUtil.canUserCreatePrivateListing(userBiproxiRolesAndPermissions);
  const radioListConfigPrivate: ISelectionBoxConfig[] = [
    {
      label: 'Yes',
      active: isPrivateListing,
      onClick: () => {
        if (createPrivateListingPermission) {
          setListingField({ isPrivateListing: true });
          setIsPrivateListing(true);
        } else {
          pushBillingPaywalModal();
        }
      },
      labelTextType: TextTypesEnum.Regular12,
    },
    {
      label: 'No',
      active: !isPrivateListing,
      onClick: () => {
        setListingField({ isPrivateListing: false });
        setIsPrivateListing(false);
      },
      labelTextType: TextTypesEnum.Regular12,
    },
  ];

  const radioListConfigPortfolio: ISelectionBoxConfig[] = [
    {
      label: 'Yes',
      active: isPortfolioListing,
      onClick: () => {
        setListingField({ isPortfolioListing: true });
        setIsPortfolioListing(true);
      },
      labelTextType: TextTypesEnum.Regular12,

    },
    {
      label: 'No',
      active: !isPortfolioListing,
      onClick: () => {
        setListingField({ isPortfolioListing: false, portfolioAddresses: [] });
        setIsPortfolioListing(false);
      },
      labelTextType: TextTypesEnum.Regular12,
    },
  ];

  /* Render */
  return (
    <ContentCard
      id={id}
      dataCy="create-listings-greeting"
      title="Basic Information"
      subtitle={customSubtitle}
      learnMoreLink={KB_CREATE_LISTING_BASIC_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <Grid>
        {!hidePrivateListing && (
        <RadioList
          dataCy="private-radio-list"
          title="Is this a private listing?"
          titleTextType={TextTypesEnum.Medium12}
          config={radioListConfigPrivate}
          flexDirection="row"
          columnGap="0 24px 0 0"
        />
        )}
        <RadioList
          dataCy="portfolio-radio-list"
          title="Is this a portfolio listing?"
          titleTextType={TextTypesEnum.Medium12}
          config={radioListConfigPortfolio}
          flexDirection="row"
          columnGap="0 24px 0 0"
        />
        {listing?.isPortfolioListing ? (
          <Address
            label={`${StringUtil.addNumberOrdinals(listing?.portfolioAddresses?.length + 1)} property address in the portfolio`}
            dataCy="property-address-input"
            id={ListingRequiredFieldsEnum.PortfolioAddresses}
            value={currentPortfolioAddressInput}
            onChange={(address: IAddress) => {
              if (AddressUtil.isCompleteAddress(address)) {
              // set the first address to be the 'address' on the listing
              // for validation purposes
                if (listing?.portfolioAddresses?.length === 0) {
                  setListingField({ address });
                }

                // clear the visible address in the input box
                setCurrentPortfolioAddressInput(addressState());

                // add to the portfolio addresses list
                setListingField({
                  portfolioAddresses: listing?.portfolioAddresses
                    ? [...listing?.portfolioAddresses, address]
                    : [address],
                });
              } else {
                // pushToast({
                //   type: ToastTypesEnum.Error,
                //   message: 'This address is invalid for a listing. Select another or contact support.',
                // });
              }
            }}
            errors={{ '/address1': portfolioAddressError }}
            fullAddressesOnly
            isPortfolioInput
            onManualChange={(address: IAddress) => {
              setCurrentPortfolioAddressInput(address);
            }}
            onManualComplete={() => {
              if (AddressUtil.isCompleteAddress(currentPortfolioAddressInput)) {
                if (listing?.portfolioAddresses?.length === 0) {
                  setListingField({ address: currentPortfolioAddressInput });
                }
                setCurrentPortfolioAddressInput(addressState());
                setListingField({
                  portfolioAddresses: listing?.portfolioAddresses
                    ? [...listing?.portfolioAddresses, currentPortfolioAddressInput]
                    : [currentPortfolioAddressInput],
                });
              } else {
                pushToast({
                  type: ToastTypesEnum.Error,
                  message: 'Please fill out all fields for this address.',
                });
              }
            }}
          />
        ) : (
          <Address
            label="Property address"
            dataCy="property-address-input"
            id={ListingRequiredFieldsEnum.Address}
            value={listing.address}
            onChange={(address: IAddress) => setListingField({ address })}
            errors={addressErrors}
            fullAddressesOnly
          />
        )}
        {listing?.portfolioAddresses?.length > 0 && (
          <div>
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey900}
            >
              {`Portfolio listing addresses (${listing?.portfolioAddresses?.length || 0})`}
            </Text>
            {listing?.portfolioAddresses.map((ad, index) => (
              <Flex
                key={index}
                justify="space-between"
                align="center"
                margin="12px 0 0"
              >
                <Text
                  type={TextTypesEnum.Regular16}
                  color={Colors.Grey900}
                >
                  {AddressUtil.formatAddress(ad)}
                </Text>
                <Icon
                  icon={Icons.TimesRegular}
                  color={Colors.Brand700 || Colors.Blurple700}
                  size={18}
                  onClick={() => {
                    // remove the address from the list
                    const arr = [...listing?.portfolioAddresses];
                    arr.splice(index, 1);

                    // change the value of the 'address' field on the listing
                    // if it is deleted in the portfolio address
                    if (index === 0 && arr?.length > 0) {
                      setListingField({ address: arr[0] });
                    } else if (index === 0 && arr.length <= 0) {
                      setListingField({ address: addressState() });
                    }

                    // set the new array of addresses on the listing
                    setListingField({
                      portfolioAddresses: arr,
                    });
                  }}
                />
              </Flex>
            ))}
          </div>
        )}
        <Input
          label={listing?.isPortfolioListing ? 'Portfolio name' : 'Property name'}
          data-cy="property-name-input"
          placeholder={listing?.isPortfolioListing ? 'Enter portfolio name' : 'Enter property name'}
          value={listing.name?.toString() ?? ''}
          onChange={(event) => setListingField({ name: event.currentTarget.value })}
          inputType={InputTypesEnum.Text}
          charLimit={100}
        />
        {!hideStatus && (
          <Flex direction="column">
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey900}
              margin="0px 0px 8px 0px"
            >
              Listing status
            </Text>
            <ListingStatus
              listing={listing}
              changeable
              margin="0px 0px 8px 0px"
            />
            <LinkText
              type={TextTypesEnum.Medium12}
              onClick={() => pushChangeListingStatusModal()}
            >
              Edit status
            </LinkText>
          </Flex>
        )}
        <CreateListingPhotos dataCy="listing-photo-dropbox" listing={listing} error={photoError} />
        <CreateListingVideo dataCy="listing-video-dropbox" listing={listing} />
      </Grid>
    </ContentCard>
  );
};

export default CreateListingBasicInformation;
