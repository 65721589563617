import gql from 'graphql-tag';

const LISTING_USER_MAP_ANALYTICS = gql`
    query ListingUserMapAnalytics($params: ListingUserMapAnalyticsParams!) {
        listingUserMapAnalytics(params: $params) {
            location
            user {
                firstName
                lastName
                email
                profileImageFile {
                    url
                }
            }
        }
    }
`;

export default LISTING_USER_MAP_ANALYTICS;
