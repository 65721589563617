import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import IOfferQuery from '@biproxi/models/interfaces/IOfferQuery';
import OfferStateEnum from '@biproxi/models/enums/OfferStateEnum';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { KB_OFFERS_URL } from '@biproxi/models/externalLinks';
import LIST_OFFERS from '../graphql/queries/offers.query';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import NoContent from '../components/NoContent';
import { Icons } from '../elements/Icon';
import Loader, { LoaderSizes } from '../elements/Loader';
import Input, { InputSizeEnum, InputTypesEnum } from '../elements/Input';
import Flex from '../elements/Flex';
import OfferCard from '../components/OfferCard';
import useListing from '../hooks/useListing.hook';
import IButtonOptions from '../models/interfaces/IButtonOptions';
import ButtonDropdown from '../elements/ButtonDropdown';
import LinkText from '../elements/LinkText';
import Link from '../elements/Link';

const Container = styled.div``;

const LoaderContainer = styled.div`
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonDropdownContainer = styled.div`
  min-width: 138px;
  margin-left: 8px;
`;

type ListingDetailsOffersProps = {};

enum OffersPageStateEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

const ListingDetailsOffers: React.FC<ListingDetailsOffersProps> = () => {
  /** State */
  const [pageState, setPageState] = React.useState(OffersPageStateEnum.Active);
  const [searchQuery, setSearchQuery] = React.useState('');

  /* Hooks */
  const { listing } = useListing();

  /** GraphQL */
  interface Data {
    offers: IOfferGraphQL[];
  }

  interface Vars {
    params: IOfferQuery;
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_OFFERS, {
    variables: {
      params: {
        queryType: OfferQueryTypesEnum.Received,
        listingId: listing?._id,
        isActive: pageState === OffersPageStateEnum.Active,
      },
    },
    onError: (error) => {
      logger.error('ListingDetailsOffers error', error);
    },
  });

  /** Render */
  const offerFilterItems: IButtonOptions[] = [
    {
      text: 'Active offers',
      onClick: () => setPageState(OffersPageStateEnum.Active),
      active: pageState === OffersPageStateEnum.Active,
    },
    {
      text: 'Inactive offers',
      onClick: () => setPageState(OffersPageStateEnum.Inactive),
      active: pageState === OffersPageStateEnum.Inactive,
    },
  ];
  const activeFilterItem = offerFilterItems.find((i) => i.active);
  const newOffers: IOfferGraphQL[] = [];
  const existingOffers: IOfferGraphQL[] = [];
  const rejectedOffers: IOfferGraphQL[] = [];
  const withdrawnOffers: IOfferGraphQL[] = [];

  const o = data?.offers?.length > 0 ? data?.offers : [];
  let filteredOffers = [...o];
  if (searchQuery) {
    filteredOffers = filteredOffers.filter((offer) => Boolean(JSON.stringify(offer).toLowerCase().includes(searchQuery.toLowerCase())));
  }

  /** Probably a cleaner solution than this */
  filteredOffers.forEach((offer) => {
    if (offer.state === OfferStateEnum.New) {
      newOffers.push(offer);
    }
    if (offer.state === OfferStateEnum.Active) {
      existingOffers.push(offer);
    }
    if (offer.state === OfferStateEnum.Rejected) {
      rejectedOffers.push(offer);
    }
    if (offer.state === OfferStateEnum.Withdrawn) {
      withdrawnOffers.push(offer);
    }
  });

  const offersMap: Record<string, IOfferGraphQL[]> = {};

  if (newOffers.length > 0) {
    offersMap.new = newOffers;
  }
  if (existingOffers.length > 0) {
    offersMap.existing = existingOffers;
  }
  if (rejectedOffers.length > 0) {
    offersMap.rejected = rejectedOffers;
  }
  if (withdrawnOffers.length > 0) {
    offersMap.withdrawn = withdrawnOffers;
  }

  const offerArrayList = Object.entries(offersMap);

  return (
    <Container data-cy="offers-list-container">
      {loading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <>
          <Flex margin="0px 0px 8px 0px" justify="space-between">
            <Input
              placeholder="Search offers"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              clear={() => setSearchQuery('')}
              inputType={InputTypesEnum.Search}
              inputSize={InputSizeEnum.Small}
              width="100%"
            />
            <ButtonDropdownContainer>
              <ButtonDropdown
                items={offerFilterItems}
                activeItem={activeFilterItem}
              />
            </ButtonDropdownContainer>
          </Flex>
          {(data?.offers?.length > 0) ? (
            <>
              {offerArrayList?.length > 0 ? (
                <>
                  {offerArrayList.map(([key, value]: [string, IOfferGraphQL[]]) => (
                    <>
                      <Text type={TextTypesEnum.Regular16} color={Colors.Grey300} margin="16px 0px 16px">
                        {StringUtil.capitalize(key)}
                        {' '}
                        offers
                      </Text>
                      {value.map((offer: IOfferGraphQL) => (
                        <OfferCard
                          key={offer._id}
                          offer={offer}
                        />
                      ))}
                    </>
                  ))}
                </>
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.SearchRegular}
                  text="No search results. Please try different search terms."
                />
              )}
            </>
          ) : (
          // TODO: Update Icon
            <NoContent
              height="176px"
              icon={Icons.EnvelopeOpenDollarLight}
              text={(
                <>
                  No offers yet. Learn more about offers&nbsp;
                  <Link href={KB_OFFERS_URL} isExternal target="_blank">
                    <LinkText type={TextTypesEnum.Bold14}>here</LinkText>
                  </Link>
                  .
                </>
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ListingDetailsOffers;
