import React from 'react';
import styled from '@emotion/styled';
import useOnclickOutside from 'react-cool-onclickoutside';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from './Button';
import DropdownList, { TDropdownItemProps } from '../components/DropdownList';
import IButtonOptions from '../models/interfaces/IButtonOptions';
import Icons from './Icons';
import Colors, { ColorsType } from '../styles/Colors';
import { IconsType } from './Icon';

const Container = styled.div`
  position: relative;
`;

type ButtonDropdownStylesType = {
  padding: string;
}

type ButtonDropdownProps = {
  items: IButtonOptions[];
  ItemComponent?: React.ForwardRefExoticComponent<TDropdownItemProps & React.RefAttributes<HTMLDivElement>>;
  activeItem: any;
  buttonType?: ButtonTypesEnum;
  buttonSize?: ButtonSizesEnum;
  rightIcon?: IconsType;
  iconColor?: ColorsType;
  styles?: ButtonDropdownStylesType;
};

const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  items: options,
  ItemComponent,
  activeItem = options[0],
  buttonType = ButtonTypesEnum.Outline,
  buttonSize = ButtonSizesEnum.Medium,
  rightIcon = Icons.AngleDownRegular,
  iconColor = Colors.Grey900,
  styles = {
    padding: '0px',
  },
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const listRef = React.createRef<HTMLInputElement>();

  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  const items = options.map((i) => ({
    name: i.text,
    value: i.onClick,
  }));

  return (
    <Container ref={ref}>
      <Button
        ref={listRef}
        icon={activeItem.icon}
        rightIcon={rightIcon}
        iconColor={iconColor}
        text={activeItem.text}
        onClick={() => {
          setOpen(!open);
        }}
        type={buttonType}
        size={buttonSize}
        padding={styles.padding}
        isFullWidth
      />
      <DropdownList
        ref={listRef}
        value={activeItem.text} // Not sure the purpose of this required field.
        open={open}
        setOpen={setOpen}
        onChange={(onClick) => onClick()}
        items={items}
        ItemComponent={ItemComponent}
      />
    </Container>
  );
};

export default ButtonDropdown;
