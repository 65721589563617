import React from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { KB_CREATE_LISTING_DD_URL, CONFIDENTIALITY_URL } from '@biproxi/models/externalLinks';
import Link from '../../elements/Link';
import GenericCard from '../../elements/GenericCard';
import Colors from '../../styles/Colors';
import { media } from '../../utils/MediaQuery';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { useAppDispatch } from '../../redux/store';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import { AppActions } from '../../redux/app.redux';
import { ListingActions } from '../../redux/listing.redux';
import CreateListingVaultFile from './CreateListingVaultFile';
import LinkText from '../../elements/LinkText';

const UploadCAContainer = styled.div`
  width: 100%;
`;

const UploadCAHeader = styled.div`
  display: inline-block;
  border-bottom: 2px dashed ${Colors.Grey400} !important;
  margin-bottom: 4px;

  ${media.mobile} {
    margin: 0px 0px 0px 16px;
  }
`;

const Dashed = styled.div`
  border: 1px dashed ${Colors.Grey300};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

type CreateListingCAUploadProps = {
  listing: IListingGraphQL
};

const CreateListingCAUpload: React.FC<CreateListingCAUploadProps> = ({ listing }) => {
  /** Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    /** If dragged file not pdf, simply return */
    if (!browserFiles.length) return;
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      /**
         * For files without a mimetype, it will
         * come in as an empty string. Instead of this,
         * use 'application/octet-stream' as it is
         * the most general mimetype
         *
         * https://stackoverflow.com/questions/12539058/is-there-a-default-mime-type/12560996
         */
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadCAFile({
      variables: {
        params: fileParams,
      },
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'application/pdf',
  });

  /** GraphQL */
  const [uploadCAFile, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      addListingVaultCAFile(data.createFiles[0]);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error uploading your file. Please try again or contact support.',
      });
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  const addListingVaultCAFile = (file: IFile) => dispatch(
    ListingActions.addListingVaultCAFile({
      file,
    }),
  );

  /** Render */
  return (
    <UploadCAContainer>
      <GenericCard
        margin="0 0 16px"
        padding="16px"
        borderRadius="4px"
      >
        <Link href={KB_CREATE_LISTING_DD_URL} target="_blank">
          <UploadCAHeader>
            <Flex align="center">
              <Text
                type={TextTypesEnum.Bold14}
                color={Colors.Black}
                data-tip="Click here to learn more"
              >
                Confidentiality Agreement
              </Text>
              <Icon
                icon={Icons.InfoSquareSolid}
                size={14}
                color={Colors.Grey400}
                margin="0px 0px 0px 8px"
              />
            </Flex>
          </UploadCAHeader>
        </Link>
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey700} margin="8px 16px 16px 0px">
          All investors must provide contact information and sign an electronic confidentiality agreement before gaining access to diligence documents.
          If you do not upload a confidentiality agreement, Biproxi&rsquo;s default&nbsp;
          <Link
            href={CONFIDENTIALITY_URL}
            target="_blank"
            isExternal
          >
            <LinkText type={TextTypesEnum.Regular14}>confidentiality agreement</LinkText>
          </Link>
          &nbsp;will be used.
        </Text>
        {(() => {
          if (!loading && !listing?.vault?.caFile) {
            return (
              <Dashed {...getRootProps()}>
                <input {...getInputProps()} />
                <Flex align="center">
                  <Icon icon={Icons.FileCertificateLight} color={Colors.Grey500} size={18} margin="0 8px 0 0" />
                  <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                    Drag files here or
                    &nbsp;
                    <Text
                      type={TextTypesEnum.Medium14}
                      as={TextAsEnum.Span}
                      color={Colors.Brand700 || Colors.Blurple700}
                    >
                      click to upload
                    </Text>
                  </Text>
                </Flex>
              </Dashed>
            );
          }

          return (
            <CreateListingVaultFile
              key={listing.vault.caFileId}
              listing={listing}
              file={listing.vault?.caFile}
            />
          );
        })()}
      </GenericCard>
    </UploadCAContainer>
  );
};

export default CreateListingCAUpload;
