import styled from '@emotion/styled';
import React from 'react';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { useLazyQuery, useMutation } from '@apollo/client';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import BoxShadows from '../../styles/BoxShadows';
import GenericCard from '../../elements/GenericCard';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Input, { InputTypesEnum } from '../../elements/Input';
import PLACE_PRIVATE_EVENT_BID from '../../graphql/mutations/placePrivateEventBid.mutation';
import { AppActions } from '../../redux/app.redux';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import ColoredTag, { ColoredTagTypesEnum } from '../../elements/ColoredTag';
import useUser from '../../hooks/useUser.hook';
import { useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import InlineAlert, { InlineAlertTypesEnum } from '../InlineAlert';
import Flex from '../../elements/Flex';
import useNavigateToPrivateEventManagement from '../../hooks/useNavigateToPrivateEventManagement.hook';
import GET_LEAD from '../../graphql/queries/lead.query';
import { ChatActions } from '../../redux/chat.redux';
import PrivateEventQuickDisplayInfo from './PrivateEvenQuickDisplayInfo';
import usePrivateEventCountdown from '../../hooks/usePrivateEventCountdown.hook';

const Container = styled.div``;

const TotalBidRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

type PrivateEventBiddingCardProps = {
  privateEvent: IPrivateEventGraphQL;
  refetchPrivateEvent: () => void;
};

const PrivateEventBiddingCard: React.FC<PrivateEventBiddingCardProps> = ({
  privateEvent,
  refetchPrivateEvent,
}) => {
  /** State */
  const [bid, setBid] = React.useState(privateEvent?.settings?.bidIncrement?.toString());
  const [confirmBidView, setConfirmBidView] = React.useState(false);
  const [brokerChannel, setBrokerChannel] = React.useState(null);

  /** Hooks */
  const { userId } = useUser();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const navigateToPrivateEventManagement = useNavigateToPrivateEventManagement();
  const {
    time, blinkText, isLessThanTwoMinutesLeft, curEndsAt,
  } = usePrivateEventCountdown(privateEvent, refetchPrivateEvent);

  /* Actions */
  const dispatch = useAppDispatch();
  const { channelsOpenedUrls } = useAppSelector((state: AppState) => state.chat);
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushActiveChatChannel = (channel) => dispatch(
    ChatActions.pushActiveChatChannels({ channel }),
  );

  const pushChannelOpenedUrl = (channelUrl) => dispatch(
    ChatActions.pushChannelsOpenedUrls({ channelUrl }),
  );

  /** GraphQL */
  const [placePrivateEventBid, { loading }] = useMutation(PLACE_PRIVATE_EVENT_BID, {
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Bid placed successfully',
      });
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
    variables: {
      params: {
        privateEventId: privateEvent._id,
        // Opening bid does not have an increment - so it is 0
        totalBidAmount: PrivateEventUtil.getTotalBids(privateEvent) > 0 ? PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount + parseInt(bid, 10) : Number(privateEvent?.settings?.startingBid),
        bidIncrement: PrivateEventUtil.getTotalBids(privateEvent) > 0 ? parseInt(bid, 10) : 0,
      },
    },
  });

  type Data = {
    lead: ILeadGraphQL;
  }

  type Vars = ILeadService.TGetLeadPayload;

  /**
   * Chat stuff needs to be refactored so it can accept a leadId and do the loading
   * and querying for the lead in the chat modal itself and not here.
   * This shit is janky.
   */
  const [getLead, { loading: leadLoading }] = useLazyQuery<Data, Vars>(GET_LEAD, {
    onCompleted: async (data) => {
      setBrokerChannel(data?.lead?.channel);
      if (!channelsOpenedUrls.includes(data?.lead?.channel?.channelUrl)) {
        pushChannelOpenedUrl(data?.lead?.channel?.channelUrl);
      }
      if (data?.lead?.channel) {
        pushActiveChatChannel(data?.lead?.channel);
      }
    },
    onError: async () => {
      // Error probably isn't related to messages but for now, with doing this
      // this way, it is more clear to the user to say that.
      // const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error loading your messages.',
      });
    },
  });

  /** Render */
  const winning = PrivateEventUtil.isUserTopBidder(userId, privateEvent);
  const placedBid = PrivateEventUtil.hasUserPlacedBid(userId, privateEvent);
  const isDisabled = PrivateEventUtil.getParticipantFromUserId(userId, privateEvent)?.disabled;
  const isBroker = userId === privateEvent?.userId;

  const handleBrokerChat = () => {
    if (brokerChannel?.channelUrl) {
      if (!channelsOpenedUrls.includes(brokerChannel.channelUrl)) {
        pushChannelOpenedUrl(brokerChannel.channelUrl);
      }
      pushActiveChatChannel(brokerChannel);
    } else {
      getLead({
        variables: {
          params: {
            listingId: privateEvent.listingId,
          },
        },
      });
    }
  };

  return (
    <Container>
      <GenericCard
        width={(isMobile || isTablet) ? '100%' : '320px'}
        margin={(isMobile || isTablet) ? '24px 0 0' : '0 0 0 24px'}
        boxShadow={BoxShadows.Standard}
        borderRadius="8px"
        padding="24px"
        height="fit-content"
      >
        {(() => {
          // Don't move this code into closures. Even if they are
          // memoized it breaks the inputs focus on rerender for the countdown.
          switch (privateEvent.state) {
            case PrivateEventStatesEnum.Upcoming:
              return (
                <>
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    Start date
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                    {privateEvent?.startsAt ? TimeUtil.format(privateEvent?.startsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                  </Text>
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    End date
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                    {privateEvent?.endsAt ? TimeUtil.format(privateEvent?.endsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
                  </Text>
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    Starting bid
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                    {privateEvent?.settings?.startingBid ? `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}` : '-'}
                  </Text>
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    Min. bid increments
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 16px">
                    {privateEvent?.settings?.bidIncrement ? `$${StringUtil.formatNumber(privateEvent?.settings?.bidIncrement)}` : '-'}
                  </Text>
                  {isBroker ? (
                    <Button
                      text="Back to event dashboard"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      onClick={() => navigateToPrivateEventManagement(privateEvent?._id)}
                    />
                  ) : (
                    <Button
                      text="Message Broker"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      isLoading={leadLoading}
                      onClick={handleBrokerChat}
                    />
                  )}
                </>
              );
            case PrivateEventStatesEnum.InProgress:
              return (
                <>
                  {isDisabled && (
                    <InlineAlert
                      type={InlineAlertTypesEnum.RedWarning}
                      text="You are currently blocked from placing bids. Contact the broker if you feel this is an error."
                      margin="0 0 24px"
                    />
                  )}
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    {PrivateEventUtil.getTotalBids(privateEvent) > 0 ? 'Highest bid' : 'Opening bid'}
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 0">
                    {PrivateEventUtil.getTotalBids(privateEvent) > 0 ? `$${StringUtil.formatNumber(PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)}` : `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}`}
                  </Text>
                  {/* kinda gross, sorry */}
                  {isBroker ? (
                    <ColoredTag
                      type={ColoredTagTypesEnum.Blurple}
                      text="This is your event"
                      margin="0 0 16px"
                    />
                  ) : (
                    <>
                      {isDisabled ? (
                        <ColoredTag
                          type={ColoredTagTypesEnum.Red}
                          text="Blocked from bidding"
                          margin="0 0 16px"
                        />
                      ) : (
                        <ColoredTag
                          type={placedBid ? (winning ? ColoredTagTypesEnum.Green : ColoredTagTypesEnum.Red) : (ColoredTagTypesEnum.Yellow)}
                          text={placedBid ? (winning ? 'You\'re winning!' : 'You\'ve been outbid') : 'You haven\'t placed a bid yet'}
                          margin="0 0 16px"
                        />
                      )}
                    </>
                  )}
                  {/* Prevent edge case negative number flashes */}
                  {curEndsAt >= time && (
                    <>
                      <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                        Time Remaining
                      </Text>
                      <Text
                        color={isLessThanTwoMinutesLeft
                          ? blinkText
                            ? Colors.White
                            : Colors.Red600
                          : Colors.Black}
                        type={TextTypesEnum.Bold18}
                        margin="0 0 16px"
                      >
                        {TimeUtil.formattedCountDown(time, curEndsAt)}
                      </Text>
                    </>
                  )}
                  {isBroker ? (
                    <Button
                      text="Back to event dashboard"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      margin="16px 0 8px"
                      isFullWidth
                      onClick={() => navigateToPrivateEventManagement(privateEvent?._id)}
                    />
                  ) : (
                    <>
                      {PrivateEventUtil.getTotalBids(privateEvent) > 0 ? (
                        <>
                          <Input
                            label={`Bid increment (Min. $${StringUtil.formatNumber(privateEvent?.settings?.bidIncrement)})`}
                            placeholder="Enter a bid increment"
                            value={bid}
                            onChange={(_e, unmasked) => setBid(unmasked)}
                            inputType={InputTypesEnum.Currency}
                            disabled={confirmBidView}
                          />
                          <TotalBidRow>
                            <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
                              New bid
                            </Text>
                            <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
                              {/*
                                * Something weird with the typing of numbers on private events...
                                * We need to use Number() in some case but parseInt() in others for some reason
                                * TODO: revisit.
                                */}
                              {PrivateEventUtil.getTotalBids(privateEvent) > 0
                                ? `$${StringUtil.formatNumber((parseInt(bid, 10) + (PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)) || PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)}`
                                : `$${StringUtil.formatNumber((parseInt(bid, 10) + Number(privateEvent?.settings?.startingBid)) || Number(privateEvent?.settings?.startingBid))}`}
                            </Text>
                          </TotalBidRow>
                        </>
                      ) : (
                        <div />
                      )}
                      {confirmBidView && (
                        <>
                          <Flex margin="16px 0 0" align="center">
                            <Text color={Colors.Grey900} type={TextTypesEnum.Regular14Small}>
                              You are placing a bid of &nbsp;
                            </Text>
                            {' '}
                            <Text color={Colors.Black} type={TextTypesEnum.Bold14Small}>
                              {PrivateEventUtil.getTotalBids(privateEvent) > 0
                                ? `$${StringUtil.formatNumber((parseInt(bid, 10) + (PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)) || PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)}`
                                : `$${StringUtil.formatNumber(Number(privateEvent?.settings?.startingBid))}`}
                            </Text>
                          </Flex>
                          <Text color={Colors.Black} type={TextTypesEnum.Bold14Small} margin="8px 0px">
                            Do you wish to continue?
                          </Text>
                        </>
                      )}
                      <Button
                        text={confirmBidView ? 'Continue' : PrivateEventUtil.getTotalBids(privateEvent) > 0 ? 'Place Bid' : 'Place Opening Bid'}
                        type={ButtonTypesEnum.Primary}
                        size={ButtonSizesEnum.Large}
                        isFullWidth
                        onClick={(confirmBidView ? () => {
                          placePrivateEventBid();
                          setConfirmBidView(false);
                        } : () => {
                          if (!loading) {
                            setConfirmBidView(true);
                          }
                        })}
                        margin="16px 0 8px"
                        disabled={!bid || isDisabled || (parseInt(bid, 10) < privateEvent?.settings?.bidIncrement)}
                        isLoading={loading}
                      />
                      <Button
                        text={confirmBidView ? 'Cancel' : 'Message broker'}
                        type={ButtonTypesEnum.Outline}
                        loaderColor={Colors.Brand700 || Colors.Blurple700}
                        size={ButtonSizesEnum.Large}
                        isFullWidth
                        isLoading={leadLoading}
                        onClick={(confirmBidView
                          ? () => setConfirmBidView(false)
                          : () => handleBrokerChat())}
                      />
                    </>
                  )}
                </>
              );
            case PrivateEventStatesEnum.Canceled:
              return (
                <>
                  <InlineAlert
                    type={InlineAlertTypesEnum.GreyNotice}
                    text="The private event has been canceled."
                    margin="0 0 24px"
                  />
                  {isBroker ? (
                    <Button
                      text="Back to event dashboard"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      onClick={() => navigateToPrivateEventManagement(privateEvent?._id)}
                    />
                  ) : (
                    <Button
                      text="Message Broker"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      isLoading={leadLoading}
                      onClick={handleBrokerChat}
                    />
                  )}
                </>
              );
            case PrivateEventStatesEnum.Finished:
              return (
                <>
                  <InlineAlert
                    type={InlineAlertTypesEnum.BlurpleNotice}
                    text="The private event has ended."
                    margin="0 0 24px"
                  />
                  <Text color={Colors.Grey700} type={TextTypesEnum.Regular16}>
                    Highest bid
                  </Text>
                  <Text color={Colors.Black} type={TextTypesEnum.Bold18} margin="0 0 0">
                    {PrivateEventUtil.getTotalBids(privateEvent) > 0 ? `$${StringUtil.formatNumber(PrivateEventUtil.getTopBid(privateEvent)?.totalBidAmount)}` : `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}`}
                  </Text>
                  {isBroker ? (
                    <Button
                      text="Back to event dashboard"
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      margin="16px 0 0"
                      onClick={() => navigateToPrivateEventManagement(privateEvent?._id)}
                    />
                  ) : (
                    <>
                      <ColoredTag
                        type={winning ? ColoredTagTypesEnum.Green : ColoredTagTypesEnum.Red}
                        text={winning ? 'You won the private event!' : 'You lost the private event'}
                        margin="0 0 16px"
                      />
                      <Button
                        text="Message Broker"
                        type={ButtonTypesEnum.Primary}
                        size={ButtonSizesEnum.Large}
                        isFullWidth
                        isLoading={leadLoading}
                        onClick={handleBrokerChat}
                      />
                    </>
                  )}
                </>
              );
            default:
              return null;
          }
        })()}
        <PrivateEventQuickDisplayInfo privateEvent={privateEvent} />
      </GenericCard>
    </Container>
  );
};

export default PrivateEventBiddingCard;
