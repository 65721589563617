enum PropertyFilterSectionsEnum {
    PropertyType = 'propertyType',
    LotSize = 'lotSize',
    BuildingDetails = 'buildingDetails',
    Owner = 'owner',
    Transactions = 'transactions',
    Mortgages = 'mortgages',
    Taxes = 'taxes',
    Demographics = 'demographics',
    Address = 'address',
    MSA = 'msaName',
    Zip = 'zipCode',
    CityState = 'cityState'
}

export default PropertyFilterSectionsEnum;
