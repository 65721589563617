import React from 'react';
import styled from '@emotion/styled';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import AssetClassPropertyTypes from '@biproxi/models/objects/AssetClassPropertyTypes.object';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import TListingInfoField from '@biproxi/models/types/TListingInfoField';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import { useQuery } from '@apollo/client';
import { KB_CREATE_LISTING_DETAILS_URL } from '@biproxi/models/externalLinks';
import CHERRE_INFO_FIELDS from '../../graphql/queries/listingCherreInfoFields';
import ContentCard, { ContentCardHeaderTypesEnum } from '../../elements/ContentCard';
import Dropdown from '../../elements/Dropdown';
import Text, { TextTypesEnum } from '../../elements/Text';
import CreateListingInfoFieldInput from './CreateListingInfoFieldInput';
import Colors from '../../styles/Colors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import InlineAlert, { InlineAlertTypesEnum, InlineAlertStylesEnum } from '../InlineAlert';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Flex from '../../elements/Flex';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 24px;

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Cell = styled.div<{wide?: boolean, full?: boolean}>`
  grid-column: ${(props) => (props.full ? '1 / 3' : null)};
`;

const Spacer = styled.div`
  height: 32px;
`;

const Placeholder = styled.div`
  height: 176px;
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type CreateListingPropertyDetailsProps = {
  id?: string;
  listing: IListingGraphQL;
};

const CreateListingPropertyDetails: React.FC<CreateListingPropertyDetailsProps> = ({
  id,
  listing,
}) => {
  /* State */
  const errors = useAppSelector(ListingSelectors.errors);
  const assetClassError = errors['/assetClass'];
  const propertyTypeError = errors['/propertyType'];
  const [autoCompleteDone, setAutoCompleteDone] = React.useState<boolean>(false);

  /** Hooks */
  const isMobile = useMobileMedia();

  /* Actions */
  const dispatch = useAppDispatch();

  const setListingAssetClass = (assetClass: ListingAssetClassEnum) => dispatch(
    ListingActions.setListingAssetClass({ assetClass }),
  );

  const setListingPropertyType = (propertyType: ListingPropertyTypeEnum) => dispatch(
    ListingActions.setListingPropertyType({ propertyType }),
  );

  const setListingInfoFields = (info: TListingInfoField[]) => dispatch(
    ListingActions.setListingInfoFields({ info }),
  );

  const setListingInfoField = (info: TListingInfoField) => dispatch(
    ListingActions.setListingInfoField({ info }),
  );

  /* GraphQL */
  type Data = {
    cherreInfoFieldsFromTaxAssessorId: TListingInfoField[];
  }

  type Vars = {
    params: {
      cherreTaxAssessorId: number;
    }
  }

  const { data: { cherreInfoFieldsFromTaxAssessorId: mappedCherreFields } = {}, loading } = useQuery<Data, Vars>(CHERRE_INFO_FIELDS, {
    skip: !listing?.address?.cherreId,
    variables: { params: { cherreTaxAssessorId: Number(listing?.address?.cherreId) } },
  });

  /* Callbacks */
  const autoCompleteFields = (mappedCherreFields: TListingInfoField[]) => {
    setListingInfoFields(mappedCherreFields);
    setAutoCompleteDone(true);
  };

  /** Effects */
  React.useEffect(() => {
    if (!listing?.address) {
      setListingAssetClass(null);
    }
  }, [listing?.address]);

  React.useEffect(() => {
    setAutoCompleteDone(false);
  }, [listing?.assetClass]);

  /* Render */
  const specialCaseFields = [
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome,
    ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage,
  ];

  const listingInfoFields = listing.info.filter((infoField) => !specialCaseFields.includes(infoField.fieldName));
  const specialCasedListingInfoFields = listing.info.filter((infoField) => specialCaseFields.includes(infoField.fieldName));
  return (
    <ContentCard
      id={id}
      dataCy="property-details-container"
      title="Property Details"
      subtitle="Select Property Type and Subtype for dynamic fields specific to the property type. This section will help investors find your listing through search filters."
      learnMoreLink={KB_CREATE_LISTING_DETAILS_URL}
      headerType={ContentCardHeaderTypesEnum.RichHeader}
    >
      <Grid>
        <Cell>
          <Dropdown
            cyInput="asset-class-dropdown"
            cyList="asset-class-list"
            label="Asset class"
            value={listing.assetClass}
            onChange={(assetClass: ListingAssetClassEnum) => {
              setListingAssetClass(assetClass);
            }}
            placeholder="Asset Class"
            items={Object.values(ListingAssetClassEnum).map((value) => ({
              name: value,
              value,
            }))}
            error={assetClassError}
          />
        </Cell>
        <Cell>
          <Dropdown
            label="Subtype"
            cyInput="subtype-dropdown"
            cyList="subtype-list"
            value={listing.propertyType ?? ''}
            onChange={(propertyType: ListingPropertyTypeEnum) => setListingPropertyType(propertyType)}
            placeholder="Property Type"
            items={Object.values(AssetClassPropertyTypes[listing.assetClass] ?? {}).map((value) => ({
              name: value.toString(),
              value,
            }))}
            error={propertyTypeError}
          />
        </Cell>
        {(listing?.address?.cherreId && listing?.propertyType && !autoCompleteDone) && (
        <Cell full={!isMobile}>
          {loading ? (
            <Flex flex="1" align="center" justify="center" direction="column">
              <Loader size={LoaderSizes.Medium} color={Colors.Brand700 || Colors.Blurple700} />
            </Flex>
          ) : (
            <>
              {listingInfoFields?.length > 0 && (
              <InlineAlert
                type={InlineAlertTypesEnum.GreenNotice}
                styleOption={InlineAlertStylesEnum.WithButtons}
                dataCy="cherre-autofill-button"
                label="We found property data for your listing"
                text="Click the autocomplete button to attempt to auto-fill your data fields"
                buttonOne={{ text: 'Autocomplete', onClick: () => autoCompleteFields(mappedCherreFields) }}
              />
              )}
            </>
          )}
        </Cell>
        )}
        {(() => {
          if (!listing.propertyType) {
            return null;
          }
          return listingInfoFields.map((infoField: TListingInfoField, index) => (
            <Cell key={`${listing.propertyType}-${index}`}>
              <CreateListingInfoFieldInput
                autoFocus={false}
                listing={listing}
                fieldType={infoField.fieldName}
                value={ListingUtil.getField(listing.info, infoField.fieldName).value}
                onChange={(value) => setListingInfoField(
                  ListingUtil.field(infoField.fieldName as any, value), // TODO fix typing to TListingFieldTypes
                )}
              />
            </Cell>
          ));
        })()}
      </Grid>
      <Spacer />
      {(() => {
        if (!listing.propertyType) {
          return null;
        }
        return specialCasedListingInfoFields.map((infoField: TListingInfoField, index) => (
          <Cell key={`${listing.propertyType}-${index}`}>
            <CreateListingInfoFieldInput
              autoFocus={false}
              listing={listing}
              key={`${listing.propertyType}-${index}`}
              fieldType={infoField.fieldName}
              value={ListingUtil.getField(listing.info, infoField.fieldName).value}
              onChange={(value) => setListingInfoField(
                ListingUtil.field(infoField.fieldName as any, value), // TODO fix typing to TListingFieldTypes
              )}
            />
          </Cell>
        ));
      })()}
      {specialCasedListingInfoFields.length > 0 && (
        <Spacer />
      )}
      {!listing.propertyType && (
        <Placeholder>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey500} align="center">Select an asset class and subtype to populate fields</Text>
        </Placeholder>
      )}
    </ContentCard>
  );
};

export default CreateListingPropertyDetails;
