import { gql } from '@apollo/client';
import PrivateEventFragments from '../fragments/privateEvent.fragments';

const SEND_PRIVATE_EVENT_INVITE = gql`
  mutation SendPrivateEventInvite($params: SendPrivateEventInviteParams!) {
    sendPrivateEventInvite(params: $params) { 
      ...PrivateEventFields
      ...PrivateEventBids
      ...PrivateEventSettings
      ...PrivateEventContingencies
      ...PrivateEventMeta
    }
  }

  ${PrivateEventFragments.fields}
  ${PrivateEventFragments.bids}
  ${PrivateEventFragments.settings}
  ${PrivateEventFragments.contingencies}
  ${PrivateEventFragments.meta}
`;

export default SEND_PRIVATE_EVENT_INVITE;
