import React from 'react';
import styled from '@emotion/styled';
import PayWallMessagesEnum from '@biproxi/models/enums/PaywallMessagesEnum';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import Flex from '../elements/Flex';
import useChargebee from '../hooks/useChargebee.hook';
import Link from '../elements/Link';

const Container = styled.div<{ 'data-cy': string }>`
  width: 100%;
  height: 100%;
`;

const ChildrenContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const PaywallOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const SignUpContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const ChildBlur = styled.div`
  filter: blur(4px);
  height: 100%;
  width: 100%;
`;

type PaywallProps = {
  children?: React.ReactNode;
  message?: PayWallMessagesEnum;
  disabled?: boolean;
  learnMoreLink?: string;
};

const Paywall: React.FC<PaywallProps> = ({
  children, message, disabled = false, learnMoreLink = 'https://www.biproxi.com/pricing?',
}) => {
  /** State */
  const paidPlan = BillingPlanIdEnum.BrokerProMonthly;

  /** Hooks */
  const { proceedToCheckout } = useChargebee();

  if (disabled) {
    return (
      <>
        {children}
      </>
    );
  }

  /** Render */
  return (
    <Container data-cy="billing-paywall-container">
      <ChildrenContainer>
        <PaywallOverlay>
          <SignUpContainer>
            <Flex gap="4px">
              <Icon
                icon={Icons.LockAltSolid}
                color={Colors.Red500}
                margin="0 4px 0 0"
              />
              <Text
                type={TextTypesEnum.Medium14}
                color={Colors.Red500}
              >
                {message || 'This feature is available with a paid plan.'}
              </Text>
            </Flex>
            {/* This is a link for now, but it can be any marketing material / argument that user really needs to give us money */}
            <Link
              href={learnMoreLink}
              target="_blank"
              dataCy="paywall-link"
            >
              Learn more
            </Link>
            <Button
              text="Upgrade now!"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              onClick={() => proceedToCheckout(paidPlan)}
              data-cy="paywall-checkout-button"
            />
          </SignUpContainer>
        </PaywallOverlay>
        <ChildBlur>
          {children}
        </ChildBlur>
      </ChildrenContainer>
    </Container>
  );
};

export default Paywall;
