import React from 'react';
import styled from '@emotion/styled';
import { useMutation as rqMutation } from 'react-query';
import { removeListingFromOrganization } from '@biproxi/next-api-requests';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { logger } from '@biproxi/logger';
import { TeamPermissionsEnum } from '@biproxi/models/enums/PermissionsEnum';
import { useNavigate } from 'react-router-dom';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import Flex from '../../elements/Flex';
// import getOrganizationUsers from '../../../next-api/queries/neo4j/getOrganizationUsers.query';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import ListingCard from '../ListingCard.team';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import { ListingActions } from '../../redux/listing.redux';

const TeamListingsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const TeamListingsHeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${Colors.Grey100};
  padding: 8px 16px;
  border-radius: 4px;
`;

const ListingActionsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const MobileTeamContainer = styled.div`
  ${media.mobile} {
    width: 100vw;
    padding: 8px 8px;
    margin: 0 auto;
    overflow-x: hidden;
    white-space: nowrap;
  }
`;

type TeamPageListingsTableProps = {
  team: INeo4jServiceAPI.INeo4jOrganization;
  listings: any;
  userPermissions: TeamPermissionsEnum[];
  refetch: () => void;
}

const TeamPageListingsTable: React.FC<TeamPageListingsTableProps> = ({
  team,
  listings,
  userPermissions,
  refetch,
}) => {
  /** State */
  const teamId = team?._id;
  /** React Query */
  const { mutate: handleRemoveListingFromOrganization } = rqMutation((params: INeo4jServiceAPI.IRemoveListingFromOrganizationParams) => removeListingFromOrganization(params), {
    onSuccess: () => handleRemoveListingFromOrganizationSuccess(),
    onError: (err) => handleRemoveListingFromOrganizationError(err),
  });

  /** Hooks */
  const isMobile = useMobileMedia();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const cacheListingId = (listingId: string) => dispatch(ListingActions.setListingId({ listingId }));

  const pushAddListingsToTeamModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AddListingsToTeam,
      props: {
        organizationId: teamId,
        teamListings: listings,
        refetchListings: refetch,
      },
    }),
  );

  const pushRemoveListingModal = ({ organizationId, listingId, listingName }) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Warning,
        title: `Remove ${listingName}?`,
        text: `Are you sure you want to remove ${listingName} from your team? If you remove the listing, it will no longer be available to your team. You can always add listings back if you change your mind later.`,
        buttonText: 'Remove listing',
        confirm: () => handleRemoveListingFromOrganization({ organizationId, listingId }),
      },
    }),
  );

  const pushListingRemovedConfirmationModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your listing has been removed',
        text: 'You can always add listings back if you change your mind later.',
        confirm: () => popModal(),
      },
    }),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** Functions */
  const handleRemoveListingFromOrganizationSuccess = () => {
    pushListingRemovedConfirmationModal();
    refetch();
  };

  const handleRemoveListingFromOrganizationError = (error) => {
    logger.warn(`There was an issue removing a listing from the team ${teamId}: ${error}`);
    pushToast({
      type: ToastTypesEnum.Error,
      message: 'There was an issue removing the listing. Please contact support or try again later.',
    });
  };

  /** Render */
  return (
    <>
      <TeamListingsHeader>
        {!isMobile ? (
          <Flex
            width="100%"
            justify="space-between"
          >
            <Flex
              direction="column"
            >
              <Text
                type={TextTypesEnum.Bold18}
              >
                Team listings
              </Text>
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey700}
              >
                View and manage team listings
              </Text>
            </Flex>
            <Button
              text="Add listing"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Pale}
              onClick={() => pushAddListingsToTeamModal()}
              data-cy="add-listings-button"
            />
          </Flex>
        ) : (
          <>
            <MobileTeamContainer>
              <Flex
                direction="column"
              >
                <Text
                  type={TextTypesEnum.Bold18}
                >
                  Team listings
                </Text>
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey700}
                >
                  View and manage team listings
                </Text>
              </Flex>
              <Button
                text="Add listing"
                size={ButtonSizesEnum.Small}
                type={ButtonTypesEnum.Pale}
                onClick={() => pushAddListingsToTeamModal()}
                customWidth="100vw"
                margin="16px 0 0 0"
              />
            </MobileTeamContainer>
          </>
        )}
      </TeamListingsHeader>
      {!isMobile && (
      <>
        <TeamListingsHeaderBar>
          <Text
            color={Colors.Grey900}
            type={TextTypesEnum.Regular12}
          >
            Listings
          </Text>
          <Text
            color={Colors.Grey900}
            type={TextTypesEnum.Regular12}
          >
            Actions
          </Text>
        </TeamListingsHeaderBar>
      </>
      )}
      {listings?.map((listing, index) => (
        <Flex
          justify="space-between"
          padding="0 14px"
          key={index}
        >
          <ListingCard
            // teamName={team?.name}
            listing={listing}
          />
          {userPermissions?.includes(TeamPermissionsEnum.RemoveListings) && (
            <ListingActionsContainer>
              <Icon
                icon={Icons.PenRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size={14}
                onClick={(event) => {
                  event.stopPropagation();
                  cacheListingId(listing?._id);
                  navigate(`/app/create-listing?listingId=${listing._id}`);
                }}
              />
              <Icon
                icon={Icons.TimesRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size={14}
                onClick={() => {
                  const mutationParams: INeo4jServiceAPI.IRemoveListingFromOrganizationParams = {
                    listingId: listing?._id,
                    organizationId: teamId,
                  };
                  pushRemoveListingModal({ ...mutationParams, listingName: listing?.name });
                }}
                data-cy="remove-listing"
              />
            </ListingActionsContainer>
          )}
        </Flex>
      ))}
    </>
  );
};

export default TeamPageListingsTable;
