import React from 'react';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import { NationalOrGlobalFocusEnum, RegionalFocusEnum } from '@biproxi/models/enums/InvestorProfileAnswersEnums';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import ProfileRegionalChecklist from './ProfileRegionalChecklist';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Checkbox, { CheckboxTypesEnum } from '../../elements/Checkbox';

type ProfileGeographicQuestionProps = {
  user: IUserGraphQL;
  setUserInvestorProfileField: (field: Partial<IInvestorProfile>) => void;
};

const ProfileGeographicQuestion: React.FC<ProfileGeographicQuestionProps> = ({
  user,
  setUserInvestorProfileField,
}) => {
  /** State */
  const { investorProfile } = user;
  const nationalFocus = Boolean(investorProfile.nationalOrGlobal === NationalOrGlobalFocusEnum.National) || investorProfile.geographicalFocus.length === 50;

  /** Actions */
  const addOrRemoveNationalPreference = () => setUserInvestorProfileField({
    nationalOrGlobal: nationalFocus ? null : NationalOrGlobalFocusEnum.National,
    geographicalFocus: nationalFocus ? [] : ListingUtil.returnAllStates(),
  });

  /* Render */
  return (
    <>
      <Text type={TextTypesEnum.Bold18} color={Colors.Black}>
        What geographic areas are you most interested in investing in?
      </Text>
      <Checkbox
        data-cy="national-geography"
        label="National"
        active={nationalFocus}
        onClick={() => addOrRemoveNationalPreference()}
        type={CheckboxTypesEnum.Large}
        labelTextType={TextTypesEnum.Medium16}
        margin="12px 0px 0px 0px"
      />
      {Object.values(RegionalFocusEnum).map((region) => (
        <ProfileRegionalChecklist
          data-cy="geography"
          key={region}
          user={user}
          region={region}
          setUserInvestorProfileField={setUserInvestorProfileField}
        />
      ))}
    </>
  );
};

export default ProfileGeographicQuestion;
