enum DataExplorerPropertyFiltersEnum {
    PropertyType = 'Property type',
    LotSize = 'Lot size',
    BuildingDetails = 'Building details',
    // Owner = 'Owner',
    Transactions = 'Transactions',
    // Mortgages = 'Mortgages', // commented out because we need Attom data :/
    Taxes = 'Taxes',
    // CensusDemographics = 'Census demographics',
  }

export default DataExplorerPropertyFiltersEnum;
