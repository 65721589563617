import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import PrivateEventsQueryTypeEnum from '@biproxi/models//enums/PrivateEventsQueryTypeEnum';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import * as PrivateEventJoi from '@biproxi/models/joi/privateEvent.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import { useNavigate } from 'react-router-dom';
import GenericCard from '../../elements/GenericCard';
import BoxShadows from '../../styles/BoxShadows';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  PrivateEventActions,
  PrivateEventSelectors,
} from '../../redux/privateEvent.redux';
import Divider from '../../elements/Divider';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import CREATE_PRIVATE_EVENT from '../../graphql/mutations/createPrivateEvent.mutation';
import Flex from '../../elements/Flex';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import useNavigateToPrivateEventManagement from '../../hooks/useNavigateToPrivateEventManagement.hook';
import LIST_PRIVATE_EVENTS from '../../graphql/queries/privateEvents.query';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import { useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import useListing from '../../hooks/useListing.hook';
import ListingLoaderTypesEnum from '../../models/enums/ListingLoaderTypesEnum';
import ZIndexes from '../../styles/ZIndexes';
import LinkText from '../../elements/LinkText';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const EmailContainer = styled.div`
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MobileFooter = styled.div`
  position: fixed;
  display: flex;
  padding: 12px;
  background: ${Colors.White};
  box-shadow: ${BoxShadows.UpDrop};
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: ${ZIndexes.MobileFooterActions};
  box-sizing: border-box;
`;

const MobileButtonSpacer = styled.div`
  min-width: 16px;
`;

type CreatePrivateEventCardProps = {};

const CreatePrivateEventCard: React.FC<CreatePrivateEventCardProps> = () => {
  /** State */
  const [clicked, setClicked] = React.useState(false);

  /** Hooks */
  const navigate = useNavigate();
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Actions */
  const privateEvent = useAppSelector(PrivateEventSelectors.privateEvent);
  const navigateToPrivateEventManagement = useNavigateToPrivateEventManagement();
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const setValidationErrors = (errors: Record<string, string>) => dispatch(
    PrivateEventActions.setErrors(errors),
  );

  const { listing } = useListing();
  const listingErrors = useAppSelector(ListingSelectors.errors);
  const isNewListing = useAppSelector(PrivateEventSelectors.isNewListing);
  const updateListing = () => dispatch(ListingActions.updateListing({ listing, toast: false }));
  const loaders = useAppSelector(ListingSelectors.loading);
  const isUpdateListingLoading = loaders[ListingLoaderTypesEnum.UpdateListing];
  const clearSelectedListing = () => dispatch(ListingActions.selectListing({ listingId: null }));
  const clearCreatePEFields = () => dispatch(PrivateEventActions.setPrivateEventField({
    settings: null,
    startsAt: null,
    endsAt: null,
    participants: null,
    contingencies: null,
  }));
  const clearPEErrors = () => dispatch(PrivateEventActions.setErrors(null));
  const pushCancelCreationModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Cancel Private Event Creation',
        text: 'Are you sure you want to cancel creating this private event?',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => {
          navigate('/app/dashboard/events');
          popModal();
        },
      },
    }),
  );
  const viewAdditionalTerms = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.DisplayTextBlock,
        props: {
          title: 'Additional terms',
          text: privateEvent?.contingencies?.additionalTerms,
        },
      }),
    );
  };

  /** GraphQL */
  const [createPrivateEvent, { loading }] = useMutation(CREATE_PRIVATE_EVENT, {
    variables: {
      params: {
        ...privateEvent,
      },
    },
    refetchQueries: [{
      query: LIST_PRIVATE_EVENTS,
      variables: {
        params: {
          queryType: PrivateEventsQueryTypeEnum.Managing,
        },
      },
    }],
    onCompleted: async (data) => {
      navigateToPrivateEventManagement(data?.createPrivateEvent?._id);
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /** Effects */
  React.useEffect(() => () => {
    clearSelectedListing();
    clearCreatePEFields();
    clearPEErrors();
  }, []);

  React.useEffect((): void => {
    // I hate this, I'm sorry.
    // There were race conditions and this was the quick and dirty fix.
    // TODO: refactor maybe make into a saga
    if (!(Object.keys(listingErrors)?.length > 0)
      && !isUpdateListingLoading
      && isNewListing
      && clicked) {
      createPrivateEvent();
    } else if (!isUpdateListingLoading) {
      setClicked(false);
    }
  }, [listingErrors, isUpdateListingLoading, isNewListing, clicked]);

  /** Render */
  const createEventHandler = async () => {
    if (isNewListing) {
      updateListing();
      setClicked(true);
    }

    const { errors } = JoiUtil.validate(
      PrivateEventJoi.createPrivateEventParamsSchema,
      { ...privateEvent },
    );

    if (JoiUtil.hasErrors(errors)) {
      setValidationErrors(JoiUtil.parseErrors(errors));

      // hacky lil regex to provide better error messages
      // could highlight the actual incorrect email with a little more effort
      if (Object.keys(JoiUtil.parseErrors(errors)).find((key) => key.match('/participants/.+/email'))) {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'Please ensure all emails are valid.',
        });
      } else {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'Please fill out all required fields.',
        });
      }
      return;
    }

    // if is new listing, call from the effect above
    if (!isNewListing) {
      createPrivateEvent();
    }
  };

  /** Render */
  if (isMobile || isTablet) {
    return (
      <MobileFooter>
        <Button
          text="Cancel"
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Medium}
          onClick={pushCancelCreationModal}
          isFullWidth
        />
        <MobileButtonSpacer />
        <Button
          data-cy="create-event-button"
          text="Create Event"
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Medium}
          isLoading={loading || isUpdateListingLoading}
          onClick={createEventHandler}
          isFullWidth
        />
      </MobileFooter>
    );
  }

  return (
    <GenericCard
      width="320px"
      margin="0 0 0 24px"
      boxShadow={BoxShadows.Standard}
      borderRadius="8px"
      padding="24px"
      height="fit-content"
    >
      <Text color={Colors.Black} type={TextTypesEnum.Bold16} margin="0 0 4px">
        Overview
      </Text>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Starting bid
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.settings?.startingBid ? `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}` : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Bid increments
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.settings?.bidIncrement ? `$${StringUtil.formatNumber(privateEvent?.settings?.bidIncrement)}` : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Start date
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.startsAt ? TimeUtil.format(privateEvent?.startsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          End date
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.endsAt ? TimeUtil.format(privateEvent?.endsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Diligence period
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.contingencies?.dueDiligencePeriod ? `${privateEvent.contingencies.dueDiligencePeriod} days` : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Closing period
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.contingencies?.closingPeriod ? `${privateEvent.contingencies.closingPeriod} days` : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
          Earnest money deposit
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
          {privateEvent?.contingencies?.depositAmount ? `$${StringUtil.formatNumber(privateEvent.contingencies.depositAmount)}` : '-'}
        </Text>
      </Row>
      <Row>
        <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
          Additional terms
        </Text>
        {privateEvent?.contingencies?.additionalTerms ? (
          <LinkText
            type={TextTypesEnum.Regular14Small}
            onClick={viewAdditionalTerms}
          >
            View
          </LinkText>
        ) : (
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            -
          </Text>
        )}
      </Row>
      {privateEvent?.participants?.length > 0 && (
        <>
          <Text color={Colors.Black} type={TextTypesEnum.Bold16} margin="24px 0 4px">
            Invites
          </Text>
          {privateEvent?.participants?.map((participant, index) => (
            <EmailContainer key={index}>
              <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 8px">
                {participant?.email}
              </Text>
            </EmailContainer>
          ))}
        </>
      )}
      <Divider margin="24px 0" />
      <Flex justify="flex-end">
        <Button
          margin="0 8px 0 0"
          text="Cancel"
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Medium}
          onClick={pushCancelCreationModal}
        />
        <Button
          data-cy="create-private-event-button"
          text="Create Event"
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Medium}
          isLoading={loading || isUpdateListingLoading}
          onClick={createEventHandler}
        />
      </Flex>
    </GenericCard>
  );
};

export default CreatePrivateEventCard;
