import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import IChargebeeInvoice from '@biproxi/models/interfaces/IChargebeeInvoice';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import SettingsHeader from '../components/settings/SettingsHeader';
import Flex from '../elements/Flex';
import InvoiceTable from '../components/settings/InvoiceTable';
import queryBillingInvoices from '../next-api/queries/billing/invoices.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import useUser from '../hooks/useUser.hook';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
  box-sizing: border-box;
`;

const Spacer = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? '16px'};
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 16px 0px;
`;

const LoaderContainer = styled.div`
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type SettingsInvoicePageProps = {};

const SettingsInvoicePage: React.FC<SettingsInvoicePageProps> = () => {
  /** Hooks */
  const { user } = useUser();

  /** Data */
  const { data, isLoading } = useQuery(['billing:invoices'], () => queryBillingInvoices(user?._id));
  const invoices: IChargebeeInvoice[] = data?.list?.map(({ invoice }) => invoice);

  /** Render */
  return (
    <Container>
      <Text color={Colors.Black} type={TextTypesEnum.Bold24}>Settings</Text>
      <Spacer />
      <SettingsHeader />
      <Spacer height="32px" />
      <Flex direction="row">
        <SectionTitle>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
          >
            Invoices
          </Text>
          <Text
            type={TextTypesEnum.Regular14}
            color={Colors.Grey700}
          >
            View or download your invoices.
          </Text>
        </SectionTitle>
      </Flex>
      {isLoading ? (
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      ) : (
        <InvoiceTable invoices={invoices} />
      )}
    </Container>
  );
};

export default SettingsInvoicePage;
