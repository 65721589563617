/* eslint-disable no-undef */
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import GET_LEAD_STATUS_OVERVIEW from '../../../graphql/queries/getLeadStatusOverview.query';
import Icon from '../../../elements/Icon';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import Icons from '../../../elements/Icons';
import { media } from '../../../utils/MediaQuery';
import Loader from '../../../elements/Loader';
import Flex from '../../../elements/Flex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
  height: 284px;
  border: 1px solid ${Colors.Grey300};
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);

  ${media.mobile} {
    width: 100%;
    height: 292px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
`;

const NoDataIconContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: ${Colors.Grey200};
  border-radius: 100px;
  margin: 4px 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

const Left = styled.div`
  white-space: nowrap;
  box-sizing: border-box;
`;

const Right = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`;

const Bar = styled.div<{ width: number; color: string }>`
  background: ${({ color }) => color};
  box-sizing: border-box;
  width: ${({ width }) => `${width}%`};
  height: 13px;
  border-radius: 4px;
  transition: all 0.2s;
  margin: ${({ width }) => (width <= 0 ? '0' : '0 8px 0 0')};
`;

type ComponentProps = {
  listingId: string;
};

const LeadsOverviewCard: React.FC<ComponentProps> = ({ listingId }) => {
  /** GraphQL */
  const { data, loading } = useQuery(
    GET_LEAD_STATUS_OVERVIEW,
    {
      variables: {
        params: {
          listingId,
        },
      },
    },
  );

  const counts = data?.getLeadStatusOverview;
  /**
   * Calculate the bar width based on the relative maximum.
   * The highest number in the count will always be 100% width.
   */
  const getBarWidth = (value: number) => {
    if (counts?.totalCount >= 0) {
      const { __typename, ...rest } = counts;
      const max = Math.max(...Object.values(rest || {}) as any); // TODO: fix typing
      return ((value / max) * 100);
    }
    return 0;
  };

  return (
    <Container>
      <Text type={TextTypesEnum.Bold16} margin="0 0 16px 0">
        Leads overview
      </Text>
      <StatsContainer>
        {loading ? (
          <LoaderContainer>
            <Loader color={Colors.Brand700 || Colors.Blurple700} />
          </LoaderContainer>
        ) : counts.totalCount > 0 ? (
          <Flex width="100%">
            <Left>
              <Text type={TextTypesEnum.Bold14} color={Colors.Grey900} margin="0 24px 8px 0">
                Total Count
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Not contacted
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Attempting contact
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Not interested
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Tour scheduled
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Reviewing diligence
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Offer expected
              </Text>
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900} margin="0 24px 8px 0">
                Offer received
              </Text>
            </Left>
            <Right>
              <Text type={TextTypesEnum.Bold14} margin="0 24px 8px 0">
                {counts?.totalCount}
              </Text>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.notContactedCount)} color={Colors.Brand700 || Colors.Blurple700} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.notContactedCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.attemptingContactCount)} color={Colors.Red400} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.attemptingContactCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.notInterestedCount)} color={Colors.Yellow300} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.notInterestedCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.propertyTourScheduledCount)} color={Colors.Grey200} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.propertyTourScheduledCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.reviewingDiligenceCount)} color={Colors.Green200} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.reviewingDiligenceCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.offerExpectedCount)} color={Colors.Yellow500} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.offerExpectedCount}
                </Text>
              </Flex>
              <Flex margin="0 0 8px 0" align="center">
                <Bar width={getBarWidth(counts?.offerReceivedCount)} color={Colors.Green500} />
                <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                  {counts?.offerReceivedCount}
                </Text>
              </Flex>
            </Right>
          </Flex>
        ) : (
          <NoDataContainer>
            <NoDataIconContainer>
              <Icon icon={Icons.UsersLight} color={Colors.Grey700} size={24} />
            </NoDataIconContainer>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey700}>
              No leads yet
            </Text>
          </NoDataContainer>
        )}
      </StatsContainer>
    </Container>
  );
};

export default LeadsOverviewCard;
