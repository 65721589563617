/* eslint-disable no-undef */
import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import IListingOverviewAnalytics, { ListingOverviewAnalyticsEnum } from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import LISTING_OVERVIEW_ANALYTICS from '../../../graphql/queries/listingOverviewAnalytics.query';
import Icon, { IconsType } from '../../../elements/Icon';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import { media, useMobileMedia, useTabletMedia } from '../../../utils/MediaQuery';
import { cubeTimeDimensionFilters } from './ListingViewsLineChart';
import Flex from '../../../elements/Flex';

const AnalyticChartTab = styled.div<{ type: AnalyticsOverviewTabsTypesEnum }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  ${media.mobile} {
    justify-content: space-between;
  }

  ${media.tablet} {
    justify-content: space-between;
  }
`;

const DesktopTabContainer = styled.div<{ isSelected?: boolean, type: AnalyticsOverviewTabsTypesEnum }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin: ${({ type }) => {
    switch (type) {
      case AnalyticsOverviewTabsTypesEnum.GeneralTabs:
        return '0px';
      case AnalyticsOverviewTabsTypesEnum.MapTabs:
        return '8px';
      default:
        return '0px';
    }
  }};
  flex: 1;
  height: ${({ type }) => {
    switch (type) {
      case AnalyticsOverviewTabsTypesEnum.GeneralTabs:
        return '48px';
      case AnalyticsOverviewTabsTypesEnum.MapTabs:
        return '64px';
      default:
        return '100%';
    }
  }};
  cursor: ${({ type }) => {
    switch (type) {
      case AnalyticsOverviewTabsTypesEnum.GeneralTabs:
        return 'default';
      case AnalyticsOverviewTabsTypesEnum.MapTabs:
        return 'pointer';
      default:
        return 'default';
    }
  }};
  background-color: ${({ isSelected }) => (isSelected ? 'rgba(63, 63, 225, 0.05)' : `${Colors.White}`)};
  color: ${({ isSelected }) => (isSelected ? `${Colors.Brand700 || Colors.Blurple700}` : `${Colors.White}`)};
  &:hover {
    background: ${({ type }) => {
    switch (type) {
      case AnalyticsOverviewTabsTypesEnum.GeneralTabs:
        return null;
      case AnalyticsOverviewTabsTypesEnum.MapTabs:
        return `${Colors.Grey100}`;
      default:
        return `${Colors.Grey100}`;
    }
  }};
  }
`;

const MobileTabContainer = styled.div<{ isSelected?: boolean, type: AnalyticsOverviewTabsTypesEnum }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px 0;
  border-radius: 8px;
  margin: 0 8px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? 'rgba(63, 63, 225, 0.05)' : `${Colors.White}`)};
  color: ${({ isSelected }) => (isSelected ? `${Colors.Brand700 || Colors.Blurple700}` : `${Colors.White}`)};
`;

const TabInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
`;

const MobileTabRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type AnalyticsOverviewTabsProps = {
  icon: IconsType;
  analyticName?: ListingOverviewAnalyticsEnum;
  type?: AnalyticsOverviewTabsTypesEnum
  tabName: string;
  listingId: string | string[];
  listingUserId: string;
  filterValue?: string;
  selectedAnalyticType?: ListingOverviewAnalyticsEnum;
  clickableTabs?: boolean;
  setSelectedAnalyticType?: (analyticType: ListingOverviewAnalyticsEnum) => void;
}

export enum AnalyticsOverviewTabsTypesEnum {
  MapTabs = 'MapTabs',
  GeneralTabs = 'GeneralTabs'
}

const AnalyticsOverviewTabs : React.FC<AnalyticsOverviewTabsProps> = ({
  icon,
  analyticName,
  type = AnalyticsOverviewTabsTypesEnum.GeneralTabs,
  tabName,
  listingId,
  listingUserId,
  filterValue,
  selectedAnalyticType,
  clickableTabs = true,
  setSelectedAnalyticType = () => {},
}) => {
  /* State */
  if (typeof listingId === 'string') listingId = [listingId];

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Render */
  const isSelected = analyticName === selectedAnalyticType;

    /** GraphQL */
    interface ListingAnalyticsData {
      listingOverviewAnalytics: IListingOverviewAnalytics;
    }
    interface ListingAnalyticsVars {
      params: {
        listingId: string[]
        listingUserId: string
        dateRange: string
      }
    }

    const { data: listingOverviewData } = useQuery<ListingAnalyticsData, ListingAnalyticsVars>(LISTING_OVERVIEW_ANALYTICS, {
      variables: {
        params: {
          listingId,
          listingUserId,
          dateRange: filterValue ? cubeTimeDimensionFilters[filterValue].dateRange : '',
        },
      },
      skip: !listingId?.length,
      onError: (error) => {
        logger.error('LISTING_OVERVIEW_ANALYTICS error', error);
      },
    });

    const specificAnalyticTabData = listingOverviewData?.listingOverviewAnalytics?.[analyticName];

    return (
      <AnalyticChartTab type={type}>
        {isMobile || isTablet ? (
          <MobileTabContainer
            onClick={() => { if (clickableTabs) setSelectedAnalyticType(analyticName); }}
            isSelected={isSelected}
            type={type}
          >
            <MobileTabRowContainer>
              <Icon
                icon={icon}
                color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
                margin="0px 16px 0 0"
                size={24}
              />
              <Flex direction="column">
                <Text
                  type={TextTypesEnum.Medium14}
                  color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
                >
                  {tabName}
                </Text>
                <Text
                  type={TextTypesEnum.Bold18}
                  color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
                  margin="0 3px"
                >
                  {specificAnalyticTabData >= 0 ? specificAnalyticTabData : '--'}
                </Text>
              </Flex>
            </MobileTabRowContainer>

          </MobileTabContainer>
        ) : (
          <DesktopTabContainer
            onClick={() => { if (clickableTabs) setSelectedAnalyticType(analyticName); }}
            isSelected={isSelected}
            type={type}
          >
            <Icon
              icon={icon}
              color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
              margin={type === AnalyticsOverviewTabsTypesEnum.GeneralTabs ? '0 16px 0 0' : '16px'} // a super hacky way to change very small css nuances. should probably revisit the design for the listing map component
              size={24}
            />
            <TabInfoContainer>
              <Text
                type={TextTypesEnum.Regular14}
                color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
              >
                {tabName}
              </Text>
              <Text
                type={TextTypesEnum.Bold18}
                color={isSelected ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey700}
              >
                {specificAnalyticTabData >= 0 ? specificAnalyticTabData : '--'}
              </Text>
            </TabInfoContainer>
          </DesktopTabContainer>
        )}
      </AnalyticChartTab>
    );
};

export default AnalyticsOverviewTabs;
