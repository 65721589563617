import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';

const Container = styled.div<{ active: boolean; margin: string }>`
  transition: all 0.2s ease-in-out;
  background: ${({ active }) => (active ? Colors.Green500 : Colors.Grey100)};
  border-radius: 4px;
  padding: 8px 12px;
  margin: ${({ margin }) => margin};
  cursor: pointer;
`;

type PageViewToggleButtonProps = {
  text: string;
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  margin?: string;
};

const PageViewToggleButton: React.FC<PageViewToggleButtonProps> = ({
  text,
  active,
  onClick,
  margin,
}) => (
  <Container
    active={active}
    onClick={onClick}
    margin={margin}
  >
    <Text
      type={TextTypesEnum.Medium12}
      color={active ? Colors.White : Colors.Brand700}
    >
      {text}
    </Text>
  </Container>
);

export default PageViewToggleButton;
