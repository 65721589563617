enum FitchCreditRatingEnum {
  AAA = 'AAA',
  AAPlus = 'AA+',
  AA = 'AA',
  AAMinus = 'AA-',
  APlus = 'A+',
  A = 'A',
  AMinus = 'A-',
  BBBPlus = 'BBB+',
  BBB = 'BBB',
  BBBMinus = 'BBB-',
}

export default FitchCreditRatingEnum;
