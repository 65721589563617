import { logger } from '@biproxi/logger';
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import { ChatActions } from '../redux/chat.redux';
import GET_CHANNEL from '../graphql/queries/channel.query';
import {
  AppState,
  useAppDispatch,
  useAppSelector,
} from '../redux/store';

type UseUpdateChannelsParams = {
  data: any;
  channels: any;
  setChannels: (val: any) => void;
  listingId?: string;
  search?: string;
}

type UseUpdateChannelsHook = (params: UseUpdateChannelsParams) => void;

/**
 * This hook is used to update the UI for sendbird
 * channel lists in real time as new channels or
 * updates to existing channels come through.
 * Might be better implemented as a redux saga.
 */
const useUpdateChannels: UseUpdateChannelsHook = ({
  data,
  channels,
  setChannels,
  listingId,
  search,
}) => {
  /** GraphQL */
  interface Data {
    channel: IChannelGraphQL;
  }

  interface Vars {
    params: {
      channelUrl: string;
    };
  }

  const [getChannel] = useLazyQuery<Data, Vars>(GET_CHANNEL, {
    onCompleted: (data) => {
      /**
       * If the new incoming channel meets the filter requirement,
       * add it to the visible UI, if not, don't.
       */
      const u = data?.channel?.user;
      if (u?.firstName.includes(search) || u?.lastName?.includes(search) || u?.email?.includes(search) || !search) {
        const prevChannels = [...channels];
        prevChannels?.unshift(data?.channel);
        setChannels(prevChannels);
      }
    },
    onError: (error) => {
      logger.error('GET_CHANNEL error', error);
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const { newChannel, channelsOpenedUrls, activeChatChannels } = useAppSelector((state: AppState) => state.chat);
  const popChannelsOpenedUrl = (channelUrl) => dispatch(
    ChatActions.popChannelsOpenedUrls({ channelUrl }),
  );

  /** Effects */
  React.useEffect(() => {
    setChannels(Array.from(new Set(data?.channels)));
  }, [data]);

  React.useEffect(() => {
    if (newChannel) {
      if (listingId && (newChannel?.listingId !== listingId)) return;
      if (channelsOpenedUrls?.includes(newChannel?.channelUrl) && (!activeChatChannels?.find((ch) => ch?.channelUrl === newChannel?.channelUrl))
      ) {
        popChannelsOpenedUrl(newChannel?.channelUrl);
      }
      const prevChannels = [...channels];
      const channelToUpdate = prevChannels?.find((channel) => channel?.channelUrl === newChannel?.channelUrl);
      if (channelToUpdate) {
        const updatedChannel: IChannelGraphQL = { ...channelToUpdate };
        prevChannels?.splice(prevChannels?.indexOf(channelToUpdate), 1);
        updatedChannel.lastMessageText = newChannel?.lastMessageText;
        updatedChannel.lastMessageAt = newChannel?.lastMessageAt;
        updatedChannel.hasUnreadMessages = newChannel?.hasUnreadMessages;
        prevChannels?.unshift(updatedChannel);
        setChannels(Array.from(new Set(prevChannels)));
      } else {
        getChannel({
          variables: {
            params: {
              channelUrl: newChannel?.channelUrl,
            },
          },
        });
      }
    }
  }, [newChannel]);
};

export default useUpdateChannels;
