import React, { MutableRefObject } from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IListingVaultFolderGraphQL } from '@biproxi/models/interfaces/IListingVaultFolder';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ListingVaultFolderNamesEnum from '@biproxi/models/enums/ListingVaultFolderNamesEnum';
import styled from '@emotion/styled';
import { useAppDispatch } from '../../redux/store';
import { ListingActions } from '../../redux/listing.redux';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import CreateListingVaultFile from './CreateListingVaultFile';
import LinkText from '../../elements/LinkText';
import useReduxInput from '../../hooks/useReduxInput.hook';
import Input, { InputSizeEnum, InputTypesEnum } from '../../elements/Input';
import { FileList } from './CreateListingVaultSection';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';

const DragContainer = styled.div<{ isDraggingOver: boolean, fullOpacity: boolean }>`
  background: ${(props) => (props.isDraggingOver
    ? Colors.Brand50 || Colors.Blurple50
    : null)};
  border-radius: 4px;

  opacity: ${(props) => (props.fullOpacity ? 1 : 0.3)};

  input {
    background-color: ${(props) => (props.isDraggingOver
    ? Colors.Brand50 || Colors.Blurple50
    : null)};
  }
`;

const FileOpacityContainer = styled.div<{ fullOpacity: boolean }>`
  #VaultFile {
    background-color: ${Colors.Brand50 || Colors.Blurple50};
    opacity: ${(props) => (props.fullOpacity ? 1 : 0.3)};
  }
`;

const ListItem = styled.div`
  margin-bottom: 8px;
`;

type CreateListingVaultFolderProps = {
    folder: IListingVaultFolderGraphQL;
    sectionOpen?: boolean;
    isRootFolder?: boolean; // conditional renders for root file
    onClick?: any, // for root folder
    listing?: IListingGraphQL, // render files for subFolders
    dragId?: string, // set opacity when dragging
    folderCountRef?: MutableRefObject<number>, // ref with value to increment new folder names
  };

const CreateListingVaultFolder: React.FC<CreateListingVaultFolderProps> = ({
  folder,
  sectionOpen = true,
  isRootFolder = false,
  onClick,
  listing,
  dragId,
  folderCountRef,
}) => {
  /** State */
  const [visible, setVisible] = React.useState<boolean>(true);
  const fullOpacity = Boolean(!dragId || (dragId === folder.name));

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  const addListingVaultSubFolder = (folderName: ListingVaultFolderNamesEnum) => {
    dispatch(
      ListingActions.addListingVaultSubFolder({
        folderName,
        count: folderCountRef.current,
      }),
    );
    folderCountRef.current += 1;
  };

  const removeListingVaultSubFolder = (folderName: string) => {
    dispatch(
      ListingActions.removeListingVaultSubFolder({
        folderName,
      }),
    );
    popModal();
  };

  const renameListingVaultSubFolder = (subFolderId: string) => dispatch(
    ListingActions.renameListingVaultSubFolder({
      subFolderId,
      newName: reduxInputValue,
    }),
  );

  const pushDeleteSubFolderModal = (folderName: string) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        title: 'Delete folder',
        text: 'Are you sure? You cannot undo this action afterwards.',
        buttonText: 'Delete',
        type: ConfirmChangeModalTypesEnum.Warning,
        confirm: () => removeListingVaultSubFolder(folderName),
      },
    }),
  );

  /** Hooks */
  const { reduxInputValue, setReduxInputValue } = useReduxInput({
    dispatchFunction: renameListingVaultSubFolder,
    dispatchParams: [folder.subFolderId],
    placeholder: folder.name ? folder.name : `Untitled${folderCountRef.current - 1}`,
    debounceTime: 1000,
  });

  /** Render */
  const subFolderDroppableId = folder.name;
  return (
    <>
      {(() => {
        const renderFile = (file, index) => {
          if (!file) return null;
          return (
            <Draggable
              key={file._id ?? index}
              draggableId={file._id ?? index}
              index={index}
            >
              {(provided) => (
                <ListItem
                  key={file._id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <CreateListingVaultFile
                    key={file._id}
                    listing={listing}
                    folder={folder}
                    file={file}
                  />
                </ListItem>
              )}
            </Draggable>
          );
        };

        return (
          <Droppable min-height="26px" droppableId={subFolderDroppableId ?? 'subFolder'}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DragContainer
                  isDraggingOver={snapshot.isDraggingOver}
                  fullOpacity={fullOpacity}
                >
                  <Flex align="center" justify="space-between" margin="0 0 8px" width="100%">
                    <Flex onClick={onClick ? () => onClick() : () => setVisible(!visible)} align="center" justify="flex-end">
                      <Icon
                        icon={
                            isRootFolder && sectionOpen
                              ? Icons.FolderOpenSolid
                              : isRootFolder && !sectionOpen
                                ? Icons.FolderSolid
                                : visible
                                  ? Icons.FolderOpenSolid
                                  : Icons.FolderSolid
                        }
                        size="16px"
                        color={Colors.Brand700 || Colors.Blurple700}
                        margin="0px 8px 0px 0px"
                      />
                      {isRootFolder ? (
                        <Text type={TextTypesEnum.Bold14} color={Colors.Black}>
                          {folder.name}
                        </Text>
                      ) : (
                        <Input
                          inputType={InputTypesEnum.FolderText}
                          inputSize={InputSizeEnum.Fitted}
                          onChange={(event, _unmasked) => setReduxInputValue(event.currentTarget.value)}
                          width="fit-content"
                          innerWidth="150px"
                          value={reduxInputValue}
                          noBorder
                        />
                      )}
                      <Text>
                  &nbsp;(
                        {isRootFolder
                          ? folder.subFolders?.reduce((cur, next) => cur + next.fileIds.length, 0) + folder.fileIds.length
                          : folder.fileIds.length}
                  &nbsp;
                        {isRootFolder
                          ? StringUtil.pluralize('doc', 'docs', folder.subFolders?.reduce(
                            (cur, next) => cur + next.fileIds.length,
                            0,
                          ) + folder.fileIds.length)
                          : StringUtil.pluralize('doc', 'docs', folder.fileIds.length)}
                        )
                      </Text>
                    </Flex>
                    {isRootFolder ? (
                      <LinkText type={TextTypesEnum.Medium12} onClick={() => addListingVaultSubFolder(folder.name as ListingVaultFolderNamesEnum)}>Add Folder</LinkText>
                    ) : (
                      <Icon
                        icon={Icons.TrashSolid}
                        size="16px"
                        color={Colors.Brand700 || Colors.Blurple700}
                        onClick={() => pushDeleteSubFolderModal(folder.name)}
                      />
                    )}
                  </Flex>
                </DragContainer>
                {(!isRootFolder && visible) && (
                  <FileOpacityContainer fullOpacity={fullOpacity}>
                    <FileList
                      files={folder.files}
                      folderName={folder.name}
                      renderFile={renderFile}
                      placeholder={provided.placeholder}
                      subFolderGrid
                    />
                  </FileOpacityContainer>
                )}
              </div>
            )}
          </Droppable>
        );
      })()}
    </>
  );
};

export default CreateListingVaultFolder;
