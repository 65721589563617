import React from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import styled from '@emotion/styled';
import RangeInput from '../../../RangeInput';
import { InputTypesEnum } from '../../../../elements/Input';
import { media } from '../../../../utils/MediaQuery';
import { dataExplorerTransactionFiltersAtom, ITransactionFilters } from '../../state-management/recoil/atoms/DataExplorerFiltersAtoms';

const TransactionsFiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mobile} {
      padding: 0 8px;
    }
`;

type TransactionsFiltersProps = {}

const TransactionsFilters: React.FC<TransactionsFiltersProps> = () => {
  /** Recoil */
  const dataExplorerTransactionFilters = useRecoilValue(dataExplorerTransactionFiltersAtom);
  const {
    lastSaleAmountRange,
    lastSalePeriodRange,
  } = dataExplorerTransactionFilters;

  /** Callbacks */
  const setLastSaleAmount = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerTransactionFiltersAtom, (prevState: ITransactionFilters) => ({
      ...prevState,
      lastSaleAmountRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [lastSaleAmountRange]);

  const setLastSalePeriod = useRecoilCallback(({ set }) => ({ minValue, maxValue } : { minValue: string | number, maxValue: string | number }) => {
    set(dataExplorerTransactionFiltersAtom, (prevState: ITransactionFilters) => ({
      ...prevState,
      lastSalePeriodRange: {
        max: maxValue,
        min: minValue,
      },
    }));
  }, [lastSalePeriodRange]);

  return (
    <TransactionsFiltersWrapper
      data-cy="TransactionsContainer"
    >
      <RangeInput
        label="Last sale amount"
        data-cy="LastSaleAmountInput"
        inputType={InputTypesEnum.Currency}
        onMinChange={(_e, unmasked) => setLastSaleAmount({ minValue: unmasked, maxValue: lastSaleAmountRange?.max })}
        onMaxChange={(_e, unmasked) => setLastSaleAmount({ minValue: lastSaleAmountRange?.min, maxValue: unmasked })}
        minValue={lastSaleAmountRange?.min}
        maxValue={lastSaleAmountRange?.max}
      />
      <RangeInput
        label="Last sale period"
        inputType={InputTypesEnum.Date}
        onMinChange={(e) => setLastSalePeriod({ minValue: e.target?.value, maxValue: lastSalePeriodRange?.max })}
        onMaxChange={(e) => setLastSalePeriod({ minValue: lastSalePeriodRange?.min, maxValue: e.target?.value })}
        minValue={lastSalePeriodRange?.min}
        maxValue={lastSalePeriodRange?.max}
      />
    </TransactionsFiltersWrapper>
  );
};

export default TransactionsFilters;
